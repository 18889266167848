import React, { memo, useRef } from "react";
import {
  Button,
  SvgIcon,
  clsx,
  Tooltip,
  useBoolean,
  SvgIconIconType,
  ButtonProps,
  PickUnion,
} from "@gemlightbox/core-kit";

import styles from "./table-action-icon.module.css";

type SupportedAppearances = PickUnion<
  NonNullable<ButtonProps["appearance"]>,
  "primaryGhost" | "errorGhost" | "secondaryGhost" | "promoGhost" | "whiteGhost"
>;

export interface TableActionIconProps {
  label: string;
  appearance: SupportedAppearances;
  icon: SvgIconIconType;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
}

export const TableActionIcon: React.FC<TableActionIconProps> = memo(
  ({ label, appearance, className, icon, onClick, disabled }: TableActionIconProps) => {
    const targetRef = useRef<HTMLButtonElement>(null);

    const isOpenBoolean = useBoolean();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick?.(e);
    };

    return (
      <>
        <Button
          className={clsx(styles.tableActionIconContainer, className)}
          appearance={appearance}
          forwardRef={targetRef}
          onClick={handleClick}
          onMouseEnter={isOpenBoolean.setTruthy}
          onMouseLeave={isOpenBoolean.setFalsy}
          data-cy={`${label}-table-product-action`}
          disabled={disabled}
        >
          <SvgIcon icon={icon} />
        </Button>
        <Tooltip
          appearance="secondary"
          position="bottom"
          target={targetRef}
          onClose={isOpenBoolean.setValue}
          isOpen={isOpenBoolean.value}
          withAngle
        >
          {label}
        </Tooltip>
      </>
    );
  },
);

export default TableActionIcon;
