import React from "react";
import { observer } from "mobx-react-lite";

import ToastComponent from "src/components/ToastComponent";
import { useStores } from "src/hooks";

import { MAX_BLINK_MEDIA_IN_PRODUCT } from "src/containers/products/products.constants";

type MaxBlinkAssignedToastProps = {
  isOpened: boolean;
  onClose: VoidFunction;
};

export const MaxBlinkAssignedToast: React.FC<MaxBlinkAssignedToastProps> = observer(
  ({ isOpened, onClose }) => {
    const { localeStore } = useStores();

    return (
      <ToastComponent isOpened={isOpened} onClose={onClose}>
        <div className="label">
          {localeStore.t('["product-assign-media-sidebar"]["max-blink-assigned-toast"].first')}
          &nbsp;{MAX_BLINK_MEDIA_IN_PRODUCT}&nbsp;
          {localeStore.t('["product-assign-media-sidebar"]["max-blink-assigned-toast"].second')}
        </div>
      </ToastComponent>
    );
  },
);

export default MaxBlinkAssignedToast;
