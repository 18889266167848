import React from "react";
import { observer } from "mobx-react-lite";
import { clsx, Heading } from "@gemlightbox/core-kit";

import { subscriptionsData } from "src/containers/settings/subscriptions/subscriptions.constants";
import { useStores } from "src/hooks";

import styles from "./offer-features.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export interface OfferFeaturesProps {
  className?: string;
  canDisplayAddons?: boolean;
}

export const OfferFeatures: React.FC<OfferFeaturesProps> = observer(
  ({ className = "", canDisplayAddons = true }: OfferFeaturesProps) => {
    const { localeStore } = useStores();

    return (
      <div className={clsx(styles.offerNamesWrapper, className)}>
        {canDisplayAddons && (
          <div className={clsx(styles.offerFeaturesWrapper, styles.addons)}>
            <div className={styles.offerLabelWrapper}>
              {localeStore.t(
                'settings.subscriptions.components.content["offer-features"]["subaccounts-and-storage-label"]',
              )}
            </div>
            <div className={styles.offerContentContainer}>
              <Heading tag="h4" className={clsx(styles.featureName, styles.subaccounts)}>
                {localeStore.t(
                  'settings.subscriptions.components.content["offer-features"].subaccounts',
                )}
              </Heading>
              <Heading tag="h4" className={styles.featureName}>
                {localeStore.t(
                  'settings.subscriptions.components.content["offer-features"].storage',
                )}
              </Heading>
            </div>
          </div>
        )}
        <div className={styles.offerFeaturesWrapper}>
          <div className={styles.offerLabelWrapper}>
            {localeStore.t(
              'settings.subscriptions.components.content["offer-features"]["features-label"]',
            )}
          </div>
          <div className={styles.offerContentContainer}>
            {subscriptionsData.features.map(({ name }, index) => (
              <Heading tag="h4" key={index} className={styles.featureName}>
                {localeStore.t(
                  `settings.subscriptions.components.content["offer-features"][${name
                    .replaceAll(" ", "")
                    .toLocaleLowerCase()}]` as LocaleCodeTypes,
                )}
              </Heading>
            ))}
          </div>
        </div>
      </div>
    );
  },
);

export default OfferFeatures;
