import React from "react";
import { Button, Heading, Modal } from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";

import { ModalExtendedType } from "src/store";
import { ExtendedTrashBinItemModel } from "src/models";
import { useStores } from "src/hooks";

import styles from "./recover-media-modal.module.css";

export type RecoverMediaModalProps = ModalExtendedType<{
  media: ExtendedTrashBinItemModel[];
}>;

export const RecoverMediaModal: React.FC<RecoverMediaModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { localeStore, trashBinStore } = useStores();

    const handleSubmit = () => {
      trashBinStore.recoverItems(options.media);
      setClose();
    };

    return (
      <Modal
        contentClassName={styles.modal}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        withCross
        disablePadding
        disableBorderRadius
      >
        <Heading className={styles.title} tag="h2" color="textSecondary">
          {localeStore.t('["trash-bin"].modals.recover.title')}
        </Heading>
        <div className={styles.buttons}>
          <Button appearance="tertiaryOutlined" onClick={setClose}>
            {localeStore.t('["trash-bin"].modals.buttons.cancel')}
          </Button>
          <Button appearance="primary" onClick={handleSubmit}>
            {localeStore.t('["trash-bin"].modals.buttons.recover')}
          </Button>
        </div>
      </Modal>
    );
  },
);
