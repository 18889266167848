import React from "react";
import { Table } from "@gemlightbox/core-kit";

import { SubaccountsTableHeader } from "./subaccounts-table-header";
import { SubaccountsTableBody } from "./subaccounts-table-body";

import styles from "./subaccounts-table.module.css";

export const SubaccountsTable: React.FC = () => {
  return (
    <Table containerClassName={styles.subaccountsTableContainer}>
      <SubaccountsTableHeader />
      <SubaccountsTableBody />
    </Table>
  );
};
