import { capitalize, SelectOption } from "@gemlightbox/core-kit";

import { SubaccountStatuses } from "src/containers/settings/subaccounts/subaccounts.constants";
import { DEFAULT_USER_FILTER_TYPE } from "./user-select.constants";

export const getUserFilterOptions = (
  me: any,
  users: any,
  includeYou = true,
): SelectOption<number>[] => {
  const filteredUsers = users.filter(({ status }: any) => status !== SubaccountStatuses.pending);

  const defaultOption = {
    label: capitalize(DEFAULT_USER_FILTER_TYPE),
    value: DEFAULT_USER_FILTER_TYPE,
  };

  const isMeInUsers = !!filteredUsers.find(({ user_id }: any) => user_id === me._id);

  let usersList;

  if (isMeInUsers) {
    usersList = [...filteredUsers];
  } else {
    usersList = [{ ...me, user_id: me._id }, ...filteredUsers];
  }

  const parsedUsers = usersList.map(({ firstName, lastName, user_id }) => ({
    label: `${firstName} ${lastName}${user_id === me._id && includeYou ? " (You)" : ""}`,
    value: user_id,
  }));

  return [defaultOption, ...parsedUsers];
};
