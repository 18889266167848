import React from "react";
import { observer } from "mobx-react-lite";
import {
  useWindowSize,
  WIDTH_BREAKPOINTS,
  Image,
  Heading,
  Typography,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import emptyCatalogsImg from "src/external-ts/assets/images/catalog/empty-catalogs-new.png";
import styles from "./empty-catalogs.module.css";

export const EmptyCatalogs: React.FC = observer(() => {
  const { windowWidth } = useWindowSize();

  const { localeStore } = useStores();

  const renderEmptyScreen = () => (
    <>
      <Image src={emptyCatalogsImg} />

      <div className={styles.textContainer}>
        <Heading className={styles.headline} tag="h2" color="textSecondary">
          {localeStore.t('catalog["empty-catalogs"].title')}
        </Heading>
        <Typography className={styles.label} size="small" color="textTertiary">
          {localeStore.t('catalog["empty-catalogs"].subtitle.top')}
          <br />
          {localeStore.t('catalog["empty-catalogs"].subtitle.bottom')}
        </Typography>
      </div>
    </>
  );

  const renderEmptyLabel = () => (
    <Heading className={styles.headline} tag="h2" color="textSecondary">
      {localeStore.t('catalog["empty-catalogs"]["mobile-title"]')}
    </Heading>
  );

  return (
    <div className="empty-items empty-catalogs">
      {windowWidth >= WIDTH_BREAKPOINTS.tablet ? renderEmptyScreen() : renderEmptyLabel()}
    </div>
  );
});

export default EmptyCatalogs;
