import React from "react";
import { observer } from "mobx-react-lite";
import { RefModel } from "@gemlightbox/core-kit";

import { ViewTypeKeys } from "src/external-ts/components";
import { TableView } from "./table-view";
import { GridView } from "./grid-view";

export type ProductsListProps = {
  view: ViewTypeKeys;
  productsContainerRef: RefModel<HTMLDivElement>;
};

export const ProductsList: React.FC<ProductsListProps> = observer(
  ({ view, productsContainerRef }) => {
    return (
      <>
        {view === "table" && <TableView productsContainerRef={productsContainerRef} />}
        {view === "grid" && <GridView productsContainerRef={productsContainerRef} />}
      </>
    );
  },
);

export default ProductsList;
