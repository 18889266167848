import React from "react";
import { Nullable, clsx } from "@gemlightbox/core-kit";

import { AttributeModel } from "src/models";
import { ProductSortDirectionType } from "src/store";

import { ReactComponent as AngleUpSVG } from "src/common/images/angle-up-secondary.svg";
import styles from "./table-sort-buttons.module.css";

interface TableSortButtonsProps {
  attribute: AttributeModel;
  activeSortAngleIcon: Nullable<{
    attributeName: string;
    sortDir: ProductSortDirectionType;
  }>;
  handleSort: (attribute: AttributeModel, sortDir: ProductSortDirectionType) => Promise<void>;
}

export const TableSortButtons: React.FC<TableSortButtonsProps> = ({
  attribute,
  handleSort,
  activeSortAngleIcon,
}) => {
  const { name: attributeName } = attribute;

  return (
    <div>
      <div className={styles.angleUpIconContainer} onClick={() => handleSort(attribute, "desc")}>
        <AngleUpSVG
          className={clsx(styles.angleIcon, {
            [styles.active]:
              activeSortAngleIcon?.attributeName === attributeName &&
              activeSortAngleIcon.sortDir === "desc",
          })}
        />
      </div>
      <div
        className={clsx(styles.angleDownIconContainer, {
          [styles.active]:
            activeSortAngleIcon?.attributeName === attributeName &&
            activeSortAngleIcon.sortDir === "asc",
        })}
        onClick={() => handleSort(attribute, "asc")}
      >
        <AngleUpSVG className={styles.angleIcon} />
      </div>
    </div>
  );
};
