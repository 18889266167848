import {
  ActionOption,
  Button,
  Collapse,
  EllipseActions,
  SvgIcon,
  Typography,
  clsx,
  useMediaBreakpoints,
} from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import React from "react";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as DownloadCloudSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as csvExportIcon } from "src/external-ts/assets/images/file-csv-grey.svg";

import { useStores } from "src/hooks";

import styles from "./products-selection-panel.module.css";

export const ProductsSelectionPanel: React.FC<{ beforeDelete?: () => void }> = observer(
  ({ beforeDelete }) => {
    const { productsStore, modalsStore, localeStore } = useStores();
    const { isMobileMedia } = useMediaBreakpoints();
    const selectedProducts = productsStore.productsPendingUploadList.filter(
      (product) => product.extended.selected,
    );

    const handleDelete = () => {
      modalsStore.open("DeleteProductModal", {
        products: selectedProducts,
        deleteFunc: () => {
          beforeDelete?.();
          productsStore.deleteProductsPendingUpload(selectedProducts);
        },
      });
    };

    const handleSelectAll = () => {
      productsStore.productsPendingUploadList.forEach((product) => {
        product.extended.selected = true;
      });
    };

    const handleUnSelectAll = () => {
      productsStore.productsPendingUploadList.forEach((product) => {
        product.extended.selected = false;
      });
    };

    return (
      <Collapse isOpen={selectedProducts.length > 0} opacityTransition>
        <div
          className={clsx(styles.productsSelectionPanelWrapper, {
            [styles.mobileMedia]: isMobileMedia,
          })}
          data-cy="products-selection-panel"
        >
          <div className={styles.productsSelectionPanelLeft}>
            <div className={styles.selectedAmount}>
              <Typography size="small600">
                {selectedProducts.length}&nbsp;
                {(selectedProducts.length > 1 &&
                  localeStore.t(
                    'products["products-selection-panel"]["selected-amount"].multiple',
                  )) ||
                  localeStore.t('products["products-selection-panel"]["selected-amount"].single')}
              </Typography>
              &nbsp;
              <Typography size="small">
                {localeStore.t('products["products-selection-panel"].selected')}
              </Typography>
            </div>
            <Button
              appearance="secondary"
              size="small"
              onClick={handleUnSelectAll}
              data-cy="unselect-button"
            >
              {localeStore.t("common.buttons.unselect")}
            </Button>
            {/* <Button
              appearance="primaryOutlined"
              size="small"
              onClick={handleSelectAll}
              data-cy="select-button"
            >
              {localeStore.t('common.buttons["select-all"]')}
            </Button> */}
          </div>
          <div className={styles.productsSelectionPanelRight}>
            <Button
              appearance="unset"
              size="unset"
              onClick={() => handleDelete()}
              className={styles.deleteButton}
            >
              <SvgIcon icon={DeleteSVG} />
              {localeStore.t("common.buttons.delete")}
            </Button>
          </div>
        </div>
      </Collapse>
    );
  },
);
