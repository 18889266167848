import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import { MediaPreview, NUMBER_OF_MEDIA_TO_PREVIEW } from "../../media-preview";

import styles from "./media-without-sku-card.module.css";

type MediaWithoutSKUCardProps = {
  style?: React.CSSProperties;
};

export const MediaWithoutSKUCard: React.FC<MediaWithoutSKUCardProps> = observer(({ style }) => {
  const { mediaStore, modalsStore, localeStore } = useStores();

  const handleCardClick = () => modalsStore.open("NoSKUMediaSidebar", {});

  return (
    <div className={styles.container} style={style} onClick={handleCardClick}>
      <div className={styles.imageContainer}>
        <div className={styles.imageFiller} />
        <MediaPreview
          className={styles.mediaPreview}
          view="grid"
          totalMediaAmount={mediaStore.resultsMediaAmount}
          mediaToShow={mediaStore.mediaList.slice(0, NUMBER_OF_MEDIA_TO_PREVIEW)}
        />
      </div>
      <div className={styles.title}>
        {localeStore.t('products["products-list"]["grid-view"]["media-without-sku-card"].title')}
      </div>
    </div>
  );
});

export default MediaWithoutSKUCard;
