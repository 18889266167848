import React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Button,
  SvgIcon,
  SelectGroup,
  SelectGroupOption,
  SelectGroupOptionValueType,
  capitalize,
  clsx,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ProductVariantOption } from "../../product-variant.types";
import { productVariantsStore } from "../../product-variants.store";
import { OptionValue } from "./option-value";

import { ReactComponent as DragSVG } from "src/external-ts/assets/images/drag-grey.svg";
import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./variant-option.module.css";

export type VariantOptionProps = {
  index: number;
  isDraggedOption: boolean;
  isDropPosition: boolean;
  option: ProductVariantOption;
  onOptionPointerDown: (e: React.PointerEvent, index: number) => void;
};

export const VariantOption: React.FC<VariantOptionProps> = observer(
  ({ index, isDraggedOption, isDropPosition, option, onOptionPointerDown }: VariantOptionProps) => {
    const { id, name, option_values } = option;

    const { productId } = useParams<{ productId: string }>();

    const { localeStore } = useStores();

    const disabledControls = productVariantsStore.isVariantOptionInProcess;
    const draggable = id != null && !disabledControls;

    const validOptions = option_values.filter(({ id, value }) => id && value);

    const handleSelectChange = (value: SelectGroupOptionValueType) => {
      productVariantsStore.setOptionName(value as string, index);
    };

    const handlePointerDown = (e: React.PointerEvent) => {
      if (!draggable) return;
      onOptionPointerDown(e, index);
    };

    const handleTrashClick = () => productVariantsStore.removeOption(index);

    const handleOptionValueChange = (value: string, optionValueIndex: number) => {
      productVariantsStore.changeOptionValue(value, index, optionValueIndex);
    };

    const handleOptionValueBlur = (optionValueIndex: number) => {
      productVariantsStore.blurOptionValue(index, optionValueIndex);
    };

    const handleOptionValueRemoveClick = (optionValueIndex: number) => {
      productVariantsStore.removeOptionValue(index, optionValueIndex);
    };

    const handleDoneClick = () => {
      productVariantsStore.submitOption(Number(productId), index);
    };

    return (
      <div
        className={clsx(
          styles.variantOptionContainer,
          { [styles.draggable]: draggable },
          { [styles.inDrag]: isDraggedOption },
          { [styles.isDropPosition]: isDropPosition },
        )}
      >
        <div className={styles.variantOptionMainContent}>
          <Button
            className={styles.dragBtn}
            appearance="secondaryGhost"
            onPointerDown={handlePointerDown}
            disabled={!draggable}
          >
            <SvgIcon icon={DragSVG} />
          </Button>
          <div />
          <SelectGroup
            className={styles.selectContainer}
            inputWrapperClassName={styles.selectWrapper}
            label={localeStore.t('["product-variant"]["option-name"]')}
            labelMessage={!index ? localeStore.t('["product-variant"]["max-3"]') : ""}
            placeholder={localeStore.t('["product-variant"]["option-select-placeholder"]')}
            searchValue={capitalize(name)}
            value={name}
            onChange={handleSelectChange}
            disableUncheck
            disableSearch
            disableClear
          >
            {productVariantsStore.availableOptions.map((variant) => (
              <SelectGroupOption
                key={variant}
                className={styles.selectOptionContainer}
                value={variant}
              >
                {variant}
              </SelectGroupOption>
            ))}
          </SelectGroup>
          <Button
            appearance="tertiaryOutlined"
            onClick={handleTrashClick}
            disabled={disabledControls}
          >
            <SvgIcon icon={TrashSVG} />
          </Button>
        </div>
        {name && (
          <div className={styles.variantOptionChildrenContent}>
            {option_values.map(({ id, value }, index) => {
              const canRemove = id ? validOptions.length > 1 : true;
              return (
                <OptionValue
                  key={index}
                  value={value}
                  index={index}
                  onOptionValueChange={handleOptionValueChange}
                  onOptionValueBlur={handleOptionValueBlur}
                  onOptionValueRemoveClick={handleOptionValueRemoveClick}
                  showMaxLabel={!index}
                  canRemove={canRemove}
                />
              );
            })}
            <Button
              className={styles.doneButton}
              appearance="secondary"
              size="small"
              onClick={handleDoneClick}
              loading={productVariantsStore.submittingOption}
              disabled={!productVariantsStore.checkCanSubmitOption(index)}
            >
              {localeStore.t("common.buttons.done")}
            </Button>
          </div>
        )}
      </div>
    );
  },
);

export default VariantOption;
