import React, { memo } from "react";
import { RadioGroup, RadioOption } from "@gemlightbox/core-kit";

import styles from "./ar-embed-button.module.css";

export type AREmbedButtonProps = {
  value: string;
  type: string;
  staticUrl: string;
  onChange: (type: string) => void;
};

export const AREmbedButton: React.FC<AREmbedButtonProps> = memo(
  ({ value, type, staticUrl, onChange }: AREmbedButtonProps) => {
    const handleSelectButton = () => onChange(type);

    return (
      <div className={styles.arEmbedButtonContainer}>
        <RadioGroup value={value} onChange={handleSelectButton} disableError>
          <RadioOption value={type} />
        </RadioGroup>
        <button
          className={styles.arEmbedButton}
          type="button"
          style={{ backgroundImage: `url(${staticUrl})` }}
          onClick={handleSelectButton}
        >
          Try me on!
        </button>
      </div>
    );
  },
);

export default AREmbedButton;
