import React from "react";
import { observer } from "mobx-react-lite";
import { globalStyles, clsx, useScrollDetect } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";

import { editMediaStore } from "../edit-media.store";
import { Layers } from "./layers";
import { Adjustments } from "./adjustments";
import { Background } from "./background";
import { Measurements } from "./measurements";
import { Resize } from "./resize";
import { MediaInfo } from "src/containers/media/media-info";

import styles from "./inspector.module.css";

export const Inspector: React.FC = observer(() => {
  const { renderer } = editMediaStore;
  const { localeStore } = useStores();

  const { ref, scrollable } = useScrollDetect<HTMLDivElement>();

  const tab = editMediaStore.currentTab;

  const inspectorStyles = clsx(styles.editMediaInspector, globalStyles.addScrollStyles, {
    [styles.scrollable]: scrollable,
  });

  return (
    <div className={inspectorStyles} ref={ref}>
      {renderer && <Layers />}
      {renderer && tab === "adjustments" && <Adjustments />}
      {renderer && tab === "measurements" && <Measurements />}
      {renderer && tab === "resize" && <Resize />}
      {renderer && tab === "background" && <Background />}
      {renderer && tab === "media-info" && (
        <div className={styles.deviceInfo}>
          <span>{localeStore.t('media["media-info"]["device-info"]')}</span>
          <MediaInfo
            className={styles.mediaInfo}
            mediaInfoItemClass={styles.mediaInfoItem}
            metaData={editMediaStore.metaData}
          />
        </div>
      )}
    </div>
  );
});

export default Inspector;
