import { dashboardApiMiddleware } from "src/api";
import { CardModel } from "src/models";
import {
  BillingInfo,
  GetSubscriptionPlansResponse,
  PostSubscriptionCardData,
  PostSubscriptionUpcomingInvoicePreviewData,
} from "./subscriptions.interface";

export const getSubscription = dashboardApiMiddleware<any>()({
  method: "get",
  endpoint: "/api/subscription",
  auxiliaryKeys: {
    isRestoreCache: true,
  },
});

export const getSubscriptionPlans = dashboardApiMiddleware<GetSubscriptionPlansResponse[]>()({
  method: "get",
  endpoint: "/api/subscription/plans",
});

export const getCards = dashboardApiMiddleware<CardModel[]>()({
  method: "get",
  endpoint: "/api/subscription/cards",
});

export const postSubscription = dashboardApiMiddleware<undefined, PostSubscriptionCardData>()({
  method: "post",
  endpoint: "/api/subscription/:priceId",
});

export const putRenewSubscription = dashboardApiMiddleware()({
  method: "put",
  endpoint: "/api/subscription/renew",
});

export const putSubscriptionAdditional = dashboardApiMiddleware<
  undefined,
  PostSubscriptionCardData | undefined
>()({
  method: "put",
  endpoint: "/api/subscription/additional",
});

export const postSubscriptionUpcomingInvoicePreview = dashboardApiMiddleware<
  BillingInfo,
  PostSubscriptionUpcomingInvoicePreviewData
>()({
  method: "post",
  endpoint: "/api/subscription/upcoming-invoice/preview",
});

export const putCard = dashboardApiMiddleware<CardModel["id"], PostSubscriptionCardData>()({
  method: "put",
  endpoint: "/api/subscription/cards/default",
  responseType: "text",
});

export const patchSubscription = dashboardApiMiddleware<undefined, PostSubscriptionCardData>()({
  method: "patch",
  endpoint: "/api/subscription/:priceId",
});

export const deviceSubscription = dashboardApiMiddleware<undefined, PostSubscriptionCardData>()({
  method: "post",
  endpoint: "/api/subscription/device/:bluetoothName",
});

export const deleteSubscription = dashboardApiMiddleware()({
  method: "delete",
  endpoint: "/api/subscription",
});

export const deleteCard = dashboardApiMiddleware()({
  method: "delete",
  endpoint: "/api/subscription/cards/:cardId",
});

export const activateLifetimePlan = dashboardApiMiddleware<
  undefined,
  FormData,
  { code: string; message: string }
>()({
  method: "post",
  endpoint: "/api/subscription/activate-lifetime",
});
