import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { clsx, Input, useBoolean } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./catalog-name.module.css";

export interface CatalogNameProps {
  name: string;
  editable: boolean;
  onNameUpdate: (name: string) => Promise<any>;
}

export const CatalogName: React.FC<CatalogNameProps> = observer(
  ({ editable, name, onNameUpdate }) => {
    const { localeStore } = useStores();

    const editBoolean = useBoolean();
    const [_name, setName] = useState(name);

    const handleBlur = () => {
      setName(name);
      editBoolean.setFalsy();
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") return;

      const target = e.target as HTMLInputElement;

      if (!_name || _name === name) {
        return target.blur();
      }

      await onNameUpdate(_name);
      target.blur();
    };

    return (
      <div className={styles.container}>
        {editable && editBoolean.value ? (
          <Input
            inputClassName={styles.input}
            appearance="secondaryV2"
            value={_name}
            onChange={setName}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            disableError
            autoFocus
          />
        ) : (
          <div
            className={clsx(styles.name, { [styles.editable]: editable })}
            onClick={editBoolean.setTruthy}
          >
            <div className={globalStyles.applySingleOverflow}>{name}</div>
            {editable && (
              <span className={styles.editButton}>
                {localeStore.t(
                  'catalog["catalogs-list"]["catalog-grid-card"]["catalog-name"].buttons.edit',
                )}
              </span>
            )}
          </div>
        )}
      </div>
    );
  },
);
