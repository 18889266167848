import { Webgl2New, Vec2, Nullable, deepObserve, findClosestNumbers } from "@gemlightbox/core-kit";

import { Camera2d, cameraScalesArr } from "./camera-2d";
import { EventsManager } from "./events-manager";

export type CameraRendererParentType = Nullable<HTMLElement>;

export class CameraRenderer {
  public parent: CameraRendererParentType;
  private _parentObserver: ResizeObserver | null = null;

  public readonly canvas: HTMLCanvasElement;
  public readonly ctx: CanvasRenderingContext2D;

  public readonly eventsManager: EventsManager;

  public readonly camera: Camera2d;

  private _mounted = false;
  private _animationFrameId = 0;

  private _chessGridComponent: Webgl2New.ChessGridComponent;

  private _canvasSize = Vec2.zero();
  public get canvasSize() {
    return this._canvasSize.clone();
  }

  public components: Webgl2New.Webgl2Component[] = [];

  private _disposeMap = new Map<Webgl2New.Webgl2Component, VoidFunction>();

  constructor() {
    this.render = this.render.bind(this);
    this._render = this._render.bind(this);

    this.canvas = document.createElement("canvas");
    // NOTE: since we have checks for webgl2, it's 100% guaranteed that we will have "2d" context
    this.ctx = this.canvas.getContext("2d") as CanvasRenderingContext2D;

    this.camera = new Camera2d();
    this.camera.events.on("change", this.render);

    this.eventsManager = new EventsManager(this);
    // this.export = new RendererExport(this);
  }

  public setViewport(): void {
    if (!this.parent) return;

    const width = this._canvasSize.width;
    const height = this._canvasSize.height;

    if (this.camera.projection.equals(width, height)) return;

    this.canvas.width = width;
    this.canvas.height = height;
    this.camera.updateProjection(width, height);

    const [min] = findClosestNumbers(
      this.camera.projection.divide(this._canvasSize).min(),
      ...cameraScalesArr.map(([, scale]) => scale),
    );

    this.camera.setScale(min);
    this.camera.centerViewportOnGlobalPosition(this._canvasSize.getHalf());

    this.render();
  }

  public setCanvas(width: number, height: number): void {
    this._chessGridComponent.transform.setSize(width, height);
    this._canvasSize.set(width, height);
    this.setViewport();

    const [min] = findClosestNumbers(
      this.camera.projection.divide(this._canvasSize).min(),
      ...cameraScalesArr.map(([, scale]) => scale),
    );

    this.camera.setTranslationClamp(this._canvasSize);
    this.camera.setScale(min);
    this.camera.centerViewportOnGlobalPosition(this._canvasSize.getHalf());

    this.render();
  }

  public mount(parent: CameraRendererParentType, width: number, height: number): boolean {
    this.unmount(parent);

    if (!parent) return false;

    this.parent = parent;
    this._mounted = true;
    this.eventsManager.registerListeners();

    const observer = new ResizeObserver(() => this.setViewport());
    observer.observe(parent);
    this._parentObserver = observer;

    this._prepareComponents();

    this.setCanvas(width, height);
    this.setViewport();

    this.canvas.tabIndex = 0;
    this.canvas.style.outline = "none";
    parent.appendChild(this.canvas);

    this.render();

    return true;
  }

  public unmount(parent: CameraRendererParentType = null) {
    parent?.querySelector<HTMLCanvasElement>("canvas")?.remove();
    this.parent = null;
    this._parentObserver?.disconnect();
    this._parentObserver = null;
    this.eventsManager.unregisterListeners();
    window.cancelAnimationFrame(this._animationFrameId);
    this._disposeMap.forEach((dispose) => dispose());
    this._mounted = false;
  }

  public addComponent(component: Webgl2New.Webgl2Component<any, any>): boolean {
    const canObserve = this._observeComponent(component);
    if (!canObserve) return false;
    this.components.push(component);
    this.render();
    return true;
  }

  public removeComponent(component: Webgl2New.Webgl2Component<any, any>): boolean {
    const foundIndex = this.components.findIndex((c) => c === component);
    if (foundIndex === -1 || !this._disposeComponent(component)) return false;
    this.components.splice(foundIndex, 1);
    this.render();
    return true;
  }

  public renderComponents() {
    for (const component of this.components) {
      Webgl2New.Webgl2.renderComponent(component);
    }
  }

  public render() {
    if (this._animationFrameId || !this._mounted) return;
    this._animationFrameId = window.requestAnimationFrame(this._render);
  }

  private _render() {
    this._animationFrameId = 0;

    if (this.camera.projection.getFloor().hasZero()) return;

    // Setup block
    const viewport = this.camera.projection;
    Webgl2New.Webgl2.setViewport(viewport);
    Webgl2New.Webgl2.camera.copy(this.camera);
    Webgl2New.Webgl2.clear();

    // Render block
    Webgl2New.Webgl2.renderComponent(this._chessGridComponent);

    this.renderComponents();

    // Render result to ui canvas
    this.ctx.clearRect(0, 0, viewport.width, viewport.height);
    this.ctx.drawImage(Webgl2New.Webgl2.canvas, 0, 0, viewport.width, viewport.height);
  }

  private _observeComponent(component: Webgl2New.Webgl2Component<any, any>): boolean {
    if (this._disposeMap.has(component)) return false;
    const dispose = deepObserve(component, this.render);
    this._disposeMap.set(component, dispose);
    return true;
  }

  private _disposeComponent(component: Webgl2New.Webgl2Component<any, any>): boolean {
    const dispose = this._disposeMap.get(component);
    if (!dispose) return false;
    dispose();
    this._disposeMap.delete(component);
    return true;
  }

  private _prepareComponents() {
    this._chessGridComponent = new Webgl2New.ChessGridComponent();
  }
}

export default CameraRenderer;
