import React from "react";
import { observer } from "mobx-react-lite";
import { Image, SvgIcon, Button, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getStaticUrl } from "src/utils";

import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import styles from "./measurement-tutorial.module.css";

export type MeasurementTutorialProps = {
  onClose?: VoidFunction;
};

export const MeasurementTutorial: React.FC<MeasurementTutorialProps> = observer(
  ({ onClose }: MeasurementTutorialProps) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.measurementTutorialContainer}>
        {onClose && (
          <Button className={styles.closeBtn} appearance="secondaryGhost" onClick={onClose}>
            <SvgIcon icon={CrossSVG} />
          </Button>
        )}
        <Typography className={styles.tutorialHead} size="small">
          {localeStore.t('["edit-media"]["measurement-tutorial"].head')}
        </Typography>
        <Image src={getStaticUrl("/measurement-tutorial.jpg")} />
        <div>
          <Typography size="small">
            {localeStore.t('["edit-media"]["measurement-tutorial"]["ol-title"]')}
          </Typography>
          <ol>
            <li>
              <Typography size="small">
                {localeStore.t('["edit-media"]["measurement-tutorial"]["ol-1"]')}
              </Typography>
            </li>
            <li>
              <Typography size="small">
                {localeStore.t('["edit-media"]["measurement-tutorial"]["ol-2"].first')}{" "}
                <Typography size="small600">
                  {localeStore.t('["edit-media"]["measurement-tutorial"]["ol-2"].bold')}
                </Typography>{" "}
                {localeStore.t('["edit-media"]["measurement-tutorial"]["ol-2"].last')}
              </Typography>
            </li>
            <li>
              <Typography size="small">
                {localeStore.t('["edit-media"]["measurement-tutorial"]["ol-3"]')}
              </Typography>
            </li>
            <li>
              <Typography size="small">
                <Typography size="small">
                  {localeStore.t('["edit-media"]["measurement-tutorial"]["ol-4"].first')}{" "}
                  <Typography size="small600">
                    {localeStore.t('["edit-media"]["measurement-tutorial"]["ol-4"].bold')}
                  </Typography>{" "}
                  {localeStore.t('["edit-media"]["measurement-tutorial"]["ol-4"].last')}
                </Typography>
              </Typography>
            </li>
          </ol>
        </div>
      </div>
    );
  },
);

export default MeasurementTutorial;
