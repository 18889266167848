import { MarketplaceType } from "./resize.types";

export const marketplacesArr: Array<MarketplaceType> = [
  {
    label: "Shopify Square",
    value: "shopify-square",
    size: "2048 x 2048",
    staticItem: "/marketplace-shopify.svg",
  },
  {
    label: "Shopify Portrait",
    value: "shopify-portrait",
    size: "1600 x 2000",
    staticItem: "/marketplace-shopify.svg",
  },
  {
    label: "Shopify Landscape",
    value: "shopify-landscape",
    size: "2000 x 1800",
    staticItem: "/marketplace-shopify.svg",
  },
  {
    label: "Poshmark",
    value: "poshmark",
    size: "1800 x 1800",
    staticItem: "/marketplace-poshmark.svg",
  },
  {
    label: "Etsy",
    value: "etsy",
    size: "2000 x 2000",
    staticItem: "/marketplace-etsy.svg",
  },
  {
    label: "Depop",
    value: "depop",
    size: "1280 x 1280",
    staticItem: "/marketplace-depop.svg",
  },
  {
    label: "Mercari",
    value: "mercari",
    size: "1080 x 1080",
    staticItem: "/marketplace-mercari.svg",
  },
  {
    label: "Amazon",
    value: "amazon",
    size: "2000 x 2000",
    staticItem: "/marketplace-amazon.svg",
  },
  {
    label: "Instagram Story",
    value: "instagram-story",
    size: "1080 x 1920",
    staticItem: "/social-instagram.svg",
  },
  {
    label: "Instagram Post",
    value: "instagram-post",
    size: "1080 x 1080",
    staticItem: "/social-instagram.svg",
  },
  {
    label: "Instagram Reel",
    value: "instagram-reel",
    size: "1080 x 1920",
    staticItem: "/social-instagram.svg",
  },
];
