import React from "react";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, useDidMount, useRerender, Webgl2New } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ScaleSelect } from "./scale-select";

import { ReactComponent as UndoSVG } from "src/external-ts/assets/images/undo-arrow-grey.svg";
import styles from "./control-buttons.module.css";

export type ControlButtonsProps = {
  renderer: Webgl2New.Webgl2dRenderer;
};

export const ControlButtons: React.FC<ControlButtonsProps> = observer(({ renderer }) => {
  const rerender = useRerender();
  const { localeStore } = useStores();

  const handleUndo = () => renderer.commands.undo();

  const handleRedo = () => renderer.commands.redo();

  useDidMount(() => {
    renderer.commands.events.on("history", rerender);
    return () => renderer.commands.events.off("history", rerender);
  });

  return (
    <div className={styles.container}>
      <ScaleSelect renderer={renderer} />

      <Button
        className={styles.undoBtn}
        appearance="primaryOutlined"
        onClick={handleUndo}
        disabled={!renderer.commands.canUndo}
        data-name="undo-button"
      >
        <SvgIcon icon={UndoSVG} />
        {localeStore.t('["ar-media"]["media-editor"]["control-buttons"].undo')}
      </Button>
      <Button
        className={styles.redoBtn}
        appearance="primaryOutlined"
        onClick={handleRedo}
        disabled={!renderer.commands.canRedo}
        data-name="redo-button"
      >
        {localeStore.t('["ar-media"]["media-editor"]["control-buttons"].redo')}
        <SvgIcon icon={UndoSVG} />
      </Button>
    </div>
  );
});

export default ControlButtons;
