import { ActionTypeDEPRECATED } from "@gemlightbox/core-kit";

import {
  PRODUCTS_PAGE,
  MEDIA_PAGE,
  CATALOG_PAGE,
  CREATE_PAGE,
  TRASH_BIN_PAGE,
  SETTINGS_PAGE,
  CAMERA_PAGE,
} from "src/constants";
import { NavbarItemType } from "./navbar.types";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { useStores } from "src/hooks";

import { ReactComponent as CreatePageSVG } from "src/external-ts/assets/images/navbar/create-page-grey.svg";
import { ReactComponent as CatalogPageSVG } from "src/external-ts/assets/images/navbar/catalog-page-grey.svg";
import { ReactComponent as MediaPageSVG } from "src/external-ts/assets/images/navbar/media-page.grey.svg";
import { ReactComponent as ProductPageSVG } from "src/external-ts/assets/images/navbar/product-page-grey.svg";
import { ReactComponent as ContactPageSVG } from "src/external-ts/assets/images/navbar/contact-page-grey.svg";
import { ReactComponent as LogoutSVG } from "src/external-ts/assets/images/navbar/logout-grey.svg";
import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/navbar/trash-grey.svg";
import { ReactComponent as ProfileSVG } from "src/external-ts/assets/images/profile-grey.svg";
import { ReactComponent as CameraPageSVG } from "src/external-ts/assets/images/navbar/camera-page-grey.svg";

import styles from "./navbar.module.css";

export const navbarItems: NavbarItemType[] = [
  {
    className: styles.navbarCreateItem,
    path: CREATE_PAGE.path,
    label: "Create",
    icon: CreatePageSVG,
    name: "create",
  },
  {
    path: PRODUCTS_PAGE.path,
    label: "Products",
    icon: ProductPageSVG,
    name: "products",
  },
  {
    path: MEDIA_PAGE.path,
    label: "Gallery",
    icon: MediaPageSVG,
    name: "gallery",
  },
  {
    path: CATALOG_PAGE.path,
    label: "Catalog",
    icon: CatalogPageSVG,
    name: "catalog",
  },
  {
    path: TRASH_BIN_PAGE.path,
    label: "Trash Bin",
    icon: TrashSVG,
    name: "trash-bin",
  },
];

export const mobileNavOptions: ActionTypeDEPRECATED[] = [
  {
    label: "Create",
    value: CREATE_PAGE.path,
    iconProps: {
      icon: CreatePageSVG,
    },
  },
  {
    label: "Contact Us",
    value: "https://picupmedia.com/contact-us",
    iconProps: {
      icon: ContactPageSVG,
    },
  },
  {
    label: "Log out",
    value: "",
    iconProps: {
      icon: LogoutSVG,
    },
  },
  {
    label: "Settings",
    value: SETTINGS_PAGE.path,
    iconProps: {
      icon: ProfileSVG,
    },
  },
  {
    label: "ORDER RETOUCHING",
    value: "https://picupmedia.com/dashboard",
  },
];

export const getNavbarItemsLocalized = () => {
  const { localeStore, userStore } = useStores();
  const showGemCam = window.$platform.isApp || userStore.hasAttemptedGemCamSetup;
  // 1. 通过GemCam设置链接登录的用户 2. Mac用户直接从App Store搜索GemLightbox应用程序并下载并登录
  if (showGemCam && navbarItems.every((item) => item.name !== "camera")) {
    navbarItems.splice(navbarItems.length - 1, 0, {
      path: CAMERA_PAGE.path,
      label: "Camera",
      icon: CameraPageSVG,
      name: "camera",
    });
  }
  return navbarItems.map((item) => {
    return {
      ...item,
      label: localeStore.t(`app.navbar["desktop-layout"].labels.${item.name}` as LocaleCodeTypes),
    };
  });
};
