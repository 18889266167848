import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { clsx, useDidMount, useDidUpdate, isPositive } from "@gemlightbox/core-kit";

import { getDiffInDays } from "src/common/helpers/subscriptions.helpers";
import { SupportedSubscriptionInterval } from "src/containers/settings/subscriptions/subscriptions.types";
import { useStores } from "src/hooks";
import { BillingExtraData } from "../billing-modal";
import { DaysRemainingPanel } from "../days-remaining-panel";
// import { FreeOffer, OfferFeatures, ProOffer, StarterOffer } from "./components";
import { FreeOffer, OfferFeatures, ProOffer } from "./components";

import styles from "./content.module.css";
import { additionalSubGbPriceMap } from "../../subscriptions.constants";

export type ContentProps = {
  proSubscriptionToSelect: SupportedSubscriptionInterval;
  activeSubaccountAmount: number;
  extraCount: number;
  isPaymentDisabled: boolean;
  setSubscriptionToSelect: (
    interval: SupportedSubscriptionInterval,
    plan: "starter" | "pro",
  ) => void;
  onDowngrade: VoidFunction;
  onProceed: (plan: "starter" | "pro", data: BillingExtraData) => void;
  className?: string;
  canDisplayAddons?: boolean;
  disableStarterUpgrade?: boolean;
  starterSubscriptionToSelect?: SupportedSubscriptionInterval;
  onOpenBillingFromProOffer?: VoidFunction;
};

export const Content: React.FC<ContentProps> = observer(
  ({
    proSubscriptionToSelect,
    starterSubscriptionToSelect,
    activeSubaccountAmount,
    extraCount,
    isPaymentDisabled,
    setSubscriptionToSelect,
    onDowngrade,
    onProceed,
    className = "",
    canDisplayAddons = true,
    // disableStarterUpgrade = false,
    onOpenBillingFromProOffer,
  }: ContentProps) => {
    const { subscriptionsStore, modalsStore, localeStore, userStore, notificationStore } =
      useStores();

    const [proSubscriptionExtraCount, setProSubscriptionExtraCount] = useState(
      subscriptionsStore.isProSubscription ? extraCount : 0,
    );
    const [starterSubscriptionExtraCount, setStarterSubscriptionExtraCount] = useState(
      subscriptionsStore.isStarterSubscription ? extraCount : 0,
    );

    const starterSubDiff =
      subscriptionsStore.subaccountsLimit -
      starterSubscriptionExtraCount +
      Number(subscriptionsStore.starterPlan?.metadata.subaccounts || 0);
    const proSubDiff =
      subscriptionsStore.subaccountsLimit -
      (proSubscriptionExtraCount + Number(subscriptionsStore.proPlan?.metadata.subaccounts || 0));

    useDidMount(() => subscriptionsStore.loadSubscriptionPlans());

    useDidUpdate(() => {
      if (subscriptionsStore.isStarterSubscription) setStarterSubscriptionExtraCount(extraCount);
      if (subscriptionsStore.isProSubscription) setProSubscriptionExtraCount(extraCount);
    }, [extraCount]);

    useDidUpdate(() => {
      handleCalculateCost();
    }, [proSubscriptionExtraCount, proSubscriptionToSelect]);

    const handleCalculateCost = () => {
      const additionalPriceId = additionalSubGbPriceMap[proSubscriptionToSelect];
      if (additionalPriceId && proSubDiff <= 0) {
        subscriptionsStore.calculateCost(additionalPriceId, proSubscriptionExtraCount);
      }
    };

    const handleProOfferOpenBilling = onOpenBillingFromProOffer
      ? onOpenBillingFromProOffer
      : () => {
          handleCalculateCost();
          modalsStore.open("BillingModal", {
            offer: "pro",
            subscriptionType: proSubscriptionToSelect,
            extraCountValue: proSubscriptionExtraCount,
            canDisplayAddons: canDisplayAddons,
            isPaymentDisabled: isPaymentDisabled,
            onProceed: (data) => onProceed("pro", data),
            onExtraCountChange: setProSubscriptionExtraCount,
          });
        };

    const handleStarterOfferOpenBilling = () => {
      modalsStore.open("BillingModal", {
        offer: "starter",
        subscriptionType: starterSubscriptionToSelect || "monthly",
        extraCountValue: starterSubscriptionExtraCount,
        canDisplayAddons: canDisplayAddons,
        isPaymentDisabled: isPaymentDisabled,
        onProceed: (data) => onProceed("starter", data),
        onExtraCountChange: setStarterSubscriptionExtraCount,
      });
    };

    const handleAreYouSureDowngrade = (offer: "pro" | "starter") => {
      const subDiff = offer === "pro" ? proSubDiff : starterSubDiff;

      notificationStore.open({
        title: localeStore.t('settings.subscriptions.components.content["downgrade-modal"].title'),
        message: `${localeStore.t(
          'settings.subscriptions.components.content["downgrade-modal"].message.first',
        )} ${subDiff} ${localeStore.t(
          'settings.subscriptions.components.content["downgrade-modal"].message.middle',
        )} ${subDiff * 50}GB ${localeStore.t(
          'settings.subscriptions.components.content["downgrade-modal"].message.last',
        )}.`,
        confirmText: localeStore.t(
          'settings.subscriptions.components.content["downgrade-modal"]["ok-text"]',
        ),
        cancelText: localeStore.t(
          'settings.subscriptions.components.content["downgrade-modal"]["cancel-text"]',
        ),
        onOk: offer === "starter" ? handleStarterOfferOpenBilling : handleProOfferOpenBilling,
      });
    };

    // const handleStarterOfferSelect = () => {
    //   if (subscriptionsStore.isProSubscription && starterSubDiff > 0) {
    //     handleAreYouSureDowngrade("starter");
    //   } else {
    //     handleStarterOfferOpenBilling();
    //   }
    // };

    const handleProOfferSelect = () => {
      if (subscriptionsStore.isStarterSubscription && proSubDiff > 0) {
        handleAreYouSureDowngrade("pro");
      } else {
        handleProOfferOpenBilling();
      }
    };

    const diffDays = getDiffInDays(subscriptionsStore.subscription);

    const shouldRenderDaysRemainingPanel =
      subscriptionsStore.subscription?.trial_end && isPositive(diffDays, true);

    return (
      <div className={clsx(styles.content, className)}>
        <div className={styles.offerContainer}>
          <div
            className={clsx(
              styles.left,
              shouldRenderDaysRemainingPanel ? styles.spaceBetween : styles.flexEnd,
            )}
          >
            {shouldRenderDaysRemainingPanel && <DaysRemainingPanel diffDays={diffDays} />}
            <OfferFeatures canDisplayAddons={canDisplayAddons} />
          </div>

          {userStore.isLogged && !userStore.isShopifyUser && (
            <FreeOffer
              className={styles.offer}
              onDowngrade={onDowngrade}
              canDisplayAddons={canDisplayAddons}
            />
          )}
          {/* starter offer left in comments in case it's needed to come back */}
          {/*<StarterOffer*/}
          {/*  className={styles.offer}*/}
          {/*  canDisplayAddons={canDisplayAddons}*/}
          {/*  subscriptionToSelect={starterSubscriptionToSelect || "monthly"}*/}
          {/*  activeSubaccountAmount={activeSubaccountAmount}*/}
          {/*  extraCount={starterSubscriptionExtraCount}*/}
          {/*  setSubscriptionToSelect={setSubscriptionToSelect}*/}
          {/*  setExtraCount={setStarterSubscriptionExtraCount}*/}
          {/*  onOpenBilling={handleStarterOfferSelect}*/}
          {/*  disableStarterUpgrade={disableStarterUpgrade}*/}
          {/*/>*/}
          <ProOffer
            className={styles.offer}
            canDisplayAddons={canDisplayAddons}
            subscriptionToSelect={proSubscriptionToSelect}
            activeSubaccountAmount={activeSubaccountAmount}
            extraCount={proSubscriptionExtraCount}
            setSubscriptionToSelect={setSubscriptionToSelect}
            setExtraCount={setProSubscriptionExtraCount}
            onOpenBilling={handleProOfferSelect}
          />
          {/*{userStore.isLogged && !userStore.isShopifyUser && !isCustomSubscription && <ThreeYearsOffer />}*/}
        </div>
      </div>
    );
  },
);

export default Content;
