import { SignupFormType } from "./signup.types";

export const signupInitialForm: SignupFormType = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  phone: {
    country: "US",
    phone: "",
  },
  company: "",
  website: "",
  acceptTerms: true,
};
