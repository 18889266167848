import React, { Fragment, useRef } from "react";
import { SvgIcon, Tooltip, TooltipProps, RefModel } from "@gemlightbox/core-kit";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-grey.svg";

import styles from "./with-success-tooltip.module.css";

export type WithSuccessTooltipChildrenProps = {
  targetRef: RefModel<HTMLElement>;
};

export type WithSuccessTooltipProps = Pick<TooltipProps, "isOpen" | "onClose" | "position"> & {
  children: React.FC<WithSuccessTooltipChildrenProps>;
  label: string;
};

export const WithSuccessTooltip: React.FC<WithSuccessTooltipProps> = ({
  children,
  isOpen,
  onClose,
  label,
  position = "top",
}) => {
  const targetRef = useRef<HTMLElement>(null);

  return (
    <Fragment>
      {children({ targetRef })}
      <Tooltip
        className={styles.content}
        position={position}
        offsetY={10}
        target={targetRef}
        onClose={onClose}
        isOpen={isOpen}
        withAngle
      >
        <div className={styles.checked}>
          <SvgIcon icon={CheckmarkSVG} size={[8, 8]} />
        </div>
        <div className={styles.label}>{label}</div>
      </Tooltip>
    </Fragment>
  );
};
