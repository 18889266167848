import React, { useRef, useState } from "react";
import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import {
  Modal,
  Button,
  Form,
  SvgIcon,
  Heading,
  Typography,
  FormField,
  useCall,
  isEmpty,
} from "@gemlightbox/core-kit";

import { postPaypalIntegrate } from "src/api";
import { useStores } from "src/hooks";
import { validateForRequired } from "src/utils";
import { connectPaypalModalForm } from "./connect-paypal-modal.constants";
import { ConnectPaypalModalProps, ConnectPaypalModalForm } from "./connect-paypal-modal.types";
import { connectPaypalModalValidation } from "./connect-paypal.utils";

import { ReactComponent as PaypalSVG } from "src/external-ts/assets/images/integrations/paypal.svg";

import styles from "./connect-paypal-modal.module.css";

export const ConnectPaypalModal: React.FC<ConnectPaypalModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed }: ConnectPaypalModalProps) => {
    const { localeStore, userStore } = useStores();

    const [isValidated, setIsValidated] = useState(false);

    const formRef = useRef<FormikProps<ConnectPaypalModalForm> | null>(null);

    const postPaypalIntegrateCall = useCall(postPaypalIntegrate);
    postPaypalIntegrateCall.onCallSuccess(async (data) => {
      const linkElement = document.createElement("a");
      linkElement.target = "_blank";
      linkElement.href = data.link;
      linkElement.click();

      await userStore.loadUserMeSilently();
      setClose();
    });

    const handleSubmit = (data: ConnectPaypalModalForm) => {
      if (isValidated) postPaypalIntegrateCall.submit({ data });
      setIsValidated(isEmpty(connectPaypalModalValidation(data)));
    };

    const handleCancel = () => {
      if (!isValidated) setClose();
      setIsValidated(false);
    };

    const isSubmitting = postPaypalIntegrateCall.submitting || userStore.loadingMeSilently;

    return (
      <Modal
        contentClassName={styles.connectPaypalModalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBackdropClose={isSubmitting}
        withCross={!isSubmitting}
        disableBorderRadius
        disablePadding
      >
        <Form
          contentClassName={styles.connectPaypalFormContent}
          initialValues={connectPaypalModalForm}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validate={connectPaypalModalValidation}
          disabled={isSubmitting}
        >
          <SvgIcon icon={PaypalSVG} size={111} />

          <Heading className={styles.title} tag="h2" color="textSecondary">
            {localeStore.t('integrations.paypal["connect-account"]')}
          </Heading>

          <Typography className={styles.description} size="small" color="textTertiary">
            {isValidated
              ? localeStore.t('integrations.paypal["connect-confirm-email"]')
              : localeStore.t('integrations.paypal["connect-description"]')}
          </Typography>

          {isValidated ? (
            <Heading className={styles.email} tag="h3">
              {formRef.current?.values.email}
            </Heading>
          ) : (
            <FormField
              className={styles.emailContainer}
              type="text"
              name="email"
              appearance="primaryV2"
              placeholder={localeStore.t('integrations.paypal["email-placeholder"]')}
              label={localeStore.t('integrations.paypal["email-label"]')}
              validate={validateForRequired}
            />
          )}

          <div className={styles.buttonsWrapper}>
            <Button appearance="tertiaryOutlined" onClick={handleCancel}>
              {localeStore.t("common.buttons.cancel")}
            </Button>
            <Button type="submit">
              {isValidated
                ? localeStore.t("common.buttons.confirm")
                : localeStore.t("common.buttons.continue")}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  },
);

export default ConnectPaypalModal;
