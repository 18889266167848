import { dashboardApiMiddleware } from "src/api";
import {
  PostCTFInstallRequestData,
  PostCTFInstallResponseData,
  GetCTFStatusResponse,
  PutCTFSyncRequest,
  PutCTFSyncResponse,
} from "./chow-tai-fook.interface";

export const postCTFInstall = dashboardApiMiddleware<
  PostCTFInstallResponseData,
  PostCTFInstallRequestData
>()({
  method: "POST",
  endpoint: "/api/chotaifook/install",
});

export const getCTFStatus = dashboardApiMiddleware<GetCTFStatusResponse>()({
  method: "GET",
  endpoint: "/api/chotaifook/status",
});

export const putCTFSync = dashboardApiMiddleware<PutCTFSyncResponse, PutCTFSyncRequest>()({
  method: "PUT",
  endpoint: "/api/chotaifook/sync",
});
