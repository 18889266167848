import React from "react";
import { observer } from "mobx-react-lite";
import { FormikProps } from "formik";
import { Form, FormField, Button, clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { FORM_ID } from "./profile-form.constants";
import { PersonalInfoType } from "../profile.types";
import { profileValidation } from "../profile.utils";

import styles from "./profile-form.module.css";

interface FormProps {
  initialValues: PersonalInfoType;
  onSubmit: (values: PersonalInfoType) => void;
  formInnerRef?: React.Ref<FormikProps<any>> | undefined;
}

export const ProfileForm: React.FC<FormProps> = observer(
  ({ initialValues, onSubmit, formInnerRef }) => {
    const { modalsStore, localeStore } = useStores();

    const handlePasswordChange = () => modalsStore.open("ChangePasswordModal", {});

    return (
      <Form
        className={styles.container}
        formId={FORM_ID}
        initialValues={initialValues}
        innerRef={formInnerRef}
        onSubmit={onSubmit}
        validate={profileValidation}
        enableReinitialize
        validateOnBlur
      >
        <div className={styles.fields}>
          <div className={styles.fieldRow}>
            <FormField
              className={styles.field}
              inputWrapperClassName={styles.fieldWrapper}
              appearance="primaryV2"
              name="firstName"
              label={localeStore.t('settings.profile["profile-form"].fields["first-name"].label')}
              type="text"
              required
              data-cy="first-name-field"
            />
            <FormField
              className={styles.field}
              inputWrapperClassName={styles.fieldWrapper}
              appearance="primaryV2"
              name="lastName"
              label={localeStore.t('settings.profile["profile-form"].fields["last-name"].label')}
              labelMessage={localeStore.t(
                'settings.profile["profile-form"].fields["last-name"]["label-message"]',
              )}
              type="text"
              required
              data-cy="last-name-field"
            />
          </div>
          <div className={styles.fieldRow}>
            <FormField
              className={clsx(styles.field, styles.emailField)}
              inputClassName={styles.emailInput}
              inputWrapperClassName={styles.fieldWrapper}
              appearance="primaryV2"
              name="email"
              label={localeStore.t('settings.profile["profile-form"].fields.email.label')}
              type="text"
              tooltipText={localeStore.t(
                'settings.profile["profile-form"].fields.email["tooltip-text"]',
              )}
              disabled
            />
            <FormField
              containerClassName={styles.field}
              phoneFieldClassName={styles.phoneInput}
              countrySelectClassName={styles.phoneCountryInput}
              type="phone"
              name="phone"
              label={localeStore.t('settings.profile["profile-form"].fields.phone.label')}
              placeholder={localeStore.t(
                'settings.profile["profile-form"].fields.phone.placeholder',
              )}
              required
              data-cy="phone-number-field"
            />
          </div>
          <div className={styles.fieldRow}>
            <FormField
              className={styles.field}
              inputWrapperClassName={styles.fieldWrapper}
              appearance="primaryV2"
              name="company"
              label={localeStore.t('settings.profile["profile-form"].fields.company.label')}
              type="text"
            />
            <FormField
              className={styles.field}
              inputWrapperClassName={styles.fieldWrapper}
              appearance="primaryV2"
              name="website"
              label={localeStore.t('settings.profile["profile-form"].fields.website.label')}
              type="text"
            />
          </div>
          <div className={styles.fieldRow}>
            <Button
              className={styles.changePassButton}
              appearance="secondaryOutlined"
              onClick={handlePasswordChange}
              data-cy="change-password-button"
            >
              {localeStore.t('settings.profile["profile-form"].buttons.change')}
            </Button>
          </div>
        </div>
      </Form>
    );
  },
);
