import { DNSFormErrorsType, DNSFormType } from "./first-step.types";

export const dnsFormValidation = (values: DNSFormType) => {
  const errors: DNSFormErrorsType = {};

  if (!values.subdomain) errors.subdomain = true;
  if (!values.domain) errors.domain = true;
  if (!values.topLevelDomain) errors.topLevelDomain = true;

  if (values.domain) {
    const valid = validateDomainName(
      `${values.subdomain}.${values.domain}${values.topLevelDomain}`,
    );
    if (!valid) {
      errors.subdomain = "Enter valid domain.";
      errors.domain = true;
      errors.topLevelDomain = true;
    }
  }

  return errors;
};

export const validateDomainName = (dn: string) => {
  const regEx = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
  return regEx.test(dn);
};
