import React from "react";
import { observer } from "mobx-react-lite";
import { Switch } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { AttributeTypeKeys } from "src/models";
import { SelectValues } from "./select-values";
import { WithTitle } from "../components";

export interface SelectSettingsProps {
  type: AttributeTypeKeys;
  values: string[];
  onTypeChange: (type: AttributeTypeKeys) => void;
  onValuesChange: (values: string[]) => void;
}

export const SelectSettings: React.FC<SelectSettingsProps> = observer(
  ({ type, values, onTypeChange, onValuesChange }) => {
    const { localeStore } = useStores();

    const handleTypeChange = () => {
      if (type === "select") onTypeChange("multiselect");
      else onTypeChange("select");
    };

    return (
      <>
        <WithTitle
          title={localeStore.t(
            'products.modals["create-attribute-sidebar"]["select-settings"].title',
          )}
        >
          <Switch
            data-cy="multiselect-switch"
            checked={type === "multiselect"}
            onChange={handleTypeChange}
          />
        </WithTitle>

        <SelectValues values={values} onValuesChange={onValuesChange} />
      </>
    );
  },
);
