import React from "react";
import { observer } from "mobx-react-lite";
import { TableNew } from "@gemlightbox/core-kit";

import { productVariantsStore } from "../product-variants.store";
import { VariantTableHead } from "./variant-table-head";
import { VariantTableRow } from "./variant-table-row";

import styles from "./variants-table.module.css";

export const VariantsTable: React.FC = observer(() => {
  return (
    <TableNew className={styles.variantsTableContainer}>
      <VariantTableHead />
      {productVariantsStore.variantList.map((variant) => (
        <VariantTableRow key={variant._id} variant={variant} />
      ))}
    </TableNew>
  );
});

export default VariantsTable;
