import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import { ModalTypesKeys } from "src/store/modals/modals.store.types";
import { modalsMap } from "src/store/modals/modals.store.constants";

export const ModalsProviderMobx: React.FC = observer(() => {
  const { modalsStore } = useStores();

  const handleCloseModal = (modal: ModalTypesKeys) => () => modalsStore.close(modal);

  const handleRemoveModal = (modal: ModalTypesKeys) => () => modalsStore.remove(modal);

  return (
    <>
      {Object.entries(modalsStore.modals).map(([_modal, [isOpen, options]]) => {
        const modal = _modal as ModalTypesKeys;
        const Component = modalsMap[modal];

        return (
          <Component
            key={modal}
            options={options as never}
            setClose={handleCloseModal(modal)}
            onFinalClosed={handleRemoveModal(modal)}
            isOpen={isOpen}
          />
        );
      })}
    </>
  );
});

export default ModalsProviderMobx;
