import { extendObservable, makeAutoObservable, runInAction, toJS } from "mobx";
import {
  ApiRequest,
  cloneDeep,
  ExtractApiResponse,
  isEqual,
  MaybeArray,
  Nullable,
  PAGINATION_OPTIONS,
  toArrayOptions,
} from "@gemlightbox/core-kit";

import {
  getCatalog,
  GetCatalogFilters,
  putUpdateCatalog,
  PostCreateCatalogData,
  deleteUnassignProductsFromCatalog,
  postAssignProductsToCatalog,
  patchCatalogProductOrder,
} from "src/api";
import { ProductModel } from "src/models";

import { ExtendedCatalogProduct, ExtendedCatalogFullModel } from "./catalog.store.types";
import { ExtendedProductModel } from "../products";

export class CatalogStore {
  private readonly _initialFilters: GetCatalogFilters = {
    limit: PAGINATION_OPTIONS[0].value,
    page: 1,
  };

  private _prevRequest: Nullable<ApiRequest<ExtractApiResponse<typeof getCatalog>>> = null;
  private _loading = false;
  private _orderModalOpenStatus = false;
  private _catalog: Nullable<ExtendedCatalogFullModel> = null;
  private _dragNDropList: Nullable<ExtendedCatalogProduct[]> = null;
  private _filters: GetCatalogFilters = this._initialFilters;

  public get loading() {
    return this._loading;
  }

  public get catalog() {
    return this._catalog;
  }

  public get products() {
    return this._catalog?.products.rows ?? [];
  }

  public get productsAmount() {
    return this._catalog?.products.rows.length ?? 0;
  }

  public get totalProductsAmount() {
    return this._catalog?.products.total_items ?? 0;
  }

  public get filteredProductsAmount() {
    return this._catalog?.products.filtered_items ?? 0;
  }

  public get selectedProductList() {
    return this._catalog?.products.rows.filter((product) => product.extended.selected) ?? [];
  }

  public get selectedDragNDropList() {
    return this._dragNDropList?.filter((product) => product.extended.selected) ?? [];
  }

  public get selectedProductAmount() {
    return this.selectedProductList.length;
  }

  public get selectedDragNDropAmount() {
    return this.selectedDragNDropList?.length;
  }

  public get canShowCatalog() {
    return !!this.productsAmount && !this.loading;
  }

  public get isAutoCatalog() {
    return !!this._catalog?.filter;
  }

  public get filters() {
    return this._filters;
  }

  public get totalPages() {
    return Math.ceil(this.filteredProductsAmount / this.filters.limit);
  }

  public get orderModalOpenStatus() {
    return this._orderModalOpenStatus;
  }

  public get dragNDropList() {
    return this._dragNDropList;
  }

  constructor() {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async loadCatalog(
    catalogId: number,
    filters?: GetCatalogFilters,
    type: "load" | "supplement" = "load",
  ) {
    // Note: If you try to load catalog with filters, and they are the same with
    // already stored one -> do nothing
    if (filters && isEqual(filters, this.filters)) return;

    if (this.loading && this._prevRequest) this._prevRequest.abort();

    runInAction(() => {
      if (type === "load") this._loading = true;
      if (filters) this.setFilters(filters);
    });

    const request = getCatalog.getRequest({
      params: { catalogId },
      queryParams: this._getRequestFilters(),
    });
    this._prevRequest = request;
    const { success, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      if (success) {
        if (type === "load") {
          this._catalog = {
            ...success,
            products: {
              ...success.products,
              rows: success.products.rows.map(this._extendProduct),
            },
          };
        }

        if (type === "supplement") {
          if (!this._catalog) return;
          const newProducts = success.products.rows.map(this._extendProduct);
          this._catalog.products.rows = this._catalog.products.rows.concat(newProducts);
        }

        this._loading = false;
      }
    });

    this._dragNDropList = cloneDeep(this.products);
  }

  public async updateCatalog(data: Partial<PostCreateCatalogData>) {
    if (!this._catalog) return;

    runInAction(() => {
      this._loading = true;
    });

    const { error, details } = await putUpdateCatalog
      .getRequest({
        params: { catalogId: this._catalog.id },
        data,
      })
      .fetch();

    if (error) return { error, details };

    this.loadCatalog(this._catalog.id);
  }

  public async assignProducts(productIds: MaybeArray<number>) {
    if (!this._catalog) return;
    const ids = toArrayOptions(productIds);

    runInAction(() => {
      this._loading = true;
    });

    const { error, details } = await postAssignProductsToCatalog
      .getRequest({
        params: { catalogId: this._catalog.id },
        queryParams: ids.toString(),
      })
      .fetch();

    if (error) return { error, details };

    this.loadCatalog(this._catalog.id);
  }

  public async unassignProducts(products: MaybeArray<ExtendedCatalogProduct>) {
    if (!this._catalog) return;

    const ids = toArrayOptions(products).map(({ _id }) => _id);

    runInAction(() => {
      this.toggleProductsList(products, "loading", true);
    });

    const { error, status, details } = await deleteUnassignProductsFromCatalog
      .getRequest({
        params: { catalogId: this._catalog.id },
        queryParams: ids.toString(),
      })
      .fetch();

    runInAction(() => {
      if (!this._catalog) return;

      if (status === "success") {
        ids.forEach((id) => {
          const index = this._catalog?.products.rows.findIndex(({ _id }) => _id === id);
          if (index !== undefined && index !== -1) {
            this._catalog?.products.rows.splice(index, 1);
          }
        });

        this._catalog.products.filtered_items -= ids.length;
      }

      this.toggleProductsList(products, "loading", false);
    });

    if (error) return { error, details };
  }

  async updateProductOrder(orderList: Array<[number, number]>) {
    if (!this._catalog) return;

    await patchCatalogProductOrder
      .getRequest({
        params: { catalogId: this._catalog.id },
        data: {
          order: orderList,
        },
      })
      .fetch();
  }

  public async updateCatalogOnScroll(
    catalogId: number,
    orderedCatalog: ExtendedProductModel[],
    filters?: GetCatalogFilters,
  ) {
    // Note: If you try to load catalog with filters, and they are the same with
    // already stored one -> do nothing
    if (filters && isEqual(filters, this.filters)) return;

    if (this.loading && this._prevRequest) this._prevRequest.abort();

    runInAction(() => {
      if (filters) this.setFilters(filters);
    });

    const request = getCatalog.getRequest({
      params: { catalogId },
      queryParams: this._getRequestFilters(),
    });
    this._prevRequest = request;
    const { success, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      if (success) {
        if (!this._dragNDropList) return;
        const newProducts = success.products.rows.map(this._extendProduct);
        this._dragNDropList = orderedCatalog?.concat(newProducts);
      }

      this._loading = false;
    });
  }

  /* Requests ↑ */

  /* UI State ↓ */
  public setFilters(filters: GetCatalogFilters) {
    this._filters = filters;
  }

  public resetFilters() {
    this._filters = this._initialFilters;
  }

  public resetDragNDrop() {
    this._filters = this._initialFilters;
    this._dragNDropList = cloneDeep(this.products);
  }

  public toggleProductsList(
    products: MaybeArray<ExtendedCatalogProduct>,
    field: "selected" | "loading",
    value?: boolean,
  ) {
    toArrayOptions(products).forEach((product) => {
      product.extended[field] = value ?? !product.extended[field];
    });
  }

  public selectDragNDropCard(products: MaybeArray<ExtendedCatalogProduct>, value?: boolean) {
    toArrayOptions(products).forEach((product) => {
      product.extended["selected"] = value ?? !product.extended["selected"];
    });
  }

  public selectAllProductList(field: "selected" | "loading") {
    this._catalog?.products.rows.forEach((item) => {
      item.extended[field] = true;
    });
  }

  public unselectAllProductList(field: "selected" | "loading") {
    this._catalog?.products.rows.forEach((item) => {
      item.extended[field] = false;
    });
  }

  public unselectAllDragNDropList() {
    this._dragNDropList?.forEach((item) => {
      item.extended["selected"] = false;
    });
  }

  public updateCatalogLinkPrivacyLocal(isPrivate: boolean) {
    if (!this._catalog) return;
    this._catalog.link.isPrivate = isPrivate;
  }

  public updateCatalogProductsLinkPrivacyLocal(isPrivate: boolean) {
    if (!this._catalog) return;
    this._catalog.products.rows.forEach((row) => {
      row.link.isPrivate = isPrivate;
    });
    this._catalog.link.isPrivate = isPrivate;
  }

  public updateProductsLocal(products: MaybeArray<ProductModel>) {
    toArrayOptions(products).forEach((product) => {
      if (!this._catalog) return;

      const index = this._catalog.products.rows.findIndex(({ _id }) => _id === product._id);

      if (index !== -1) {
        Object.assign(this._catalog.products.rows[index], product);
      }
    });
  }

  public toggleOrderModalOpenStatus() {
    this._orderModalOpenStatus = !this._orderModalOpenStatus;
  }

  /* UI State  ↑  */

  /* Helpers ↓ */
  private _getRequestFilters(): GetCatalogFilters {
    return toJS(this._filters);
  }

  private _extendProduct(product: ProductModel): ExtendedCatalogProduct {
    return extendObservable(product, {
      ...product,
      extended: { selected: false, loading: false },
    });
  }

  /* Helpers ↑ */
}
