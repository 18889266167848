import React from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, Button, Typography, ColorPicker } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import styles from "./color-picker-content.module.css";

export type AddColorContentProps = {
  color: string;
  onColorChange: (color: string) => void;
  onClose: VoidFunction;
  isEdit?: boolean;
  onAddColorClick?: (color: string) => void;
  onEditColorClick?: (color: string) => void;
};

export const ColorPickerContent: React.FC<AddColorContentProps> = observer(
  ({
    color,
    onColorChange,
    onClose,
    isEdit = false,
    onAddColorClick,
    onEditColorClick,
  }: AddColorContentProps) => {
    const { localeStore } = useStores();

    const titleText = isEdit
      ? '["edit-media"]["color-picker-content"]["edit-title"]'
      : '["edit-media"]["color-picker-content"]["add-title"]';

    const buttonText = isEdit
      ? '["edit-media"]["color-picker-content"]["edit-button"]'
      : '["edit-media"]["color-picker-content"]["add-button"]';

    const handleButtonClick = () => {
      isEdit ? onEditColorClick?.(color) : onAddColorClick?.(color);
    };

    return (
      <div className={styles.colorPickerContainer}>
        <div className={styles.colorPickerHeader}>
          <Typography size="extraSmall500">{localeStore.t(titleText)}</Typography>
          <Button appearance="secondaryGhost" onClick={onClose}>
            <SvgIcon icon={CrossSVG} size="small" />
          </Button>
        </div>
        <ColorPicker className={styles.colorPickerWrapper} onChange={onColorChange} color={color} />
        <Button appearance="primaryOutlined" onClick={handleButtonClick}>
          {localeStore.t(buttonText)}
        </Button>
      </div>
    );
  },
);

export default ColorPickerContent;
