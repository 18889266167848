import React from "react";
import styles from "./media-amount-card.module.css";

interface MediaAmountCardProps {
  label: string;
  value: string | number;
}

export const MediaAmountCard: React.FC<MediaAmountCardProps> = ({ label, value }) => {
  return (
    <div className={styles.container}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};
