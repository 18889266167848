import React from "react";
import { observer } from "mobx-react-lite";
import { CommandAction, Webgl2New } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { editMediaStore } from "../../../edit-media.store";
import { Layer } from "../layer";

import { ReactComponent as MeasureSVG } from "src/external-ts/assets/images/measure-grey.svg";

export const MeasurementLayer: React.FC = observer(() => {
  const {
    renderer,
    isInCrop,
    isInMeasurement,
    isInResize,
    measurementsArr,
    measurementsComponent,
  } = editMediaStore;
  const hasMeasurements = !!measurementsArr.length;

  const isHidden = measurementsComponent?.style.display === "none";
  const canControl = !isInMeasurement && hasMeasurements && !isInCrop && !isInResize;

  const { localeStore, notificationStore } = useStores();

  const handleMeasurementLayerClick = () => {
    if (isInMeasurement) return;
    editMediaStore.openMeasurementsTab();
  };

  const handleHideClick = () => {
    measurementsComponent.style.display = isHidden ? "initial" : "none";
  };

  const handleDeleteClick = () => {
    const actionMeasurements = [] as Webgl2New.MeasurementComponent[];

    const undoMeasurements = editMediaStore.measurementsArr;

    renderer.commands.execute(
      new CommandAction(
        "Delete measurement layer",
        () => {
          editMediaStore.measurementsArr = actionMeasurements;
          editMediaStore.measurementsComponent.setChildren(actionMeasurements);
          renderer.render();
        },
        () => {
          editMediaStore.measurementsArr = undoMeasurements;
          editMediaStore.measurementsComponent.setChildren(undoMeasurements);
          renderer.render();
        },
      ),
    );
  };

  const handleConfirmDelete = () => {
    notificationStore.open({
      title: localeStore.t(
        '["edit-media"].inspector.helpers.layers["measurement-layer"]["confirm-delete"]',
      ),
      confirmText: localeStore.t("common.buttons.delete"),
      cancelText: localeStore.t("common.buttons.cancel"),
      icon: "exclamation",
      onOk: handleDeleteClick,
    });
  };

  if (!isInMeasurement && !hasMeasurements) return null;

  return (
    <Layer
      name={localeStore.t('["edit-media"].inspector.helpers.layers["measurement-layer"].name')}
      layerPreview={MeasureSVG}
      onClick={handleMeasurementLayerClick}
      onHide={canControl ? handleHideClick : undefined}
      onDelete={canControl ? handleConfirmDelete : undefined}
      selected={isInMeasurement}
      selectable={canControl}
      isHidden={isHidden}
    />
  );
});

export default MeasurementLayer;
