import { isAndroid, isIPhone, isMac, isWindows } from "@gemlightbox/core-kit";

export const getPlatform = () => {
  let platform = "";

  if (typeof window.webkit?.messageHandlers?.PicupMediaNativeAPI?.postMessage !== "undefined") {
    platform = "MacApp";
  } else if (isMac()) {
    platform = "MacWeb";
  } else if (isIPhone()) {
    platform = "Ios";
  } else if (isAndroid()) {
    platform = "Android";
  } else if (isWindows()) {
    platform = "WinWeb";
  } else {
    platform = "WinApp";
  }

  return platform;
};

export const getVersion = () => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge|Edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera").replace("Edg", "Edge");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
};
