import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Select, SelectOption, FilterCollapse, Nullable } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";
import { mediaExistOptions } from "src/containers/media/media.constants";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export interface MediaExistFilterProps {
  value: Nullable<boolean>;
  onChange: (value: string) => void;
}

export const MediaExistFilter: React.FC<MediaExistFilterProps> = observer(({ value, onChange }) => {
  const { localeStore } = useStores();

  const handleChange = (option: SelectOption) => {
    const optionValue = option?.value;
    if (optionValue) onChange(optionValue.toString());
  };

  const filterValue = useMemo(() => {
    switch (value) {
      case true:
        return "with";
      case false:
        return "without";
      default:
        return "all";
    }
  }, [value]);
  return (
    <FilterCollapse
      data-cy="media-exist-filter-collapse"
      title={localeStore.t(
        'products["products-list"]["products-table"]["products-table-header"]["media-column"]',
      )}
    >
      <Select
        data-name={filterValue}
        selectedOptionsKeys={filterValue}
        options={mediaExistOptions.map((item) => {
          item.label = localeStore.t(
            `products["products-list"]["products-table"]["products-table-header"].mediaoptions["${item.value}"]` as LocaleCodeTypes,
          );
          return item;
        })}
        appearance="primaryV2"
        onChange={(option) => handleChange(option || ({} as SelectOption))}
        disableError
        disableClearing
        disableSingleOptionUncheck
      />
    </FilterCollapse>
  );
});
