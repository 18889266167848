import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Heading, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { SelectedFiltersPanel } from "../../../catalog-add-products/selected-filters-panel";
import { ProductsList } from "../../../catalog-add-products/products-list";

import styles from "./products-list-wrapper.module.css";

interface ProductsListWrapperProps {
  areFiltersEmpty: boolean;
  handleOpenFilters: () => Promise<void>;
}

export const ProductsListWrapper: React.FC<ProductsListWrapperProps> = observer(
  ({ areFiltersEmpty, handleOpenFilters }) => {
    const { productsStore, localeStore } = useStores();

    const emptyTitleText = areFiltersEmpty
      ? localeStore.t(
          '["create-catalog"]["create-auto-catalog"]["products-list-wrapper"]["products-empty"].title["empty-filters"]',
        )
      : localeStore.t(
          '["create-catalog"]["create-auto-catalog"]["products-list-wrapper"]["products-empty"].title["no-products"]',
        );

    const emptySubtitleText = `${localeStore.t(
      '["create-catalog"]["create-auto-catalog"]["products-list-wrapper"]["products-empty"].subtitle.first',
    )} ${
      areFiltersEmpty
        ? localeStore.t(
            '["create-catalog"]["create-auto-catalog"]["products-list-wrapper"]["products-empty"].subtitle.add',
          )
        : localeStore.t(
            '["create-catalog"]["create-auto-catalog"]["products-list-wrapper"]["products-empty"].subtitle.change',
          )
    } ${localeStore.t(
      '["create-catalog"]["create-auto-catalog"]["products-list-wrapper"]["products-empty"].subtitle.last',
    )}`;

    const emptyButtonText = areFiltersEmpty
      ? localeStore.t(
          '["create-catalog"]["create-auto-catalog"]["products-list-wrapper"]["products-empty"].buttons.add',
        )
      : localeStore.t(
          '["create-catalog"]["create-auto-catalog"]["products-list-wrapper"]["products-empty"].buttons.change',
        );

    return (
      <div className={styles.container}>
        <Heading tag="h4" color="textSecondary">
          {`${localeStore.t(
            '["create-catalog"]["create-auto-catalog"]["products-list-wrapper"].title',
          )} ${
            productsStore.productsAmount !== 0 && !areFiltersEmpty
              ? `(${productsStore.productsAmount})`
              : ""
          }`}
        </Heading>

        {productsStore.productsAmount === 0 || areFiltersEmpty ? (
          <div className={styles.emptyContainer}>
            <div className={styles.emptyTextContainer}>
              <Heading tag="h2" color="textSecondary">
                {emptyTitleText}
              </Heading>
              <Typography size="small" color="textTertiary">
                {emptySubtitleText}
              </Typography>
            </div>
            <Button
              className={styles.addFiltersButton}
              size="large"
              onClick={handleOpenFilters}
              data-cy="add-filters"
            >
              {emptyButtonText}
            </Button>
          </div>
        ) : (
          <>
            <SelectedFiltersPanel
              onOpenFilters={handleOpenFilters}
              onClearFilters={handleOpenFilters}
            />
            <ProductsList className={styles.createCatalogProducts} disableSelect />
          </>
        )}
      </div>
    );
  },
);
