import React from "react";
import { Button, memo, SvgIcon, clsx } from "@gemlightbox/core-kit";

import { ViewTypeKeys } from "./view-variant.types";

import { ReactComponent as TableViewIcon } from "src/external-ts/assets/images/table-view-icon-grey.svg";
import { ReactComponent as GridViewIcon } from "src/external-ts/assets/images/grid-view-icon-grey.svg";
import styles from "./view-variant.module.css";

export interface ViewVariantProps {
  type: ViewTypeKeys;
  onChange: (type: ViewTypeKeys) => void;
}

export const ViewVariant: React.FC<ViewVariantProps> = memo(({ type, onChange }) => {
  const isTable = type === "table";
  const isGrid = type === "grid";

  const handleChange = (type: ViewTypeKeys) => () => onChange(type);

  return (
    <div className={styles.container} data-cy="view-variant-selector">
      <Button
        className={clsx(styles.button, styles.tableButton, { [styles.active]: isTable })}
        appearance="unset"
        size="unset"
        onClick={handleChange("table")}
        data-cy="table-view-button"
      >
        <SvgIcon icon={TableViewIcon} />
      </Button>
      <Button
        className={clsx(styles.button, styles.gridButton, { [styles.active]: isGrid })}
        appearance="unset"
        size="unset"
        onClick={handleChange("grid")}
        data-cy="grid-view-button"
      >
        <SvgIcon icon={GridViewIcon} />
      </Button>
    </div>
  );
});
