import React from "react";
import { observer } from "mobx-react-lite";
import { Input, Nullable, Switch } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { AttributeTypes, AttributeTypesProps } from "./attribute-types";
import { AttributePrivacy, WithTitle } from "../components";
import { ErrorsType } from "../create-attribute-sidebar.types";

import styles from "./default-settings.module.css";

export interface DefaultSettingsProps {
  name: string;
  type: AttributeTypesProps["value"];
  required: boolean;
  isPublic: boolean;
  errors: Nullable<ErrorsType>;
  onNameChange: (value: string) => void;
  onTypeChange: AttributeTypesProps["onChange"];
  onRequiredChange: VoidFunction;
  onPrivacyChange: VoidFunction;
}

export const DefaultSettings: React.FC<DefaultSettingsProps> = observer(
  ({
    name,
    type,
    required,
    isPublic,
    errors,
    onNameChange,
    onTypeChange,
    onRequiredChange,
    onPrivacyChange,
  }) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.container}>
        <div className={styles.nameContainer}>
          <Input
            label={localeStore.t(
              'products.modals["create-attribute-sidebar"]["default-settings"].label',
            )}
            appearance="primaryV2"
            placeholder={localeStore.t(
              'products.modals["create-attribute-sidebar"]["default-settings"].placeholder',
            )}
            error={errors?.name}
            value={name}
            onChange={onNameChange}
            data-cy="attribute-name-field"
          />
        </div>

        <div className={styles.attributesContainer}>
          <AttributeTypes value={type} onChange={onTypeChange} />
          <WithTitle
            title={localeStore.t(
              'products.modals["create-attribute-sidebar"]["default-settings"].title',
            )}
          >
            <Switch data-cy="required-switch" checked={required} onChange={onRequiredChange} />
          </WithTitle>

          <AttributePrivacy isPrivate={!isPublic} onChange={onPrivacyChange} />
        </div>
      </div>
    );
  },
);
