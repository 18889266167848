import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Modal, SvgIcon, useBoolean, clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import threeYearsOfferPNG from "src/external-ts/assets/images/subscription/three-years-offer/three-years-offer.png";
import { ReactComponent as FirstSVG } from "src/external-ts/assets/images/subscription/three-years-offer/three-years-offer-1st.svg";
import { ReactComponent as SecondSVG } from "src/external-ts/assets/images/subscription/three-years-offer/three-years-offer-2nd.svg";
import { ReactComponent as ThirdSVG } from "src/external-ts/assets/images/subscription/three-years-offer/three-years-offer-3rd.svg";
import { ReactComponent as ArrowSVG } from "src/external-ts/assets/images/arrow-left-oblong-grey.svg";

import styles from "./three-years-offer.module.css";

export interface ThreeYearsOfferProps {
  className?: string;
}

export const ThreeYearsOffer: React.FC<ThreeYearsOfferProps> = observer(
  ({ className = "" }: ThreeYearsOfferProps) => {
    const { localeStore } = useStores();

    const isOpenBoolean = useBoolean(false);

    const handleRedirect = (url: string) => () => {
      isOpenBoolean.setFalsy();
      window.open(url, "_blank");
    };

    return (
      <>
        <div className={clsx(styles.threeYearsOffer, className)}>
          <img src={threeYearsOfferPNG} alt="" />
          <div className={styles.threeYearsOfferContent}>
            <span className={styles.title}>
              {localeStore.t(
                'settings.subscriptions.components.content["three-years-offer"].title.first',
              )}{" "}
              <span>
                $159{" "}
                {localeStore.t(
                  'settings.subscriptions.components.content["three-years-offer"].title.last',
                )}
              </span>
            </span>
            <Button onClick={isOpenBoolean.trigger}>
              {localeStore.t(
                'settings.subscriptions.components.content["three-years-offer"].buttons.start',
              )}
            </Button>
          </div>
        </div>
        <Modal
          contentClassName={styles.modalContent}
          setClose={isOpenBoolean.setFalsy}
          isOpen={isOpenBoolean.value}
          withCross
        >
          <div className={styles.modalTitle}>
            {localeStore.t(
              'settings.subscriptions.components.content["three-years-offer"]["modal-title"]',
            )}
          </div>
          <div className={styles.modalSubtitle}>
            {localeStore.t(
              'settings.subscriptions.components.content["three-years-offer"]["modal-subtitle"]',
            )}
          </div>
          <div className={styles.modalItemsWrapper}>
            <div
              className={styles.modalItem}
              onClick={handleRedirect("https://picupmedia.com/contact-us#hs-chat-open")}
            >
              <SvgIcon icon={FirstSVG} size={[56, 48]} />
              <div className={styles.modalItemText}>
                {localeStore.t(
                  'settings.subscriptions.components.content["three-years-offer"]["live-chat"]',
                )}
              </div>
              <SvgIcon className={styles.arrowSVG} icon={ArrowSVG} />
            </div>
            <div
              className={styles.modalItem}
              onClick={handleRedirect(
                "https://api.whatsapp.com/send/?phone=16469821297&text=Hi+Picup+Media%21%0D%0A%0D%0AI%27d+like+to+lea",
              )}
            >
              <SvgIcon icon={SecondSVG} size={48} />
              <div className={styles.modalItemText}>
                {localeStore.t(
                  'settings.subscriptions.components.content["three-years-offer"]["whatsapp-chat"]',
                )}
              </div>
            </div>
            <div
              className={styles.modalItem}
              onClick={handleRedirect("https://meetings.hubspot.com/kevin133/jewelry-hub-demo")}
            >
              <SvgIcon icon={ThirdSVG} size={[56, 48]} />
              <div className={styles.modalItemText}>
                {localeStore.t(
                  'settings.subscriptions.components.content["three-years-offer"]["book-meeting"]',
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  },
);

export default ThreeYearsOffer;
