import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { TableRow, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ProductsPreview } from "../../../../products-preview";

import styles from "./products-pending-upload-row.module.css";

type ProductsPendingUploadRowProps = {
  style?: React.CSSProperties;
};

export const ProductsPendingUploadRow: React.FC<ProductsPendingUploadRowProps> = observer(
  ({ style }) => {
    const { productsStore, modalsStore, localeStore } = useStores();

    const mediaPreviewCellRef = useRef<HTMLTableCellElement | null>(null);

    const handleRowClick = () => modalsStore.open("PendingUploadProductsSidebar", {});

    return (
      <TableRow className={styles.container} style={style} onClick={handleRowClick}>
        <div className={styles.titleContainerCell}>
          <Typography size="medium600" color="textSecondary">
            {localeStore.t('products["products-list"]["pending-upload"].title')}
          </Typography>
        </div>
        <div className={styles.mediaPreviewCell} ref={mediaPreviewCellRef}>
          <ProductsPreview
            className={styles.mediaPreview}
            view={"table"}
            totalAmount={productsStore.totalProductsPendingUploadAmount}
            productToShow={productsStore.productsPendingUploadList.slice(0, 4)}
          />
        </div>
      </TableRow>
    );
  },
);

export default ProductsPendingUploadRow;
