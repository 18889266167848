import { dashboardApiMiddleware } from "../api.middlewares";
import {
  DeleteTrashBinItemsRequestData,
  RecoverTrashBinItemsRequestData,
} from "./trash-bin.interface";

export const getTrashBinList = dashboardApiMiddleware()({
  method: "get",
  endpoint: "/api/recycle-bin",
});

export const deleteTrashBinItems = dashboardApiMiddleware<never, DeleteTrashBinItemsRequestData>()({
  method: "delete",
  endpoint: "/api/recycle-bin",
});

export const recoverTrashBinItems = dashboardApiMiddleware<
  never,
  RecoverTrashBinItemsRequestData
>()({
  method: "post",
  endpoint: "/api/recycle-bin/recover",
});
