import React, { useRef, useState } from "react";
import { Button, clsx, memo, useDidMount, useDidUnmount } from "@gemlightbox/core-kit";

import styles from "./promo-carousel.module.css";
import { generatePromoCarouselSlides } from "./promo-carousel.utils";

export const PromoCarousel: React.FC = memo(() => {
  const intervarRef = useRef<any>(null);
  const slides = generatePromoCarouselSlides();

  const [current, setCurrent] = useState(0);

  useDidMount(() => setupInterval());
  useDidUnmount(() => clearInterval(intervarRef.current));

  const setupInterval = () => {
    intervarRef.current = setInterval(
      () =>
        setCurrent((prev) => {
          const next = prev + 1;

          if (next > slides.length - 1) return 0;
          return next;
        }),
      2500,
    );
  };

  const handleSlide = (i: number) => {
    clearInterval(intervarRef.current);

    setCurrent(i);
    setupInterval();
  };

  return (
    <div className={styles.container}>
      <div className={styles.viewport}>
        {slides.map(({ render }, index) => (
          <React.Fragment key={index}>{render({ current: index === current })}</React.Fragment>
        ))}
      </div>

      <div className={styles.nav}>
        {slides.map((_, index) => (
          <Button
            key={index}
            className={clsx(styles.navButton, { [styles.current]: index === current })}
            appearance="unset"
            size="unset"
            onClick={() => handleSlide(index)}
          >
            &nbsp;
          </Button>
        ))}
      </div>
    </div>
  );
});
