import { makeAutoObservable } from "mobx";
import { ObjectType } from "@gemlightbox/core-kit";

import { ModalsProviderOptions, ModalTypesKeys, ModalPropsType } from "./modals.store.types";

export class ModalsStore {
  private _modals: ObjectType<ModalPropsType, ModalTypesKeys> = {} as any;

  public get modals() {
    return this._modals;
  }

  constructor() {
    makeAutoObservable(this);
  }

  public open<T extends ModalTypesKeys>(modal: T, props: ModalsProviderOptions<T>) {
    this._modals[modal] = [true, props];
  }

  public close<T extends ModalTypesKeys>(modal: T) {
    if (!this._modals?.[modal]?.[0]) {
      return console.warn("You are trying to close modal which not exist anymore");
    }
    this._modals[modal][0] = false;
  }

  public remove<T extends ModalTypesKeys>(modal: T) {
    delete this._modals[modal];
  }
}
