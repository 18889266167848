import React from "react";
import { observer } from "mobx-react-lite";
import {
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Button,
  useBoolean,
  copyToClipboard,
  SvgIcon,
} from "@gemlightbox/core-kit";

import { config } from "src/config/environment";
import { WithSuccessTooltip } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ExpandableStep } from "../expandable-step";

import { ReactComponent as CopySVG } from "src/external-ts/assets/images/copy-grey.svg";
import styles from "../steps-list.module.css";

export interface Step3Props {
  domain: string;
  completed: boolean;
  initialOpen: boolean;
  isOpen: boolean;
  onProceed: VoidFunction;
}

export const Step3: React.FC<Step3Props> = observer(
  ({ domain, completed, initialOpen, isOpen, onProceed }) => {
    const { localeStore } = useStores();

    const galleryCopied = useBoolean();
    const domainCopied = useBoolean();

    const galleryURL = config.galleryURL.split("/")[2];

    const handleCopyGalleryURL = async () => {
      await copyToClipboard(galleryURL);
      galleryCopied.setTruthy();
    };

    const handleCopyDomain = async () => {
      await copyToClipboard(domain);
      domainCopied.setTruthy();
    };

    return (
      <ExpandableStep
        title={localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-3"].title',
        )}
        checkboxLabel={localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-3"]["checkbox-label"]',
        )}
        checked={completed}
        initialOpen={initialOpen}
        isOpen={isOpen}
        onChange={onProceed}
      >
        <div className={styles.label}>
          {localeStore.t(
            'settings["dns-settings"]["second-step"]["steps-list"]["step-3"].subtitle',
          )}
        </div>

        <Table>
          <Thead>
            <Tr>
              <Th className={styles.type}>Type</Th>
              <Th className={styles.host}>Host(Name)</Th>
              <Th className={styles.value}>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td> CNAME </Td>
              <Td>
                <div className={styles.cell}>
                  <WithSuccessTooltip
                    isOpen={domainCopied.value}
                    position="bottom"
                    label={localeStore.t(
                      'settings["dns-settings"]["second-step"]["steps-list"]["success-tooltip-label"]',
                    )}
                    onClose={domainCopied.setValue}
                  >
                    {({ targetRef }) => (
                      <div
                        className={styles.host}
                        ref={targetRef as React.ForwardedRef<HTMLDivElement>}
                      >
                        {domain}
                      </div>
                    )}
                  </WithSuccessTooltip>
                  <Button appearance="secondaryOutlined" onClick={handleCopyDomain}>
                    <SvgIcon icon={CopySVG} />
                    {localeStore.t(
                      'settings["dns-settings"]["second-step"]["steps-list"].buttons.copy',
                    )}
                  </Button>
                </div>
              </Td>
              <Td>
                <div className={styles.cell}>
                  <WithSuccessTooltip
                    isOpen={galleryCopied.value}
                    position="bottom"
                    label="Link successfully copied!"
                    onClose={galleryCopied.setValue}
                  >
                    {({ targetRef }) => (
                      <div
                        className={styles.result}
                        ref={targetRef as React.ForwardedRef<HTMLDivElement>}
                      >
                        {galleryURL}
                      </div>
                    )}
                  </WithSuccessTooltip>
                  <Button appearance="secondaryOutlined" onClick={handleCopyGalleryURL}>
                    <SvgIcon icon={CopySVG} />
                    {localeStore.t(
                      'settings["dns-settings"]["second-step"]["steps-list"].buttons.copy',
                    )}
                  </Button>
                </div>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </ExpandableStep>
    );
  },
);
