import { PickUnion, SelectOption } from "@gemlightbox/core-kit";

import { MediaType, MediaTypeKeys } from "src/models";
import { MediaTypeNames } from "src/containers/media";

export const maxSizeInGb = 1;

export const mediaTypeOptions: SelectOption<PickUnion<MediaTypeKeys, "video" | "video360">>[] = [
  { label: MediaTypeNames.video, value: MediaType.video, name: `save-as-${MediaType.video}` },
  {
    label: MediaTypeNames.video360,
    value: MediaType.video360,
    name: `save-as-${MediaType.video360}`,
  },
];
