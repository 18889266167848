import React from "react";
import { observer } from "mobx-react-lite";

import { Button, SvgIcon, UseBooleanReturnType, clsx } from "@gemlightbox/core-kit";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { ReactComponent as CloseSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import { ReactComponent as BrowserUpgradeSVG } from "src/external-ts/assets/images/camera/browser-upgrade-dialog.svg";

import styles from "./browser-upgrade-modal.module.css";
import { useStores } from "src/hooks";

const BootstrapDialog = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    background: "linear-gradient(180deg, #9BE7EB 0%, #FFF 46.5%)",
    borderRadius: "16px",
  },
  ".MuiDialog-paper": {
    width: "324px",
  },
}));

type BrowserUpgradeModalProps = {
  visible: UseBooleanReturnType;
};

export const BrowserUpgradeModal: React.FC<BrowserUpgradeModalProps> = observer(({ visible }) => {
  const { localeStore } = useStores();

  return (
    <BootstrapDialog open={visible.value} maxWidth="xs">
      <DialogTitle className={styles.bluetoothDialogHeader}>
        <SvgIcon
          className={styles.closeButton}
          icon={CloseSVG}
          size={24}
          onClick={visible.setFalsy}
        />

        <SvgIcon className={styles.browserIcon} icon={BrowserUpgradeSVG} size={56} />
      </DialogTitle>
      <DialogContent>
        <div className={clsx(styles.dialogDesc, styles.mt20)}>
          {localeStore.t('camera["browser-update-tips"]')}
        </div>

        <div className={styles.actionBox}>
          <Button appearance="primary" className={styles.confirmBtn} onClick={visible.setFalsy}>
            {localeStore.t('camera["confirm-text"]')}
          </Button>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
});
