import React from "react";
import "./index.scss";
import Loader from "../Loader";

const OverlayLoader = () => (
  <div className="overlay-loader">
    <div className="backdrop" />
    <Loader />
  </div>
);

export default OverlayLoader;
