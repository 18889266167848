import React from "react";
import { observer } from "mobx-react-lite";
import { ProgressLine, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./loading-view.module.css";

export interface LoadingViewProps {
  progress: number;
}

export const LoadingView: React.FC<LoadingViewProps> = observer(({ progress }) => {
  const { localeStore } = useStores();

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <Typography className={styles.title} size="small" color="textSecondary">
          {localeStore.t('["products-import"].modals["finish-import-modal"]["loading-view"].title')}
        </Typography>
        <Typography size="extraSmall500" color="textPrimary">
          {progress}%
        </Typography>
      </div>

      <ProgressLine progress={progress} />
    </div>
  );
});
