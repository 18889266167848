import React from "react";
import { observer } from "mobx-react-lite";
import { clsx, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./catalog-type-tag.module.css";

interface CatalogTypeTagProps {
  type: "manual" | "automatic";
}

export const CatalogTypeTag: React.FC<CatalogTypeTagProps> = observer(({ type }) => {
  const { localeStore } = useStores();

  return (
    <Typography className={clsx(styles.container, styles[type])} size="extraSmall500">
      {type === "automatic"
        ? localeStore.t('components.client["catalog-type-tag"].automatic')
        : localeStore.t('components.client["catalog-type-tag"].manual')}
    </Typography>
  );
});
