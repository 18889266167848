import { makeAutoObservable, runInAction } from "mobx";
import { pushDataLayerEvent } from "../entities";

class ErrorCatch {
  private _errorType: "error" | "network" | "unhandledrejection" | "none";
  public get errorType() {
    return this._errorType;
  }

  constructor() {
    makeAutoObservable(this);

    // TODO: This method has flaws
    // catch browser crash
    // window.addEventListener("load", function () {
    //   sessionStorage.setItem("good_exit", "pending");
    // });

    // window.addEventListener("beforeunload", function () {
    //   sessionStorage.setItem("good_exit", "true");
    // });

    // if (sessionStorage.getItem("good_exit") && sessionStorage.getItem("good_exit") !== "true") {
    //   appCallbackStore.webviewNotification({ type: 2, needReloadMain: true });
    //   sessionStorage.setItem("good_exit", "true");
    // }
  }

  public init() {
    this.onerror();
    this.unhandledrejection();
    this.network();
  }

  // catch network error
  public network(online?: () => void, offline?: () => void) {
    window.addEventListener("offline", () => {
      runInAction(() => {
        this._errorType = "network";
      });
      typeof offline === "function" && offline();
    });

    window.addEventListener("online", () => {
      runInAction(() => {
        this._errorType = "none";
      });
      typeof online === "function" && online();
    });
  }

  // global errro catch & log
  public onerror(cb?: () => void) {
    window.onerror = (message, source, lineno, colno, error) => {
      pushDataLayerEvent({
        event: "gemhub:javascript_error:uncaught_exception",
        event_params: { error_message: { message, source, lineno, colno, stack: error?.stack } },
      });
      runInAction(() => {
        this._errorType = "error";
      });
      typeof cb === "function" && cb();
      console.warn(message, "(catched by onerror)");
      return true;
    };
  }

  // unhandledrejection errro catch & log
  public unhandledrejection(cb?: () => void) {
    window.addEventListener("unhandledrejection", async (e) => {
      pushDataLayerEvent({
        event: "gemhub:javascript_error:unhandled_rejection",
        event_params: { reason: e.reason instanceof Error ? e.reason.message : e.reason },
      });
      runInAction(() => {
        this._errorType = "unhandledrejection";
      });
      typeof cb === "function" && cb();
      e.preventDefault();
      console.warn(`${e.type}: ${e.reason}`);
      return true;
    });
  }
}

export const errorCatchSotre = new ErrorCatch();
