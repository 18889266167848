import React from "react";
import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";

import { SupportedSubscriptionInterval } from "src/containers/settings/subscriptions/subscriptions.types";
import { useStores } from "src/hooks";
import { YourPlanBadge } from "../your-plan-badge";

import styles from "./interval-choice-picker.module.css";

interface IntervalChoicePickerProps {
  subscriptionToSelect: "monthly" | "yearly" | "lifetime";
  offer?: "starter" | "pro";
  extraCount: number;
  isMonthlySubscription: boolean;
  isYearlySubscription: boolean;
  isCustomSubscription: boolean;
  planPrices: {
    monthlyPrice: number;
    yearlyPrice: number;
  };
  onSelectPlan: (type: SupportedSubscriptionInterval, disable?: boolean) => () => void;
  disableStarterUpgrade?: boolean;
}

export const IntervalChoicePicker: React.FC<IntervalChoicePickerProps> = observer(
  ({
    subscriptionToSelect,
    offer = "pro",
    extraCount,
    isMonthlySubscription,
    isYearlySubscription,
    isCustomSubscription,
    planPrices,
    onSelectPlan,
    disableStarterUpgrade,
  }) => {
    const { localeStore, subscriptionsStore } = useStores();
    const extraPriceYr = extraCount * subscriptionsStore.subaccountAddonPrices.yearlyPrice;
    const extraPriceMo = extraCount * subscriptionsStore.subaccountAddonPrices.monthlyPrice;
    const yearlyText =
      offer === "pro"
        ? `US$${planPrices.yearlyPrice + extraPriceYr}/yr`
        : `US$${planPrices.yearlyPrice / 12 + extraPriceMo}/mo`;

    return (
      <div className={styles.offerButtonsWrapper}>
        <div className={styles.choiceButtonWrapper}>
          {isMonthlySubscription && <YourPlanBadge />}
          <button
            className={clsx(styles.choiceButton, styles[offer], {
              [styles.checked]: subscriptionToSelect === "monthly",
            })}
            onClick={onSelectPlan("monthly")}
            disabled={isCustomSubscription || disableStarterUpgrade}
          >
            <span className={styles.choiceButtonText}>
              {localeStore.t(
                'settings.subscriptions.components.content["subscription-types"].monthly',
              )}
            </span>
            <span className={styles.choiceButtonPrice}>
              US${planPrices.monthlyPrice + extraPriceMo}/mo
            </span>
          </button>
        </div>
        <div className={styles.choiceButtonWrapper}>
          {isYearlySubscription && <YourPlanBadge />}
          <button
            className={clsx(styles.choiceButton, styles[offer], {
              [styles.checked]: subscriptionToSelect === "yearly",
            })}
            onClick={onSelectPlan("yearly")}
            disabled={isCustomSubscription || disableStarterUpgrade}
          >
            <span className={styles.choiceButtonText}>
              {localeStore.t(
                'settings.subscriptions.components.content["subscription-types"].yearly',
              )}
            </span>
            <span className={styles.choiceButtonPrice}>{yearlyText}</span>
          </button>
        </div>
      </div>
    );
  },
);
