import React, { memo } from "react";
import {
  clsx,
  Typography,
  SvgIcon,
  Button,
  SvgIconIconType,
  RefModel,
} from "@gemlightbox/core-kit";

import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as EyeSVG } from "src/external-ts/assets/images/eye-grey.svg";
import { ReactComponent as EyeCrossedSVG } from "src/external-ts/assets/images/eye-crossed-grey.svg";
import styles from "./layer.module.css";

export type LayerProps = {
  name: string;
  selectable: boolean;
  previewClassName?: string;
  selected?: boolean;
  isHidden?: boolean;
  borderPreview?: boolean;
  layerPreview?: SvgIconIconType;
  layerPreviewRef?: RefModel<HTMLDivElement>;
  onClick?: VoidFunction;
  onHide?: VoidFunction;
  onDelete?: VoidFunction;
};

export const Layer: React.FC<LayerProps> = memo(
  ({
    name,
    selectable,
    selected = false,
    isHidden = false,
    borderPreview = false,
    layerPreview,
    layerPreviewRef,
    onClick,
    onHide,
    onDelete,
  }: LayerProps) => {
    const handleClick = () => selectable && !selected && onClick?.();

    const handleHide = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onHide?.();
    };

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onDelete?.();
    };

    const layerContainerStyles = clsx(
      styles.layerContainer,
      { [styles.selected]: selected },
      { [styles.selectable]: selectable },
      { [styles.borderPreview]: borderPreview },
    );

    return (
      <div className={layerContainerStyles} onClick={handleClick}>
        <div className={styles.layerPreview} ref={layerPreviewRef}>
          {layerPreview && <SvgIcon icon={layerPreview} size={28} />}
        </div>
        <Typography className={styles.layerName} size="small">
          {name}
        </Typography>
        {onHide && (
          <Button appearance="secondaryGhost" onClick={handleHide}>
            <SvgIcon icon={isHidden ? EyeCrossedSVG : EyeSVG} />
          </Button>
        )}
        {onDelete && (
          <Button appearance="errorGhost" onClick={handleDelete}>
            <SvgIcon icon={TrashSVG} />
          </Button>
        )}
      </div>
    );
  },
);

export default Layer;
