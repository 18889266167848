import React from "react";
import { PaymentIntent, Stripe } from "@stripe/stripe-js";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  Image,
  Heading,
  Typography,
  Button,
  clsx,
  globalStyles,
  useScrollDetect,
  useWindowSize,
  useDidMount,
  SvgIcon,
  useCall,
  ApiRequestErrorResponse,
  useFetch,
  useBoolean,
  useQuery,
} from "@gemlightbox/core-kit";

import {
  deviceSubscription,
  getUserTagManagerInfo,
  getUserTagManagerInfoCallback,
  patchSubscription,
  postSubscription,
  PostSubscriptionCardData,
  putSubscriptionAdditional,
} from "src/api";
import { Logo, LanguageSelect } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { getStaticUrl, pushDataLayerEvent } from "src/utils";
import { DEFAULT_PAGE, ErrorCodes } from "src/constants";
import { BillingExtraData } from "src/containers/settings/subscriptions/components";
import {
  proPlanId,
  additionalSubGbAddonMonthlyPriceId,
  proActivatePlanMonthlyPriceId,
} from "src/containers/settings/subscriptions/subscriptions.constants";

import { ReactComponent as SuccessSVG } from "src/external-ts/assets/images/subscription/success-prompt.svg";
import styles from "./activate.page.module.css";

export const ActivatePage: React.FC = observer(() => {
  const { localeStore, modalsStore, subscriptionsStore, notificationStore } = useStores();
  const { isFreeSubscription, planInterval, currentPlan } = subscriptionsStore;

  const { ref, scrollable } = useScrollDetect<HTMLDivElement>();
  const { windowWidth } = useWindowSize();
  const isTabletVertical = windowWidth < 769;

  const navigate = useNavigate();

  const {
    query: { bluetooth_name },
  } = useQuery();

  const useUserGoogleTagFetch = useFetch(getUserTagManagerInfo);

  const useLoadingBoolean = useBoolean(false);

  const handlePaymentError = (message: string) => {
    const title = message || localeStore.t('common.warnings["something-wrong"]');

    notificationStore.open({
      title,
      confirmAppearance: "secondary",
      confirmText: localeStore.t('common.buttons["try-again"]'),
      cancelText: "",
      icon: "exclamation",
      onlyConfirm: true,
    });
  };

  const handlePaymentSuccess = () => {
    getUserTagManagerInfoCallback((response) => {
      pushDataLayerEvent({
        event: "upgrade_completed",
        event_params: {
          user_id: response.user_id,
          prev_account_type: useUserGoogleTagFetch.payload?.account_type ?? "couldn't get data",
          prev_paid_plan: useUserGoogleTagFetch.payload?.current_paid_plan ?? "couldn't get data",
          new_account_type: response?.account_type ?? "couldn't get data",
          new_paid_plan: response?.current_paid_plan ?? "couldn't get data",
          is_trial: response?.isTrial ?? "couldn't get data",
        },
      });
    });

    useLoadingBoolean.setFalsy();

    subscriptionsStore.loadSubscriptionPlans();
    subscriptionsStore.loadSubscription();

    notificationStore.open({
      title: localeStore.t('settings.subscriptions["payment-modal-success"].title'),
      message: localeStore.t('activate["success-activation"].message'),
      confirmAppearance: "primary",
      confirmText: localeStore.t('common.buttons["to-dashboard"]'),
      cancelText: "",
      icon: <SvgIcon size={195} icon={SuccessSVG} />,
      onlyConfirm: true,
      onFinal: () => navigate(DEFAULT_PAGE.path),
    });
  };

  const usePostSubscription = useCall(postSubscription);
  usePostSubscription.onCallSuccess(handlePaymentSuccess);

  const usePutSubscription = useCall(putSubscriptionAdditional);
  usePutSubscription.onCallSuccess(handlePaymentSuccess);

  const usePatchSubscription = useCall(patchSubscription);
  usePatchSubscription.onCallSuccess(handlePaymentSuccess);

  const useDeviceSubscription = useCall(deviceSubscription);
  useDeviceSubscription.onCallSuccess(handlePaymentSuccess);

  const handleConfirmPayment = (stripe: Stripe, onSuccess?: (res: PaymentIntent) => void) => {
    return (error: ApiRequestErrorResponse) => {
      const { originalError, formattedMessage } = error;

      if (originalError?.code === ErrorCodes.SUBSCRIPTION_REQUIRE_ACTION) {
        stripe.confirmCardPayment(originalError.payload.client_secret).then((res) => {
          if (res?.paymentIntent?.status === "succeeded") {
            onSuccess?.(res.paymentIntent);
          }
          if (res?.error) {
            const errorMessage = res.error.message || formattedMessage;

            getUserTagManagerInfoCallback((response) => {
              pushDataLayerEvent({
                event: "upgrade_failed",
                event_params: {
                  user_id: response.user_id,
                  prev_account_type:
                    useUserGoogleTagFetch.payload?.account_type ?? "couldn't get data",
                  prev_paid_plan:
                    useUserGoogleTagFetch.payload?.current_paid_plan ?? "couldn't get data",
                  new_account_type: response?.account_type ?? "couldn't get data",
                  new_paid_plan: response?.current_paid_plan ?? "couldn't get data",
                  is_trial: response?.isTrial ?? "couldn't get data",
                  source_platform: errorMessage,
                },
              });
            });

            handlePaymentError(errorMessage);
            useLoadingBoolean.setFalsy();
          }
        });

        return;
      }

      getUserTagManagerInfoCallback((response) => {
        pushDataLayerEvent({
          event: "upgrade_failed",
          event_params: {
            user_id: response.user_id,
            prev_account_type: useUserGoogleTagFetch.payload?.account_type ?? "couldn't get data",
            prev_paid_plan: useUserGoogleTagFetch.payload?.current_paid_plan ?? "couldn't get data",
            new_account_type: response?.account_type ?? "couldn't get data",
            new_paid_plan: response?.current_paid_plan ?? "couldn't get data",
            is_trial: response?.isTrial ?? "couldn't get data",
            source_platform: formattedMessage,
          },
        });
      });

      handlePaymentError(formattedMessage);
      useLoadingBoolean.setFalsy();
    };
  };

  const handleSubmit = async (card: string, stripe: Stripe, billingExtraData: BillingExtraData) => {
    useLoadingBoolean.setTruthy();

    usePostSubscription.onCallError(handleConfirmPayment(stripe, handlePaymentSuccess));
    usePatchSubscription.onCallError(handleConfirmPayment(stripe, handlePaymentSuccess));
    useDeviceSubscription.onCallError(handleConfirmPayment(stripe, handlePaymentSuccess));

    const coupon = billingExtraData.coupon;
    const addonsIds = billingExtraData.extraCount;
    const bluetoothName = billingExtraData.bluetoothName;
    const priceId = proActivatePlanMonthlyPriceId;

    const queryParams = `?prices=${JSON.stringify([
      [additionalSubGbAddonMonthlyPriceId, addonsIds],
    ])}`;

    const data: PostSubscriptionCardData = { card, coupon };

    if (bluetoothName) {
      useDeviceSubscription.submit({
        params: { bluetoothName },
        data: { card },
      });

      return;
    }

    // You have no subscription and you buy one ->
    if (isFreeSubscription) {
      usePostSubscription.submit({
        params: { priceId },
        data,
        queryParams,
      });

      return;
    }

    // You already have subscription, and you only buy extra addons
    if (planInterval === "monthly" && proPlanId === currentPlan?.plan_id) {
      usePutSubscription.onCallError(handleConfirmPayment(stripe, handlePaymentSuccess));

      await usePutSubscription.submit({
        data,
        queryParams: `?priceID=${additionalSubGbAddonMonthlyPriceId}&quantity=${addonsIds}`,
      });
    } else {
      // You already have subscription, and you change to another plan & addons
      if (addonsIds === 0 && proPlanId === currentPlan?.plan_id) {
        await putSubscriptionAdditional
          .getRequest({
            data,
            queryParams: `?priceID=${additionalSubGbAddonMonthlyPriceId}&quantity=${addonsIds}`,
          })
          .fetch();
      }

      await usePatchSubscription.submit({
        params: { priceId },
        data,
        queryParams,
      });
    }
  };

  const handleProceedToPayment = (billingExtraData: BillingExtraData) => {
    modalsStore.close("BillingModal");

    modalsStore.open("AddCardModal", {
      solelyActivationPurpose: true,
      onAddCardSuccess: ({ card, stripe }) => handleSubmit(card, stripe, billingExtraData),
    });
  };

  const handleActivateClick = () => {
    modalsStore.open("BillingModal", {
      offer: "pro",
      subscriptionType: "monthly",
      extraCountValue: 0,
      canDisplayAddons: true,
      promoCodeRequired: true,
      activateProPlanPage: true,
      onProceed: handleProceedToPayment,
    });
  };

  useDidMount(() => subscriptionsStore.loadSubscriptionPlans());

  const textContentWrapperStyles = clsx(
    styles.activationTextContentWrapper,
    { [styles.scrollable]: scrollable },
    globalStyles.addScrollStyles,
  );

  return (
    <div className={styles.activationPageContainer}>
      <div className={styles.activationLeftContainer}>
        <Logo className={styles.activationLogo} />

        <div className={styles.activationContent}>
          <div className={textContentWrapperStyles} ref={ref}>
            <Heading className={styles.activationHeader} tag={isTabletVertical ? "h3" : "h2"}>
              {localeStore.t("activate.title")}
            </Heading>

            <Typography className={styles.toActivate} size="medium">
              {localeStore.t('activate["activate-your"]')}{" "}
              <Typography size="medium600">{localeStore.t('activate["twelve-months"]')}</Typography>{" "}
              Pro {localeStore.t("activate.subscription")}
            </Typography>

            <ol className={styles.listWrapper}>
              {!bluetooth_name && (
                <li>
                  <Typography size="big" className={styles.listItem}>
                    {localeStore.t("activate.steps.one")}
                  </Typography>
                </li>
              )}
              <li>
                <Typography size="big" className={styles.listItem}>
                  {localeStore.t("activate.steps.two")}
                </Typography>
              </li>
              <li>
                <Typography size="big" className={styles.listItem}>
                  {localeStore.t("activate.steps.three")}
                </Typography>
              </li>
            </ol>

            <Typography className={styles.support} size="small">
              {localeStore.t("activate.support.message")}{" "}
              <a href="mailto: support@picupmedia.com">
                <Typography className={styles.supportLink} color="primary">
                  {localeStore.t("activate.support.email")}{" "}
                </Typography>
              </a>
            </Typography>
          </div>

          <div className={styles.language}>
            <Button onClick={handleActivateClick} loading={useLoadingBoolean.value}>
              {localeStore.t("activate.buttons.activate")}
              {windowWidth > 375 && ` ${localeStore.t('activate.buttons["pro-plan"]')}`}
            </Button>
            <LanguageSelect />
          </div>
        </div>
      </div>
      <div className={styles.activationRightContainer}>
        <div className={styles.gemlightboxImgWrapper}>
          <Image
            className={styles.gemlightboxImg}
            src={getStaticUrl("/activate-gemlightbox.png")}
          />
          <Image className={styles.brilliantsImg} src={getStaticUrl("/activate-brilliants.png")} />
        </div>
      </div>
    </div>
  );
});

export default ActivatePage;
