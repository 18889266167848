import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { clsx, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { GlobalErrorsMapModel } from "src/models";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./invalid-view.module.css";
import { Button } from "@gemlightbox/core-kit";

export interface InvalidViewProps {
  totalCount: number;
  invalidCount: number;
  errors: GlobalErrorsMapModel;
}

export const InvalidView: React.FC<InvalidViewProps> = observer(
  ({ totalCount, invalidCount, errors }) => {
    const { localeStore } = useStores();

    const [limit, setLimit] = useState(5);

    const errorsAmount = Object.keys(errors).length;

    const handleShowMore = () => setLimit((prev) => prev + 5);

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.invalid}> {invalidCount}/ </span>
          <span className={styles.valid}> {totalCount} </span>
          {localeStore.t('["products-import"].modals["finish-import-modal"]["invalid-view"].title')}
        </div>

        <div className={clsx(globalStyles.addScrollStyles, styles.body)}>
          {Object.entries(errors)
            .slice(0, limit)
            .map(([title, list]) => (
              <div className={styles.errorContainer} key={title}>
                <Typography size="small600" color="textSecondary">
                  {title}
                </Typography>

                <ul className={styles.errorsValuesList}>
                  {list.map((error) => (
                    <li className={styles.errorValue} key={error}>
                      <Typography size="small">{error}</Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>

        {errorsAmount > limit && (
          <Button className={styles.button} appearance="secondaryOutlined" onClick={handleShowMore}>
            {localeStore.t(
              '["products-import"].modals["finish-import-modal"]["invalid-view"].buttons["show-more"]',
            )}
          </Button>
        )}
      </div>
    );
  },
);
