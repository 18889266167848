import * as Sentry from "@sentry/react";

import React from "react";
import ReactDOM from "react-dom";

import { ENVIRONMENT } from "src/config/environment";
import { Providers } from "src/external-ts/components/client/providers";
import { App } from "./app";
import { setupLocale } from "./setup-locale";
import { errorCatchSotre } from "src/utils/error-catch";
import ErrorBoundaryWrap from "./components/NewErrorBoundary";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "././redux/store";
import "./common/styles/root.css";

Sentry.init({
  dsn: "https://cc40ff473c3a50e1df94f0e374a5fa23@o4507146544545792.ingest.us.sentry.io/4507167622692864",
  environment: ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: ENVIRONMENT === "production" ? 0.1 : 1.0,
  ...(import.meta.env?.GEMHUB_BUILD_RELEASE_ID && {
    release: import.meta.env?.GEMHUB_BUILD_RELEASE_ID,
  }),
});
window.Sentry = Sentry;

errorCatchSotre.init();
setupLocale();

ReactDOM.render(
  <ErrorBoundaryWrap>
    <Providers>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Providers>
  </ErrorBoundaryWrap>,
  document.getElementById("root"),
);
