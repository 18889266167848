import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./selected-products-panel.module.css";

export const SelectedProductsPanel: React.FC = observer(() => {
  const { productsStore, localeStore } = useStores();

  if (!productsStore.selectedProductsAmount) return null;

  const handleUnselect = () => productsStore.unselectAllProductsList("selected");

  const handleSelect = () => productsStore.selectAllProductsList("selected");

  return (
    <div className={styles.container}>
      <div data-cy="catalog-add-products-selected-products-label">
        <Typography size="small600" color="textSecondary">{`${
          productsStore.selectedProductsAmount
        } ${localeStore.t(
          '["catalog-add-products"]["selected-products-panel"].label.product',
        )} `}</Typography>
        <Typography size="small" color="textSecondary">
          {localeStore.t('["catalog-add-products"]["selected-products-panel"].label.selected')}
        </Typography>
      </div>

      <Button
        className={styles.button}
        appearance="secondary"
        size="small"
        onClick={handleUnselect}
        data-cy="catalog-add-products-unselect-button"
      >
        {localeStore.t('["catalog-add-products"]["selected-products-panel"].buttons.unselect')}
      </Button>
      <Button
        className={styles.button}
        appearance="primaryOutlined"
        size="small"
        onClick={handleSelect}
      >
        {localeStore.t('["catalog-add-products"]["selected-products-panel"].buttons["select-all"]')}
      </Button>
    </div>
  );
});
