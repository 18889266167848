import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, usePointerDragNDrop } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { productVariantsStore } from "../product-variants.store";
import { VariantOption } from "./variant-option";

import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-thick-grey.svg";
import optionStyles from "./variant-option/variant-option.module.css";
import styles from "./variants-options.module.css";

export const VariantsOptions: React.FC = observer(() => {
  const { productId } = useParams<{ productId: string }>();

  const { localeStore } = useStores();

  const [currentOptionIndex, setCurrentOptionIndex] = useState(-1);
  const [newPositionIndex, setNewPositionIndex] = useState(-1);

  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const currentMouseYRef = useRef(0);
  const optionsDistArrRef = useRef<number[]>([]);
  const firstOptionRef = useRef<HTMLElement | null>(null);

  const dnd = usePointerDragNDrop();
  dnd.onDragStart((e: PointerEvent) => {
    const optionsContainer = optionsContainerRef.current;
    if (!optionsContainer) return;

    const options = optionsContainer.querySelectorAll(`.${optionStyles.variantOptionContainer}`);
    const firstOption = options[0] as HTMLElement;
    const firstOptionTop = firstOption.getBoundingClientRect().top;

    const arr: number[] = [];
    for (const child of options) {
      arr.push(child.getBoundingClientRect().top - firstOptionTop);
    }

    currentMouseYRef.current = e.clientY;
    optionsDistArrRef.current = arr;
    firstOptionRef.current = firstOption;
  });
  dnd.onDragMove((e) => {
    if (!firstOptionRef.current) return;

    currentMouseYRef.current = e.clientY;

    const currPos = e.clientY - firstOptionRef.current.getBoundingClientRect().top;

    const distArr = optionsDistArrRef.current;
    let newIndexToSet = 0;

    for (let i = 0; i < distArr.length; i++) {
      newIndexToSet = i;
      if (distArr[i] > currPos) {
        newIndexToSet = Math.max(newIndexToSet - 1, 0);
        break;
      }
    }

    setNewPositionIndex(newIndexToSet);
  });
  dnd.onDragEnd(() => {
    productVariantsStore.dropOption(Number(productId), currentOptionIndex, newPositionIndex);
    setCurrentOptionIndex(-1);
    setNewPositionIndex(-1);
  });

  const handleOpenSidebar = () => productVariantsStore.openSidebar();

  const handleOptionPointerDown = (e: React.PointerEvent, index: number) => {
    setCurrentOptionIndex(index);
    setNewPositionIndex(index);
    dnd.setupPointedDown(e);
  };

  return (
    <div className={styles.variantsContentContainer} ref={optionsContainerRef}>
      {productVariantsStore.optionsList.map((option, index) => {
        if (!option.id) return;
        return (
          <VariantOption
            key={index}
            variantOption={option}
            index={index}
            onOptionPointerDown={handleOptionPointerDown}
            isDraggedOption={index === currentOptionIndex}
            isDropPosition={index === newPositionIndex}
          />
        );
      })}
      {!productVariantsStore.hasOptionsLimit && (
        <Button appearance="primaryOutlined" onClick={handleOpenSidebar}>
          <SvgIcon icon={PlusSVG} />
          {localeStore.t('["product-variant"]["create-option"]')}
        </Button>
      )}
    </div>
  );
});

export default VariantsOptions;
