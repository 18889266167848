import { UseFiltersProps } from "@gemlightbox/core-kit";
import { GetCatalogsFilters } from "src/api";

export const initialCatalogFilters: UseFiltersProps<Pick<GetCatalogsFilters, "page" | "limit">> = {
  isStateBased: true,
  searchKey: "name",
  initialState: {
    page: 1,
    limit: 50,
  },
};
