import React from "react";

import { Button, Collapse, Typography } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";

import styles from "./styles.module.css";

interface SelectPanelProps {
  selectedAmount: number;
  isOpen: boolean;
  handleUnSelectAll: VoidFunction;
}

export const SelectPanel: React.FC<SelectPanelProps> = ({
  selectedAmount,
  isOpen,
  handleUnSelectAll,
}) => {
  const { localeStore } = useStores();
  return (
    <Collapse className={styles.selectPanelContainer} isOpen={isOpen} opacityTransition>
      <div className={styles.selectPanelWrapper}>
        <div className={styles.selectPanelLeft}>
          <Typography size="small" color="textTertiary">
            {localeStore.t('["catalog-details"]["product-order-modal"]["select-panel"].info')}
          </Typography>
        </div>
        <div className={styles.selectPanelRight}>
          <div className={styles.selectedAmount}>
            <Typography size="small600" color="textSecondary">
              {selectedAmount}&nbsp;{" "}
              {localeStore.t('["catalog-details"]["product-order-modal"]["select-panel"].media')}
            </Typography>
            &nbsp;
            <Typography size="small" color="textSecondary">
              {localeStore.t('["catalog-details"]["product-order-modal"]["select-panel"].selected')}
            </Typography>
          </div>
          <Button appearance="secondary" onClick={handleUnSelectAll} size="small">
            {localeStore.t('["catalog-details"]["product-order-modal"]["select-panel"].unselect')}
          </Button>
        </div>
      </div>
    </Collapse>
  );
};
