import { FORBIDDEN_STATUS } from "src/constants";
// import { push } from "react-router-redux";
import { logout } from "src/redux/reducers/user";
import { LOAD_SHOPIFY_SETTINGS_FAILURE, LOAD_SHOPIFY_STATUS_FAILED } from "../reducers/shopify";

const blackActionsList = [LOAD_SHOPIFY_SETTINGS_FAILURE, LOAD_SHOPIFY_STATUS_FAILED];

export default (store) => (next) => (action) => {
  if (action?.status === FORBIDDEN_STATUS && !blackActionsList.includes(action.type)) {
    // TODO: fix this via using new api for remaining requests
    window.location = window.location.origin + "/subaccount-login-failed";
    // store.dispatch(push("/subaccount-login-failed"));
    store.dispatch(logout(true));
  }

  return next(action);
};
