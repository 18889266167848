import React from "react";
import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { SETTINGS_TABS, SettingTabNames } from "../settings.constants";
import { SettingsNavLink } from "./settings-nav-link";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./settings-nav.module.css";

export const SettingsNav = observer(() => {
  const { userStore } = useStores();

  return (
    <div className={clsx(styles.container, globalStyles.addScrollStyles)}>
      {SETTINGS_TABS.map((tabItem) => {
        const name = tabItem.name;

        // NOTE: hide tabs relying on conditions
        if (userStore.isSubaccount && name === SettingTabNames.gallery) return null;
        if (
          (userStore.isSubaccount || userStore.isAppStoreDemoAccount) &&
          name === SettingTabNames.subscriptions
        ) {
          return null;
        }
        if (userStore.isAppStoreDemoAccount && name === SettingTabNames.billing) return null;

        return <SettingsNavLink key={name} tabItem={tabItem} />;
      })}
    </div>
  );
});
