import React from "react";
import { observer } from "mobx-react-lite";

import Modal from "src/components/modals/Modal";
import { useStores } from "src/hooks";
import IconCircle from "src/components/IconCircle";
import BtnWithLoader from "../../../../../components/BtnWithLoader";

import questionImg from "../../images/question.svg";

export const IntegrationCancelSyncModal = observer(
  ({ isOpened, name, loading, onClose, onConfirm }) => {
    const { localeStore } = useStores();

    return (
      <Modal
        className="shopify-disconnect-modal"
        isOpened={isOpened}
        withClose
        handleModalClose={onClose}
      >
        <div className="modal-content__header">
          <IconCircle img={questionImg} />
          <div className="headline">
            {localeStore.t('settings.integrations.modals["integration-cancel-sync-modal"].title')}{" "}
            {name}?
          </div>
        </div>
        <div className="modal-content__body">
          <div className="label error">
            {localeStore.t('settings.integrations.modals["integration-cancel-sync-modal"].warning')}
          </div>
          <ol>
            <li className="label">
              {localeStore.t(
                'settings.integrations.modals["integration-cancel-sync-modal"]["already-synced"].first',
              )}{" "}
              {name}{" "}
              {localeStore.t(
                'settings.integrations.modals["integration-cancel-sync-modal"]["already-synced"].last',
              )}
            </li>
            <li className="label">
              {localeStore.t(
                'settings.integrations.modals["integration-cancel-sync-modal"]["sync-rest"]',
              )}
            </li>
          </ol>
        </div>
        <div className="modal-content__footer">
          <button className="primary-reverse-btn cancel" onClick={onClose}>
            {localeStore.t("settings.integrations.modals.buttons.cancel")}
          </button>
          <BtnWithLoader
            loading={loading}
            label={localeStore.t("settings.integrations.modals.buttons.proceed")}
            className="primary-btn confirm"
            onClick={onConfirm}
          />
        </div>
      </Modal>
    );
  },
);

export default IntegrationCancelSyncModal;
