import React from "react";
import { observer } from "mobx-react-lite";
import { addMonths, Heading, Typography, Input, useQuery, clsx } from "@gemlightbox/core-kit";

import { SupportedSubscriptionInterval } from "src/containers/settings/subscriptions/subscriptions.types";
import { useStores } from "src/hooks";
import { BillingInfoItem } from "../billing-info-item";

import styles from "../../billing-modal.module.css";

export interface BillingDowngradeProps {
  price: number;
  subscriptionType: SupportedSubscriptionInterval;
}

export const BillingDowngrade: React.FC<BillingDowngradeProps> = observer(
  ({ price, subscriptionType }: BillingDowngradeProps) => {
    const { localeStore } = useStores();
    const getSubscriptionType = () => {
      if (subscriptionType === "lifetime") return "yearly";
      return subscriptionType.toString().replace("ly", "");
    };

    const getDowngradeInstructions = () => {
      const placeholder = localeStore.t(
        'settings.subscriptions.components["billing-modal"].components["billing-downgrade"].placeholder',
      );
      const subscriptionTypeStr = localeStore.t(
        `settings.subscriptions.components["billing-modal"].components["billing-downgrade"].${subscriptionType}`,
      );
      return localeStore
        .t(
          'settings.subscriptions.components["billing-modal"].components["billing-downgrade"]["downgrade-instructions"]',
        )
        .replace(placeholder, subscriptionTypeStr);
    };

    return (
      <div className={styles.rightContainer}>
        <div className={styles.rightContent}>
          <Heading className={styles.leftHeader} tag="h3" color="textSecondary">
            US${price}/{getSubscriptionType()}
          </Heading>

          <div className={styles.billingInfoContainer}>
            <BillingInfoItem
              content={
                <Typography
                  className={styles.offerItemName}
                  size="extraSmall"
                  color="textSecondary"
                >
                  {getDowngradeInstructions()}
                </Typography>
              }
            />

            <BillingInfoItem
              content={
                <Typography
                  className={clsx(styles.offerItemName, styles.oneLine)}
                  size="extraSmall"
                  color="textSecondary"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: localeStore
                        .t("settings.subscriptions.components.content.refund")
                        .replace(
                          localeStore.t('settings.subscriptions.components.content["contact-us"]'),
                          `<a href="https://picupmedia.com/contact-us#support" target="_blank">${localeStore.t(
                            'settings.subscriptions.components.content["contact-us"]',
                          )}</a>`,
                        ),
                    }}
                  />
                </Typography>
              }
            />
          </div>
        </div>
      </div>
    );
  },
);

export default BillingDowngrade;
