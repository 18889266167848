import { MediaDownloadType } from "src/models";
import { DownloadMediaFormType } from "./download-media-modal.types";

export const NORMALIZED_SIZE_MIN = 1;
export const NORMALIZED_SIZE_MAX = 10000;

export const downloadMediaFormatNames: Record<MediaDownloadType, string> = {
  [MediaDownloadType.jpg]: "JPG",
  [MediaDownloadType.png]: "PNG",
  [MediaDownloadType.pdf]: "PDF",
};

export const downloadMediaFormatOptions: Array<{
  label: string;
  id: string;
  value: MediaDownloadType;
}> = [
  { label: "JPG", value: MediaDownloadType.jpg, id: "download-jpg" },
  { label: "PNG", value: MediaDownloadType.png, id: "download-png" },
  // { label: "PDF", value: MediaDownloadType.pdf, id: "download-pdf" },
];

export const initialValues: DownloadMediaFormType = {
  format: MediaDownloadType.jpg,
  width: 3400,
  height: 3400,
};

export const downloadMediaLocalStorageEntity = "download-media";
