import React from "react";
import { SvgIcon, Checkbox, Button } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { CardModel } from "src/models";
import { paymentCardIconsMap } from "./payment-card.constants";

import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";

import styles from "./payment-card.module.css";

interface PaymentCardProps {
  name: string;
  cardIndex: number;
  loading: boolean;
  isDefault: boolean;
  data: CardModel;
  onChangeDefaultCard: (card: CardModel, cardIndex: number) => void;
  onDelete: (card: CardModel) => void;
}

export const PaymentCard: React.FC<PaymentCardProps> = ({
  name,
  cardIndex,
  loading,
  isDefault,
  data,
  onChangeDefaultCard,
  onDelete,
}) => {
  const { localeStore } = useStores();

  const { brand, last4 } = data;
  const typeIcon = paymentCardIconsMap[brand?.toLowerCase()];

  const handleChange = () => onChangeDefaultCard(data, cardIndex);

  const handleDelete = () => onDelete(data);

  return (
    <div className={styles.container}>
      <Checkbox
        containerClassName={styles.checkboxContainer}
        className={styles.checkbox}
        label={localeStore.t('settings.profile["payment-info"]["default-card-label"]')}
        checked={isDefault}
        onChange={handleChange}
        disabled={loading}
        disableError
      />
      {!isDefault && (
        <Button appearance="unset" className={styles.delete} onClick={handleDelete}>
          <SvgIcon icon={TrashSVG} size={32} className={styles.deleteIcon} />
        </Button>
      )}
      <div className={styles.number}>
        <span>****</span>
        <span>****</span>
        <span>****</span>
        <span>{last4}</span>
      </div>
      <div className={styles.footer}>
        <div className={styles.name}>{name}</div>
        {typeIcon && <SvgIcon className={styles.icon} icon={typeIcon} />}
      </div>
    </div>
  );
};
