import { loadImage } from "@gemlightbox/core-kit";

export const combineImageAlpha = async (imageURL: string, maskURL: string) => {
  //Merge original image and alpha channel
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  try {
    const [image, mask] = await Promise.all([loadImage(imageURL), loadImage(maskURL)]);

    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    ctx.drawImage(mask, 0, 0);

    const idata = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data32 = new Uint32Array(idata.data.buffer);
    let i = 0;
    const len = data32.length;
    while (i < len) data32[i] = data32[i++] << 8;
    ctx.putImageData(idata, 0, 0);
    ctx.globalCompositeOperation = "source-in";
    ctx.drawImage(image, 0, 0);

    return canvas.toDataURL();
  } catch (e) {
    console.error(e);
  }
};
