import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import { userReducer } from "./user";
import { shopifyReducer } from "./shopify";

export const reducers = combineReducers({
  routing: routerReducer,
  userReducer,
  shopifyReducer,
});
