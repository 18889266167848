import { PAGINATION_OPTIONS } from "@gemlightbox/core-kit";

import { ProductRangeModel } from "src/models";
import { DefaultFilterNameType, FiltersType } from "./filters-sidebar.types";

export const defaultRange: ProductRangeModel = {
  min: 1,
  max: 10000000,
};

export const defaultFiltersNames: DefaultFilterNameType[] = [
  "title",
  "detailedTitle",
  "description",
  "productType",
];

export const defaultFiltersNamesLabels = {
  title: "SKU",
  detailedTitle: "Title",
  description: "Description",
  productType: "Product Type",
};

export const initialFilters: FiltersType = {
  limit: PAGINATION_OPTIONS[0].value,
  page: 1,
  ids: [],
  attributes: [],
  title: "",
  fromPrice: null,
  toPrice: null,
  detailedTitle: "",
  description: "",
  productType: "all",
  quantity: [],
  user_id: "all",
  mediaType: "all",
  isThereMedia: undefined,
  isPrivate: undefined,
};
