import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import { PrivacyButton } from "./privacy-button";

import styles from "./attribute-privacy.module.css";
import { WithTitle } from "../with-title";

export interface AttributePrivacy {
  isPrivate: boolean;
  onChange: (value: boolean) => void;
}

export const AttributePrivacy: React.FC<AttributePrivacy> = observer(({ isPrivate, onChange }) => {
  const { localeStore } = useStores();

  const beforeChange = (value: boolean) => () => {
    if (value === isPrivate) return;
    onChange(value);
  };

  return (
    <WithTitle
      className={styles.container}
      contentClassName={styles.content}
      title={localeStore.t('products.modals.components["attribute-privacy"].title')}
    >
      <PrivacyButton active={isPrivate} privacy="private" onClick={beforeChange(true)} />
      <PrivacyButton active={!isPrivate} privacy="public" onClick={beforeChange(false)} />
    </WithTitle>
  );
});
