import React from "react";
import { observer } from "mobx-react-lite";

import { Button, SvgIcon, UseBooleanReturnType, clsx } from "@gemlightbox/core-kit";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { ReactComponent as CloseSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import { ReactComponent as ChromeSVG } from "src/external-ts/assets/images/camera/win-bluetooth-chrome.svg";
import { ReactComponent as EdgeSVG } from "src/external-ts/assets/images/camera/win-bluetooth-edge.svg";

import styles from "./bluetooth-permission-modal-win.module.css";
import { useStores } from "src/hooks";

const BootstrapDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    background: "linear-gradient(180deg, #9BE7EB 0%, #FFF 46.5%)",
    borderRadius: "16px",
  },
}));

type BluetoothPermModalProps = {
  bluetoothPermBool: UseBooleanReturnType;
  bluetoothPermTipsBool: UseBooleanReturnType;
};

export const WinBluetoothPermModal: React.FC<BluetoothPermModalProps> = observer(
  ({ bluetoothPermBool, bluetoothPermTipsBool }) => {
    const { localeStore } = useStores();
    const handleOpenTips = () => {
      bluetoothPermBool.setFalsy();
      bluetoothPermTipsBool.setTruthy();
    };
    return (
      <BootstrapDialog open={bluetoothPermBool.value} maxWidth="xs">
        <DialogTitle className={styles.bluetoothDialogHeader}>
          <SvgIcon
            className={styles.closeButton}
            icon={CloseSVG}
            size={24}
            onClick={bluetoothPermBool.setFalsy}
          />

          <SvgIcon className={styles.browserIcon} icon={ChromeSVG} size={72} />
          <SvgIcon className={styles.browserIcon} icon={EdgeSVG} size={72} />
        </DialogTitle>
        <DialogContent>
          <div className={clsx(styles.dialogDesc, styles.mt20)}>
            {localeStore.t('camera["browser-not-support-tips1"]')}
          </div>
          <div className={styles.dialogDesc}>
            {localeStore.t('camera["browser-not-support-tips2"]')}
            <span className={styles.subDesc}>
              ({localeStore.t('camera["browser-not-support-tips3"]')})
            </span>
          </div>

          <div className={styles.actionBox}>
            <Button appearance="primary" className={styles.confirmBtn} onClick={handleOpenTips}>
              {localeStore.t('camera["browser-not-support-btn1"]')}
            </Button>
            <Button
              appearance="tertiaryOutlined"
              className={styles.cancelBtn}
              onClick={bluetoothPermBool.trigger}
            >
              {localeStore.t('camera["browser-not-support-btn2"]')}
            </Button>
          </div>
        </DialogContent>
      </BootstrapDialog>
    );
  },
);
