import React from "react";
import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { Column } from "./column";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./columns-list.module.css";

export const ColumnsList: React.FC = observer(() => {
  const { productsImportStore } = useStores();

  return (
    <div className={clsx(globalStyles.addScrollStyles, styles.container)}>
      {productsImportStore.columns.map((column) => (
        <React.Fragment key={column.id}>
          <Column data={column} />
        </React.Fragment>
      ))}
    </div>
  );
});
