import React from "react";
import { FilterCollapse, Input } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { observer } from "mobx-react-lite";

export interface SKUFilterProps {
  value: string;
  onChange: (value: string) => void;
}

export const SKUFilter: React.FC<SKUFilterProps> = observer(({ value, onChange }) => {
  const { localeStore } = useStores();

  return (
    <FilterCollapse data-cy="sku-filter-collapse" title="SKU">
      <Input
        data-cy="sku-filter-input"
        value={value}
        appearance="primaryV2"
        placeholder={localeStore.t(
          'components.business.filters["filters-sidebar"]["default-filters"]["sku-filter"].placeholder',
        )}
        onChange={onChange}
        disableError
      />
    </FilterCollapse>
  );
});
