import React from "react";
import { observer } from "mobx-react-lite";
import { GooLoader } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { PageResultsEmpty, PageResultsPlaceholderContainer } from "src/external-ts/components";
import { MediaTotalEmpty } from "./media-total-empty/media-total-empty";

export const MediaListPlaceholder: React.FC = observer(() => {
  const { mediaStore } = useStores();

  const loading = mediaStore.loading;
  const isEmpty = mediaStore.isEmpty;

  return (
    <PageResultsPlaceholderContainer>
      {loading && <GooLoader />}
      {!loading && isEmpty && <MediaTotalEmpty />}
      {!loading && !isEmpty && (
        <PageResultsEmpty
          search={mediaStore.filters.sku || ""}
          isOnlySearch={mediaStore.isOnlySearch}
        />
      )}
    </PageResultsPlaceholderContainer>
  );
});
