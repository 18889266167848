import React, { Fragment, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, Tooltip, useBoolean, Webgl2New } from "@gemlightbox/core-kit";

import { ColorPickerContent } from "../../../../color-picker-content";
import { editMediaStore } from "../../../../edit-media.store";
import { maxColorsLimit } from "./add-color-button.constants";

import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-thick-grey.svg";
import styles from "./add-color-button.module.css";

export type AddColorButtonProps = {
  measurement: Webgl2New.MeasurementComponent;
};

export const AddColorButton: React.FC<AddColorButtonProps> = observer(
  ({ measurement }: AddColorButtonProps) => {
    const { renderer, measurementColors } = editMediaStore;

    const isAddColorOpenBoolean = useBoolean();

    const addColorButtonRef = useRef<HTMLButtonElement>(null);

    const handleToggleAddColorTooltip = () => {
      isAddColorOpenBoolean.trigger();
      measurement.changeTempColor(measurement.color);
      renderer.render();
    };

    const handleCloseTooltip = () => {
      isAddColorOpenBoolean.setFalsy();
      measurement.changeColor(measurement.color, measurement.colorIndex);
      renderer.render();
    };

    const handleAddColor = (color: string) => {
      isAddColorOpenBoolean.setFalsy();

      const hasSameColor = measurementColors.includes(color);
      if (hasSameColor) return;

      measurementColors.push(color);
      measurement.changeColor(color, measurementColors.length - 1);
    };

    const handleColorChange = (color: string) => {
      measurement.changeTempColor(color);
      renderer.render();
    };

    if (measurementColors.length >= maxColorsLimit) return null;

    return (
      <Fragment>
        <Button
          appearance="primaryOutlined"
          size="small"
          forwardRef={addColorButtonRef}
          onClick={handleToggleAddColorTooltip}
        >
          <SvgIcon icon={PlusSVG} />
        </Button>
        <Tooltip
          className={styles.colorPickerTooltip}
          appearance="unset"
          position="right"
          offsetX={4}
          target={addColorButtonRef}
          onClose={handleCloseTooltip}
          isOpen={isAddColorOpenBoolean.value}
          disableAutoPosChange
        >
          <ColorPickerContent
            color={measurement.tempColor}
            onColorChange={handleColorChange}
            onClose={handleCloseTooltip}
            onAddColorClick={handleAddColor}
          />
        </Tooltip>
      </Fragment>
    );
  },
);

export default AddColorButton;
