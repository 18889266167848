import { LocalSaveSettingsType } from "src/external-ts/pages/camera/camera.types";
import { pushDataLayerEvent, pushRemoveContextPropertiesEvent } from "./index";
import {
  EnvironmentInfo,
  GemCamDeviceInfo,
  LocalSaveSettingsSuperProperties,
  SuperProperties,
  TurntableInfo,
} from "./super-properties.utils";
import { EnvironmentInfoRes, USBDeviceConnectRes } from "src/store/app-callback";
import { bytesToSize, sizeTobytes } from "src/utils/common.utils";

type UpdateContextPropertiesEventPayload =
  | EnvironmentInfo
  | GemCamDeviceInfo
  | TurntableInfo
  | LocalSaveSettingsSuperProperties
  | SuperProperties;

export const pushUpdateContextPropertiesEvent = (params: UpdateContextPropertiesEventPayload) => {
  pushDataLayerEvent({
    event: "update_context_properties",
    event_params: params,
  });
};

export const pushUpdateContextPropertiesEnvironmentInfoEvent = (data: EnvironmentInfoRes) => {
  pushUpdateContextPropertiesEvent({
    "app:bundle_id": data.appBundleID,
    "app:version": data.appVersion,
    "app:build_version": data.appBuildVersion,
    "os:version": data.osVersion ? data.osVersion : data.macOSVersion,
    "os:version_string": data.osVersionString ? data.osVersionString : data.macOSVersionString,
    "client_device:manufacturer": data.clientDeviceManufacturer,
    "client_device:model_id": data.clientDeviceModelID ? data.clientDeviceModelID : data.macModelID,
  });
};

export const pushUpdateContextPropertiesGemCamDeviceInfoEvent = (
  data: USBDeviceConnectRes | undefined,
) => {
  const gemCamDeviceInfo = {
    "gemcam:device_name": data?.cameraName,
    "gemcam:serial_number": data?.cameraSerialNumber,
    "gemcam:firmware_version": data?.cameraFirmwareVersion,
  } as GemCamDeviceInfo;
  pushRemoveContextPropertiesEvent(Object.keys(gemCamDeviceInfo) as SuperProperties[]);
  pushUpdateContextPropertiesEvent(gemCamDeviceInfo);
};

export const pushUpdateContextPropertiesTurntableInfoEvent = (
  turntableName: string | undefined,
) => {
  if (!turntableName) return;

  const getTurntableType = () => {
    const gemLightboxKeywords = ["Turntable", "GemLightbox", "Aerial"];
    const types = [
      { keywords: gemLightboxKeywords, type: "GemLightbox" },
      { keywords: ["GLB PRO"], type: "GemLightbox Pro" },
      { keywords: ["Macro"], type: "Macro" },
      { keywords: ["GemStack"], type: "GemStack" },
    ];

    for (const { keywords, type } of types) {
      if (keywords.some((keyword) => turntableName.includes(keyword))) {
        return type;
      }
    }
    return "";
  };

  const turntableInfo = {
    "turntable:type": getTurntableType(),
    "turntable:device_name": turntableName,
  } as TurntableInfo;
  pushRemoveContextPropertiesEvent(Object.keys(turntableInfo) as SuperProperties[]);
  pushUpdateContextPropertiesEvent(turntableInfo);
};

export const pushUpdateContextPropertiesLocalSaveSettingsEvent = (
  localSaveSettings: LocalSaveSettingsType | undefined,
) => {
  if (!localSaveSettings) return;
  const localSaveSettingsSuper = {
    "media:local_save:status": localSaveSettings?.isLocalSaveMode ? "enabled" : "disabled",
    "media:local_save:base_path": localSaveSettings?.folderPath || "",
    "media:local_save:reserved_disk_space_in_mb":
      localSaveSettings.storageSpaceUnit == "MB"
        ? localSaveSettings?.miniStorageSpace || 0
        : bytesToSize(
            sizeTobytes(localSaveSettings?.miniStorageSpace, localSaveSettings.storageSpaceUnit),
            "MB",
          ),
  } as LocalSaveSettingsSuperProperties;
  pushRemoveContextPropertiesEvent(Object.keys(localSaveSettingsSuper) as SuperProperties[]);
  pushUpdateContextPropertiesEvent(localSaveSettingsSuper);
};
