import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  memo,
  SvgIcon,
  Tooltip,
  Typography,
  useBoolean,
  clsx,
} from "@gemlightbox/core-kit";

import { CREATE_PAGE, SETTINGS_PAGE } from "src/constants";
import { useStores } from "src/hooks";
import { DashboardLink } from "src/external-ts/components";
import { mobileNavOptions } from "../../navbar.constants";

import { ReactComponent as OptionsSVG } from "src/external-ts/assets/images/options-dots.svg";
import _styles from "../mobile-layout.module.css";
import styles from "./nav-selector.module.css";

export type NavSelectorProps = {
  active: boolean;
};

export const NavSelector: React.FC<NavSelectorProps> = memo(({ active }) => {
  const navigate = useNavigate();

  const { userStore } = useStores();

  const ref = useRef<HTMLButtonElement>(null);
  const tooltipActive = useBoolean(false);

  const handleOption = (path: string) => {
    if (path === CREATE_PAGE.path || path === SETTINGS_PAGE.path) {
      navigate(path);
    } else if (!path) {
      userStore.logout();
    } else {
      // contact us || order retouch
      window.open(path, "_blank");
    }

    tooltipActive.setFalsy();
  };

  return (
    <>
      <Button
        className={clsx(_styles.navItem, { [_styles.active]: active })}
        appearance="unset"
        size="unset"
        forwardRef={ref}
        onClick={tooltipActive.setTruthy}
      >
        <SvgIcon icon={OptionsSVG} />
        <Typography size="extraSmall"> More </Typography>
      </Button>

      <Tooltip
        position="topRight"
        className={styles.tooltip}
        target={ref}
        isOpen={tooltipActive.value}
        onClose={tooltipActive.setFalsy}
      >
        <div className={styles.navContainer}>
          {mobileNavOptions.map(({ iconProps, label, value }) => (
            <div key={label} className={styles.nav} onClick={() => handleOption(value)}>
              {iconProps && label ? (
                <>
                  <SvgIcon {...iconProps} />
                  <Typography className={styles.label} size="extraSmall">
                    {label}
                  </Typography>
                </>
              ) : (
                <DashboardLink label={label} />
              )}
            </div>
          ))}
        </div>
      </Tooltip>
    </>
  );
});
