import React from "react";
import { observer } from "mobx-react-lite";
import { Heading } from "@gemlightbox/core-kit";

import { BackgroundFillSelect, ColorValue } from "src/external-ts/components";
import { useStores } from "src/hooks";

import styles from "./background-fill-block.module.css";

export type BackgroundFillBlockProps = {
  onChange?: (value: ColorValue) => void;
  value: ColorValue;
};

export const BackgroundFillBlock: React.FC<BackgroundFillBlockProps> = observer(
  ({ onChange, value }: BackgroundFillBlockProps) => {
    const { localeStore } = useStores();
    return (
      <div className={styles.container}>
        <Heading tag="h2" color="textSecondary">
          {localeStore.t('settings.profile["background-fill-block"].title')}
        </Heading>
        <BackgroundFillSelect
          className={styles.backgroundFillSelectContainer}
          onChange={onChange}
          value={value}
        />
      </div>
    );
  },
);
