import React from "react";
import { observer } from "mobx-react-lite";
import { ExpandableSidebar, useMediaBreakpoints } from "@gemlightbox/core-kit";

import { ExtendedMediaModel } from "src/store";
import { AssignMediaItem } from "./assign-media-item";

import styles from "./assign-to-product-sidebar.module.css";

export type AssignToProductSidebarProps = {
  isOpen: boolean;
  options: {
    medias: ExtendedMediaModel[];
  };
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
};

export const AssignToProductSidebar: React.FC<AssignToProductSidebarProps> = observer(
  ({ isOpen, options, setClose, onFinalClosed }) => {
    const { medias } = options;
    const { isMobileMedia } = useMediaBreakpoints();

    return (
      <ExpandableSidebar
        sidebarContentClassName={styles.assignMediaList}
        icon="cross"
        iconPos={isMobileMedia ? "right" : "outside"}
        title="Assign to SKU"
        name="assign-to-product-sidebar"
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        data-cy="assign-to-product-sidebar"
      >
        {medias.map((data) => (
          <AssignMediaItem key={data.id} data={data} />
        ))}
      </ExpandableSidebar>
    );
  },
);
