import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Nullable,
  useCall,
  useAutocomplete,
  useApiRequestAbort,
  useDidUpdate,
  UseAutocompleteReturnType,
  ApiRequestErrorResponse,
} from "@gemlightbox/core-kit";

import { ExtendedMediaModel } from "src/store";
import { ProductModel } from "src/models";
import { getProductNameIsUnique, getProducts, postProduct } from "src/api";
import { useStores } from "./use-stores.hook";

type OptionsType = UseAutocompleteReturnType<typeof getProducts>["options"];

export type UseMediaAssignData = {
  data: ExtendedMediaModel;
  callOnMount?: boolean;
  onError?: (originalError: ApiRequestErrorResponse) => void;
};

export type UseMediaAssignOutput = {
  searchValue: string;
  title: Nullable<ProductModel["title"]>;
  value: ProductModel["_id"];
  error: string;
  canCreateNew: boolean;
  isSearchUnique: boolean;
  loading: boolean;
  submitting: boolean;
  inProcess: boolean;
  hasProduct: boolean;
  options: OptionsType;
  onSearchProduct: (value: string) => void;
  onBlur: VoidFunction;
  onChangeProduct: (option: Nullable<ProductModel["_id"]>) => void;
  onCreateProduct: VoidFunction;
  onGoToProduct: VoidFunction;
  onDropdownOpen: VoidFunction;
  onDropdownClose: VoidFunction;
};

export const useMediaAssign = ({
  data,
  callOnMount = false,
  onError,
}: UseMediaAssignData): UseMediaAssignOutput => {
  const navigate = useNavigate();

  const { mediaStore, productsStore } = useStores();

  // NOTE: don't use 'mediaStore.isAssigning' because then another items will have loading
  // due to mobx state, so we repeat it locally
  const [isInAssign, setIsInAssign] = useState(false);
  const [hadInitLoad, setHadInitLoad] = useState(callOnMount);
  const [error, setError] = useState("");

  const [searchValueLocal, setSearchValueLocal] = useState(data.sku || "");
  const [isSearchUnique, setSearchUnique] = useState(false);
  const useUniqProductAbort = useApiRequestAbort();
  const getProductsAutoComplete = useAutocomplete(getProducts, "_id", "title", {
    searchKey: "title",
    callOnMount,
  });
  getProductsAutoComplete.onBeforeSearch(async (search) => {
    setError("");
    setSearchUnique(false);

    useUniqProductAbort.abort();

    const request = useUniqProductAbort.setRequest(
      getProductNameIsUnique.getRequest({ params: { title: encodeURIComponent(search) } }),
    );

    const { success, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    if (typeof success === "boolean") {
      const isUnique = !success;
      setSearchUnique(isUnique);
    }
  });

  const createProductCall = useCall(postProduct);
  createProductCall.onCallSuccess(({ rows, total_items }) => {
    productsStore.sendCreateProductEvent(total_items);
    const product = rows[0];
    handleAssignProductToMedia(product);
  });
  createProductCall.onCallError((error) => {
    setError(error.formattedMessage);
    onError?.(error);
  });

  const options = getProductsAutoComplete.options;

  const hasProduct = !!data.sku_id;

  const loading = getProductsAutoComplete.loading || data.extended?.loading || false;
  const submitting = createProductCall.submitting || isInAssign;
  const inProcess = loading || submitting;
  const canCreateNew = !!searchValueLocal && !inProcess && isSearchUnique;

  const handleAssignProductToMedia = async (product: ProductModel) => {
    const prevProduct = data.sku && data.sku_id ? { title: data.sku, _id: data.sku_id } : null;

    mediaStore.updateMediaAssignLocal(data, product);

    setIsInAssign(true);

    const result = await mediaStore.assignMedia(data.id, product);

    setIsInAssign(false);

    if (result.error) {
      mediaStore.updateMediaAssignLocal(data, prevProduct);
      setError(result.error.formattedMessage);
      onError?.(result.error);
      return;
    }
  };

  const handleUnAssignMedia = async () => {
    const prevProduct = data.sku && data.sku_id ? { title: data.sku, _id: data.sku_id } : null;

    mediaStore.updateMediaAssignLocal(data, null);

    setSearchValueLocal("");
    getProductsAutoComplete.onSearch("");

    setIsInAssign(true);

    const result = await mediaStore.unassignMedia(data.id);

    setIsInAssign(false);

    if (result.error) {
      setSearchValueLocal(prevProduct?.title || "");
      mediaStore.updateMediaAssignLocal(data, prevProduct);
      setError(result.error.formattedMessage);
      onError?.(result.error);
      return;
    }
  };

  const handleSearch = (value: string) => {
    getProductsAutoComplete.onSearch(value);
    setSearchValueLocal(value);
  };

  const handleBlur = () => {
    if (!hasProduct || searchValueLocal) return;
    handleUnAssignMedia();
  };

  const handleChangeProduct = async (value: Nullable<ProductModel["_id"]>) => {
    setError("");

    if (!value) {
      handleUnAssignMedia();
      return;
    }

    const foundProduct = getProductsAutoComplete.options.find((option) => option._id === value);
    if (!foundProduct) return;

    handleAssignProductToMedia(foundProduct);
  };

  const handleCreateProduct = () => {
    if (!canCreateNew) return;

    const title = getProductsAutoComplete.searchValue;

    createProductCall.submit({
      data: { title },
      // NOTE: setting 'index-response' to empty string will remove header from request
      // Thus endpoint will always return us array with only our newly created product
      headers: {
        "index-response": "",
      },
    });

    setSearchValueLocal(title);
    getProductsAutoComplete.onSearch("");
  };

  const handleGoToProduct = () => {
    if (!data.sku_id) return;
    navigate(`/product/${data.sku_id}`);
  };

  const handleDropdownOpen = () => {
    if (hadInitLoad) return;
    setHadInitLoad(true);
    getProductsAutoComplete.refresh();
  };

  const handleDropdownClose = () => {
    setSearchValueLocal(data.sku || "");
    getProductsAutoComplete.onSearch("");
  };

  useDidUpdate(() => {
    setSearchValueLocal(data.sku || "");
    getProductsAutoComplete.onSearch("");
  }, [data.sku]);

  return {
    searchValue: searchValueLocal,
    title: data.sku,
    value: data.sku_id || 0,
    error,
    isSearchUnique,
    canCreateNew,
    loading,
    submitting,
    inProcess,
    hasProduct,
    options,
    onSearchProduct: handleSearch,
    onBlur: handleBlur,
    onChangeProduct: handleChangeProduct,
    onCreateProduct: handleCreateProduct,
    onGoToProduct: handleGoToProduct,
    onDropdownOpen: handleDropdownOpen,
    onDropdownClose: handleDropdownClose,
  };
};
