import React, { memo } from "react";
import { clsx } from "@gemlightbox/core-kit";

import styles from "./group-block.module.css";

export type GroupBlockProps = {
  children: React.ReactNode;
  className?: string;
  selected?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const GroupBlock: React.FC<GroupBlockProps> = memo(
  ({ children, className, selected = false, onClick }: GroupBlockProps) => {
    const groupBlockStyles = clsx(
      styles.groupBlockContainer,
      { [styles.selected]: selected },
      className,
    );

    return (
      <div className={groupBlockStyles} onClick={onClick}>
        {children}
      </div>
    );
  },
);

export default GroupBlock;
