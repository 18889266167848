import React, { useMemo } from "react";
import { Select, SelectOption, Nullable } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { productDefaultTypesNames } from "src/models";

interface ProductTypeSelectProps {
  disabled: boolean;
  selectedOption: SelectOption<string> | undefined;
  onChange: (value: SelectOption<string> | undefined) => void;
}

export const ProductTypeSelect: React.FC<ProductTypeSelectProps> = ({
  disabled,
  selectedOption,
  onChange,
}) => {
  const { localeStore, attributesStore } = useStores();

  const productTypeAttrs = useMemo(
    () => attributesStore.findAttribute("productType"),
    [attributesStore.loading],
  );

  const options: SelectOption<string>[] =
    productTypeAttrs?.values?.map((value) => ({
      label: value,
      value: value,
      name: value,
      indelible: true,
    })) || [];

  const handleChange = (option: Nullable<SelectOption<string>> | undefined) => {
    if (option === undefined) return;

    if (option === null) {
      onChange(undefined);
    }

    if (option !== null) {
      const { value, label } = option;
      if (!value || !label) return;
      onChange({ label, value });
    }
  };

  return (
    <Select
      appearance="primaryV2"
      label={localeStore.t('["product-attributes"].type')}
      placeholder={
        disabled
          ? ""
          : localeStore.t('["product-attributes"]["attribute-field"]["select-placeholder"]')
      }
      selectedOptionsKeys={selectedOption}
      options={options?.map((item) => {
        const label = localeStore.t(
          `create["augmented-reality-item"]["ar-types"].${
            productDefaultTypesNames[item.value.toLocaleLowerCase()]
          }` as LocaleCodeTypes,
          item.value,
        );
        return { ...item, label };
      })}
      onChange={handleChange}
      canCreate={false}
      isTextEditable
      disableError
      disabled={disabled}
      data-hj-allow
    />
  );
};
