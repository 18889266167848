import { UNAUTHORIZED_STATUS } from "src/constants";
// import { push } from "react-router-redux";
import { logout } from "src/redux/reducers/user";

export default (store) => (next) => (action) => {
  if (action.status && action.status === UNAUTHORIZED_STATUS) {
    store.dispatch(logout(action.withReload));

    // TODO: fix this via using new api for remaining requests
    // window.location = window.location.origin + "/auth/login" + window.location.search;
    // store.dispatch(push("/auth/login" + window.location.search));
  }

  return next(action);
};
