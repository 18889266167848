import {
  download,
  MaybeArray,
  toArrayOptions,
  QueryParams,
  queryStringify,
} from "@gemlightbox/core-kit";

import { postCreateGeneratedCatalog } from "src/api";
import { useStores } from "src/hooks";
import { ProductModel } from "src/models";
import { config } from "src/config/environment";

export const downloadProducts = async (
  products: MaybeArray<ProductModel>,
  openDownloadModal = true,
) => {
  if (!products) return;
  products = toArrayOptions(products);
  if (!products.length) return;

  const { modalsStore, userStore, notificationStore } = useStores();

  const mediaIds = products.map((product) => product.images.map((image) => image.id)).flat();

  const queryParams: QueryParams = {
    type: "png",
    imageIDS: mediaIds,
    token: userStore.token,
    isUseSkuAsNames: true,
  };

  const query = queryStringify(queryParams);
  download(config.apiUrl + "/api/content/download/resize" + query);

  if (!openDownloadModal) return;

  if (products.length > 1) {
    notificationStore.openLoader({
      loaderType: "circle-loader",
    });
    const productsIds = products.map((product) => product._id);
    const data = { product_ids: productsIds, isGenerated: true };
    const response = await postCreateGeneratedCatalog.getRequest({ data }).fetch();
    notificationStore.closeLoader();

    if (response.success) {
      modalsStore.open("DownloadModal", { data: response.success as any, type: "catalogFullItem" });
    }
  } else {
    modalsStore.open("DownloadModal", { data: products[0] as any, type: "product" });
  }
};
