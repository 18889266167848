import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { PromoCarouselSlideType } from "./promo-carousel.types";

import slide1Img from "src/external-ts/assets/images/auth/promo-carousel/slide-1.png";
import slide2Img from "src/external-ts/assets/images/auth/promo-carousel/slide-2.png";
import slide3Img from "src/external-ts/assets/images/auth/promo-carousel/slide-3.png";
import slide4Img from "src/external-ts/assets/images/auth/promo-carousel/slide-4.png";

import styles from "./promo-carousel.module.css";

export const generatePromoCarouselSlides = (): PromoCarouselSlideType[] => {
  const { localeStore } = useStores();
  const slides = [
    {
      img: slide1Img,
      title: localeStore.t('auth["promo-carousel"].slide1.title'),
      subtitle: localeStore.t('auth["promo-carousel"].slide1.subtitle'),
    },
    {
      img: slide2Img,
      title: localeStore.t('auth["promo-carousel"].slide2.title'),
      subtitle: localeStore.t('auth["promo-carousel"].slide2.subtitle'),
    },
    {
      img: slide3Img,
      title: localeStore.t('auth["promo-carousel"].slide3.title'),
      subtitle: localeStore.t('auth["promo-carousel"].slide3.subtitle'),
    },
    {
      img: slide4Img,
      title: localeStore.t('auth["promo-carousel"].slide4.title'),
      subtitle: localeStore.t('auth["promo-carousel"].slide4.subtitle'),
    },
  ];
  const result: PromoCarouselSlideType[] = [];

  slides.forEach(({ img, title, subtitle }) => {
    const obj: PromoCarouselSlideType = {
      render: ({ current }) => (
        <div className={clsx(styles.slide, { [styles.current]: current })}>
          <div className={styles.imageContainer}>
            <img src={img} alt="slide" />
          </div>

          <div className={styles.textContainer}>
            <div className={styles.title}> {title} </div>
            <div className={styles.subtitle}> {subtitle} </div>
          </div>
        </div>
      ),
    };

    result.push(obj);
  });

  return result;
};
