import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  SvgIcon,
  Tooltip,
  useBoolean,
  Typography,
  uniqId,
  clsx,
  useDidUpdate,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as MinusSVG } from "src/external-ts/assets/images/minus-grey.svg";
import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-grey.svg";

import styles from "./subaccounts-offer-counter.module.css";
import { additionalSubGbPriceMap } from "src/containers/settings/subscriptions/subscriptions.constants";
import { SupportedSubscriptionInterval } from "src/containers/settings/subscriptions/subscriptions.types";

type SubaccountsOfferCounterProps = {
  extraCount: number;
  planSubaccountsUnit: number;
  planStorageUnit: number;
  isCustomSubscription: boolean;
  subaccountAddonPrices: {
    monthlyPrice: number;
    yearlyPrice: number;
  };
  onAddExtraCount: () => void;
  onSubtractExtraCount: () => void;
  containerClassName?: string;
  planStorageIteration?: number;
  subscriptionToSelect: SupportedSubscriptionInterval;
};

export const SubaccountsOfferCounter: React.FC<SubaccountsOfferCounterProps> = observer(
  ({
    containerClassName,
    extraCount,
    planSubaccountsUnit,
    planStorageUnit,
    isCustomSubscription,
    subaccountAddonPrices,
    onAddExtraCount,
    onSubtractExtraCount,
    planStorageIteration = 50,
    subscriptionToSelect,
  }) => {
    const { localeStore, subscriptionsStore } = useStores();

    const [addExtraTooltipId] = useState(uniqId);
    const [subExtraTooltipId] = useState(uniqId);
    const [subaccountsOfferCounterTooltipId] = useState(uniqId);

    const addExtraTooltipBoolean = useBoolean();
    const subtractExtraTooltipBoolean = useBoolean();
    const subaccountsOfferTooltipBoolean = useBoolean();
    const isCurrentMonthlyPlan = subscriptionToSelect === "monthly";

    return (
      <>
        <div className={clsx(styles.container, containerClassName)}>
          <div
            className={clsx(styles.subaccountOffer, {
              [styles.disabled]: subscriptionsStore.isProLifetimeByOff100,
            })}
            id={subaccountsOfferCounterTooltipId}
            onMouseEnter={subaccountsOfferTooltipBoolean.setTruthy}
            onMouseLeave={subaccountsOfferTooltipBoolean.setFalsy}
          >
            <Button
              id={subExtraTooltipId}
              className={styles.subaccountOfferButton}
              appearance="unset"
              size="unset"
              onClick={onSubtractExtraCount}
              onMouseEnter={subtractExtraTooltipBoolean.setTruthy}
              onMouseLeave={subtractExtraTooltipBoolean.setFalsy}
              disabled={isCustomSubscription || extraCount === 0}
            >
              <SvgIcon size={[10, 1]} icon={MinusSVG} />
            </Button>
            <Typography size="small"> {extraCount + planSubaccountsUnit}</Typography>
            <Button
              id={addExtraTooltipId}
              className={styles.subaccountOfferButton}
              appearance="unset"
              size="unset"
              onClick={onAddExtraCount}
              onMouseEnter={addExtraTooltipBoolean.setTruthy}
              onMouseLeave={addExtraTooltipBoolean.setFalsy}
              disabled={isCustomSubscription}
            >
              <SvgIcon className={styles.plusIcon} size={8} icon={PlusSVG} />
            </Button>
          </div>
          <Typography className={styles.featureOfferAmount} size="medium600" tag="div">
            {extraCount * planStorageIteration + planStorageUnit}
            GB
          </Typography>
        </div>
        {subaccountAddonPrices && (
          <>
            <Tooltip
              appearance="secondary"
              position="bottom"
              target={subExtraTooltipId}
              onClose={subtractExtraTooltipBoolean.setValue}
              isOpen={subtractExtraTooltipBoolean.value}
              withAngle
            >
              <Typography size="extraSmall500">
                - US
                <strong>
                  ${subaccountAddonPrices[isCurrentMonthlyPlan ? "monthlyPrice" : "yearlyPrice"]}
                </strong>
                {isCurrentMonthlyPlan ? "/mo" : "/yr"}&nbsp;
                {localeStore.t('common.settings["extra-offer"]["extra-user"]')}&nbsp;&&nbsp;
                <strong>50GB</strong>
              </Typography>
            </Tooltip>
            <Tooltip
              appearance="secondary"
              position="bottom"
              target={addExtraTooltipId}
              onClose={addExtraTooltipBoolean.setValue}
              isOpen={addExtraTooltipBoolean.value}
              withAngle
            >
              <Typography size="extraSmall500">
                + US
                <strong>
                  ${subaccountAddonPrices[isCurrentMonthlyPlan ? "monthlyPrice" : "yearlyPrice"]}
                </strong>
                {isCurrentMonthlyPlan ? "/mo" : "/yr"}&nbsp;
                {localeStore.t('common.settings["extra-offer"]["extra-user"]')}&nbsp;&&nbsp;
                <strong>50GB</strong>
              </Typography>
            </Tooltip>
          </>
        )}
        <Tooltip
          appearance="promo"
          position="bottom"
          target={subaccountsOfferCounterTooltipId}
          isOpen={subaccountsOfferTooltipBoolean.value && subscriptionsStore.isProLifetimeByOff100}
          onClose={subaccountsOfferTooltipBoolean.setValue}
          withAngle
        >
          <span className={styles.contactText}>
            {localeStore.t(
              'settings.subscriptions.components.content["lifetime-offer"]["contact-us"]',
            )}
          </span>
        </Tooltip>
      </>
    );
  },
);
