import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { observer } from "mobx-react-lite";
import {
  Button,
  Modal,
  sleep,
  useDidUpdate,
  useCall,
  Heading,
  Typography,
} from "@gemlightbox/core-kit";

import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { getShopifyStoreURL } from "src/common/helpers/integrations.helpers";
import { useStores } from "src/hooks";
import { loadShopifyStatus, toggleShopifySelectStoreModal } from "src/redux/reducers/shopify";
import { setActiveShopifyShop } from "src/redux/reducers/user";
import { putActiveShop } from "src/api";
import { ErrorCodes } from "src/constants";
import StoresList from "./stores-list/stores-list";

import styles from "./shopify-select-store-modal.module.css";

const ShopifySelectStoreModal = observer(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { localeStore, subscriptionsStore } = useStores();

  const {
    settingsList,
    modals: {
      selectStore: { isOpened },
    },
  } = useSelector(({ shopifyReducer }) => shopifyReducer);

  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState("");

  const usePutActiveShopCall = useCall(putActiveShop);
  const activeShopifyShop = useSelector(({ userReducer }) => userReducer.activeShopifyShop);

  useDidUpdate(() => {
    if (settingsList.length) setStore(settingsList[0].shop);
  }, [settingsList]);

  const handleStoreChange = (_isChecked, value) => {
    if (value !== store) setStore(value);
  };

  const handleLogin = async () => {
    const shop_url = getShopifyStoreURL(store);

    setLoading(true);

    try {
      await usePutActiveShopCall.submit({ data: { shop_url } });
      dispatch(setActiveShopifyShop(store));
      await subscriptionsStore.loadSubscription();
    } catch (e) {
      if (e?.response?.data?.code === ErrorCodes.SUBSCRIPTION_NOT_FOUND) {
        const subscriptionPath = getSettingsTab(SettingTabNames.subaccounts).item.path;
        navigate(subscriptionPath);
        return handleClose();
      }

      console.error(e);
    } finally {
      setLoading(false);
    }

    handleClose();
    await sleep(0);
    const integrationsTab = getSettingsTab(SettingTabNames.integrations).item;
    navigate(integrationsTab.shopify.settings.path);
  };

  const handleClose = () => dispatch(toggleShopifySelectStoreModal());

  const checkIfLogInIsDisabled = () => !store || activeShopifyShop === store || loading;

  return (
    <Modal
      isOpen={isOpened}
      setClose={handleClose}
      withCross={true}
      contentClassName={styles.container}
      size="small"
    >
      <Heading className={styles.title} tag="h2" color="textSecondary">
        {localeStore.t('settings.integrations.modals["shopify-select-store"].title')}
      </Heading>
      <Typography className={styles.subtitle} size="small" color="textTertiary">
        {localeStore.t('settings.integrations.modals["shopify-select-store"].subtitle')}
      </Typography>

      <StoresList store={store} onChange={handleStoreChange} list={settingsList} />

      <div className={styles.buttons}>
        <Button
          className={styles.button}
          disabled={loading}
          appearance="primaryOutlined"
          onClick={handleClose}
        >
          {localeStore.t('settings.integrations.modals["shopify-select-store"].buttons.cancel')}
        </Button>
        <Button className={styles.button} disabled={checkIfLogInIsDisabled()} onClick={handleLogin}>
          {localeStore.t('settings.integrations.modals["shopify-select-store"].buttons.login')}
        </Button>
      </div>
    </Modal>
  );
});

export default ShopifySelectStoreModal;
