import { RemoveBackgroundItem } from "./remove-background-block.types";

export const removeBackgroundBlockSubId = "removeBackgroundBlockSubId";

export const removeBackgroundMap = {
  white: "white",
  black: "black",
  transparent: "transparent",
};

export const removeBackgroundItems: RemoveBackgroundItem[] = [
  {
    type: "white",
    staticUrl: "/create-background-white.png",
    tooltipText: "White background",
  },
  {
    type: "black",
    staticUrl: "/create-background-black.png",
    tooltipText: "Black background",
  },
  {
    type: "transparent",
    staticUrl: "/create-background-transparent.png",
    tooltipText: "Transparent background",
  },
];
