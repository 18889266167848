import React from "react";
import { observer } from "mobx-react-lite";
import { Modal, Button, ObjectType } from "@gemlightbox/core-kit";
import styles from "./language-unfetch-modal.module.css";
import languageUnfetchImg from "src/common/images/language-unfetch-header.svg";

export interface LanguageUnfetchModalProps {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed?: VoidFunction;
  options: ObjectType;
}

export const LanguageUnfetchModal: React.FC<LanguageUnfetchModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const handleRetry = () => {
      location.reload();
    };

    return (
      <Modal
        scrollWrapperClassName={styles.modalContainer}
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
        withCross
      >
        <img src={languageUnfetchImg} alt="" />
        <div>
          <b className={styles.title}>The language pack download failed, please retry</b>
          <p className={styles.subtitle}>Otherwise, you won't be able to use GemHub normally</p>
        </div>
        <Button onClick={handleRetry} className={styles.button} type="button">
          Retry
        </Button>
      </Modal>
    );
  },
);

export default LanguageUnfetchModal;
