import React from "react";
import { observer } from "mobx-react-lite";
import {
  Typography,
  Loader,
  Button,
  SelectGroupOption,
  SelectGroup,
  SvgIcon,
  Image,
  clsx,
  globalStyles,
} from "@gemlightbox/core-kit";

import { ExtendedMediaModel } from "src/store";
import { MediaType } from "src/models";
import { useMediaAssign, useStores } from "src/hooks";
import { MediaTypeNames } from "src/containers/media";

import { ReactComponent as ArrowRightSVG } from "src/external-ts/assets/images/arrow-right-oblong-grey.svg";
import styles from "./assign-media-item.module.css";

export type AssignMediaItemProps = {
  data: ExtendedMediaModel;
};

export const AssignMediaItem: React.FC<AssignMediaItemProps> = observer(({ data }) => {
  const { thumbnail, cropFile, type } = data;

  const { localeStore, modalsStore, userStore } = useStores();

  const isSKUTextEditable = !userStore.isCTFSub;

  const {
    searchValue,
    title,
    value,
    error,
    canCreateNew,
    inProcess,
    hasProduct,
    options,
    onGoToProduct,
    onSearchProduct,
    onBlur,
    onChangeProduct,
    onCreateProduct,
    onDropdownOpen,
    onDropdownClose,
  } = useMediaAssign({ data });

  const getPreviewSource = () => {
    let result: string = thumbnail;

    if (type === MediaType.blink && cropFile) result = cropFile.small;

    return result;
  };

  const handleGoToProduct = () => {
    modalsStore.close("AssignToProductSidebar");
    onGoToProduct();
  };

  return (
    <div className={styles.assignMediaItemContainer} data-cy="assign-to-product-item">
      <Image className={styles.assignMediaImage} src={getPreviewSource()} />

      <div className={styles.assignMediaDataWrapper}>
        <div className={styles.assignMediaInfo}>
          <Typography size="small" color="textTertiary">
            {localeStore.t('media.modals["assign-to-product-sidebar"]["media-item"].sku.title')}
          </Typography>
          <Typography size="small" color="textSecondary">
            {title ||
              localeStore.t(
                'media.modals["assign-to-product-sidebar"]["media-item"].sku["not-assigned-value"]',
              )}
          </Typography>
          <Typography size="small" color="textTertiary">
            {localeStore.t(
              'media.modals["assign-to-product-sidebar"]["media-item"]["media-type"].title',
            )}
          </Typography>
          <Typography size="small" color="textSecondary">
            {MediaTypeNames[type]}
          </Typography>
        </div>

        <div className={styles.goToProductWrapper}>
          <Button
            className={clsx({ [globalStyles.hideElement]: !hasProduct })}
            appearance="promoGhost"
            size="large"
            onClick={handleGoToProduct}
            disabled={!hasProduct || inProcess}
            data-cy="go-to-product"
          >
            {localeStore.t('common.buttons["go-to-product"]')}
            <SvgIcon icon={ArrowRightSVG} />
          </Button>
        </div>
      </div>

      <SelectGroup
        className={styles.selectSKU}
        label={localeStore.t(
          'media.modals["assign-to-product-sidebar"]["media-item"].select.label',
        )}
        placeholder={localeStore.t(
          'media.modals["assign-to-product-sidebar"]["media-item"].select.placeholder',
        )}
        searchValue={searchValue}
        value={value}
        error={error}
        onBlur={onBlur}
        onChange={onChangeProduct}
        onSearch={onSearchProduct}
        onDropdownOpen={onDropdownOpen}
        onDropdownClose={onDropdownClose}
        onEnter={onCreateProduct}
        disableSearch={!isSKUTextEditable}
        loading={inProcess}
        data-cy="assign-to-product-input"
      >
        {inProcess && <Loader className={styles.dropdownLoader} size={24} />}

        {canCreateNew && !options.length && (
          <Button
            className={styles.createNewBtn}
            appearance="secondaryGhost"
            onClick={onCreateProduct}
            data-cy="create-new"
          >
            {localeStore.t('common.buttons["create-new-sku"]')}
          </Button>
        )}

        {!inProcess &&
          options.map(({ label, value }) => (
            <SelectGroupOption key={value} value={value} data-cy={`assign-to-${label}`}>
              {label}
            </SelectGroupOption>
          ))}
      </SelectGroup>
    </div>
  );
});
