import React from "react";
import { Table, Typography, SvgIcon, clsx } from "@gemlightbox/core-kit";

import { OrderExtendedModel } from "src/api";
import { useStores } from "src/hooks";
import { orderTableColumns } from "./order-table.constants";

import { ReactComponent as MediaSVG } from "src/external-ts/assets/images/navbar/media-page.grey.svg";

import styles from "./order-table.module.css";

type OrderTableProps = {
  totalPrice: number;
  rows: OrderExtendedModel["lineItems"];
};

export const OrderTable: React.FC<OrderTableProps> = ({ rows, totalPrice }) => {
  const { localeStore } = useStores();

  const renderCell = (cellName: string, rowIndex: number) => {
    switch (cellName) {
      case "sku":
      case "quantity":
        return (
          <Typography size="extraSmall" color="textSecondary">
            {rows[rowIndex][cellName]}
          </Typography>
        );
      case "amount":
        return (
          <Typography size="small600" color="textSecondary">
            {rows[rowIndex][cellName]}
          </Typography>
        );
      case "price":
        return (
          <Typography size="small600" color="textSecondary">
            {rows[rowIndex][cellName]} {rows[rowIndex].currency}
          </Typography>
        );
      case "media": {
        const media = rows[rowIndex].product.images[0];
        return (
          <div className={clsx({ [styles.noMediaIconContainer]: !media })}>
            {media ? (
              <img className={styles.orderMediaCellImage} src={media.file?.small} />
            ) : (
              <SvgIcon icon={MediaSVG} />
            )}
          </div>
        );
      }
    }
  };

  return (
    <Table className={styles.orderTableContainer}>
      <thead className={styles.orderTableHeader}>
        <tr>
          {orderTableColumns.map(({ name, label }) => {
            return (
              <th key={`${name}-header`} className={styles[name]}>
                <Typography size="extraSmall" color="textTertiary">
                  {label}
                </Typography>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((_, index) => {
          return (
            <tr key={`row-${index}`} className={styles.orderTableRowContainer}>
              {orderTableColumns.map(({ name }) => {
                return <td key={name}>{renderCell(name, index)}</td>;
              })}
            </tr>
          );
        })}
        <tr>
          {orderTableColumns.map(({ name }) => {
            if (name !== "price" && name !== "amount") return <td key={`${name}-bottom`} />;

            if (name === "price") {
              return (
                <td key={`${name}-bottom`}>
                  <div className={styles.orderTableBottomRowCellContent}>
                    <Typography size="small600" color="textSecondary">
                      {localeStore.t("integrations.payments.order.total")}
                    </Typography>
                  </div>
                </td>
              );
            } else {
              return (
                <td key={name} className={styles.orderTableBottomRow}>
                  <div className={styles.orderTableBottomRowCellContent}>
                    <Typography size="small600" color="textSecondary">
                      {totalPrice} {rows[0]?.currency || ""}
                    </Typography>
                  </div>
                </td>
              );
            }
          })}
        </tr>
      </tbody>
    </Table>
  );
};
