import React from "react";
import { observer } from "mobx-react-lite";
import { Checkbox, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { AttributeModel } from "src/models";
import { getAttributeDisplayName } from "src/utils";

import styles from "./available-attributes.module.css";

interface AvailableAttributesProps {
  attributes: AttributeModel[];
  onVisibilityChange: (id: number) => void;
}

export const AvailableAttributes: React.FC<AvailableAttributesProps> = observer(
  ({ attributes, onVisibilityChange }) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography size="small600" color="textSecondary">
            {localeStore.t(
              'products.modals["manage-column-sidebar"]["available-attributes"].title',
            )}
          </Typography>
          <Typography size="extraSmall" color="textTertiary">
            {localeStore.t(
              'products.modals["manage-column-sidebar"]["available-attributes"].subtitle',
            )}
          </Typography>
        </div>
        <div className={styles.attributesListContainer}>
          {attributes.map(({ id, name, displayName, isHidden }, index) => (
            <Checkbox
              key={index}
              className={styles.attribute}
              label={getAttributeDisplayName({ name, displayName } as AttributeModel)}
              checked={!isHidden}
              onChange={() => onVisibilityChange(id)}
              disableError
            />
          ))}
        </div>
      </div>
    );
  },
);

export default AvailableAttributes;
