import React from "react";
import CallApp from "callapp-lib";

import { Button, SvgIcon, useQuery } from "@gemlightbox/core-kit";
import { ReactComponent as LogoSVG } from "src/external-ts/assets/images/welcome/logo.svg";
import { ReactComponent as DownloadSVG } from "src/external-ts/assets/images/welcome/download.svg";
import { ReactComponent as AngleRightSVS } from "src/common/images/angle-right.svg";
import { INTRO_LIST } from "./download.constants";
import { usePageViewEvent, useStores } from "src/hooks";
import { useNavigate } from "react-router-dom";
import { pushDataLayerEvent } from "src/utils";

import styles from "./download.module.css";
import { CallappOptions } from "callapp-lib/dist/type/types";

export const WelcomeDownloadPage: React.FC = () => {
  usePageViewEvent();
  const { query } = useQuery();
  const { localeStore } = useStores();
  const navigate = useNavigate();

  const handleDownloadClick = () => {
    const path = query.from === "gemcam" ? "emailVerification?/camera" : "";
    let url: string;
    if (window.$platform.os == "Win") {
      const options = {
        scheme: {
          protocol: "ms-windows-store",
        },
      } as CallappOptions;
      const callLib = new CallApp(options);
      callLib.open({
        path: "pdp/?productid=9NPMPGT367QS",
        callback: () => {
          pushDataLayerEvent({ event: "click:download_gemhub_windows_app" });
        },
      });
    } else {
      url = "https://apps.apple.com/us/app/id6458103197";
      pushDataLayerEvent({ event: "click:download_gemhub_mac_app" });
    }
    const options = {
      scheme: {
        protocol: "GemLightbox",
      },
    } as CallappOptions;

    const callLib = new CallApp(options);

    callLib.open({
      path,
      callback: () => {
        url && window.open(url);
      },
    });
  };

  const handleBack = () => navigate(-1);

  const isWindows = window.$platform.os === "Win";
  const titleKey = isWindows ? "app.download.wintTitle" : "app.download.title";
  const additionalKey = isWindows ? "app.download.winAdditional" : "app.download.additional";

  return (
    <div className={styles.downloadPageContainer}>
      <div className={styles.appLogo}>
        <SvgIcon size={196} icon={LogoSVG}></SvgIcon>
      </div>
      <div className={styles.appIntro}>
        <div className={styles.title}>{localeStore.t(titleKey)}</div>
        <div className={styles.subtitle}>{localeStore.t("app.download.subtitle")}</div>
        <div className={styles.introList}>
          {INTRO_LIST.map(({ icon, desc }) => (
            <div key={desc} className={styles.introItem}>
              <SvgIcon className={styles.icon} size={48} icon={icon}></SvgIcon>
              <div className={styles.desc}>{localeStore.t(desc)}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.btns}>
        <Button className={styles.appDownload} appearance="secondary" onClick={handleDownloadClick}>
          <SvgIcon icon={DownloadSVG}></SvgIcon> {localeStore.t("app.download.download")}
        </Button>
        <Button className={styles.inBrowser} appearance="secondaryOutlined" onClick={handleBack}>
          <SvgIcon icon={AngleRightSVS} className={styles.angleRight} />
          {localeStore.t("common.buttons.back")}
        </Button>
      </div>
      <div className={styles.systemRequirements}>{localeStore.t(additionalKey)}</div>
    </div>
  );
};
