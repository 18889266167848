import React, { lazy } from "react";
import { observer } from "mobx-react-lite";
import { FormField, Heading, SvgIcon, Typography, clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MediaFile } from "src/api/graphql-api/share-settings/share-settings.interface";
import { DropImageError, Logo, LogoImageType } from "../../logo";
import { ViewExample } from "../view-example";

import styles from "./company-info.module.css";
import CompanyLogoStepOne from "src/external-ts/assets/images/share-settings/company-logo-step-1.jpg";
import CompanyLogoStepTwo from "src/external-ts/assets/images/share-settings/company-logo-step-2.jpg";
import CompanyInfoStepOne from "src/external-ts/assets/images/share-settings/company-info-step-1.jpg";
import CompanyInfoStepTwo from "src/external-ts/assets/images/share-settings/company-info-step-2.jpg";
import { ReactComponent as CircleWarningSVG } from "src/external-ts/assets/images/circle-warning-icon-red.svg";
import { UploadToolTip } from "src/external-ts/components/business/tooltips/upload-tooltip/upload-tooltip";

interface CompanyInfoProps {
  companyRef: React.RefObject<HTMLDivElement>;
  descriptionLength: number;
  initialLogo: MediaFile | string | null;
  logo: MediaFile | string | null;
  resetLogo: boolean;
  handleLogoChange: (data: LogoImageType) => void;
}

export const CompanyInfo: React.FC<CompanyInfoProps> = observer(
  ({ companyRef, descriptionLength, logo, initialLogo, resetLogo, handleLogoChange }) => {
    const { localeStore, modalsStore, tooltipStore } = useStores();
    const logoToSet = resetLogo ? initialLogo : logo;

    const openCompanyLogoModal = () => {
      modalsStore.open("ViewExampleModal", {
        size: 720,
        title: `${localeStore.t('settings["share-settings"].link')}`,
        subTitle: [
          `${localeStore.t(
            'settings["share-settings"]["company-info"].logo["example-info"].titles.0',
          )}`,
          `${localeStore.t(
            'settings["share-settings"]["company-info"].logo["example-info"].titles.1',
          )}`,
        ],
        description: [
          `${localeStore.t(
            'settings["share-settings"]["company-info"].logo["example-info"].description.0',
          )}`,
          `${localeStore.t(
            'settings["share-settings"]["company-info"].logo["example-info"].description.1',
          )}`,
        ],
        images: [CompanyLogoStepOne, CompanyLogoStepTwo],
      });
    };

    const openCompanyInfoModal = () => {
      modalsStore.open("ViewExampleModal", {
        size: 555,
        title: `${localeStore.t('settings["share-settings"].link')}`,
        subTitle: [
          `${localeStore.t(
            'settings["share-settings"]["company-info"].info["example-info"].title.0',
          )}`,
          `${localeStore.t(
            'settings["share-settings"]["company-info"].info["example-info"].title.1',
          )}`,
        ],
        description: [
          `${localeStore.t(
            'settings["share-settings"]["company-info"].info["example-info"].description.0',
          )}`,
          `${localeStore.t(
            'settings["share-settings"]["company-info"].info["example-info"].description.1',
          )}`,
        ],
        images: [CompanyInfoStepOne, CompanyInfoStepTwo],
      });
    };

    const handleImageError = ({ type, message }: DropImageError) => {
      tooltipStore.open(
        <UploadToolTip>
          <SvgIcon icon={CircleWarningSVG} size={16} />
          {type === "MAX_SIZE_EXCEEDED" && localeStore.t("settings.logo.tip.size")}
          {type === "MAX_WIDTH_EXCEEDED" && localeStore.t("settings.logo.tip.width")}
        </UploadToolTip>,
        {
          target: document.body,
          appearance: "promo",
          position: "bottomRight",
        },
        {
          timeout: 5000,
        },
      );
    };

    return (
      <div ref={companyRef} className={styles.container} data-cy="company">
        <Heading className={styles.title} tag="h2">
          {localeStore.t('settings["share-settings"]["company-info"].title')}
        </Heading>
        <div className={styles.companyLogoContainer}>
          <Typography color="textSecondary" size="small600" data-cy="company-subtitle">
            {localeStore.t('settings["share-settings"]["company-info"].logo.title')}
          </Typography>
          <Typography color="textTertiary" size="extraSmall" data-cy="company-description">
            {localeStore.t('settings["share-settings"]["company-info"].logo.description')}{" "}
            <ViewExample callback={openCompanyLogoModal} />
          </Typography>
          <Logo
            containerClassName={styles.logoContainer}
            detailsClassName={styles.logoDetails}
            accept="image/png, image/jpg, image/jpeg"
            tip={localeStore.t('settings["share-settings"]["company-info"].logo.tip')}
            appearance="primaryV2"
            title={localeStore.t('settings["share-settings"]["company-info"].logo.subTitle')}
            initialLogo={logoToSet}
            onImageChange={handleLogoChange}
            onImageError={handleImageError}
            maxWidth={Infinity}
          />
        </div>
        <div className={styles.companyInfoContainer}>
          <Typography color="textSecondary" size="small600" data-cy="company-subtitle">
            {localeStore.t('settings["share-settings"]["company-info"].info.title')}
          </Typography>
          <Typography color="textTertiary" size="extraSmall" data-cy="company-description">
            {localeStore.t('settings["share-settings"]["company-info"].info.description')}{" "}
            <ViewExample callback={openCompanyInfoModal} />
          </Typography>
          <div className={styles.fieldsContainer}>
            <div className={styles.fieldsRow}>
              <FormField
                className={styles.field}
                inputWrapperClassName={styles.fieldWrapper}
                type="text"
                name="companyName"
                label={localeStore.t(
                  'settings["share-settings"]["company-info"].fields.name.label',
                )}
                placeholder={localeStore.t(
                  'settings["share-settings"]["company-info"].fields.name.placeholder',
                )}
                appearance="primaryV2"
                data-cy="company-info-name"
              />
              <FormField
                className={styles.field}
                inputWrapperClassName={styles.fieldWrapper}
                type="text"
                name="companyWebsite"
                label={localeStore.t(
                  'settings["share-settings"]["company-info"].fields.website.label',
                )}
                placeholder="https://site.com"
                appearance="primaryV2"
                data-cy="company-info-website"
              />
            </div>
            <FormField
              inputWrapperClassName={clsx(styles.descriptionFieldWrapper, styles.fieldWrapper)}
              name="companyDescription"
              label={localeStore.t(
                'settings["share-settings"]["company-info"].fields.description.label',
              )}
              placeholder={localeStore.t(
                'settings["share-settings"]["company-info"].fields.description.placeholder',
              )}
              labelMessage={`${descriptionLength}/500`}
              appearance="primaryV2"
              type="textarea"
              maxLength={500}
              max="test"
              data-cy="company-info-description"
            />
          </div>
        </div>
      </div>
    );
  },
);
