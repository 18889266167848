import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Pagination, useDidUpdate, clsx } from "@gemlightbox/core-kit";

import { PageContainer } from "src/external-ts/components";
import { useStores } from "src/hooks";
import WSConnection from "src/common/socket";
import { CatalogAppliedFilters } from "./catalog-applied-filters";
import { CatalogDetailsActionsPanel } from "./catalog-details-actions-panel";
import { CatalogDetailsControlPanel } from "./catalog-details-control-panel";
import { CatalogInputsPanel } from "./catalog-inputs-panel";
import { DragNDrop } from "./product-order-modal";
import { ProductsListPlaceholder } from "./products-list-placeholder";
import { ProductsList } from "./products-list";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./catalog-details.module.css";

export const CatalogDetailsList = observer(() => {
  const params = useParams<{ catalogId: string }>();
  const catalogIdParam = Number(params.catalogId);

  const { catalogStore, localeStore, userStore } = useStores();

  useDidUpdate(() => catalogStore.loadCatalog(catalogIdParam), [catalogIdParam], true);

  useDidUpdate(
    () => {
      const catalogWS = new WSConnection("/catalog", userStore.token);

      catalogWS.on("catalog/CATALOG_RELOAD", ({ payload }: any) => {
        if (payload.catalog.id === catalogIdParam) catalogStore.loadCatalog(catalogIdParam);
      });

      return () => catalogWS.destroy();
    },
    [userStore.token, catalogIdParam],
    true,
  );

  const handlePageChange = (page: number) => {
    catalogStore.loadCatalog(catalogIdParam, { ...catalogStore.filters, page });
  };

  const handleLimitChange = (limit: number) => {
    catalogStore.loadCatalog(catalogIdParam, { ...catalogStore.filters, limit, page: 1 });
  };

  return (
    <PageContainer className={styles.container}>
      <CatalogDetailsControlPanel />
      <CatalogDetailsActionsPanel />

      <div className={styles.content} data-cy="catalog-details">
        <ProductsListPlaceholder />

        {catalogStore.canShowCatalog && catalogStore.orderModalOpenStatus && <DragNDrop />}
        {catalogStore.canShowCatalog && (
          <>
            <div className={clsx(styles.wrapper, globalStyles.addScrollStyles)}>
              <div className={styles.catalogDetailsContainer}>
                <CatalogInputsPanel />
                <CatalogAppliedFilters />
              </div>
              <ProductsList />
            </div>
            <Pagination
              totalItemsText={localeStore.t('components.ui.pagination["total-items"]')}
              prevText={localeStore.t("components.ui.pagination.prev")}
              nextText={localeStore.t("components.ui.pagination.next")}
              perPageText={localeStore.t('components.ui.pagination["per-page"]')}
              page={catalogStore.filters.page}
              limit={catalogStore.filters.limit}
              totalPages={catalogStore.totalPages}
              totalItems={catalogStore.filteredProductsAmount}
              onPageChange={handlePageChange}
              onLimitChange={handleLimitChange}
            />
          </>
        )}
      </div>
    </PageContainer>
  );
});
