import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Typography, useDebounce, useDidUpdate, deepObserve } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { editMediaStore } from "../../edit-media.store";
import { GroupBlock } from "../group-block";
import { Layer } from "./layer";
import { MeasurementLayer } from "./measurement-layer";

import styles from "./layers.module.css";

export const Layers: React.FC = observer(() => {
  const { renderer, isInMeasurement, isInResize } = editMediaStore;
  const canvasSize = editMediaStore.renderer.canvasSize;
  const canvasTranslation = editMediaStore.renderer.canvasTranslation;

  const { localeStore } = useStores();

  const mainImagePreviewRef = useRef<HTMLImageElement>(null);

  const selectedComponent = renderer.selectedComponent;
  const isMainComponentSelected = selectedComponent === (editMediaStore.mainImageComponent as any);

  const mainLayerDebounce = useDebounce(100);

  const handleMainComponentLayerClick = () => {
    if (isInMeasurement || isInResize) return;
    renderer.selectComponent(editMediaStore.mainImageComponent);
  };

  useDidUpdate(
    () => {
      const callback = () => {
        mainLayerDebounce.debounce(() => {
          if (!mainImagePreviewRef.current) return;

          const mainImageCast = renderer.export.getComponentsCanvasCast(
            editMediaStore.mainImageComponent,
          );
          mainImagePreviewRef.current.replaceChildren(mainImageCast);
        });
      };

      callback();

      return deepObserve(editMediaStore.mainImageComponent, callback);
    },
    [
      editMediaStore.mainImageComponent,
      canvasSize.width,
      canvasSize.height,
      canvasTranslation.x,
      canvasTranslation.y,
    ],
    true,
  );

  return (
    <GroupBlock className={styles.layersBlockGroup}>
      <div className={styles.layersTitle}>
        <Typography size="small600" color="textSecondary">
          {localeStore.t('["edit-media"].inspector.helpers.layers.title')}
        </Typography>
        <Typography size="extraSmall" color="textTertiary">
          {localeStore.t('["edit-media"]["max-10"]')}
        </Typography>
      </div>

      <Layer
        name="SKU"
        layerPreviewRef={mainImagePreviewRef}
        onClick={handleMainComponentLayerClick}
        selected={isMainComponentSelected}
        selectable={!isInResize && !isInMeasurement && !isMainComponentSelected}
        borderPreview
      />

      <MeasurementLayer />
    </GroupBlock>
  );
});

export default Layers;
