import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Input, Radio, useDidUpdate, Select, SelectOption } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ATTRIBUTE_NUMBER_SETTINGS } from "src/constants";
import { WithTitle } from "../components";

import styles from "./number-settings.module.css";

export interface NumberSettingsProps {
  suffix: string;
  prefix: string;
  onSuffixChange: (value: string) => void;
  onPrefixChange: (value: string) => void;
}

export const NumberSettings: React.FC<NumberSettingsProps> = observer(
  ({ suffix, prefix, onSuffixChange, onPrefixChange }) => {
    const { productsStore, localeStore } = useStores();

    const [spValue, setSpValue] = useState(suffix || prefix);

    const isCurrency =
      spValue === "currency" ||
      !!productsStore.currenciesOptions.find(({ value }) => value === spValue);
    const isCustom = !isCurrency && spValue !== "%";

    const initialCurrency: SelectOption<string> | undefined = useMemo(() => {
      const usd = productsStore.currencies.find(({ name }) => name === "USD");
      if (!usd) return;

      return {
        value: `${usd.icon} ${usd.name}`,
        label: `${usd.icon} ${usd.name}`,
      };
    }, [productsStore.currencies]);

    const [isPrefix, setIsPrefix] = useState(() => {
      if (!prefix) return !suffix;
      return !!prefix;
    });
    const [isSuffix, setIsSuffix] = useState(!!suffix);

    useDidUpdate(() => {
      handleSpValueChange();
    }, [isSuffix, isPrefix]);

    const handleSpValueChange = (value?: string) => {
      const _isCurrency = value === "currency";
      const v = _isCurrency ? initialCurrency?.value ?? spValue : value ?? spValue;

      if (isPrefix) onPrefixChange(v);
      if (isSuffix) onSuffixChange(v);

      if (value !== undefined) setSpValue(v);
    };

    const handleCurrencyChange = (option?: SelectOption<string>) => {
      handleSpValueChange(option?.value);
    };

    const handleSuffixChange = () => {
      if (isPrefix) {
        setIsSuffix(true);
        setIsPrefix(false);
      }
    };

    const handlePrefixChange = () => {
      if (isSuffix) {
        setIsPrefix(true);
        setIsSuffix(false);
      }
    };

    return (
      <>
        <WithTitle
          title={localeStore.t(
            'products.modals["create-attribute-sidebar"]["number-settings"]["type-title"]',
          )}
          contentClassName={styles.spType}
        >
          <div className={styles.options}>
            {ATTRIBUTE_NUMBER_SETTINGS.map(({ name, value }) => (
              <Radio
                data-cy={`${name}-value-type`}
                key={name}
                label={name}
                value={value}
                checked={
                  value === "currency" ? isCurrency : value === "" ? isCustom : spValue === value
                }
                disableError
                onChange={() => handleSpValueChange(value)}
              />
            ))}
          </div>

          {isCurrency && (
            <Select
              data-name="currency-dropdown"
              appearance="primaryV2"
              label={localeStore.t(
                'products.modals["create-attribute-sidebar"]["number-settings"]["currency-label"]',
              )}
              initiallySelectedOptions={
                spValue === "currency" ? null : { value: spValue, label: spValue }
              }
              selectedOptionsKeys={isCurrency ? spValue : null}
              options={productsStore.currenciesOptions}
              onChange={handleCurrencyChange}
              disableClearing
            />
          )}
        </WithTitle>

        <WithTitle
          title={localeStore.t(
            'products.modals["create-attribute-sidebar"]["number-settings"].location.title',
          )}
          contentClassName={styles.location}
        >
          <Radio
            data-cy="before-content"
            label={localeStore.t(
              'products.modals["create-attribute-sidebar"]["number-settings"].location["before-label"]',
            )}
            checked={isPrefix}
            disableError
            onChange={handlePrefixChange}
          />
          <Radio
            data-cy="after-content"
            label={localeStore.t(
              'products.modals["create-attribute-sidebar"]["number-settings"].location["after-label"]',
            )}
            checked={isSuffix}
            disableError
            onChange={handleSuffixChange}
          />
        </WithTitle>

        {isCustom && (
          <Input
            data-cy="custom-value-field"
            appearance="primaryV2"
            label={localeStore.t(
              'products.modals["create-attribute-sidebar"]["number-settings"].custom.label',
            )}
            placeholder={localeStore.t(
              'products.modals["create-attribute-sidebar"]["number-settings"].custom.placeholder',
            )}
            value={spValue}
            onChange={handleSpValueChange}
          />
        )}
      </>
    );
  },
);
