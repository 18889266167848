import React from "react";
import { Typography, formatDate } from "@gemlightbox/core-kit";

import { OrderModel } from "src/api";
import { PaymentsTableStatusCell } from "../payments-table-status-cell";
import { paymentsTableColumns } from "../../../payments.page.constants";
import { PaymentsTableRowType } from "../../../payments.page.types";

import styles from "./payments-table-row.module.css";

type PaymentsTableRowProps = {
  data: OrderModel;
  onOrderClick: (orderId: number) => void;
};

export const PaymentsTableRow: React.FC<PaymentsTableRowProps> = ({ data, onOrderClick }) => {
  const renderCell = (cellName: keyof PaymentsTableRowType) => {
    switch (cellName) {
      case '"createdAt"':
        return (
          <Typography size="extraSmall" color="textTertiary">
            {formatDate(data.createdAt, "MMM DD, hh:MM AA")}
          </Typography>
        );
      case "amount":
        return (
          <Typography size="small600" color="textSecondary">
            {data.amount}
          </Typography>
        );
      case "status":
        return <PaymentsTableStatusCell status={data.status} />;
      case "sku":
        return (
          <Typography size="small" color="textSecondary">
            {data.lineItems[0].sku}
          </Typography>
        );
      case "currency":
        return (
          <Typography size="small" color="textSecondary">
            {data.currency}
          </Typography>
        );
      case "customer":
        return (
          <Typography size="small" color="textSecondary">
            {data.customerEmail}
          </Typography>
        );
    }
  };

  const handleGoToOrder = () => onOrderClick(data.id);

  return (
    <tr className={styles.rowContainer} onClick={handleGoToOrder}>
      {paymentsTableColumns.map(({ name }) => {
        return <td key={name}>{renderCell(name)}</td>;
      })}
    </tr>
  );
};
