import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Pagination,
  Heading,
  useDidMount,
  useDidUnmount,
  useDidUpdate,
} from "@gemlightbox/core-kit";

import { PageContainer } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ControlPanel } from "./control-panel";
import { FiltersPanel } from "./filters-panel";
import { SelectedProductsPanel } from "./selected-products-panel";
import { SelectedFiltersPanel } from "./selected-filters-panel";
import { ProductsList } from "./products-list";
import { ProductsListPlaceholder } from "./products-list-placeholder";

import styles from "./catalog-add-products.module.css";

export const CatalogAddProductsPage: React.FC = observer(() => {
  const { catalogId } = useParams<{ catalogId: string }>();

  const navigate = useNavigate();

  const { catalogStore, productsStore, localeStore } = useStores();

  const isAllCatalog = catalogStore.catalog?.is_all;

  const canShowProducts = !!productsStore.productsAmount && !isAllCatalog;

  const handlePageChange = (page: number) => {
    productsStore.loadProductsList({ ...productsStore.filters, page });
  };

  const handleLimitChange = (limit: number) => {
    productsStore.loadProductsList({ ...productsStore.filters, limit, page: 1 });
  };

  useDidMount(() => {
    catalogStore.loadCatalog(Number(catalogId));
    productsStore.loadProductsList({
      ...productsStore.filters,
      catalog_ids_to_exclude: [Number(catalogId)],
    });
  });

  useDidUpdate(() => {
    //you cannot add products to the 'all products' catalog
    if (isAllCatalog) navigate("/catalog", { replace: true });
  }, [isAllCatalog]);

  useDidUnmount(() => productsStore.resetStore());

  return (
    <PageContainer className={styles.container}>
      <ControlPanel />
      <Heading tag="h3" color="textSecondary">
        {localeStore.t('["catalog-add-products"].title')}
      </Heading>
      <FiltersPanel />
      <SelectedProductsPanel />
      <SelectedFiltersPanel />

      {!canShowProducts && <ProductsListPlaceholder />}
      {canShowProducts && (
        <>
          <ProductsList />
          <Pagination
            totalItemsText={localeStore.t('components.ui.pagination["total-items"]')}
            prevText={localeStore.t("components.ui.pagination.prev")}
            nextText={localeStore.t("components.ui.pagination.next")}
            perPageText={localeStore.t('components.ui.pagination["per-page"]')}
            page={productsStore.filters.page}
            totalPages={productsStore.totalPages}
            totalItems={productsStore.resultsProductsAmount}
            limit={productsStore.filters.limit}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
          />
        </>
      )}
    </PageContainer>
  );
});
