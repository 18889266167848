import { makeAutoObservable, runInAction } from "mobx";
import { MaybeArray, toArrayOptions } from "@gemlightbox/core-kit";

import { AttributeModel, AttributeRequestModel } from "src/models";
import { getAttributes, postAttribute, putAttribute, deleteAttribute } from "src/api";
import { AttributesFiltersType, AttributesNamesMapType } from "./attributes.store.types";

export class AttributesStore {
  private _loading = false;
  private readonly _initialFilters: AttributesFiltersType = {
    search: "",
    sort: "order_column",
  };
  private _filters: AttributesFiltersType = this._initialFilters;
  private _attributes: AttributeModel[] = [];
  private _columnsAttributes: AttributeModel[] = []; // for columns in products table
  private _galleryAttributes: AttributeModel[] = []; // for drag drop in manage attributes sidebar
  private _initialAttributes: AttributeModel[] = [];

  public get loading() {
    return this._loading;
  }

  public get filters() {
    return this._filters;
  }

  public get attributes() {
    return this._attributes;
  }

  public get columnsAttributes() {
    return this._columnsAttributes;
  }

  public get galleryAttributes() {
    return this._galleryAttributes;
  }

  public get initialAttributes() {
    return this._initialAttributes;
  }

  public get visibleAttributes() {
    return this._attributes.filter(({ isHidden }) => !isHidden);
  }

  public get hiddenAttributes() {
    return this._attributes.filter(({ isHidden }) => isHidden);
  }

  public get requiredAttributes() {
    return this.attributes.filter(({ required }) => required);
  }

  public get optionalAttributes() {
    return this.attributes.filter(({ required }) => !required);
  }

  public get customAttributes() {
    // remove shopify product and variants relative attributes
    // see https://www.notion.so/gemlightbox/Shopify-18d38e4da16047bd82152d79adeab6ad
    return this.attributes.filter(
      ({ kind, integratedTo }) => kind === "custom" && integratedTo !== "shopify",
    );
  }

  public get defaultAttributes() {
    return this.attributes.filter(({ kind }) => kind === "default");
  }

  public get attributesNamesMap(): AttributesNamesMapType {
    return this.initialAttributes.reduce((acc, { name, displayName, id }) => {
      acc[id] = displayName || name;
      return acc;
    }, {} as AttributesNamesMapType);
  }

  constructor() {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async loadAttributesList(filters?: AttributesFiltersType) {
    runInAction(() => {
      this._loading = true;
      if (filters) this.setFilters(filters);
    });

    const { success } = await getAttributes
      .getRequest({
        queryParams: this._filters,
      })
      .fetch();

    const initialAttributes = await getAttributes
      .getRequest({
        queryParams: this._initialFilters,
      })
      .fetch();

    runInAction(() => {
      if (success) {
        this._attributes = success;
        this._setGalleryAttributes();
      }

      if (initialAttributes.success) {
        this._setInitialAttributeList(initialAttributes.success);
        this._setColumnsAttributes();
      }

      this._loading = false;
    });
  }

  public async createAttributes(attribute: MaybeArray<AttributeRequestModel>) {
    runInAction(() => {
      this._loading = true;
    });

    const { success } = await postAttribute
      .getRequest({
        data: toArrayOptions(attribute),
      })
      .fetch();

    runInAction(() => {
      if (success) {
        this._initialAttributes = success;
        this._setAttributeList(success);
      }

      this._loading = false;
    });
  }

  public async updateAttribute(id: AttributeModel["id"], attribute: AttributeRequestModel) {
    runInAction(() => {
      this._loading = true;
    });

    const { success, error } = await putAttribute
      .getRequest({
        params: { id },
        data: attribute,
      })
      .fetch();

    runInAction(() => {
      if (success) {
        this.updateAttributesLocal(success);
      }
      this._loading = false;
    });
    return error;
  }

  public async deleteAttribute(id: AttributeModel["id"]) {
    runInAction(() => {
      this._loading = true;
    });

    const { success } = await deleteAttribute.getRequest({ params: { id } }).fetch();

    runInAction(() => {
      if (success) {
        const index = this._attributes.findIndex((attribute) => attribute.id === id);

        if (index !== -1) {
          this._attributes.splice(index, 1);
        }

        this._initialAttributes = success;
        this._setColumnsAttributes();
      }

      this._loading = false;
    });
  }
  /* Requests ↑ */

  /* UI State ↓ */
  public setFilters(filters: AttributesFiltersType) {
    this._filters = filters;
  }

  public updateAttributesLocal(attributes: MaybeArray<AttributeModel>) {
    const attributesList = toArrayOptions(attributes);

    attributesList.forEach((attribute) => {
      const index = this._attributes.findIndex(({ id }) => id === attribute.id);
      const indexLocal = this._initialAttributes.findIndex(({ id }) => id === attribute.id);

      if (index !== -1) {
        Object.assign(this._attributes[index], attribute);
        Object.assign(this._initialAttributes[indexLocal], attribute);
      }
    });

    this._setColumnsAttributes();
    this._setGalleryAttributes();
  }
  /* UI State ↑ */

  /* Helpers ↓ */
  private _setAttributeList(attributes: AttributeModel[]) {
    this._attributes = attributes;
    this._setColumnsAttributes();
    this._setGalleryAttributes();
  }

  private _setInitialAttributeList(attributes: AttributeModel[]) {
    this._initialAttributes = attributes;
  }

  private _setColumnsAttributes() {
    // sorted by order_column by default
    // title attribute should always be first in products table

    this._columnsAttributes = this._initialAttributes
      .filter(({ isHidden }) => !isHidden)
      .sort((a, b) => a.order - b.order);
    const titleIndex = this._columnsAttributes.findIndex(({ name }) => name === "title");

    if (titleIndex !== -1) {
      const [titleAttribute] = this._columnsAttributes.splice(titleIndex, 1);

      this._columnsAttributes.unshift(titleAttribute);
    }
  }

  private _setGalleryAttributes() {
    this._galleryAttributes = this._attributes.sort((a, b) => {
      return a.order_gallery - b.order_gallery;
    });
  }

  public getAttributeRequestPayload(attribute?: AttributeModel): AttributeRequestModel {
    const result: AttributeRequestModel = {
      name: "",
      displayName: "",
      suffix: "",
      prefix: "",
      type: "text",
      required: false,
      isPublic: true,
      isHidden: false,
      order: this._attributes.length,
      order_gallery: this._attributes.length,
      values: [],
    };

    if (attribute) {
      result.name = attribute.name;
      result.displayName = attribute.displayName || "";
      result.suffix = attribute.suffix || "";
      result.prefix = attribute.prefix || "";
      result.type = attribute.type;
      result.required = attribute.required;
      result.isPublic = attribute.isPublic ?? true;
      result.isHidden = attribute.isHidden ?? false;
      result.order = attribute.order;
      result.order_gallery = attribute.order_gallery;
      result.values = attribute.values || [];
    }

    return result;
  }

  public findAttribute(name: string): AttributeModel | undefined {
    let attribute = this._attributes.find((attribute) => attribute.name === name);

    if (!attribute)
      attribute = this._initialAttributes.find((attribute) => attribute.name === name);

    return attribute;
  }
  /* Helpers ↑ */
}
