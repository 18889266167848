import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, clsx, SvgIcon, Tooltip, useBoolean } from "@gemlightbox/core-kit";

import { StarterPlanBadge } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { SubaccountsOfferCounter } from "../subaccounts-offer-counter";
import { IntervalChoicePicker } from "../interval-choice-picker";
import { subscriptionsData } from "../../../../subscriptions.constants";
import { SupportedSubscriptionInterval } from "../../../../subscriptions.types";

import { ReactComponent as MinusSVG } from "src/external-ts/assets/images/minus-grey.svg";

import contentStyles from "../../content.module.css";
import styles from "./starter-offer.module.css";

export interface StarterOfferProps {
  subscriptionToSelect: SupportedSubscriptionInterval;
  extraCount: number;
  activeSubaccountAmount: number;
  setSubscriptionToSelect: (
    interval: SupportedSubscriptionInterval,
    plan: "starter" | "pro",
  ) => void;
  setExtraCount: React.Dispatch<React.SetStateAction<number>>;
  onOpenBilling: VoidFunction;
  className?: string;
  canDisplayAddons?: boolean;
  disableStarterUpgrade?: boolean;
}
// Note: Starter Offer was not deleted in case it will need to come back
export const StarterOffer: React.FC<StarterOfferProps> = observer(
  ({
    subscriptionToSelect,
    extraCount,
    activeSubaccountAmount,
    setSubscriptionToSelect,
    setExtraCount,
    onOpenBilling,
    className = "",
    canDisplayAddons = true,
    disableStarterUpgrade = true,
  }: StarterOfferProps) => {
    const { subscriptionsStore, localeStore } = useStores();

    const {
      starterSubscriptionInterval,
      subGbAddonsAmount,
      starterPlan,
      starterPlanPrices,
      subaccountAddonPrices,
      isFreeSubscription,
      isStarterSubscription,
      isProSubscription,
      isStarterMonthlySubscription,
      isStarterYearlySubscription,
      isCustomSubscription,
    } = subscriptionsStore;

    const tooltipBoolean = useBoolean();
    const selectButtonRef = useRef<HTMLButtonElement>(null);

    const planSubaccountsUnit = Number(starterPlan?.metadata?.subaccounts || 0);
    const planStorageUnit = Number(starterPlan?.metadata?.limit_storage || 0);

    const handleSubtractExtraCount = () => setExtraCount((prevState) => Math.max(prevState - 1, 0));

    const handleAddExtraCount = () => setExtraCount((prevState) => prevState + 1);

    const handleSelectPlan = (type: SupportedSubscriptionInterval, disable = false) => {
      return () => {
        if (isCustomSubscription || disable) return;
        setSubscriptionToSelect(type, "starter");
      };
    };

    const getSelectPlanButtonText = () => {
      if (isStarterSubscription) {
        return subscriptionToSelect !== starterSubscriptionInterval
          ? localeStore.t("settings.subscriptions.components.content.buttons.upgrade")
          : localeStore.t("settings.subscriptions.components.content.buttons.current");
      } else if (isProSubscription) {
        return localeStore.t("settings.subscriptions.components.content.buttons.downgrade");
      }
      return localeStore.t("settings.subscriptions.components.content.buttons.upgrade");
    };

    const isSelectButtonDisabled =
      isCustomSubscription ||
      (!isFreeSubscription &&
        subGbAddonsAmount === extraCount &&
        starterSubscriptionInterval === subscriptionToSelect);

    const shouldRenderTooltip =
      isProSubscription && activeSubaccountAmount > extraCount + planSubaccountsUnit;

    return (
      <>
        <div className={clsx(styles.container, className)}>
          <div className={styles.header}>
            <StarterPlanBadge split hideInterval secondaryTextColor />
            <div className={styles.headerContent}>
              <IntervalChoicePicker
                offer="starter"
                subscriptionToSelect={subscriptionToSelect}
                extraCount={extraCount}
                isMonthlySubscription={isStarterMonthlySubscription}
                isYearlySubscription={isStarterYearlySubscription}
                isCustomSubscription={isCustomSubscription}
                planPrices={starterPlanPrices}
                onSelectPlan={handleSelectPlan}
                disableStarterUpgrade={disableStarterUpgrade}
              />
              <Button
                className={clsx(
                  styles.upgradeButton,
                  shouldRenderTooltip ? styles.disabled : undefined,
                )}
                appearance={
                  starterSubscriptionInterval === subscriptionToSelect
                    ? "secondaryOutlined"
                    : "primary"
                }
                disabled={isSelectButtonDisabled || disableStarterUpgrade}
                forwardRef={selectButtonRef}
                onClick={shouldRenderTooltip ? undefined : onOpenBilling}
                onMouseEnter={tooltipBoolean.setTruthy}
                onMouseLeave={tooltipBoolean.setFalsy}
              >
                {getSelectPlanButtonText()}
              </Button>
            </div>
          </div>
          <div className={contentStyles.offerContent}>
            {canDisplayAddons && (
              <SubaccountsOfferCounter
                extraCount={extraCount}
                planSubaccountsUnit={planSubaccountsUnit}
                planStorageUnit={planStorageUnit}
                isCustomSubscription={isCustomSubscription}
                subaccountAddonPrices={subaccountAddonPrices}
                onAddExtraCount={handleAddExtraCount}
                onSubtractExtraCount={handleSubtractExtraCount}
                subscriptionToSelect={subscriptionToSelect}
              />
            )}
            <div className={contentStyles.featureOfferListWrapper}>
              {subscriptionsData.features.map(({ starterPlanAmount }, index) => (
                <div key={index} className={contentStyles.feature}>
                  {starterPlanAmount !== "-" ? (
                    starterPlanAmount
                  ) : (
                    <SvgIcon className={styles.minusIcon} icon={MinusSVG} size={10} />
                  )}
                </div>
              ))}
            </div>
          </div>
          <Tooltip
            isOpen={tooltipBoolean.value}
            target={shouldRenderTooltip ? selectButtonRef : undefined}
            onClose={tooltipBoolean.setFalsy}
            position="leftTop"
            withAngle
          >
            <div className={styles.tooltipContent}>
              {localeStore.t('settings.subscriptions.components.content["change-plan-tooltip"]')}
            </div>
          </Tooltip>
        </div>
      </>
    );
  },
);

export default StarterOfferProps;
