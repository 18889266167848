import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  copyToClipboard,
  StatusTag,
  StatusTagAppearanceKeys,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBoolean,
  WarningPlate,
  SvgIcon,
} from "@gemlightbox/core-kit";

import { config } from "src/config/environment";
import { WithSuccessTooltip } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ExpandableStep } from "../expandable-step";

import { ReactComponent as CopySVG } from "src/external-ts/assets/images/copy-grey.svg";
import styles from "../steps-list.module.css";

export interface Step4Props {
  domain: string;
  success: boolean;
  error: boolean;
  initialOpen: boolean;
  isOpen: boolean;
  loading: boolean;
}

export const Step4: React.FC<Step4Props> = observer(
  ({ domain, success, initialOpen, isOpen, error, loading }) => {
    const { localeStore } = useStores();

    const galleryCopied = useBoolean();
    const domainCopied = useBoolean();

    const galleryURL = config.galleryURL.split("/")[2];

    const handleCopyGalleryURL = async () => {
      await copyToClipboard(galleryURL);
      galleryCopied.setTruthy();
    };

    const handleCopyDomain = async () => {
      await copyToClipboard(domain);
      domainCopied.setTruthy();
    };

    const getStatus = (): StatusTagAppearanceKeys => {
      let result: StatusTagAppearanceKeys = "disabled";

      if (loading) result = "warning";
      if (error) result = "error";
      if (success) result = "success";

      return result;
    };

    const getStatusLabel = () => {
      let result = "";

      if (loading)
        result = localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-4"]["status-labels"].loading',
        );
      if (error)
        result = localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-4"]["status-labels"].error',
        );
      if (success)
        result = localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-4"]["status-labels"].success',
        );

      return result;
    };

    return (
      <ExpandableStep
        title={localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-4"].title',
        )}
        loading={loading}
        initialOpen={initialOpen}
        isOpen={isOpen}
        error={error}
        errorLabel={localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-4"]["checkbox-error-label"]',
        )}
        loadingLabel={localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-4"]["checkbox-loading-label"]',
        )}
        triggerContainerClassName={styles.triggerContainer}
        contentClassName={styles.content}
      >
        {!error && (
          <div className={styles.label}>
            {localeStore.t(
              'settings["dns-settings"]["second-step"]["steps-list"]["step-4"].subtitle',
            )}
          </div>
        )}

        {error && (
          <WarningPlate
            className={styles.error}
            title={localeStore.t(
              'settings["dns-settings"]["second-step"]["steps-list"]["step-4"].warning.title',
            )}
            subtitle={localeStore.t(
              'settings["dns-settings"]["second-step"]["steps-list"]["step-4"].warning.subtitle',
            )}
          />
        )}

        <Table>
          <Thead>
            <Tr>
              <Th className={styles.type}>
                {localeStore.t(
                  'settings["dns-settings"]["second-step"]["steps-list"]["step-4"]["columns-titles"].type',
                )}
              </Th>
              <Th className={styles.host}>
                {localeStore.t(
                  'settings["dns-settings"]["second-step"]["steps-list"]["step-4"]["columns-titles"].host',
                )}
              </Th>
              <Th className={styles.value}>
                {localeStore.t(
                  'settings["dns-settings"]["second-step"]["steps-list"]["step-4"]["columns-titles"].value',
                )}
              </Th>
              <Th className={styles.statusth}>
                {localeStore.t(
                  'settings["dns-settings"]["second-step"]["steps-list"]["step-4"]["columns-titles"].status',
                )}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td> CNAME </Td>
              <Td>
                <div className={styles.cell}>
                  <WithSuccessTooltip
                    isOpen={domainCopied.value}
                    position="bottom"
                    label={localeStore.t(
                      'settings["dns-settings"]["second-step"]["steps-list"]["success-tooltip-label"]',
                    )}
                    onClose={domainCopied.setValue}
                  >
                    {({ targetRef }) => (
                      <div
                        className={styles.host}
                        ref={targetRef as React.ForwardedRef<HTMLDivElement>}
                      >
                        {domain}
                      </div>
                    )}
                  </WithSuccessTooltip>
                  <Button appearance="secondaryOutlined" onClick={handleCopyDomain}>
                    <SvgIcon icon={CopySVG} />
                    {localeStore.t(
                      'settings["dns-settings"]["second-step"]["steps-list"].buttons.copy',
                    )}
                  </Button>
                </div>
              </Td>
              <Td>
                <div className={styles.cell}>
                  <WithSuccessTooltip
                    isOpen={galleryCopied.value}
                    position="bottom"
                    label={localeStore.t(
                      'settings["dns-settings"]["second-step"]["steps-list"]["success-tooltip-label"]',
                    )}
                    onClose={galleryCopied.setValue}
                  >
                    {({ targetRef }) => (
                      <div
                        className={styles.result}
                        ref={targetRef as React.ForwardedRef<HTMLDivElement>}
                      >
                        {galleryURL}
                      </div>
                    )}
                  </WithSuccessTooltip>
                  <Button appearance="secondaryOutlined" onClick={handleCopyGalleryURL}>
                    <SvgIcon icon={CopySVG} />
                    {localeStore.t(
                      'settings["dns-settings"]["second-step"]["steps-list"].buttons.copy',
                    )}
                  </Button>
                </div>
              </Td>
              <Td>
                <div className={styles.statusCell}>
                  <StatusTag
                    className={styles.status}
                    appearance={getStatus()}
                    label={getStatusLabel()}
                  />
                </div>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </ExpandableStep>
    );
  },
);
