import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  SvgIcon,
  Button,
  Typography,
  EllipseActions,
  ActionOption,
  ProgressLine,
  Loader,
  useFetch,
  useCall,
  stopPropagationReact,
  useDidMount,
  Nullable,
  round,
  useBoolean,
  clsx,
  useWindowSize,
} from "@gemlightbox/core-kit";

import { getRapnetStatus, putRapnetRemove } from "src/api";
import { useStores } from "src/hooks";
import { RAPNET_PAGE } from "src/constants";
import WSConnection from "src/common/socket";
import { IntegrationBlock } from "../integration-block";
import { RapnetErrorModal } from "./rapnet-error-modal";
import { RapnetBatchStatusDataType } from "./rapnet.types";

import { ReactComponent as RapnetSVG } from "src/external-ts/assets/images/integrations/rapnet.svg";
import styles from "./rapnet.module.css";

export const Rapnet: React.FC = observer(() => {
  const { localeStore, modalsStore, userStore } = useStores();

  const navigate = useNavigate();

  const { windowWidth } = useWindowSize();

  const rapnetStatusFetch = useFetch(getRapnetStatus);
  const isSyncing = rapnetStatusFetch.payload?.syncStatus === "syncing";
  const isSyncStatusHasError =
    rapnetStatusFetch.payload?.syncStatus === "completed" && rapnetStatusFetch.payload?.error > 0;

  const putRapnetRemoveCall = useCall(putRapnetRemove);
  putRapnetRemoveCall.onCallSuccess(() => {
    userStore.loadUserMeSilently();
    rapnetStatusFetch.refresh();
  });

  const isErrorsOpenBoolean = useBoolean();

  const [batchStatus, setBatchStatus] = useState<Nullable<RapnetBatchStatusDataType>>(null);
  const completedProducts = batchStatus?.completed || rapnetStatusFetch.payload?.completed || 0;
  const pendingProducts = batchStatus?.pending || rapnetStatusFetch.payload?.pending || 0;
  const totalProducts = batchStatus?.total || rapnetStatusFetch.payload?.total || 0;

  const totalCompletedPercent = round((completedProducts / totalProducts || 0) * 100, 2);
  const totalProcessedPercent = round((1 - (pendingProducts / totalProducts || 1)) * 100, 2);

  const [isSyncDone, setIsSyncDone] = useState(false);

  const loading =
    putRapnetRemoveCall.submitting || userStore.loadingMeSilently || rapnetStatusFetch.loading;

  const canGoToSettings = userStore.isRapnetUser && !loading;

  const handleBlockClick = () => {
    if (!canGoToSettings) return;
    handleGoToSettings();
  };

  const handleGoToSettings = () => navigate(RAPNET_PAGE.path);

  const handleRemoveIntegration = () => putRapnetRemoveCall.submit();

  const handleViewErrors = () => isErrorsOpenBoolean.setTruthy();

  const handleSetupClick = () => {
    modalsStore.open("LoginToRapnetModal", {
      onAddIntegration: handleGoToSettings,
    });
  };

  useDidMount(() => {
    const rapnetSyncWS = new WSConnection("/sync", userStore.token);

    rapnetSyncWS.on("sync/RAPNET_2_0", ({ payload }: any) => {
      if (!payload.batchStatus) return;

      setBatchStatus(payload.batchStatus);

      if (payload.batchStatus.syncStatus === "completed") {
        setIsSyncDone(true);
      }

      if (
        payload.batchStatus.syncStatus === "completed" ||
        payload.batchStatus.syncStatus === "canceled"
      ) {
        rapnetStatusFetch.refresh();
      }
    });

    return () => rapnetSyncWS.destroy();
  });

  return (
    <>
      <IntegrationBlock
        className={styles.rapnetIntegrationContainer}
        onClick={handleBlockClick}
        clickable={canGoToSettings}
      >
        {loading && <Loader position="absolute" withOverlay />}

        {windowWidth > 840 ? (
          <SvgIcon icon={RapnetSVG} size={48} />
        ) : (
          <div className={styles.header}>
            <SvgIcon icon={RapnetSVG} size={36} />
            <Typography size={"medium600"}>Rapnet</Typography>
          </div>
        )}

        <div className={styles.rapnetContent}>
          {windowWidth > 840 && (
            <Typography size="small600" color="textSecondary">
              Rapnet
            </Typography>
          )}

          {!isSyncing && !isSyncDone && (
            <Typography size="extraSmall" color="textTertiary">
              {localeStore.t('integrations.general["sync-information"]')}{" "}
              <a
                href="https://support.picupmedia.com/integrating-with-rapnet"
                target="_blank"
                rel="noreferrer"
                onClick={stopPropagationReact}
              >
                <Typography
                  className={styles.learnButton}
                  size="extraSmall500"
                  color="textTertiary"
                >
                  {localeStore.t('common.buttons["learn-more"]')}
                </Typography>
              </a>
            </Typography>
          )}
          {!isSyncing && isSyncDone && (
            <Typography size="extraSmall" color="textTertiary">
              {localeStore.t('settings.integrations["integration-status-bar"]["completed-state"]')}
            </Typography>
          )}
          {isSyncing && (
            <Typography size="extraSmall" color="textTertiary">
              {localeStore.t("integrations.general.uploading")}
            </Typography>
          )}

          {!userStore.isRapnetUser && windowWidth <= 840 && (
            <Button className={styles.setupButton} onClick={handleSetupClick}>
              {localeStore.t('common.buttons["setup-now"]')}
            </Button>
          )}
        </div>

        {isSyncing && (
          <div className={styles.rapnetSyncWrapper}>
            <div className={styles.rapnetSyncContent}>
              <Typography size="small">
                {completedProducts}/{totalProducts} {localeStore.t("integrations.general.items")}
              </Typography>
              <Typography size="small600">{totalProcessedPercent}%</Typography>
            </div>
            <ProgressLine progress={totalCompletedPercent} />
          </div>
        )}

        {userStore.isRapnetUser && (
          <EllipseActions
            className={styles.actionsBtn}
            actionsListClassName={styles.rapnetActionsList}
            showAttentionDot={isSyncStatusHasError}
          >
            <ActionOption onClick={handleGoToSettings}>
              {localeStore.t("integrations.general.settings")}
            </ActionOption>
            <ActionOption onClick={handleRemoveIntegration}>
              {localeStore.t('integrations.general["remove-integration"]')}
            </ActionOption>
            <ActionOption
              className={clsx(styles.viewErrorsOption, {
                [styles.hasSyncError]: isSyncStatusHasError,
              })}
              onClick={handleViewErrors}
            >
              <span>{localeStore.t('integrations.general["view-errors"]')}</span>
            </ActionOption>
          </EllipseActions>
        )}
        {!userStore.isRapnetUser && windowWidth > 840 && (
          <Button className={styles.setupButton} onClick={handleSetupClick}>
            {localeStore.t('common.buttons["setup-now"]')}
          </Button>
        )}
      </IntegrationBlock>

      <RapnetErrorModal
        isOpen={isErrorsOpenBoolean.value}
        setClose={isErrorsOpenBoolean.setFalsy}
      />
    </>
  );
});

export default Rapnet;
