export const sortAttributeValues = (values: string[]): string[] => {
  return values.sort((current, next) => {
    const digitRegex = /^\d/;
    const alphabetRegex = /^[a-zA-Z]/;
    const symbolRegex = /^[^\w\s]/;
    const a = current.trim().toLowerCase();
    const b = next.trim().toLowerCase();

    const scoreA = +symbolRegex.test(a) || +digitRegex.test(a) * 10 || +alphabetRegex.test(a) * 100;
    const scoreB = +symbolRegex.test(b) || +digitRegex.test(b) * 10 || +alphabetRegex.test(b) * 100;

    if (scoreA !== scoreB) {
      return scoreA - scoreB;
    }

    return 0;
  });
};
