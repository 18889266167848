import { useStores } from "src/hooks";
import { AttributeModel, ProductTypeModel, productDefaultTypesNames } from "src/models";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { checkIfAttributeIsDefault, valuesToOptions } from "src/utils";

export const getBottomMessage = (attributeName: string) => {
  const { localeStore } = useStores();

  switch (attributeName) {
    case "title":
      return localeStore.t('["product-attributes"]["attribute-field"]["bottom-messages"].sku');
    case "detailedTitle":
      return localeStore.t('["product-attributes"]["attribute-field"]["bottom-messages"].title');
    case "price":
      return localeStore.t('["product-attributes"]["attribute-field"]["bottom-messages"].price');
    case "productType":
      return localeStore.t(
        '["product-attributes"]["attribute-field"]["bottom-messages"]["product-type"]',
      );
    default:
      return;
  }
};

export const getAttributeOptions = (
  attribute: AttributeModel,
  defaultProductTypes: ProductTypeModel[],
) => {
  const { localeStore } = useStores();
  const isDefault = checkIfAttributeIsDefault(attribute);
  const opts = valuesToOptions(attribute.values);
  const defaultProductTypesNames: string[] = defaultProductTypes.map(({ name }) => name);

  defaultProductTypesNames.push("Diamonds");
  if (opts.length === 1) {
    return opts.map((option) => ({ ...option, indelible: true }));
  }

  if (isDefault) {
    return opts.map((option) => {
      const label = option.label;
      option.label = localeStore.t(
        `create["augmented-reality-item"]["ar-types"].${
          productDefaultTypesNames[label.toLocaleLowerCase()]
        }` as LocaleCodeTypes,
        label,
      );
      return {
        ...option,
        indelible: defaultProductTypesNames.includes(option.value.toString()),
        name: `attribute-option-${label.toLowerCase()}`,
      };
    });
  }
  return opts;
};
