import React from "react";
import { observer } from "mobx-react-lite";
import { Input, FilterCollapse } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

export interface DescriptionFilterProps {
  value: string;
  onChange: (value: string) => void;
}

export const DescriptionFilter: React.FC<DescriptionFilterProps> = observer(
  ({ value, onChange }) => {
    const { localeStore } = useStores();

    return (
      <FilterCollapse
        data-cy="description-filter-collapse"
        title={localeStore.t(
          'products["products-list"]["products-table"]["products-table-header"].description',
        )}
      >
        <Input
          data-cy="description-filter-input"
          value={value}
          appearance="primaryV2"
          placeholder={`${localeStore.t(
            'components.business.filters["filters-sidebar"]["default-filters"]["description-filter"].placeholder',
          )} ${localeStore.t(
            'products["products-list"]["products-table"]["products-table-header"].description',
          )}`}
          onChange={onChange}
          disableError
        />
      </FilterCollapse>
    );
  },
);
