import React from "react";

import { OrderModel } from "src/api";
import { PaymentsTableRow } from "./payments-table-row";

type PaymentsTableBodyProps = {
  data: OrderModel[];
  onOrderClick: (orderId: number) => void;
};

export const PaymentsTableBody: React.FC<PaymentsTableBodyProps> = ({ data, onOrderClick }) => {
  return (
    <tbody>
      {data.map((row, index) => {
        return <PaymentsTableRow key={index} data={row} onOrderClick={onOrderClick} />;
      })}
    </tbody>
  );
};
