import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import "./index.scss";

interface MobileHeaderWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

export const MobileHeaderWrapper: React.FC<MobileHeaderWrapperProps> = ({
  className = "",
  children = null,
}) => <header className={clsx("mobile-header-wrapper shadowed", className)}>{children}</header>;

export default MobileHeaderWrapper;
