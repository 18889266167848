import React from "react";
import { memo, Modal, ObjectType } from "@gemlightbox/core-kit";

import { ModalExtendedType } from "src/store";
import { TermsOfServiceArticle } from "src/external-ts/pages/auth/terms-of-service/terms-of-service-article";

import styles from "./terms-of-service-modal.module.css";

export type TermsOfServiceModalProps = ModalExtendedType<ObjectType>;

export const TermsOfServiceModal: React.FC<TermsOfServiceModalProps> = memo(
  ({ isOpen, setClose, onFinalClosed }) => {
    return (
      <Modal
        scrollWrapperClassName={styles.modalScrollWrapper}
        contentClassName={styles.modalContent}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        isOpen={isOpen}
        withCross
      >
        <div className={styles.title}>Terms Of Service</div>
        <TermsOfServiceArticle
          containerClassName={styles.article}
          articleWrapperClassName={styles.articleWrapper}
          withTitle={false}
          withDate={false}
        />
      </Modal>
    );
  },
);
