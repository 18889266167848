import { observer } from "mobx-react-lite";
import React from "react";
import { isAndroid, isIPhone, queryStringify, useQuery } from "@gemlightbox/core-kit";
import { config } from "src/config/environment";

export const RedirectPage: React.FC = observer(() => {
  function checkForMacApp() {
    const { query } = useQuery();
    const queryString = queryStringify(query);

    let params = "";
    switch (query.type) {
      case "resetpassword":
        params = `/auth/reset/password${queryString}`;
        break;
      case "confirmemail":
        params = `${queryString}`;
        break;
      default:
        window.location.href = "/auth/local";
        return;
    }

    const url = `${config.dashboardUrl}${params}`;
    if (window.$platform.isApp || isIPhone() || isAndroid()) {
      const loadDateTime = new Date().getTime();
      window.setTimeout(function () {
        const timeOutDateTime = new Date().getTime();
        if (timeOutDateTime - loadDateTime < 5000) {
          window.location.href = url;
        } else {
          window.close();
        }
      }, 100);

      if (window.$platform.isApp) {
        window.location.href = `GemLightbox://emailVerification?${params}`;
      } else if (isIPhone()) {
        window.location.href = `gemlightbox://ResetPassword?${queryString}`;
      } else {
        window.location.href = `com.gemlightbox://resetpw?${queryString}`;
      }
    } else {
      window.location.href = url;
    }
  }

  // 检测是否需要跳转唤起 Mac App
  checkForMacApp();

  return <div></div>;
});
