import React, { useMemo, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Descendant } from "slate";
import {
  Input,
  Select,
  SelectOption,
  TableCell,
  Tooltip,
  useBoolean,
  MaybeArray,
  clsx,
  TextEditor,
  LimitedEditorText,
  TextEditorUtils,
  Typography,
  globalStyles,
} from "@gemlightbox/core-kit";

import {
  AttributeModel,
  AttributeType,
  ProductModel,
  ProductParameterRequestModel,
  ProductRequestModel,
  productDefaultTypesNames,
} from "src/models";
import { useStores } from "src/hooks";

import styles from "./attribute.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export interface AttributeProps {
  productId: ProductModel["_id"];
  productPayload: ProductRequestModel;
  attribute: AttributeModel;
  initialValue: string;
  value: string | Descendant[];
  onChange: (value: string | Descendant[]) => void;
  onReset: VoidFunction;
}

export const Attribute: React.FC<AttributeProps> = observer(
  ({ productId, productPayload, attribute, initialValue, value, onChange, onReset }) => {
    const { name, type, suffix, prefix, id, values, kind } = attribute;
    const { productsStore, attributesStore, localeStore } = useStores();

    const editBoolean = useBoolean();
    const toolTipBoolean = useBoolean();

    const cellContentRef = useRef<HTMLDivElement>(null);

    const isProductType = name === "productType";
    const isDescription = name === "description";

    const isText = type === AttributeType.text;
    const isNumber = type === AttributeType.number;
    const isSelect = type === AttributeType.select;
    const isMultiselect = type === AttributeType.multiselect;

    const selectOptions = useMemo(() => {
      return (
        values?.map((value) => {
          return { label: value, value };
        }) ?? []
      );
    }, [values]);

    const geTableCellDisplayValue = () => {
      let result = value;

      if (value) {
        if (prefix) result = `${prefix} ${value}`;
        if (suffix) result = `${value} ${suffix}`;

        if (name === "price" && suffix) result = `${suffix} ${value}`;
        if (name === "productType") {
          result = localeStore.t(
            `create["augmented-reality-item"]["ar-types"].${
              productDefaultTypesNames[String(value).toLocaleLowerCase()]
            }` as LocaleCodeTypes,
            value + "",
          );
        }
      } else {
        result = "";
      }

      return result;
    };

    const getFreshPayload = async (
      freshPayload?: string,
    ): Promise<ProductRequestModel | undefined> => {
      const freshValueToUse = freshPayload ?? value;
      const payload = { ...productPayload };

      const parameter: ProductParameterRequestModel = {
        attribute_id: id,
        value: isDescription
          ? TextEditorUtils.serializeEditorValue(freshValueToUse)
          : (freshValueToUse as string),
      };

      if (!payload.parameters) return;

      if (isSelect || isMultiselect) {
        if (!freshValueToUse || freshValueToUse === value) {
          parameter.value = null;
        }
        if (!attribute.values) return;
      }

      const parameterIndex = payload.parameters.findIndex(
        ({ attribute_id }) => attribute_id === id,
      );

      if (parameterIndex !== -1) {
        payload.parameters.splice(parameterIndex, 1, parameter);
      }

      return payload;
    };

    const handleSubmit = async () => {
      const payload = await getFreshPayload();
      if (!payload) return;
      if (initialValue === value) return;

      const res = await productsStore.updateProduct(productId, payload);
      if (res?.error) onReset();
    };

    const handleBlur = async () => {
      if (!isSelect && !isMultiselect) await handleSubmit();
      setTimeout(() => {
        editBoolean.setFalsy();
      }, 300);
    };

    const handleChangeAttributeOption = async (
      options: MaybeArray<SelectOption<string> | undefined>,
    ) => {
      let payload;
      if (!Array.isArray(options)) {
        payload = await getFreshPayload(options?.value);
      } else {
        const values = options.map((option) => option?.value).join(", ");
        payload = await getFreshPayload(values);
      }
      if (!payload) return;

      const res = await productsStore.updateProduct(productId, payload);
      if (res?.error) onReset();
      if (isSelect) handleBlur();
    };

    const handleCreateOption = (value: string) => {
      const trimmedNewAttributeValue = value.trim();

      if (!values?.some((value) => value === trimmedNewAttributeValue)) {
        const data = {
          ...attribute,
          values: values?.concat(trimmedNewAttributeValue) || [],
        };
        const tableCellData = attributesStore.getAttributeRequestPayload(data);
        attributesStore.updateAttribute(data.id, tableCellData);

        const newOption = isMultiselect
          ? [{ value: trimmedNewAttributeValue, label: trimmedNewAttributeValue }]
          : { value: trimmedNewAttributeValue, label: trimmedNewAttributeValue };

        handleChangeAttributeOption(newOption);
      }

      handleBlur();
    };

    const handleTextAttributeWithTooltipMouseEnter = () => {
      const isTooltipTextAttribute = kind === "custom" && type === "text";
      if (isTooltipTextAttribute && !!geTableCellDisplayValue()) toolTipBoolean.setTruthy();
      if (
        name === "description" &&
        TextEditorUtils.calculateLength(TextEditorUtils.normalizeEditorValue(value)) !== 0
      ) {
        toolTipBoolean.setTruthy();
      }
    };

    const handleCellClick = () => {
      toolTipBoolean.setFalsy();
      editBoolean.setTruthy();
    };

    const selectSelectedValue = isMultiselect ? (value as string)?.split(", ") : value;

    return (
      <TableCell className={clsx(styles.cellContainer, styles[name])} onClick={handleCellClick}>
        {editBoolean.value ? (
          <>
            {(isSelect || isMultiselect) && (
              <Select
                appearance="primaryV2"
                inputWrapperClassName={styles.selectWrapper}
                options={selectOptions.map((item) => {
                  if (isProductType) {
                    const label = localeStore.t(
                      `create["augmented-reality-item"]["ar-types"].${
                        productDefaultTypesNames[item.value.toLocaleLowerCase()]
                      }` as LocaleCodeTypes,
                      item.value + "",
                    );
                    return { ...item, label };
                  }
                  return item;
                })}
                selectedOptionsKeys={selectSelectedValue as string}
                onChange={handleChangeAttributeOption}
                onCreateNew={handleCreateOption}
                onDropdownBlur={handleBlur}
                isMulti={isMultiselect}
                capitalizeOptions={isProductType}
                trimCreatedOption
                isTextEditable
                disableError
                disableClearing
                autoFocus
                canCreate
              />
            )}

            {!isDescription && (isText || isNumber) && (
              <Input
                appearance="primaryV2"
                className={styles.inputContainer}
                inputWrapperClassName={styles.inputWrapper}
                inputClassName={styles.input}
                value={value as string}
                suffix={suffix}
                prefix={prefix}
                type={isNumber ? "number" : "text"}
                onBlur={handleBlur}
                onChange={onChange}
                autoFocus
                disableError
              />
            )}

            {isDescription && (
              <TextEditor
                editorContainerClassName={styles.descriptionInput}
                containerClassName={styles.descriptionContainer}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                disableError
                autoFocus
                withLink={false}
              />
            )}
          </>
        ) : (
          <>
            <div
              className={styles.cell}
              ref={cellContentRef}
              onMouseEnter={handleTextAttributeWithTooltipMouseEnter}
              onMouseLeave={toolTipBoolean.setFalsy}
            >
              {isDescription ? (
                <LimitedEditorText
                  containerClassName={styles.description}
                  value={value}
                  limit={100}
                />
              ) : (
                <Typography
                  color="textSecondary"
                  size={name === "price" || name === "productType" ? "small600" : "extraSmall"}
                  className={globalStyles.applyMultiOverflow}
                >
                  {geTableCellDisplayValue()}
                </Typography>
              )}
            </div>

            <Tooltip
              appearance="secondary"
              position="topRight"
              target={cellContentRef}
              isOpen={toolTipBoolean.value}
              onClose={toolTipBoolean.setValue}
              withAngle
            >
              <div className={styles.tooltipContent}>
                {isDescription ? (
                  <LimitedEditorText
                    containerClassName={styles.descriptionTooltip}
                    plainTextClassName={styles.descriptionPlainText}
                    value={value}
                    limit={1000}
                  />
                ) : (
                  geTableCellDisplayValue()
                )}
              </div>
            </Tooltip>
          </>
        )}
      </TableCell>
    );
  },
);
