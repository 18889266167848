import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import { Tooltip } from "@mui/joy";
import { observer } from "mobx-react-lite";
import { useStores } from "src/hooks";
import "./index.scss";
import { pushDataLayerEvent } from "src/utils";

const displayTipCount = 1;
const displayWaitSeconds = 7;

let saveTiming = 0;

export const SaveProgress: React.FC = observer(() => {
  const { localeStore, mediaStore, notificationStore } = useStores();
  const [displayTip, setDisplayTip] = useState(false);

  const [saveCount, setSaveCount] = useState(0);

  useEffect(() => {
    if (mediaStore.savedStatus === 1) {
      handleSaveTiming();
    } else if (mediaStore.savedStatus === 3) {
      if (!saveCount) {
        retrySave();
        setSaveCount(1);
      }
    }
  }, [mediaStore.savedStatus]);

  const handleSaveTiming = () => {
    setTimeout(() => {
      saveTiming++;
      if (saveTiming >= displayWaitSeconds) {
        handleDisplayTip();
      } else {
        handleSaveTiming();
      }
    }, 1000);
  };

  const handleDisplayTip = () => {
    const lsKey = "__displayTipToday__";
    const displayTipToday = localStorage.getItem(lsKey);
    const date = new Date().toLocaleDateString();
    let num = 0;
    if (displayTipToday) {
      num = parseInt(displayTipToday?.split("@")[1]);
    }
    if (displayTipToday?.startsWith(date)) {
      if (num >= displayTipCount) {
        return setDisplayTip(false);
      }
    }
    setDisplayTip(true);
    setTimeout(() => {
      setDisplayTip(false);
    }, 3500);
    num++;
    localStorage.setItem(lsKey, date + "@" + num);
  };

  const cancelSave = () => {
    // const img = mediaStore.savedPostUploadMedia[0].files[0];
    notificationStore.open({
      title: localeStore.t('camera["save-background"].cancel.title'),
      message: (
        <div className="save-progress-modal">
          <div>{localeStore.t('camera["save-background"].cancel.content')}</div>
          <img
            src={
              mediaStore.savedPostUploadMedia && mediaStore.savedPostUploadMedia[1]
                ? URL.createObjectURL(mediaStore.savedPostUploadMedia[1].files)
                : mediaStore.savedCancelImg
            }
          />
        </div>
      ),
      confirmText: localeStore.t('camera["save-background"].cancel.confirm'),
      cancelText: localeStore.t('camera["save-background"].cancel.cancel'),
      onOk: () => {
        pushDataLayerEvent({
          event: "gemhub:camera:media:cloud_save:canceled",
          event_params: {
            product_sku: mediaStore.savedPostUploadMedia[0]?.sku,
            product_type: mediaStore.savedPostUploadMedia[0]?.productType,
            media_filenames: (mediaStore.preUploadFiles.length
              ? mediaStore.preUploadFiles[0].concat(
                  mediaStore.savedPostUploadMedia.filter((d) => !d.types.includes("video")),
                )
              : mediaStore.savedPostUploadMedia
            ).map((f: { files: { name: any } }) => f.files.name),
            preuploaded_media_urls: mediaStore.uploadedVideos?.map((f) => f.original),
          },
        });
        mediaStore.setSavedStatus(0);
        mediaStore.setSavedProgress(0);
        mediaStore.setPreUploadStatus(0);
        mediaStore.setPreUploadFiles([]);
        mediaStore.uploadRequest?.abort();
        mediaStore.saveRequest?.abort();
      },
    });
  };

  const retrySave = () => {
    pushDataLayerEvent({
      event: "click:camera:media:cloud_save:retry",
      event_params: {
        product_sku: mediaStore.savedPostUploadMedia[0]?.sku,
        product_type: mediaStore.savedPostUploadMedia[0]?.productType,
        media_filenames: (mediaStore.preUploadFiles.length
          ? mediaStore.preUploadFiles[0].concat(
              mediaStore.savedPostUploadMedia.filter((d) => !d.types.includes("video")),
            )
          : mediaStore.savedPostUploadMedia
        ).map((f: { files: { name: any } }) => f.files.name),
        preuploaded_media_urls: mediaStore.uploadedVideos?.map((f) => f.original),
      },
    });
    if (mediaStore.uploadedVideos?.length < 1) {
      mediaStore.setSavedProgress(0);
      mediaStore.setSavedStatus(1);
      mediaStore.uploadMediaSync(mediaStore.preUploadFiles[0]);
      mediaStore.uploadRequest?.fetch();
      return;
    }
    mediaStore.setSavedStatus(1);
    mediaStore.handleSave();
  };

  if (mediaStore.savedStatus === 0 || mediaStore.savedStatus === 2) {
    return <></>;
  }

  return (
    <div className="save-progress" style={{ right: mediaStore.sidebarOpened ? "618px" : "346px" }}>
      <div className="content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.87868 1.87868C0 2.75736 0 4.17157 0 7V9C0 11.8284 0 13.2426 0.87868 14.1213C1.75736 15 3.17157 15 6 15H10C12.8284 15 14.2426 15 15.1213 14.1213C16 13.2426 16 11.8284 16 9V7C16 4.17157 16 2.75736 15.1213 1.87868C14.2426 1 12.8284 1 10 1H6C3.17157 1 1.75736 1 0.87868 1.87868ZM6 5L11 8L6 11V5Z"
            fill="#363E5C"
          />
        </svg>
        <div className={mediaStore.savedStatus === 1 ? "text loading" : "text"}>
          {localeStore.t('camera["save-background"]["loading-tip"]')}
        </div>
        {mediaStore.savedStatus === 1 && (
          <CircularProgress
            sx={{
              "--CircularProgress-size": "16px",
              "--CircularProgress-trackThickness": "3px",
              "--CircularProgress-progressThickness": "3px",
              "--CircularProgress-trackColor": "#DCDDE3",
              "--CircularProgress-progressColor": "#0099A1",
            }}
            size="sm"
            variant="soft"
            determinate
            value={mediaStore.savedProgress}
          />
        )}
        {mediaStore.savedStatus === 3 && (
          <>
            <Tooltip
              variant="solid"
              id="save-retry-tooltip"
              sx={{
                background: "#FFEDED !important",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
                borderRadius: "4px",
                zIndex: 9999,
                marginTop: "15px !important",
              }}
              title={
                <div className="save-retry-tooltip">
                  {localeStore.t('camera["save-background"]["retry-tip"]')}
                </div>
              }
              arrow
              placement="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM9 4V9H7V4H9ZM8 12C8.55229 12 9 11.5523 9 11C9 10.4477 8.55229 10 8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12Z"
                  fill="#F23D3D"
                />
              </svg>
            </Tooltip>
            <a href="javascript:void(0);" onClick={retrySave}>
              {localeStore.t('camera["save-background"]["retry-btn"]')}
            </a>
          </>
        )}
      </div>

      <div className="close" onClick={cancelSave}>
        <Tooltip
          variant="solid"
          id="save-progress-tooltip"
          sx={{
            transform: mediaStore.sidebarOpened
              ? "translate3d(-620px, 75px, 0px) !important"
              : "translate3d(-348px, 75px, 0px) !important",
            background: "#0099A1",
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
          }}
          title={
            <div className="save-progress-tooltip">
              {localeStore.t('camera["save-background"]["network-slow-tip"]')}
            </div>
          }
          arrow
          open={displayTip}
          placement="bottom-end"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM10 11.0607L7.53033 13.5303L6.46967 12.4697L8.93934 10L6.46967 7.53033L7.53033 6.46967L10 8.93934L12.4697 6.46967L13.5303 7.53033L11.0607 10L13.5303 12.4697L12.4697 13.5303L10 11.0607Z"
              fill="#DCDDE3"
            />
          </svg>
        </Tooltip>
      </div>
    </div>
  );
});
