import development from "./development.json";
import prestage from "./prestage.json";
import staging from "./staging.json";
import production from "./production.json";
import project from "./project.json";
import { EnvironmentConfig } from "./environment.types";

export const environmentsMap: Record<EnvironmentType, EnvironmentConfig> = {
  production,
  staging,
  prestage,
  development,
  project,
};

export const ENVIRONMENT = import.meta.env.MODE as EnvironmentType;

export const config = environmentsMap[ENVIRONMENT];
