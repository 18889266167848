import { JSONParse, JSONStringify } from "@gemlightbox/core-kit";

export const STORAGE_TYPES = {
  USER_DATA: "user_data",
  CATALOG_DATA: "catalog_data",
  GLOBAL_DATA: "global_data",
  CAMERA_DATA: "camera_data",
  GEMAI_DATA: "gemai_data",
  AI_LANGUAGE: "ai_language",
  USER_LOCAL_SETTINGS: "user_local_settings",
};

export default class LocalStorage {
  constructor(namespace, maxLength) {
    this.namespace = namespace;
    this.maxLength = maxLength;
    if (!this.namespace) console.warn("Namespace for localstorage is required");
  }

  hasContent() {
    if (this.namespace) {
      return !!(this.get() || []).length;
    }
  }

  listen(cb) {
    window.addEventListener("storage", (storageEvent) => {
      if (storageEvent.key === this.namespace) cb(JSONParse(storageEvent.newValue));
    });
  }

  isExist = () => !!JSONParse(localStorage.getItem(this.namespace));

  set(data) {
    if (!this.namespace) return;
    localStorage.setItem(this.namespace, JSONStringify(data));
  }

  get = () => JSONParse(localStorage.getItem(this.namespace)) || {};

  getAsArray() {
    if (this.namespace) {
      return JSONParse(localStorage.getItem(this.namespace)) || [];
    }
  }

  add(item) {
    if (!this.namespace) return;

    const currentItems = this.get();
    if (currentItems && currentItems.includes && !currentItems.includes(item)) {
      currentItems.unshift(item);

      if (currentItems.length > this.maxLength) currentItems.pop();

      localStorage.setItem(this.namespace, JSONStringify(currentItems));
    }
  }

  destroy() {
    if (!this.namespace) return;
    localStorage.removeItem(this.namespace);
  }
}
