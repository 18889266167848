import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Nullable, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ProductModel } from "src/models";
import { getSharedResourceLink } from "src/external-ts/components/business/share-modal/shared-link-content/share-link-modal.utils";
import { EnquireButton } from "./enquire-button";

import { ReactComponent as BlinkSVG } from "src/external-ts/assets/images/media/blink-type.svg";
import styles from "./product-contacts.module.css";

export type ProductContactsProps = {
  hasBlink: boolean;
  product: Nullable<ProductModel>;
};

export const ProductContacts: React.FC<ProductContactsProps> = observer(
  ({ hasBlink, product }: ProductContactsProps) => {
    const { localeStore, userStore } = useStores();

    const isTryOnLinksLimited = userStore.userMe?.isTryOnLinksLimited || false;
    const galleryInfo = userStore.galleryInfo;

    const showModelImage = !isTryOnLinksLimited && hasBlink;
    const whatsapp = galleryInfo?.contact_whatsup;
    const mail = galleryInfo?.contact_email;
    const phone = galleryInfo?.contact_phone;

    const handleModelImageClick = () => {
      if (!product) return;
      const link = getSharedResourceLink("product", product as any) + "?tryMeOn=true";
      window.open(link, "_blank");
    };

    if (!showModelImage && !whatsapp && !mail && !phone) return null;

    return (
      <div className={styles.productContactsContainer}>
        {showModelImage && (
          <Button
            className={styles.modelImageBtn}
            appearance="secondaryOutlined"
            onClick={handleModelImageClick}
          >
            <SvgIcon icon={BlinkSVG} disableAutoColor />
            {localeStore.t('common.media["model-image"]')}
          </Button>
        )}
        <EnquireButton whatsapp={whatsapp} mail={mail} phone={phone} />
      </div>
    );
  },
);

export default ProductContacts;
