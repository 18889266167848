import React from "react";
import { observer } from "mobx-react-lite";
import { RefModel } from "@gemlightbox/core-kit";

import { ProductsTable } from "./product-table";

import styles from "./table-view.module.css";

export interface TableViewProps {
  productsContainerRef: RefModel<HTMLDivElement>;
}

export const TableView: React.FC<TableViewProps> = observer(({ productsContainerRef }) => {
  return (
    <div className={styles.container} data-cy="products-table-view">
      <ProductsTable productsContainerRef={productsContainerRef} />
    </div>
  );
});
