import React from "react";
import { observer } from "mobx-react-lite";
import { Thead, Tr, Th } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./subaccounts-table-header.module.css";

export const SubaccountsTableHeader = observer(() => {
  const { localeStore, userStore } = useStores();

  return (
    <Thead className={styles.tableHeader}>
      <Tr className={styles.tableRow}>
        <Th className={styles.firstCell}>
          {localeStore.t('settings.subaccounts["table-columns"].photo')}
        </Th>
        <Th>{localeStore.t('settings.subaccounts["table-columns"].name')}</Th>
        <Th>{localeStore.t('settings.subaccounts["table-columns"].email')}</Th>
        <Th>{localeStore.t('settings.subaccounts["table-columns"].phone')}</Th>
        <Th>{localeStore.t('settings.subaccounts["table-columns"].photos')}</Th>
        <Th>{localeStore.t('settings.subaccounts["table-columns"].videos')}</Th>
        <Th>{localeStore.t('settings.subaccounts["table-columns"].usage')}</Th>

        {!userStore.isSubaccount && (
          <Th>{localeStore.t('settings.subaccounts["table-columns"].actions')}</Th>
        )}
      </Tr>
    </Thead>
  );
});
