import { PaymentsTableColumnType } from "./payments.page.types";

export const paymentsTableColumns: PaymentsTableColumnType[] = [
  {
    name: '"createdAt"',
    label: "Date",
    isSortable: true,
  },
  {
    name: "amount",
    label: "Amount",
    isSortable: true,
  },
  {
    name: "currency",
    label: "Currency",
    isSortable: false,
  },
  {
    name: "sku",
    label: "SKU",
    isSortable: false,
  },
  {
    name: "customer",
    label: "Customer",
    isSortable: false,
  },
  {
    name: "status",
    label: "Status",
    isSortable: true,
  },
];
