import React from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, Heading, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./page-results-empty.module.css";
import { ReactComponent as ResultsSVG } from "src/external-ts/assets/images/media/search.svg";

export interface PageResultsEmpty {
  search: string;
  isOnlySearch: boolean;
}

export const PageResultsEmpty: React.FC<PageResultsEmpty> = observer(({ search, isOnlySearch }) => {
  const { localeStore } = useStores();

  return (
    <>
      <div className={styles.iconCircle}>
        <SvgIcon icon={ResultsSVG} size={56} />
      </div>

      <Heading className={styles.title} tag="h2" color="textSecondary">
        {isOnlySearch &&
          `${localeStore.t(
            'components.layout["page-results-empty"]["is-only-search"].title',
          )} “${search}”`}
        {!isOnlySearch &&
          localeStore.t('components.layout["page-results-empty"]["is-not-only-search"].title')}
      </Heading>
      <Typography size="medium" color="textSecondary">
        {isOnlySearch &&
          localeStore.t('components.layout["page-results-empty"]["is-only-search"].subtitle')}
        {!isOnlySearch &&
          localeStore.t('components.layout["page-results-empty"]["is-not-only-search"].subtitle')}
      </Typography>
    </>
  );
});
