import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import styles from "./page-results-placeholder-container.module.css";
import { memo } from "@gemlightbox/core-kit";

export interface PageResultsPlaceholderContainerProps {
  containerClassName?: string;
  contentClassName?: string;
}

export const PageResultsPlaceholderContainer: React.FC<PageResultsPlaceholderContainerProps> = memo(
  ({ children, containerClassName, contentClassName }) => {
    return (
      <div
        className={clsx(styles.container, containerClassName)}
        data-cy="page-results-placeholder-container"
      >
        <div className={clsx(styles.content, contentClassName)}>{children}</div>
      </div>
    );
  },
);
