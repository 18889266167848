import React, { useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import {
  Button,
  clsx,
  ExpandableSidebar,
  isDeepEmpty,
  Typography,
  useWindowSize,
  useDidUpdate,
} from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import { useStores } from "src/hooks";
import {
  ProductParameterRequestModel,
  ProductPriceRangeModel,
  ProductRangeModel,
} from "src/models";
import { CustomFilters } from "./custom-filters/custom-filters";
import { DefaultFilters } from "./default-filters";
import { FiltersType } from "./filters-sidebar.types";
import styles from "./filters-sidebar.module.css";
import { defaultRange } from "./filters-sidebar.constants";

export interface FiltersSidebarProps {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
  options: {
    initialFilters: FiltersType;
    appliedFilters: FiltersType;
    onApply: (filters: FiltersType) => void;
    onClear: VoidFunction;
    title?: string;
    name?: string;
    description?: string;
    disableCustomFilters?: boolean;
    disableSKUFilter?: boolean;
    disableTitleFilter?: boolean;
    disableDescriptionFilter?: boolean;
    disableMediaTypeFilter?: boolean;
    renderNumberAsRange?: boolean;
    quantityType?: "range" | "exact";
    quantityRange?: ProductRangeModel;
    priceRange?: ProductPriceRangeModel;
  };
}

export const FiltersSidebar: React.FC<FiltersSidebarProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { localeStore, catalogsStore, productsStore } = useStores();

    const {
      initialFilters,
      appliedFilters,
      onApply,
      onClear,
      priceRange = defaultRange,
      quantityRange = { ...defaultRange, min: 0 },
      title = "Filters",
      name,
      description,
      disableCustomFilters,
      disableSKUFilter,
      disableTitleFilter,
      disableDescriptionFilter,
      disableMediaTypeFilter,
      renderNumberAsRange,
      quantityType,
    } = options;
    const { windowWidth } = useWindowSize();

    const [filters, setFilters] = useState<FiltersType>(() => toJS(appliedFilters));

    const handleFromPriceChange = (fromPrice: number) => {
      let _filters = filters;

      const { toPrice } = filters;

      if (isNaN(fromPrice)) return;
      if (!toPrice) _filters = { ..._filters, toPrice: priceRange.max, fromPrice };
      else _filters = { ..._filters, fromPrice };

      setFilters(_filters);
    };

    const handleToPriceChange = (toPrice: number) => {
      let _filters = filters;

      const { fromPrice } = filters;

      if (isNaN(toPrice)) return;
      if (!fromPrice) _filters = { ..._filters, fromPrice: priceRange.min, toPrice };
      else _filters = { ..._filters, toPrice };

      setFilters(_filters);
    };

    const handleRangePriceChange = ([fromPrice, toPrice]: number[]) =>
      setFilters({ ...filters, fromPrice, toPrice });

    const handleFromQuantityChange = (fromQuantity: number) => {
      let _filters = filters;
      const [, toQuantity] = filters.quantity;

      if (isNaN(fromQuantity)) return;
      if (!toQuantity) _filters = { ..._filters, quantity: [fromQuantity, quantityRange.max] };
      else _filters = { ...filters, quantity: [fromQuantity, toQuantity] };
      setFilters(_filters);
    };

    const handleToQuantityChange = (toQuantity: number) => {
      let _filters = filters;
      const [fromQuantity] = filters.quantity;

      if (isNaN(toQuantity)) return;
      if (!fromQuantity) _filters = { ..._filters, quantity: [quantityRange.min, toQuantity] };
      else _filters = { ...filters, quantity: [fromQuantity, toQuantity] };

      setFilters(_filters);
    };

    const handleExactQuantityChange = (quantityValue: number) => {
      if (isNaN(quantityValue)) return;

      setFilters({ ...filters, quantity: [quantityValue, quantityValue] });
    };

    const handleRangeQuantityChange = ([fromQuantity, toQuantity]: number[]) =>
      setFilters({ ...filters, quantity: [fromQuantity, toQuantity] });

    const handleDefaultAttributeChange =
      (name: "title" | "detailedTitle" | "description" | "productType" | "mediaType" | "user_id") =>
      (value: string | number) => {
        setFilters({ ...filters, [name]: value });
      };

    const onMediaExistFilterChange = (value: string) => {
      switch (value) {
        case "with": {
          setFilters({ ...filters, isThereMedia: true });
          break;
        }
        case "without": {
          setFilters({ ...filters, isThereMedia: false });
          break;
        }
        default: {
          setFilters({ ...filters, isThereMedia: undefined });
        }
      }
    };

    const onLinkAccessFilterChange = (value: string) => {
      switch (value) {
        case "private": {
          setFilters({ ...filters, isPrivate: true });
          break;
        }
        case "public": {
          setFilters({ ...filters, isPrivate: false });
          break;
        }
        default: {
          setFilters({ ...filters, isPrivate: undefined });
        }
      }
    };

    const handleCustomAttributeChange = (newAttribute: ProductParameterRequestModel) => {
      const existingAttribute = filters.attributes.find(
        (attribute) => attribute.attribute_id === newAttribute.attribute_id,
      );

      let newAttributes = [];

      if (existingAttribute) {
        newAttributes = filters.attributes.map((currentAttribute) => {
          if (currentAttribute.attribute_id === newAttribute.attribute_id) return newAttribute;
          return currentAttribute;
        });
      } else {
        newAttributes = [...filters.attributes, newAttribute];
      }

      setFilters({
        ...filters,
        attributes: newAttributes,
      });
    };

    const checkIfFromPriceIsValid = (): boolean => {
      const { fromPrice, toPrice: _toPrice } = filters;
      const toPrice = _toPrice ?? 0;
      const min = priceRange.min ?? 0;

      if (fromPrice === 0) return false;
      if (!fromPrice) return true;
      return fromPrice <= toPrice && fromPrice >= min;
    };

    const checkIfToPriceIsValid = (): boolean => {
      const { fromPrice: _fromPrice, toPrice } = filters;
      const fromPrice = _fromPrice ?? 0;
      const max = priceRange.max ?? 0;

      if (toPrice === 0) return false;
      if (!toPrice) return true;
      return toPrice >= fromPrice && toPrice <= max;
    };

    const checkIfFromQuantityIsValid = (): boolean => {
      const [fromQuantity, toQuantity] = filters.quantity;

      if (!fromQuantity || !toQuantity) return true;
      return fromQuantity <= toQuantity;
    };

    const checkIfToQuantityIsValid = (): boolean => {
      const [fromQuantity, toQuantity] = filters.quantity;

      if (!toQuantity || !fromQuantity) return true;
      return toQuantity >= fromQuantity;
    };

    const checkIfFiltersChanged = () => {
      const appliedFiltersStr = JSON.stringify(appliedFilters);
      const currentFiltersStr = JSON.stringify(filters);

      return appliedFiltersStr !== currentFiltersStr;
    };

    const checkIfFilterEmpty = () => {
      const parsedFilters = catalogsStore.parseFilters(productsStore.activeFilters);
      return isDeepEmpty(parsedFilters);
    };

    const handleApply = () => {
      onApply(filters);
      setClose();
    };

    const isTablet = windowWidth < BREAKPOINTS.tablet;
    const isMobile = windowWidth < BREAKPOINTS.mobileL;
    const isChanged = checkIfFiltersChanged();
    const isFromPriceValid = checkIfFromPriceIsValid();
    const isToPriceValid = checkIfToPriceIsValid();
    const isFromQuantityValid = checkIfFromQuantityIsValid();
    const isToQuantityValid = checkIfToQuantityIsValid();
    const isFilterEmpty = checkIfFilterEmpty();
    const canApply =
      isChanged &&
      isFromPriceValid &&
      isToPriceValid &&
      isFromQuantityValid &&
      isToQuantityValid &&
      !isFilterEmpty;

    useDidUpdate(() => {
      productsStore.setFilters(filters);
    }, [filters]);

    return (
      <ExpandableSidebar
        contentClassName={styles.content}
        sidebarHeaderClassName={clsx(
          styles.sidebarHeader,
          description ? styles.withDescription : undefined,
        )}
        sidebarContentClassName={styles.sidebarContent}
        sidebarFooterClassName={styles.sidebarFooter}
        name={`sidebar-filters-${name}`}
        icon="cross"
        iconPos={isMobile ? "right" : "outside"}
        title={localeStore.t(
          'products["products-list"]["products-table"]["products-table-header"].filters',
        )}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        footer={
          <>
            <Button
              appearance="tertiaryOutlined"
              onClick={() => {
                setFilters(toJS(initialFilters));
                onClear();
              }}
              data-cy="clear-filters-button"
            >
              {localeStore.t('components.business.filters["filters-sidebar"].buttons.clear')}
            </Button>
            <Button onClick={handleApply} disabled={!canApply} data-cy="apply-filters-button">
              {localeStore.t('components.business.filters["filters-sidebar"].buttons.apply')}
            </Button>
          </>
        }
      >
        <>
          {description && (
            <Typography
              className={styles.description}
              size={isTablet ? "small" : "medium"}
              color="textTertiary"
            >
              {description}
            </Typography>
          )}
          <div data-cy="filters-list" className={styles.filtersList}>
            <DefaultFilters
              filters={filters}
              priceRange={priceRange}
              quantityRange={quantityRange}
              isFromPriceValid={isFromPriceValid}
              isToPriceValid={isToPriceValid}
              isFromQuantityValid={isFromQuantityValid}
              isToQuantityValid={isToQuantityValid}
              onFromPriceChange={handleFromPriceChange}
              onToPriceChange={handleToPriceChange}
              onRangePriceChange={handleRangePriceChange}
              onFromQuantityChange={handleFromQuantityChange}
              onToQuantityChange={handleToQuantityChange}
              onRangeQuantityChange={handleRangeQuantityChange}
              onExactQuantityChange={handleExactQuantityChange}
              onMediaExistFilterChange={onMediaExistFilterChange}
              onLinkAccessFilterChange={onLinkAccessFilterChange}
              onSKUChange={handleDefaultAttributeChange("title")}
              onTitleChange={handleDefaultAttributeChange("detailedTitle")}
              onDescriptionChange={handleDefaultAttributeChange("description")}
              onProductTypeChange={handleDefaultAttributeChange("productType")}
              onMediaTypeChange={handleDefaultAttributeChange("mediaType")}
              onUserFilter={handleDefaultAttributeChange("user_id")}
              disableSKUFilter={disableSKUFilter}
              disableTitleFilter={disableTitleFilter}
              disableDescriptionFilter={disableDescriptionFilter}
              disableMediaTypeFilter={disableMediaTypeFilter}
              quantityType={quantityType}
            />

            {!disableCustomFilters && (
              <CustomFilters
                attributesFilters={filters.attributes}
                renderNumberAsRange={renderNumberAsRange}
                onChange={handleCustomAttributeChange}
              />
            )}
          </div>
        </>
      </ExpandableSidebar>
    );
  },
);
