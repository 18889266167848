import { ObjectType } from "@gemlightbox/core-kit";

import { AIDescriptionBuilderForm } from "./ai-description-builder-sidebar.types";

export const aiDescriptionBuilderValidation = (values: AIDescriptionBuilderForm) => {
  const errors: ObjectType = {};

  if (!values.detailedTitle.trim()) {
    errors.detailedTitle = "This field is required";
  }

  if (!values.productType.trim()) {
    errors.productType = "This field is required";
  }

  if (values.tags?.length < 3) {
    errors.tags = "At least 3 tags are required";
  }

  return errors;
};
