import React from "react";
import { observer } from "mobx-react-lite";
import { useDidMount, SvgIcon, Heading } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { CardModel } from "src/models";
import { PaymentCard } from "./payment-card";

import { ReactComponent as AddIcon } from "src/external-ts/assets/images/plus-circle-grey.svg";

import styles from "./payment-info.module.css";

interface PaymentInfoProps {
  name: string;
}

export const PaymentInfo: React.FC<PaymentInfoProps> = observer(({ name }) => {
  const { notificationStore, modalsStore, subscriptionsStore, localeStore } = useStores();

  useDidMount(async () => {
    await subscriptionsStore.loadPaymentCards();
  });

  const onChangeDefaultCard = async (card: CardModel, cardIndex: number) => {
    if (card.isDefault) return;
    await subscriptionsStore.changeDefaultCard(card.id, cardIndex);
  };

  const handleDeleteCard = async (card: CardModel) => {
    notificationStore.open({
      title: `${localeStore.t(
        'settings.profile["payment-info"]["remove-card"].title',
      )} **** **** **** ${card.last4}?`,
      confirmText: localeStore.t("common.buttons.yes"),
      cancelText: localeStore.t("common.buttons.no"),
      confirmAppearance: "secondary",
      onOk: async () => await subscriptionsStore.deletePaymentCard(card.id),
    });
  };

  const handleAddNewCard = () => {
    modalsStore.open("AddCardModal", {
      onAddCardSuccess: async () => await subscriptionsStore.loadPaymentCards(),
    });
  };

  return (
    <div className={styles.container}>
      <Heading tag="h2" color="textSecondary">
        {localeStore.t('settings.profile["payment-info"].title')}
      </Heading>

      <div className={styles.cardList}>
        {subscriptionsStore.paymentCardList.map((card, index) => {
          return (
            <PaymentCard
              key={card.id}
              name={name}
              cardIndex={index}
              data={card}
              isDefault={card.isDefault}
              loading={subscriptionsStore.paymentCardsLoading}
              onChangeDefaultCard={onChangeDefaultCard}
              onDelete={handleDeleteCard}
            />
          );
        })}
        <div className={styles.addCardButtonContainer} onClick={handleAddNewCard}>
          <SvgIcon icon={AddIcon} size={32} />
          {localeStore.t('settings.profile["payment-info"]["add-card"]')}
        </div>
      </div>
    </div>
  );
});
