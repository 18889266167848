import React from "react";
import { useStores } from "src/hooks";
import { useNavigate } from "react-router-dom";
import { SvgIcon } from "@gemlightbox/core-kit";
import { getHealth } from "src/api";

import { CREATE_PAGE } from "src/constants";
import maintenanceSVG from "src/containers/maintenance/images/maintenance-icon.svg";
import styles from "./maintenance.module.css";

export const Maintenance = () => {
  const { localeStore } = useStores();
  const navigate = useNavigate();

  const setIntervalId = setInterval(async () => {
    const res = await getHealth.getRequest().fetch();
    if (res.details.statusCode === 200) {
      clearInterval(setIntervalId);
      return navigate(CREATE_PAGE.path);
    }
  }, 1000 * 60 * 1);

  return (
    <div className={styles.maintenance}>
      <SvgIcon className={styles.iconCircle} icon={maintenanceSVG} />
      <span className={styles.title}>{localeStore.t("maintenance.title")}</span>
      <span className={styles.subTitle}>
        {localeStore.t('maintenance["sub-title"]')}
        <a href="https://picupmedia.com/contact-us/" target="_blank" rel="noreferrer">
          {localeStore.t("maintenance.link")}
        </a>
        .
      </span>
    </div>
  );
};

export default Maintenance;
