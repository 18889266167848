import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Typography, Button } from "@gemlightbox/core-kit";

import { proFeatureTooltip } from "src/utils";
import { useStores } from "src/hooks";
import { CREATE_PAGE } from "src/constants";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";

import backgroundImg from "src/external-ts/assets/images/media/media-item/background.png";
import styles from "./blink-promo.module.css";

type BlinkPromoProps = {
  style?: React.CSSProperties;
};

export const BlinkPromo: React.FC<BlinkPromoProps> = observer(({ style }) => {
  const navigate = useNavigate();

  const { canBlinks } = useLimits();

  const { localeStore, tooltipStore } = useStores();

  const handleBlinkPointerEnter = (e: React.PointerEvent<HTMLButtonElement>) => {
    if (canBlinks) return;
    proFeatureTooltip(e.currentTarget, {
      position: "bottom",
    });
  };

  const handleBlinkPointerLeave = () => {
    if (canBlinks) tooltipStore.close();
  };

  const handleBlinkClick = () => navigate(CREATE_PAGE.path + "/augmented-reality-product");

  return (
    <div className={styles.container} style={style} data-cy="media-blink-promo">
      <img className={styles.background} src={backgroundImg} alt="" loading="lazy" />

      <div className={styles.previewContainer}>
        <img
          className={styles.preview}
          src="https://dev.static.cloud.picupmedia.com/static/ring-model.gif"
          alt="ring-model"
        />
      </div>

      <Typography className={styles.text} size="extraSmall" color="textTertiary">
        {localeStore.t('media["media-list"]["blink-promo"].title')}
      </Typography>

      <Button
        className={styles.button}
        appearance="primaryOutlined"
        onPointerEnter={handleBlinkPointerEnter}
        onPointerLeave={handleBlinkPointerLeave}
        onClick={handleBlinkClick}
        disabled={!canBlinks}
        data-cy="create-blink-button"
      >
        {localeStore.t('media["media-list"]["blink-promo"].buttons.create')}
      </Button>
    </div>
  );
});
