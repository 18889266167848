import React, { useLayoutEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  clsx,
  globalStyles,
  getNodeRect,
  useWindowSize,
  arrayOf,
  useInfinityScroll,
  RefModel,
  curry,
  handleSetRef,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ExtendedMediaModel } from "src/store";
import { MediaItem } from "./media-item";
import { mediaItemsColumnWidth, mediaItemsColumnGap } from "./media-items.constants";

import styles from "./media-items.module.css";

export type MediaItemsProps = {
  limit: number;
  forwardRef: RefModel<HTMLDivElement>;
};

export const MediaItems: React.FC<MediaItemsProps> = observer(({ limit, forwardRef }) => {
  const { mediaStore } = useStores();
  const mediaList = mediaStore.mediaList;
  const selectedAmount = mediaStore.selectedMediaAmount;

  const [columnsAmount, setColumnsAmount] = useState(0);

  const useInfinityScrollHook = useInfinityScroll<HTMLDivElement>();
  useInfinityScrollHook.onScroll(() => {
    const { limit = 1, page = 1 } = mediaStore.filters;
    const offset = page * limit;

    if (offset < mediaStore.resultsMediaAmount) {
      useInfinityScrollHook.resetTrigger();

      mediaStore.updateMediaFilter("page", (mediaStore.filters.page || 0) + 1, "upload");
    }
  });

  const handleCalcColumnsAmount = () => {
    const container = useInfinityScrollHook.ref.current;
    if (!container) return;
    const rect = getNodeRect(container);
    const dirtyColumnAmount = Math.floor(rect.width / mediaItemsColumnWidth);
    const columnGapsAmount = Math.max(dirtyColumnAmount - 1, 0);
    const columnGapsTotalWidth = columnGapsAmount * mediaItemsColumnGap;
    const finalColumnAmount = Math.floor(
      (rect.width - columnGapsTotalWidth) / mediaItemsColumnWidth,
    );
    setColumnsAmount(finalColumnAmount);
  };

  const mediasColumnsArr = useMemo(() => {
    if (!columnsAmount) return [];
    const medias = arrayOf<ExtendedMediaModel[]>(columnsAmount, () => []);

    for (let i = 0; i < mediaList.length; i++) {
      const columnIndex = i % columnsAmount;
      medias[columnIndex].push(mediaList[i]);
    }

    return medias;
  }, [columnsAmount, mediaList]);

  useWindowSize(handleCalcColumnsAmount, "ref");

  useLayoutEffect(() => {
    handleCalcColumnsAmount();
  }, [mediaList]);

  if (!mediaList.length) return null;

  return (
    <div
      className={clsx(styles.mediaItemsContainer, globalStyles.addScrollStyles)}
      ref={curry(handleSetRef)([useInfinityScrollHook.ref, forwardRef])}
    >
      {mediasColumnsArr.map((medias, i) => (
        <div key={i} className={styles.mediaItemColumn} data-cy="image-column">
          {medias.map((media) => (
            <MediaItem key={media.id} media={media} canSelect={selectedAmount < limit} />
          ))}
        </div>
      ))}
    </div>
  );
});

export default MediaItems;
