import React from "react";
import { observer } from "mobx-react-lite";
import { RefModel, VirtualizedItem } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { Row } from "./row";

type CatalogTbodyProps = {
  virtualizedItems: VirtualizedItem[];
  forwardRef?: RefModel<HTMLTableSectionElement>;
};

export const Tbody: React.FC<CatalogTbodyProps> = observer(({ virtualizedItems, forwardRef }) => {
  const { catalogsStore } = useStores();

  return (
    <div ref={forwardRef}>
      {virtualizedItems.map(({ index, styles }) => {
        const catalog = catalogsStore.catalogList[index];
        if (!catalog) return;

        return <Row key={catalog.id} data={catalog} style={styles} />;
      })}
    </div>
  );
});

export default Tbody;
