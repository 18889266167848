import { makeAutoObservable, runInAction } from "mobx";
import {
  stringToObjectProperty,
  Nullable,
  ApiRequest,
  ExtractApiResponse,
} from "@gemlightbox/core-kit";

import { getTranslationFile } from "src/api";
import { LocaleModel, SupportedLocalesModel } from "src/models";
import { config } from "src/config/environment";
import { LocaleCodeTypes } from "./locale-generated.store.types";
import { useStores } from "src/hooks";
import { pushDataLayerEvent } from "src/utils";

export class LocaleStore {
  private _prevRequest: Nullable<ApiRequest<ExtractApiResponse<typeof getTranslationFile>>> = null;

  private _currentLocale: Nullable<LocaleModel>;
  private _currentLocaleCode: Nullable<SupportedLocalesModel>;
  private _initLoading = true;
  private _loading = true;

  public get currentLocale() {
    return this._currentLocale;
  }

  public get currentLocaleCode() {
    return this._currentLocaleCode;
  }

  public get initLoading() {
    return this._initLoading;
  }

  public get loading() {
    return this._loading;
  }

  constructor() {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async fetchLocale(locale: SupportedLocalesModel, isInit = false) {
    // if (this.loading && this._prevRequest) this._prevRequest.abort();

    runInAction(() => {
      if (isInit) {
        this._initLoading = true;
      }
      this._loading = true;
    });

    const request = getTranslationFile.getRequest({
      params: { key: config.localeKey, locale: locale + ".json" },
    });
    this._prevRequest = request;
    const { success, error, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      this._loading = false;
      const { modalsStore } = useStores();

      if (success) {
        this._currentLocale = success;
        this._currentLocaleCode = locale;

        localStorage.setItem("localeCode", this._currentLocaleCode || "");
        this.setWritingDirection();
      } else {
        console.error("Locale api error: api no response");
      }

      // Download default language if you couldn't download non-default language
      if (error) {
        console.error("fetch language pack failed", error, details);
        pushDataLayerEvent({
          event: "gemhub:locale:text:download:error",
          event_params: {
            locale: locale,
            download_url: request.requestConfig.href,
            http_status_code: details.statusCode,
            details_message: details,
            error_message: error,
          },
        });
        if (locale !== "en") {
          this.fetchLocale("en", isInit);
        } else {
          modalsStore.open("LanguageUnfetchModal", {});
        }
      }
      setTimeout(() => {
        runInAction(() => (this._initLoading = false));
      });
    });
  }
  /* Requests ↑ */

  /* UI State ↓ */
  // Uncomment this line if we would need not strict TS check for locale codes
  // public t(path: LocaleCodeTypes | string): string {
  public t(path: LocaleCodeTypes, defaultValue?: string): string {
    if (!this._currentLocale) return "no locale found";
    const translation = stringToObjectProperty(this._currentLocale, path);
    return String(translation ?? defaultValue ?? path);
  }

  public setWritingDirection() {
    const rightToLeft = ["ar", "he", "fa", "ur", "yi"];
    if (document?.body?.setAttribute && this._currentLocaleCode) {
      if (rightToLeft.includes(this._currentLocaleCode)) {
        document.body.setAttribute("dir", "rtl");
      } else {
        document.body.setAttribute("dir", "ltr");
      }
    }
  }
  /* UI State ↑ */
}
