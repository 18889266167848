import { Nullable } from "@gemlightbox/core-kit";
import { GlobalImageDataModel } from "./global-entity.models";

export type SubaccountModel = {
  avatar: GlobalImageDataModel | string | null;
  countOfImages: string;
  countOfVideos: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: Nullable<string>;
  role: SubaccountRoleType;
  sizeOfImages: Nullable<number>;
  sizeOfVideos: Nullable<number>;
  status: SubaccountStatusType;
  user_id: number;
};

// the data handle policy for the deleted subaccount
export enum DataStrategy {
  RETAIN_ALL = "retain_all",
  RETAIN_PARTIAL = "retain_partial",
  RETAIN_NONE = "retain_none",
}

export type SubaccountStatusType = "pending" | "confirmed";
export type SubaccountRoleType = "sub" | "master";
