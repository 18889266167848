import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Heading, Modal, Typography, useWindowSize } from "@gemlightbox/core-kit";

import { ExtendedMediaModel, ModalExtendedType } from "src/store";
import { useStores } from "src/hooks";

import styles from "./delete-media-modal.module.css";
import { PendingUploadStatus } from "src/models";

export type DeleteMediaModalProps = ModalExtendedType<{
  media: ExtendedMediaModel[];
  onDelete?: VoidFunction;
}>;

export const DeleteMediaModal: React.FC<DeleteMediaModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { mediaStore, localeStore } = useStores();

    const { windowWidth } = useWindowSize();

    const mediaAmount = options.media.length === 1 ? "single" : "multi";

    const mediaType =
      options.media[0].type === "image" || options.media[0].type === "blink"
        ? "model-image"
        : "media";

    const { media } = options;
    const isUploading = media.findIndex((f) => f?.status === PendingUploadStatus.pending) >= 0;

    const handleSubmit = () => {
      mediaStore.deleteMedia(media);
      setClose();
      options.onDelete?.();
    };

    return (
      <Modal
        contentClassName={styles.modal}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        withCross
        disablePadding
        disableBorderRadius
        data-cy="delete-media-modal"
      >
        <Heading
          className={styles.title}
          tag={windowWidth > 375 ? "h2" : "h3"}
          color="textSecondary"
        >
          {localeStore.t(`media.modals.delete.title.${mediaAmount}`)}
        </Heading>
        <Typography className={styles.article} size="medium" color="textTertiary">
          {mediaAmount === "multi" &&
            !isUploading &&
            localeStore.t(`media.modals.delete.description.multi`)}
          {mediaAmount === "multi" &&
            isUploading &&
            localeStore.t('products["products-list"]["pending-upload"]["delete-media"]')}
          {mediaAmount === "single" &&
            !media[0].sku &&
            localeStore.t(`media.modals.delete.description.single["without-sku"]`)}
          {mediaAmount === "single" &&
            media[0].sku &&
            !isUploading &&
            localeStore.t(`media.modals.delete.description.single["with-sku"].first.${mediaType}`) +
              media[0].sku +
              localeStore.t(`media.modals.delete.description.single["with-sku"].second`)}
          {mediaAmount === "single" &&
            media[0].sku &&
            isUploading &&
            localeStore.t('products["products-list"]["pending-upload"]["delete-media"]')}
        </Typography>
        {!isUploading && (
          <Typography className={styles.warning} size="small600" color="textSecondary">
            {localeStore.t(`media.modals.delete.strong.${mediaAmount}`)}
          </Typography>
        )}

        <div className={styles.buttons}>
          <Button appearance="tertiaryOutlined" onClick={setClose} data-cy="cancel-deletion">
            {localeStore.t("media.modals.delete.buttons.cancel")}
          </Button>
          <Button appearance="error" onClick={handleSubmit} data-cy="delete-media">
            {localeStore.t("media.modals.delete.buttons.delete")}
          </Button>
        </div>
      </Modal>
    );
  },
);
