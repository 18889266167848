import React, { Fragment } from "react";
import { FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  SvgIcon,
  Form,
  FormButtons,
  FormField,
  Button,
  clsx,
  useCall,
  Heading,
  Typography,
} from "@gemlightbox/core-kit";

import { getEmailAlreadyExists, postRequestPasswordReset } from "src/api";
import { useStores } from "src/hooks";
import { LOGIN_PAGE } from "src/constants";
import { initialResetPasswordForm, sendRequestBtnId, btnTooltipTimeout } from "./request.constants";
import { RequestResetPasswordForm } from "./request.types";
import { requestPasswordResetValidate } from "./request.utils";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-circle-grey.svg";
import styles from "./request.module.css";

export const RequestPage: React.FC = observer(() => {
  const navigate = useNavigate();

  const { tooltipStore, localeStore } = useStores();

  const { submit, onCallSuccess } = useCall(postRequestPasswordReset);
  onCallSuccess(() => {
    tooltipStore.open(
      <Fragment>
        <SvgIcon className={styles.checkmarkIcon} icon={CheckmarkSVG} />
        {localeStore.t('auth.reset.request["request-sent"]')}
      </Fragment>,
      {
        className: styles.tooltipContent,
        target: sendRequestBtnId,
        position: "bottom",
        disableOnOutsideClick: true,
        disableAutoPosChange: true,
        withAngle: true,
      },
      {
        timeout: btnTooltipTimeout,
      },
    );
  });

  const handleSubmit = async (
    { email }: RequestResetPasswordForm,
    helpers: FormikHelpers<RequestResetPasswordForm>,
  ) => {
    const existsResult = await getEmailAlreadyExists.getRequest({ params: { email } }).fetch();

    if (!existsResult.success) {
      return helpers.setFieldError(
        "email",
        localeStore.t('auth.reset.request.fields.email["error-not-registered"]'),
      );
    }

    await submit({ params: { email } });
  };

  const handleGoBack = () => navigate(LOGIN_PAGE.path);

  return (
    <div className={styles.filler}>
      <div className={styles.requestPageContainer}>
        <Form
          className={styles.requestPageFormContainer}
          contentClassName={styles.requestPageWrapper}
          initialValues={initialResetPasswordForm}
          validate={requestPasswordResetValidate}
          onSubmit={handleSubmit}
        >
          <Heading tag="h2" color="textSecondary">
            {localeStore.t("auth.reset.request.title")}
          </Heading>

          <Typography className={styles.message} size="small" color="textTertiary">
            {localeStore.t("auth.reset.request.subtitle")}
          </Typography>

          <FormField
            className={styles.emailContainer}
            type="text"
            name="email"
            label={localeStore.t("auth.reset.request.fields.email.label")}
            placeholder={localeStore.t("auth.reset.request.fields.email.placeholder")}
            appearance="primaryV2"
          />

          <FormButtons align="column">
            <Button id={sendRequestBtnId} type="submit">
              {localeStore.t("auth.reset.request.buttons.send")}
            </Button>
          </FormButtons>

          <Typography
            className={clsx(styles.message, styles.centered)}
            size="small"
            color="textTertiary"
          >
            {localeStore.t("auth.reset.request.hint")}
          </Typography>

          <FormButtons align="column">
            <Button className={styles.goBackBtn} appearance="secondaryGhost" onClick={handleGoBack}>
              {localeStore.t("auth.reset.request.buttons.back")}
            </Button>
          </FormButtons>
        </Form>
      </div>
    </div>
  );
});

export default RequestPage;
