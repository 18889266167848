import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggableRubric,
  DropResult,
} from "react-beautiful-dnd";
import { Button, Input, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { sortAttributeValues } from "src/utils";
import { DraggableValueItem } from "./draggable-value-item";

import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-circle-grey.svg";

import styles from "./select-values.module.css";

export interface SelectValuesProps {
  values: string[];
  onValuesChange: (values: string[]) => void;
}

export const SelectValues: React.FC<SelectValuesProps> = observer(({ values, onValuesChange }) => {
  const { localeStore } = useStores();

  const [value, setValue] = useState("");
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const checkIfValuesExists = () => {
    const _values = values.map((item) => item.trim());
    return _values.includes(value.trim());
  };

  const handleAddValue = () => {
    const exists = checkIfValuesExists();
    if (exists) return;

    onValuesChange(sortAttributeValues([...values, value]));
    setValue("");
  };

  const handleDeleteValue = (_value: string) => {
    const result = values.filter((item) => item !== _value);
    onValuesChange(result);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") handleAddValue();
  };

  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return;

    const result = [...values];
    const [valueToMove] = result.splice(source.index, 1);

    result.splice(destination.index, 0, valueToMove);

    // Here dragged styles applies to freshly placed item to prevent horrible hover transition
    setDraggedIndex(destination.index);

    setTimeout(() => setDraggedIndex(null), 1);

    onValuesChange(result);
  };

  const renderClone = (
    provided: DraggableProvided,
    _: DraggableStateSnapshot,
    rubric: DraggableRubric,
  ) => {
    const value = values[rubric.source.index];

    return (
      <DraggableValueItem
        value={value}
        provided={provided}
        isDragged
        onDelete={handleDeleteValue}
      />
    );
  };

  return (
    <div className={styles.container}>
      <Input
        data-cy="input-value-field"
        className={styles.input}
        appearance="primaryV2"
        placeholder={localeStore.t(
          'products.modals["create-attribute-sidebar"]["select-settings"].placeholder',
        )}
        value={value}
        disableError
        onChange={setValue}
        onKeyPress={handleKeyPress}
      >
        <Button data-cy="add-value" className={styles.inputButton} onClick={handleAddValue}>
          <SvgIcon icon={PlusSVG} />
        </Button>
      </Input>

      {values.length !== 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="selectValues" renderClone={renderClone}>
            {(provided) => (
              <React.Fragment>
                <div
                  className={styles.valuesList}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {values.map((value, index) => (
                    <Draggable draggableId={value} key={value} index={index}>
                      {(provided) => (
                        <DraggableValueItem
                          value={value}
                          provided={provided}
                          isDragged={index === draggedIndex}
                          onDelete={handleDeleteValue}
                        />
                      )}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </React.Fragment>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
});
