import React from "react";
import { observer } from "mobx-react-lite";
import { Image, Heading, Typography } from "@gemlightbox/core-kit";

import { PageResultsPlaceholderContainer } from "src/external-ts/components";
import { useStores } from "src/hooks";

import EmptyTrashBin from "src/external-ts/assets/images/empty-trash-bin.png";
import styles from "./empty-list-placeholder.module.css";

export const EmptyListPlaceholder: React.FC = observer(() => {
  const { localeStore } = useStores();

  return (
    <PageResultsPlaceholderContainer>
      <Image src={EmptyTrashBin} className={styles.image} />
      <Heading tag="h2" color="textSecondary" className={styles.title}>
        {localeStore.t('["trash-bin"].placeholder.title')}
      </Heading>
      <Typography color="textTertiary" size="small">
        {localeStore.t('["trash-bin"].placeholder.article')}
      </Typography>
    </PageResultsPlaceholderContainer>
  );
});
