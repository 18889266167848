import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Heading,
  Button,
  SvgIcon,
  useFetch,
  useCall,
  formatDate,
  download,
} from "@gemlightbox/core-kit";

import { getSingleOrder, getOrderInvoice, postOrderRefund } from "src/api";
import { useStores } from "src/hooks";
import { OrderTable } from "./order-table";
import { PaymentInfoCard } from "./payment-info-card";
import { PaymentsTableStatusCell } from "../payments/payments-table/payments-table-body/payments-table-status-cell";

import { ReactComponent as ArrowSVG } from "src/external-ts/assets/images/arrow-left-grey.svg";
import { ReactComponent as GemlightboxSVG } from "src/external-ts/assets/images/gemlightbox-logo-text.svg";
import { ReactComponent as VisaSVG } from "src/external-ts/assets/images/subscription/billing/visa-card.svg";
import { ReactComponent as MastercardSVG } from "src/external-ts/assets/images/subscription/billing/mastercard.svg";
import { ReactComponent as AmericanExpressSVG } from "src/external-ts/assets/images/subscription/billing/american-express-card.svg";

import styles from "./order.page.module.css";

export const Order: React.FC = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();

  const { notificationStore, localeStore } = useStores();

  const orderParams = { orderId: Number(orderId) };
  const { payload } = useFetch(getSingleOrder.setParams(orderParams));
  const useOrderInvoiceCall = useCall(getOrderInvoice.setParams(orderParams));
  const useOrderRefundCall = useCall(postOrderRefund.setParams(orderParams));

  const handleGoBack = () => navigate(-1);

  const handleRefundOrder = () => {
    notificationStore.open({
      title: localeStore.t('integrations.payments.order["refund-modal-title"]'),
      confirmText: localeStore.t("common.buttons.yes"),
      cancelText: localeStore.t("common.buttons.no"),
      contentClassName: styles.warningContent,
      onOk: async () => await useOrderRefundCall.submit(),
    });
  };

  const handleGenerateInvoice = async () => {
    const res = await useOrderInvoiceCall.submit();
    if (res.success) download(URL.createObjectURL(res.success), `invoice-${orderId}.pdf`);
  };

  const totalPrice = payload?.lineItems.reduce((acc, item) => (acc += item.price), 0) || 0;

  const getCardLogo = () => {
    switch (payload?.card.brand) {
      case "american express":
        return AmericanExpressSVG;
      case "visa":
        return VisaSVG;
      case "mastercard":
      default:
        return MastercardSVG;
    }
  };

  return (
    <div className={styles.orderContainer}>
      <div className={styles.orderHeaderContainer}>
        <SvgIcon icon={GemlightboxSVG} size={[192, 30]} />
        <Button appearance="secondaryGhost" onClick={handleGoBack}>
          <SvgIcon icon={ArrowSVG} /> {localeStore.t("common.buttons.back")}
        </Button>
      </div>
      <div className={styles.orderPriceContainer}>
        <div className={styles.priceTitle}>
          <Typography size="big" color="textSecondary">
            {localeStore.t("integrations.payments.order.title")}
          </Typography>
          <div className={styles.price}>
            <Heading tag="h1" color="textSecondary">
              {totalPrice} {payload?.currency}
            </Heading>
            <PaymentsTableStatusCell status={payload?.status || "unpaid"} />
          </div>
        </div>
        <div className={styles.buttons}>
          <Button appearance="secondary" onClick={handleGenerateInvoice}>
            {localeStore.t('integrations.payments.order.buttons["generate-invoice"]')}
          </Button>
          <Button appearance="secondaryOutlined" onClick={handleRefundOrder}>
            {localeStore.t("integrations.payments.order.buttons.cancel")}
          </Button>
        </div>
      </div>
      <div className={styles.orderPaymentInfo}>
        <PaymentInfoCard label="Last update">
          <Typography size="small" color="textSecondary">
            {formatDate(payload?.updatedAt, "MMM DD, hh:MM AA")}
          </Typography>
        </PaymentInfoCard>
        <PaymentInfoCard label="Customer">
          <Typography size="small600" color="textSecondary">
            {payload?.customerName}
          </Typography>
        </PaymentInfoCard>
        <PaymentInfoCard label="Payment method">
          <Typography className={styles.paymentMethodContent} size="small" color="textSecondary">
            <SvgIcon icon={getCardLogo()} size={[29, 18]} /> **** {payload?.card.last_digits}
          </Typography>
        </PaymentInfoCard>
      </div>
      <div className={styles.orderCheckoutSummaryContainer}>
        <Heading tag="h3" color="textSecondary">
          {localeStore.t("integrations.payments.order.summary")}
        </Heading>
        <PaymentInfoCard label="Shipping address" width={330}>
          <div className={styles.orderShippingAddress}>
            <Typography size="small600" color="textSecondary">
              {payload?.customerName}
            </Typography>
            <Typography className={styles.address} size="small" color="textSecondary">
              {payload?.shippingDetails.line1}
              <br /> {payload?.shippingDetails.city}, {payload?.shippingDetails.state}{" "}
              {payload?.shippingDetails.postalCode} {payload?.shippingDetails.country}
            </Typography>
          </div>
        </PaymentInfoCard>
        <div className={styles.orderTableContainer}>
          <Typography size="small600" color="textSecondary">
            Products
          </Typography>
          <OrderTable rows={payload?.lineItems || ([] as any)} totalPrice={totalPrice} />
        </div>
      </div>
    </div>
  );
};

export default Order;
