import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { clsx, SvgIcon, Heading, Button } from "@gemlightbox/core-kit";

import { YourSubscriptionPlanBadge } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { CREATE_PAGE } from "src/constants";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { HeaderProfileButton } from "src/external-ts/components/business/header-profile-button";

import { ReactComponent as ArrowLeftBack } from "src/external-ts/assets/images/arrow-left-oblong-grey.svg";
import styles from "./page-header.module.css";

export type PageHeaderProps = {
  title: string;
  className?: string;
  showSubscriptionBadge?: boolean;
  onBack?: VoidFunction;
};

export const PageHeader: React.FC<PageHeaderProps> = observer(
  ({ title, className = "", showSubscriptionBadge = false, onBack }) => {
    const navigate = useNavigate();

    const { userStore, subscriptionsStore } = useStores();

    const handleBadgeButtonClick = () => {
      if (userStore.isSubaccount) return navigate(CREATE_PAGE.path);
      navigate(getSettingsTab(SettingTabNames.subscriptions)?.item.path as string);
    };

    return (
      <header
        className={clsx(styles.pageHeaderContainer, className)}
        data-cy={`${title}-page-header`}
      >
        <div className={styles.headingContainer}>
          {onBack && (
            <Button appearance="secondaryGhost" onClick={onBack}>
              <SvgIcon icon={ArrowLeftBack} />
            </Button>
          )}

          <Heading tag="h3">{title}</Heading>

          {showSubscriptionBadge &&
            !subscriptionsStore?.isLoadingSubscription &&
            !userStore.isAppStoreDemoAccount && (
              <button className={styles.pageHeaderBadgeButton} onClick={handleBadgeButtonClick}>
                <YourSubscriptionPlanBadge
                  hideInterval={subscriptionsStore.isOldStarterSubscription}
                />
              </button>
            )}
        </div>

        <HeaderProfileButton />
      </header>
    );
  },
);
