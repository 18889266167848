import React from "react";
import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { StepLabel } from "./step-label";

import styles from "./steps-bar.module.css";

export type StepsBarProps = {
  currentStep: 1 | 2;
  className?: string;
  completed?: boolean;
};

export const StepsBar: React.FC<StepsBarProps> = observer(
  ({ currentStep, className = "", completed = false }) => {
    const { localeStore } = useStores();

    const isFirst = currentStep === 1;
    const isSecond = currentStep === 2;

    return (
      <div className={clsx(styles.container, className)}>
        <StepLabel
          label={localeStore.t('settings["dns-settings"]["steps-bar"].first')}
          order={1}
          active={isFirst}
          completed={completed}
        />
        <div className={styles.separator} />
        <StepLabel
          label={localeStore.t('settings["dns-settings"]["steps-bar"].second')}
          order={2}
          active={isSecond}
          completed={completed}
        />
      </div>
    );
  },
);
