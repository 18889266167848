import { Nullable, TextEditorUtils } from "@gemlightbox/core-kit";

import {
  AttributeModel,
  AttributeType,
  GlobalErrorsMapModel,
  ProductRequestModel,
} from "src/models";
import { ColumnType } from "src/store";

export const filterEmptyColumns = (columns: ColumnType[]) => columns.filter(({ name }) => name);

export const parseValues = (values: string) =>
  values ? values.split(", ").map((value) => value) : [];

export const columnsToProducts = (
  columns: ColumnType[],
  attributes: AttributeModel[],
): ProductRequestModel[] =>
  columns.reduce((acc, { name, values }) => {
    values.forEach((value, index) => {
      const attribute = attributes.find((item) => item.name === name);
      if (!attribute) return;

      const product: ProductRequestModel = acc[index] || {};
      const parameters = product.parameters || [];
      let newValue = value;

      if (name === "title" && !product.title) {
        product.title = value?.toString() || "";
      }

      if (name === "price" && !value) {
        value = undefined;
      }

      if (name === attribute.name) {
        if (attribute.type === AttributeType.text || attribute.type === AttributeType.number) {
          newValue = value !== undefined ? value.toString() : "";
        }

        if (attribute.type === AttributeType.number) {
          if (value !== 0) {
            newValue = value?.toString();
          }
        }
      }

      if (newValue) {
        product.parameters = [...parameters, { attribute_id: attribute.id, value: newValue }];
      }

      acc[index] = product;
    });

    return acc;
  }, [] as ProductRequestModel[]);

export const getInvalidRowsCount = (errors: GlobalErrorsMapModel): number => {
  return Object.entries(errors).reduce((acc, [_, values]) => {
    acc += values.length;
    return acc;
  }, 0);
};

interface ValidateProducts {
  (products: ProductRequestModel[], attributes: AttributeModel[]): [
    GlobalErrorsMapModel,
    ProductRequestModel[],
  ];
}
export const validateProducts: ValidateProducts = (products, attributes) => {
  let errors: GlobalErrorsMapModel = {};
  const validProducts = products.filter(({ title, parameters }) => {
    if (!title) return false;

    errors[title] = [];

    if (parameters) {
      let valid = true;

      parameters.forEach(({ attribute_id, value }) => {
        const attribute = attributes.find(({ id }) => id === attribute_id);

        if (!attribute) return;

        if (attribute.name === "detailedTitle") {
          const v = value as string;
          if (v.length > 100) {
            errors[title].push("Title cannot be more than 100 symbols.");
            valid = false;
          }
        }

        if (attribute.name === "description") {
          const v = value as string;
          const normalized = TextEditorUtils.normalizeEditorValue(v);
          const length = TextEditorUtils.calculateLength(normalized);

          if (length > 1000) {
            errors[title].push("Description cannot be more than 1000 symbols.");
            valid = false;
          }
        }

        if (attribute.name === "price") {
          if (value !== 0 && value && value < 0) {
            errors[title].push("Price cannot be less than 0.");
            valid = false;
          }
        }

        const parameterErrors = validateParameter({
          name: attribute.name,
          type: attribute.type,
          value,
          selectValues: attribute.values,
        });

        if (parameterErrors.length > 0) {
          errors[title] = [...errors[title], ...parameterErrors];
          valid = false;
        }
      });

      return valid;
    }

    return true;
  });

  errors = Object.entries(errors).reduce((acc, [title, errors]) => {
    if (errors.length > 0) acc[title] = errors;
    return acc;
  }, {} as GlobalErrorsMapModel);

  return [errors, validProducts];
};

type ValidateParameterData = {
  name: AttributeModel["name"];
  type: AttributeModel["type"];
  value: Nullable<string | number>;
  selectValues: Nullable<string[]>;
};
export const validateParameter = ({
  name,
  type,
  value,
  selectValues,
}: ValidateParameterData): string[] => {
  const errors = [];

  if (type === AttributeType.number && value && isNaN(value as number)) {
    errors.push(`${name} must be a number.`);
  }

  if (type === AttributeType.select) {
    const v = value as string;

    if (!v) {
      errors.push(`${name}. Value is required for type "select".`);
    }

    if (v && !selectValues?.includes(v)) {
      errors.push(`${name}. ${v} is not included in values list of ${name} attribute.`);
    }
  }

  if (type === AttributeType.multiselect) {
    const v = value as Nullable<string>;

    if (v) {
      if (!v.length) {
        errors.push(`${name}. Comma-separated value is required for type "multiselect".`);
      }

      if (v.length > 0) {
        parseValues(v).forEach((val) => {
          if (!selectValues?.includes(val)) {
            errors.push(`${name}. ${val} is not included in values list of ${name} attribute.`);
          }
        });
      }
    }
  }

  return errors;
};
