import { ObjectType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { validateEmail } from "src/utils";
import { RequestResetPasswordForm } from "./request.types";

export const requestPasswordResetValidate = (values: RequestResetPasswordForm) => {
  const { localeStore } = useStores();
  const errors: ObjectType = {};

  if (!values.email) {
    errors.email = localeStore.t('auth.reset.request.fields.email["error-required"]');
  } else if (!validateEmail(values.email)) {
    errors.email = localeStore.t('auth.reset.request.fields.email["error-not-valid"]');
  }

  return errors;
};
