import React from "react";
import { observer } from "mobx-react-lite";
import { Loader } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { StorageUsage } from "./storage-usage";

import styles from "./user-storage-info.module.css";

export const UserStorageInfo: React.FC = observer(() => {
  const { userStore } = useStores();

  if (userStore.loadingMe) {
    return (
      <div className={styles.userStorageInfo}>
        <Loader position="absolute" />
      </div>
    );
  }

  return (
    <div className={styles.userStorageInfo}>
      {/* TODO: for ios review, need to remove after */}
      {!userStore.isAppStoreDemoAccount && <StorageUsage />}
    </div>
  );
});
