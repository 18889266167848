import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Heading,
  Button,
  bytesConverter,
  FileQueueItem,
  FileUpload,
  formatBytes,
  Loader,
  Modal,
  useDidUnmount,
  useDidUpdate,
  useFileQueue,
  ProgressLine,
  Typography,
} from "@gemlightbox/core-kit";

import { ModalExtendedType } from "src/store/modals";
import { postUploadMedia } from "src/api";
import { useStores } from "src/hooks";
import { MediaType } from "src/models";
import { pushUploadMediaDataLayerEvent } from "src/utils";
import { ErrorCodes } from "src/constants";
import WSConnection from "src/common/socket";
import { maxSizeInGb } from "./upload-media-modal.constants";
import { MediaList } from "./media-list";

import styles from "./upload-media-modal.module.css";

export type UploadMediaModalProps = ModalExtendedType<{
  onSuccess?: VoidFunction;
}>;

export const UploadMediaModal: React.FC<UploadMediaModalProps> = observer(
  ({ isOpen, options, setClose, onFinalClosed }) => {
    const { modalsStore, notificationStore, localeStore, userStore } = useStores();
    const { onSuccess } = options;

    const [processedFiles, setProcessedFiles] = useState(0);

    // IMPORTANT
    // Keep in mind that if there is no WS connection OR it was lost during file processing
    // there is no sense to show neither progress line nor "Complete: " information.
    const [mediaWS] = useState(() => new WSConnection("/media", userStore.token));
    const [hasWSConnection, setHasWSConnection] = useState(false);
    const {
      files,
      createInitialFile,
      addFiles,
      updateFiles,
      deleteEntity,
      submitAtOnce,
      isEntityInProcess,
      clearEntity,
      abortAtOnceCall,
    } = useFileQueue("upload-media-modal", postUploadMedia);
    const filesLength = files.length;
    const hasFiles = !!filesLength;

    const {
      progress = 0,
      etaSize = 0,
      loadedSize = 0,
      totalSize = 0,
    } = files[0]?.progressInfo || {};

    const hasFilesUploaded = !etaSize && isEntityInProcess;

    const processedFilesProgress = (processedFiles / filesLength) * 100;

    const mediaWSisConnected = mediaWS.isConnected();

    useDidUpdate(() => {
      mediaWS.on("media/UPLOAD_STATUS", ({ payload }: any) => {
        const fileId = payload?.media_data?.uploadID;
        const validFile = !!files.find(() => fileId);
        if (validFile) setProcessedFiles((prevState) => prevState + 1);
      });
    });

    useDidUpdate(() => {
      if (!mediaWSisConnected) setHasWSConnection(false);
    }, [mediaWSisConnected]);

    useDidUnmount(() => mediaWS.destroy());

    const renderHeadline = () => (
      <Heading tag="h2" color="textSecondary">
        {localeStore.t('media.modals["upload-media-modal"].title')}
      </Heading>
    );

    const handleDrop = (files: File[]) => {
      const acceptedFiles = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "video/quicktime",
        "video/mp4",
      ];
      const entityFiles: FileQueueItem[] = [];

      for (const file of files) {
        if (!acceptedFiles.includes(file.type)) continue;

        const fileExt = file.name.split(".").pop();
        if (!fileExt) continue;

        const type = ["mp4", "mov"].includes(fileExt.toLowerCase())
          ? MediaType.video
          : MediaType.image;

        entityFiles.push(
          createInitialFile(file, {
            data: { type, fileExt },
          }),
        );
      }

      addFiles(entityFiles);
    };

    const handleCloseModal = () => {
      modalsStore.close("UploadMediaModal");
      !isEntityInProcess && deleteEntity();
      setClose();
    };

    const handleUploadConfirm = async () => {
      const formData = new FormData();

      setHasWSConnection(mediaWSisConnected);

      files.forEach(({ originalFile, data }) => {
        const { name } = originalFile;
        const { type, fileExt } = data;
        formData.append("files", originalFile, name);
        if (fileExt === "pdf") {
          formData.append("types", "pdf");
          return;
        }
        formData.append("types", type as string);
      });

      const ids = files.map(({ id }) => id);
      formData.append("uploadIDs", JSON.stringify(ids));
      submitAtOnce(
        files.map(({ id }) => id),
        formData,
      ).then(({ success, error, status }) => {
        if (status === "success") {
          pushUploadMediaDataLayerEvent({
            media_total: success.total_items,
            uploaded_total: files.length,
          });

          deleteEntity();
          handleCloseModal();
          onSuccess?.(success);
        } else {
          if (ErrorCodes.MEDIA_UPLOAD_STORAGE_EXCEEDED === error.originalError?.code) {
            deleteEntity();
            handleCloseModal();
            modalsStore.open("CloudStorageFullModal", { formattedMessage: error.formattedMessage });
          } else {
            notificationStore.open({
              title: error.formattedMessage,
              confirmText: localeStore.t('media.modals["upload-media-modal"]["error-confirm"]'),
              cancelText: "",
              confirmAppearance: "primary",
              onlyConfirm: true,
            });
          }
        }
      });
    };

    const handleMediaTypeChange = (id: string | number, type: string) => {
      updateFiles({
        id,
        file: { data: { type } },
      });
    };

    const handleAbort = () => {
      abortAtOnceCall();
      clearEntity();
    };

    return (
      <Modal
        contentClassName={styles.modalContent}
        scrollWrapperClassName={styles.modalWrapper}
        name="upload-media-modal"
        setClose={handleCloseModal}
        isOpen={isOpen}
        withCross
        onFinalClosed={onFinalClosed}
        data-cy="upload-media-modal"
      >
        <div className={styles.header}>{renderHeadline()}</div>
        {isEntityInProcess && (
          <>
            <div className={styles.uploadingWrapper} data-cy="progress-block">
              <div className={styles.uploadingInfo}>
                {hasWSConnection && hasFilesUploaded && (
                  <>
                    <Typography size="small600" color="textSecondary">
                      {localeStore.t('media.modals["upload-media-modal"].processing')}
                    </Typography>
                    <Typography size="extraSmall" color="primary">
                      {processedFiles}/{filesLength}
                    </Typography>
                  </>
                )}
                {!hasFilesUploaded && (
                  <>
                    <Typography size="small600" color="textSecondary">
                      {localeStore.t('media.modals["upload-media-modal"].uploading')}
                    </Typography>
                    <span className={styles.etaInfo}>
                      {formatBytes(loadedSize)}/{formatBytes(totalSize)}
                    </span>
                  </>
                )}
              </div>
              {(hasFilesUploaded ? hasWSConnection : true) && (
                <ProgressLine progress={hasFilesUploaded ? processedFilesProgress : progress} />
              )}
              {hasFilesUploaded && <Loader type="circle-loader" size={32} />}
              {!hasFilesUploaded && (
                <Button className={styles.abortButton} onClick={handleAbort}>
                  {localeStore.t('media.modals["upload-media-modal"].buttons.abort')}
                </Button>
              )}
            </div>
          </>
        )}
        {hasFiles && !isEntityInProcess && (
          <>
            <div className={styles.body}>
              <MediaList list={files} onMediaTypeChange={handleMediaTypeChange} />
            </div>
            <div className={styles.footer}>
              <Button onClick={handleUploadConfirm} data-cy="save-buton">
                {localeStore.t('media.modals["upload-media-modal"].buttons.upload')}
              </Button>
            </div>
          </>
        )}
        {!hasFiles && !isEntityInProcess && (
          <FileUpload
            title={localeStore.t('media.modals["upload-media-modal"]["file-upload"].title')}
            subtitle={localeStore.t('media.modals["upload-media-modal"]["file-upload"].subtitle')}
            orText={localeStore.t('media.modals["upload-media-modal"]["file-upload"]["or-text"]')}
            browseButtonText={localeStore.t(
              'media.modals["upload-media-modal"]["file-upload"]["browse-button-text"]',
            )}
            onDropAccepted={handleDrop}
            accept="image/jpeg, image/jpg, image/png, video/quicktime, video/mp4"
            maxSize={bytesConverter(maxSizeInGb, "Bytes", "GB")}
            multiple
          />
        )}
      </Modal>
    );
  },
);

export default UploadMediaModal;
