import React, { memo } from "react";
import { SvgIcon } from "@gemlightbox/core-kit";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";
import styles from "./default-embed-button.module.css";

export type DefaultEmbedButtonProps = {
  value: string;
  type: string;
  staticUrl: string;
  onChange: (type: string) => void;
};

export const DefaultEmbedButton: React.FC<DefaultEmbedButtonProps> = memo(
  ({ value, type, staticUrl, onChange }: DefaultEmbedButtonProps) => {
    const handleSelectButton = () => onChange(type);

    return (
      <button
        className={styles.defaultButtonContainer}
        type="button"
        style={{ backgroundImage: `url(${staticUrl})` }}
        onClick={handleSelectButton}
      >
        {value === type && (
          <div className={styles.checkmarkContainer}>
            <SvgIcon icon={CheckmarkSVG} />
          </div>
        )}
      </button>
    );
  },
);

export default DefaultEmbedButton;
