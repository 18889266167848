import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { SubaccountModel } from "src/models";

import styles from "./pending-invitation.module.css";
import TimeDelayTipComponent from "src/components/TimeDelayTipComponent";

interface PendingInvitationProps {
  data: SubaccountModel;
}

export const PendingInvitation: React.FC<PendingInvitationProps> = observer(({ data }) => {
  const { localeStore, notificationStore, subaccountsStore } = useStores();

  const handleCancel = () => {
    notificationStore.open({
      title: localeStore.t('settings.subaccounts["cancel-modal"].title'),
      confirmText: localeStore.t("common.buttons.yes"),
      cancelText: localeStore.t("common.buttons.no"),
      confirmAppearance: "secondary",
      onOk: () =>
        subaccountsStore.deactivateInvitationLink(data.email).then(async (data) => {
          if (data?.errorCode && data.errorCode === "INVITE_LINK_USED") {
            subaccountsStore.showErrorTip = true;
            subaccountsStore.errorTipMessage = localeStore.t(
              'settings.subaccounts["invite-link-used-tip"]',
            );
            setTimeout(async () => {
              subaccountsStore.showErrorTip = false;
              subaccountsStore.errorTipMessage = "";
              await subaccountsStore.loadSubaccounts();
            }, 1500);
          }
        }),
    });
  };

  return (
    <div className={styles.container}>
      <TimeDelayTipComponent
        text={localeStore.t('settings.subaccounts["invite-link-used-tip"]')}
        isOpened={false}
      />

      <Typography className={styles.text} size="extraSmall">
        {localeStore.t(
          'settings.subaccounts["pending-invitations-list"]["pending-invitation"].label',
        )}
        <span className={styles.email}> {data.email}</span>
      </Typography>
      <Button
        className={styles.button}
        appearance="secondaryOutlined"
        size="small"
        onClick={handleCancel}
      >
        {localeStore.t(
          'settings.subaccounts["pending-invitations-list"]["pending-invitation"].buttons.cancel',
        )}
      </Button>
    </div>
  );
});
