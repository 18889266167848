import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useDispatch } from "react-redux";
import { Input } from "@gemlightbox/core-kit";

import { pushDataLayerEvent } from "src/utils";
import { useStores } from "src/hooks";
import Modal from "src/components/modals/Modal";
import BtnWithLoader from "src/components/BtnWithLoader";
import { toggleShopifySetupModal, connectToShopify } from "src/redux/reducers/shopify";
import { getShopifyStoreURL } from "src/common/helpers/integrations.helpers";

import "./index.scss";

export const ShopifySetupModal = observer(({ isOpened }) => {
  const dispatch = useDispatch();

  const { localeStore, userStore } = useStores();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleConfirm = async () => {
    pushDataLayerEvent({ event: "gemhub:shopify:setup" });
    const url = getShopifyStoreURL(name);
    try {
      setLoading(true);
      const data = await dispatch(connectToShopify(url));
      if (window.$platform.isCompact) {
        userStore.parentPostMessageRedirect(`${data.url}&platform=NEW_GEM_HUB`);
      } else {
        window.location.replace(data.url);
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (!window.$platform.isCompact) {
        setLoading(false);
      }
    }
  };

  const handleNameChange = (value) => {
    const regexp = /^(http|https)|(myshopify|.com)$/;

    if (regexp.test(value)) {
      setError(
        localeStore.t('settings.integrations.modals["shopify-setup-modal"]["error-store-name"]'),
      );
    } else {
      setError("");
    }

    setName(value);
  };

  return (
    <Modal
      className="shopify-setup-modal"
      isOpened={isOpened}
      withClose
      handleModalClose={() => dispatch(toggleShopifySetupModal())}
    >
      <div className="modal-content__header">
        <div className="headline title">
          {localeStore.t('settings.integrations.modals["shopify-setup-modal"].title')}
        </div>
        <div className="label subtitle">
          {localeStore.t('settings.integrations.modals["shopify-setup-modal"].subtitle')}
        </div>
      </div>

      <div className="modal-content__body">
        <div className="input-container">
          <div className="label"> https:// </div>
          <Input placeholder="picupmedia" value={name} onChange={handleNameChange} disableError />
          <div className="label"> .myshopify.com </div>
        </div>

        {error && <div className="label error"> {error} </div>}
      </div>

      <div className="modal-content__footer">
        <button className="primary-reverse-btn" onClick={() => dispatch(toggleShopifySetupModal())}>
          {localeStore.t('settings.integrations.modals["shopify-setup-modal"].buttons.cancel')}
        </button>
        <BtnWithLoader
          disabled={!name}
          loading={loading}
          label={localeStore.t(
            'settings.integrations.modals["shopify-setup-modal"].buttons.confirm',
          )}
          className="primary-btn"
          onClick={handleConfirm}
        />
      </div>
    </Modal>
  );
});

export default ShopifySetupModal;
