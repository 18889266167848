import React from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { PrivacyTypeKeys } from "./privacy-button.types";

import { ReactComponent as LockedSVG } from "src/external-ts/assets/images/locked-grey.svg";
import { ReactComponent as UnlockedSVG } from "src/external-ts/assets/images/unlocked-grey.svg";

import styles from "./privacy-button.module.css";

export interface PrivacyButtonProps {
  privacy: PrivacyTypeKeys;
  active: boolean;
  onClick: VoidFunction;
}

export const PrivacyButton: React.FC<PrivacyButtonProps> = observer(
  ({ privacy, active, onClick }) => {
    const { localeStore } = useStores();

    const isPrivate = privacy === "private";

    return (
      <button className={clsx(styles.container, { [styles.active]: active })} onClick={onClick}>
        <SvgIcon icon={isPrivate ? LockedSVG : UnlockedSVG} size={[16, 16]} />
        <span>
          {isPrivate
            ? localeStore.t(
                'products.modals.components["attribute-privacy"]["privacy-button-type"].private',
              )
            : localeStore.t(
                'products.modals.components["attribute-privacy"]["privacy-button-type"].public',
              )}
        </span>
      </button>
    );
  },
);
