import { MediaModel, ProductImageModel } from "src/models";
import { Nullable } from "@gemlightbox/core-kit";

export type GetMediaSrcSizeType = "small" | "medium";

export const getMediaSrc = (
  media: Nullable<MediaModel | ProductImageModel>,
  size: GetMediaSrcSizeType,
) => {
  if (!media) return "";

  let source = media.file;

  // Video src
  if (media.type === "video" || media.type === "video360") {
    // NOTE: we use `source.compressed ?? source.original` due to if we remove bg in video,
    // such videos don't have compressed field, thus they have same compressed video in original field.
    // (BE logic requirement)
    const videoSrc = size === "small" ? source?.small : source?.compressed ?? source?.original;
    return videoSrc || source?.medium || source?.small;
  }

  // Blink src
  if (media.type === "blink" && (media?.arData?.cropFile || (media as any)?.cropFile)) {
    source = (media as any).arData?.cropFile ?? (media as any).cropFile;
  }

  // Other src
  return size === "small" ? source?.small || source?.medium : source?.medium || source?.small;
};
