import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  ObjectType,
  Input,
  Modal,
  Button,
  Heading,
  SvgIcon,
  NotificationText,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { validateEmail } from "src/utils";
import { AccessTypes } from "src/containers/settings/subaccounts/subaccounts.constants";
import { Access } from "./access";

import { ReactComponent as UsersIcon } from "src/common/images/users.svg";
import styles from "./create-invitation-link-modal.module.css";

export type CreateInvitationLinkModalProps = {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed?: VoidFunction;
  options: ObjectType;
};

export const CreateInvitationLinkModal: React.FC<CreateInvitationLinkModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const { notificationStore, subaccountsStore, localeStore } = useStores();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [access, setAccess] = useState(AccessTypes.member);

    const handleEmailChange = async (value: string) => {
      const isValid = validateEmail(value);

      setEmail(value);
      if (error && isValid) setError("");
    };

    const handleEmailBlur = () => {
      const isValid = validateEmail(email);
      if (!isValid && email)
        setError(
          localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"]["email-invalid-error"]',
          ),
        );
    };

    const handleConfirm = async () => {
      setLoading(true);

      const error = await subaccountsStore.createInvitationLink(email, access);

      setClose();

      if (error) {
        notificationStore.open({
          title: localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"]["invite-failed"].title',
          ),
          message: `${localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"]["invite-failed"].message.top',
          )}
          ${email}
          ${localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"]["invite-failed"].message.bottom',
          )}`,
          confirmText: localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"]["invite-failed"]["ok-text"]',
          ),
          cancelText: "",
          icon: "exclamation",
          onlyConfirm: true,
        });
      } else {
        notificationStore.open({
          title: localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"]["invite-success"].title',
          ),
          message: (
            <NotificationText disableBold>
              {localeStore.t(
                'settings.subaccounts["create-invitation-link-modal"]["invite-success"].message.top',
              )}
              <span> {email}</span>.{" "}
              {localeStore.t(
                'settings.subaccounts["create-invitation-link-modal"]["invite-success"].message.bottom',
              )}
            </NotificationText>
          ),
          confirmText: localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"]["invite-success"]["ok-text"]',
          ),
          cancelText: "",
          onlyConfirm: true,
          confirmAppearance: "primary",
          icon: (
            <div className={styles.iconContainer}>
              <SvgIcon icon={UsersIcon} />
            </div>
          ),
        });
      }
      setLoading(false);
    };

    const handleAccessChange = (value: AccessTypes) => setAccess(value);

    const isSubmitButtonDisabled = !access || !email || !!error;

    return (
      <Modal
        scrollWrapperClassName={styles.modalContainer}
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
        withCross
      >
        <Heading className={styles.title} tag="h2" color="textSecondary">
          {localeStore.t('settings.subaccounts["create-invitation-link-modal"].title')}
        </Heading>
        <Input
          inputWrapperClassName={styles.emailInputWrapper}
          appearance="primaryV2"
          label={localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"].fields.email.label',
          )}
          placeholder={localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"].fields.email.placeholder',
          )}
          value={email}
          error={error}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
        />
        <div className={styles.accessContainer}>
          <div className={styles.accessTextContainer}>
            <Heading tag="h4" color="textSecondary">
              {localeStore.t(
                'settings.subaccounts["create-invitation-link-modal"]["access-title"]',
              )}
            </Heading>
            <a
              className={styles.accessLink}
              href="https://support.picupmedia.com/adding-subaccounts"
              target="_blank"
              rel="noreferrer"
            >
              {localeStore.t('settings.subaccounts["create-invitation-link-modal"].link')}
            </a>
          </div>
          <Access access={access} onChange={handleAccessChange} />
        </div>
        <div className={styles.buttonsContainer}>
          <Button appearance="tertiaryOutlined" onClick={setClose}>
            {localeStore.t('settings.subaccounts["create-invitation-link-modal"].buttons.cancel')}
          </Button>
          <Button onClick={handleConfirm} loading={loading} disabled={isSubmitButtonDisabled}>
            {localeStore.t('settings.subaccounts["create-invitation-link-modal"].buttons.send')}
          </Button>
        </div>
      </Modal>
    );
  },
);
