import React, { memo, useState } from "react";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import {
  Heading,
  Typography,
  Modal,
  MessageField,
  SvgIcon,
  Button,
  Form,
  useBoolean,
  useCall,
  Nullable,
  Tooltip,
} from "@gemlightbox/core-kit";

import { putCard } from "src/api";
import { useStores } from "src/hooks";
import { StripeInput } from "./stripe-input";
import { stripePromise, STRIPE_ELEMENTS_OPTIONS, CVCTooltipId } from "./add-card-modal.constants";
import { AddCardModalProps } from "./add-card-modal.types";

import { ReactComponent as CvcTooltipImage } from "src/external-ts/assets/images/subscription/billing/cvc-tooltip.svg";
import { ReactComponent as AmericanExpressSVG } from "src/external-ts/assets/images/subscription/billing/american-express-card.svg";
import { ReactComponent as MastercardSVG } from "src/external-ts/assets/images/subscription/billing/mastercard.svg";
import { ReactComponent as VisaSVG } from "src/external-ts/assets/images/subscription/billing/visa-card.svg";
import { ReactComponent as InfoSVG } from "src/external-ts/assets/images/info-circle-grey.svg";
import styles from "./add-card-modal.module.css";

export const AddCardModal: React.FC<AddCardModalProps> = memo(
  ({ isOpen, setClose, onFinalClosed, options }: AddCardModalProps) => {
    const { solelyActivationPurpose, onAddCardSuccess } = options;

    const { localeStore } = useStores();

    const isTooltipOpenBoolean = useBoolean();
    const useLoadingBoolean = useBoolean(false);
    const [errorText, setErrorText] = useState("");

    const usePutCard = useCall(putCard);

    const loading = useLoadingBoolean.value || usePutCard.submitting;

    const handleSubmit = (stripe: Nullable<Stripe>, elements: Nullable<StripeElements>) => {
      return () => {
        if (!stripe || !elements) return;

        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) return;

        setErrorText("");
        useLoadingBoolean.setTruthy();

        stripe.createToken(cardNumberElement).then(({ token, error }) => {
          if (error || !token) {
            console.error("error");
            console.error(error);
            setErrorText(error?.message || "Something went wrong");
            useLoadingBoolean.setFalsy();
            return;
          }

          const tokenId = token.id;

          usePutCard.onCallError(({ formattedMessage }) => {
            setErrorText(formattedMessage);
            useLoadingBoolean.setFalsy();
          });
          usePutCard.onCallSuccess((card) => {
            setClose();
            onAddCardSuccess?.({ card, stripe });
          });

          usePutCard.submit({
            data: { token: tokenId },
          });
        });
      };
    };

    return (
      <>
        <Modal
          scrollWrapperClassName={styles.addCardModalScrollWrapper}
          contentClassName={styles.addCardModalContent}
          isOpen={isOpen}
          setClose={setClose}
          onFinalClosed={onFinalClosed}
          disableBackdropClose
          disableBorderRadius
          withCross
        >
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <Form
                  className={styles.addCardForm}
                  contentClassName={styles.addCardFormContent}
                  initialValues={{}}
                  onSubmit={handleSubmit(stripe, elements)}
                >
                  <Heading className={styles.modalTitle} tag="h2" color="textSecondary">
                    {localeStore.t('components.business["add-card-modal"].title')}
                  </Heading>

                  <div className={styles.supportedCards}>
                    <Typography size="extraSmall" color="textTertiary">
                      {localeStore.t('components.business["add-card-modal"].subtitle')}
                    </Typography>
                    <SvgIcon className={styles.brandIcon} icon={VisaSVG} />
                    <SvgIcon className={styles.brandIcon} icon={MastercardSVG} />
                    <SvgIcon className={styles.brandIcon} icon={AmericanExpressSVG} />
                  </div>

                  <MessageField
                    className={styles.modalErrorMessage}
                    message={errorText}
                    size="large"
                  />

                  <div className={styles.fieldsContainer}>
                    <StripeInput
                      label={localeStore.t(
                        'components.business["add-card-modal"]["card-number-label"]',
                      )}
                    >
                      <CardNumberElement options={STRIPE_ELEMENTS_OPTIONS.NUMBER} />
                    </StripeInput>
                    <div className={styles.cardDateAndCvvContainer}>
                      <StripeInput className={styles.dateField} label="Exp Date">
                        <CardExpiryElement options={STRIPE_ELEMENTS_OPTIONS.EXPIRY} />
                      </StripeInput>
                      <StripeInput className={styles.cvvField} label="CVC/CVV">
                        <CardCvcElement options={STRIPE_ELEMENTS_OPTIONS.CVC} />
                        <SvgIcon
                          className={styles.cvcTooltip}
                          icon={InfoSVG}
                          id={CVCTooltipId}
                          onMouseEnter={isTooltipOpenBoolean.setTruthy}
                          onMouseLeave={isTooltipOpenBoolean.setFalsy}
                        />
                      </StripeInput>
                    </div>
                  </div>

                  {solelyActivationPurpose && (
                    <Typography
                      className={styles.activationPurpose}
                      size="extraSmall"
                      color="textTertiary"
                    >
                      {localeStore.t('components.business["add-card-modal"]["activation-purpose"]')}
                    </Typography>
                  )}

                  <div className={styles.buttonContainer}>
                    <Button appearance="tertiaryOutlined" onClick={setClose} disabled={loading}>
                      {localeStore.t('components.business["add-card-modal"].buttons.cancel')}
                    </Button>
                    <Button type="submit" disabled={!stripe} loading={loading}>
                      {localeStore.t('components.business["add-card-modal"].buttons.add')}
                    </Button>
                  </div>
                </Form>
              )}
            </ElementsConsumer>
          </Elements>
        </Modal>
        <Tooltip
          position="rightTop"
          offsetX={6}
          target={CVCTooltipId}
          onClose={isTooltipOpenBoolean.setValue}
          isOpen={isTooltipOpenBoolean.value}
          withAngle
        >
          <div className={styles.tooltipContent}>
            {localeStore.t('components.business["add-card-modal"]["cvc-hint"]')}
            <CvcTooltipImage />
          </div>
        </Tooltip>
      </>
    );
  },
);

export default AddCardModal;
