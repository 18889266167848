import React from "react";
import { observer } from "mobx-react-lite";
import { Tbody } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { SubaccountRoles } from "../../subaccounts.constants";
import { getUserStorageUsage } from "../../subaccount.utils";
import { SubaccountTableRow } from "./subaccount-table-row";

import styles from "./subaccounts-table-body.module.css";

export const SubaccountsTableBody: React.FC = observer(() => {
  const { subaccountsStore, userStore } = useStores();
  const { subaccountMaster } = subaccountsStore;
  const { userStorageUsage } = userStore;

  const adminData = userStore.userMe?.user || ({} as any);

  const admin = subaccountMaster ?? adminData;
  const adminStorageUsage = subaccountMaster
    ? getUserStorageUsage(subaccountMaster.sizeOfImages, subaccountMaster.sizeOfVideos)
    : getUserStorageUsage(
        (userStorageUsage?.self.video_size || 0) / Math.pow(1024, 3),
        (userStorageUsage?.self.image_size || 0) / Math.pow(1024, 3),
      );

  return (
    <Tbody className={styles.container}>
      <SubaccountTableRow
        role={SubaccountRoles.master}
        countOfImages={
          subaccountMaster
            ? subaccountMaster.countOfImages || 0
            : userStorageUsage?.self.image_count || 0
        }
        countOfVideos={
          subaccountMaster
            ? subaccountMaster.countOfVideos || 0
            : userStorageUsage?.self.video_count || 0
        }
        storageUsage={adminStorageUsage}
        data={admin}
      />
      {subaccountsStore.confirmedSubaccounts.map((item) => {
        const storageUsage = getUserStorageUsage(item.sizeOfImages, item.sizeOfVideos);

        return (
          <SubaccountTableRow
            key={item.email}
            role={item.role}
            countOfImages={item.countOfImages}
            countOfVideos={item.countOfVideos}
            storageUsage={storageUsage}
            data={item}
          />
        );
      })}
    </Tbody>
  );
});
