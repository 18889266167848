import { loadStripe } from "@stripe/stripe-js/pure";

import { config } from "src/config/environment";

export const CVCTooltipId = "cvc-tooltip-id";

export const stripePromise = loadStripe(config.stripe_key);

const commonStyles = {
  fontFamily: '"Inter", sans-serif',
  fontSize: "14px",
  lineHeight: "18px",
  color: "#363E5C",
  "::placeholder": {
    fontSize: "14px",
    lineHeight: "18px",
    color: "#6D769C",
  },
};

export const STRIPE_ELEMENTS_OPTIONS = {
  NUMBER: {
    style: {
      base: commonStyles,
    },
  },
  EXPIRY: {
    style: {
      base: commonStyles,
    },
  },
  CVC: {
    style: {
      base: commonStyles,
    },
  },
} as const;
