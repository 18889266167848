import React, { useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { PageContainer, PageHeader } from "src/external-ts/components";
import { AR_MEDIA_PAGE, CREATE_PAGE } from "src/constants";
import { useStores } from "src/hooks";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { ExtendedMediaModel } from "src/store";
import { ProductType, productDefaultTypesNames, productTypesSingularNames } from "src/models";
import { MediaList } from "../media-list";
import { augmentedRealityTypes } from "../augmented-reality-block/augmented-reality-block.constants";
import { augmentedRealityMediaLimit } from "./augmented-reality.constants";

export const AugmentedReality: React.FC = observer(() => {
  const { type } = useParams<{ type: ProductType }>();
  const navigate = useNavigate();

  const { canBlinks } = useLimits();

  const { notificationStore, categoriesStore, localeStore } = useStores();

  const handleGoBack = () => navigate(-1);

  const handleAugmentedReality = async (medias: ExtendedMediaModel[]) => {
    const media = medias[0];
    if (!media) return;

    notificationStore.openLoader({
      loaderType: "diamond-loader",
      appearance: "secondary",
      title: localeStore.t('create["augmented-reality"].notification.loader.title'),
      subtitle: localeStore.t('create["augmented-reality"].notification.loader.subtitle'),
    });

    try {
      const category = categoriesStore.defaultCategories.find(({ name }) => name === type);
      if (!category) return;

      navigate(AR_MEDIA_PAGE.path, {
        state: {
          media: toJS(media),
          type: "media",
          productType: toJS(category),
        },
      });
    } catch (error) {
      notificationStore.closeLoader();
      notificationStore.open({
        title: localeStore.t('create["augmented-reality"].notification.error.title'),
        message: localeStore.t('create["augmented-reality"].notification.error.subtitle'),
        confirmText: localeStore.t('create["augmented-reality"].notification.error["ok-text"]'),
        cancelText: "",
        icon: "exclamation",
        confirmAppearance: "primary",
        onlyConfirm: true,
        onOk: () => window.location.reload(),
      });
    }
  };

  const hasValidType = useMemo(() => {
    return !!augmentedRealityTypes.find((item) => item.type === type);
  }, []);

  if (!canBlinks || !type || !hasValidType) return <Navigate to={CREATE_PAGE.path} />;

  const titleType = localeStore.t(
    `create["augmented-reality-item"]["ar-types"].${
      productDefaultTypesNames[type.toLocaleLowerCase()]
    }` as LocaleCodeTypes,
    type,
  );
  const subTitleType = localeStore.t(
    `create["augmented-reality-item"]["product-type"].${productTypesSingularNames[
      type
    ].toLowerCase()}` as LocaleCodeTypes,
  );

  return (
    <PageContainer>
      <PageHeader
        title={`${localeStore.t('create["augmented-reality"].title')} ${titleType}`}
        onBack={handleGoBack}
      />
      <MediaList
        subTitle={`${localeStore.t('create["augmented-reality"].subtitle.text')} ${
          productTypesSingularNames[type] === "Earring"
            ? localeStore.t('create["augmented-reality"].subtitle["article-vowel"]')
            : localeStore.t('create["augmented-reality"].subtitle["article-consonant"]')
        } ${subTitleType}`}
        actionBtnText={localeStore.t('create["augmented-reality"]["action-btn-text"]')}
        type="image"
        limit={augmentedRealityMediaLimit}
        onActionClick={handleAugmentedReality}
      />
    </PageContainer>
  );
});

export default AugmentedReality;
