import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import {
  Heading,
  Typography,
  Button,
  FormButtons,
  FormField,
  useFormContext,
  useDidMount,
  useQuery,
  queryStringify,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { pushDataLayerEvent } from "src/utils";
import { LOGIN_PAGE } from "src/constants";
import { SignupFormType } from "../signup.types";

import styles from "../signup.module.css";

export const SecondStep: React.FC = observer(() => {
  const navigate = useNavigate();
  const { query } = useQuery();

  const { values } = useFormikContext<SignupFormType>();

  const { goBack } = useFormContext();

  const { modalsStore, localeStore } = useStores();

  const handleDisplayTerms = () => modalsStore.open("TermsOfServiceModal", {});

  const handleSignIn = () => navigate(LOGIN_PAGE.path + queryStringify(query));

  useDidMount(() => {
    pushDataLayerEvent({
      event: "gemhub:registration:started",
      event_params: {
        email: values.email,
      },
      email: values.email,
    });
  });

  return (
    <>
      <Heading className={styles.header} tag="h2" color="textSecondary">
        {localeStore.t('auth["sign-up"]["second-step"].title')}
      </Heading>
      <Typography
        className={styles.description}
        size="small"
        color="textTertiary"
        data-cy="second-step-enter-data-hint"
      >
        {localeStore.t('auth["sign-up"]["second-step"].subtitle')}
      </Typography>

      <div className={styles.inputsRow}>
        <FormField
          type="text"
          name="firstName"
          label={localeStore.t('auth["sign-up"]["second-step"].fields["first-name"].label')}
          placeholder={localeStore.t(
            'auth["sign-up"]["second-step"].fields["first-name"].placeholder',
          )}
          appearance="primaryV2"
          data-cy="second-step-first-name"
        />
        <FormField
          type="text"
          name="lastName"
          label={localeStore.t('auth["sign-up"]["second-step"].fields["last-name"].label')}
          labelMessage={localeStore.t(
            'auth["sign-up"]["second-step"].fields["last-name"]["label-message"]',
          )}
          placeholder={localeStore.t(
            'auth["sign-up"]["second-step"].fields["last-name"].placeholder',
          )}
          appearance="primaryV2"
          data-cy="second-step-last-name"
        />
      </div>

      <FormField
        phoneFieldClassName={styles.phone}
        type="phone"
        name="phone"
        label={localeStore.t('auth["sign-up"]["second-step"].fields.phone.label')}
        placeholder={localeStore.t('auth["sign-up"]["second-step"].fields.phone.placeholder')}
      />

      <div className={styles.inputsRow}>
        <FormField
          type="text"
          name="company"
          label={localeStore.t('auth["sign-up"]["second-step"].fields.company.label')}
          placeholder={localeStore.t('auth["sign-up"]["second-step"].fields.company.placeholder')}
          appearance="primaryV2"
          data-cy="second-step-company-name"
        />
        <FormField
          type="text"
          name="website"
          label={localeStore.t('auth["sign-up"]["second-step"].fields.website.label')}
          placeholder="https://site.com"
          appearance="primaryV2"
          data-cy="second-step-company-website"
        />
      </div>

      <div className={styles.acceptTermsContainer}>
        <FormField
          className={styles.termsCheckbox}
          type="checkbox"
          name="acceptTerms"
          label={localeStore.t('auth["sign-up"]["second-step"].fields.checkbox.label')}
          disableError
          data-cy="second-step-checkbox"
        />
        <Button
          className={styles.termsButton}
          appearance="unset"
          size="unset"
          onClick={handleDisplayTerms}
          data-cy="second-step-terms-of-service"
        >
          {localeStore.t('auth["sign-up"]["second-step"].buttons["terms-of-service"]')}
        </Button>
      </div>

      <div className={styles.bottomContainer}>
        <FormButtons align="stretchedRow">
          <Button appearance="tertiaryOutlined" onClick={goBack} data-cy="second-step-back-button">
            {localeStore.t('auth["sign-up"]["second-step"].buttons.back')}
          </Button>
          <Button type="submit" disabled={!values.acceptTerms} data-cy="second-step-sign-up-button">
            {localeStore.t('auth["sign-up"]["second-step"].buttons["sign-up"]')}
          </Button>
        </FormButtons>

        <div className={styles.textContainer}>
          <Typography size="extraSmall" color="textTertiary" data-cy="second-step-account-hint">
            {localeStore.t('auth["sign-up"]["sign-in-text"]')}
          </Typography>
          <Button appearance="primaryGhost" onClick={handleSignIn} data-cy="second-step-sign-in">
            {localeStore.t('auth["sign-up"]["sign-in-button"]')}
          </Button>
        </div>
      </div>
    </>
  );
});
