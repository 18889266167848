import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { observer } from "mobx-react-lite";
import { Button, Input, useQuery, useDidUpdate } from "@gemlightbox/core-kit";

import AuthAside from "src/components/auth-aside";
import IconCircle from "src/components/IconCircle";
import { useStores } from "src/hooks";
import { connectToShopify } from "src/redux/reducers/shopify";

import shopifyLogoImg from "src/containers/settings/integrations/images/shopify-logo.svg";
import styles from "./shopify-connect.module.css";

export const ShopifyConnect = observer(() => {
  const dispatch = useDispatch();
  const {
    query: { shop },
  } = useQuery();

  const { notificationStore, localeStore } = useStores();

  const [name, setName] = useState(shop);
  const [error, setError] = useState(false);

  useDidUpdate(
    async () => {
      if (shop) await handleConnect(false);
    },
    [shop],
    true,
  );

  const handleConnect = async (validate = true) => {
    if (validate) {
      const isValid = validateStoreURL();
      if (!isValid) return;
    }

    notificationStore.openLoader({
      loaderType: "circle-loader",
      title: localeStore.t('["shopify-connect"]["loader-text"]'),
      appearance: "secondary",
    });
    try {
      const data = await dispatch(connectToShopify(name));
      window.location.replace(data.url);
    } catch (e) {
      console.error(e);
      notificationStore.closeLoader();
      setError(localeStore.t('["shopify-connect"]["error-default"]'));
    }
  };

  const handleNameChange = (value) => {
    if (error) setError(false);
    setName(value);
  };

  const validateStoreURL = () => {
    const regexp = /(^(https|http)\:\/\/)?([a-zA-Z0-9][a-zA-Z0-9\-]*)\.myshopify\.com\/?$/;

    if (!regexp.test(name)) {
      setError(localeStore.t('["shopify-connect"]["error-invalid-store"]'));
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className={styles.shopifyConnect}>
      <AuthAside />

      <div className={styles.shopifyConnectSide}>
        <div className={styles.shopifyConnectContent}>
          <IconCircle img={shopifyLogoImg} />

          <div className="label">
            {localeStore.t('["shopify-connect"].title.first')}{" "}
            <span className="bold"> {localeStore.t('["shopify-connect"].title.last')} </span>
          </div>

          <Input
            className={styles.shopifyInput}
            value={name}
            error={error}
            label={localeStore.t('["shopify-connect"].fields["shopify-input"].label')}
            placeholder="https://my-store.myshopify.com"
            onChange={handleNameChange}
          />

          <Button className={styles.shopifyButton} onClick={handleConnect} disabled={!name}>
            {localeStore.t('["shopify-connect"].buttons.connect')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default ShopifyConnect;
