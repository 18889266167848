import React from "react";
import { observer } from "mobx-react-lite";
import { Modal } from "@gemlightbox/core-kit";

import { getSharedResourceLink } from "src/external-ts/components/business/share-modal/shared-link-content/share-link-modal.utils";
import { MediaCollectionModel } from "src/models";
import { ModalExtendedType } from "src/store/modals/modals.store.types";
import { ExtendedCatalogFullModel, ExtendedMediaModel, ExtendedProductModel } from "src/store";
import { LinkContent } from "./link-content";

import styles from "./download-modal.module.css";
import { DownloadModalTypes } from "./download-modal.types";

export type DownloadModalProps = ModalExtendedType<{
  type: DownloadModalTypes;
  data: ExtendedMediaModel | ExtendedProductModel | MediaCollectionModel | ExtendedCatalogFullModel;
  onClose?: VoidFunction;
}>;

export const DownloadModal: React.FC<DownloadModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { type, data, onClose } = options;

    const handleClose = () => {
      setClose();
      onClose?.();
    };

    return (
      <Modal
        contentClassName={styles.modalContent}
        name="download-modal"
        data-cy="download-modal"
        isOpen={isOpen}
        setClose={handleClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
        withCross
      >
        <div className={styles.content}>
          <LinkContent link={getSharedResourceLink(type, data)} />
        </div>
      </Modal>
    );
  },
);
