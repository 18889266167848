import React from "react";
import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";

import { ExtendedProductModel } from "src/store/products";
import { useStores } from "src/hooks";
import { ProductCard } from "src/external-ts/components";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./products-list.module.css";

export type ProductsListProps = {
  className?: string;
  productClassName?: string;
  disableSelect?: boolean;
};

export const ProductsList: React.FC<ProductsListProps> = observer(
  ({ className = "", productClassName = "", disableSelect = false }) => {
    const { productsStore } = useStores();

    const handleSelect = (product: ExtendedProductModel) => {
      productsStore.toggleProductsList(product, "selected");
    };

    return (
      <div
        className={clsx(styles.productListContainer, globalStyles.addScrollStyles, className)}
        data-cy="products-list"
      >
        <div className={styles.productListWrapper}>
          {productsStore.productsList.map((product) => (
            <ProductCard
              key={product._id}
              data={product}
              className={clsx(styles.product, productClassName)}
              titleContainerClassName={styles.productTitleContainer}
              onSelect={disableSelect ? undefined : handleSelect}
              showImageCounter
              selectOnContainerClick
            />
          ))}
        </div>
      </div>
    );
  },
);
