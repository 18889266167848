import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, Heading, Typography, SvgIcon, Tooltip, useBoolean } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as UsersIcon } from "src/common/images/users.svg";
import { ReactComponent as PlusIcon } from "src/external-ts/assets/images/plus-circle-grey.svg";
import styles from "./subaccounts-empty.module.css";

type SubaccountsEmptyProps = {
  blocked: boolean;
};

export const SubaccountsEmpty: React.FC<SubaccountsEmptyProps> = observer(({ blocked }) => {
  const { modalsStore, localeStore, userStore } = useStores();

  const addSubaccountButtonRef = useRef<HTMLButtonElement>(null);

  const toolTipBoolean = useBoolean();

  const handleAddSubaccount = () => modalsStore.open("CreateInvitationLinkModal", {});
  const handleAddSubaccountPointerEnter = () => {
    if (!blocked) return;
    toolTipBoolean.setTruthy();
  };
  const handleAddSubaccountPointerLeave = () => {
    toolTipBoolean.setFalsy();
  };

  return (
    <div className={styles.container}>
      <SvgIcon icon={UsersIcon} size={80} />

      {userStore.isSubaccount && (
        <Heading tag="h2" color="textSecondary">
          {localeStore.t('settings.subaccounts["subaccounts-empty"]["subaccount-title"]')}
        </Heading>
      )}

      {!userStore.isSubaccount && (
        <>
          <Heading tag="h2" color="textSecondary">
            {localeStore.t('settings.subaccounts["subaccounts-empty"]["admin-title"]')}
          </Heading>

          <Button
            className={styles.addButton}
            forwardRef={addSubaccountButtonRef}
            onClick={handleAddSubaccount}
            onPointerEnter={handleAddSubaccountPointerEnter}
            onPointerLeave={handleAddSubaccountPointerLeave}
            disabled={blocked}
            data-cy="add-subaccounts-button"
          >
            <SvgIcon icon={PlusIcon} />
            {localeStore.t('settings.subaccounts["subaccounts-empty"].buttons.add')}
          </Button>
        </>
      )}

      <Tooltip
        appearance="secondary"
        position="right"
        offsetX={14}
        target={addSubaccountButtonRef}
        isOpen={toolTipBoolean.value}
        onClose={toolTipBoolean.setValue}
        withAngle
        data-cy="subaccount-tooltip"
      >
        <Typography size="small">
          {localeStore.t('settings.subaccounts["subaccounts-empty"]["tooltip-text"]')}
        </Typography>
      </Tooltip>
    </div>
  );
});
