import React from "react";
import { observer } from "mobx-react-lite";
import { useWindowSize, WIDTH_BREAKPOINTS, Heading, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import emptyProductsImg from "src/external-ts/assets/images/catalog/empty-products.png";

import styles from "./products-empty.module.css";

interface ProductsEmptyProps {
  showImage?: boolean;
}

export const ProductsEmpty: React.FC<ProductsEmptyProps> = observer(({ showImage = true }) => {
  const { windowWidth } = useWindowSize();

  const { localeStore } = useStores();

  const renderEmptyScreen = () => (
    <div className={styles.container}>
      {showImage && <img src={emptyProductsImg} alt="" />}

      <div className={styles.textContainer}>
        <Heading tag="h2" color="textSecondary">
          {localeStore.t('components.layout["products-empty"].title')}
        </Heading>
        <Typography className={styles.label} size="small" color="textTertiary">
          {localeStore.t('components.layout["products-empty"].subtitle')}
        </Typography>
      </div>
    </div>
  );

  const renderEmptyLabel = () => (
    <Heading tag="h2" color="textSecondary">
      {localeStore.t('components.layout["products-empty"]["title-mobile"]')}
    </Heading>
  );

  return (
    <div className="empty-items empty-catalogs">
      {windowWidth >= WIDTH_BREAKPOINTS.tablet ? renderEmptyScreen() : renderEmptyLabel()}
    </div>
  );
});
