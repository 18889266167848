import React, { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { clsx, useDidUpdate } from "@gemlightbox/core-kit";
import { cameraStore } from "../camera.store";
import CircularProgress from "@mui/joy/CircularProgress";

import styles from "./stopwatch.module.css";

interface StopwatchProps {
  className?: string;
  videoLimit: number;
  stoped: boolean;
  clickFn: () => void;
}

export const Stopwatch: React.FC<StopwatchProps> = observer(
  ({ className, videoLimit, stoped, clickFn }: StopwatchProps) => {
    const timer = useRef<number | undefined>();
    const videoLimitToSecond = Math.ceil(videoLimit / 1000);
    const [countdown, setCountdown] = useState(videoLimitToSecond);
    const countdownStr = countdown > 0 && !stoped ? `${String(countdown)}s` : null;
    const countdownRef = useRef<number>(countdown);
    countdownRef.current = countdown;
    const [videoRecordProgress, setVideoRecordProgress] = useState(100);

    const { isRecording } = cameraStore;

    const getTime = () => {
      timer.current = window.setInterval(() => {
        const value = (countdownRef.current -= 1);
        const progressValue = Math.floor((value / videoLimitToSecond) * 100);
        setVideoRecordProgress(progressValue);
        setCountdown(value);

        if (value <= 0) {
          clearInterval(timer?.current);
        }
      }, 1000);
    };

    useDidUpdate(
      () => {
        if (isRecording) {
          getTime();
        } else {
          clearInterval(timer?.current);
          setVideoRecordProgress(100);
          setCountdown(videoLimitToSecond);
        }
      },
      [isRecording],
      true,
    );

    return (
      <CircularProgress
        variant="plain"
        determinate
        value={videoRecordProgress}
        onClick={clickFn}
        sx={{
          color: "#fff",
          width: 80,
          height: 80,
          position: "relative",
          zIndex: 6,
          "--CircularProgress-size": "80px",
          "--joy-palette-primary-plainColor": "var(--warning-red)",
        }}
      >
        <div className={styles.videoCaptureBtnBody}>
          {isRecording && (
            <div className={clsx(styles.stopwatchContainer, className)}>{countdownStr}</div>
          )}
        </div>
      </CircularProgress>
    );
  },
);
