import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  ActionsBtn,
  Tabs,
  Button,
  SvgIcon,
  ActionOption,
  DateRangePicker,
  useBoolean,
  useMediaBreakpoints,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MediaFilterTypeKeys } from "src/containers/media/media.types";
import { CREATE_PAGE } from "src/constants";
import { removeBackgroundMap } from "src/external-ts/pages/create/remove-background-block/remove-background-block.constants";
import { Filters } from "./filters";
import { mediaTabs } from "./media-control-panel.constants";

import { ReactComponent as UploadSVG } from "../../../external-ts/assets/images/upload-cloud-grey.svg";
import styles from "./media-control-panel.module.css";

export const MediaControlPanel: React.FC = observer(() => {
  const navigate = useNavigate();

  const { mediaStore, modalsStore, localeStore } = useStores();

  const { isTabletMedia } = useMediaBreakpoints(undefined, {
    mobileBreakpoint: 767,
  });

  const resetCalendarBoolean = useBoolean(false);

  useEffect(() => {
    if (!mediaStore.isSortedByDate) {
      resetCalendarBoolean.setTruthy();
    }
  }, [mediaStore.isSortedByDate]);

  const formatDate = (date: Date, isStart = false) => {
    if (isStart) {
      date.setHours(0, 0, 0, 0);
    } else {
      date.setHours(23, 59, 0, 0);
    }
    return date.toISOString();
  };
  const onSubmit = (start: any, end: any) => {
    mediaStore.loadMediaList({
      ...mediaStore.filters,
      createdFrom: formatDate(start, true),
      createdTo: formatDate(end),
    });
  };
  const getMediaTabsLocalized = () => {
    return mediaTabs.map(({ value, label }) => {
      switch (value) {
        case "all":
          return { label: localeStore.t('media["media-control-panel"].tabs.all'), value };
        case "image":
          return { label: localeStore.t('media["media-control-panel"].tabs.image'), value };
        case "video":
          return { label: localeStore.t('media["media-control-panel"].tabs.video'), value };
        case "video360":
          return { label: localeStore.t('media["media-control-panel"].tabs.video360'), value };
        case "blink":
          return { label: localeStore.t('media["media-control-panel"].tabs.blink'), value };
        default:
          return { value, label };
      }
    });
  };

  const handleMediaUpload = () => {
    return modalsStore.open("UploadMediaModal", { onSuccess: () => mediaStore.loadMediaList() });
  };

  const handleRemoveBackgroundWhite = () => {
    navigate(CREATE_PAGE.path + "/remove-background/" + removeBackgroundMap.white);
  };

  const handleMediaTypeChange = (type: string) => {
    mediaStore.loadMediaList({ ...mediaStore.filters, type: type as MediaFilterTypeKeys, page: 1 });
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Filters />

        <div className={styles.buttons}>
          <DateRangePicker
            lang={localStorage.getItem("localeCode") || "en"}
            datePickerWrapperClassName={styles.datePickerWrapper}
            onSubmit={onSubmit}
            resetBoolean={resetCalendarBoolean}
            shouldRenderSingleCalendar={isTabletMedia}
          />
          <Button className={styles.removeBackground} onClick={handleMediaUpload}>
            <SvgIcon icon={UploadSVG} />
            {localeStore.t('media["media-control-panel"].actions["media-action-upload"]')}
          </Button>
        </div>
      </div>

      {!mediaStore.isEmpty && (
        <Tabs
          tabs={getMediaTabsLocalized()}
          currentTab={mediaStore.filters.type || ""}
          onChange={handleMediaTypeChange}
          name="media-type-filter"
        />
      )}
    </div>
  );
});
