import { TuplifyUnion } from "@gemlightbox/core-kit";

import { SupportedLocalesModel } from "src/models";

// NOTE: If you change these constants, make sure you check lib/update-locale-script.js
export const supportedLanguages: TuplifyUnion<SupportedLocalesModel> = [
  "en",
  "es",
  "zh",
  "zh_tw",
  "hi",
  "ar",
  "fr",
  "ja",
  "ko",
  "de",
  "it",
  "tr",
  "th",
  "he",
];
