import React, { useRef } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { observer } from "mobx-react-lite";
import { SvgIcon, Tooltip, useBoolean } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./with-drag.module.css";
import { ReactComponent as DragSVG } from "src/external-ts/assets/images/drag-grey.svg";

export interface WithDragProps {
  provided: DraggableProvided;
}

export const WithDrag: React.FC<WithDragProps> = observer(({ children, provided }) => {
  const { localeStore } = useStores();

  const divRef = useRef<HTMLDivElement>(null);

  const isOpenBoolean = useBoolean();

  return (
    <div className={styles.container} ref={provided.innerRef} {...provided.draggableProps}>
      <div className={styles.drag} {...provided.dragHandleProps}>
        <div className={styles.svgContainer} ref={divRef}>
          <SvgIcon
            icon={DragSVG}
            onMouseEnter={isOpenBoolean.setTruthy}
            onMouseLeave={isOpenBoolean.setFalsy}
          />
        </div>
        <Tooltip
          className={styles.tooltipContent}
          appearance="secondary"
          position="bottom"
          target={divRef}
          onClose={isOpenBoolean.setValue}
          isOpen={isOpenBoolean.value}
          withAngle
        >
          {localeStore.t(
            'products.modals["manage-attributes-sidebar"]["attributes-list"]["with-drag"].tooltip',
          )}
        </Tooltip>
      </div>

      {children}
    </div>
  );
});
