import React from "react";
import { observer } from "mobx-react-lite";
import { Table, Thead, Th, Td, Tbody, Tr, StatusTag, Button } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./result-content.module.css";

export const ResultContent: React.FC = observer(() => {
  const { notificationStore, localeStore, userStore } = useStores();
  const { dns_host } = userStore.userMe || {};

  const handleRemoveConnection = () => {
    notificationStore.open({
      title: localeStore.t('settings["dns-settings"]["result-content"]["remove-connection"].title'),
      message: localeStore.t(
        'settings["dns-settings"]["result-content"]["remove-connection"].message',
      ),
      confirmText: localeStore.t(
        'settings["dns-settings"]["result-content"]["remove-connection"]["ok-text"]',
      ),
      cancelText: localeStore.t("common.buttons.cancel"),
      onOk: async () => {
        const formData = new FormData();
        formData.append("dns_host", "null");
        await userStore.updateUser(formData);
      },
    });
  };

  return (
    <div className={styles.container}>
      <Table>
        <Thead>
          <Tr>
            <Th className={styles.type}>
              {localeStore.t('settings["dns-settings"]["result-content"].type')}
            </Th>
            <Th className={styles.domain}>
              {localeStore.t('settings["dns-settings"]["result-content"].domain')}
            </Th>
            <Th className={styles.status}>
              {localeStore.t('settings["dns-settings"]["result-content"].status')}
            </Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{localeStore.t('settings["dns-settings"]["result-content"].name')}</Td>
            <Td>{dns_host}</Td>
            <Td>
              <div className={styles.statusContent}>
                <StatusTag
                  className={styles.statusTag}
                  label={localeStore.t(
                    'settings["dns-settings"]["result-content"]["status-label"]',
                  )}
                />
              </div>
            </Td>
            <Td>
              <div className={styles.actionsContent}>
                <Button
                  className={styles.button}
                  appearance="primaryOutlined"
                  onClick={handleRemoveConnection}
                >
                  {localeStore.t('settings["dns-settings"]["result-content"].buttons.remove')}
                </Button>
              </div>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
});
