import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Heading,
  Typography,
  Modal,
  Input,
  SelectGroup,
  Button,
  SelectGroupOption,
  clsx,
} from "@gemlightbox/core-kit";

import { AttributeModel } from "src/models";
import { ExtendedProductModel, ModalExtendedType } from "src/store";
import { useStores } from "src/hooks";
import { valuesToOptions } from "src/utils";
import { numberNormalizer } from "src/common/helpers/common.helpers";

import styles from "./bulk-attributes-edit-modal.module.css";

export type BulkAttributesEditModalProps = ModalExtendedType<{
  attributeId: number;
}>;

export const BulkAttributesEditModal: React.FC<BulkAttributesEditModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { attributesStore, productsStore, localeStore } = useStores();

    const [value, setValue] = useState<string | string[]>("");
    const [search, setSearch] = useState("");
    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const attribute = attributesStore.attributes.find(
      (attribute) => attribute.id === options.attributeId,
    );

    const attributeLabel = attribute?.displayName || attribute?.name || "";

    const filteredOptions = valuesToOptions(attribute!.values).filter(({ value }) => {
      const searchedVal = value.toString();
      return searchedVal.toLowerCase().includes(search.toLowerCase());
    });

    const handleCreateNewOption = async () => {
      const trimmedNewAttributeValue = search.trim();

      const data = {
        ...attribute,
        values: attribute?.values?.concat(trimmedNewAttributeValue) || [],
      };

      const requestData = attributesStore.getAttributeRequestPayload(data as AttributeModel);

      setSearch("");

      await attributesStore
        .updateAttribute(data.id!, requestData)
        .then(() =>
          attribute?.type === "multiselect"
            ? setValue([...value, trimmedNewAttributeValue])
            : setValue(trimmedNewAttributeValue),
        );
    };

    const handleEnter = () => {
      if (search && filteredOptions.length === 0) handleCreateNewOption();
    };

    const handleSubmit = () => {
      if (attribute!.name === "price" && +value === 0) {
        setError(true);
        return;
      }

      setLoading(true);

      let newValue = value ?? "";

      if (Array.isArray(value)) newValue = value.join(", ");

      const productsToUpdate = productsStore.selectedProductsList.map((product) => ({
        ...product,
        parameters: [
          ...product.parameters.filter((attribute) => attribute.id !== options.attributeId),
          {
            ...attributesStore.attributes.find((attribute) => attribute.id === options.attributeId),
            value: newValue.toString(),
          },
        ],
      }));

      productsStore
        .updateProducts(productsToUpdate as ExtendedProductModel[], attributesStore.attributes)
        .then(() => {
          setLoading(false);
          setClose();
        });
    };

    const handleSelectChange = (value: string | string[]) => {
      setSearch("");
      value = value ?? "";
      setValue(value);
    };

    const handleInputChange = (value: string) => {
      setValue(value);
      setError(attribute!.name === "price" && +value === 0);
    };

    const getSelectDisplayValue = (option: string) => option;

    return (
      <Modal
        name="bulk-attributes-edit-modal"
        scrollWrapperClassName={styles.modal}
        contentClassName={styles.modalContent}
        setClose={setClose}
        isOpen={isOpen}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
        disablePadding
        withCross
      >
        {attribute && (
          <>
            <Heading tag="h2" className={styles.formHeading} color="textQuaternary">
              {localeStore.t('products.modals["bulk-attributes-edit"].title')} {attributeLabel}
            </Heading>
            <Typography className={styles.formText} size="medium" color="textTertiary">
              {localeStore.t('products.modals["bulk-attributes-edit"]["text-first"]')}{" "}
              {attributeLabel.toLowerCase()}{" "}
              {localeStore.t('products.modals["bulk-attributes-edit"]["text-second"]')}
            </Typography>
            <Typography className={styles.formFieldLabel} size="extraSmall">
              {attributeLabel}
            </Typography>
            {(attribute.type === "text" || attribute.type === "number") && (
              <Input
                value={value as string}
                onChange={handleInputChange}
                className={styles.formField}
                appearance="primaryV2"
                name="attribute-value"
                type="text"
                placeholder={`${localeStore.t(
                  'products.modals["bulk-attributes-edit"].placeholder',
                )} ${attributeLabel.toLowerCase()}`}
                normalize={attribute.type === "number" ? numberNormalizer : undefined}
                error={
                  isError &&
                  `${localeStore.t('products.modals["bulk-attributes-edit"]["price-error"]')}`
                }
              />
            )}
            {(attribute.type === "multiselect" || attribute.type === "select") && (
              <SelectGroup
                className={clsx(styles.formField, styles.formFieldSelect)}
                dropdownClassName={styles.selectDropdown}
                appearance="primaryV2"
                name="attribute-value"
                placeholder={`${localeStore.t(
                  'products.modals["bulk-attributes-edit"]["select-placeholder"]',
                )} ${attributeLabel.toLowerCase()}`}
                searchValue={search || (attribute.type === "multiselect" ? "" : value.toString())}
                value={value}
                getDisplayValue={getSelectDisplayValue}
                onChange={handleSelectChange}
                onSearch={setSearch}
                onEnter={handleEnter}
                isMulti={attribute.type === "multiselect"}
              >
                {search && filteredOptions.length === 0 && (
                  <Button
                    className={styles.createNewBtn}
                    appearance="secondaryGhost"
                    onClick={handleCreateNewOption}
                  >
                    {localeStore.t('products.modals["bulk-attributes-edit"]["select-create"]')}
                  </Button>
                )}
                {filteredOptions.map(({ value, label }) => (
                  <SelectGroupOption value={value} key={value}>
                    {label}
                  </SelectGroupOption>
                ))}
              </SelectGroup>
            )}
            <div className={styles.formButtons}>
              <Button appearance="tertiaryOutlined" onClick={setClose}>
                {localeStore.t('products.modals["bulk-attributes-edit"]["cancel-button"]')}
              </Button>
              <Button onClick={handleSubmit} loading={isLoading} disabled={isError}>
                {localeStore.t('products.modals["bulk-attributes-edit"]["save-button"]')}
              </Button>
            </div>
          </>
        )}
      </Modal>
    );
  },
);
