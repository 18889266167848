import { ReactComponent as VideoSVG } from "src/external-ts/assets/images/welcome/video.svg";
import { ReactComponent as LinkSVG } from "src/external-ts/assets/images/welcome/link.svg";
import { ReactComponent as CamSVG } from "src/external-ts/assets/images/welcome/cam.svg";

export const INTRO_LIST = [
  {
    icon: VideoSVG,
    desc: "app.download.intro.capture",
  },
  {
    icon: LinkSVG,
    desc: "app.download.intro.connect",
  },
  {
    icon: CamSVG,
    desc: "app.download.intro.control",
  },
];
