import { SettingsTabsType } from "./settings.types";

export enum SettingTabNames {
  account = "ACCOUNT",
  billing = "BILLING",
  subaccounts = "SUBACCOUNTS",
  gallery = "GALLERY",
  subscriptions = "SUBSCRIPTIONS",
  arsettings = "AR SETTINGS",
  integrations = "INTEGRATIONS",
  dns = "DNS",
}

export const SETTINGS_TABS: SettingsTabsType[] = [
  {
    name: SettingTabNames.account,
    item: {
      name: "Account Info",
      tab: "account-info",
      path: "/settings/account-info",
      relativePath: "/account-info",
    },
  },
  {
    name: SettingTabNames.billing,
    item: {
      name: "Billing",
      tab: "billing",
      path: "/settings/billing",
      relativePath: "/billing",
    },
  },
  {
    name: SettingTabNames.subaccounts,
    item: {
      name: "Subaccounts",
      tab: "subaccounts",
      path: "/settings/subaccounts",
      relativePath: "/subaccounts",
    },
  },
  {
    name: SettingTabNames.gallery,
    item: {
      name: "Share Settings",
      tab: "gallery-info",
      path: "/settings/gallery-info",
      relativePath: "/gallery-info",
    },
  },
  // {
  //   name: SettingTabNames.arsettings,
  //   item: {
  //     name: "AR Settings",
  //     tab: "ar-settings",
  //     path: "/settings/arsettings",
  //     relativePath: "/arsettings",
  //   },
  // },
  {
    name: SettingTabNames.subscriptions,
    item: {
      name: "Subscriptions",
      tab: "subscriptions",
      path: "/settings/subscriptions",
      relativePath: "/subscriptions",
    },
  },
  {
    name: SettingTabNames.integrations,
    item: {
      name: "Integrations",
      tab: "integrations",
      path: "/settings/integrations",
      relativePath: "/integrations",
      shopify: {
        settings: {
          path: "/settings/integrations/shopify-settings",
          name: "shopify-settings",
          relativePath: "/integrations/shopify-settings",
        },
      },
    },
  },
  {
    name: SettingTabNames.dns,
    item: {
      name: "Domain & URLs",
      tab: "dns",
      path: "/settings/dns",
      relativePath: "/dns",
    },
  },
];
