import React, { Fragment, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Tooltip,
  SvgIcon,
  ActionsList,
  useBoolean,
  Typography,
  ActionOption,
} from "@gemlightbox/core-kit";

import { SettingTabNames, getSettingsTab } from "src/containers/settings";
import { useStores } from "src/hooks";

import { ReactComponent as ProfileSVG } from "src/external-ts/assets/images/profile-grey.svg";
import styles from "./header-profile-button.module.css";

export const HeaderProfileButton: React.FC = observer(() => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { localeStore, userStore } = useStores();

  const isOpenBoolean = useBoolean(false);

  const handleGoToSettings = () => {
    navigate(getSettingsTab(SettingTabNames.account)?.item.path as string);
  };

  const handleContactUs = () => {
    window.open("https://picupmedia.com/contact-us/", "_blank");
  };

  const handleLogout = () => userStore.logout();

  return (
    <Fragment>
      <div
        className={styles.profileBtn}
        ref={buttonRef}
        onClick={isOpenBoolean.trigger}
        data-cy="account-button"
      >
        <Typography size="medium" color={isOpenBoolean.value ? "primary" : "textSecondary"}>
          {`${userStore.userMe?.user.firstName} ${userStore.userMe?.user.lastName}`}
        </Typography>
        <SvgIcon color={isOpenBoolean.value ? "#0099A1" : ""} icon={ProfileSVG} />
      </div>
      <Tooltip
        className={styles.moreButtonTooltipContainer}
        position="bottomRight"
        appearance="unset"
        target={buttonRef}
        onClose={isOpenBoolean.setValue}
        isOpen={isOpenBoolean.value}
        disableAutoPosChange
      >
        <ActionsList className={styles.menuWrapper} onOptionClick={isOpenBoolean.setFalsy}>
          {!location.pathname.includes("settings") && (
            <ActionOption
              className={styles.profileOption}
              onClick={handleGoToSettings}
              data-cy="action-settings"
            >
              <Typography size="extraSmall">
                {localeStore.t("common.helpers.integrations.options.settings")}
              </Typography>
            </ActionOption>
          )}
          <ActionOption
            className={styles.profileOption}
            onClick={handleContactUs}
            data-cy="action-contact-us"
          >
            <Typography size="extraSmall">
              {localeStore.t('app.navbar["desktop-layout"].buttons.contact')}
            </Typography>
          </ActionOption>
          <ActionOption
            className={styles.profileOption}
            onClick={handleLogout}
            data-cy="action-log-out"
          >
            <Typography size="extraSmall">
              {localeStore.t('app.navbar["desktop-layout"].buttons.logout')}
            </Typography>
          </ActionOption>
        </ActionsList>
      </Tooltip>
    </Fragment>
  );
});
