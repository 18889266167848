import { getAppRequiredMinimumVersion } from "src/api";
import { pushDataLayerEvent } from "./push-data-layer-event.utils";
import { EnvironmentInfoRes } from "src/store/app-callback";

export const pushGemHubAppUpgrageRequiredEvent = (data: EnvironmentInfoRes) => {
  getAppRequiredMinimumVersion
    .getRequest({
      queryParams: `?platform=${data.osType ? data.osType : "macOS"}&bundleId=${
        data.appBundleID
      }&version=${data.appVersion}&osVersion=${
        data.osVersion ? data.osVersion : data.macOSVersion
      }`,
    })
    .fetch()
    .then((res) => {
      if (res.status === "success" && res?.success?.appRequiredMinimumVersion) {
        if (
          Number(data.appVersion.replace(/\./g, "")) <
          Number(res.success.appRequiredMinimumVersion?.replace(/\./g, ""))
        ) {
          pushDataLayerEvent({
            event: "gemhub:app:upgrade_required",
            event_params: { required_minimum_app_version: res.success.appRequiredMinimumVersion },
          });
        }
      }
    });
};
