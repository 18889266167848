import React from "react";
import { observer } from "mobx-react-lite";
import { Nullable, Radio, SvgIcon, Typography } from "@gemlightbox/core-kit";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { useStores } from "src/hooks";
import { ProductTypeModel, productDefaultTypesNames } from "src/models";
import { productTypesMap } from "../../ar-media.constants";

import styles from "./product-type-selector.module.css";

export type ProductTypeSelectorProps = {
  productType: Nullable<ProductTypeModel>;
  data: ProductTypeModel[];
  onChange: (value: ProductTypeModel) => void;
};

export const ProductTypeSelector: React.FC<ProductTypeSelectorProps> = observer(
  ({ productType, data, onChange }) => {
    if (!data.length) return null;

    const { localeStore } = useStores();

    return (
      <div className={styles.container}>
        <Typography size="small600" color="textSecondary">
          {localeStore.t('["ar-media"]["media-editor"]["product-type-selector"].title')}
        </Typography>
        <div className={styles.list}>
          {data.map((category) => {
            const item = productTypesMap[category.name.toLowerCase()];

            if (!item) return;

            return (
              <div
                key={item.type}
                className={styles.item}
                onClick={() => onChange(category)}
                data-name={`${item.type}-type-selector`}
              >
                <Radio
                  className={styles.selector}
                  checked={category.name === productType?.name}
                  onChange={() => null}
                />

                <SvgIcon icon={item.icon} />
                <Typography size="extraSmall500" color="textSecondary">
                  {/* {item.label} */}
                  {localeStore.t(
                    `create["augmented-reality-item"]["product-type"].${
                      productDefaultTypesNames[item.label.toLowerCase()]
                    }` as LocaleCodeTypes,
                  )}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);
