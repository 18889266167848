import { makeAutoObservable, runInAction } from "mobx";
import {
  base64ToBlob,
  CommandAction,
  hexToRgba,
  loadImage,
  loadImageUrl,
  resizeImage,
  Vec2,
  Webgl2New,
  CanvasDrawImageSource,
  Nullable,
} from "@gemlightbox/core-kit";

import { getMedia, postRetouchAISingle, postSingleRemoveDust } from "src/api";
import { DeviceMetaDataModel, MediaShortModel } from "src/models";
import { pushDataLayerEvent, removeImageBackground } from "src/utils";
import { useStores } from "src/hooks";
import { ErrorCodes } from "src/constants";
import {
  openDustRemovalErrorNotification,
  openRetouchErrorNotification,
} from "src/containers/media/media.utils";
import {
  EditMediaPageHistoryState,
  EditMediaTabBlockCallback,
  EditMediaTabType,
} from "./edit-media.types";
import { transparentBackground } from "../create/remove-background/remove-background.constants";

class EditMediaStore {
  private _loading: boolean;
  public get loading() {
    return this._loading;
  }

  private _currentTab: EditMediaTabType = "adjustments";
  public get currentTab() {
    return this._currentTab;
  }

  private _tabBlockCallback: Nullable<EditMediaTabBlockCallback> = null;
  private _tabProceedCallback: Nullable<VoidFunction> = null;

  private _metaData: DeviceMetaDataModel;
  public get metaData() {
    this._metaData &&
      (this._metaData.isTransparentBackground = this.currentBGColor === transparentBackground);
    return this._metaData;
  }

  private _isBGRemoved: boolean;
  public get isBGRemoved() {
    return this._isBGRemoved;
  }

  private _inBGAtFirst: boolean;
  public get inBGAtFirst() {
    return this._inBGAtFirst;
  }

  private _isInCrop: boolean;
  public get isInCrop() {
    return this._isInCrop;
  }

  public get isInMeasurement() {
    return this._currentTab === "measurements";
  }

  public get isInResize() {
    return this._currentTab === "resize";
  }

  public get isInBackground() {
    return this._currentTab === "background";
  }

  public mediaShortModel: MediaShortModel;
  public renderer: Webgl2New.Webgl2dRenderer;

  private _defaultColors = [transparentBackground, "#0d0d0d", "#ffffff"];
  private _addedColors: string[] = [];

  public get bgColors() {
    return this._defaultColors.concat(this._addedColors);
  }

  public currentBGColor = this._defaultColors[2];
  public tempBGColor = this.currentBGColor;

  public uploadedBGs: string[] = []; // objectUrls
  public currentUploadedBG = ""; // objectUrl
  private _isProcessingImage = false;
  public get isProcessingImage() {
    return this._isProcessingImage;
  }

  public measurementColors: string[] = [];
  public measurementsArr: Webgl2New.MeasurementComponent[] = [];
  public fantomMeasurement: Webgl2New.MeasurementComponent;
  private _currentMeasurement: Webgl2New.MeasurementComponent;
  public get currentMeasurement() {
    return this._currentMeasurement;
  }

  public set currentMeasurement(measurementComponent) {
    this._currentMeasurement = measurementComponent;
    this.renderer.selectComponent(measurementComponent);
  }

  private _measurementsStraightenMode = true;
  public get measurementsStraightenMode() {
    return this._measurementsStraightenMode;
  }

  public backgroundComponent: Webgl2New.BackgroundComponent;
  public backgroundImageComponent: Webgl2New.ImageAdjustRectComponent;
  public mainImageComponent: Webgl2New.ImageAdjustRectComponent;
  public measurementsComponent: Webgl2New.MeasurementsComponent;

  constructor() {
    this._initStore();
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async loadMedia(mediaId: string, locationState?: EditMediaPageHistoryState) {
    runInAction(() => {
      this._loading = true;
    });

    const { success } = await getMedia.getRequest({ params: { mediaId } }).fetch();
    const originalImage = await loadImage(
      locationState?.image ?? success?.file?.original ?? "",
      true,
    );

    runInAction(() => {
      if (success) {
        this.mediaShortModel = success;
        this._metaData = success.metaData || {};
        const image = this.constrainSizeImage(originalImage);
        const size = new Vec2(image.width, image.height);

        this.measurementsComponent.setMeasurementHeightAndFontSize(size.min());

        this.mainImageComponent.transform.setSize(size);
        this.mainImageComponent.state.texture.loadSource(image);

        this.renderer.setCanvasSize(size, true);
        this.renderer.selectComponent(this.mainImageComponent);
        this._isBGRemoved = this._metaData.isTransparentBackground || false;
        this._isBGRemoved && this.changeBGColor(transparentBackground);
      }

      this._loading = false;
    });
  }

  public constrainSizeImage(image: CanvasDrawImageSource) {
    const w = Webgl2New.maxCanvasSize;
    const h = Webgl2New.maxCanvasSize;

    if (image.width > w || image.height > h) {
      if (image.width > image.height) {
        const ratio = w / image.width;
        return resizeImage(image, w, Math.round(image.height * ratio));
      } else {
        const ratio = h / image.height;
        return resizeImage(image, Math.round(image.height * ratio), h);
      }
    }

    return image;
  }

  public removeBG(params: { mediaId: number }) {
    const { mediaId = null } = params;
    if (this._isBGRemoved) return;

    const { notificationStore, localeStore, userStore } = useStores();

    notificationStore.openLoader({
      loaderType: "diamond-loader",
      appearance: "secondary",
      title: localeStore.t('["edit-media"].loader.title'),
      subtitle: localeStore.t('["edit-media"].loader.subtitle'),
    });

    const oldImage = this.mainImageComponent.state.texture.textureToCanvas();

    const media_ids = mediaId ? [mediaId] : [];

    removeImageBackground(oldImage, {}, media_ids)
      .then(({ image }) => {
        // Note: silently updating userMe due to usage limits
        userStore.loadUserMeSilently();

        const actionTexture = new Webgl2New.Texture();
        actionTexture.loadSyncSource(image);

        const undoTexture = this.mainImageComponent.state.texture.clone();

        this.renderer.commands.execute(
          new CommandAction(
            "background-removal",
            () => {
              runInAction(() => {
                this.mainImageComponent.state.texture = actionTexture.clone();
                this._isBGRemoved = true;
                this._metaData = { ...this._metaData, isAiBackgroundRemoval: true };
              });
            },
            () => {
              runInAction(() => {
                this.mainImageComponent.state.texture = undoTexture.clone();
                this._isBGRemoved = false;
                this._metaData = { ...this._metaData, isAiBackgroundRemoval: false };
              });
            },
          ),
        );

        this._inBGAtFirst = true;
        this.openBackgroundTab();
      })
      .finally(() => notificationStore.closeLoader());
  }

  public retouchAI(params: { mediaId?: string }) {
    const { mediaId } = params;
    const { notificationStore, localeStore, userStore } = useStores();

    notificationStore.openLoader({
      title: localeStore.t('["edit-media"].loader.title'),
      subtitle: localeStore.t('["edit-media"].loader.subtitle'),
      loaderType: "diamond-loader",
      appearance: "secondary",
    });

    const oldImage = this.mainImageComponent.state.texture.textureToCanvas();
    const prevWidth = oldImage.width;
    const prevHeight = oldImage.height;
    const base64 = oldImage.toDataURL();
    const blob = base64ToBlob(base64.split(",")[1], "image/png");
    const formData = new FormData();
    formData.append("files", blob);

    if (mediaId) {
      formData.append("media_ids", mediaId.toString());
    }

    const request = postRetouchAISingle.getRequest({ data: formData });

    request.events.on("success", async ({ success }) => {
      // Note: silently updating userMe due to usage limits
      userStore.loadUserMeSilently();

      let retouchedImg: CanvasDrawImageSource = await loadImage(
        `data:image/png;base64,${success}`,
        true,
      );

      // NOTE: retouch AI is limited to 1536x1536 maximum resolution,
      // so resize image to source size before actual retouching
      if (retouchedImg.width !== prevWidth || retouchedImg.height !== prevHeight) {
        retouchedImg = resizeImage(retouchedImg, prevWidth, prevHeight);
      }

      pushDataLayerEvent({ event: "retouch_ai" });

      const actionTexture = new Webgl2New.Texture();
      actionTexture.loadSyncSource(retouchedImg);
      const undoTexture = this.mainImageComponent.state.texture.clone();
      const undoIsTransparentBackground = this._metaData?.isTransparentBackground || false;
      const undoCurrentBGColor = this.currentBGColor;

      this.renderer.commands.execute(
        new CommandAction(
          "retouch-ai",
          () => {
            this.mainImageComponent.state.texture = actionTexture.clone();
            this._metaData = {
              ...this._metaData,
              isAiRetouch: true,
              isTransparentBackground: false,
            };
            this.changeBGColor("#ffffff");
          },
          () => {
            this.mainImageComponent.state.texture = undoTexture.clone();
            this._metaData = {
              ...this._metaData,
              isAiRetouch: false,
              isTransparentBackground: undoIsTransparentBackground,
            };
            this.changeBGColor(undoCurrentBGColor);
          },
        ),
      );

      notificationStore.closeLoader();
    });

    request.events.on("error", ({ error }) => {
      if (error.originalError?.code === ErrorCodes.MEDIA_RETOUCH_LIMIT_EXCEEDED) {
        openRetouchErrorNotification(error.originalError?.message);
      }
      notificationStore.closeLoader();
    });

    request.fetch();
  }

  public removeDust(params: { mediaId?: string }) {
    const { mediaId = null } = params;
    const { notificationStore, localeStore, userStore } = useStores();

    notificationStore.openLoader({
      loaderType: "diamond-loader",
      appearance: "secondary",
      title: localeStore.t('["edit-media"].loader.title'),
      subtitle: localeStore.t('["edit-media"].loader.subtitle'),
    });

    const oldImage = this.mainImageComponent.state.texture.textureToCanvas();
    const base64 = oldImage.toDataURL();
    const blob = base64ToBlob(base64.split(",")[1], "image/png");

    const formData = new FormData();
    formData.append("file", blob);
    if (mediaId) {
      formData.append("media_ids", mediaId.toString());
    }

    const request = postSingleRemoveDust.getRequest({ data: formData });

    request.events.on("success", async ({ success }) => {
      const { statusCode, base64 } = success;

      if (statusCode === 200) {
        // Note: silently updating userMe due to usage limits
        userStore.loadUserMeSilently();

        pushDataLayerEvent({ event: "retouch_lite" });

        const actionTexture = new Webgl2New.Texture();
        await actionTexture.loadSource("data:image/png;base64," + base64);
        const undoTexture = this.mainImageComponent.state.texture.clone();

        this.renderer.commands.execute(
          new CommandAction(
            "remove-dust",
            () => {
              this.mainImageComponent.state.texture = actionTexture.clone();
              this._metaData = { ...this._metaData, isAiRemoveDust: true };
            },
            () => {
              this.mainImageComponent.state.texture = undoTexture.clone();
              this._metaData = { ...this._metaData, isAiRemoveDust: false };
            },
          ),
        );

        notificationStore.closeLoader();
      }
    });

    request.events.on("error", ({ error }) => {
      if (error.statusCode === 400) {
        openDustRemovalErrorNotification(error.originalError?.message);
      }
      notificationStore.closeLoader();
    });

    request.fetch();
  }

  /* Requests ↑ */

  /* UI State ↓ */
  public mount(parent: HTMLElement, mediaId: string, locationState: EditMediaPageHistoryState) {
    runInAction(() => {
      this.renderer = new Webgl2New.Webgl2dRenderer();
      this._isBGRemoved = locationState?.hasRemovedBackground || false;
    });

    return Webgl2New.Webgl2.initialize().then(() => {
      this.loadMedia(mediaId, locationState);
      this.renderer.mount(parent, parent.clientWidth, parent.clientHeight);
      this._handleInitializeWebgl2();
    });
  }

  public unmount() {
    this.renderer.unmount();
    this._initStore();
  }

  public openTab(tab: EditMediaTabType): boolean {
    if (
      this._tabBlockCallback?.({
        tab,
        unregister: () => this.unregisterTabBlockCallback(),
        proceed: () => this._tabProceedCallback?.(),
      })
    ) {
      return false;
    }

    this._currentTab = tab;
    return true;
  }

  public registerTabBlockCallback(callback: EditMediaTabBlockCallback) {
    this._tabBlockCallback = callback;
    return () => this.unregisterTabBlockCallback();
  }

  public unregisterTabBlockCallback() {
    this._tabBlockCallback = null;
  }

  public changeBGColor(color: string) {
    this.currentBGColor = color;
    this.tempBGColor = color;
    this.backgroundComponent.state.color = hexToRgba(color, true);
  }

  public setTempColor(color: string) {
    this.tempBGColor = color;
    this.backgroundComponent.state.color = hexToRgba(color, true);
  }

  public addBGColor(color: string) {
    const hasSameColor = this.bgColors.includes(color);
    if (hasSameColor) return this.changeBGColor(color);

    const maximumAddedColors = 10;

    this.currentBGColor = color;
    this.tempBGColor = color;
    this.backgroundComponent.state.color = hexToRgba(color, true);
    this._addedColors = this._addedColors.concat(color).slice(-maximumAddedColors);
  }

  public async uploadBGImage(file: File) {
    runInAction(() => {
      this._isProcessingImage = true;
    });

    const { image, imageUrl } = await loadImageUrl(file);

    runInAction(() => {
      this._isProcessingImage = false;
    });

    const newTexture = new Webgl2New.Texture();
    newTexture.loadSyncSource(image);

    this.backgroundImageComponent.transform.calcDimensions({
      position: this.renderer.canvasGlobalCenter,
      size: new Vec2(image.width, image.height),
    });

    this.currentUploadedBG = imageUrl;
    this.uploadedBGs = this.uploadedBGs.concat(imageUrl);
    this.backgroundImageComponent.transform.copy(this.backgroundImageComponent.transform.clone());
    this.backgroundImageComponent.state.texture = newTexture;
    this.backgroundImageComponent.style.display = "initial";
    this.renderer.selectComponent(this.backgroundImageComponent);
  }

  private _selectSameBGImage() {
    this.currentUploadedBG = "";
    this.backgroundImageComponent.style.display = "none";
    this.renderer.selectComponent(null);
  }

  public async selectBGImage(objectUrl: string) {
    if (this.currentUploadedBG === objectUrl) {
      if (this.renderer.selectedComponent === (this.backgroundImageComponent as any)) {
        return this._selectSameBGImage();
      } else {
        return this.renderer.selectComponent(this.backgroundImageComponent);
      }
    }

    runInAction(() => {
      this._isProcessingImage = true;
    });

    const image = await loadImage(objectUrl);

    runInAction(() => {
      this._isProcessingImage = false;
    });

    const newTexture = new Webgl2New.Texture();
    newTexture.loadSyncSource(image);

    this.backgroundImageComponent.transform.calcDimensions({
      position: this.renderer.canvasGlobalCenter,
      size: new Vec2(image.width, image.height),
    });

    this.currentUploadedBG = objectUrl;
    this.backgroundImageComponent.transform.copy(this.backgroundImageComponent.transform.clone());
    this.backgroundImageComponent.state.texture = newTexture;
    this.backgroundImageComponent.style.display = "initial";
    this.renderer.selectComponent(this.backgroundImageComponent);
  }

  public setStraightenMode(value: boolean) {
    this._measurementsStraightenMode = value;
    this.fantomMeasurement.setMeasurementsStraightenMode(value);
    this.measurementsArr.forEach((measurement) => {
      measurement.setMeasurementsStraightenMode(value);
    });
  }

  public openCrop() {
    this._isInCrop = true;
    this.renderer.commands.events.block();
    this.renderer.selectComponent(this.mainImageComponent);
    this.renderer.eventsManager.assignTool(this.renderer.tools.cropTool);
  }

  public closeCrop() {
    this._isInCrop = false;
    this.renderer.commands.events.unblock();
    this.renderer.eventsManager.assignTool(this.renderer.tools.handSelectionTool);
  }

  public handleCrop() {
    this.renderer.commands.events.unblock();
    this.renderer.tools.cropTool.handleCrop();
    this.closeCrop();
  }

  public openAdjustmentsTab() {
    if (this._tabBlockCallback) this._tabProceedCallback = this.openAdjustmentsTab;
    if (!this.openTab("adjustments")) return;
    this.renderer.eventsManager.assignTool(this.renderer.tools.handSelectionTool);
  }

  public openMeasurementsTab() {
    if (this._tabBlockCallback) this._tabProceedCallback = this.openMeasurementsTab;
    if (!this.openTab("measurements")) return;
    this.renderer.commands.events.block();
    this.renderer.selectComponent(this.fantomMeasurement);
    this.renderer.eventsManager.assignTool(this.renderer.tools.measurementTool);
  }

  public closeMeasurementsTab() {
    this.openTab("adjustments");
    this.renderer.commands.events.unblock();
    this.renderer.eventsManager.assignTool(this.renderer.tools.handSelectionTool);
    this.renderer.selectComponent(this.mainImageComponent);
    this._resetFantomMeasurement();
  }

  public openResizeTab() {
    if (this._tabBlockCallback) this._tabProceedCallback = this.openResizeTab;
    if (!this.openTab("resize")) return;
    this.renderer.commands.events.block();
    this.renderer.selectComponent(null);
    this.renderer.eventsManager.assignTool(this.renderer.tools.canvasResizeTool);
  }

  public closeResizeTab() {
    this.openTab("adjustments");
    this.renderer.commands.events.unblock();
    this.renderer.eventsManager.assignTool(this.renderer.tools.handSelectionTool);
    this.renderer.selectComponent(this.mainImageComponent);
  }

  public openBackgroundTab() {
    if (this._tabBlockCallback) this._tabProceedCallback = this.openBackgroundTab;
    if (!this.openTab("background")) return;
    this.renderer.commands.events.block();
    this.renderer.eventsManager.assignTool(this.renderer.tools.handSelectionTool);
  }

  public closeBackgroundTab() {
    this.openTab("adjustments");
    this.renderer.commands.events.unblock();
    this.renderer.eventsManager.assignTool(this.renderer.tools.handSelectionTool);
    this.renderer.selectComponent(this.mainImageComponent);
    this._inBGAtFirst = false;
  }

  public openMediaInfoTab() {
    this.openTab("media-info");
  }

  /* UI State ↑ */

  /* Helpers ↓ */
  private _initStore() {
    this._currentTab = "adjustments";
    this._tabBlockCallback = null;
    this._tabProceedCallback = null;

    this._loading = true;
    this._isBGRemoved = false;
    this._inBGAtFirst = false;
    this._isInCrop = false;

    this.mediaShortModel = null as any;
    this.renderer = null as any;

    this._addedColors = [];
    this.currentBGColor = this._defaultColors[2];
    this.tempBGColor = this.currentBGColor;
    this.uploadedBGs = [];
    this.currentUploadedBG = "";
    this._isProcessingImage = false;

    this.measurementColors = ["#0d0d0d", "#ffffff"];
    this.measurementsArr = [];

    this.backgroundComponent = null as any;
    this.renderer?.events?.offEvent("canvasTransform");

    this._measurementsStraightenMode = true;

    this.backgroundImageComponent = null as any;
    this.mainImageComponent = null as any;
    this.measurementsComponent = null as any;
  }

  private _resetFantomMeasurement() {
    this.fantomMeasurement = new Webgl2New.MeasurementComponent({
      color: this.measurementColors[0],
      straightenMode: this.measurementsStraightenMode,
    });
    this._currentMeasurement = this.fantomMeasurement;
  }

  private _handleInitializeWebgl2() {
    // Background Color Component ↓
    const backgroundComponent = new Webgl2New.BackgroundComponent(
      hexToRgba(this.currentBGColor, true),
    );
    this.backgroundComponent = backgroundComponent;
    this.renderer.events.on("canvasTransform", () => {
      this.backgroundComponent.transform.copy(this.renderer.chessGridComponent.transform);
    });
    backgroundComponent.transform.setSize(this.renderer.canvasSize);
    backgroundComponent.style.pointerEvents = "none";
    this.renderer.addComponent(backgroundComponent);
    // Background Color Component ↑

    // Background Image Component ↓
    const backgroundImageTexture = new Webgl2New.Texture();
    const backgroundImageComponent = new Webgl2New.ImageAdjustRectComponent(backgroundImageTexture);
    this.backgroundImageComponent = backgroundImageComponent;
    backgroundImageComponent.style.display = "none";
    this.renderer.addComponent(backgroundImageComponent);
    // Background Image Component ↑

    // Main Image Component ↓
    const mainImageTexture = new Webgl2New.Texture();
    const mainImageComponent = new Webgl2New.ImageAdjustRectComponent(mainImageTexture);
    this.mainImageComponent = mainImageComponent;
    this.renderer.addComponent(mainImageComponent);
    // Main Image Component ↑

    // Fantom Measurement Component ↓
    this._resetFantomMeasurement();
    // Fantom Measurement Component ↑

    // Measurements Component ↓
    const measurementsComponent = new Webgl2New.MeasurementsComponent();
    this.measurementsComponent = measurementsComponent;
    this.renderer.addComponent(measurementsComponent);
    // Measurements Component ↑
  }

  /* Helpers ↑ */
}

export const editMediaStore = new EditMediaStore();
