import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IntegrationDisconnectModal from "../IntegrationDisconnectModal";

import { toggleShopifyDisconnectModal, removeShopifyIntegration } from "src/redux/reducers/shopify";
import { getShopifyStoreURL } from "../../../../../common/helpers/integrations.helpers";
import { setActiveShopifyShop } from "src/redux/reducers/user";

export const ShopifyDisconnectModal = ({ isOpened }) => {
  const dispatch = useDispatch();
  const activeShopifyShop = useSelector(({ userReducer }) => userReducer.activeShopifyShop);
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = async () => {
    const storeURL = getShopifyStoreURL(activeShopifyShop);
    setLoading(true);

    try {
      await dispatch(removeShopifyIntegration(activeShopifyShop, storeURL));
      dispatch(setActiveShopifyShop(""));
      dispatch(toggleShopifyDisconnectModal());
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <IntegrationDisconnectModal
      isOpened={isOpened}
      loading={loading}
      name="Shopify"
      onClose={() => dispatch(toggleShopifyDisconnectModal())}
      onConfirm={handleConfirm}
    />
  );
};

export default ShopifyDisconnectModal;
