import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  clsx,
  globalStyles,
  useTargetEvent,
  curry,
  handleSetRef,
  useDidMount,
  useBoolean,
  SvgIcon,
  useWindowSize,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { BlockWrapper } from "../block-wrapper";
import { BlockTitle } from "../block-title";
import { AugmentedRealityItem } from "./augmented-reality-item";
import {
  augmentedRealityBlockSubId,
  augmentedRealityTypes,
  targetOptions,
} from "./augmented-reality-block.constants";
import { preventEvent } from "./augmented-reality-block.utils";

import { ReactComponent as ArrowRightSVG } from "src/external-ts/assets/images/arrow-right-grey.svg";
import styles from "./augmented-reality-block.module.css";

export const AugmentedRealityBlock: React.FC = observer(() => {
  const { localeStore } = useStores();

  const canShowLeftArrowBoolean = useBoolean();

  const canShowRightArrowBoolean = useBoolean();

  const [scrolledIndex, setScrolledIndex] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const wheelTargetEvent = useTargetEvent("wheel", preventEvent, targetOptions);
  const keyDownTargetEvent = useTargetEvent("keydown", preventEvent, targetOptions);
  const touchMoveTargetEvent = useTargetEvent("touchmove", preventEvent, targetOptions);

  const handleLeftArrowClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const arrowParent = e.currentTarget.parentElement;
    const container = containerRef.current;
    if (!container || !arrowParent) return;

    const children = container.children;
    const newNodeIndex = Math.max(scrolledIndex - 1, 0);
    const child = children[newNodeIndex] as HTMLElement;
    const left = Math.max(child.offsetLeft - arrowParent.clientWidth, 0);

    container.scrollTo({ behavior: "smooth", left });
    setScrolledIndex(newNodeIndex);
  };

  const handleRightArrowClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const arrowParent = e.currentTarget.parentElement;
    const container = containerRef.current;
    if (!container || !arrowParent) return;

    const children = container.children;
    let newNodeIndex = Math.min(scrolledIndex + 1, children.length - 1);
    let child = children[newNodeIndex] as HTMLElement;
    let left = child.offsetLeft - arrowParent.clientWidth;

    const computed = getComputedStyle(container);

    const containerLimit =
      container.clientWidth - child.clientWidth - parseFloat(computed.columnGap);
    const limit = container.scrollWidth - left;
    newNodeIndex = limit >= containerLimit ? newNodeIndex : newNodeIndex - 1;
    child = children[newNodeIndex] as HTMLElement;
    left = child.offsetLeft - arrowParent.clientWidth;

    container.scrollTo({ behavior: "smooth", left });
    setScrolledIndex(newNodeIndex);
  };

  const handleCalcScroll = () => {
    const container = containerRef.current;
    if (!container) return;
    const canShowLeft = !!container.scrollLeft;
    const canShowRightArrow = container.clientWidth + container.scrollLeft < container.scrollWidth;
    canShowLeftArrowBoolean.setValue(canShowLeft);
    canShowRightArrowBoolean.setValue(canShowRightArrow);
  };

  useDidMount(handleCalcScroll);

  useWindowSize(() => {
    containerRef.current?.scrollTo({
      behavior: "auto",
      left: 0,
    });
    setScrolledIndex(0);
    handleCalcScroll();
  }, "ref");

  return (
    <BlockWrapper className={styles.augmentedRealityBlockContainer}>
      <BlockTitle
        title={localeStore.t('create["augmented-reality-block"].title')}
        tooltipText={localeStore.t('create["augmented-reality-block"]["tooltip-text"]')}
      />

      <div id={augmentedRealityBlockSubId} className={styles.augmentedRealityItemsContainer}>
        <div
          className={clsx(styles.arrowContainer, styles.arrowContainerLeft, {
            [styles.visible]: canShowLeftArrowBoolean.value,
          })}
        >
          <SvgIcon
            className={styles.arrowIcon}
            icon={ArrowRightSVG}
            onClick={handleLeftArrowClick}
          />
        </div>
        <div
          className={clsx(styles.arrowContainer, styles.arrowContainerRight, {
            [styles.visible]: canShowRightArrowBoolean.value,
          })}
        >
          <SvgIcon
            className={styles.arrowIcon}
            icon={ArrowRightSVG}
            onClick={handleRightArrowClick}
          />
        </div>
        <div
          className={clsx(styles.augmentedRealityItemsWrapper, globalStyles.hideScrollBar)}
          ref={curry(handleSetRef)([
            containerRef,
            wheelTargetEvent.targetRef,
            keyDownTargetEvent.targetRef,
            touchMoveTargetEvent.targetRef,
          ])}
          onScroll={handleCalcScroll}
        >
          {augmentedRealityTypes.map((item, i) => (
            <AugmentedRealityItem key={i} item={item} />
          ))}
        </div>
      </div>
    </BlockWrapper>
  );
});

export default AugmentedRealityBlock;
