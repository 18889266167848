import React from "react";
import { observer } from "mobx-react-lite";
import { Loader } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import {
  PageResultsEmpty,
  PageResultsPlaceholderContainer,
  ProductsEmpty,
} from "src/external-ts/components";

export const ProductsListPlaceholder: React.FC = observer(() => {
  const { productsStore, catalogStore } = useStores();

  const loading = productsStore.loading || catalogStore.loading || catalogStore.catalog?.is_all;
  const isEmpty = productsStore.totalProductsAmount === 0;

  return (
    <PageResultsPlaceholderContainer>
      {loading && <Loader type="goo-loader" />}
      {!loading && isEmpty && <ProductsEmpty showImage={false} />}
      {!loading && !isEmpty && (
        <PageResultsEmpty
          search={productsStore.filters.title}
          isOnlySearch={productsStore.isOnlySearch}
        />
      )}
    </PageResultsPlaceholderContainer>
  );
});
