import { SelectOption } from "@gemlightbox/core-kit";

import { ProductType } from "src/models";
import { AIDescriptionBuilderForm } from "./ai-description-builder-sidebar.types";

export const FORM_ID = "ai-description-builder";

export const aiDescriptionBuilderInitialForm: AIDescriptionBuilderForm = {
  detailedTitle: "",
  productType: "",
  tags: [],
};

export const maxTags = 25;
export const maxTagLength = 100;

const supportedProductTypes = [
  ProductType.rings,
  ProductType.earrings,
  ProductType.necklaces,
  ProductType.bracelets,
  ProductType.gemstones,
  ProductType.watches,
];

export const productTypeOptions: SelectOption[] = supportedProductTypes.map((value) => ({
  value,
  label: value,
  name: `product-type-${value}`,
}));
