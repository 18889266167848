import { useStores as useCoreStores } from "@gemlightbox/core-kit";

import { rootStore } from "src/store";

const stores = {
  ...useCoreStores(),
  ...rootStore.stores,
};

// Note this is kinda crutch because it's can't be threatened as hook since we don't use
// component lifecycle, so we can refer it everywhere we want use mobx stores.
export const useStores = () => stores;
