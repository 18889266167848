import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  CommandAction,
  Range,
  SvgIcon,
  TooltipTarget,
  Typography,
  useRerender,
  Webgl2New,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { editMediaStore } from "../../../edit-media.store";
import { GroupBlock } from "../../group-block";
import { SupportedAdjustments } from "./adjustments-filters.types";
import { ReactComponent as AutoPhotographyTipsSVG } from "src/external-ts/assets/images/camera/auto-photography-tips.svg";

import styles from "./adjustments-filters.module.css";
import { MuiSwitch } from "src/external-ts/components";

export const AdjustmentsFilters: React.FC = observer(() => {
  const rerender = useRerender();
  const { localeStore, tooltipStore } = useStores();
  const { renderer } = editMediaStore;

  const adjustableImage =
    renderer.selectedComponent instanceof Webgl2New.ImageAdjustRectComponent
      ? renderer.selectedComponent
      : null;

  const handleChangeFilter = (uniform: SupportedAdjustments) => (value: number) => {
    if (!adjustableImage) return;
    adjustableImage.state.filters[uniform] = value;
    rerender();
  };

  const handleChangeFilterFinal = (uniform: SupportedAdjustments) => {
    return (value: number, _: number, prevValue: number) => {
      if (!adjustableImage) return;

      const imageComponent = adjustableImage;

      renderer.commands.execute(
        new CommandAction(
          "Adjustment change",
          () => {
            imageComponent.state.filters[uniform] = value;
          },
          () => {
            imageComponent.state.filters[uniform] = prevValue;
          },
        ),
      );
    };
  };

  let calibTiptimeoutId = 0;
  const handleMouseEnterCalib = (event: React.MouseEvent<HTMLButtonElement>) => {
    calibTiptimeoutId = setTimeout(() => {
      tooltipStore.open(
        <div className={styles.tooltipCalib}>
          {localeStore.t(
            '["edit-media"].inspector.adjustments["adjustments-filters"]["calib-tip"]',
          )}
        </div>,
        {
          target: event.target as TooltipTarget,
          appearance: "promo",
          position: "top",
          withAngle: true,
          className: styles.fadeEffect,
        },
      );
      setTimeout(() => {
        if (tooltipStore.tooltip) tooltipStore.tooltip.className = styles.fadeIn;
      }, 80);
    }, 600) as unknown as number;
  };

  const handleMouseLeaveCalib = () => {
    clearTimeout(calibTiptimeoutId);
    if (tooltipStore.tooltip?.className) tooltipStore.tooltip.className = styles.fadeEffect;
    tooltipStore.close();
  };

  if (!adjustableImage) return null;

  return (
    <GroupBlock className={styles.adjustmentsBlockGroup}>
      <Typography size="small600" color="textSecondary">
        {localeStore.t("common.webgl2.filters.title")}
      </Typography>
      <div className={styles.controlSummary}>
        <span className={styles.controlLabel}>
          {localeStore.t('camera["camera-controls"].buttons.calibrate')}
        </span>
        <Button
          className={styles.ml8}
          appearance="unset"
          onMouseEnter={handleMouseEnterCalib}
          onMouseLeave={handleMouseLeaveCalib}
        >
          <SvgIcon icon={AutoPhotographyTipsSVG} size={16}></SvgIcon>
        </Button>
        <MuiSwitch
          className={styles.switchWhiteBalance}
          checked={adjustableImage.checkWhiteBalance()}
          onChange={() => adjustableImage.isAutoWhiteBalance(!adjustableImage.checkWhiteBalance())}
        ></MuiSwitch>
      </div>
      <Range
        label={localeStore.t("common.webgl2.filters.brightness")}
        value={adjustableImage.state.filters.u_brightness}
        roundValue={2}
        step={0.05}
        min={-1}
        max={1}
        onChange={handleChangeFilter("u_brightness")}
        onFinalChange={handleChangeFilterFinal("u_brightness")}
        disableFinalCallOnSameValue
        showRangeValue
        disableError
      />
      <Range
        label={localeStore.t("common.webgl2.filters.contrast")}
        value={adjustableImage.state.filters.u_contrast}
        roundValue={2}
        step={0.05}
        min={-1.5}
        max={1.5}
        onChange={handleChangeFilter("u_contrast")}
        onFinalChange={handleChangeFilterFinal("u_contrast")}
        disableFinalCallOnSameValue
        showRangeValue
        disableError
      />
      <Range
        label={localeStore.t("common.webgl2.filters.saturation")}
        value={adjustableImage.state.filters.u_saturation}
        roundValue={2}
        step={0.05}
        min={-1.5}
        max={1.5}
        onChange={handleChangeFilter("u_saturation")}
        onFinalChange={handleChangeFilterFinal("u_saturation")}
        disableFinalCallOnSameValue
        showRangeValue
        disableError
      />
      <Range
        label={localeStore.t("common.webgl2.filters.sharpness")}
        value={adjustableImage.state.filters.u_sharpness}
        roundValue={2}
        step={0.05}
        min={-1}
        max={1}
        onChange={handleChangeFilter("u_sharpness")}
        onFinalChange={handleChangeFilterFinal("u_sharpness")}
        disableFinalCallOnSameValue
        showRangeValue
        disableError
      />
      <Range
        label={localeStore.t("common.webgl2.filters.hue")}
        rangeWrapperClassName={styles.hueRange}
        value={adjustableImage.state.filters.u_hue}
        step={1}
        min={0}
        max={360}
        onChange={handleChangeFilter("u_hue")}
        onFinalChange={handleChangeFilterFinal("u_hue")}
        disableFinalCallOnSameValue
        disableProgress
        showRangeValue
        disableError
      />
      <Range
        label={localeStore.t("common.webgl2.filters.levelsTone")}
        value={adjustableImage.state.filters.u_levelsTone}
        roundValue={2}
        step={0.01}
        min={-0.3}
        max={0.3}
        onChange={handleChangeFilter("u_levelsTone")}
        onFinalChange={handleChangeFilterFinal("u_levelsTone")}
        disableFinalCallOnSameValue
        showRangeValue
        disableError
      />
    </GroupBlock>
  );
});

export default AdjustmentsFilters;
