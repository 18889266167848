import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, CommandAction, hexToRgba, useDidMount } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { unsavedChangesNotification } from "src/utils";
import { editMediaStore } from "../../edit-media.store";
import { Color } from "./color";
import { Opacity } from "./opacity";
import { RecentPictures } from "./recent-pictures";
import { UploadBtn } from "./upload-btn";

import styles from "./background.module.css";

export const Background: React.FC = observer(() => {
  const { localeStore } = useStores();
  const { renderer, backgroundImageComponent } = editMediaStore;
  const isInCrutch = editMediaStore.inBGAtFirst;

  const [prevData] = useState(() => ({
    opacity: backgroundImageComponent.state.filters.u_opacity,
    bgColor: editMediaStore.currentBGColor,
    bgImage: editMediaStore.currentUploadedBG,
    display: backgroundImageComponent.style.display,
    texture: backgroundImageComponent.state.texture.clone(),
    transform: backgroundImageComponent.transform.clone(),
  }));

  const checkForChanges = () => {
    return (
      prevData.opacity !== backgroundImageComponent.state.filters.u_opacity ||
      prevData.bgColor !== editMediaStore.currentBGColor ||
      prevData.bgImage !== editMediaStore.currentUploadedBG ||
      !prevData.transform.equals(backgroundImageComponent.transform)
    );
  };

  const hasChanges = checkForChanges();
  const disableDone = !hasChanges && !isInCrutch;

  const handleCancel = () => {
    editMediaStore.unregisterTabBlockCallback();

    backgroundImageComponent.state.filters.u_opacity = prevData.opacity;

    editMediaStore.currentBGColor = prevData.bgColor;
    editMediaStore.tempBGColor = prevData.bgColor;
    editMediaStore.backgroundComponent.state.color = hexToRgba(prevData.bgColor, true);

    editMediaStore.currentUploadedBG = prevData.bgImage;
    backgroundImageComponent.transform.copy(prevData.transform.clone());
    backgroundImageComponent.state.texture = prevData.texture.clone();
    backgroundImageComponent.style.display = prevData.display;
    renderer.selectComponent(null);

    editMediaStore.closeBackgroundTab();
  };

  const handleConfirmCancel = () => {
    if (hasChanges) {
      unsavedChangesNotification(handleCancel);
      return;
    }

    handleCancel();
  };

  const handleDone = () => {
    if (isInCrutch && !hasChanges) {
      editMediaStore.unregisterTabBlockCallback();
      editMediaStore.closeBackgroundTab();
      return;
    }

    editMediaStore.unregisterTabBlockCallback();

    const undoOpacity = prevData.opacity;
    const undoColor = prevData.bgColor;
    const undoUploadedBG = prevData.bgImage;
    const undoTransform = prevData.transform.clone();
    const undoTexture = prevData.texture.clone();
    const undoDisplay = prevData.display;

    const actionOpacity = backgroundImageComponent.state.filters.u_opacity;
    const actionColor = editMediaStore.currentBGColor;
    const actionUploadedBG = editMediaStore.currentUploadedBG;
    const actionTransform = backgroundImageComponent.transform.clone();
    const actionTexture = backgroundImageComponent.state.texture.clone();
    const actionDisplay = backgroundImageComponent.style.display;

    editMediaStore.closeBackgroundTab();

    renderer.commands.execute(
      new CommandAction(
        "Background change",
        () => {
          backgroundImageComponent.state.filters.u_opacity = actionOpacity;

          editMediaStore.currentBGColor = actionColor;
          editMediaStore.tempBGColor = actionColor;
          editMediaStore.backgroundComponent.state.color = hexToRgba(actionColor, true);

          editMediaStore.currentUploadedBG = actionUploadedBG;
          backgroundImageComponent.transform.copy(actionTransform);
          backgroundImageComponent.state.texture = actionTexture;
          backgroundImageComponent.style.display = actionDisplay;
        },
        () => {
          backgroundImageComponent.state.filters.u_opacity = undoOpacity;

          editMediaStore.currentBGColor = undoColor;
          editMediaStore.tempBGColor = undoColor;
          editMediaStore.backgroundComponent.state.color = hexToRgba(undoColor, true);

          editMediaStore.currentUploadedBG = undoUploadedBG;
          backgroundImageComponent.transform.copy(undoTransform);
          backgroundImageComponent.state.texture = undoTexture;
          backgroundImageComponent.style.display = undoDisplay;
        },
      ),
    );
  };

  useDidMount(() => {
    return editMediaStore.registerTabBlockCallback(({ unregister, proceed }) => {
      if (checkForChanges()) {
        unsavedChangesNotification(() => {
          unregister();
          handleCancel();
          proceed();
        });

        return true;
      }

      unregister();
      handleCancel();
    });
  });

  return (
    <>
      <RecentPictures />
      <UploadBtn />
      <Color />
      <Opacity />
      <div className={styles.buttons}>
        <Button appearance="secondaryOutlined" onClick={handleConfirmCancel}>
          {localeStore.t("common.buttons.cancel")}
        </Button>
        <Button onClick={handleDone} disabled={disableDone}>
          {localeStore.t("common.buttons.done")}
        </Button>
      </div>
    </>
  );
});

export default Background;
