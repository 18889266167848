import React from "react";
import { useImmerReducer } from "use-immer";

import { Button, ButtonAppearanceKeys, SvgIcon, clsx } from "@gemlightbox/core-kit";
import { ReactComponent as CloseSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import cameraLaptopSrc from "src/external-ts/assets/images/camera/verify-camera-connection-laptop.png";
import cameraMainSrc from "src/external-ts/assets/images/camera/verify-camera-connection-main.png";
import cameraUsbSrc from "src/external-ts/assets/images/camera/verify-camera-connection-usb.png";

import styles from "./camera-troubleshooting.module.css";
import { useStores } from "src/hooks";

type VerifyGemcamConnectionProps = {
  onClose: VoidFunction;
};

type StepType = {
  title: string;
  subtitle: string;
  actionLabel: string;
  actionStyle: ButtonAppearanceKeys;
  img: string;
  value: number;
};

type StepActionType = {
  step: number;
};

export const VerifyGemcamConnection: React.FC<VerifyGemcamConnectionProps> = ({ onClose }) => {
  const { localeStore } = useStores();

  const initStep: StepType = {
    title: localeStore.t('camera["camera-troubleshooting"]["connect-step"].init.title'),
    subtitle: localeStore.t('camera["camera-troubleshooting"]["connect-step"].init.subtitle'),
    actionLabel: localeStore.t('camera["camera-troubleshooting"]["connect-step"].init.actionLabel'),
    actionStyle: "primary",
    img: cameraLaptopSrc,
    value: 0,
  };

  const stepReducer = (draft: StepType, action: StepActionType) => {
    switch (action.step) {
      case 0: {
        draft.title = localeStore.t('camera["camera-troubleshooting"]["connect-step"].one.title');
        draft.subtitle = "";
        draft.actionLabel = localeStore.t(
          'camera["camera-troubleshooting"]["connect-step"].one.actionLabel',
        );
        draft.img = cameraMainSrc;
        draft.value = 1;
        break;
      }
      case 1: {
        draft.title = localeStore.t('camera["camera-troubleshooting"]["connect-step"].two.title');
        draft.actionLabel = localeStore.t(
          'camera["camera-troubleshooting"]["connect-step"].two.actionLabel',
        );
        draft.img = cameraUsbSrc;
        draft.value = 2;
        break;
      }
    }
  };

  const [step, dispath] = useImmerReducer(stepReducer, initStep);

  const handleNext = (step: number) => {
    if (step >= 2) {
      window.open("https://picupmedia.com/contact-us/?contact-support", "_blank");
      return;
    }

    dispath({
      step,
    });
  };

  return (
    <div className={clsx(styles.cameraTroubleshootingGemcamContainer, styles.connectionContainer)}>
      <Button className={styles.closeButton} appearance="secondaryGhost" onClick={onClose}>
        <SvgIcon icon={CloseSVG} />
      </Button>
      <div className={styles.cameraTroubleshootingBlockContent}>
        <img className={styles.cameraTroubleshootingConnectionBanner} src={step.img} />
        <div className={clsx(styles.cameraTroubleshootingBlockTitle, styles.pdV24)}>
          {step.title}
        </div>
        <div className={clsx(styles.cameraTroubleshootingBlockSubtitle, styles.pdV24)}>
          {step.subtitle}
        </div>
      </div>

      <Button
        className={clsx(styles.cameraTroubleshootingConnectionConfirmBtn, styles.pdV24)}
        appearance={step.actionStyle}
        onClick={() => handleNext(step.value)}
      >
        {step.actionLabel}
      </Button>
    </div>
  );
};
