import { ChangePasswordFormType } from "./change-password-modal.types";

export const initialValues: ChangePasswordFormType = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  accountAccess: true,
};

export const FORM_ID = "change-password-modal-form";
