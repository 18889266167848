import React, { useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import {
  Button,
  Modal,
  PuffLoader,
  SvgIcon,
  clsx,
  useBoolean,
  useDidUpdate,
  useDidMount,
  UseBooleanReturnType,
  Info,
} from "@gemlightbox/core-kit";
import { List, ListItem, ListItemText, ListSubheader, ListItemIcon } from "@mui/material";

import { ExtendedDeviceKind, DeviceModel } from "src/models";
import { useStores } from "src/hooks";
import { cameraStore } from "../camera.store";
import { ModalExtendedType } from "src/store";
import { deviceListModalStore } from "./device-list-modal.store";
import {
  BLUETOOTH_DEVICE_IDENTIFIER,
  BLUETOOTH_CONNECTION_TIMEDOUT,
} from "./device-list-modal.constants";

import BluetoothExplora from "src/components/BluetoothExplora";

import { ReactComponent as CloseSVG } from "src/external-ts/assets/images/camera/close-icon.svg";
import { ReactComponent as SuccessSVG } from "src/external-ts/assets/images/camera/bluetooth-success-icon.svg";
import { ReactComponent as ArrowRightSVG } from "src/external-ts/assets/images/arrow-right-grey.svg";
import { ReactComponent as RefreshSVG } from "src/external-ts/assets/images/camera/bluetooth-refresh-icon.svg";
import { ReactComponent as DeviceSVG } from "src/external-ts/assets/images/camera/bluetooth-device-icon.svg";
import { ReactComponent as ErrorSVG } from "src/external-ts/assets/images/circle-warning-icon-red.svg";
import { ReactComponent as ConnFailedSVG } from "src/external-ts/assets/images/camera/conn-failed-icon.svg";
import styles from "./device-list-modal.module.css";
import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import { FilterMode } from "../camera.types";

export type DeviceListModalProps = ModalExtendedType<{
  bluetoothAutoConnect?: boolean;
  bluetoothPermBool: UseBooleanReturnType;
  onClose?: VoidFunction;
  openScan?: boolean;
}>;

export const DeviceListModal: React.FC<DeviceListModalProps> = observer(
  ({ isOpen, setClose, options }) => {
    const {
      bluetoothAutoConnect: bluetoothAutoConnectDefault = false,
      onClose,
      openScan,
    } = options;
    const { localeStore, notificationStore, modalsStore } = useStores();
    const { bluetoothDeviceList, bluetoothDeviceSelected } = cameraStore;
    const { ExtendedDeviceKindMap, DeviceSelected, deviceSelectedStorage } = deviceListModalStore;
    const bluetoothAutoConnect = useBoolean(bluetoothAutoConnectDefault);
    const [pageIndex, setPageIndex] = useState(openScan ? 1 : 0);
    const refreshSty = useBoolean(false);
    const [retriesNum, setRetriesNum] = useState(0);
    const latestRetriesNum = useRef(retriesNum);
    const retriesNumId = useRef<NodeJS.Timeout>();
    const [currentDevice, setCurrentDevice] = useState<ExtendedDeviceKind>();

    const filterBluetooth = useMemo(() => {
      if (!currentDevice || !bluetoothDeviceList) return [];
      const filterList = bluetoothDeviceList.filter((item) => {
        return currentDevice.keywords.some((keyword) => item.name.includes(keyword));
      });
      return filterList;
    }, [bluetoothDeviceList, currentDevice]);

    const getDeviceName = (item: ExtendedDeviceKind) => {
      if (item?.device) return item.device.name;
      if (item?.selected)
        return localeStore.t('camera["device-list-modal"]["turntable-not-connected"]');
      if (item.value === FilterMode.GemCamOnly) return "No Lightbox";
      return "";
    };

    const handleDeviceSelect = (item: ExtendedDeviceKind) => {
      cameraStore.getBuletoothDeviceList();
      if (DeviceSelected?.device?.state === 2 && item.label !== DeviceSelected.label) {
        if (item.value === FilterMode.GemCamOnly) {
          notificationStore.open({
            title: localeStore.t('camera["device-list-modal"]["disconnect-device"]'),
            confirmAppearance: "primary",
            confirmText: localeStore.t('camera["device-list-modal"].confirm'),
            cancelText: localeStore.t('camera["device-list-modal"].cancel'),
            onOk: () => {
              cameraStore.disconnectBluetoothDevice(DeviceSelected.device!.identifier);
              runInAction(() => {
                ExtendedDeviceKindMap.map((m) => (m.selected = false));
              });
              deviceListModalStore.setDeviceSelected(item);
              setClose();
            },
          });
        } else {
          notificationStore.open({
            title: localeStore.t('camera["device-list-modal"]["connect-another-device"]'),
            message: localeStore.t('camera["device-list-modal"]["connect-another-device-sub"]'),
            confirmAppearance: "primary",
            confirmText: localeStore.t('camera["device-list-modal"].confirm'),
            cancelText: localeStore.t('camera["device-list-modal"].cancel'),
            onOk: () => {
              cameraStore.disconnectBluetoothDevice(DeviceSelected.device!.identifier);
              deviceListModalStore.setDeviceSelected(item);
              setCurrentDevice(item);
              handleGo();
            },
          });
        }
      } else {
        if (item.value !== FilterMode.GemCamOnly) {
          cameraStore.bluetoothConnectTurnTable().then((status) => {
            if (status < 5) {
              options.bluetoothPermBool.setTruthy();
              setClose();
            } else {
              setCurrentDevice(item);
              handleGo();
            }
          });
        } else {
          runInAction(() => {
            ExtendedDeviceKindMap.map((m) => (m.selected = false));
          });
          deviceListModalStore.setDeviceSelectedStorage(null);
          setCurrentDevice(item);
          deviceListModalStore.setDeviceSelected(item);
          setClose();
        }
      }
    };

    const handleBack = () => {
      setPageIndex(0);
    };

    const handleGo = () => {
      setPageIndex(1);
    };

    const handleDeviceConnect = (item: DeviceModel) => {
      const connectbuletooth = () => {
        item.loading = true;
        item.state = 1;
        cameraStore.connectBuletoothDevice(item.identifier);
        setTimeout(() => {
          runInAction(() => {
            if (item.loading) {
              item.loading = false;
              item.state = 9;
            }
          });
        }, 1000 * 5);
      };
      if (DeviceSelected && DeviceSelected.device?.state === 2) {
        notificationStore.open({
          title: `Are you sure to connect another ${DeviceSelected?.label}?`,
          message: `${DeviceSelected.device.name} will be disconnected`,
          confirmAppearance: "primary",
          confirmText: "Confirm",
          cancelText: "Cancel",
          onOk: async () => {
            await cameraStore.disconnectBluetoothDevice(DeviceSelected.device!.identifier);
            runInAction(connectbuletooth);
          },
        });
      } else {
        runInAction(connectbuletooth);
      }
    };

    const handleDeviceListRefresh = () => {
      cameraStore.getBuletoothDeviceList();
      refreshSty.trigger();
    };

    const handleDistconnect = (
      evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      item: ExtendedDeviceKind,
    ) => {
      evt.stopPropagation();
      cameraStore.disconnectBluetoothDevice(item.device!.identifier);
      runInAction(() => {
        item.device = undefined;
        item.selected = true;
        deviceListModalStore.setDeviceSelectedStorage(item);
      });
    };

    const handleBluetoothConnectionFailed = () => {
      notificationStore.open({
        title: (
          <div className={styles.connFailedContent}>
            <span className={styles.connFailedTitle}>
              {localeStore.t('camera["device-list-modal"]["no-device"]')}
            </span>
            <div className={styles.connFailedMsg}>
              <span>{localeStore.t('camera["device-list-modal"]["no-device-sub1"]')}</span>
              <span>{localeStore.t('camera["device-list-modal"]["no-device-sub2"]')}</span>
            </div>
          </div>
        ),
        confirmAppearance: "primary",
        confirmText: "Ok",
        cancelText: "Contact us",
        icon: <SvgIcon icon={ConnFailedSVG} size={72}></SvgIcon>,
        onOk: () => {
          notificationStore.close();
        },
        onCancel: () => {
          window.open("https://picupmedia.com/contact-us/", "_blank");
        },
      });
    };

    const renderPage = (index: number) => {
      switch (index) {
        case 0:
          return (
            <List
              subheader={
                <ListSubheader className={clsx(styles.listHeader)}>
                  <div className={styles.listSubheaderTitle}>
                    <span>{localeStore.t('camera["device-label"]')}</span>
                    <span className={styles.listSubtitle}>
                      {localeStore.t('camera["device-tips"]')}
                    </span>
                  </div>
                </ListSubheader>
              }
            >
              {ExtendedDeviceKindMap.map((item, index) => (
                <ListItem
                  className={clsx(styles.listItem)}
                  key={index}
                  onClick={() => handleDeviceSelect(item)}
                >
                  <ListItemIcon className={styles.listItemIcon}>
                    <SvgIcon
                      icon={item.src}
                      size={24}
                      color={
                        item.selected ||
                        item.device ||
                        (item.value === FilterMode.GemCamOnly &&
                          item.value === DeviceSelected?.value)
                          ? "#0099A1"
                          : "#E3E4E6"
                      }
                    ></SvgIcon>
                    {(item.device ||
                      (item.value === FilterMode.GemCamOnly &&
                        item.value === DeviceSelected?.value)) && (
                      <SvgIcon icon={SuccessSVG} size={12} className={styles.successIcon}></SvgIcon>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    className={styles.itemText}
                    classes={{
                      primary: styles.itemTextPrimary,
                      secondary: styles.itemTextsecondary,
                    }}
                    primary={item.label}
                    secondary={getDeviceName(item)}
                  />
                  {item.device && (
                    <Button
                      className={styles.actionBtn}
                      appearance="unset"
                      color="secondary"
                      onClick={(e) => handleDistconnect(e, item)}
                    >
                      {localeStore.t('camera["disconnect-text"]')}
                    </Button>
                  )}
                  <SvgIcon icon={ArrowRightSVG} size={24} className={styles.arrowRight}></SvgIcon>
                </ListItem>
              ))}
            </List>
          );
        case 1:
          if (filterBluetooth.length === 0) {
            return (
              <div className={styles.bluetoothExploraEx}>
                <BluetoothExplora />
                <span className={styles.searching}>
                  {localeStore.t('camera["device-list-modal"]["searching-devices"]')}...
                </span>
                <span className={styles.searchingTitle}>
                  {localeStore.t('camera["device-list-modal"]["searching-devices-sub"]')}
                </span>
              </div>
            );
          } else {
            return (
              <List
                subheader={
                  <ListSubheader className={clsx(styles.listHeader)}>
                    <div className={styles.listSubheaderTitle}>
                      <span>{localeStore.t('camera["device-label"]')}</span>
                      <span className={styles.listSubtitle}>
                        {localeStore.t('camera["device-tips"]')}
                      </span>
                    </div>
                    <SvgIcon
                      onClick={handleDeviceListRefresh}
                      className={clsx(styles.refreshBtn, { [styles.active]: refreshSty.value })}
                      icon={RefreshSVG}
                      size={24}
                    ></SvgIcon>
                  </ListSubheader>
                }
              >
                {filterBluetooth.map((item) => (
                  <ListItem className={clsx(styles.listItem)} key={item.identifier}>
                    <ListItemIcon className={styles.listItemIcon}>
                      <SvgIcon icon={DeviceSVG} size={24}></SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      className={styles.itemText}
                      classes={{
                        primary: styles.itemTextPrimary,
                      }}
                      primary={item.name}
                    />
                    {renderAction(item)}
                  </ListItem>
                ))}
              </List>
            );
          }
      }
    };

    const renderAction = (item: DeviceModel) => {
      switch (item.state) {
        case 0: {
          return (
            <div className={styles.itemAction}>
              {item.loading ? (
                <PuffLoader size={24} className={styles.actionIcon}></PuffLoader>
              ) : (
                <Button
                  className={styles.connectBtn}
                  appearance="unset"
                  color="secondary"
                  onClick={() => handleDeviceConnect(item)}
                >
                  {localeStore.t('camera["connect-text"]')}
                </Button>
              )}
            </div>
          );
        }
        case 1: {
          return (
            <div className={styles.itemAction}>
              <PuffLoader size={24} className={styles.actionIcon}></PuffLoader>
            </div>
          );
        }
        case 2: {
          return (
            <div className={styles.itemAction}>
              <Button
                className={styles.actionBtn}
                appearance="unset"
                color="secondary"
                onClick={() => {
                  cameraStore.disconnectBluetoothDevice(item.identifier);
                  runInAction(() => {
                    if (DeviceSelected) {
                      DeviceSelected.device = undefined;
                      DeviceSelected.selected = true;
                      deviceListModalStore.setDeviceSelectedStorage(DeviceSelected);
                    }
                  });
                }}
              >
                {localeStore.t('camera["disconnect-text"]')}
              </Button>
              <SvgIcon icon={SuccessSVG} size={18} className={styles.actionIcon}></SvgIcon>
            </div>
          );
        }
        case 9: {
          return (
            <div className={styles.itemAction}>
              <Button
                className={styles.connectBtn}
                appearance="unset"
                color="secondary"
                onClick={() => handleDeviceConnect(item)}
              >
                {localeStore.t('camera["device-list-modal"].reconnect')}
              </Button>
              <SvgIcon
                icon={ErrorSVG}
                size={18}
                className={styles.actionIcon}
                onClick={handleBluetoothConnectionFailed}
              ></SvgIcon>
            </div>
          );
        }
      }
    };

    const handleCloseModal = () => {
      if (bluetoothAutoConnect.value && !bluetoothDeviceSelected) {
        setPageIndex(0);
        clearInterval(retriesNumId.current);
        bluetoothAutoConnect.setFalsy();
        return;
      }
      if (
        !bluetoothDeviceSelected &&
        DeviceSelected?.value != FilterMode.GemCamOnly &&
        !DeviceSelected?.selected
      )
        onClose?.();
      setClose();
    };

    const handleSkip = () => {
      if (currentDevice) {
        runInAction(() => {
          ExtendedDeviceKindMap.map((m) => {
            m.selected = false;
            m.device = null;
          });
          currentDevice.selected = true;
          deviceListModalStore.setDeviceSelectedStorage(currentDevice);
        });
      }

      setClose();
    };

    const handleAutoConnect = () => {
      if (bluetoothAutoConnect.value) {
        if (bluetoothDeviceSelected) {
          setClose();
          return;
        } else if (cameraStore.filterValue === 5) {
          deviceListModalStore.setDeviceSelected(
            ExtendedDeviceKindMap.find((m) => m.value === cameraStore.filterValue) ?? null,
          );
          setClose();
          return;
        }
      } else {
        return;
      }

      const identifier = localStorage.getItem(BLUETOOTH_DEVICE_IDENTIFIER);
      if (!identifier) {
        bluetoothAutoConnect.setFalsy();
        return;
      }
      cameraStore.getBuletoothDeviceList();
      retriesNumId.current = setInterval(() => {
        setRetriesNum((prevCount) => prevCount + 1);
        if (
          !bluetoothAutoConnect.value ||
          latestRetriesNum.current >= BLUETOOTH_CONNECTION_TIMEDOUT
        ) {
          clearInterval(retriesNumId.current);
          return;
        }

        if (cameraStore.bluetoothDeviceSelected) {
          clearInterval(retriesNumId.current);
          setClose();
          return;
        }

        if (identifier) {
          cameraStore.connectBuletoothDevice(identifier);
        }
      }, 1000 * 1);
    };

    useDidMount(() => {
      if (DeviceSelected) {
        setCurrentDevice(DeviceSelected);
      } else if (deviceSelectedStorage) {
        setCurrentDevice(deviceSelectedStorage);
      }
      if (!DeviceSelected) {
        setCurrentDevice(ExtendedDeviceKindMap.find((m) => m.value === cameraStore.filterValue));
      }
      handleAutoConnect();
      if (openScan) {
        cameraStore.getBuletoothDeviceList();
      }
    });

    useDidUpdate(() => {
      latestRetriesNum.current = retriesNum;
    }, [retriesNum]);

    useDidUpdate(() => {
      const bluetoothDevice = bluetoothDeviceSelected as unknown as DeviceModel;
      runInAction(() => {
        ExtendedDeviceKindMap.map((devicekind) => {
          if (devicekind.keywords.some((keyword) => bluetoothDevice?.name.includes(keyword))) {
            devicekind.device = bluetoothDevice;
            deviceListModalStore.setDeviceSelected(devicekind);
          } else {
            devicekind.device = null;
          }
        });
      });

      if (bluetoothDeviceSelected) {
        runInAction(() => {
          ExtendedDeviceKindMap.map((m) => (m.selected = false));
        });
        localStorage.setItem(BLUETOOTH_DEVICE_IDENTIFIER, bluetoothDevice.identifier);
        deviceListModalStore.setDeviceSelectedStorage(null);
        if (currentDevice) deviceListModalStore.setDeviceSelected(currentDevice);
        clearInterval(retriesNumId.current);
        setClose();
      }
    }, [bluetoothDeviceSelected && bluetoothDeviceSelected.name]);

    useDidUpdate(() => {
      if (pageIndex === 0 && !bluetoothDeviceSelected) {
        runInAction(() => {
          ExtendedDeviceKindMap.map((m) => (m.device = null));
        });
      }
    }, [pageIndex, bluetoothAutoConnect.value]);

    return (
      <Modal
        scrollWrapperClassName={styles.modalScrollContent}
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        disableBackdropClose
        name="device-list-modal"
        data-cy="device-list-modal"
        onFinalClosed={() => modalsStore.remove("DeviceListModal")}
      >
        {bluetoothAutoConnect.value && retriesNum < 6 && (
          <div className={styles.reconnectingWrapper}>
            <div className={styles.reconnectingTitleContainer}>
              <PuffLoader size={24} className={styles.actionIcon}></PuffLoader>
              <span>{localeStore.t('camera["device-list-modal"]["reconnecting-device"]')}</span>
            </div>
            <SvgIcon
              className={styles.closeBtn}
              icon={CloseSVG}
              size={28}
              onClick={handleCloseModal}
            />
          </div>
        )}
        {bluetoothAutoConnect.value === false && (
          <div className={styles.deviceListWrapper}>
            <div className={styles.deviceListTitle}>
              {pageIndex === 0 ? (
                <span className={styles.title}>
                  {localeStore.t('camera["device-list-modal"]["select-device"]')}
                </span>
              ) : (
                <div className={styles.titleContainer}>
                  <SvgIcon
                    icon={ArrowRightSVG}
                    size={36}
                    className={styles.arrowLieft}
                    onClick={() => handleBack()}
                  ></SvgIcon>
                  <span className={styles.title}>
                    {localeStore.t('camera["device-list-modal"].bluetooth')}
                  </span>
                </div>
              )}
              {(pageIndex !== 1 ||
                (pageIndex === 1 && currentDevice?.selected) ||
                bluetoothDeviceSelected) && (
                <Button
                  className={styles.closeBtn}
                  appearance="unset"
                  disabled={
                    !bluetoothDeviceSelected &&
                    DeviceSelected?.value != FilterMode.GemCamOnly &&
                    !DeviceSelected?.selected
                  }
                  onClick={handleCloseModal}
                >
                  {!bluetoothDeviceSelected &&
                  DeviceSelected?.value != FilterMode.GemCamOnly &&
                  !DeviceSelected?.selected ? (
                    <Info
                      className={styles.infoClose}
                      toolTipClassName={styles.toolTipClose}
                      appearance="promo"
                      position="bottom"
                      size={28}
                      withAngle
                      icon={CloseSVG}
                    >
                      {localeStore.t('camera["device-list-modal"]["info-close"]')}
                    </Info>
                  ) : (
                    <SvgIcon icon={CloseSVG} size={28} />
                  )}
                </Button>
              )}
            </div>
            <div
              className={clsx(styles.dialogContent, globalStyles.addScrollStyles, {
                [styles.skipShow]: pageIndex === 1 && !bluetoothDeviceSelected,
              })}
            >
              {renderPage(pageIndex)}
            </div>
            {pageIndex === 1 && !bluetoothDeviceSelected && (
              <Button className={styles.skipBtn} onClick={handleSkip}>
                {localeStore.t('camera["device-list-modal"].skip')}
              </Button>
            )}
          </div>
        )}
        {bluetoothAutoConnect.value && retriesNum >= 6 && (
          <div className={styles.retryWrapper}>
            <SvgIcon icon={ConnFailedSVG} size={72}></SvgIcon>
            <span className={styles.retryTitle}>
              {localeStore.t('camera["device-list-modal"]["connection-lost"]')}
            </span>
            <span className={styles.retryDesc}>
              {localeStore.t('camera["device-list-modal"]["connection-lost-sub"]')}
            </span>
            <div className={styles.btnWrapper}>
              <Button
                className={styles.cancelBtn}
                onClick={() => {
                  handleSkip();
                }}
              >
                {localeStore.t('camera["device-list-modal"]["cancel"]')}
              </Button>
              <Button
                className={styles.retryBtn}
                onClick={() => {
                  bluetoothAutoConnect.setFalsy();
                }}
              >
                {localeStore.t('camera["device-list-modal"]["select-device"]')}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  },
);
