import React, { useState } from "react";
import { SvgIcon, UseBooleanReturnType, useFetch } from "@gemlightbox/core-kit";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { useStores } from "src/hooks";
import { config } from "src/config/environment";
import { ReactComponent as CloseSVG } from "src/external-ts/assets/images/camera/tutorial-close-icon.svg";

import { getCountryCode } from "src/api";

import styles from "./tutorials-modal.module.css";

const BootstrapDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "16px",
  },
  ".MuiDialog-paper": {
    maxWidth: "896px",
    width: "896px",
  },
  ".MuiDialogContent-root": {
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  ".MuiDialogTitle-root": {
    padding: "4px 0",
  },
}));

type TutorialsModalProps = {
  isOpenTutorial: UseBooleanReturnType;
  onClose: VoidFunction;
};

export const TutorialsModal: React.FC<TutorialsModalProps> = ({ isOpenTutorial, onClose }) => {
  const { localeStore } = useStores();
  const { loading, onFetchSuccess } = useFetch(getCountryCode);
  const [isCN, setCountry] = useState(false);
  const videoSrc = isCN
    ? `${config.staticURL}/static/tutorial-video.mp4`
    : "https://www.youtube-nocookie.com/embed/o9oTMFVCsPo?si=AW2JCoRtT1n1ka15&amp;controls=0&autoplay=1&fs=0&loop=1";

  onFetchSuccess((ret) => {
    setCountry(ret.country.isoCode === "CN");
  });

  return (
    <BootstrapDialog open={isOpenTutorial.value} maxWidth="xs">
      <DialogTitle className={styles.dialogHeader}>
        <SvgIcon className={styles.closeButton} icon={CloseSVG} size={32} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        {!loading && (
          <div className={styles.dialogContent}>
            {isCN ? (
              <video autoPlay loop className={styles.tutorialsVideo}>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div className={styles.tutorialsVideo}>
                <iframe
                  className={styles.youtubeIframe}
                  width="100%"
                  src={videoSrc}
                  title="Gemcam setup"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            )}
            <div className={styles.tutorialsDesc}>
              {localeStore.t('camera["camera-controls"]["tutorial-guide"]')}
            </div>
          </div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};
