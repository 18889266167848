import React from "react";

import ProgressLine from "src/components/ProgressLine";
import { useStores } from "src/hooks";
import IntegrationStatusLogo from "../IntegrationStatusLogo";
import IntegrationStatusOptions from "../IntegrationStatusOptions";
import { SvgIcon, Typography, clsx, useWindowSize } from "@gemlightbox/core-kit";
import styles from "./styles.module.scss";

const IntegrationStatusSyncingState = ({
  logo,
  title,
  completedAmount,
  errorAmount,
  totalAmount,
  progress,
  options,
  onOptionClick,
}) => {
  const { localeStore } = useStores();
  const { windowWidth } = useWindowSize();

  const renderProgressBar = () => (
    <div className={clsx("integration-status-progress", styles.progress)}>
      <div className="integration-status-progress__header">
        <div className="left">
          <div className="label">
            {completedAmount + errorAmount}/{totalAmount}{" "}
            {localeStore.t(
              'settings.integrations["integration-status-bar"]["integration-status-syncing-state"].items',
            )}
          </div>
        </div>
        <div className="right">
          <div className="label percent"> {progress}% </div>
        </div>
      </div>

      <div className="integration-status-progress__body">
        <ProgressLine progress={progress} />
      </div>
    </div>
  );

  return (
    <>
      {windowWidth <= 840 && (
        <div className={styles.title}>
          <SvgIcon icon={logo} size={36} />
          <Typography size="medium600">{title}</Typography>
        </div>
      )}
      {windowWidth > 840 && <IntegrationStatusLogo src={logo} />}

      <div className={clsx("integration-status-data fixed-width", styles.desc)}>
        {windowWidth > 840 ? (
          <>
            <div className="integration-status-data__title label"> {title} </div>
            <div className="integration-status-data__description label">
              {localeStore.t(
                'settings.integrations["integration-status-bar"]["integration-status-syncing-state"].subtitle',
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles.textWrapper}>
              <div className={clsx("integration-status-data__description label", styles.text)}>
                {localeStore.t(
                  'settings.integrations["integration-status-bar"]["integration-status-syncing-state"].subtitle',
                )}
              </div>
              {renderProgressBar()}
            </div>
            <IntegrationStatusOptions options={options} onOptionClick={onOptionClick} />
          </>
        )}
      </div>

      {windowWidth > 840 && (
        <>
          {renderProgressBar()}
          <IntegrationStatusOptions options={options} onOptionClick={onOptionClick} />
        </>
      )}
    </>
  );
};

export default IntegrationStatusSyncingState;
