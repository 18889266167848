import React from "react";
import { observer } from "mobx-react-lite";
import {
  Heading,
  Typography,
  Input,
  DateMeasure,
  CoreKitDate,
  getDaysInMonth,
  clsx,
  useQuery,
} from "@gemlightbox/core-kit";

import { formatDate } from "src/common/helpers/subscriptions.helpers";
import { useStores } from "src/hooks";
import { BillingInfoItem } from "../billing-info-item";

import styles from "../../billing-modal.module.css";

export interface BillingMonthlyProps {
  coupon: string;
  monthlyPrice: number;
  yearlyToMonthly: boolean;
  upgrading: boolean;
  onCouponChange: (value: string) => void;
  promoCodeError?: string;
  promoCodeRequired?: boolean;
  activateProPlanPage?: boolean;
}

export const BillingMonthly: React.FC<BillingMonthlyProps> = observer(
  ({
    coupon,
    monthlyPrice,
    yearlyToMonthly,
    upgrading,
    onCouponChange,
    promoCodeError = "",
    promoCodeRequired,
    activateProPlanPage,
  }: BillingMonthlyProps) => {
    const { localeStore, subscriptionsStore, userStore } = useStores();
    const isFreeTierAvailable = userStore.isFreeTierAvailable;

    const {
      query: { bluetooth_name },
    } = useQuery();

    const trial = +new CoreKitDate().addDays(14);

    const getNextPaymentDate = (startDate: number) => {
      const coreDate = new CoreKitDate(startDate);
      if (coreDate.getDayOfMonth() === 1) return coreDate.valueOf();
      return coreDate.addMonths(1).setDay(1).valueOf();
    };

    const calculatePrice = () => {
      const now = Date.now();

      const startDate = isFreeTierAvailable ? trial : now;
      const paymentDate = isFreeTierAvailable ? trial : getNextPaymentDate(trial);

      const timeDiff = (paymentDate - startDate) / 1000;

      const daysInMonth = getDaysInMonth(startDate);
      const secondsInMonth = daysInMonth * (DateMeasure.day / 1000);

      const pricePerSeconds = monthlyPrice / secondsInMonth;

      const res = pricePerSeconds * timeDiff;

      return res.toFixed(2);
    };

    return (
      <div className={styles.rightContainer}>
        <div className={styles.rightContent}>
          <Heading className={styles.leftHeader} tag="h3" color="textSecondary">
            US${monthlyPrice}/month
          </Heading>

          <div className={styles.billingInfoContainer}>
            {!activateProPlanPage && isFreeTierAvailable && (
              <BillingInfoItem
                content={
                  <div className={styles.freeTrialTextContainer}>
                    <Typography
                      className={styles.offerItemName}
                      size="extraSmall"
                      color="textSecondary"
                    >
                      {localeStore.t(
                        'settings.subscriptions.components["billing-modal"].components["billing-monthly"]["trial-ends"]',
                      )}{" "}
                    </Typography>
                    <Typography size="extraSmall500" color="textSecondary">
                      {formatDate(trial)}
                    </Typography>
                  </div>
                }
              />
            )}
            {upgrading && (
              <>
                <BillingInfoItem
                  content={
                    <Typography
                      className={styles.offerItemName}
                      size="extraSmall"
                      color="textSecondary"
                    >
                      {localeStore.t(
                        'settings.subscriptions.components.content["lifetime-offer"]["today-charge"].first',
                      )}{" "}
                      US${subscriptionsStore.formattedBillingInfo?.current_amount_due ?? "--"}{" "}
                      {localeStore.t(
                        'settings.subscriptions.components.content["lifetime-offer"]["today-charge"].today',
                      )}{" "}
                      ({localeStore.t("settings.subscriptions.components.content.balance")}).
                    </Typography>
                  }
                />
                <BillingInfoItem
                  content={
                    <Typography
                      className={styles.offerItemName}
                      size="extraSmall"
                      color="textSecondary"
                    >
                      {localeStore.t(
                        'settings.subscriptions.components["billing-modal"].components["billing-monthly"].subsequently',
                      )}{" "}
                      US${monthlyPrice}
                      {"."}
                      <span className={styles.nextDate}>
                        {localeStore.t(
                          'settings.subscriptions.components["billing-modal"].components["next-billing"]',
                        )}{" "}
                        {subscriptionsStore.formattedBillingInfo
                          ? formatDate(subscriptionsStore.formattedBillingInfo.next_billing_date)
                          : formatDate(getNextPaymentDate(trial))}
                      </span>
                    </Typography>
                  }
                />
              </>
            )}
            {yearlyToMonthly && !upgrading && (
              <>
                <BillingInfoItem
                  content={
                    <Typography
                      className={styles.offerItemName}
                      size="extraSmall"
                      color="textSecondary"
                    >
                      {localeStore.t(
                        'settings.subscriptions.components["billing-modal"].components["billing-monthly"]["yearly-to-monthly"]',
                      )}
                    </Typography>
                  }
                />
                <BillingInfoItem
                  content={
                    <Typography
                      className={styles.offerItemName}
                      size="extraSmall"
                      color="textSecondary"
                    >
                      {localeStore.t(
                        'settings.subscriptions.components["billing-modal"].components["billing-monthly"].subsequently',
                      )}{" "}
                      US${monthlyPrice}
                      {"."}
                      <span className={styles.nextDate}>
                        {localeStore.t(
                          'settings.subscriptions.components["billing-modal"].components["next-billing"]',
                        )}{" "}
                        {formatDate(getNextPaymentDate(trial))}
                      </span>
                    </Typography>
                  }
                />
              </>
            )}
            {!yearlyToMonthly && !upgrading && (
              <>
                <BillingInfoItem
                  content={
                    <Typography
                      className={clsx(styles.offerItemName, styles.oneLine)}
                      size="extraSmall"
                      color="textSecondary"
                    >
                      {localeStore.t(
                        'settings.subscriptions.components["billing-modal"].components["billing-yearly"]["today-charge"].first',
                      )}{" "}
                      ${subscriptionsStore.formattedBillingInfo?.current_amount_due ?? "--"}{" "}
                      {localeStore.t(
                        'settings.subscriptions.components["billing-modal"].components["billing-yearly"]["today-charge"].middle',
                      )}{" "}
                      ({localeStore.t("settings.subscriptions.components.content.balance")}).
                    </Typography>
                  }
                />
                <BillingInfoItem
                  content={
                    <Typography
                      className={clsx(styles.offerItemName, styles.oneLine)}
                      size="extraSmall"
                      color="textSecondary"
                    >
                      {localeStore.t(
                        'settings.subscriptions.components["billing-modal"].components["billing-monthly"].subsequently',
                      )}{" "}
                      US${monthlyPrice}
                      {"."}
                    </Typography>
                  }
                />
              </>
            )}
          </div>
        </div>

        {(promoCodeRequired || !subscriptionsStore.isSubscribed) && !bluetooth_name && (
          <Input
            className={styles.promoCodeContainer}
            label={localeStore.t(
              'settings.subscriptions.components["billing-modal"]["promo-code"].label',
            )}
            placeholder={localeStore.t(
              'settings.subscriptions.components["billing-modal"]["promo-code"].placeholder',
            )}
            appearance="primaryV2"
            value={coupon}
            error={promoCodeError}
            onChange={onCouponChange}
            disableError={!promoCodeRequired}
          />
        )}
      </div>
    );
  },
);

export default BillingMonthly;
