import { Vec2, Mat3, makeAutoBindFunctions, throttle } from "@gemlightbox/core-kit";
import { Camera2d } from "../camera-2d";
import { CameraRenderer } from "../camera.renderer";

export class EventsManager {
  private get _canvas() {
    return this._renderer.canvas;
  }

  private get _camera() {
    return this._renderer.camera;
  }

  private _startCamTransform = Vec2.zero();
  private _startMove = Vec2.zero();
  private _startInvViewProjMat = Mat3.identity();

  constructor(private readonly _renderer: CameraRenderer) {
    makeAutoBindFunctions(this);
  }

  public registerListeners() {
    this._canvas.addEventListener("mousedown", this._handleMouseDown);

    window.addEventListener("keydown", this._handleWindowKeyDown);

    this._canvas.addEventListener("touchstart", this._handleTouchStart);
    this._canvas.addEventListener("touchmove", this._handleTouchMove);

    window.addEventListener("wheel", this._handleWindowWheel, { passive: false });
    this._canvas.addEventListener("wheel", this._handleWheel);
    this._canvas.addEventListener("contextmenu", this._handleContextMenu);
  }

  public unregisterListeners() {
    this._canvas.removeEventListener("mousedown", this._handleMouseDown);

    window.removeEventListener("keydown", this._handleWindowKeyDown);

    this._canvas.removeEventListener("touchstart", this._handleTouchStart);
    this._canvas.removeEventListener("touchmove", this._handleTouchMove);

    window.removeEventListener("wheel", this._handleWindowWheel);
    this._canvas.removeEventListener("wheel", this._handleWheel);
    this._canvas.removeEventListener("contextmenu", this._handleContextMenu);
  }

  private _handleDownStart(e: MouseEvent | TouchEvent) {
    this._startInvViewProjMat = this._camera.viewProjection.invert();

    const clipSpace = Camera2d.getClipSpacePosition(
      this._renderer.canvas.clientWidth,
      this._renderer.canvas.clientHeight,
      this._camera.getCSSMousePosition(e),
    );
    this._startMove.copy(clipSpace.transformMat3Det(this._startInvViewProjMat.mat));
    this._startCamTransform.copy(this._camera.translation);
  }
  private _handleMove(e: MouseEvent | TouchEvent) {
    const clipSpace = Camera2d.getClipSpacePosition(
      this._renderer.canvas.clientWidth,
      this._renderer.canvas.clientHeight,
      this._camera.getCSSMousePosition(e),
    );
    const pos = clipSpace.transformMat3Det(this._startInvViewProjMat.mat);

    const diff = this._startMove.clone().sub(pos);

    const newTransform = this._startCamTransform.clone().add(diff);

    this._camera.setTranslation(newTransform);
  }

  // Mouse events
  private _handleMouseDown(e: MouseEvent | TouchEvent) {
    this._handleDownStart(e);
    window.addEventListener("mousemove", this._handleMouseMove);
    window.addEventListener("mouseup", this._handleMouseUp);
  }

  private _handleMouseMove(e: MouseEvent) {
    this._handleMove(e);
  }

  private _handleMouseUp() {
    window.removeEventListener("mousemove", this._handleMouseMove);
    window.removeEventListener("mouseup", this._handleMouseUp);
  }

  // Touch events
  private _handleTouchStart(e: TouchEvent) {
    e.preventDefault();
    this._handleDownStart(e);
  }

  private _handleTouchMove(e: TouchEvent) {
    this._handleMove(e);
  }

  // Keyboard events
  private _handleWindowKeyDown(e: KeyboardEvent) {
    if (
      e.ctrlKey &&
      (e.code === "Equal" ||
        e.code === "Minus" ||
        e.code === "NumpadSubtract" ||
        e.code === "NumpadAdd")
    ) {
      e.preventDefault();
    }
  }

  // Wheel events
  private _handleWheelScale = throttle((e: WheelEvent) => {
    this._camera.rescale(e);
  }, 75);

  private _handleWindowWheel(e: WheelEvent) {
    e.ctrlKey && e.preventDefault();
  }

  private _handleWheel(e: WheelEvent) {
    e.preventDefault();

    if (e.ctrlKey || e.metaKey) {
      this._handleWheelScale(e);
      return;
    }

    const moveY = e.shiftKey ? 0 : e.deltaY;
    const moveX = e.shiftKey ? e.deltaX || e.deltaY : e.deltaX || 0;
    this._camera.translate(moveX, moveY, true);
  }

  // Misc events
  private _handleContextMenu(e: MouseEvent) {
    e.preventDefault();
  }
}
