import React, { Fragment, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { FacebookProvider } from "react-facebook";
import {
  ApiRequest,
  EmptyObjectType,
  ExtractApiResponse,
  Nullable,
  ProgressCircle,
  useDidMount,
} from "@gemlightbox/core-kit";

import { ExtendedMediaModel, ExtendedProductModel } from "src/store";
import { generatePost, GeneratePostResponseData } from "src/api";
import WSConnection from "src/common/socket";
import { useStores } from "src/hooks";
import { ManualPostForm } from "./manual-post-form";
import { PostPreview } from "./post-preview/post-preview";

import styles from "./generate-post-content.module.css";

type GeneratePostContentProps = {
  type: "media" | "product";
  data: ExtendedMediaModel | ExtendedProductModel;
  setClose: VoidFunction;
};

export const GeneratePostContent: React.FC<GeneratePostContentProps> = observer(
  ({ setClose, type, data }) => {
    const { userStore, productsStore } = useStores();

    const [product, setProduct] = useState<Nullable<ExtendedProductModel>>();
    const [progress, setProgress] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [isReadyToPublish, setReadyToPublish] = useState(false);
    const [initialPost, setInitialPost] = useState("");

    const requestIdRef = useRef("");
    const requestRef = useRef<Nullable<ApiRequest<ExtractApiResponse<typeof generatePost>>>>(null);

    const onRequestStart = (
      request: Nullable<ApiRequest<GeneratePostResponseData, undefined, EmptyObjectType>>,
    ) => {
      setLoading(true);
      requestRef.current = request;
      request!.events.on("requestStart", ({ headers }) => {
        requestIdRef.current = headers["x-request-id"];
      });
    };

    const onRequestEnd = (post: string) => {
      setInitialPost(post);
      setReadyToPublish(true);
      setLoading(false);
    };

    useDidMount(async () => {
      const formData = new FormData();

      if (type === "media") {
        const media = data as ExtendedMediaModel;

        const productToSet = await productsStore.getProductById(media.sku_id!);
        setProduct(productToSet as ExtendedProductModel);

        if (media.type !== "image") return;

        setLoading(true);
        const blob = await fetch(media.file.original).then((result) => result.blob());

        formData.append("file", blob);
        formData.append("media_id", "");
        formData.append("title", "");
        formData.append("occasion", "");
        formData.append("promotion", "");
      }

      if (type === "product") {
        const product = data as ExtendedProductModel;
        setProduct(product);
        const media = product.images.find((media) => media.type === "image");

        if (media) {
          setLoading(true);
          const blob = await fetch(media.file.original).then((result) => result.blob());

          formData.append("file", blob);
          formData.append("media_id", "");
          formData.append("title", "");
          formData.append("occasion", "");
          formData.append("promotion", "");
        }

        if (!media) return;
      }

      const request = generatePost.getRequest({ data: formData });

      onRequestStart(request);

      const requestResult = await request.fetch();

      if (requestResult.success) {
        onRequestEnd(requestResult.success.gpt_post_1);
      }
    });

    useDidMount(() => {
      const userId = userStore.userMe?.user._id;

      if (!userId) return;

      const progressWS = new WSConnection(`/user/${userId}`, userStore.token);

      progressWS.on("progress/UPDATE", ({ payload }: any) => {
        if (requestIdRef.current === payload.requestID) {
          setProgress(payload.progress);
        }
      });

      return () => {
        progressWS.destroy();
      };
    });

    return (
      <Fragment>
        {isLoading && <ProgressCircle className={styles.progress} progress={progress} />}
        {!isLoading && !isReadyToPublish && (
          <ManualPostForm
            setClose={setClose}
            onRequestStart={onRequestStart}
            onRequestEnd={onRequestEnd}
          />
        )}
        {!isLoading && isReadyToPublish && (
          <FacebookProvider appId="545600097660237">
            <PostPreview data={product!} initialPost={initialPost} />
          </FacebookProvider>
        )}
      </Fragment>
    );
  },
);
