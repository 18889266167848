import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import { ExpandableStep } from "../expandable-step";

import styles from "../steps-list.module.css";

export interface Step2Props {
  completed: boolean;
  initialOpen: boolean;
  isOpen: boolean;
  onProceed: VoidFunction;
}

export const Step2: React.FC<Step2Props> = observer(
  ({ initialOpen, isOpen, completed, onProceed }) => {
    const { localeStore } = useStores();

    return (
      <ExpandableStep
        title={localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-2"].title',
        )}
        checkboxLabel={localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-2"]["checkbox-label"]',
        )}
        checked={completed}
        initialOpen={initialOpen}
        isOpen={isOpen}
        onChange={onProceed}
      >
        <div className={styles.label}>
          {localeStore.t(
            'settings["dns-settings"]["second-step"]["steps-list"]["step-2"].subtitle',
          )}
        </div>
      </ExpandableStep>
    );
  },
);
