import React from "react";
import { observer } from "mobx-react-lite";
import { Modal, Heading, Typography, useDidMount } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import {
  ExtendedCatalogFullModel,
  ExtendedCatalogModel,
  ExtendedMediaModel,
  ExtendedProductModel,
} from "src/store";
import { ModalExtendedType } from "src/store/modals/modals.store.types";
import { CatalogModel, MediaCollectionModel } from "src/models";
import { ShareModalTypeKeys } from "../share-modal/share-modal.types";
import { EmbedWidgetContent } from "../share-modal/embed-widget-content";

import styles from "./embed-widget-modal.module.css";

export type EmbedWidgetModalProps = ModalExtendedType<{
  type: ShareModalTypeKeys;
  data:
    | ExtendedMediaModel
    | MediaCollectionModel
    | ExtendedProductModel
    | ExtendedCatalogModel
    | ExtendedCatalogFullModel
    | CatalogModel;
}>;

export const EmbedWidgetModal: React.FC<EmbedWidgetModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }: EmbedWidgetModalProps) => {
    const { mediaStore, productsStore, catalogStore, catalogsStore, localeStore } = useStores();
    useDidMount(() => catalogsStore.loadCatalogList());
    const { data, type } = options;
    const isMedia = type === "media";
    const isProduct = type === "product";
    const isCatalogListItem = type === "catalogListItem";
    const isCatalogFullItem = type === "catalogFullItem";

    const handlePrivacyChange = (isPrivate: boolean) => {
      if (isMedia) {
        const d = data as ExtendedMediaModel;
        mediaStore.updateMediaLinkPrivacyLocal(d, isPrivate);
      }
      if (isProduct) {
        const d = data as ExtendedProductModel;
        productsStore.updateProductsLinkPrivacyLocal(d, isPrivate);
      }
      if (isCatalogListItem) {
        const d = data as ExtendedCatalogModel;
        catalogsStore.updateCatalogLinkPrivacyLocal(d, isPrivate);
      }
      if (isCatalogFullItem) {
        catalogStore.updateCatalogLinkPrivacyLocal(isPrivate);
      }
    };

    const handleCatalogProductsPrivacyChange = (isPrivate: boolean) => {
      if (isCatalogFullItem) {
        catalogStore.updateCatalogProductsLinkPrivacyLocal(isPrivate);
      }
    };

    return (
      <Modal
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        contentClassName={styles.portal}
        disableBorderRadius
        withCross
      >
        <div className={styles.titleContainer}>
          <Heading className={styles.title} tag="h2" color="textSecondary">
            {localeStore.t('components.business["embed-widget-modal"].title')}
          </Heading>
          <div className={styles.subTitleContainer}>
            <Typography size="medium" color="textTertiary">
              {localeStore.t('components.business["embed-widget-modal"].subtitle')}
            </Typography>
            <a href="https://support.picupmedia.com/how-to-embed-gemlightbox-widget-onto-shopify">
              <button className={styles.learnButton}>
                {" "}
                {localeStore.t('components.business["embed-widget-modal"].link')}
              </button>
            </a>
          </div>
        </div>
        <EmbedWidgetContent
          data={data}
          onPrivacyChange={handlePrivacyChange}
          onProductsPrivacyChange={handleCatalogProductsPrivacyChange}
          isIntegration
        />
      </Modal>
    );
  },
);
export default EmbedWidgetModal;
