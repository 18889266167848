import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { FormikProps } from "formik";
import { Button, Form, FormField, Heading, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { StepsBar } from "../steps-bar";
import { DNS_FORM_ID, dnsFormInitialValues } from "./first-step.constants";
import { dnsFormValidation } from "./first-step.utils";
import { DNSFormType } from "./first-step.types";

import styles from "./first-step.module.css";

export type FirstStepProps = {
  onCancel: VoidFunction;
  onProceed: (domain: string) => void;
};

export const FirstStep: React.FC<FirstStepProps> = observer(({ onCancel, onProceed }) => {
  const { localeStore } = useStores();

  const [formInner, setFormInner] = useState<FormikProps<DNSFormType> | null>(null);

  const handleSubmit = (values: DNSFormType) => {
    const domain = `${values.subdomain}.${values.domain}${values.topLevelDomain}`;
    onProceed(domain);
  };

  return (
    <div className={styles.container}>
      <StepsBar className={styles.stepper} currentStep={1} />

      <Heading tag="h4" color="textSecondary">
        {localeStore.t('settings["dns-settings"]["first-step"].title')}
      </Heading>
      <Typography className={styles.description} size="small" color="textTertiary">
        {localeStore.t('settings["dns-settings"]["first-step"].subtitle.text')}
        <span> mywebsite.com </span>
        <a
          target="_blank"
          href="https://support.picupmedia.com/using-your-own-domain-for-sharing-links-from-gemhub"
          rel="noreferrer"
        >
          {localeStore.t('settings["dns-settings"]["first-step"].subtitle.link')}
        </a>
      </Typography>

      <Form
        className={styles.form}
        contentClassName={styles.formContent}
        formId={DNS_FORM_ID}
        initialValues={dnsFormInitialValues}
        onSubmit={handleSubmit}
        innerRef={setFormInner}
        validate={dnsFormValidation}
      >
        <div className={styles.formInnerContent}>
          <Typography size="medium600" color="textSecondary">
            {localeStore.t('settings["dns-settings"]["first-step"]["inner-title"]')}
          </Typography>

          <div className={styles.inputsContainer}>
            <div className={styles.inputs}>
              <div className={styles.inputsRow}>
                <FormField
                  className={styles.subdomain}
                  type="text"
                  name="subdomain"
                  appearance="primaryV2"
                  label={localeStore.t(
                    'settings["dns-settings"]["first-step"].fields.subdomain.label',
                  )}
                  placeholder="my"
                />

                <div>.</div>

                <FormField
                  className={styles.brandDomain}
                  type="text"
                  name="domain"
                  appearance="primaryV2"
                  label={localeStore.t(
                    'settings["dns-settings"]["first-step"].fields.domain.label',
                  )}
                  placeholder="jewelrystore"
                />
              </div>

              <FormField
                className={styles.tld}
                inputWrapperClassName={styles.tldInputWrapper}
                type="text"
                name="topLevelDomain"
                appearance="primaryV2"
                label={localeStore.t(
                  'settings["dns-settings"]["first-step"].fields["top-level-domain"].label',
                )}
                placeholder=".com"
              />
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <Button appearance="primaryOutlined" onClick={onCancel}>
            {localeStore.t('settings["dns-settings"]["first-step"].buttons.cancel')}
          </Button>
          <Button type="submit" disabled={!formInner?.dirty}>
            {localeStore.t('settings["dns-settings"]["first-step"].buttons.next')}
          </Button>
        </div>
      </Form>
    </div>
  );
});
