import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, Nullable, SvgIcon, Tooltip, useBoolean } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getWhatsappLink } from "./enquire-button.utils";

import { ReactComponent as WhatsappSVG } from "src/external-ts/assets/images/whatsapp-grey.svg";
import { ReactComponent as MailSVG } from "src/external-ts/assets/images/mail-grey.svg";
import { ReactComponent as PhoneSVG } from "src/external-ts/assets/images/phone-grey.svg";
import styles from "./enquire-button.module.css";

export type EnquireButtonProps = {
  whatsapp: Nullable<string>;
  mail: Nullable<string>;
  phone: Nullable<string>;
};

export const EnquireButton: React.FC<EnquireButtonProps> = observer(
  ({ whatsapp, mail, phone }: EnquireButtonProps) => {
    const { localeStore } = useStores();

    const whatsappRef = useRef(null);
    const mailRef = useRef(null);
    const phoneRef = useRef(null);

    const active = useBoolean(false);

    const whatsappTooltipOpened = useBoolean(false);
    const mailTooltipOpened = useBoolean(false);
    const phoneTooltipOpened = useBoolean(false);

    const handleWhatsapp = () => window.open(getWhatsappLink(whatsapp), "_blank");
    const handlePhone = () => window.open(`tel:${phone}`, "_self");
    const handleMail = () => window.open(`mailto:${mail}`, "_self");

    if (!whatsapp && !mail && !phone) return null;

    return (
      <>
        {!active.value && (
          <Button className={styles.enquireBtn} appearance="secondary" onClick={active.setTruthy}>
            {localeStore.t("common.buttons.enquire")}
          </Button>
        )}

        {active.value && whatsapp && (
          <>
            <Button
              appearance="success"
              forwardRef={whatsappRef}
              onClick={handleWhatsapp}
              onMouseOver={whatsappTooltipOpened.setTruthy}
              onMouseOut={whatsappTooltipOpened.setFalsy}
            >
              <SvgIcon icon={WhatsappSVG} />
            </Button>

            <Tooltip
              appearance="secondary"
              position="bottom"
              target={whatsappRef}
              onClose={whatsappTooltipOpened.setValue}
              isOpen={whatsappTooltipOpened.value}
              withAngle
            >
              Whatsapp us
            </Tooltip>
          </>
        )}

        {active.value && mail && (
          <>
            <Button
              appearance="secondaryOutlined"
              forwardRef={mailRef}
              onClick={handleMail}
              onMouseOver={mailTooltipOpened.setTruthy}
              onMouseOut={mailTooltipOpened.setFalsy}
            >
              <SvgIcon icon={MailSVG} />
            </Button>

            <Tooltip
              appearance="secondary"
              position="bottom"
              target={mailRef}
              onClose={mailTooltipOpened.setValue}
              isOpen={mailTooltipOpened.value}
              withAngle
            >
              Email us
            </Tooltip>
          </>
        )}

        {active.value && phone && (
          <>
            <Button
              appearance="secondaryOutlined"
              forwardRef={phoneRef}
              onClick={handlePhone}
              onMouseOver={phoneTooltipOpened.setTruthy}
              onMouseOut={phoneTooltipOpened.setFalsy}
            >
              <SvgIcon icon={PhoneSVG} />
            </Button>

            <Tooltip
              appearance="secondary"
              position="bottom"
              target={phoneRef}
              onClose={phoneTooltipOpened.setValue}
              isOpen={phoneTooltipOpened.value}
              withAngle
            >
              Call us
            </Tooltip>
          </>
        )}
      </>
    );
  },
);

export default EnquireButton;
