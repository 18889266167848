import { CountryCode } from "@gemlightbox/core-kit";

import { PersonalInfoType } from "./profile.types";

export const initialValues: PersonalInfoType = {
  avatar: null,
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  website: "",
  country: "US",
  phone: {
    country: "US" as CountryCode,
    phone: "",
  },
};
