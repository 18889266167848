import React, { memo } from "react";
import { Checkbox, SvgIcon, clsx } from "@gemlightbox/core-kit";

import { CardModel } from "src/models";
import { billingCardIconsMap } from "./billing-card-item.constants";

import styles from "./billing-card-item.module.css";

export interface BillingCardItemProps {
  checked: boolean;
  cardItem: CardModel;
  onChange: (card: CardModel, isChecked: boolean) => void;
  className?: string;
  hideCheckbox?: boolean;
  disable?: boolean;
  onDelete?: (card: CardModel) => void;
}

export const BillingCardItem: React.FC<BillingCardItemProps> = memo(
  ({
    checked,
    cardItem,
    onChange,
    className = "",
    hideCheckbox = false,
    disable = false,
    onDelete,
  }: BillingCardItemProps) => {
    const { last4, brand, isDefault } = cardItem;
    const typeIcon = billingCardIconsMap[brand?.toLowerCase()];

    const handleChange = (isChecked: boolean) => onChange(cardItem, isChecked);

    const handleDelete = () => onDelete?.(cardItem);

    return (
      <div className={clsx(styles.cardWrapper, className)}>
        {typeIcon && <SvgIcon size={[56, 36]} icon={typeIcon} />}
        <div className={styles.cardInfoWrapper}>
          <span className={styles.cardInfo}>**** **** **** {last4}</span>
          <span className={styles.typeInfo}>{brand}</span>
        </div>
        <div className={styles.cardActions}>
          {!hideCheckbox && (
            <Checkbox checked={checked} onChange={handleChange} disabled={disable} disableError />
          )}
        </div>
      </div>
    );
  },
);

export default BillingCardItem;
