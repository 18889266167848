import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Range, Typography, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { EraserInfo } from "./eraser-info";

import { ReactComponent as EraserSVG } from "src/external-ts/assets/images/edit-media/eraser.svg";
import styles from "./eraser-section.module.css";

export type EraserSectionProps = {
  size: number;
  onEraserClick: VoidFunction;
  onSizeChange: (value: number) => void;
  onInfoClick: VoidFunction;
};

export const EraserSection: React.FC<EraserSectionProps> = observer(
  ({ size, onEraserClick, onSizeChange, onInfoClick }) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.container}>
        <Typography className={styles.title} size="small600" color="textSecondary">
          {localeStore.t('["ar-media"]["media-editor"]["eraser-section"].title')}
          <EraserInfo onInfoClick={onInfoClick} />
        </Typography>

        <div className={styles.buttons}>
          <Button appearance="secondaryOutlined" onClick={onEraserClick}>
            <SvgIcon icon={EraserSVG} />
            {localeStore.t('["ar-media"]["media-editor"]["eraser-section"].buttons.erase')}
          </Button>
        </div>

        <Range
          className={styles.range}
          label={localeStore.t('["ar-media"]["media-editor"]["eraser-section"]["size-range"]')}
          step={1}
          min={1}
          max={1000}
          value={size}
          onChange={onSizeChange}
          showRangeValue
          disableError
        />
      </div>
    );
  },
);
