import React from "react";
import { ObjectType } from "@gemlightbox/core-kit";

import { ReactComponent as VisaSVG } from "src/external-ts/assets/images/subscription/billing/visa-card.svg";
import { ReactComponent as MastercardSVG } from "src/external-ts/assets/images/subscription/billing/mastercard.svg";
import { ReactComponent as AmericanExpressSVG } from "src/external-ts/assets/images/subscription/billing/american-express-card.svg";

export const billingCardIconsMap: ObjectType<React.FC<React.SVGProps<SVGSVGElement>>> = {
  visa: VisaSVG,
  mastercard: MastercardSVG,
  "american express": AmericanExpressSVG,
};
