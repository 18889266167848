import { config } from "src/config/environment";

export const getMediaLink = (uuid: string, customDomain: string | undefined, isBlink?: boolean) => {
  let result = `${config.galleryURL}/c/${uuid}`;

  if (customDomain) result = `https://${customDomain}/c/${uuid}`;
  if (isBlink) result = result + "?tryMeOn=true";

  return result;
};
