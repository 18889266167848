import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, Link } from "react-router-dom";
import QRCode from "react-qr-code";
import {
  Heading,
  Typography,
  Button,
  uniqId,
  useDidMount,
  useDidUnmount,
  useWindowSize,
  queryStringify,
  useQuery,
} from "@gemlightbox/core-kit";

import WSConnection from "src/common/socket";
import { DEFAULT_PAGE, SIGNUP_PAGE } from "src/constants";
import { Logo } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { SIGN_IN_ERROR, UNIQUE_ID_SEPARATOR } from "./qr-code.constants";
import { QRCodeResponseType } from "./qr-code.types";
import { AuthContainer } from "../auth-container";
import { AuthMain } from "../auth-main";
import { AuthAside } from "../auth-aside/";
import { PromoCarousel } from "../promo-carousel";

import styles from "./qr-code.module.css";

export const QRCodePage = observer(() => {
  const navigate = useNavigate();
  const { query } = useQuery();

  const { windowWidth } = useWindowSize();

  const { localeStore, userStore } = useStores();

  const [connectionId] = useState(uniqId(UNIQUE_ID_SEPARATOR));
  const [qrConnection] = useState(() => new WSConnection("/auth/qr", connectionId));

  const getQRCodeSize = () => {
    let result = 331;

    if (windowWidth < 1440) result = 216;
    if (windowWidth < 768) result = 173;

    return result;
  };

  useDidMount(async () => {
    qrConnection.on("auth/QR_CODE_LOGIN", async (response: QRCodeResponseType) => {
      const { payload } = response;

      if (typeof payload !== "string") {
        const { token, scene } = payload as any;
        if (token && scene) {
          userStore.token = token.split(" ")[1];
          await userStore.loadUserMe();
          switch (scene) {
            case "UPGRADE": {
              navigate("/settings/subscriptions");
              break;
            }
            case "DEFAULT": {
              if (query.r) {
                navigate(query.r);
              } else {
                navigate(DEFAULT_PAGE.path);
              }
              break;
            }
            default: {
              console.error("Unknown scene", scene);
            }
          }
          return;
        }

        const { code, email } = payload as any;
        if (code === SIGN_IN_ERROR) {
          navigate(`/auth/terms-of-service?email=${encodeURIComponent(email)}`);
        }
      } else {
        const [_, token] = payload.split(" ");

        userStore.token = token;
        await userStore.loadUserMe();
        if (query.r) {
          navigate(query.r);
        } else {
          navigate(DEFAULT_PAGE.path);
        }
      }
    });
  });

  useDidUnmount(() => qrConnection.destroy());

  const handleLogin = () => navigate("/auth/login" + queryStringify(query));

  return (
    <AuthContainer>
      <AuthMain>
        <Logo />

        <section className={styles.section}>
          <div className={styles.qrCodeContainer}>
            <QRCode value={connectionId} size={getQRCodeSize()} />
          </div>

          <Heading className={styles.title} tag="h2" color="textSecondary">
            {localeStore.t('auth["qr-code"].title')}
          </Heading>

          <ul className={styles.list}>
            <li>
              <Typography size="small600" color="textSecondary">
                {localeStore.t('auth["qr-code"].instructions.first')}
              </Typography>
            </li>
            <li>
              <Typography size="small600" color="textSecondary">
                {localeStore.t('auth["qr-code"].instructions.second')}
              </Typography>
            </li>
            <li>
              <Typography size="small600" color="textSecondary">
                {localeStore.t('auth["qr-code"].instructions.third')}
              </Typography>
            </li>
          </ul>

          <Button className={styles.button} appearance="primaryOutlined" onClick={handleLogin}>
            {localeStore.t('auth["qr-code"].buttons["sign-in-email"]')}
          </Button>

          <div className={styles.signupContainer}>
            <Typography size="extraSmall" color="textTertiary">
              {localeStore.t('auth["qr-code"]["no-account-text"]')}
            </Typography>
            <Link className={styles.link} to={SIGNUP_PAGE.path + queryStringify(query)}>
              {localeStore.t('auth["qr-code"].buttons["sign-up"]')}
            </Link>
          </div>
        </section>
      </AuthMain>
      <AuthAside>
        <PromoCarousel />
      </AuthAside>
    </AuthContainer>
  );
});

export default QRCodePage;
