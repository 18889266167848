import { ProductsExportType } from "src/models";
import { OptionType } from "./export-all-products-modal.types";

import { ReactComponent as csvExportIcon } from "src/external-ts/assets/images/file-csv-grey.svg";
import { ReactComponent as pdfExportIcon } from "src/external-ts/assets/images/file-grey.svg";
import { ReactComponent as xlsxExportIcon } from "src/external-ts/assets/images/export-xlsx.svg";

export const optionsLabelsMap: Record<ProductsExportType, string> = {
  csv: "CSV",
  pdf: "PDF",
  xlsx: "XLSX",
};

export const exportOptions: OptionType[] = [
  {
    label: "CSV",
    value: ProductsExportType.csv,
    icon: csvExportIcon,
  },
  {
    label: "PDF",
    value: ProductsExportType.pdf,
    icon: pdfExportIcon,
  },
  {
    label: "XLSX",
    value: ProductsExportType.xlsx,
    icon: xlsxExportIcon,
  },
];
