import { ObjectType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { validateEmail } from "src/utils";
import { ConnectPaypalModalForm } from "./connect-paypal-modal.types";

export const connectPaypalModalValidation = (values: ConnectPaypalModalForm) => {
  const { localeStore } = useStores();

  const errors: ObjectType = {};

  if (!values.email) {
    errors.email = localeStore.t("common.errors.required");
  }

  if (values.email && !validateEmail(values.email)) {
    errors.email = localeStore.t('common.errors["email-invalid"]');
  }

  return errors;
};
