import React from "react";
import { SvgIcon } from "@gemlightbox/core-kit";

import { ReactComponent as CheckSVG } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";

import styles from "../billing-modal.module.css";

interface BillingInfoComponentProps {
  content: React.ReactNode;
}

export const BillingInfoItem: React.FC<BillingInfoComponentProps> = ({ content }) => {
  return (
    <div className={styles.offerItemWrapper}>
      <SvgIcon className={styles.checkSVG} icon={CheckSVG} />
      {content}
    </div>
  );
};
