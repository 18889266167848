import React from "react";
import { memo, clsx, SvgIcon } from "@gemlightbox/core-kit";

import { ReactComponent as ArrowRightSVG } from "src/external-ts/assets/images/dashboard-link/arrow-right.svg";
import styles from "./dashboard-link.module.css";

interface DashboardLinkProps {
  label: string;
  name?: string;
  className?: string;
  onClick?: VoidFunction;
}

export const DashboardLink: React.FC<DashboardLinkProps> = memo(
  ({ label, name, className, onClick }) => {
    return (
      <div className={clsx(styles.container, className)} onClick={onClick} data-cy={name}>
        <div className={styles.label}>{label}</div>
        <SvgIcon icon={ArrowRightSVG} size={[24, 16]} />
      </div>
    );
  },
);
