import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import closeImg from "src/common/images/x-close.svg";
import "./index.scss";

type ToastComponentProps = {
  isOpened: boolean;
  children: React.ReactElement;
  onClose: VoidFunction;
  className?: string;
};

const ToastComponent: React.FC<ToastComponentProps> = ({
  children,
  isOpened,
  onClose,
  className,
}) => (
  <div className={clsx("toast", { active: isOpened }, className)}>
    <div className="content">{children}</div>
    <div className="close" onClick={onClose}>
      <img src={closeImg} alt="" />
    </div>
  </div>
);

export default ToastComponent;
