import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import "./index.scss";

const IconBtn = ({ className = "", children, ...props }) => {
  return (
    <div className={clsx("icon-btn shadowed", className)} {...props}>
      {children}
    </div>
  );
};

export default IconBtn;
