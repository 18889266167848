import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Loader } from "@gemlightbox/core-kit";

import IntegrationStatusSetupState from "src/containers/settings/integrations/IntegrationStatusBar/IntegrationStatusSetupState";
import IntegrationStatusSyncingState from "src/containers/settings/integrations/IntegrationStatusBar/IntegrationStatusSyncingState";
import IntegrationStatusCompletedState from "src/containers/settings/integrations/IntegrationStatusBar/IntegrationStatusCompletedState";
import IntegrationStatusSettingsState from "src/containers/settings/integrations/IntegrationStatusBar/IntegrationStatusSettingsState";
import IntegrationsStatusDefaultState from "src/containers/settings/integrations/IntegrationStatusBar/IntegrationsStatusDefaultState";
import IntegrationStatusSettingsSyncingState from "src/containers/settings/integrations/IntegrationStatusBar/IntegrationStatusSettingsSyncingState";
import { useStores } from "src/hooks";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { INTEGRATION_ACTIONS, INTEGRATION_STATUSES, INTEGRATIONS } from "src/constants";
import {
  toggleShopifyErrorsModal,
  startSync,
  toggleShopifyCancelSyncModal,
  toggleShopifyDisconnectModal,
  toggleShopifySetupModal,
  toggleShopifySelectStoreModal,
  loadShopifyStatus,
  selectShopifyStore,
} from "src/redux/reducers/shopify";
import { getIntegrationOptions, getShopifyStoreURL } from "src/common/helpers/integrations.helpers";
import { IntegrationBlock } from "../../integration-block";

import logoImg from "src/containers/settings/integrations/images/shopify-logo.svg";
import "src/containers/settings/integrations/IntegrationStatusBar/index.scss";

const ShopifyStatusBar = observer(({ status }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { localeStore, userStore } = useStores();

  const { isLoading, settings, totalProducts, completedProducts, errorProducts, settingsList } =
    useSelector(({ shopifyReducer }) => shopifyReducer);
  const activeShopifyShop = useSelector(({ userReducer }) => userReducer.activeShopifyShop);

  const options = getIntegrationOptions(status, INTEGRATIONS.SHOPIFY).filter(({ name }) => {
    if (name === INTEGRATION_ACTIONS.ADD_STORE) return !userStore.isShopifyUser;
    return true;
  });

  const shopifyIntegrationPath = getSettingsTab(SettingTabNames.integrations).item.shopify.settings
    .path;

  const isClickable =
    status === INTEGRATION_STATUSES.DEFAULT || status === INTEGRATION_STATUSES.COMPLETED;

  const getSyncProgress = () =>
    Math.ceil(((completedProducts + errorProducts) * 100) / totalProducts);

  const handleOptionClick = (name) => {
    switch (name) {
      case INTEGRATION_ACTIONS.ADD_STORE:
        return dispatch(toggleShopifySetupModal());
      case INTEGRATION_ACTIONS.SELECT_STORE:
        return dispatch(toggleShopifySelectStoreModal());
      case INTEGRATION_ACTIONS.SETTINGS:
        return navigate(shopifyIntegrationPath);
      case INTEGRATION_ACTIONS.STOP_SYNC:
        return dispatch(toggleShopifyCancelSyncModal());
      case INTEGRATION_ACTIONS.REMOVE_INTEGRATION:
        return dispatch(toggleShopifyDisconnectModal());
      case INTEGRATION_ACTIONS.VIEW_ERRORS:
        return dispatch(toggleShopifyErrorsModal({}));
      default:
        return;
    }
  };

  const handleClick = () => navigate(shopifyIntegrationPath);

  const handleStartSync = async () => {
    // params validation
    const selectedParams = {};
    for (let key in settings.selectedParams) {
      if (settings.availableParams[key]) {
        selectedParams[key] = [];
        for (const attr of settings.selectedParams[key]) {
          if (settings.availableParams[key].includes(attr)) {
            selectedParams[key].push(attr);
          }
        }
      }
    }
    const shopUrl = getShopifyStoreURL(activeShopifyShop);
    await dispatch(
      startSync({
        selectedParams,
        shop_url: shopUrl,
      }),
    );
    await dispatch(loadShopifyStatus(shopUrl));
    navigate(getSettingsTab(SettingTabNames.integrations).item.path);
  };

  const handleSetup = () => {
    if (settingsList.length) {
      dispatch(toggleShopifySelectStoreModal());
    } else {
      dispatch(toggleShopifySetupModal());
    }
  };

  const renderSetupState = () => (
    <IntegrationStatusSetupState
      logo={logoImg}
      title="Shopify"
      description={localeStore.t(
        'settings.integrations.shopify["shopify-status-bar"]["setup-state-description"]',
      )}
      link="https://support.picupmedia.com/integrating-with-shopify"
      onSetup={handleSetup}
    />
  );

  const renderSyncingState = () => (
    <IntegrationStatusSyncingState
      logo={logoImg}
      title="Shopify"
      completedAmount={completedProducts}
      errorAmount={errorProducts}
      totalAmount={totalProducts}
      progress={getSyncProgress()}
      options={options}
      onOptionClick={handleOptionClick}
    />
  );

  const renderSyncCompletedState = () => (
    <IntegrationStatusCompletedState
      logo={logoImg}
      title="Shopify"
      options={options}
      onOptionClick={handleOptionClick}
      onClick={isClickable ? handleClick : undefined}
    />
  );

  const renderSettingsState = () => (
    <>
      {status === INTEGRATION_STATUSES.SYNCING ? (
        <IntegrationStatusSettingsSyncingState
          logo={logoImg}
          title="Shopify"
          description={localeStore.t(
            'settings.integrations.shopify["shopify-status-bar"]["settings-syncing-description"]',
          )}
          onCancel={() => toggleShopifyCancelSyncModal()}
        />
      ) : (
        <IntegrationStatusSettingsState
          logo={logoImg}
          title="Shopify"
          description={localeStore.t(
            'settings.integrations.shopify["shopify-status-bar"]["settings-syncing-description"]',
          )}
          onStart={handleStartSync}
        />
      )}
    </>
  );

  const renderDefaultState = () => (
    <IntegrationsStatusDefaultState
      logo={logoImg}
      title="Shopify"
      options={options}
      onOptionClick={handleOptionClick}
      onClick={isClickable ? handleClick : undefined}
    />
  );

  const renderCurrentState = () => {
    switch (status) {
      case INTEGRATION_STATUSES.SETUP:
        return renderSetupState();
      case INTEGRATION_STATUSES.SYNCING:
        return renderSyncingState();
      case INTEGRATION_STATUSES.COMPLETED:
        return renderSyncCompletedState();
      case INTEGRATION_STATUSES.SETTINGS:
        return renderSettingsState();
      default:
        return renderDefaultState();
    }
  };

  useEffect(() => {
    if (activeShopifyShop) {
      dispatch(loadShopifyStatus(getShopifyStoreURL(activeShopifyShop)));
    }
  }, [activeShopifyShop]);
  useEffect(() => {
    if (activeShopifyShop && settingsList.find((item) => item.shop === activeShopifyShop)) {
      dispatch(selectShopifyStore(activeShopifyShop));
    }
  }, [activeShopifyShop, settingsList]);

  return (
    <IntegrationBlock clickable={isClickable}>
      {isLoading && <Loader position="absolute" withOverlay />}
      {renderCurrentState()}
    </IntegrationBlock>
  );
});

export default ShopifyStatusBar;
