import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Heading, SvgIcon, useCall, download, useDidMount } from "@gemlightbox/core-kit";

import { getExportOrders } from "src/api";
import { useStores } from "src/hooks";
import { PaymentsTable } from "./payments-table";

import { ReactComponent as GemlightboxSVG } from "src/external-ts/assets/images/gemlightbox-logo-text.svg";
import { ReactComponent as ArrowSVG } from "src/external-ts/assets/images/arrow-left-grey.svg";

import styles from "./payments.page.module.css";

export const PaymentsPage: React.FC = observer(() => {
  const navigate = useNavigate();

  const { ordersStore, localeStore } = useStores();

  //paypal or stripe
  const paymentSystem = window.location.search.substring(1);

  const useExportOrdersCall = useCall(
    getExportOrders.setQueryParams({ fileType: "csv", paymentSystem }),
  );

  const onOrderClick = (orderId: number) => {
    navigate(`/integration/payments/${orderId}`);
  };

  const handleGoBack = () => navigate(-1);

  const handleExport = async () => {
    const res = await useExportOrdersCall.submit();
    if (res.success) download(URL.createObjectURL(res.success), `export-${new Date()}.csv`);
  };

  useDidMount(() => ordersStore.loadOrderList(paymentSystem));

  return (
    <div className={styles.paymentsPageContainer}>
      <div className={styles.paymentsPageHeader}>
        <SvgIcon icon={GemlightboxSVG} size={[192, 30]} />
        <Button appearance="secondaryGhost" onClick={handleGoBack}>
          <SvgIcon icon={ArrowSVG} /> {localeStore.t("common.buttons.back")}
        </Button>
      </div>
      <div className={styles.paymentsPageTitleContainer}>
        <Heading tag="h1" color="textSecondary">
          {localeStore.t("integrations.payments.title")}
        </Heading>
        <Button appearance="secondary" onClick={handleExport}>
          {localeStore.t('integrations.payments["export-csv"]')}
        </Button>
      </div>
      <PaymentsTable data={ordersStore.orderList} onOrderClick={onOrderClick} />
    </div>
  );
});

export default PaymentsPage;
