import { useLocation } from "react-router-dom";
import { useDidUpdate, isFiniteNumber } from "@gemlightbox/core-kit";

import { pushDataLayerEvent } from "src/utils";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { useStores } from "./use-stores.hook";

export const usePageViewEvent = (): void => {
  const { userStore } = useStores();

  const location = useLocation();
  const { pathname } = location;

  const blackList: string[] = [
    getSettingsTab(SettingTabNames.integrations)?.item.shopify?.settings.path as string,
  ];

  const userId = userStore.userMe?.user?._id;

  useDidUpdate(
    () => {
      const pageName = pathname.match(/[a-zA-Z-]+$/)?.[0];

      if (!isFiniteNumber(userId) || !pageName || blackList.includes(pathname)) return;

      pushDataLayerEvent({
        event: "pageview",
        event_params: {
          user_id: userId,
          page_path: pathname,
          page_name: pageName,
        },
      });
    },
    [userId, pathname],
    true,
  );
};

export default usePageViewEvent;
