import React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, Typography, useDidMount, useDidUpdate } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { CreateVariantsSidebar } from "./create-variants-sidebar";
import { VariantsOptions } from "./variants-options";
import { VariantsTable } from "./variants-table";
import { productVariantsStore } from "./product-variants.store";

import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-thick-grey.svg";
import styles from "./product-variant.module.css";

export const ProductVariant: React.FC = observer(() => {
  const { productId } = useParams<{ productId: string }>();
  const productNumId = Number(productId);

  const { localeStore } = useStores();
  const { canProductVariants, checkVariantsLimit } = useLimits();

  const handleOpenSidebarClick = () => productVariantsStore.openSidebar();

  useDidMount(() => checkVariantsLimit());

  useDidUpdate(
    () => {
      if (canProductVariants) {
        productVariantsStore.getProductVariantOptions(productNumId);
        productVariantsStore.getProductVariants(productNumId);
      }
      return () => productVariantsStore.resetStore();
    },
    [canProductVariants],
    true,
  );

  if (!canProductVariants) return null;

  return (
    <>
      <div className={styles.variantBlockContainer}>
        <Typography size="extraSmall">{localeStore.t('["product-variant"].variants')}</Typography>

        {!productVariantsStore.hasOptions && (
          <Button appearance="primaryOutlined" onClick={handleOpenSidebarClick}>
            <SvgIcon icon={PlusSVG} />
            {localeStore.t('["product-variant"]["create-option"]')}
          </Button>
        )}

        {productVariantsStore.hasOptions && <VariantsOptions />}

        {productVariantsStore.canShowVariantsTable && <VariantsTable />}
      </div>

      {productVariantsStore.isSidebarFinalOpened && <CreateVariantsSidebar />}
    </>
  );
});

export default ProductVariant;
