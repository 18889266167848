import {
  ProductAssignMediasSidebar,
  AddToCatalogSidebar,
  CameraPreviewSidebar,
} from "src/external-ts/pages";
import {
  FiltersSidebar,
  AddCardModal,
  PaymentModal,
  LoginToRapnetModal,
  ConnectPaypalModal,
} from "src/external-ts/components";
import {
  ManageAttributesSidebar,
  CreateAttributeSidebar,
  ProductsImportModal,
  ManageColumnsSidebar,
  NoSKUMediaSidebar,
  ExportAllProductsModal,
  BulkAttributesEditModal,
  DeleteProductModal,
} from "src/containers/products/modals";
import {
  AssignToProductSidebar,
  DeleteMediaModal,
  DeleteTrashModal,
  DownloadMediaModal,
  MediaDetailsSidebar,
  RecoverMediaModal,
  PdfPreviewModal,
  UploadMediaModal,
} from "src/containers/media/modals";
import { FinishImportModal } from "src/containers/products-import/modals";
import { ChangePasswordModal } from "src/containers/settings/modals";
import { LanguageUnfetchModal } from "src/containers/settings/modals/language-unfetch-modal";
import { BillingModal } from "src/containers/settings/subscriptions/components";
import { CreateInvitationLinkModal } from "src/containers/settings/subaccounts/create-invitation-link-modal";
import { ShareModal, DownloadModal } from "src/external-ts/components";
import { EmbedWidgetModal } from "src/external-ts/components/business/embed-widget-modal/embed-widget-modal";
import { TermsOfServiceModal } from "src/external-ts/pages/auth/signup";
import { AIDescriptionBuilderSidebar } from "src/external-ts/components/business/ai-description-builder-sidebar";
import { BatchRemoveBackgroundModal } from "src/external-ts/components/business/batch-remove-background-modal";
import { BatchAIRetouchModal } from "src/external-ts/components/business/batch-ai-retouch-modal";
import { BatchDustRemovalModal } from "src/external-ts/components/business/batch-dust-removal-modal";
import { WellDoneModal } from "src/external-ts/components/business/well-done-modal";
import { ModalsProviderTypeMapping } from "./modals.store.types";
import { CloudStorageFullModal } from "src/external-ts/components/business/cloud-storage-full-modal";
import { NoMediaProductsSidebar } from "src/containers/products/modals/no-media-products-sidebar";
import { LogoWatermarkModal } from "src/external-ts/pages/camera/logo-watermark-modal";
import { LocalSaveSettingsModal } from "src/external-ts/pages/camera/local-save-settings-modal";
import { DeviceListModal } from "src/external-ts/pages/camera/device-list-modal";
import { PendingUploadProductsSidebar } from "src/containers/products/modals/pending-upload-products-sidebar";
import { CustomNotificationModal } from "src/external-ts/components/business/custom-notification-modal";
import { PendingUploadMediaSidebar } from "src/containers/products/modals/pending-upload-media-sidebar";
import { PendingUploadMediaSubSidebar } from "src/containers/products/modals/pending-upload-media-sub-sidebar";
import { GeneratedSkuModal } from "src/external-ts/pages/camera/generated-sku-modal";
import { GeneratedSkuTutorialModal } from "src/external-ts/pages/camera/generated-sku-tutorial-modal";
import { FilterSceneTutorialModal } from "src/external-ts/pages/camera/filter-scene-tutorial-modal";
import { ViewExampleModal } from "src/external-ts/components/business/view-example-modal";
import { CropBannerModal } from "src/external-ts/components/business/crop-banner-modal/crop-banner-modal";
import { ShareSettingModal } from "src/external-ts/components/business/share-setting-modal/share-setting-modal";
import { PrintModal } from "../../external-ts/components/business/print-modal";

export const modalsMap: ModalsProviderTypeMapping = {
  //Camera related:
  CameraPreviewSidebar,
  GeneratedSkuModal,
  GeneratedSkuTutorialModal,
  FilterSceneTutorialModal,

  // Product related:
  ProductsImportModal,
  FinishImportModal,
  ProductAssignMediasSidebar,
  ManageColumnsSidebar,
  NoSKUMediaSidebar,
  AddToCatalogSidebar,
  AIDescriptionBuilderSidebar,
  FiltersSidebar,
  ExportAllProductsModal,
  BulkAttributesEditModal,
  DeleteProductModal,
  NoMediaProductsSidebar,
  PendingUploadProductsSidebar,
  PendingUploadMediaSidebar,
  PendingUploadMediaSubSidebar,

  // Media related:
  AssignToProductSidebar,
  MediaDetailsSidebar,
  UploadMediaModal,
  BatchRemoveBackgroundModal,
  BatchAIRetouchModal,
  BatchDustRemovalModal,
  DownloadMediaModal,
  RecoverMediaModal,
  PdfPreviewModal,
  DeleteMediaModal,
  DeleteTrashModal,
  LogoWatermarkModal,
  LocalSaveSettingsModal,
  DeviceListModal,

  //Settings related:
  ChangePasswordModal,
  CreateInvitationLinkModal,
  LanguageUnfetchModal,
  ShareSettingModal,
  ViewExampleModal,
  CropBannerModal,
  PrintModal,

  // Subscription related:
  AddCardModal,
  PaymentModal,
  BillingModal,

  // Attributes related:
  ManageAttributesSidebar,
  CreateAttributeSidebar,

  // Integrations related:
  EmbedWidgetModal,
  LoginToRapnetModal,
  ConnectPaypalModal,

  // Auth related:
  TermsOfServiceModal,

  // Misc
  ShareModal,
  DownloadModal,
  WellDoneModal,
  CloudStorageFullModal,
  CustomNotificationModal,
};
