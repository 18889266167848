import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, Button, Typography, useDidUpdate } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { unsavedChangesNotification } from "src/utils";
import { editMediaStore } from "../../../edit-media.store";
import { GroupBlock } from "../../group-block";

import { ReactComponent as CropSVG } from "src/external-ts/assets/images/edit-media/crop.svg";
import styles from "./crop.module.css";

export const Crop: React.FC = observer(() => {
  const { localeStore } = useStores();
  const { isInCrop } = editMediaStore;

  const handleOpenCrop = () => editMediaStore.openCrop();

  const handleCancelCrop = () => editMediaStore.closeCrop();

  const handleCrop = () => editMediaStore.handleCrop();

  useDidUpdate(
    () => {
      if (!isInCrop) return;

      return editMediaStore.registerTabBlockCallback(({ unregister, proceed }) => {
        unsavedChangesNotification(() => {
          unregister();
          editMediaStore.closeCrop();
          proceed();
        });

        return true;
      });
    },
    [isInCrop],
    true,
  );

  return (
    <GroupBlock className={styles.cropBlockGroup}>
      <Typography size="small600" color="textSecondary">
        {localeStore.t('["edit-media"].inspector.adjustments.crop.title')}
      </Typography>
      <div className={styles.buttons}>
        {isInCrop && (
          <Fragment>
            <Button appearance="tertiaryOutlined" onClick={handleCancelCrop}>
              {localeStore.t("common.buttons.cancel")}
            </Button>
            <Button onClick={handleCrop}>{localeStore.t("common.buttons.crop")}</Button>
          </Fragment>
        )}
        {!isInCrop && (
          <Button appearance="tertiaryOutlined" onClick={handleOpenCrop}>
            <SvgIcon icon={CropSVG} />
            {localeStore.t("common.buttons.crop")}
          </Button>
        )}
      </div>
    </GroupBlock>
  );
});

export default Crop;
