import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Heading, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import placeholderImg from "src/external-ts/assets/images/dns-settings/dns-placeholder.png";
import styles from "./content-placeholder.module.css";

export type ContentPlaceholderProps = {
  onConfirm: VoidFunction;
};

export const ContentPlaceholder: React.FC<ContentPlaceholderProps> = observer(({ onConfirm }) => {
  const { localeStore } = useStores();

  return (
    <div className={styles.container}>
      <img src={placeholderImg} alt="" />

      <div className={styles.content}>
        <Heading className={styles.title} tag="h2" color="textSecondary">
          {localeStore.t('settings["dns-settings"]["content-placeholder"].title')}
        </Heading>
        <Typography className={styles.subtitle} size="medium" color="textSecondary">
          {localeStore.t('settings["dns-settings"]["content-placeholder"].subtitle')}
        </Typography>
        <Button className={styles.button} onClick={onConfirm}>
          {localeStore.t('settings["dns-settings"]["content-placeholder"].buttons.connect')}
        </Button>
      </div>
    </div>
  );
});
