import React, { useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  base64ToBlob,
  Button,
  clsx,
  loadImage,
  Nullable,
  resizeImage,
  rotateImageTrimmed,
  SvgIcon,
  Tooltip,
  toRadians,
  Heading,
  Typography,
  uniqId,
  useBoolean,
  useDidMount,
  useRerender,
  globalStyles,
  Webgl2New,
  Vec2,
} from "@gemlightbox/core-kit";

import {
  getMedias,
  getUserTagManagerInfoCallback,
  postBlinkMedia,
  putAssignMediaToProduct,
  putBlinkMedia,
  putUpdateProduct,
} from "src/api";
import {
  GlobalImageDataModel,
  MediaModel,
  MediaType,
  ProductModel,
  ProductType,
  ProductTypeMediaModel,
  ProductTypeModel,
} from "src/models";
import { useStores } from "src/hooks";
import { ErrorCodes, MEDIA_PAGE } from "src/constants";
import { getStaticUrl, pushDataLayerEvent } from "src/utils";
import { ArMediaFormType, ArMediaType } from "../ar-media.types";
import { TemplateSelector } from "./template-selector";
import { EraserSection } from "./eraser-section";
import { ProductSelector } from "./product-selector";
import { ControlButtons } from "./control-buttons";
import { ChainTemplateSelector } from "./chain-template-selector";
import { canvasSize, chainTypes } from "./media-editor.constants";
import {
  getARMainImageData,
  GetARMainImageDataReturnType,
  getChainTemplateData,
} from "./media-editor.utils";
import { ProductTypeSelector } from "./product-type-selector";

import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import { ReactComponent as InfoSVG } from "src/external-ts/assets/images/info-grey.svg";
import styles from "./media-editor.module.css";
import { ShareInfoType, useShare } from "src/hooks/use-share.hook";

export type MediaEditorProps = {
  productType: ProductTypeModel;
  data: Nullable<MediaModel>;
  mainImage: HTMLCanvasElement;
  onClose: VoidFunction;
  handleGoBack: (mediaId?: Nullable<number>) => void;
  productTypes: any;
  setProductType: any;
};

export const MediaEditor: React.FC<MediaEditorProps> = observer(
  ({
    productType,
    data,
    productTypes,
    mainImage,
    setProductType,
    onClose,
    handleGoBack,
  }: MediaEditorProps) => {
    const navigate = useNavigate();
    const { handleShare: handleShareMedia } = useShare();

    const { modalsStore, notificationStore, productsStore, mediaStore, localeStore, userStore } =
      useStores();

    const location = useLocation();
    const stateData =
      (location.state as Nullable<ArMediaType>) ??
      ({ media: data, type: "media" } as Nullable<ArMediaType>);
    const redirect = stateData?.redirect;

    const predefinedProductType =
      stateData?.media?.arData?.productType || stateData?.media?.productType;
    const isInEditAR = !!predefinedProductType;

    const editARTemplate = Number(
      stateData?.media?.arData?.arTemplateId || stateData?.media?.arTemplateId,
    );

    const rerender = useRerender();

    const [xFlowId] = useState(uniqId);

    const canvasWrapperRef = useRef<HTMLDivElement>(null);

    const fetchedProductRef = useRef<Nullable<ProductModel>>(null);

    const tutorialTriggerRef = useRef<HTMLButtonElement>(null);

    const hintActive = useBoolean(false);
    const tutorialActive = useBoolean(false);

    const templates = useMemo(() => {
      return productType?.templates.flatMap((template) => template.medias) || [];
    }, [productType]);

    // const product = data?.type === "product" ? data.product : null;
    const product = null;
    const productTypeName = productType?.name;
    const isPendantType = productTypeName === ProductType.pendants;

    const [template, setTemplate] = useState<Nullable<ProductTypeMediaModel>>(() => {
      const editTemplate =
        editARTemplate && templates.find((template) => template.id === editARTemplate);
      return editTemplate || templates[0];
    });

    const [chainTemplates, setChainTemplates] = useState<HTMLImageElement[]>([]);
    const [chainTemplate, setChainTemplate] = useState<Nullable<number>>(null);

    const [renderer] = useState(() => new Webgl2New.Webgl2dRenderer());

    const templateComponentRef = useRef<Webgl2New.ImageRectComponent>(null as any);
    const chainComponentRef = useRef<Webgl2New.ImageRectComponent>(null as any);
    const mainImageComponentRef = useRef<Webgl2New.ImageRectComponent>(null as any);
    const mainImageInitialRotationOffset = useRef(0);

    const [initialMainImageRectData] = useState<GetARMainImageDataReturnType>(() =>
      getARMainImageData(mainImage, canvasSize.width, canvasSize.height),
    );

    const handleInitializeWebgl2 = () => {
      Webgl2New.Texture.defaultPixelTextureColor = [255, 255, 255, 255];

      // Template ↓
      const templateTexture = new Webgl2New.Texture();
      const templateComponent = new Webgl2New.ImageRectComponent(templateTexture);
      templateComponentRef.current = templateComponent;
      templateComponent.transform.setSize(canvasSize);
      templateComponent.style.pointerEvents = "none";
      renderer.addComponent(templateComponent);

      // const template = templates[0];
      if (template) {
        templateTexture.loadSource(template.file.medium).then(() => renderer.render());
      }
      // Template ↑

      // Chain ↓
      const chainPositioning: ReturnType<typeof getChainTemplateData> =
        template?.metaData?.template?.positioning ?? getChainTemplateData();

      const chainTexture = new Webgl2New.Texture();
      const chainComponent = new Webgl2New.ImageRectComponent(chainTexture);
      chainComponentRef.current = chainComponent;
      chainComponent.transform.setSize(chainPositioning.width, chainPositioning.height);
      chainComponent.transform.setTranslation(chainPositioning.x, chainPositioning.y);
      chainComponent.style.pointerEvents = "none";
      chainComponent.style.display = isPendantType ? "initial" : "none";

      const chainPromises = chainTypes.map(({ src }) => loadImage(src, true));
      Promise.all(chainPromises).then((chainTemplates) => {
        setChainTemplates(chainTemplates);
        setChainTemplate(0);
        chainTexture.loadSource(chainTemplates[0]).then(() => renderer.render());
      });
      renderer.addComponent(chainComponent);
      // Chain ↑

      // Main Image ↓
      const editARTranslateX = Number(
        stateData?.media?.arData?.arOffsetX || stateData?.media?.arOffsetX,
      );
      const editARTranslateY = Number(
        stateData?.media?.arData?.arOffsetY || stateData?.media?.arOffsetY,
      );

      const mainImageTexture = new Webgl2New.Texture();
      const mainImageComponent = new Webgl2New.ImageRectComponent(mainImageTexture);
      mainImageComponentRef.current = mainImageComponent;

      if (isInEditAR && editARTranslateX && editARTranslateY) {
        mainImageComponent.transform.calcDimensions({
          position: new Vec2(editARTranslateX, editARTranslateY),
          size: new Vec2(mainImage.width, mainImage.height),
        });
      } else {
        mainImageComponent.transform.setSize(
          initialMainImageRectData.width,
          initialMainImageRectData.height,
        );
        mainImageComponent.transform.setTranslation(
          initialMainImageRectData.x,
          initialMainImageRectData.y,
        );
      }

      mainImageComponent.events.on("rotationOffset", (offset) => {
        mainImageInitialRotationOffset.current += offset;
      });

      mainImageTexture.loadSource(mainImage).then(() => renderer.render());

      renderer.addComponent(mainImageComponent);
      // Main Image ↑

      renderer.selectComponent(mainImageComponent);
    };

    const handleSelectProductType = (item: ProductTypeModel) => {
      const template = item.templates[0].medias[0];
      setProductType(item);
      handleChangeTemplate(template).then(() => {
        const isPendantNewType = item.name === ProductType.pendants;
        chainComponentRef.current.style.display = isPendantNewType ? "initial" : "none";
        renderer.render();
      });
    };

    const handleTutorialTriggerClick = () => {
      hintActive.setFalsy();
      tutorialActive.setTruthy();
    };

    const handleTutorialTriggerMouseEnter = () => {
      if (tutorialActive.value) return;
      hintActive.setTruthy();
    };
    const handleChangeTemplate = async (newTemplate: ProductTypeMediaModel) => {
      if (newTemplate === template) return;

      setTemplate(newTemplate);

      await templateComponentRef.current.state.texture.loadSource(newTemplate.file.medium, false);

      const positioning: ReturnType<typeof getChainTemplateData> =
        newTemplate?.metaData?.template?.positioning ?? getChainTemplateData();

      chainComponentRef.current.transform.setSize(positioning.width, positioning.height);
      chainComponentRef.current.transform.setTranslation(positioning.x, positioning.y);

      renderer.render();
    };

    const handleChainTemplateChange = (index: number) => {
      chainComponentRef.current.state.texture
        .loadSource(chainTemplates[index])
        .then(() => renderer.render());
      setChainTemplate(index);
    };

    const handleEraseBrushClick = () => {
      !renderer.hasSelectedComponent && renderer.selectComponent(mainImageComponentRef.current);

      if (renderer.eventsManager.checkForTool(renderer.tools.eraseTool)) {
        renderer.eventsManager.assignTool(renderer.tools.handSelectionTool);
      } else {
        renderer.eventsManager.assignTool(renderer.tools.eraseTool);
      }
      rerender();
    };

    const handleEraseBrushSize = (value: number) => {
      renderer.tools.eraseTool.brushSize = value;
      rerender();
    };

    const handleConfirmSuccess = (mediaId: Nullable<number>) => {
      if (window.$platform.isCompact) {
        handleGoBack(mediaId);
        return;
      }

      try {
        const mediasRequest = getMedias.getRequest();
        const redirectPath = redirect ?? MEDIA_PAGE.path;
        navigate(redirectPath);
        const isRedirectedFromProductPage = redirect?.includes("/product/");

        mediasRequest.events.on("success", ({ success }) => {
          if (isRedirectedFromProductPage) {
            return;
          }
          const blinkData = mediaId
            ? success.rows.find(({ id }) => id === mediaId)
            : success.rows.find(({ type }) => type === MediaType.blink);
          if (blinkData) {
            modalsStore.open("MediaDetailsSidebar", {
              data: mediaStore.extendMedia(blinkData),
              handleShareMedia: (shareInfoType: ShareInfoType) => {
                handleShareMedia(shareInfoType);
              },
            });
          }
        });
        mediasRequest.fetch();
        // }
      } catch (e) {
        console.error(e);
      }
    };

    const handleSubmit = async (
      values: ArMediaFormType,
      selectedProduct: Nullable<ProductModel>,
    ) => {
      // if (!selectedProduct || !template) return;
      if (!data || !template) return;

      const { sku: productId, width, height, unit } = values;

      try {
        notificationStore.openLoader({
          loaderType: "diamond-loader",
          appearance: "secondary",
          title: localeStore.t('["ar-media"]["media-editor"].loader.title'),
          subtitle: localeStore.t('["ar-media"]["media-editor"].loader.subtitle'),
        });

        const rectsToGet: Webgl2New.Webgl2Component<any, any>[] = [mainImageComponentRef.current];
        if (chainComponentRef.current) rectsToGet.unshift(chainComponentRef.current);

        let finalBlink = renderer.export.getComponentsCast(rectsToGet);

        const editOffsetDegrees = Number(
          stateData?.media?.arData?.arOffsetRotation || stateData?.media?.arOffsetRotation || 0,
        );

        const offsetDegrees = mainImageInitialRotationOffset.current % 360;

        if (!isPendantType) {
          finalBlink = rotateImageTrimmed(
            finalBlink,
            toRadians(-offsetDegrees - editOffsetDegrees),
          );
        }

        const cropImg64 = renderer.export.getCanvasCast().toDataURL();
        const cropDataUrl = cropImg64.split(",")[1];
        const cropBlob = base64ToBlob(cropDataUrl, "image/png");
        const blinkImg64 = finalBlink.toDataURL();
        const blinkDataUrl = blinkImg64.split(",")[1];
        const blinkBlob = base64ToBlob(blinkDataUrl, "image/png");

        const formData = new FormData();

        const centerVec = Webgl2New.Webgl2dRenderer.global.getComponentClipSpaceInGlobal(
          mainImageComponentRef.current,
          Vec2.half(),
        );

        if (width) {
          formData.append("arWidth", String(parseFloat(width)));
          formData.append("arWidthUnit", String(unit));
        }
        if (height) {
          formData.append("arHeight", String(parseFloat(height)));
          formData.append("arHeightUnit", String(unit));
        }
        formData.append("blinkFile", blinkBlob, "blinkFile.png");
        formData.append("cropFile", cropBlob, "cropFile.png");
        formData.append("productTypeId", productType.id.toString());
        formData.append("productType", productTypeName);
        formData.append("arTemplateId", template.id.toString());
        formData.append("arOffsetX", centerVec.x.toString());
        formData.append("arOffsetY", centerVec.y.toString());
        formData.append("arOffsetRotation", offsetDegrees.toString());

        if (isInEditAR) {
          const putBlinkResponse = await putBlinkMedia
            .getRequest({
              params: { mediaId: data.id },
              data: formData,
              headers: { "x-flow-id": xFlowId },
            })
            .fetch();

          if (putBlinkResponse.status !== "success") {
            throw {
              response: {
                data: putBlinkResponse.error.originalError,
              },
            };
          }

          mediaStore.loadMediaList();
          modalsStore.open("WellDoneModal", {
            message: localeStore.t('["ar-media"]["media-editor"]["well-done-modal"].message'),
            type: "media",
            clickHerePreText: localeStore.t(
              '["ar-media"]["media-editor"]["well-done-modal"]["click-here-pre-text"]',
            ),
            data: {
              ...mediaStore.extendMedia(putBlinkResponse.success),
              cropFile: putBlinkResponse.success.arData?.cropFile as GlobalImageDataModel,
            },
            onClose: () => handleConfirmSuccess(putBlinkResponse.success.id),
          });

          return;
        }

        formData.append(
          "metaData",
          JSON.stringify({ ...(data?.metaData || {}), isModelImage: true }),
        );
        const postBlinkResponse = await postBlinkMedia
          .getRequest({
            data: formData,
            headers: { "x-flow-id": xFlowId },
          })
          .fetch();
        if (postBlinkResponse.status !== "success") {
          throw {
            response: {
              data: postBlinkResponse.error.originalError,
            },
          };
        }

        const blinkId = postBlinkResponse.success?.id;

        await putAssignMediaToProduct
          .getRequest({
            params: { productId },
            queryParams: blinkId.toString(),
            headers: {
              "x-flow-id": xFlowId,
            },
          })
          .fetch();

        // NOTE: This is kinda crutch, because when we assign blink to product we change it order to be first in list,
        // hence when we open "ProductEditPage" later -> it will be shown as first main image...
        const mediaIDS = selectedProduct?.images.map(({ id }) => id);
        mediaIDS?.unshift(blinkId);

        const response = await putUpdateProduct
          .getRequest({
            params: { productId },
            data: { productType: productTypeName, mediaIDS },
            queryParams: { ids: [productId] },
            headers: { "x-flow-id": xFlowId },
          })
          .fetch();

        const fetchedProduct = response?.success?.rows.find(
          (product: any) => product._id === productId,
        );
        if (!fetchedProduct) return;

        fetchedProductRef.current = fetchedProduct;
        modalsStore.open("WellDoneModal", {
          message: localeStore.t('["ar-media"]["media-editor"]["well-done-modal"].message'),
          type: "product",
          clickHerePreText: localeStore.t(
            '["ar-media"]["media-editor"]["well-done-modal"]["click-here-pre-text"]',
          ),
          data: productsStore.extendProduct(fetchedProduct),
          onClose: () => handleConfirmSuccess(blinkId),
        });

        productsStore.loadProductsList(undefined, {
          "x-flow-id": xFlowId,
        });
      } catch (e: any) {
        console.error(e);

        const code = e?.response?.data?.code;

        if (
          code === ErrorCodes.MEDIA_BLINK_CREATION_ATTEMPTS_LIMIT_EXCEEDED ||
          code === ErrorCodes.MEDIA_BLINKS_LIMIT_EXCEEDED
        ) {
          notificationStore.open({
            title: localeStore.t(
              '["ar-media"]["media-editor"]["error-modal"].title["error-limit"]',
            ),
            message: e.response.data.message,
            confirmText: localeStore.t("common.buttons.confirm"),
            cancelText: "",
            onlyConfirm: true,
          });
        } else {
          notificationStore.open({
            title:
              e?.response?.data?.message ??
              e?.name ??
              localeStore.t('["ar-media"]["media-editor"]["error-modal"].title.default'),
            confirmText: localeStore.t(
              '["ar-media"]["media-editor"]["error-modal"]["ok-text-back"]',
            ),
            cancelText: "",
            onlyConfirm: true,
            onOk: onClose,
          });
        }
      } finally {
        notificationStore.closeLoader();
      }
    };

    const handleWebgl2Error = (error: any) => {
      const title =
        error?.name ?? localeStore.t('["ar-media"]["media-editor"]["error-modal"].title.default');
      const message =
        error?.message ?? localeStore.t('["ar-media"]["media-editor"]["error-modal"].message');

      // if (error?.code === 11) {
      //   title = localeStore.t('["ar-media"]["media-editor"]["error-modal"].title["error-11]');
      //   message = "";
      // }

      notificationStore.open({
        title,
        message,
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: "",
        confirmAppearance: "primary",
        onlyConfirm: true,
        onFinal: onClose,
      });
    };

    useDidMount(() => {
      if (isInEditAR) {
        mediaStore.loadMediaList();
      }

      Webgl2New.Webgl2.initialize()
        .then(() => {
          renderer.mount(canvasWrapperRef.current, canvasSize);
          handleInitializeWebgl2();
        })
        .catch((error) => {
          console.error("Webgl2 Couldn't initialize Webgl2");
          console.error(error);
          handleWebgl2Error(error);
        })
        .finally(() => rerender());

      return () => renderer.unmount();
    });

    return (
      <>
        <div className={styles.header}>
          <Heading tag="h2" color="textSecondary" data-cy="model-image-page-title">
            {localeStore.t('["ar-media"].title')}
          </Heading>

          <ControlButtons renderer={renderer} />

          <Button
            className={styles.close}
            appearance="secondaryGhost"
            onClick={onClose}
            data-cy="cross-btn"
          >
            <SvgIcon icon={CrossSVG} />
          </Button>
        </div>
        <div className={styles.body}>
          <div className={clsx(styles.controls, globalStyles.addScrollStyles)}>
            <ProductTypeSelector
              productType={productType}
              data={productTypes}
              onChange={handleSelectProductType}
            />
            <TemplateSelector
              template={template}
              templates={templates}
              onChange={handleChangeTemplate}
            />
            {isPendantType && chainTemplates.length !== 0 && (
              <ChainTemplateSelector
                templateIndex={chainTemplate}
                onTemplateIndexChange={handleChainTemplateChange}
              />
            )}

            <EraserSection
              size={renderer.tools?.eraseTool?.brushSize ?? 1}
              onSizeChange={handleEraseBrushSize}
              onEraserClick={handleEraseBrushClick}
              onInfoClick={tutorialActive.setTruthy}
            />

            {mainImageComponentRef.current && (
              <ProductSelector
                data={stateData}
                product={product}
                productType={productType}
                initialMainImageRectData={initialMainImageRectData}
                mainImageComponent={mainImageComponentRef.current}
                renderer={renderer}
                onCancel={handleGoBack}
                onSubmit={handleSubmit}
              />
            )}
          </div>

          <div className={styles.editor}>
            <div className={styles.canvasWrapper} ref={canvasWrapperRef} />

            <Button
              className={styles.button}
              appearance="secondaryOutlined"
              forwardRef={tutorialTriggerRef}
              onClick={handleTutorialTriggerClick}
              onMouseEnter={handleTutorialTriggerMouseEnter}
            >
              <SvgIcon icon={InfoSVG} />
            </Button>

            <Tooltip
              position="left"
              isOpen={hintActive.value}
              target={tutorialTriggerRef}
              onClose={hintActive.setFalsy}
              onMouseOut={hintActive.setFalsy}
              withAngle
            >
              <Typography size="small" color="textSecondary">
                {localeStore.t('["ar-media"]["media-editor"]["view-tutorial-hint"]')}
              </Typography>
            </Tooltip>
            <Tooltip
              position="topLeft"
              className={styles.tutorial}
              isOpen={tutorialActive.value}
              target={tutorialTriggerRef}
              onClose={tutorialActive.setFalsy}
            >
              <Button
                className={styles.closeBtn}
                appearance="secondaryGhost"
                onClick={tutorialActive.setFalsy}
              >
                <SvgIcon icon={CrossSVG} />
              </Button>
              <img src={getStaticUrl("/erase-tutorial.gif")} alt="" />
              <Typography size="small" color="textSecondary">
                {localeStore.t('["ar-media"]["media-editor"]["brush-hint"]')}
              </Typography>
            </Tooltip>
          </div>
        </div>
      </>
    );
  },
);
