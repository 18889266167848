import { PaginationList } from "@gemlightbox/core-kit";

import {
  dashboardApiMiddleware,
  GetProductsFilters,
  PatchCatalogProductOrderRequestData,
} from "src/api";
import { CatalogModel, CatalogFullModel, ProductModel } from "src/models";
import { CatalogFilterByType } from "src/store";
import { PostCreateCatalogData, PostCreateGeneratedCatalogData } from "./catalog.interface";

export type GetCatalogsFilters = {
  name: string;
  page: number;
  limit: number;
  user_id: string | number;
  filterBy: CatalogFilterByType | null;
};

export const getCatalogs = dashboardApiMiddleware<PaginationList<CatalogModel>>()({
  method: "get",
  endpoint: "/api/catalog",
});

export type GetCatalogFilters = Pick<GetProductsFilters, "page" | "limit">;

export const getCatalog = dashboardApiMiddleware<CatalogFullModel>()({
  method: "get",
  endpoint: "/api/catalog/:catalogId",
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
});

export const getLastUntitledCatalogNumber = dashboardApiMiddleware<number>()({
  method: "get",
  endpoint: "/api/catalog/last_untitled_catalog",
});

export const postCreateCatalog = dashboardApiMiddleware<
  PaginationList<CatalogModel>,
  PostCreateCatalogData
>()({
  method: "post",
  endpoint: "/api/catalog",
});

export const postCreateGeneratedCatalog = dashboardApiMiddleware<
  CatalogModel,
  PostCreateGeneratedCatalogData
>()({
  method: "post",
  endpoint: "/api/catalog",
  headers: {
    "index-response": "",
  },
});

export const postAssignProductsToCatalog = dashboardApiMiddleware<{
  assigned: Array<ProductModel["_id"]>;
  unassigned: Array<ProductModel["_id"]>;
}>()({
  method: "post",
  endpoint: "/api/catalog/:catalogId/assign/", // ids. /api/catalog/732/assign/118481,118483,118484,118485
});

export const putReassignProducts = dashboardApiMiddleware<{
  assigned: Array<ProductModel["_id"]>;
}>()({
  method: "put",
  endpoint: "/api/catalog/:fromCatalogId/:toCatalogId/reassign/", // ids.  /api/catalog/732/reassign/118481,118483,118484,118485
});

export const putUpdateCatalog = dashboardApiMiddleware<
  PaginationList<CatalogModel>,
  Partial<PostCreateCatalogData>
>()({
  method: "put",
  endpoint: "/api/catalog/:catalogId",
});

export const deleteCatalog = dashboardApiMiddleware<PaginationList<CatalogModel>>()({
  method: "delete",
  endpoint: "/api/catalog/:catalogId",
});

export const deleteUnassignProductsFromCatalog = dashboardApiMiddleware()({
  method: "delete",
  endpoint: "/api/catalog/:catalogId/unassign/", // ids. Ask about why we pass it thorough query
});

export const patchCatalogProductOrder = dashboardApiMiddleware<
  never,
  PatchCatalogProductOrderRequestData
>()({
  method: "patch",
  endpoint: "/api/catalog/order/:catalogId",
});
