import { extendObservable, isObservable, makeAutoObservable, runInAction, toJS } from "mobx";
import { isEqual, MaybeArray, PAGINATION_OPTIONS, toArrayOptions } from "@gemlightbox/core-kit";

import { deleteTrashBinItems, getTrashBinList, recoverTrashBinItems } from "src/api";
import { ExtendedTrashBinItemModel, TrashBinItemModel } from "src/models";
import {
  GetTrashBinResponseType,
  TrashBinFilters,
  TrashBinFiltersQueryType,
} from "./trash-bin.store.types";

export class TrashBinStore {
  private readonly _initialFilters: TrashBinFilters = {
    page: 1,
    limit: PAGINATION_OPTIONS[0].value,
  };

  private _loading = false;

  private _trashBinList: ExtendedTrashBinItemModel[] = [];

  private _filters: TrashBinFilters = this._initialFilters;

  private _resultsTrashAmount = 0;

  private _totalTrashAmount = 0;

  public get trashBinList() {
    return this._trashBinList;
  }

  public get filters() {
    return this._filters;
  }

  public get totalPages() {
    return Math.ceil(this.totalTrashAmount / (this.filters.limit || this.totalTrashAmount));
  }

  public get totalTrashAmount() {
    return this._totalTrashAmount;
  }

  public get resultsTrashAmount() {
    return this._resultsTrashAmount;
  }

  public get loading() {
    return this._loading;
  }

  public get itemsAmount() {
    return this.trashBinList.length;
  }

  public get selectedItemsList() {
    return this.trashBinList.filter(({ extended: { selected } }) => selected);
  }

  public get selectedItemsAmount() {
    return this.selectedItemsList.length;
  }

  public get isItemSelected() {
    return this.selectedItemsAmount !== 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  public async loadTrashBinList(filters?: TrashBinFilters) {
    if (filters && isEqual(filters, this.filters)) return;

    runInAction(() => {
      this._loading = true;
      if (filters) this.setFilters(filters);
    });

    const request = getTrashBinList.getRequest({
      queryParams: this._getRequestFilters(),
    });
    const { success, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      if (success) {
        this._setTrashBinList(success);
      } else {
        this._totalTrashAmount = 0;
        this._resultsTrashAmount = 0;
        this._trashBinList = [];
      }

      this._loading = false;
    });
  }

  public async deleteItems(items: ExtendedTrashBinItemModel[]) {
    runInAction(() => {
      this._loading = true;
    });

    const ids = items.map((item) => item._id);

    const { details } = await deleteTrashBinItems.getRequest({ data: { ids } }).fetch();

    runInAction(() => {
      if (details.isSuccess) this.loadTrashBinList();

      this._loading = false;
    });
  }

  public async recoverItems(items: ExtendedTrashBinItemModel[]) {
    runInAction(() => {
      this._loading = true;
    });

    const ids = items.map((item) => item._id);

    const { details } = await recoverTrashBinItems.getRequest({ data: { ids } }).fetch();

    runInAction(() => {
      if (details.isSuccess) this.loadTrashBinList();

      this._loading = false;
    });
  }

  public setFilters(filters: TrashBinFilters) {
    this._filters = filters;
  }

  public toggleTrashBinList(
    items: MaybeArray<ExtendedTrashBinItemModel>,
    field: "selected" | "loading",
  ) {
    toArrayOptions(items).forEach((item) => {
      item.extended[field] = !item.extended[field];
    });
  }

  public selectAllTrashBinList(field: "selected" | "loading") {
    this._trashBinList.forEach((item) => {
      item.extended[field] = true;
    });
  }

  public unselectAllTrashBinList(field: "selected" | "loading") {
    this._trashBinList.forEach((item) => {
      item.extended[field] = false;
    });
  }

  public extendTrashBinItem(item: TrashBinItemModel): ExtendedTrashBinItemModel {
    if (isObservable(item)) return item as ExtendedTrashBinItemModel;
    return extendObservable<TrashBinItemModel, ExtendedTrashBinItemModel>(item, {
      ...item,
      extended: {
        selected: false,
        loading: false,
      },
    });
  }

  private _setTrashBinList(response: GetTrashBinResponseType) {
    this._totalTrashAmount = response.total_items;
    this._resultsTrashAmount = response.items.length;
    this._trashBinList = response.items.map((item) => this.extendTrashBinItem(item));
  }

  private _getRequestFilters(): TrashBinFiltersQueryType {
    const filtersCopy: TrashBinFiltersQueryType = toJS(this._filters);

    return filtersCopy;
  }
}
