export enum ModalNavType {
  generatePost = "AI Social Media Post",
  shareLink = "Share Link",
  embedButton = "Embed Button",
  embedCode = "Embed Code",
  embedCatalog = "Embed Website Bottom Menu",
}

export enum ModalType {
  media = "media",
  mediaCollection = "mediaCollection",
  product = "product",
  catalogListItem = "catalogListItem",
  catalogFullItem = "catalogFullItem",
}

export const subscriptionTooltip = "share-modal-subscription-tooltip";
