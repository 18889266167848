import { dashboardApiMiddleware } from "src/api";
import { GetOrdersResponseData, GetSingleOrderResponseData } from "./orders.interface";

export const getOrders = dashboardApiMiddleware<GetOrdersResponseData>()({
  method: "get",
  endpoint: "/api/order",
  queryFormatter: (query) => Object.assign(query),
});

export const getExportOrders = dashboardApiMiddleware<Blob>()({
  method: "get",
  endpoint: "/api/order/export",
  responseType: "blob",
  queryFormatter: (query) => Object.assign(query),
});

export const getSingleOrder = dashboardApiMiddleware<GetSingleOrderResponseData>()({
  method: "get",
  endpoint: "/api/order/:orderId",
});

export const getOrderInvoice = dashboardApiMiddleware<Blob>()({
  method: "get",
  endpoint: "/api/order/invoice/:orderId",
  responseType: "blob",
});

export const postOrderRefund = dashboardApiMiddleware()({
  method: "post",
  endpoint: "/api/order/refund/:orderId",
});
