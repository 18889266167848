import { getStaticUrl } from "src/utils";

export const Video360Variants = {
  "video360-circle-1": getStaticUrl("/video360-circle-1.png"),
  "video360-circle-2": getStaticUrl("/video360-circle-2.png"),
  "video360-circle-3": getStaticUrl("/video360-circle-3.png"),
  "video360-square-1": getStaticUrl("/video360-square-1.png"),
  "video360-square-2": getStaticUrl("/video360-square-2.png"),
  "video360-square-3": getStaticUrl("/video360-square-3.png"),
};

export const ARBlinkVariants = {
  "ar-type-0": "",
  "ar-type-1": getStaticUrl("/ar-type-1.png"),
  "ar-type-2": getStaticUrl("/ar-type-2.png"),
  "ar-type-3": getStaticUrl("/ar-type-3.png"),
  "ar-type-4": getStaticUrl("/ar-type-4.png"),
  "ar-type-5": getStaticUrl("/ar-type-5.png"),
};
