import React from "react";
import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./your-plan-badge.module.css";

export interface YourPlanBadgeProps {
  className?: string;
}

export const YourPlanBadge: React.FC<YourPlanBadgeProps> = observer(
  ({ className = "" }: YourPlanBadgeProps) => {
    const { localeStore } = useStores();

    return (
      <div className={clsx(styles.container, className)}>
        {localeStore.t('settings.subscriptions.components.content["your-plan-badge"]')}
      </div>
    );
  },
);

export default YourPlanBadge;
