import { makeAutoObservable, runInAction } from "mobx";

import { sharePost } from "src/api";
import { ENVIRONMENT } from "src/config/environment";
import { authLink } from "./linkedin.constants";

export class LinkedInStore {
  private _loading = false;

  public get loading() {
    return this._loading;
  }

  constructor() {
    makeAutoObservable(this);
  }

  public async auth() {
    if (ENVIRONMENT === "development" || ENVIRONMENT === "prestage") {
      location.assign(authLink[ENVIRONMENT]);
    }
  }

  public async shareMedia(text: string, images: string[]) {
    runInAction(() => {
      this._loading = true;
    });

    const linkedinImages = images.map((url) => {
      return {
        originalUrl: url,
      };
    });

    const data = {
      socialNetworkName: "linkedin",
      payload: {
        visibility: "PUBLIC",
        shareCommentary: text,
        shareMediaCategory: "IMAGE",
        images: linkedinImages,
      },
    };

    const { error } = await sharePost.getRequest({ data: data }).fetch();

    if (error) this.auth();

    runInAction(() => {
      this._loading = false;
    });
  }

  public async shareLink(link: string) {
    runInAction(() => {
      this._loading = true;
    });

    const data = {
      socialNetworkName: "linkedin",
      payload: {
        visibility: "PUBLIC",
        shareCommentary: link,
        shareMediaCategory: "NONE",
      },
    };

    const { error } = await sharePost.getRequest({ data: data }).fetch();

    // When user tries to post, and it's unauthorized, authorize it
    if (error) this.auth();

    runInAction(() => {
      this._loading = false;
    });
  }
}
