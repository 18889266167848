import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { observer } from "mobx-react-lite";
import { Link } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { COLORS } from "src/constants";
import { SettingTabNames, getSettingsTab } from "src/containers/settings";

import "./index.scss";

export const SubscriptionTooltip = observer(({ id = "", label = "", onUpgrade = undefined }) => {
  const { userStore } = useStores();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleUpgrade = () => {
    onUpgrade?.();
  };

  return (
    <ReactTooltip
      id={id}
      className="subscription-tooltip shadowed"
      event="mousemove"
      eventOff="mouseout"
      borderColor={COLORS.subscription}
      backgroundColor={COLORS.subscription}
      clickable
    >
      <div className="tooltip-label">
        {label || <> This feature is included in the Pro plan </>}&nbsp;
        {!userStore.isAppStoreDemoAccount && (
          <Link
            href={getSettingsTab(SettingTabNames.subscriptions)?.item.path}
            size="extraSmall700"
            appearance="quaternary"
            onClick={handleUpgrade}
          >
            Upgrade
          </Link>
        )}
      </div>
    </ReactTooltip>
  );
});

export default SubscriptionTooltip;
