import React from "react";
import { clsx, Nullable } from "@gemlightbox/core-kit";

import { OrderSortDirectionType, OrderTableSortValueType } from "src/store";

import { ReactComponent as AngleUpSVG } from "src/common/images/angle-up-secondary.svg";

import styles from "./table-sort-buttons.module.css";

interface TableSortButtonsProps {
  columnName: OrderTableSortValueType;
  activeSortAngleIcon: Nullable<{
    columnName: OrderTableSortValueType;
    sortOrder: OrderSortDirectionType;
  }>;
  handleSort: (
    columnName: OrderTableSortValueType,
    sortOrder: OrderSortDirectionType,
  ) => Promise<void>;
}

export const TableSortButtons: React.FC<TableSortButtonsProps> = ({
  columnName,
  activeSortAngleIcon,
  handleSort,
}) => {
  return (
    <div>
      <div className={styles.angleUpIconContainer} onClick={() => handleSort(columnName, "DESC")}>
        <AngleUpSVG
          className={clsx(styles.angleIcon, {
            [styles.active]:
              activeSortAngleIcon?.columnName === columnName &&
              activeSortAngleIcon?.sortOrder === "DESC",
          })}
        />
      </div>
      <div
        className={clsx(styles.angleDownIconContainer, {
          [styles.active]:
            activeSortAngleIcon?.columnName === columnName &&
            activeSortAngleIcon?.sortOrder === "ASC",
        })}
        onClick={() => handleSort(columnName, "ASC")}
      >
        {/* rotate the icon in css */}
        <AngleUpSVG className={styles.angleIcon} />
      </div>
    </div>
  );
};
