import { Webgl2New } from "@gemlightbox/core-kit";

export const mapMeasurementsToPlain = (measurements: Webgl2New.MeasurementComponent[]) => {
  return measurements.map((measurement) => {
    // NOTE: we need to clone measurement value again due to some unknown issue,
    // if not do it, we have issues with changing original measurement data
    const cloned = measurement.clone();

    return {
      color: cloned.color,
      startPos: cloned.startPos.getArray(),
      endPos: cloned.endPos.getArray(),
      text: cloned.text,
    };
  });
};
