import { ExtractApiResponse } from "@gemlightbox/core-kit";

import { dashboardApiMiddleware, GetIPInfo } from "src/api";
import { UserModel, UserMeModel, UserProfileModel } from "src/models";
import {
  PostLoginPayloadType,
  PostLoginResponseType,
  GetGalleryInfoPayloadType,
  GetGalleryInfoResponseType,
  GetUserTagManagerInfo,
  CountryCode,
} from "./user.interface";

export const getUserBalance = dashboardApiMiddleware<number>()({
  method: "get",
  endpoint: "/api/users/balance",
});

export const getUserStorage = dashboardApiMiddleware<number>()({
  method: "get",
  endpoint: "/api/limit/storage",
});

export const getUserTagManagerInfo = dashboardApiMiddleware<GetUserTagManagerInfo>()({
  method: "get",
  endpoint: "/api/users/info/google_tag",
});

export const getEmailAlreadyExists = dashboardApiMiddleware<boolean>()({
  method: "get",
  endpoint: "/api/users/check/email/:email",
});

export const getCheckResetToken = dashboardApiMiddleware<boolean>()({
  method: "get",
  endpoint: "/api/users/forgot/check/:guid",
});

export const getIPInfo = dashboardApiMiddleware<GetIPInfo>()({
  method: "get",
  endpoint: "/api/users/ipinfo",
});

export const postRequestPasswordReset = dashboardApiMiddleware()({
  method: "post",
  endpoint: "/api/users/forgot/:email",
});

export const postResendConfirmEmail = dashboardApiMiddleware()({
  method: "post",
  endpoint: "/api/users/resend/:email",
});

export const getUserTagManagerInfoCallback = (
  onSuccess: (response: ExtractApiResponse<typeof getUserTagManagerInfo>) => void,
) => {
  getUserTagManagerInfo
    .getRequest()
    .fetch()
    .then((response) => {
      if (response.status === "success") {
        onSuccess(response.success);
      }
    });
};

export const postLogin = dashboardApiMiddleware<PostLoginResponseType, PostLoginPayloadType>()({
  method: "post",
  endpoint: "/auth/local",
});

export const getUserMe = dashboardApiMiddleware<UserMeModel>()({
  method: "get",
  endpoint: "/api/users/me",
});

export const getUserProfile = dashboardApiMiddleware<UserProfileModel>()({
  method: "get",
  endpoint: "/api/users/me/profile",
});

export const putUpdateUser = dashboardApiMiddleware<UserModel, FormData>()({
  method: "put",
  endpoint: "/api/users",
  isFormData: true,
});

export const deleteUser = dashboardApiMiddleware<UserModel>()({
  method: "delete",
  endpoint: "/api/users",
});

export const getGalleryInfo = dashboardApiMiddleware<GetGalleryInfoResponseType>()({
  method: "get",
  endpoint: "/api/users/me/galleryinfo",
});

export const putUpdateGalleryInfo = dashboardApiMiddleware<GetGalleryInfoPayloadType, FormData>()({
  method: "put",
  endpoint: "/api/users/me/galleryinfo",
});

export const putResetPassword = dashboardApiMiddleware<any, { password: string }>()({
  method: "put",
  endpoint: "/api/users/forgot/confirm/:guid",
});

export const getBillingHistory = dashboardApiMiddleware()({
  method: "get",
  endpoint: "/api/users/billing/history",
});

export const getCountryCode = dashboardApiMiddleware<CountryCode>()({
  method: "get",
  endpoint: "/api/geoip/me",
});
