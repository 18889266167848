import React from "react";
import { observer } from "mobx-react-lite";
import { Typography, SvgIcon, SvgIconIconType, clsx } from "@gemlightbox/core-kit";

import { ReactComponent as ProBadgeSVG } from "src/external-ts/assets/images/edit-media/pro-badge.svg";
import styles from "./navbar-item.module.css";

export type NavbarItemProps = Omit<React.HTMLProps<HTMLButtonElement>, "ref" | "type"> & {
  title: string;
  icon: SvgIconIconType;
  selected: boolean;
  onClick: VoidFunction;
  disabled?: boolean;
  proBadge?: boolean;
};

export const NavbarItem: React.FC<NavbarItemProps> = observer(
  ({
    title,
    icon,
    selected,
    onClick,
    disabled = false,
    proBadge = false,
    ...rest
  }: NavbarItemProps) => {
    const handleOpenTab = () => {
      if (selected || disabled) return;
      onClick();
    };

    const navbarItemStyles = clsx(styles.editMediaNavbarItem, { [styles.selected]: selected });

    return (
      <button {...rest} className={navbarItemStyles} onClick={handleOpenTab} disabled={disabled}>
        {proBadge && (
          <SvgIcon
            className={styles.proBadge}
            icon={ProBadgeSVG}
            size={[28, 12]}
            disableAutoColor
          />
        )}
        <SvgIcon icon={icon} />
        <Typography size="extraSmall500">{title}</Typography>
      </button>
    );
  },
);

export default NavbarItem;
