import { TabType } from "@gemlightbox/core-kit";

import { MediaTypeNames } from "../media.constants";

export const mediaTabs: TabType[] = Object.entries(MediaTypeNames)
  .filter(([value]) => value !== "video360")
  .map(([value, label]) => {
    const result: TabType = {
      value,
      label,
    };

    return result;
  });
