import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { ExpandableSidebar, Button, Nullable } from "@gemlightbox/core-kit";

import { ErrorCodes } from "src/constants";
import { AttributeModel, AttributeTypeKeys } from "src/models";
import { useStores } from "src/hooks";
import { DefaultSettings } from "./default-settings";
import { NumberSettings } from "./number-settings";
import { SelectSettings } from "./select-settings";
import { validate } from "./create-attribute-sidebar.utils";
import { ErrorsType } from "./create-attribute-sidebar.types";

import styles from "./create-attribute-sidebar.module.css";

export interface CreateAttributeSidebarProps {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
  options: {
    attribute?: AttributeModel;
    onCreate?: (name: string) => void;
  };
}

export const CreateAttributeSidebar: React.FC<CreateAttributeSidebarProps> = observer(
  ({ options, isOpen, setClose, onFinalClosed }) => {
    const { attributesStore, modalsStore, localeStore } = useStores();

    const [initialAttribute] = useState(
      attributesStore.getAttributeRequestPayload(options.attribute),
    );
    const [attribute, setAttribute] = useState(initialAttribute);
    const [errors, setErrors] = useState<Nullable<ErrorsType>>(null);

    const isNumber = attribute.type === "number";
    const isSelect = attribute.type === "select";
    const isMultiselect = attribute.type === "multiselect";

    const handleClose = () => {
      setClose();
      modalsStore.open("ManageAttributesSidebar", {});
    };

    const handleNameChange = (name: string) => {
      if (errors) setErrors(null);

      setAttribute((prev) => ({
        ...prev,
        name,
      }));
    };

    const handleTypeChange = (type: AttributeTypeKeys) => {
      setAttribute((prev) => ({
        ...prev,
        type,
      }));
    };

    const handleRequiredChange = () => {
      setAttribute((prev) => ({
        ...prev,
        required: !prev.required,
      }));
    };

    const handlePrivacyChange = () => {
      setAttribute((prev) => ({
        ...prev,
        isPublic: !prev.isPublic,
      }));
    };

    const handleSuffixChange = (suffix: string) => {
      setAttribute((prev) => ({
        ...prev,
        suffix,
        prefix: "",
      }));
    };

    const handlePrefixChange = (prefix: string) => {
      setAttribute((prev) => ({
        ...prev,
        prefix,
        suffix: "",
      }));
    };

    const handleValuesChange = (values: string[]) => {
      if (!values.some((value) => value === "")) {
        setAttribute((prev) => ({
          ...prev,
          values,
        }));
      }
    };

    const handleConfirm = async () => {
      const errors = validate(attribute);
      if (Object.keys(errors).length !== 0) return setErrors(errors);
      if (errors) setErrors(null);

      try {
        if (options.attribute) {
          await attributesStore.updateAttribute(options.attribute.id, attribute);
        } else {
          await attributesStore.createAttributes(attribute);
          options.onCreate?.(attribute.name);
        }

        setClose();
        modalsStore.open("ManageAttributesSidebar", {});
      } catch (e) {
        const error = e as any;

        console.error(error);
        if (error?.response?.data?.code === ErrorCodes.ATTRIBUTE_NAME_EXISTS) {
          setErrors({
            name:
              error?.response?.data?.message ??
              localeStore.t('products.modals["create-attribute-sidebar"].errors["already-exist"]'),
          });
        }
      }
    };

    const checkIfDisabled = () => {
      if (isMultiselect || isSelect) {
        return attribute.values.length === 0;
      }

      const isDirty = JSON.stringify(initialAttribute) !== JSON.stringify(attribute);

      if (isDirty) {
        return !attribute.name;
      }

      return !isDirty;
    };

    return (
      <ExpandableSidebar
        icon="cross"
        iconPos="outside"
        title={localeStore.t('products.modals["create-attribute-sidebar"].title')}
        sidebarContentClassName={styles.sidebarContent}
        sidebarFooterClassName={styles.sidebarFooter}
        footer={
          <>
            <Button
              data-cy="cancel-button"
              className={styles.button}
              appearance="primaryOutlined"
              onClick={handleClose}
            >
              {localeStore.t('products.modals["create-attribute-sidebar"].buttons.cancel')}
            </Button>
            <Button
              data-cy="done-button"
              className={styles.button}
              disabled={checkIfDisabled()}
              onClick={handleConfirm}
            >
              {localeStore.t('products.modals["create-attribute-sidebar"].buttons.done')}
            </Button>
          </>
        }
        isOpen={isOpen}
        setClose={handleClose}
        onFinalClosed={onFinalClosed}
      >
        <DefaultSettings
          name={attribute.name}
          type={attribute.type}
          required={attribute.required}
          isPublic={attribute.isPublic}
          errors={errors}
          onNameChange={handleNameChange}
          onTypeChange={handleTypeChange}
          onRequiredChange={handleRequiredChange}
          onPrivacyChange={handlePrivacyChange}
        />

        <div className={styles.extendedSettings}>
          {isNumber && (
            <NumberSettings
              suffix={attribute.suffix}
              prefix={attribute.prefix}
              onSuffixChange={handleSuffixChange}
              onPrefixChange={handlePrefixChange}
            />
          )}

          {(isSelect || isMultiselect) && (
            <SelectSettings
              type={attribute.type}
              values={attribute.values}
              onTypeChange={handleTypeChange}
              onValuesChange={handleValuesChange}
            />
          )}
        </div>
      </ExpandableSidebar>
    );
  },
);
