import React from "react";
import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./days-remaining-panel.module.css";

export interface DaysRemainingPanelProps {
  className?: string;
  diffDays: number;
}

export const DaysRemainingPanel: React.FC<DaysRemainingPanelProps> = observer(
  ({ className = "", diffDays }: DaysRemainingPanelProps) => {
    const { localeStore, subscriptionsStore } = useStores();

    return (
      <div className={clsx(styles.container, className)}>
        <div className={styles.amount}>{diffDays}</div>
        <div className={styles.content}>
          <div className={styles.title}>
            {localeStore.t('settings.subscriptions.components["days-remaining-panel"].title')}
          </div>
          <div>
            <span className={styles.nextPaymentText}>
              {localeStore.t(
                'settings.subscriptions.components["days-remaining-panel"]["next-payment"]',
              )}
            </span>
            &nbsp;
            <span className={styles.nextPaymentDate}>{subscriptionsStore.getExpireDate()}</span>
          </div>
        </div>
      </div>
    );
  },
);

export default DaysRemainingPanel;
