import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import styles from "./index.module.css";

const BluetoothExplora = ({ className = "", ...props }) => (
  <div className={clsx(styles.bluetoothExplora, className)} {...props}>
    <div className={styles.radar}>
      {Array.from({ length: 6 }).map((_, index) => (
        <div className={styles.wave} key={index}></div>
      ))}
      <div className={clsx(styles.wave, styles.bluetoothIcon)}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 20"
          fill="#ffffff"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="bluetooth">
            <path
              id="Vector 211"
              d="M12 20H11C11 20.3844 11.2203 20.7348 11.5668 20.9013C11.9133 21.0678 12.3245 21.021 12.6247 20.7809L12 20ZM17 16L17.6247 16.7809L18.595 16.0046L17.6292 15.2228L17 16ZM17 8L17.6292 8.77725L18.595 7.99539L17.6247 7.21913L17 8ZM12 4L12.6247 3.21913C12.3245 2.979 11.9133 2.93218 11.5668 3.0987C11.2203 3.26522 11 3.61559 11 4H12ZM12.6247 20.7809L17.6247 16.7809L16.3753 15.2191L11.3753 19.2191L12.6247 20.7809ZM17.6247 7.21913L12.6247 3.21913L11.3753 4.78087L16.3753 8.78087L17.6247 7.21913ZM17.6292 15.2228L7.1292 6.72276L5.8708 8.27725L16.3708 16.7772L17.6292 15.2228ZM7.1292 17.2772L17.6292 8.77725L16.3708 7.22276L5.8708 15.7228L7.1292 17.2772ZM11 4V20H13V4H11Z"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
);

export default BluetoothExplora;
