import React from "react";
import moment from "moment";
import { observer } from "mobx-react-lite";
import {
  Checkbox,
  Loader,
  SvgIcon,
  EllipseActions,
  clsx,
  Button,
  Typography,
  ActionOption,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ExtendedTrashBinItemModel } from "src/models";

import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-grey.svg";
import { ReactComponent as RecoverSVG } from "src/external-ts/assets/images/redo-arrow-grey.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./media-item.module.css";

export type MediaItemProps = {
  item: ExtendedTrashBinItemModel;
  onSelect: (item: ExtendedTrashBinItemModel) => void;
  selectOnClick?: boolean;
};

export const MediaItem: React.FC<MediaItemProps> = observer(
  ({ item, onSelect, selectOnClick = true }) => {
    const { trashBinStore, modalsStore, localeStore } = useStores();

    const { extended, entity } = item;

    const isSelected = extended?.selected;
    const isLoading = extended?.loading;
    const isPdf = entity.type === "pdf";

    const handleSelect = () => onSelect(item);

    const handleRecover = () => {
      return modalsStore.open("RecoverMediaModal", { media: [item] });
    };

    const handleDelete = () => {
      return modalsStore.open("DeleteTrashModal", { media: [item] });
    };

    const getMediaPreview = () => {
      // let result: any = item.thumbnail;
      //
      // if (type === MediaType.blink) result = item.cropFile?.small;
      //
      // return result;
      return item.entity?.file?.small;
    };

    return (
      <div
        className={styles.mediaItemWrapper}
        onClick={selectOnClick ? handleSelect : undefined}
        data-cy="media-list-item"
      >
        {isLoading && <Loader position="absolute" withOverlay />}
        {trashBinStore.isItemSelected && (
          <div className={clsx(styles.buttonWrapper, { [styles.selected]: isSelected })}>
            <Button className={styles.selectBtn} appearance="secondary" size="small">
              {isSelected && <SvgIcon className={styles.checkmarkWrapper} icon={CheckmarkSVG} />}
              {isSelected
                ? localeStore.t('media["media-list"]["media-item"].buttons.unselect')
                : localeStore.t('media["media-list"]["media-item"].buttons.select')}
            </Button>
          </div>
        )}
        <div className={styles.header}>
          <Checkbox
            className={clsx(styles.checkbox, { [styles.selected]: trashBinStore.isItemSelected })}
            onClick={(e) => e.stopPropagation()}
            onChange={handleSelect}
            checked={isSelected}
            disableError
            data-cy="media-list-item-checkbox"
          />

          <EllipseActions
            tooltipClassName={styles.actionsTooltipContainer}
            appearance="quaternary"
            size="tiny"
            data-cy="media-list-item-actions"
          >
            <ActionOption onClick={handleRecover} data-cy="table-action-delete">
              <SvgIcon icon={RecoverSVG} />
              {localeStore.t("common.buttons.recover")}
            </ActionOption>
            <ActionOption onClick={handleDelete} data-cy="table-action-delete">
              <SvgIcon className={styles.deleteSVG} icon={DeleteSVG} />
              {localeStore.t("common.buttons.delete")}
            </ActionOption>
          </EllipseActions>
        </div>
        <div className={clsx(styles.imageContainer)}>
          <div className={styles.imageFiller} />
          <div className={styles.imageWrapper}>
            {isPdf && <SvgIcon size={80} icon={PdfSVG} />}
            {!isPdf && <img src={getMediaPreview()} alt="" loading="lazy" />}
          </div>
        </div>
        <div className={styles.footer}>
          <Typography className={styles.dateWrapper} size={"extraSmall"} color={"textTertiary"}>
            {moment(item.createdAt).format("MM/DD/YYYY")}{" "}
          </Typography>
        </div>
      </div>
    );
  },
);
