export type AttributeKindType = "default" | "custom";

export enum AttributeType {
  text = "text",
  number = "number",
  select = "select",
  multiselect = "multiselect",
}

export type AttributeTypeKeys = keyof typeof AttributeType;

export type AttributeModel = {
  name: string;
  displayName: string | null;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  prefix: string | null;
  suffix: string | null;
  kind: AttributeKindType;
  type: AttributeTypeKeys;
  values: null | string[];
  id: number;
  order: number;
  order_gallery: number;
  user_id: number;
  isHidden: boolean;
  isPublic: boolean;
  required: boolean;
  // ↓ rapnet-only attributes, maybe in ↓
  // future there will be attributes from other integrations
  integratedTo: "rapnet" | "shopify" | null;
  // ↓ shopify integration attribute id (shopify settings page) ↓
  shop_id: number | null;
  draggableId: string;
};

export type AttributeRequestModel = {
  name: string;
  type: AttributeTypeKeys;
  displayName: string;
  values: string[];
  order: number;
  order_gallery: number;
  required: boolean;
  prefix: string;
  suffix: string;
  description?: string;
  integratedTo?: "rapnet";
  suffixValues?: string[];
  isHidden?: boolean;
  isPublic: boolean;
};
