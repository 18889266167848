import React, { Fragment, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Typography,
  Input,
  SelectGroup,
  SelectGroupOption,
  SvgIcon,
  Button,
  clamp,
  CommandAction,
  useDidUpdate,
  Webgl2New,
  useDidMount,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getStaticUrl, unsavedChangesNotification } from "src/utils";
import { editMediaStore } from "../../edit-media.store";
import { GroupBlock } from "../group-block";
import { marketplacesArr } from "./resize.constants";

import styles from "./resize.module.css";

export const Resize: React.FC = observer(() => {
  const { renderer } = editMediaStore;
  const canvasSize = renderer.canvasSize;

  const { localeStore } = useStores();

  const [prevCanvasTranslation] = useState(() => renderer.canvasTranslation.clone());
  const [prevCanvasSize] = useState(() => renderer.canvasSize.clone());
  const [selectedSizeOption, setSelectedSizeOption] = useState("Custom");

  const handleChangeWidth = (width: string) => {
    let widthToSet = parseInt(width);
    if (isNaN(widthToSet)) widthToSet = canvasSize.width;
    widthToSet = clamp(widthToSet, Webgl2New.minCanvasSize, Webgl2New.maxCanvasSize);

    setSelectedSizeOption("Custom");
    renderer.setCanvasSize(widthToSet, renderer.canvasSize.height, true);
  };

  const handleChangeHeight = (height: string) => {
    let heightToSet = parseInt(height);
    if (isNaN(heightToSet)) heightToSet = renderer.canvasSize.height;
    heightToSet = clamp(heightToSet, Webgl2New.minCanvasSize, Webgl2New.maxCanvasSize);

    setSelectedSizeOption("Custom");
    renderer.setCanvasSize(canvasSize.width, heightToSet, true);
  };

  const handleChangeMarketplace = (value: string) => {
    const foundItem = marketplacesArr.find((item) => item.value === value);
    setSelectedSizeOption(foundItem?.label ?? "Custom");
    if (!foundItem) return;
    const [width, height] = foundItem.size.split(" x ");
    renderer.setCanvasSize(Number(width), Number(height), true);
  };

  const checkNeedWarning = () => {
    return !(
      prevCanvasSize.equals(renderer.canvasSize) &&
      prevCanvasTranslation.equals(renderer.canvasTranslation)
    );
  };

  const handleCancel = () => {
    editMediaStore.unregisterTabBlockCallback();
    renderer.setCanvasTranslate(prevCanvasTranslation.width, prevCanvasTranslation.height);
    renderer.setCanvasSize(prevCanvasSize.width, prevCanvasSize.height, true);
    editMediaStore.closeResizeTab();
  };

  const handleConfirmCancel = () => {
    if (checkNeedWarning()) {
      unsavedChangesNotification(handleCancel);
      return;
    }

    handleCancel();
  };

  const handleDone = () => {
    editMediaStore.unregisterTabBlockCallback();

    const undoTranslation = prevCanvasTranslation.clone();
    const undoSize = prevCanvasSize.clone();

    const actionTranslation = renderer.canvasTranslation.clone();
    const actionSize = renderer.canvasSize.clone();

    editMediaStore.closeResizeTab();

    if (undoTranslation.equals(actionTranslation) && undoSize.equals(actionSize)) return;

    renderer.commands.execute(
      new CommandAction(
        "Resize canvas",
        () => {
          renderer.setCanvasTranslate(actionTranslation.width, actionTranslation.height);
          renderer.setCanvasSize(actionSize, true);
        },
        () => {
          renderer.setCanvasTranslate(undoTranslation.width, undoTranslation.height);
          renderer.setCanvasSize(undoSize, true);
        },
      ),
    );
  };

  useDidMount(() => {
    return editMediaStore.registerTabBlockCallback(({ unregister, proceed }) => {
      if (checkNeedWarning()) {
        unsavedChangesNotification(() => {
          unregister();
          handleCancel();
          proceed();
        });

        return true;
      }

      unregister();
      handleCancel();
    });
  });

  useDidUpdate(() => {
    // NOTE: If we change width\height via dropdown we should not set value to "Custom"
    // So this condition is workaround
    const foundItem = marketplacesArr.find((item) => item.label === selectedSizeOption);
    if (foundItem) {
      const [width, height] = foundItem.size.split(" x ");
      if (Number(width) === canvasSize.width && Number(height) === canvasSize.height) {
        return;
      }
    }

    // NOTE: If we change width\height via resizing controls we change option to be custom
    setSelectedSizeOption("Custom");
  }, [selectedSizeOption, canvasSize.width, canvasSize.height]);

  return (
    <Fragment>
      <GroupBlock className={styles.customResizeBlockGroup}>
        <Typography size="small600" color="textSecondary">
          {localeStore.t('["edit-media"].inspector.resize.custom')}
        </Typography>

        <div className={styles.inputsWrapper}>
          <Input
            type="number"
            inputWrapperClassName={styles.inputWrapper}
            label={localeStore.t("common.helpers.width")}
            value={canvasSize.width}
            appearance="primaryV2"
            suffix="px"
            onChange={handleChangeWidth}
            disableError
            forceAffix
          />
          <Input
            type="number"
            inputWrapperClassName={styles.inputWrapper}
            label={localeStore.t("common.helpers.height")}
            value={canvasSize.height}
            appearance="primaryV2"
            suffix="px"
            onChange={handleChangeHeight}
            disableError
            forceAffix
          />
        </div>
      </GroupBlock>

      <GroupBlock className={styles.marketplacesBlockGroup}>
        <Typography size="small600" color="textSecondary">
          {localeStore.t('["edit-media"].inspector.resize.marketplaces')}
        </Typography>

        <SelectGroup
          searchValue={selectedSizeOption}
          value={selectedSizeOption}
          onChange={handleChangeMarketplace}
          disableSearch
          disableClear
          disableError
        >
          {marketplacesArr.map(({ label, value, size, staticItem }) => (
            <SelectGroupOption
              key={label}
              contentContainerClassName={styles.selectOptionContent}
              value={value}
            >
              <SvgIcon icon={getStaticUrl(staticItem)} disableAutoColor />
              <Typography size="extraSmall" color="textSecondary">
                {label}
              </Typography>
              <Typography size="extraSmall" color="textTertiary">
                {size}
              </Typography>
            </SelectGroupOption>
          ))}
        </SelectGroup>
      </GroupBlock>

      <div className={styles.buttons}>
        <Button appearance="secondaryOutlined" onClick={handleConfirmCancel}>
          {localeStore.t("common.buttons.cancel")}
        </Button>
        <Button onClick={handleDone}>{localeStore.t("common.buttons.done")}</Button>
      </div>
    </Fragment>
  );
});

export default Resize;
