export type GlobalLinkModel = {
  uuid: string; // "72db51b9-5a80-4e8c-8b0f-7a4bb913f704"
  id: number;
  isPrivate: boolean;
};

export type GlobalImageDataModel = {
  createdAt: string | null; // "2007-03-11T13:38:52"
  filename: string;
  small: string;
  medium: string;
  interactive?: string;
  compressed?: string;
  original: string;
  size: number; // 340385 in bytes
};

export type GlobalErrorsMapModel = Record<string, string[]>;

export enum WebsocketStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}
