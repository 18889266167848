import { CountryCode, getCountryCallingCode, isPossiblePhoneNumber } from "@gemlightbox/core-kit";

import { getEmailAlreadyExists } from "src/api";
import { useStores } from "src/hooks";
import { validateEmail, validatePassword } from "src/utils";
import { SignupErrorsType, SignupFormType } from "./signup.types";

export const signupValidationStep1 = async (values: SignupFormType) => {
  const { localeStore } = useStores();
  const errors: SignupErrorsType = {};

  if (!values.email) errors.email = true;
  if (values.email) {
    const isValid = validateEmail(values.email);

    if (!isValid) {
      errors.email = localeStore.t('auth["sign-up"]["first-step"].fields.email["error-not-valid"]');
    } else {
      const response = await getEmailAlreadyExists
        .getRequest({ params: { email: values.email } })
        .fetch();
      if (response.success) {
        errors.email = localeStore.t(
          'auth["sign-up"]["first-step"].fields.email["error-already-exists"]',
        );
      }
    }
  }

  if (!values.password) errors.password = true;
  if (values.password && !validatePassword(values.password)) errors.password = true;

  if (!values.confirmPassword) errors.confirmPassword = true;

  if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
    errors.password = true;
    errors.confirmPassword = true;
  }

  return errors;
};

export const signupValidationStep2 = (values: SignupFormType) => {
  const { localeStore } = useStores();
  const errors: SignupErrorsType = {};

  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.phone.country || !values.phone.phone) errors.phone = true;

  if (values.phone.country && values.phone.phone) {
    const callingCode = getCountryCallingCode(values.phone.country as CountryCode).callingCode;
    const internationalPhone = `+${callingCode}${values.phone.phone}`;

    if (!isPossiblePhoneNumber(internationalPhone) && values.phone.phone !== "") {
      errors.phone = localeStore.t(
        'auth["sign-up"]["second-step"].fields.phone["error-not-valid"]',
      );
    }
  }

  if (!values.acceptTerms) errors.acceptTerms = true;

  return errors;
};
