import { LanguageOptionType } from "./language-select.types";

export const languageOptions: LanguageOptionType[] = [
  {
    label: "English",
    value: "EN",
    code: "GB",
  },
  {
    label: "Spanish",
    value: "ES",
    code: "ES",
  },
  {
    label: "Chinese (simplified)",
    value: "ZH",
    code: "CN",
  },
  {
    label: "Chinese (traditional)",
    value: "ZH_TW",
    code: "TW",
  },
  {
    label: "Hindi",
    value: "HI",
    code: "IN",
  },
  {
    label: "Arabic",
    value: "AR",
    code: "SA",
  },
  {
    label: "French",
    value: "FR",
    code: "FR",
  },
  {
    label: "Japanese",
    value: "JA",
    code: "JP",
  },
  {
    label: "Korean",
    value: "KO",
    code: "KR",
  },
  {
    label: "German",
    value: "DE",
    code: "DE",
  },
  {
    label: "Italian",
    value: "IT",
    code: "IT",
  },
  {
    label: "Turkish",
    value: "TR",
    code: "TR",
  },
  {
    label: "Thai",
    value: "TH",
    code: "TH",
  },
  {
    label: "Hebrew",
    value: "HE",
    code: "IL",
  },
];
