import { uniqId, useQuery } from "@gemlightbox/core-kit";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { config } from "src/config/environment";

import styles from "./wechat.module.css";
import { useStores } from "src/hooks";

const WechatPage = () => {
  const { localeStore } = useStores();
  const shareLink =
    decodeURIComponent(localStorage.getItem("shareLink") || "") || config.galleryURL;

  return (
    <div className={styles.wechatPage}>
      <div className={styles.qrCodeContainer}>
        <QRCode className={styles.qrCode} value={shareLink} size={270} />
        <div className={styles.tips}>
          {localeStore.t('components.business["share-options"].tips')}
        </div>
      </div>
    </div>
  );
};

export const WechatPageMemo = React.memo(WechatPage);
