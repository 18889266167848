import React from "react";
import { observer } from "mobx-react-lite";
import {
  Typography,
  LimitedEditorText,
  SelectGroup,
  SelectGroupOption,
  Loader,
  Button,
  SvgIcon,
  useFetch,
} from "@gemlightbox/core-kit";

import { getProduct } from "src/api";
import { ExtendedMediaModel } from "src/store";
import { useMediaAssign, useStores } from "src/hooks";

import { ReactComponent as ArrowRightSVG } from "src/external-ts/assets/images/arrow-right-oblong-grey.svg";
import styles from "./media-item-details.module.css";
import { PendingUploadStatus } from "src/models";

type MediaItemDetails = {
  mediaData: ExtendedMediaModel;
};

export const MediaItemDetails: React.FC<MediaItemDetails> = observer(({ mediaData }) => {
  const { localeStore, userStore, modalsStore } = useStores();
  const isUploading = mediaData?.status === PendingUploadStatus.pending;
  const isSKUTextEditable = !userStore.isCTFSub;

  const {
    searchValue,
    value,
    error,
    canCreateNew,
    inProcess,
    hasProduct,
    options,
    onGoToProduct,
    onSearchProduct,
    onBlur,
    onChangeProduct,
    onCreateProduct,
    onDropdownOpen,
    onDropdownClose,
  } = useMediaAssign({ data: mediaData });

  // NOTE: This is crutch
  const useSelectedProductFetch = useFetch(getProduct.setParams({ productId: value }), {
    disabled: !value,
    dependencies: [value],
  });
  const selectedProduct = useSelectedProductFetch.loading ? null : useSelectedProductFetch.payload;

  const handleGoToProduct = () => {
    modalsStore.close("MediaDetailsSidebar");
    onGoToProduct();
  };

  const descriptionParameter = selectedProduct?.parameters?.find(
    ({ name }) => name === "description",
  );

  return (
    <div className={styles.mediaItemDetailsContainer}>
      <div className={styles.header}>
        <Typography size="small600" color="textSecondary">
          {localeStore.t('media.modals["media-details-sidebar"]["media-item-details"].title')}
        </Typography>
        {hasProduct && (
          <Button
            appearance="promoGhost"
            size="large"
            onClick={handleGoToProduct}
            disabled={inProcess}
            data-cy="go-to-product"
          >
            {localeStore.t('common.buttons["go-to-product"]')}
            <SvgIcon icon={ArrowRightSVG} />
          </Button>
        )}
      </div>

      <SelectGroup
        label={localeStore.t(
          'media.modals["media-details-sidebar"]["media-item-details"].fields.select.label',
        )}
        placeholder={localeStore.t(
          'media.modals["media-details-sidebar"]["media-item-details"].fields.select.placeholder',
        )}
        searchValue={searchValue}
        value={value}
        error={error}
        onBlur={onBlur}
        onChange={onChangeProduct}
        onSearch={onSearchProduct}
        onDropdownOpen={onDropdownOpen}
        onDropdownClose={onDropdownClose}
        onEnter={onCreateProduct}
        disableSearch={!isSKUTextEditable}
        loading={inProcess}
        disabled={isUploading}
      >
        {inProcess && <Loader className={styles.dropdownLoader} size={24} />}

        {canCreateNew && !options.length && (
          <Button
            className={styles.createNewBtn}
            appearance="secondaryGhost"
            onClick={onCreateProduct}
          >
            {localeStore.t('common.buttons["create-new-sku"]')}
          </Button>
        )}

        {!inProcess &&
          options.map(({ label, value }) => (
            <SelectGroupOption key={value} value={value}>
              {label}
            </SelectGroupOption>
          ))}
      </SelectGroup>

      {descriptionParameter && (
        <div className={styles.productDescription}>
          <Typography
            className={styles.productDescriptionTitle}
            size="extraSmall"
            color="textSecondary"
          >
            {descriptionParameter.name}
          </Typography>
          <Typography size="small" color="textTertiary">
            <LimitedEditorText value={descriptionParameter.value as string} limit={1000} />
          </Typography>
        </div>
      )}
    </div>
  );
});
