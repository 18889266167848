import React, { Fragment, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useFacebook } from "react-facebook";
import {
  Button,
  clsx,
  copyToClipboard,
  globalStyles,
  Input,
  SvgIcon,
  Typography,
  Tooltip,
} from "@gemlightbox/core-kit";

import { ExtendedProductModel } from "src/store";
import { useStores } from "src/hooks";
import { ProductImageModel } from "src/models";
import { getSharedResourceLink } from "../../shared-link-content/share-link-modal.utils";

import styles from "./post-preview.module.css";
import { ReactComponent as MediaSVG } from "src/external-ts/assets/images/navbar/media-page.grey.svg";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as WhatsappSVG } from "src/external-ts/assets/images/whatsapp-v2.svg";
import { ReactComponent as MailSVG } from "src/external-ts/assets/images/mail-rounded.svg";
import { ReactComponent as FacebookSVG } from "src/external-ts/assets/images/facebook-v2.svg";
import { ReactComponent as LinkedInSVG } from "src/external-ts/assets/images/linkedin-icon.svg";

type PostPreviewProps = {
  data: ExtendedProductModel;
  initialPost: string;
};

export const PostPreview: React.FC<PostPreviewProps> = observer(({ data, initialPost }) => {
  const { localeStore, linkedInStore } = useStores();
  const { init } = useFacebook();

  const [mediaList, setMediaList] = useState(data.images);
  const [isEditing, setEdit] = useState(false);
  const [description, setDescription] = useState(initialPost);
  const [tooltipFBOpen, setTooltipFB] = useState(false);
  const [tooltipLIOpen, setTooltipLI] = useState(false);
  const refFB = useRef(null);
  const refLI = useRef(null);

  const link = getSharedResourceLink("product", data);

  const handleMainMediaChange = (newMainMedia: ProductImageModel) => {
    if (!mediaList) return;
    const productImages = [...mediaList] as ProductImageModel[];
    const newMediaIndex = productImages.indexOf(newMainMedia);
    productImages[newMediaIndex] = productImages[0];
    productImages[0] = newMainMedia;
    setMediaList(productImages);
  };

  const handleWhatsapp = () => {
    const message = `${description}\n\n${link}`;
    const url = new URL("https://wa.me/");
    url.searchParams.append("text", message);
    window.open(url.toString(), "_blank");
  };

  const handleMail = () => {
    const message = `${description}%0D%0A%0D%0A${link}`;

    window.open(`mailto:?body=${message}`, "_self");
  };

  const handleFacebook = async () => {
    copyToClipboard(description);
    setTooltipFB(true);
    setTimeout(() => setTooltipFB(false), 3000);
    setTimeout(async () => {
      const api = await init();
      const FB = await api!.getFB();
      FB.ui({
        method: "feed",
        link: link,
      });
    }, 1000);
  };

  const handleLinkedIn = async () => {
    setTooltipLI(true);
    await linkedInStore.shareMedia(
      description,
      mediaList.filter((f) => f.file?.original).map((media) => media!.file!.original),
    );
    setTimeout(() => setTooltipLI(false), 3000);
  };

  const getImage = (media: ProductImageModel, size: "original" | "small") => {
    if (media.type === "blink") return media.arData?.cropFile ? media.arData?.cropFile[size] : "";
    if (media.type === "video" || media.type === "video360") return media.file?.small;
    return media?.file ? media?.file[size] : "";
  };

  return (
    <div className={styles.postPreviewContainer}>
      <div className={styles.postPreviewMedias}>
        {mediaList.length ? (
          <img
            className={clsx(styles.mediaContainer, styles.mainMedia)}
            src={getImage(mediaList[0], "original")}
          />
        ) : (
          <div className={clsx(styles.mediaContainer, styles.noMedia)}>
            <SvgIcon icon={MediaSVG} size={244} />
          </div>
        )}
        {mediaList && mediaList.length > 1 && (
          <div className={styles.sideMedias}>
            {mediaList.map((media, index) => {
              if (index === 0) return;
              return (
                <img
                  key={media.id}
                  className={clsx(styles.mediaContainer, styles.sideMedia)}
                  src={getImage(media, "small")}
                  onClick={() => handleMainMediaChange(media as ProductImageModel)}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.postPreviewRight}>
        <div className={styles.descriptionEditor}>
          {isEditing ? (
            <Fragment>
              <Button
                className={styles.editButton}
                appearance="primary"
                size="small"
                onClick={() => setEdit(false)}
              >
                {localeStore.t("common.buttons.save")}
              </Button>
              <Input
                appearance="primaryV2"
                type="textarea"
                label={localeStore.t(
                  'components.business["share-modal"]["generate-post-content"]["manual-post-form"]["form-fields"].labels.description',
                )}
                className={styles.descriptionField}
                inputWrapperClassName={styles.descriptionInputWrapper}
                value={description}
                onChange={setDescription}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Button
                className={styles.editButton}
                appearance="secondary"
                size="small"
                onClick={() => setEdit(true)}
              >
                <SvgIcon icon={EditSVG} />
                {localeStore.t("common.buttons.edit")}
              </Button>
              <div className={clsx(styles.descriptionPreview, globalStyles.addScrollStyles)}>
                {description && (
                  <Typography size="medium" className={styles.description}>
                    {description}
                  </Typography>
                )}
                <Typography size="medium600" className={styles.link}>
                  <a href={link} target="_blank" rel="noreferrer">
                    {link}
                  </a>
                </Typography>
              </div>
            </Fragment>
          )}
        </div>
        <div className={styles.socialIcons}>
          <SvgIcon icon={MailSVG} size={44} onClick={handleMail} />
          <SvgIcon icon={FacebookSVG} size={44} onClick={handleFacebook} forwardRef={refFB} />
          {/*<SvgIcon icon={LinkedInSVG} size={44} onClick={handleLinkedIn} forwardRef={refLI} />*/}
          <SvgIcon icon={WhatsappSVG} size={44} onClick={handleWhatsapp} />
        </div>
        <Tooltip
          target={refFB}
          isOpen={tooltipFBOpen}
          appearance="secondary"
          onClose={setTooltipFB}
          withAngle
        >
          {localeStore.t(
            'components.business["share-modal"]["generate-post-content"]["post-preview"]["facebook-tooltip"]',
          )}
        </Tooltip>
        <Tooltip
          target={refLI}
          isOpen={tooltipLIOpen}
          appearance="secondary"
          onClose={setTooltipLI}
          withAngle
        >
          {linkedInStore.loading
            ? "..."
            : localeStore.t(
                'components.business["share-modal"]["generate-post-content"]["post-preview"]["linkedin-tooltip"]',
              )}
        </Tooltip>
      </div>
    </div>
  );
});
