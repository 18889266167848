import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";

import { WelcomeDownloadPage } from "./download";
import { WelcomeHomePage } from "./home";
import { SETUP_HOME_PAGE } from "src/constants";

export const WelcomePage: React.FC = observer(() => {
  return (
    <Routes>
      <Route path="/home" element={<WelcomeHomePage />}></Route>
      <Route path="/download" element={<WelcomeDownloadPage />}></Route>
      <Route path="*" element={<Navigate to={SETUP_HOME_PAGE.path} />} />
    </Routes>
  );
});
