import React from "react";
import { observer } from "mobx-react-lite";
import { Button, clsx, Image, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MediaType } from "src/models";
import { ExtendedMediaModel } from "src/store";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-grey.svg";
import styles from "./media-items.module.css";

export type MediaItemProps = {
  media: ExtendedMediaModel;
  canSelect: boolean;
};

export const MediaItem: React.FC<MediaItemProps> = observer(({ media, canSelect }) => {
  const { type, thumbnail, extended } = media;
  const { selected } = extended;

  const { mediaStore, localeStore } = useStores();

  const hideSelectMedia = !canSelect && !selected;

  const handleSelectMedia = () => {
    if (hideSelectMedia) return;
    mediaStore.toggleMediaList(media, "selected");
  };

  const getMediaPreview = () => {
    let result = thumbnail;

    if (type === MediaType.blink) {
      const small = media.cropFile?.small;
      if (small) result = small;
    }

    return result;
  };

  return (
    <div
      className={clsx(styles.mediaItemContainer, { [styles.selectable]: !hideSelectMedia })}
      onClick={handleSelectMedia}
      data-cy="media-from-list"
    >
      {!hideSelectMedia && (
        <div className={clsx(styles.buttonWrapper, { [styles.selected]: selected })}>
          <Button
            className={styles.selectBtn}
            appearance="secondary"
            size="small"
            data-cy="select-image"
          >
            {selected && <SvgIcon className={styles.checkmarkWrapper} icon={CheckmarkSVG} />}
            {selected
              ? localeStore.t('create["media-list"]["media-items"]["media-item"].unselect')
              : localeStore.t('create["media-list"]["media-items"]["media-item"].select')}
          </Button>
        </div>
      )}

      <Image src={getMediaPreview()} />
    </div>
  );
});

export default MediaItem;
