import React, { useRef } from "react";
import { Button, clsx, globalStyles, SvgIcon, Typography, useBoolean } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getMediaPreviewFromProduct } from "src/utils";
import { ExtendedProductModel } from "src/store";

import { ReactComponent as NoMediaSvg } from "src/external-ts/assets/images/media/no-media-grey.svg";
import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-grey.svg";
import styles from "./styles.module.css";

interface CardProps {
  top: number;
  left: number;
  cardTop: number;
  cardLeft: number;
  shadowCard: ExtendedProductModel;
}

export const ShadowCard: React.FC<CardProps> = ({ top, left, cardTop, cardLeft, shadowCard }) => {
  const { localeStore } = useStores();

  const isAnimationCompletedBoolean = useBoolean(false);

  const shadowCardRef = useRef<HTMLDivElement>(null);

  const imageToShow = shadowCard.images?.[0];

  if (isAnimationCompletedBoolean.value) return null;

  return (
    <div
      className={styles.shadowCard}
      ref={shadowCardRef}
      style={{
        // NOTE: ts-ignore to fix setting css variables via react style prop
        // @ts-ignore
        "--mouse-x": `${left}px`,
        "--mouse-y": `${top}px`,
        "--container-x": `${cardLeft}px`,
        "--container-y": `${cardTop}px`,
      }}
      onAnimationEnd={isAnimationCompletedBoolean.setTruthy}
    >
      <div className={clsx(styles.buttonWrapper, styles.selected, styles.selectedAndDragged)}>
        <Button className={styles.selectBtn} appearance="secondary" size="small">
          <SvgIcon className={styles.checkmarkWrapper} icon={CheckmarkSVG} />
        </Button>
      </div>
      <div className={styles.cardImageContainer}>
        <div className={clsx(styles.imageFiller, "productCardImageFiller")} />
        <div className={styles.imageWrapper}>
          {!imageToShow && <SvgIcon icon={NoMediaSvg} size={[83, 53]} />}
          {imageToShow && (
            <img
              className={styles.cardImage}
              src={getMediaPreviewFromProduct(imageToShow)}
              alt=""
            />
          )}
        </div>
      </div>
      <Typography
        className={clsx(styles.productTitleContainer, globalStyles.applySingleOverflow)}
        size="tiny"
      >
        {shadowCard?.title ||
          localeStore.t('["catalog-details"]["product-order-modal"].main["not-assigned"]')}
      </Typography>
    </div>
  );
};
