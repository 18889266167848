import { ObjectType, useDidMount } from "@gemlightbox/core-kit";

import { MAX_LABEL_LENGTH } from "src/constants";
import { useStores } from "src/hooks";
import { CreateCatalogFormType } from "./create-catalog.types";

export const createCatalogValidation = (values: ObjectType) => {
  const { localeStore } = useStores();

  const errors: ObjectType = {};

  if (!values.name) errors.name = localeStore.t('["create-catalog"].fields.name["error-required"]');

  if (values.name && values.name.length > MAX_LABEL_LENGTH) {
    errors.name = `${localeStore.t(
      '["create-catalog"].fields.name["error-max-length"].first',
    )} ${MAX_LABEL_LENGTH} ${localeStore.t(
      '["create-catalog"].fields.name["error-max-length"].second',
    )}`;
  }

  return errors;
};

export const useInitialCatalogForm = () => {
  const { catalogsStore } = useStores();

  useDidMount(async () => await catalogsStore.loadLastUntitledCatalogNumber());

  const createCatalogForm: CreateCatalogFormType = {
    name: catalogsStore.untitledCatalogName,
    description: "",
  };

  return { createCatalogForm };
};
