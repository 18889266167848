import React from "react";
import { Link } from "react-router-dom";

import { SETTINGS_PAGE } from "src/constants";
import arrowLeftImg from "src/common/images/arrow-left-black.svg";
import MobileHeaderWrapper from "src/components/MobileHeaderWrapper";

import "./index.scss";

const MobileTabHeader = ({ title }) => (
  <MobileHeaderWrapper className="mobile-tab-header">
    <Link to={SETTINGS_PAGE.path}>
      <img src={arrowLeftImg} alt="" />
      <div className="headline title"> {title} </div>
    </Link>
  </MobileHeaderWrapper>
);

export default MobileTabHeader;
