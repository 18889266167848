import React from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, Search, useDebounce } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getProductPriceRange } from "src/api";
import { ProductPriceRangeModel } from "src/models";

import styles from "./filters-panel.module.css";
import { ReactComponent as FiltersSVG } from "src/external-ts/assets/images/media/filter-grey.svg";

export const FiltersPanel: React.FC = observer(() => {
  const { debounce } = useDebounce(150);
  const { modalsStore, productsStore, localeStore } = useStores();

  const handleSearch = (title: string) => {
    const filters = { ...productsStore.filters, title, page: 1 };

    productsStore.setFilters(filters);
    debounce(() => productsStore.loadProductsList(filters));
  };

  const handleOpenFilters = async () => {
    //getting min and max price values for filters
    const { success } = await getProductPriceRange.getRequest().fetch();
    let priceRange: ProductPriceRangeModel = {
      min: null,
      max: null,
    };

    if (success) {
      priceRange = success;
    }

    modalsStore.open("FiltersSidebar", {
      initialFilters: productsStore.initialFilters,
      appliedFilters: productsStore.filters,
      priceRange,
      onApply: (filters) => productsStore.loadProductsList(filters),
      onClear: () => {
        // productsStore.resetFilters();
        // productsStore.loadProductsList();
      },
    });
  };

  return (
    <div className={styles.container}>
      <Search
        className={styles.search}
        searchValue={productsStore.filters.title}
        setSearch={handleSearch}
        placeholder={localeStore.t("components.business.search.placeholder")}
        data-cy="catalog-add-product-search"
      />
      <div className={styles.filtersButtonContainer} onClick={handleOpenFilters}>
        <SvgIcon icon={FiltersSVG} />
      </div>
    </div>
  );
});
