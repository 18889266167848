import React, { memo } from "react";
import { SvgIcon, clsx } from "@gemlightbox/core-kit";

import { ReactComponent as GemlightboxLogo } from "src/external-ts/assets/images/gemlightbox-logo-text.svg";
import styles from "./logo.module.css";

export type LogoProps = {
  className?: string;
};

export const Logo: React.FC<LogoProps> = memo(({ className }: LogoProps) => {
  return (
    <SvgIcon className={clsx(styles.logoContainer, className, "logo")} icon={GemlightboxLogo} />
  );
});

export default Logo;
