import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";

import { DeviceMetaDataModel } from "src/models";
import styles from "./media-info.module.css";

type MediaInfoProps = {
  metaData?: DeviceMetaDataModel;
  className?: string;
  mediaInfoItemClass?: string;
};

export const MediaInfo: React.FC<MediaInfoProps> = observer(
  ({ metaData, className = "", mediaInfoItemClass = "" }) => {
    const { localeStore } = useStores();

    return (
      <div className={clsx(styles.mediaDetails, className)}>
        <div className={clsx(styles.mediaDetailsItem, mediaInfoItemClass)}>
          <span>{localeStore.t('media["media-info"].device')}</span>
          <span>{metaData?.deviceName || "--"}</span>
        </div>
        <div className={clsx(styles.mediaDetailsItem, mediaInfoItemClass)}>
          <span>{localeStore.t('media["media-info"].manufacturer')}</span>
          <span>{metaData?.manufacturer || "--"}</span>
        </div>
        <div className={clsx(styles.mediaDetailsItem, mediaInfoItemClass)}>
          <span>{localeStore.t('media["media-info"]["serial-number"]')}</span>
          <span>{metaData?.serialNumber || "--"}</span>
        </div>
      </div>
    );
  },
);
