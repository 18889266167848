import React from "react";
import { observer } from "mobx-react-lite";

import { FiltersType, SelectedFilters } from "src/external-ts/components";
import { useStores } from "src/hooks";

export const ProductsSelectedFiltersPanel: React.FC = observer(() => {
  const { productsStore } = useStores();

  const handleFiltersChange = (filters: FiltersType) => productsStore.loadProductsList(filters);
  const handleFiltersClear = () => {
    productsStore.resetFilters();
    productsStore.loadProductsList();
  };

  return (
    <SelectedFilters
      filters={productsStore.filters}
      filtersAmount={productsStore.filtersAmount}
      onChange={handleFiltersChange}
      onClear={handleFiltersClear}
    />
  );
});
