import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { observer } from "mobx-react-lite";
import { useDidUpdate } from "@gemlightbox/core-kit";

import ShopifyStatusBar from "src/containers/settings/integrations/Shopify/ShopifyStatusBar";
import { useStores } from "src/hooks";
import { changeSelectedParams } from "src/redux/reducers/shopify";
import OverlayLoader from "src/components/OverlayLoader";
import IntegrationAttributes from "src/containers/settings/integrations/IntegrationAttributes";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { INTEGRATION_STATUSES } from "src/constants";

import shopifyLogo from "src/containers/settings/integrations/images/shopify-logo.svg";
import "./index.scss";

const ShopifySettings = observer(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { localeStore } = useStores();

  const {
    loaded,
    settings: { availableParams, selectedParams, maxCustomParams },
  } = useSelector(({ shopifyReducer }) => shopifyReducer);
  const activeShopifyShop = useSelector(({ userReducer }) => userReducer.activeShopifyShop);

  useDidUpdate(
    () => {
      if (!activeShopifyShop) navigate(getSettingsTab(SettingTabNames.integrations).item.path);
    },
    [activeShopifyShop],
    true,
  );

  const handleChangeSelectedAttributes = (attributes) => dispatch(changeSelectedParams(attributes));

  return (
    <div className="shopify-settings">
      <div className="title label">
        {localeStore.t('settings.integrations.shopify["shopify-settings"].title')}
      </div>

      {!loaded && <OverlayLoader />}

      <div className="list">
        <div className="list-item">
          <span> 1. </span>
          {localeStore.t('settings.integrations.shopify["shopify-settings"].items.first')}
        </div>

        <div className="list-item">
          <span> 2. </span>
          {localeStore.t('settings.integrations.shopify["shopify-settings"].items.second')}
        </div>

        <div className="list-item">
          <span> 3. </span>
          {localeStore.t('settings.integrations.shopify["shopify-settings"].items.third')}
        </div>

        <div className="list-item">
          <span> 4. </span>
          {localeStore.t('settings.integrations.shopify["shopify-settings"].items.fourth.text')}
        </div>
      </div>

      {loaded && (
        <>
          <IntegrationAttributes
            attributes={availableParams}
            selectedAttributes={selectedParams}
            setSelectedAttributes={handleChangeSelectedAttributes}
            maxCustomAttributes={maxCustomParams}
            integrationLogo={shopifyLogo}
          />
          <ShopifyStatusBar status={INTEGRATION_STATUSES.SETTINGS} />
        </>
      )}
    </div>
  );
});

export default ShopifySettings;
