import { makeAutoObservable, runInAction } from "mobx";

import { getProductTypes } from "src/api";
import { ProductTypeOutput } from "src/models";

export class CategoriesStore {
  private _loading = false;
  private _categories: ProductTypeOutput = {
    default: [],
    custom: [],
  };

  public get loading() {
    return this._loading;
  }

  public get categories() {
    return this._categories;
  }

  public get defaultCategories() {
    return this._categories.default;
  }

  public get customCategories() {
    return this._categories.custom;
  }

  constructor() {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async loadCategoriesList() {
    runInAction(() => {
      this._loading = true;
    });

    const { success } = await getProductTypes.getRequest().fetch();

    runInAction(() => {
      if (success) {
        this._categories = success;
      }

      this._loading = false;
    });
  }
  /* Requests ↑ */

  /* UI State ↓ */
  /* UI State  ↑  */

  /* Helpers ↓ */
  /* Helpers ↑ */
}
