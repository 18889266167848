import React from "react";
import { observer } from "mobx-react-lite";
import {
  curry,
  handleSetRef,
  RefModel,
  TableNew,
  useVirtualizedLoading,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { catalogRowMinHeight } from "../../catalogs.constants";
import { Thead } from "./t-head";
import { Tbody } from "./t-body";

import styles from "./table-view.module.css";

export type TableViewProps = {
  containerRef: RefModel<HTMLDivElement>;
};

export const TableView: React.FC<TableViewProps> = observer(({ containerRef }) => {
  const { catalogsStore } = useStores();

  const { virtualizedItems, virtualizingContainerRef, virtualizingContentRef, onScroll } =
    useVirtualizedLoading({
      itemsAmount: catalogsStore.catalogList.length,
      minItemHeight: catalogRowMinHeight,
    });

  return (
    <TableNew
      className={styles.catalogsTableContainer}
      forwardRef={curry(handleSetRef)([virtualizingContainerRef, containerRef])}
      onScroll={onScroll}
    >
      <Thead />
      <Tbody
        virtualizedItems={virtualizedItems}
        forwardRef={curry(handleSetRef)(virtualizingContentRef)}
      />
    </TableNew>
  );
});
