import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation } from "react-router-dom";
import { useStores } from "src/hooks";
import { config } from "src/config/environment";

export const ConfirmEmailPage: React.FC = observer(() => {
  const { localeStore, notificationStore } = useStores();
  const navigate = useNavigate();
  const loc = useLocation();

  const params = new URLSearchParams(loc.search);
  const type = params.get("type");

  useEffect(() => {
    notificationStore.openLoader({
      loaderType: "diamond-loader",
      appearance: "secondary",
      title: localeStore.t('["ar-media"]["media-editor"].loader.title'),
      subtitle: localeStore.t('["ar-media"]["media-editor"].loader.subtitle'),
    });

    notificationStore.closeLoader();
    if (type === "signup") {
      confirmEmail("userConfirmEmail");
    } else if (type === "resetpassword") {
      confirmEmail("userForgotConfirm");
    } else {
      goLogin();
    }
  }, []);

  async function goLogin() {
    navigate("/auth/login");
  }

  async function confirmEmail(mutationName: string) {
    const guid = params.get("guid");
    const result = await fetch(config.graphqlURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
          mutation {
            ${mutationName}(guid: "${guid}") {
              data
            }
          }
        `,
      }),
    }).then((res) => res.json());
    if (result.data[mutationName] && result.data[mutationName].data) {
      return (location.href = result.data[mutationName].data);
    }
    goLogin();
  }

  return <div></div>;
});

export default ConfirmEmailPage;
