import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Image, Typography, UseBooleanReturnType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getStaticUrl } from "src/utils";
import { BlockWrapper } from "../block-wrapper";
import { BlockTitle } from "../block-title";
import {
  removeBackgroundBlockSubId,
  removeBackgroundItems,
} from "./remove-background-block.constants";

import styles from "./remove-background-block.module.css";

type RemoveBackgroundBlockProps = {
  visible: UseBooleanReturnType;
  contentSet: React.Dispatch<React.SetStateAction<string>>;
};

export const RemoveBackgroundBlock: React.FC<RemoveBackgroundBlockProps> = observer(
  ({ visible, contentSet }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { tooltipStore, localeStore, subscriptionsStore, userStore } = useStores();
    const { isFreeSubscription } = subscriptionsStore;

    const getRemoveBackgroundItemsLocalized = () => {
      return removeBackgroundItems.map((item) => {
        switch (item.type) {
          case "black":
            return {
              ...item,
              tooltipText: localeStore.t('create["remove-background-block"].items.black'),
            };
          case "white":
            return {
              ...item,
              tooltipText: localeStore.t('create["remove-background-block"].items.white'),
            };
          case "transparent":
            return {
              ...item,
              tooltipText: localeStore.t('create["remove-background-block"].items.transparent'),
            };
        }
      });
    };

    const handleMouseEnter = (tooltipText: string) => (e: React.MouseEvent<HTMLDivElement>) => {
      tooltipStore.open(<Typography size="extraSmall">{tooltipText}</Typography>, {
        target: e.currentTarget,
        appearance: "secondary",
        position: "bottom",
        withAngle: true,
      });
    };

    const handleMouseLeave = () => tooltipStore.close();

    const handleClickItem = (itemType: string) => () => {
      tooltipStore.close();
      if (
        isFreeSubscription &&
        (userStore.userUsage?.countBackgroundRemoval ?? 0) >=
          subscriptionsStore.limits.count_removal_background
      ) {
        contentSet(localeStore.t('common["pro-user"]["remove-bg-upgrade-text"]'));
        visible.setTruthy();
        return;
      }
      navigate(location.pathname + "/remove-background/" + itemType);
    };

    return (
      <BlockWrapper>
        <BlockTitle
          title={localeStore.t('create["remove-background-block"].title')}
          tooltipText={localeStore.t('create["remove-background-block"]["tooltip-text"]')}
          showTag={isFreeSubscription}
          count={Math.max(
            0,
            subscriptionsStore.limits.count_removal_background -
              (userStore.userUsage?.countBackgroundRemoval ?? 0),
          )}
          total={subscriptionsStore.limits.count_removal_background}
        />
        <div id={removeBackgroundBlockSubId} className={styles.removeBackgroundItemsContainer}>
          {getRemoveBackgroundItemsLocalized().map(({ type, staticUrl, tooltipText }) => (
            <div
              key={type}
              className={styles.removeBackgroundItem}
              onMouseEnter={handleMouseEnter(tooltipText)}
              onMouseLeave={handleMouseLeave}
              onClick={handleClickItem(type)}
              data-name={`remove-${type}-bg`}
            >
              <Image src={getStaticUrl(staticUrl)} size={[72, 88]} loading="eager" />
            </div>
          ))}
        </div>
      </BlockWrapper>
    );
  },
);

export default RemoveBackgroundBlock;
