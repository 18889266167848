type BillingTableField = {
  name: "orderId" | "date" | "amount" | "status" | "export";
};
export const billingTableFields: BillingTableField[] = [
  { name: "orderId" },
  { name: "date" },
  { name: "amount" },
  { name: "status" },
  { name: "export" },
];
