import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { TableRow, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MediaPreview, NUMBER_OF_MEDIA_TO_PREVIEW } from "../../../../media-preview";

import styles from "./media-without-sku-row.module.css";

type MediaWithoutSKURowProps = {
  style?: React.CSSProperties;
};

export const MediaWithoutSKURow: React.FC<MediaWithoutSKURowProps> = observer(({ style }) => {
  const { mediaStore, modalsStore, localeStore } = useStores();

  const mediaPreviewCellRef = useRef<HTMLTableCellElement | null>(null);

  const handleRowClick = () => modalsStore.open("NoSKUMediaSidebar", {});

  return (
    <TableRow className={styles.container} style={style} onClick={handleRowClick}>
      <div className={styles.titleContainerCell}>
        <Typography size="medium600" color="textSecondary">
          {localeStore.t(
            'products["products-list"]["products-table"]["products-table-body"]["media-without-sku-row"].title',
          )}
        </Typography>
      </div>
      <div className={styles.mediaPreviewCell} ref={mediaPreviewCellRef}>
        <MediaPreview
          className={styles.mediaPreview}
          view={"table"}
          totalMediaAmount={mediaStore.resultsMediaAmount}
          mediaToShow={mediaStore.mediaList.slice(0, NUMBER_OF_MEDIA_TO_PREVIEW)}
        />
      </div>
    </TableRow>
  );
});

export default MediaWithoutSKURow;
