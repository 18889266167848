import React from "react";
import { observer } from "mobx-react-lite";
import { Checkbox, Typography } from "@gemlightbox/core-kit";

import { AccessTypes } from "src/containers/settings/subaccounts/subaccounts.constants";
import { useStores } from "src/hooks";

import styles from "./access.module.css";

export type AccessProps = {
  access: string;
  onChange: (value: AccessTypes) => void;
};

export const Access: React.FC<AccessProps> = observer(({ access, onChange }) => {
  const { localeStore } = useStores();

  return (
    <div className={styles.container}>
      <div className={styles.checkboxContainer}>
        <Checkbox
          className={styles.checkbox}
          label={localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"].access["member-label"]',
          )}
          checked={access === AccessTypes.member}
          onChange={() =>
            onChange(access === AccessTypes.member ? AccessTypes.viewer : AccessTypes.member)
          }
          disableError
        />
        <Typography className={styles.tip} size="extraSmall" color="textTertiary">
          {localeStore.t(
            'settings.subaccounts["create-invitation-link-modal"].access["member-tip"]',
          )}
        </Typography>
      </div>
      {/* viewer access not implemented yet */}
      {/* <div className={styles.checkboxContainer}>
        <Checkbox
          className={styles.checkbox}
          label="Viewer"
          checked={access === AccessTypes.viewer}
          onChange={() => onChange(AccessTypes.viewer)}
          disableError
        />
        <div className={styles.tip}>Members can only view your gallery, products and catalogs.</div>
      </div> */}
    </div>
  );
});

export default Access;
