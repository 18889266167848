import React from "react";
import { Table } from "@gemlightbox/core-kit";

import { OrderModel } from "src/api";
import { PaymentsTableBody } from "./payments-table-body";
import { PaymentsTableHead } from "./payments-table-head";

import styles from "./payments-table.module.css";

type PaymentsTableProps = {
  data: OrderModel[];
  onOrderClick: (orderId: number) => void;
};

export const PaymentsTable: React.FC<PaymentsTableProps> = ({ data, onOrderClick }) => {
  return (
    <Table className={styles.paymentsTableContainer}>
      <PaymentsTableHead />
      <PaymentsTableBody data={data} onOrderClick={onOrderClick} />
    </Table>
  );
};
