import { makeAutoObservable, runInAction } from "mobx";
import { Nullable } from "@gemlightbox/core-kit";

import { getDiffInDays, getExpiredDate } from "src/common/helpers/subscriptions.helpers";
import {
  additionalSubGbAddonId,
  additionalSubGbAddonMonthlyPriceId,
  additionalSubGbAddonYearlyPriceId,
  newPROPlanId,
  proPlanId,
  proPlanMonthlyPriceId,
  proPlanYearlyPriceId,
  oldStarterPlanId,
  starterPlanId,
  starterPlanMonthlyPriceId,
  starterPlanYearlyPriceId,
  proActivatePlanMonthlyPriceId,
  proPlanIdV2,
} from "src/containers/settings/subscriptions/subscriptions.constants";
import {
  FormattedBillingInfo,
  deleteCard,
  deleteSubscription,
  getCards,
  getSubscription,
  postSubscriptionUpcomingInvoicePreview,
  getSubscriptionPlans,
  putCard,
  putRenewSubscription,
  PostSubscriptionUpcomingInvoicePreviewData,
} from "src/api";
import { GetSubscriptionPlansResponse } from "src/api";
import { SubscriptionLimitsModel, CardModel } from "src/models";
import { RootStore } from "../root.store";

export class SubscriptionsStore {
  constructor(private readonly _rootStore: RootStore) {
    makeAutoObservable(this);
  }

  private _paymentCardsList: CardModel[] = [];
  private _defaultPaymentCardIndex = -1;
  private _paymentCardsLoading = false;
  private set _setPaymentCardList(cards: CardModel[]) {
    this._paymentCardsList = cards;
    this._defaultPaymentCardIndex = cards.findIndex((card) => card.isDefault);
  }

  private _isLoadingSubscription = false;
  public get isLoadingSubscription() {
    return this._isLoadingSubscription;
  }

  private _subscription: Nullable<any> = null;
  public get subscription() {
    return this._subscription;
  }

  private _isLoadingPlans = false;
  public get isLoadingPlans() {
    return this._isLoadingPlans;
  }

  private _subscriptionPlans: GetSubscriptionPlansResponse[] = [];
  public get subscriptionPlans() {
    return this._subscriptionPlans;
  }

  private _isSubscribed = false;
  public get isSubscribed() {
    return this._isSubscribed;
  }

  private _isStarter = false;
  public get isStarter() {
    return this._isStarter;
  }

  public get isFreeSubscription() {
    return !this._isSubscribed;
  }

  public get isCustomSubscription() {
    return this.isSubscribed && this.subscription.product.unit_label === "enterprise";
  }

  public get isProSubscription() {
    return (
      this.subscription?.plan?.product === proPlanId ||
      this.subscription?.plan?.product === newPROPlanId ||
      this.subscription?.plan?.product === proPlanIdV2
    );
  }

  public get isProSubscriptionV2() {
    return this.subscription?.plan?.product === proPlanIdV2;
  }

  public get isProMonthlySubscription() {
    return (
      this.isProSubscription &&
      !this.isFreeSubscription &&
      !this.isStarterSubscription &&
      !this.isCustomSubscription &&
      this.subscription?.plan?.interval === "month"
    );
  }

  public get isProYearlySubscription() {
    return (
      this.isProSubscription &&
      !this.isFreeSubscription &&
      !this.isStarterSubscription &&
      !this.isCustomSubscription &&
      this.subscription?.plan?.interval === "year"
    );
  }

  public get isProLifetimeSubscription() {
    // const coupon = this.subscription?.discount?.coupon;
    // return coupon?.valid && coupon?.duration === "forever" && coupon?.percent_off === 100;
    return this.isProLifetimeByOff100 || this.isProLifetime;
  }

  public get isProLifetimeByOff100() {
    return !!this.subscription?.isProLifetimeByOff100;
  }

  public get isProLifetime() {
    return !!this.subscription?.isProLifetime;
  }

  public get isOldStarterSubscription() {
    return this.subscription?.plan?.product === oldStarterPlanId;
  }

  public get isStarterSubscription() {
    return (
      this.subscription?.plan?.product === starterPlanId ||
      this.subscription?.plan?.product === oldStarterPlanId
    );
  }

  public get isStarterMonthlySubscription() {
    return (
      this.isStarterSubscription &&
      !this.isFreeSubscription &&
      !this.isProSubscription &&
      !this.isCustomSubscription &&
      this.subscription?.plan?.interval === "month"
    );
  }

  public get isStarterYearlySubscription() {
    return (
      this.isStarterSubscription &&
      !this.isFreeSubscription &&
      !this.isProSubscription &&
      !this.isCustomSubscription &&
      this.subscription?.plan?.interval === "year"
    );
  }

  public get isCancellingSubscription() {
    return (
      this.subscription &&
      !!this.subscription.cancel_at &&
      !this._rootStore.stores.userStore?.isShopifyUser
    );
  }

  public get starterSubscriptionInterval() {
    return this.isStarterMonthlySubscription
      ? "monthly"
      : this.isStarterYearlySubscription
      ? "yearly"
      : undefined;
  }

  public get proSubscriptionInterval() {
    return this.isProLifetimeSubscription
      ? "lifetime"
      : this.isProYearlySubscription
      ? "yearly"
      : this.isProMonthlySubscription
      ? "monthly"
      : undefined;
  }

  public get currentPlan() {
    const currentPlanId = this.subscription?.plan?.product;

    return this.subscriptionPlans.find(({ plan_id }) => plan_id === currentPlanId);
  }

  public get proPlan() {
    return this.subscriptionPlans.find(({ plan_id }) => plan_id === proPlanId);
  }

  public get proPlanV2() {
    return this.subscriptionPlans.find(({ plan_id }) => plan_id === proPlanIdV2);
  }

  public get starterPlan() {
    return this.subscriptionPlans.find(({ plan_id }) => plan_id === starterPlanId);
  }

  public get subGbAddonsAmount(): number {
    return (
      this.subscription?.additional?.find(
        ({ price }: any) => price.product === additionalSubGbAddonId,
      )?.quantity || 0
    );
  }

  public get planInterval() {
    if (this.isProLifetimeSubscription) return "lifetime";
    if (this.subscription?.plan?.interval === "month") return "monthly";
    if (this.subscription?.plan?.interval === "year") return "yearly";
  }

  public get proPlanPrices() {
    const monthlyPrice =
      this.proPlan?.prices.find(({ id }: any) => id === proPlanMonthlyPriceId)?.amount || 0;
    const monthlyActivatePrice =
      this.proPlan?.prices.find(({ id }: any) => id === proActivatePlanMonthlyPriceId)?.amount || 0;
    const yearlyPrice =
      this.proPlan?.prices.find(({ id }: any) => id === proPlanYearlyPriceId)?.amount || 0;
    return { monthlyPrice, monthlyActivatePrice, yearlyPrice };
  }

  public get starterPlanPrices() {
    const monthlyPrice =
      this.starterPlan?.prices.find(({ id }: any) => id === starterPlanMonthlyPriceId)?.amount || 0;
    const yearlyPrice =
      this.starterPlan?.prices.find(({ id }: any) => id === starterPlanYearlyPriceId)?.amount || 0;
    return { monthlyPrice, yearlyPrice };
  }

  public get subaccountAddonPrices() {
    const plan = this.subscriptionPlans.find(
      ({ plan_id }: any) => plan_id === additionalSubGbAddonId,
    );
    const monthlyPrice =
      plan?.prices.find(({ id }: any) => id === additionalSubGbAddonMonthlyPriceId)?.amount || 0;
    const yearlyPrice =
      plan?.prices.find(({ id }: any) => id === additionalSubGbAddonYearlyPriceId)?.amount || 0;

    return { monthlyPrice, yearlyPrice };
  }

  public get limits(): SubscriptionLimitsModel {
    return this._subscription?.limits || {};
  }

  public get storageLimit() {
    return this.limits?.limit_storage || 3;
  }

  public get subaccountsLimit() {
    return this.limits.subaccounts || 0;
  }

  public get getExpireDay() {
    return () => getDiffInDays(this.subscription);
  }

  public get getExpireDate() {
    return (onlyCurrentPeriod?: boolean) => getExpiredDate(this.subscription, onlyCurrentPeriod);
  }

  public get paymentCardList() {
    return this._paymentCardsList;
  }

  public get paymentCardsLoading() {
    return this._paymentCardsLoading;
  }

  /* Requests ↓ */
  public async loadSubscription() {
    runInAction(() => {
      this._isLoadingSubscription = true;
    });

    const { success, error } = await getSubscription.getRequest().fetch();

    runInAction(() => {
      this._isLoadingSubscription = false;

      if (success) {
        this._subscription = success;
        this._isSubscribed = true;
        this._isStarter = success.product.name.toLowerCase() === "starter";
      } else {
        this._subscription = error?.originalError;
        this._isSubscribed = false;
        this._isStarter = false;
      }
    });
  }

  public async loadSubscriptionPlans() {
    runInAction(() => {
      this._isLoadingPlans = true;
    });

    const { success } = await getSubscriptionPlans.getRequest().fetch();

    runInAction(() => {
      if (success) this._subscriptionPlans = success;
      this._isLoadingPlans = false;
    });
  }

  public async removeSubscription() {
    const { success } = await deleteSubscription.getRequest().fetch();

    runInAction(async () => {
      if (success) {
        await this.loadSubscription();
        await this.loadSubscriptionPlans();
      }
    });
  }

  public async renewSubscription() {
    const { success } = await putRenewSubscription.getRequest().fetch();

    runInAction(async () => {
      if (success) {
        await this.loadSubscription();
        await this.loadSubscriptionPlans();
      }
    });
  }

  public async loadPaymentCards() {
    if (this._paymentCardsLoading) return;

    runInAction(() => {
      this._paymentCardsLoading = true;
    });

    const { success } = await getCards.getRequest().fetch();

    runInAction(() => {
      if (success) this._setPaymentCardList = success;
      this._paymentCardsLoading = false;
    });
  }

  public async changeDefaultCard(cardId: CardModel["id"], cardIndex: number) {
    if (this._paymentCardsLoading) return;

    runInAction(() => {
      this._paymentCardsLoading = true;
    });

    this._paymentCardsList[cardIndex].isDefault = true;
    if (this._defaultPaymentCardIndex > -1) {
      this._paymentCardsList[this._defaultPaymentCardIndex].isDefault = false;
    }

    const { details } = await putCard.getRequest({ data: { card: cardId } }).fetch();

    runInAction(() => {
      if (details.isSuccess) {
        this._defaultPaymentCardIndex = cardIndex;
      } else {
        this._paymentCardsList[cardIndex].isDefault = false;
        if (this._defaultPaymentCardIndex > -1) {
          this._paymentCardsList[this._defaultPaymentCardIndex].isDefault = true;
        }
      }
      this._paymentCardsLoading = false;
    });
  }

  public async deletePaymentCard(cardId: string) {
    const { details } = await deleteCard.getRequest({ params: { cardId } }).fetch();

    runInAction(async () => {
      if (details.isSuccess) {
        this._setPaymentCardList = this.paymentCardList.filter((card) => {
          return card.id !== cardId;
        });
      }
    });
  }

  private _formattedBillingInfo?: FormattedBillingInfo;
  public get formattedBillingInfo() {
    return this._formattedBillingInfo;
  }

  public calculateCost(priceID: string, quantity: number) {
    const proPlanPriceId =
      additionalSubGbAddonYearlyPriceId === priceID ? proPlanYearlyPriceId : proPlanMonthlyPriceId;
    const data = {
      additionals: [
        {
          price: priceID,
          quantity: quantity,
        },
      ],
    } as PostSubscriptionUpcomingInvoicePreviewData;
    if (!this.isProLifetimeSubscription) {
      data.main = {
        price: proPlanPriceId,
        quantity: 1,
      };
    }

    const result = postSubscriptionUpcomingInvoicePreview.getRequest({ data }).fetch();
    result
      .then(({ success }) => {
        if (success) {
          this._formattedBillingInfo = {
            current_amount_due: success.current_amount_due / 100,
            next_amount_due: success.next_amount_due / 100,
            next_billing_date: new Date(success.next_billing_date * 1000),
            period_amount_due: success.period_amount_due / 100,
          } as FormattedBillingInfo;
        } else {
          this._formattedBillingInfo = undefined;
        }
      })
      .catch(() => (this._formattedBillingInfo = undefined));
  }
  /* Requests ↑ */
}
