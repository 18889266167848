export const ErrorCodes = {
  // Product Codes:
  PRODUCT_MAX_GPT: "gpt:limit_exceeded",
  PRODUCT_TITLE_EXISTS: "product:already_exist",

  // Subscription Codes:
  SUBSCRIPTION_REQUIRE_ACTION: "subscription_payment_intent_requires_action",
  SUBSCRIPTION_NOT_FOUND: "subscription:not_found",

  // Catalog Codes:
  CATALOGS_LIMIT_EXCEEDED: "sub:catalogs_limit_exceeded",
  CATALOGS_NAME_EXISTS: "catalog:name_exists",

  // Media Codes:
  MEDIA_UPLOAD_STORAGE_EXCEEDED: "limit:storage_exceeded",
  MEDIA_BACKGROUND_REMOVE_LIMIT_EXCEEDED: "media:background_removal_limit_exceeded",
  MEDIA_RETOUCH_LIMIT_EXCEEDED: "media:retouch_limit_exceeded",
  MEDIA_DUST_REMOVAL_LIMIT_EXCEEDED: "media:dust_removal_limit_exceeded",
  MEDIA_BLINK_CREATION_ATTEMPTS_LIMIT_EXCEEDED: "media:blink_creation_attempts_limit_exceeded",
  MEDIA_BLINKS_LIMIT_EXCEEDED: "media:blinks_limit_exceeded",
  MEDIA_ASSIGN_LIMIT_EXCEEDED: "product:maximum_media_limit",
  MEDIA_BLINK_ASSIGN_LIMIT_EXCEEDED: "media:maximum_blink_limit",

  // Attribute Codes:
  ATTRIBUTE_NAME_EXISTS: "attribute:duplicate_name",

  // User Codes:
  USER_RESET_PASSWORD_TOKEN_EXPIRED: "user:token_expired",
  NOT_CONFIRMED_MOBILE_EMAIL_ERROR: "signin:not_confirmed_mobile",
  CTF_MISSING_TOKEN: "chotaifook:missing_token",
};
