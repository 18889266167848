import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Modal,
  Button,
  Heading,
  Typography,
  Loader,
  useCall,
  useDidUpdate,
  useBoolean,
  clsx,
  globalStyles,
} from "@gemlightbox/core-kit";

import { getRapnetProducts, GetRapnetProductsResponseData } from "src/api";
import { useStores } from "src/hooks";
import { limitErrors } from "./rapnet-error-modal.constants";

import styles from "./rapnet-error-modal.module.css";

export type RapnetErrorModalProps = {
  isOpen: boolean;
  setClose: VoidFunction;
};

export const RapnetErrorModal: React.FC<RapnetErrorModalProps> = observer(
  ({ isOpen, setClose }: RapnetErrorModalProps) => {
    const { notificationStore, localeStore } = useStores();

    const canShowMoreBoolean = useBoolean(true);

    const [page, setPage] = useState(1);
    const [errorProducts, setErrorProducts] = useState<
      GetRapnetProductsResponseData["products"]["rows"]
    >([]);
    const getRapnetProductsCall = useCall(getRapnetProducts);
    getRapnetProductsCall.onCallSuccess(({ products }) => {
      if (products.total_count) {
        canShowMoreBoolean.setValue(products.total_count > page * limitErrors);
        setErrorProducts((prevState) => prevState.concat(products.rows));
        return;
      }

      handleSuccess();
    });
    getRapnetProductsCall.onCallError(() => handleError());

    const loading = getRapnetProductsCall.submitting && !errorProducts.length;

    const handleFinalClose = () => {
      setPage(1);
      setErrorProducts([]);
    };

    const handleFetchProducts = (page = 1) => {
      getRapnetProductsCall.submit({
        queryParams: {
          syncStatus: "error",
          limit: limitErrors,
          page,
        },
      });
      setPage(page);
    };

    const handleError = () => {
      notificationStore.open({
        title: localeStore.t('common.warnings["something-wrong"]'),
        confirmAppearance: "secondary",
        confirmText: localeStore.t('common.buttons["try-again"]'),
        cancelText: "",
        icon: "exclamation",
        onlyConfirm: true,
      });

      setClose();
    };

    const handleSuccess = () => {
      notificationStore.open({
        title: localeStore.t('integrations.general["sync-success"]'),
        confirmAppearance: "secondary",
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: "",
        onlyConfirm: true,
      });

      setClose();
    };

    const handleLoadMore = () => handleFetchProducts(page + 1);

    useDidUpdate(
      () => {
        if (!isOpen) return;
        handleFetchProducts();
      },
      [isOpen],
      true,
    );

    return (
      <Modal
        contentClassName={styles.rapnetErrorModalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={handleFinalClose}
        disableBorderRadius
        disablePadding
        withCross
      >
        {loading && <Loader type="circle-loader" />}

        {!loading && (
          <>
            <Heading className={styles.rapnetErrorHeader} tag="h2">
              {localeStore.t('integrations.general["not-valid-products"]')}
            </Heading>
            <Heading className={styles.reasonsHeader} tag="h4">
              {localeStore.t("integrations.general.reasons")}
            </Heading>
            <div className={clsx(styles.reasonsWrapper, globalStyles.addScrollStyles)}>
              {errorProducts.map((errorProduct) => (
                <div key={errorProduct.title} className={styles.productReasonWrapper}>
                  <Typography size="small600" color="textSecondary">
                    {errorProduct.title}
                  </Typography>
                  <ul>
                    <li>
                      <Typography size="small">{errorProduct.syncMessage.shortMessage}</Typography>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
            {canShowMoreBoolean.value && (
              <Button
                className={styles.showMoreBtn}
                appearance="tertiaryOutlined"
                onClick={handleLoadMore}
                loading={getRapnetProductsCall.submitting}
              >
                {localeStore.t('common.buttons["show-more"]')}
              </Button>
            )}
          </>
        )}
      </Modal>
    );
  },
);

export default RapnetErrorModal;
