import { Params, RequestParams, RequestParamsKeys } from "./finish-import-modal.types";

export const getRequestParams = (params: Params): RequestParams => {
  return Object.entries(params).reduce((acc, [_, value]) => {
    const key = _ as RequestParamsKeys;

    if (value) acc[key] = true;
    else acc[key] = undefined;

    return acc;
  }, {} as RequestParams);
};
