import React from "react";
import { Typography } from "@gemlightbox/core-kit";

import styles from "./payment-info-card.module.css";

type PaymentInfoCardProps = {
  label: string;
  children: React.ReactNode;
  width?: number;
};

export const PaymentInfoCard: React.FC<PaymentInfoCardProps> = ({
  label,
  width = 220,
  children,
}) => {
  const containerWidthStyle = width + "px";

  return (
    <div className={styles.paymentInfoCardContainer} style={{ width: containerWidthStyle }}>
      <Typography size="small" color="textTertiary">
        {label}
      </Typography>
      {children}
    </div>
  );
};
