import React from "react";
import { observer } from "mobx-react-lite";
import { Button, clsx, SvgIcon, Typography } from "@gemlightbox/core-kit";

import { ProductVariantOption } from "../../product-variant.types";
import { productVariantsStore } from "../../product-variants.store";

import { ReactComponent as DragSVG } from "src/external-ts/assets/images/drag-grey.svg";
import { ReactComponent as PencilSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./variant-option.module.css";

export type VariantOptionProps = {
  index: number;
  isDraggedOption: boolean;
  isDropPosition: boolean;
  variantOption: ProductVariantOption;
  onOptionPointerDown: (event: React.PointerEvent<HTMLButtonElement>, index: number) => void;
};

export const VariantOption: React.FC<VariantOptionProps> = observer(
  ({
    index,
    isDraggedOption,
    isDropPosition,
    variantOption,
    onOptionPointerDown,
  }: VariantOptionProps) => {
    const disabledControls = productVariantsStore.isVariantOptionInProcess;

    const handleOptionPointerDown = (event: React.PointerEvent<HTMLButtonElement>) => {
      if (disabledControls) return;
      onOptionPointerDown(event, index);
    };

    const handleEditClick = () => productVariantsStore.openSidebar();

    const handleRemoveClick = () => productVariantsStore.removeOption(index);

    return (
      <div
        className={clsx(
          styles.variantOptionContainer,
          { [styles.inDrag]: isDraggedOption },
          { [styles.isDropPosition]: isDropPosition },
        )}
      >
        <Button
          className={styles.dragBtn}
          appearance="secondaryGhost"
          onPointerDown={handleOptionPointerDown}
          disabled={disabledControls}
        >
          <SvgIcon icon={DragSVG} />
        </Button>

        <div className={styles.variantOptionTextContent}>
          <Typography className={styles.variantOptionType} size="small600">
            {variantOption.name}
          </Typography>
          <div className={styles.variantOptionValuesContainer}>
            {variantOption.option_values.map(({ value }, i) => {
              if (!value) return;
              return (
                <div key={i} className={styles.optionValue}>
                  <Typography size="extraSmall500">{value}</Typography>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.variantOptionButtons}>
          <Button appearance="secondaryOutlined" onClick={handleEditClick}>
            <SvgIcon icon={PencilSVG} />
          </Button>
          <Button
            appearance="secondaryOutlined"
            onClick={handleRemoveClick}
            disabled={disabledControls}
          >
            <SvgIcon icon={TrashSVG} />
          </Button>
        </div>
      </div>
    );
  },
);

export default VariantOption;
