import React from "react";
import { observer } from "mobx-react-lite";

import ToastComponent from "src/components/ToastComponent";
import { useStores } from "src/hooks";

import { MAX_MEDIA_IN_PRODUCT } from "src/containers/products/products.constants";

type MaxMediaAssignedToastProps = {
  isOpened: boolean;
  onClose: VoidFunction;
};

export const MaxMediaAssignedToast: React.FC<MaxMediaAssignedToastProps> = observer(
  ({ isOpened, onClose }) => {
    const { localeStore } = useStores();

    return (
      <ToastComponent isOpened={isOpened} onClose={onClose}>
        <div className="label">
          {localeStore.t('["product-assign-media-sidebar"]["max-media-assigned-toast"].first')}{" "}
          &nbsp;
          {MAX_MEDIA_IN_PRODUCT}&nbsp;
          {localeStore.t('["product-assign-media-sidebar"]["max-media-assigned-toast"].second')}
        </div>
      </ToastComponent>
    );
  },
);

export default MaxMediaAssignedToast;
