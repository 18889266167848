import React, { memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Logo } from "src/external-ts/components";
import { LOGIN_PAGE } from "src/constants";
import { AuthContainer } from "../auth-container";
import { AuthMain } from "../auth-main";
import { ExpiredPage } from "./expired";
import { PasswordPage } from "./password";
import { RequestPage } from "./request";

export const ResetPage: React.FC = memo(() => {
  return (
    <AuthContainer fullScreen>
      <AuthMain fullScreen>
        <Logo />
        <Routes>
          <Route path="/request" element={<RequestPage />} />
          <Route path="/password" element={<PasswordPage />} />
          <Route path="/expired" element={<ExpiredPage />} />
          <Route path="*" element={<Navigate to={LOGIN_PAGE.path} />} />
        </Routes>
      </AuthMain>
    </AuthContainer>
  );
});

export default ResetPage;
