import { PaginationList, MaybeArray } from "@gemlightbox/core-kit";

import { dashboardApiMiddleware } from "src/api";
import {
  ProductModel,
  ProductPriceRangeModel,
  ProductTypeKeys,
  MediaTypeKeys,
  ProductTypeOutput,
  ProductRequestModel,
  ProductVariantOptionModel,
} from "src/models";
import { ProductCurrency } from "src/store";
import { pushDataLayerEvent } from "src/utils";
import {
  ExportedProductsListResponseDataType,
  ExportProductsRequestDataType,
  ExportProductsResponseDataType,
  PostProductVariantOptionDataType,
  PutProductVariantOptionDataType,
  PutProductVariantOptionOrderDataType,
  PutUpdateProductAttributeRequestData,
  PutUpdateProductAttributeResponseData,
} from "./product.interface";

export type GetProductsFilters = {
  title: string;
  detailedTitle: string;
  description: string;
  productType: ProductTypeKeys;
  mediaType: MediaTypeKeys;
  fromPrice: string;
  toPrice: string;
  user_id: string | number;
  page: number;
  limit: number;
  ids: Array<ProductModel["_id"]>;
  quantity: any[];
  attributes: any[];
};

export const getProducts = dashboardApiMiddleware<PaginationList<ProductModel>>()({
  method: "get",
  endpoint: "/api/product",
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
});

export const getProduct = dashboardApiMiddleware<ProductModel>()({
  method: "get",
  endpoint: "/api/product/:productId",
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
  auxiliaryKeys: {
    isDisableCache: true,
  },
});

export const getProductNameIsUnique = dashboardApiMiddleware<boolean>()({
  method: "get",
  endpoint: "/api/product/check/:title",
});

export const getProductPriceRange = dashboardApiMiddleware<ProductPriceRangeModel>()({
  method: "get",
  endpoint: "/api/product/price/range",
});

export const deleteProducts = dashboardApiMiddleware<PaginationList<ProductModel>>()({
  method: "delete",
  endpoint: "/api/product",
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
});

export const getProductTypes = dashboardApiMiddleware<ProductTypeOutput>()({
  method: "get",
  endpoint: "/api/product_types/list",
});

export const getProductVariantOptions = dashboardApiMiddleware<ProductVariantOptionModel[]>()({
  method: "get",
  endpoint: "/api/product/:product_id/variant/option",
});

export const postProduct = dashboardApiMiddleware<
  PaginationList<ProductModel>,
  MaybeArray<ProductRequestModel>
>()({
  method: "post",
  endpoint: "/api/product",
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
});

export const postProductVariantOption = dashboardApiMiddleware<
  { option: ProductVariantOptionModel },
  PostProductVariantOptionDataType
>()({
  method: "post",
  endpoint: "/api/product/:product_id/variant/option",
});

export const putProductVariantOption = dashboardApiMiddleware<
  { options: ProductVariantOptionModel[] },
  PutProductVariantOptionDataType
>()({
  method: "put",
  endpoint: "/api/product/:product_id/variant/option/:option_id",
});

export const putProductVariantOptionOrder = dashboardApiMiddleware<
  { options: ProductVariantOptionModel[] },
  PutProductVariantOptionOrderDataType
>()({
  method: "put",
  endpoint: "/api/product/:product_id/variant/order",
});

export const putUpdateProduct = dashboardApiMiddleware<
  PaginationList<ProductModel>,
  ProductRequestModel
>()({
  method: "put",
  endpoint: "/api/product/:productId",
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
});

export const putUpdateProductAttribute = dashboardApiMiddleware<
  PutUpdateProductAttributeResponseData,
  PutUpdateProductAttributeRequestData
>()({
  method: "put",
  endpoint: "/api/product/:productId/attribute/:attributeId",
});

export const putUpdateProducts = dashboardApiMiddleware<PaginationList<ProductModel>, any>()({
  method: "put",
  endpoint: "/api/product/batch",
});

export const putAssignMediaToProduct = dashboardApiMiddleware()({
  method: "put",
  endpoint: "/api/content/assign/:productId/", // + ids: 88292,88283
});

export const exportProductsCSVRequestCreator = dashboardApiMiddleware<string>()({
  method: "get",
  endpoint: "/api/product/export/csv",
  responseType: "text",
});

export const exportProductsCSV = (ids: Array<ProductModel["_id"]> = [], catalogId: string = "") => {
  const request = exportProductsCSVRequestCreator.getRequest({
    queryParams: { ids, catalog_id: catalogId },
  });

  request.events.on("success", (response) => {
    pushDataLayerEvent({ event: "export_products" });

    if (response.details.statusCode === 200) {
      const blob = new Blob([response.success], { type: "text/csv" });
      const filename = "products.csv";
      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");

      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        a.click();
      }
    }
  });

  request.fetch();
};

export const getCurrencies = dashboardApiMiddleware<ProductCurrency[]>()({
  method: "get",
  endpoint: "/api/product/currencies",
});

export const postExportAllProducts = dashboardApiMiddleware<
  ExportProductsResponseDataType,
  ExportProductsRequestDataType
>()({
  method: "post",
  endpoint: "/api/product/export/file",
});

export const getExportedProductsList =
  dashboardApiMiddleware<ExportedProductsListResponseDataType>()({
    method: "get",
    endpoint: "/api/product/export/list_files",
  });

export const deleteExportedProductsList = dashboardApiMiddleware()({
  method: "delete",
  endpoint: "/api/product/export/file/:id",
});

export const deleteProductsVariantOption = dashboardApiMiddleware<{
  options: ProductVariantOptionModel[];
}>()({
  method: "delete",
  endpoint: "/api/product/:product_id/variant/option/:option_id",
});
