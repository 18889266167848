import { AttributeType } from "src/models";

export const AttributeTypeLabelMap: Record<AttributeType, string> = {
  [AttributeType.text]: "Text",
  [AttributeType.number]: "Number",
  [AttributeType.select]: "Dropdown",
  [AttributeType.multiselect]: "Dropdown",
};

export const ATTRIBUTE_NUMBER_SETTINGS = [
  { name: "Currency", value: "currency" },
  { name: "%", value: "%" },
  { name: "Custom", value: "" },
] as const;

export const staticDefaultAttributesNames: readonly string[] = [
  "detailedTitle",
  "price",
  "description",
];
