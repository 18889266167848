import React from "react";

import Badge from "src/components/badges/Badge";

import "./index.scss";

const DangerBadge = ({ children, ...props }) => {
  return (
    <Badge className="danger-badge" {...props}>
      {children}
    </Badge>
  );
};

export default DangerBadge;
