export const orderTableColumns = [
  {
    name: "media",
    label: "",
  },
  {
    name: "sku",
    label: "SKU",
  },
  {
    name: "quantity",
    label: "Quantity",
  },
  {
    name: "price",
    label: "Unit Price",
  },
  {
    name: "amount",
    label: "Amount",
  },
];
