import {
  download,
  MaybeArray,
  QueryParams,
  queryStringify,
  toArrayOptions,
} from "@gemlightbox/core-kit";

import { MediaType } from "src/models";
import { pushDataLayerEvent } from "src/utils";
import { useStores } from "src/hooks";
import { ExtendedMediaModel } from "src/store";
import { config } from "src/config/environment";

export const downloadMedias = async (
  medias: MaybeArray<ExtendedMediaModel>,
  openDownloadModal = true,
  onClose?: VoidFunction,
) => {
  if (!medias) return;
  medias = toArrayOptions(medias);
  if (!medias.length) return;

  const { modalsStore, mediaStore, userStore, notificationStore } = useStores();

  const hasImages = medias.some(
    (media) => media.type === MediaType.image || media.type === MediaType.blink,
  );

  if (hasImages) {
    return modalsStore.open("DownloadMediaModal", {
      media: medias,
      type: "medias",
      onClose: onClose,
    });
  }
  const queryParams: QueryParams = {
    type: "png",
    imageIDS: medias.map(({ id }) => id),
    token: userStore.token,
    isUseSkuAsNames: true,
  };

  const query = queryStringify(queryParams);
  download(config.apiUrl + "/api/content/download/resize" + query);
  pushDataLayerEvent({ event: "gemhub:media:download" });

  if (!openDownloadModal) return;

  if (medias.length > 1) {
    notificationStore.openLoader({
      loaderType: "circle-loader",
    });
    const res = await mediaStore.createMediaCollection(medias);
    notificationStore.closeLoader();
    if (res) {
      modalsStore.open("DownloadModal", { data: res, type: "mediaCollection" });
    }
  } else {
    modalsStore.open("DownloadModal", { data: medias[0], type: "media" });
  }
};
