import React from "react";
import { observer } from "mobx-react-lite";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { clsx } from "@gemlightbox/core-kit";

import { AttributeModel } from "src/models";
import {
  checkIfAttributeIsCustom,
  checkIfAttributeIsDefault,
  checkIfAttributeIsStatic,
} from "src/utils";
import { WithDrag } from "./with-drag";
import { DefaultAttribute } from "./default-attribute";
import { CustomAttribute } from "./custom-attribute";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./attributes-list.module.css";

export interface AttributesListProps {
  attributes: AttributeModel[];
  onReorder: (data: AttributeModel[]) => void;
  onDefaultEdit: (data: AttributeModel) => void;
  onCustomEdit: (data: AttributeModel) => void;
  onCustomDelete: (data: AttributeModel) => void;
}

export const AttributesList: React.FC<AttributesListProps> = observer(
  ({ attributes, onReorder, onDefaultEdit, onCustomEdit, onCustomDelete }) => {
    const handleReorder = ({ source, destination }: DropResult) => {
      if (!destination) return;

      let result = [...attributes];
      const [sourceColumn] = result.splice(source.index, 1);

      result.splice(destination.index, 0, sourceColumn);
      result = result.map((attribute, index) => ({
        ...attribute,
        order_gallery: index,
      }));

      onReorder(result);
    };

    const renderClone = (
      provided: DraggableProvided,
      _: DraggableStateSnapshot,
      rubric: DraggableRubric,
    ) => {
      const attribute = attributes[rubric.source.index];
      const isDefault = checkIfAttributeIsDefault(attribute);

      if (isDefault) {
        return (
          <WithDrag provided={provided}>
            <DefaultAttribute data={attribute} />
          </WithDrag>
        );
      }

      return (
        <WithDrag provided={provided}>
          <CustomAttribute data={attribute} />
        </WithDrag>
      );
    };

    const renderOriginal = (attribute: AttributeModel, index: number) => {
      const isStatic = checkIfAttributeIsStatic(attribute);
      const isDraggable = !isStatic;
      const isDefault = checkIfAttributeIsDefault(attribute);
      const isCustom = checkIfAttributeIsCustom(attribute);

      if (isStatic) {
        return <DefaultAttribute data={attribute} onEdit={onDefaultEdit} />;
      }

      if (isDefault && isDraggable) {
        return (
          <Draggable draggableId={attribute.name} index={index}>
            {(provided) => (
              <WithDrag provided={provided}>
                <DefaultAttribute data={attribute} onEdit={onDefaultEdit} />
              </WithDrag>
            )}
          </Draggable>
        );
      }

      if (isCustom) {
        return (
          <Draggable draggableId={attribute.name} index={index}>
            {(provided) => (
              <WithDrag provided={provided}>
                <CustomAttribute data={attribute} onEdit={onCustomEdit} onDelete={onCustomDelete} />
              </WithDrag>
            )}
          </Draggable>
        );
      }
    };

    return (
      <div className={clsx(globalStyles.addScrollStyles, styles.container)}>
        <DragDropContext onDragEnd={handleReorder}>
          <Droppable droppableId="attributes" renderClone={renderClone}>
            {(provided) => (
              <div
                className={styles.container}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className={styles.list}>
                  {attributes.map((attribute, index) => (
                    <React.Fragment key={attribute.name}>
                      {renderOriginal(attribute, index)}
                    </React.Fragment>
                  ))}
                </div>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  },
);
