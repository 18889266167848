import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Pagination, useLocalStorage, useDidMount, useDidUpdate } from "@gemlightbox/core-kit";

import { PageContainer, PageHeader, ViewTypeKeys } from "src/external-ts/components";
import { usePageViewEvent, useStores } from "src/hooks";
import WSConnection from "src/common/socket";
import { CatalogControlPanel } from "./catalog-control-panel";
import { CatalogListPlaceholder } from "./catalogs-list-placeholder/catalog-list-placeholder";
import { catalogLocalStorageEntity } from "./catalogs.constants";
import { CatalogsList } from "./catalogs-list";

export const CatalogList = observer(() => {
  usePageViewEvent();

  const { catalogsStore, localeStore, userStore } = useStores();

  const catalogsContainerRef = useRef<HTMLDivElement>(null);

  const catalogLocalStorage = useLocalStorage<ViewTypeKeys>(catalogLocalStorageEntity, {
    initialValue: "table",
  });

  useDidMount(() => catalogsStore.loadCatalogList());

  useDidUpdate(
    () => {
      const catalogWS = new WSConnection("/catalog", userStore.token);

      catalogWS.on("catalog_list/CATALOG_RELOAD", () => catalogsStore.loadCatalogList());
      catalogWS.on("catalog/CATALOG_RELOAD", ({ payload }: any) => {
        payload?.catalog?.id ??
          catalogsStore.updateCatalogLocal(payload.catalog.id, payload.catalog);
      });

      return () => catalogWS.destroy();
    },
    [userStore.token],
    true,
  );

  const handlePageChange = async (page: number) => {
    await catalogsStore.loadCatalogList({ ...catalogsStore.filters, page });
    catalogsContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleLimitChange = (limit: number) => {
    catalogsStore.loadCatalogList({ ...catalogsStore.filters, limit, page: 1 });
    //scroll to top after page change
    catalogsContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  return (
    <PageContainer>
      <PageHeader title={localeStore.t("catalog.title")} showSubscriptionBadge />

      <CatalogControlPanel
        view={catalogLocalStorage.storeValue ?? "table"}
        onViewChange={catalogLocalStorage.setStoreValue}
      />

      {catalogsStore.catalogsAmount === 0 && <CatalogListPlaceholder />}
      {catalogsStore.catalogsAmount !== 0 && (
        <>
          <CatalogsList
            view={catalogLocalStorage.storeValue ?? "table"}
            containerRef={catalogsContainerRef}
          />

          <Pagination
            totalItemsText={localeStore.t('components.ui.pagination["total-items"]')}
            prevText={localeStore.t("components.ui.pagination.prev")}
            nextText={localeStore.t("components.ui.pagination.next")}
            perPageText={localeStore.t('components.ui.pagination["per-page"]')}
            page={catalogsStore.filters.page}
            limit={catalogsStore.filters.limit}
            totalPages={catalogsStore.totalPages}
            totalItems={catalogsStore.filteredCatalogsAmount}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
          />
        </>
      )}
    </PageContainer>
  );
});
