import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import AttributeLine from "./AttributeLine";
import { integrationAttributeNames, picupmediaAttributeNames } from "../integrations.constants";

import "./index.scss";

const IntegrationAttributes = observer(
  ({
    attributes,
    selectedAttributes,
    setSelectedAttributes,
    maxCustomAttributes,
    integrationLogo,
  }) => {
    const { localeStore } = useStores();

    const [newAttribute, setNewAttribute] = React.useState(null);

    const getFilteredOptions = () => {
      const options = getOptions(attributes);
      const selectedOptions = getOptions(selectedAttributes);

      return options.filter(({ value }) => !selectedOptions.find((item) => item.value === value));
    };

    const getOptions = (params) => {
      return Object.entries(params).reduce((acc, [type, values]) => {
        const newValues = values.map((value) => ({
          name: integrationAttributeNames[value] || value,
          value,
          type,
        }));

        return [...acc, ...newValues];
      }, []);
    };

    const handleAddAttribute = () => setNewAttribute({ name: "", value: "" });

    const handleChangeAttribute = (value, type) => {
      const newSelectedAttributes = Object.entries(selectedAttributes).reduce(
        (acc, [key, values]) => {
          if (key === type) {
            acc[key] = [...values, value];
          } else {
            acc[key] = values;
          }

          return acc;
        },
        {},
      );

      setSelectedAttributes(newSelectedAttributes);
      setNewAttribute(null);
    };

    const handleRemoveAttribute = (value, type) => {
      const newSelectedAttributes = Object.entries(selectedAttributes).reduce(
        (acc, [key, values]) => {
          if (key === type) {
            acc[key] = values.filter((val) => val !== value);
          } else {
            acc[key] = values;
          }

          return acc;
        },
        {},
      );

      setSelectedAttributes(newSelectedAttributes);
    };

    const checkIfIsDisabled = () =>
      !getFilteredOptions().length || selectedAttributes.custom.length >= maxCustomAttributes;

    const checkIfIsRemovable = (name) => name === "title" || name === "detailedTitle";

    const getPicupmediaValue = (name) => {
      let result = picupmediaAttributeNames[name] || name;

      if (name === "images") {
        result = "Media";
      }

      return result;
    };

    return (
      <div className="integration-attributes">
        <div className="attributes-list">
          {Object.entries(selectedAttributes).map(([key, names]) => (
            <React.Fragment key={key}>
              {names.map((name) => (
                <AttributeLine
                  key={name}
                  picupmediaValue={getPicupmediaValue(name)}
                  integrationValue={integrationAttributeNames[name] || name}
                  integrationLogo={integrationLogo}
                  readOnly={!!name}
                  options={getFilteredOptions()}
                  onChange={({ value }) => handleChangeAttribute(value, key)}
                  onRemove={
                    checkIfIsRemovable(name) ? undefined : () => handleRemoveAttribute(name, key)
                  }
                />
              ))}
            </React.Fragment>
          ))}
        </div>

        {newAttribute && (
          <AttributeLine
            picupmediaValue=""
            integrationValue=""
            integrationLogo={integrationLogo}
            options={getFilteredOptions()}
            onChange={({ value, type }) => handleChangeAttribute(value, type)}
            onRemove={() => setNewAttribute(null)}
          />
        )}

        <button
          disabled={checkIfIsDisabled()}
          className="primary-reverse-btn"
          onClick={checkIfIsDisabled() ? undefined : handleAddAttribute}
        >
          {localeStore.t('settings.integrations["integration-attributes"]["add-button"]')}
        </button>
      </div>
    );
  },
);

export default IntegrationAttributes;
