import React from "react";

import { ProductPriceRangeModel, ProductRangeModel } from "src/models";
import { QuantityExactFilter } from "./quantity-exact-filter";
import { QuantityFilter } from "./quantity-filter";
import { PriceFilter } from "./price-filter/price-filter";
import { SKUFilter } from "./sku-filter";
import { DescriptionFilter } from "./description-filter";
import { ProductTypeFilter } from "./product-type-filter";
import { TitleFilter } from "./title-filter";
import { MediaTypeFilter } from "./media-type-filter";
import { CreatedByUserFilter } from "./created-by-user-filter";
import { FiltersType } from "../filters-sidebar.types";
import { MediaExistFilter } from "./media-exist-filter";
import { MediaAccessFilter } from "./media-access-filter";

export interface DefaultFiltersInterface {
  filters: FiltersType;
  priceRange: ProductPriceRangeModel;
  quantityRange: ProductRangeModel;
  isFromPriceValid: boolean;
  isToPriceValid: boolean;
  isFromQuantityValid: boolean;
  isToQuantityValid: boolean;
  onFromPriceChange: (value: number) => void;
  onToPriceChange: (value: number) => void;
  onRangePriceChange: (value: number[]) => void;
  onFromQuantityChange: (value: number) => void;
  onToQuantityChange: (value: number) => void;
  onExactQuantityChange: (value: number) => void;
  onRangeQuantityChange: (value: number[]) => void;
  onMediaExistFilterChange: (value: string) => void;
  onLinkAccessFilterChange: (value: string) => void;
  onSKUChange: (value: string) => void;
  onTitleChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
  onProductTypeChange: (value: string) => void;
  onMediaTypeChange: (value: string) => void;
  onUserFilter: (value: string | number) => void;
  disableSKUFilter?: boolean;
  disableTitleFilter?: boolean;
  disableDescriptionFilter?: boolean;
  disableMediaTypeFilter?: boolean;
  quantityType?: "range" | "exact";
}

export const DefaultFilters: React.FC<DefaultFiltersInterface> = ({
  filters,
  priceRange,
  quantityRange,
  isFromPriceValid,
  isToPriceValid,
  isFromQuantityValid,
  isToQuantityValid,
  onLinkAccessFilterChange,
  onMediaExistFilterChange,
  onFromPriceChange,
  onToPriceChange,
  onRangePriceChange,
  onExactQuantityChange,
  onFromQuantityChange,
  onToQuantityChange,
  onRangeQuantityChange,
  onSKUChange,
  onTitleChange,
  onDescriptionChange,
  onProductTypeChange,
  onMediaTypeChange,
  onUserFilter,
  disableSKUFilter,
  disableTitleFilter,
  disableDescriptionFilter,
  disableMediaTypeFilter,
  quantityType = "range",
}) => {
  return (
    <>
      {!disableSKUFilter && <SKUFilter value={filters.title} onChange={onSKUChange} />}
      {!disableTitleFilter && (
        <TitleFilter value={filters.detailedTitle} onChange={onTitleChange} />
      )}
      {!disableDescriptionFilter && (
        <DescriptionFilter value={filters.description} onChange={onDescriptionChange} />
      )}
      <PriceFilter
        value={[filters.fromPrice, filters.toPrice]}
        priceRange={priceRange}
        isFromValid={isFromPriceValid}
        isToValid={isToPriceValid}
        onFromChange={onFromPriceChange}
        onToChange={onToPriceChange}
        onRangeChange={onRangePriceChange}
      />
      <ProductTypeFilter value={filters.productType} onChange={onProductTypeChange} />
      {quantityType === "range" ? (
        <QuantityFilter
          value={filters.quantity}
          quantityRange={quantityRange}
          isFromValid={isFromQuantityValid}
          isToValid={isToQuantityValid}
          onFromChange={onFromQuantityChange}
          onToChange={onToQuantityChange}
          onRangeChange={onRangeQuantityChange}
        />
      ) : (
        <QuantityExactFilter value={filters.quantity[0]} onChange={onExactQuantityChange} />
      )}

      {!disableMediaTypeFilter && (
        <MediaTypeFilter value={filters.mediaType} onChange={onMediaTypeChange} />
      )}
      <CreatedByUserFilter userId={filters.user_id} onUserFilter={onUserFilter} />
      <MediaExistFilter value={filters.isThereMedia} onChange={onMediaExistFilterChange} />
      <MediaAccessFilter value={filters.isPrivate} onChange={onLinkAccessFilterChange} />
    </>
  );
};
