import { ObjectType } from "@gemlightbox/core-kit";
import {
  CatalogFullModel,
  CatalogModel,
  CatalogProductModel,
  MediaCollectionModel,
  MediaModel,
  MediaType,
  ProductImageModel,
  ProductModel,
} from "src/models";
import { useStores } from "src/hooks";
import {
  getCatalogLink,
  getMediaCollectionLink,
  getMediaLink,
  getMediaPreview,
  getMediaPreviewFromProduct,
  getProductLink,
} from "src/utils";
import { ShareModalProps } from "../share-modal";
import { ShareLinkImageModel } from "./share-link-content.types";

export const privateLinkValidation = (values: ObjectType) => {
  const errors: ObjectType = {};

  if (values.password && values.password.length < 6) {
    errors.password = "Password should be at least 6 symbols long.";
  }

  return errors;
};

export const getSharedResourceLink = (
  type: ShareModalProps["options"]["type"],
  data: ShareModalProps["options"]["data"],
) => {
  const { userStore } = useStores();
  const { dns_host, dns_resolved } = userStore.userMe || {};

  const customDomain = dns_resolved && dns_host ? dns_host : undefined;

  let result = "";
  if (type === "media") {
    const d = data as MediaModel;
    result = getMediaLink(d.link.uuid, customDomain, d.type === MediaType.blink);
  }
  if (type === "mediaCollection") result = getMediaCollectionLink(data.link.uuid, customDomain);
  if (type === "product") result = getProductLink(data.link.uuid, customDomain);
  if (type === "catalogFullItem" || type === "catalogListItem")
    result = getCatalogLink(data.link.uuid, customDomain);

  return result;
};

export const getSharedResourcePreviews = (
  type: ShareModalProps["options"]["type"],
  data: ShareModalProps["options"]["data"],
) => {
  let result: ShareLinkImageModel[] = [];

  if (type === "media") {
    const d = data as MediaModel;
    result = [
      {
        src: getMediaPreview(d),
        type: d.type,
      },
    ];
  }

  if (type === "mediaCollection") {
    const d = data as MediaCollectionModel;
    result = d.medias.map((data, index) => ({
      src: getMediaPreviewFromProduct(data),
      type: d.medias[index].type,
    }));
  }

  if (type === "product") {
    const d = data as ProductModel;
    result = d.images.map((data) => ({
      src: getMediaPreviewFromProduct(data),
      type: data.type,
    }));
  }

  if (type === "catalogFullItem" || type === "catalogListItem") {
    let images: ProductImageModel[] = [];
    let products: Array<ProductModel | CatalogProductModel> = [];

    if (type === "catalogFullItem") {
      const d = data as CatalogFullModel;
      products = d.products.rows;
    }

    if (type === "catalogListItem") {
      const d = data as CatalogModel;
      products = d.products;
    }

    images = products.reduce((acc: ProductImageModel[], { images }) => {
      acc = [...acc, ...images];
      return acc;
    }, []);

    result = images.map((data) => ({
      src: getMediaPreviewFromProduct(data),
      type: data.type,
    }));
  }

  return result;
};
