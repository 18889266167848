export const setUserData = (action, isLogged = true) => ({
  hubspotID: action.data.hubspotID,
  isLogged,
  data: {
    ...action.data.user,
  },
  initialData: action.data.user,
  countOfUsers: action.data.countOfUsers,
  isSubscribed: action.data.isSubscribed || action.data.isSubaccount,
  isSubaccount: action.data.isSubaccount,
  isShopifyUser: action.data.isShopifyUser,
  isFreeTierAvailable: action.data.isFreeTierAvailable,
  isLoading: false,
  dns_host: action.data.dns_host,
  dns_resolved: action.data.dns_resolved,
  fromResource: action.data.fromResource,
  activeShopifyShop: action.data.activeShopifyShop,
});
