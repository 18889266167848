import { useLocation, useNavigate } from "react-router-dom";
import { SETUP_DOWNLOAD_PAGE } from "src/constants";

export type ReturnType = ((path: string) => void)[];

export const usePageRedirect = (): ReturnType => {
  const navigate = useNavigate();
  const location = useLocation();

  return [
    (path: string) => {
      if (path === location.pathname) return;
      if (path === "/camera" && !window.$platform.isApp) {
        navigate(SETUP_DOWNLOAD_PAGE.path + "?from=gemcam");
        return;
      }
      navigate(path);
    },
  ];
};
