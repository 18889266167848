import React, { memo, useMemo } from "react";
import { clsx, SvgIcon, Typography, Nullable, globalStyles } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ExtendedProductModel } from "src/store";
import { ReactComponent as NoMediaSvg } from "src/external-ts/assets/images/media/no-media-grey.svg";
import { getMediaPreviewFromProduct } from "src/utils";

import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import styles from "./styles.module.css";

export type EditCatalogOrderItemProps = {
  top: number;
  left: number;
  itemCount: number;
  draggedCard: Nullable<ExtendedProductModel>;
};
export const EditCatalogOrderItem: React.FC<EditCatalogOrderItemProps> = memo(
  ({ top, left, itemCount, draggedCard }: EditCatalogOrderItemProps) => {
    const { localeStore } = useStores();

    const isMultipleSelected = useMemo(() => itemCount > 1, [itemCount]);

    const imageToShow = draggedCard?.images?.[0];
    const isPdf = draggedCard?.images?.[0]?.type === "pdf";

    return (
      <div
        className={clsx(styles.editOrderItem, { [styles.secondDraggedCard]: isMultipleSelected })}
        style={{
          top: top,
          left: left,
        }}
      >
        <div className={styles.draggedCard}>
          <div className={styles.cardImageContainer}>
            <div className={clsx(styles.imageFiller)} />
            <div className={styles.imageWrapper}>
              {!imageToShow && <SvgIcon icon={NoMediaSvg} size={[83, 53]} />}
              {imageToShow && !isPdf ? (
                <img
                  className={styles.cardImage}
                  src={getMediaPreviewFromProduct(imageToShow)}
                  alt=""
                />
              ) : (
                <SvgIcon icon={PdfSVG} size={80} />
              )}
            </div>
          </div>

          <Typography
            className={clsx(styles.productTitleContainer, globalStyles.applySingleOverflow)}
            size="tiny"
          >
            {draggedCard?.title ||
              localeStore.t('["catalog-details"]["product-order-modal"].main["not-assigned"]')}
          </Typography>
        </div>
        {isMultipleSelected && (
          <div className={styles.itemCount}>
            <Typography size="tiny" color="white">
              {itemCount}
            </Typography>
          </div>
        )}
      </div>
    );
  },
);
