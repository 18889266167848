import React, { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  clsx,
  globalStyles,
  Heading,
  Loader,
  Nullable,
  useFetch,
  LimitedEditorText,
  TextEditorUtils,
  useDidMount,
} from "@gemlightbox/core-kit";

import { getProduct } from "src/api";
import { ProductMainMedia, ProductMediasBar } from "src/external-ts/components";
import { ProductImageModel } from "src/models";
import { CREATE_PAGE, DEFAULT_PAGE } from "src/constants";
import { useStores } from "src/hooks";
import { MAX_MEDIA_IN_PRODUCT } from "src/containers/products";
import { ProductContacts } from "./product-contacts";
import { ProductDetailsList } from "./product-details";
import { ProductPageHeader } from "./product-page-header";
import { ProductTitle } from "./product-title";
import { getAttributesNamesValuesList } from "./product.page.utils";

import styles from "./product.page.module.css";

export const ProductPage: React.FC = observer(() => {
  const { productId } = useParams<{ productId: string }>();

  const navigate = useNavigate();

  const { localeStore, modalsStore, mediaStore, userStore } = useStores();

  const useProductFetch = useFetch(getProduct.setParams({ productId: productId as any }));
  useProductFetch.onFetchSuccess((data) => {
    setSelectedMedia(data.images[0]);
  });
  useProductFetch.onFetchError(() => navigate(DEFAULT_PAGE.path));

  const product = useProductFetch.payload;
  const productMedias = product?.images || [];

  const [selectedMedia, setSelectedMedia] = useState<Nullable<ProductImageModel>>(null);

  const productAttributesData = useMemo(() => {
    if (!product?.parameters) return { title: "", price: "", description: "", attributesList: [] };
    let priceAttribute = product.parameters.find(
      (attr) => attr.name === "price" && attr.user_id === product.user_id,
    );

    if (!priceAttribute) priceAttribute = product.parameters.find((attr) => attr.name === "price");

    return {
      title: product?.title,
      price: product?.price ? `${priceAttribute?.suffix} ${product?.price}` : "",
      description: product?.description
        ? TextEditorUtils.stringifyEditorValue(
            TextEditorUtils.normalizeEditorValue(product?.description),
          )
        : "",
      attributesList: getAttributesNamesValuesList(product),
    };
  }, [product?.parameters]);

  const hasBlink = useMemo(() => {
    return !!productMedias.find((media) => media.type === "blink");
  }, [productMedias.length]);

  const handleGoBack = () => navigate(-1);

  const handleSelectMedia = (media: Nullable<ProductImageModel>) => setSelectedMedia(media);

  const handleSetProductMedias = (medias: ProductImageModel[]) => {
    if (!product) return;

    useProductFetch.actions.setData({ ...product, images: medias });

    if (!medias.length) return handleSelectMedia(null);

    if (selectedMedia) {
      const hasMedia = medias.find((media) => media.id === selectedMedia.id);
      if (!hasMedia) return handleSelectMedia(medias[0]);
    }

    if (!selectedMedia && medias.length) handleSelectMedia(medias[0]);
  };

  const handleBlinkClick = () => {
    if (!product) return;
    navigate(CREATE_PAGE.path + "/augmented-reality-product/" + product._id);
  };

  const handleRemoveMedia = async (media: ProductImageModel) => {
    if (!product) return;

    const res = await mediaStore.unassignMedia(media.id);
    if (res.status !== "success") return;

    const newMedias = productMedias.filter((prevMedia) => prevMedia.id !== media.id);
    handleSetProductMedias(newMedias);
  };

  const handleAddMediaClick = () => {
    if (!product) return;

    modalsStore.open("ProductAssignMediasSidebar", {
      productImages: productMedias,
      onSubmit: async (medias) => {
        const mediasIds = medias.map(({ id }) => id);
        const res = await mediaStore.assignMedia(mediasIds, product);
        if (res.status !== "success") return;

        await useProductFetch.refresh();

        // NOTE: it should be this code, but not refresh above, to not have useless api call
        // but then there is issue with images ordering, fix it later (TODO)
        // const newMedias = productMedias.concat(medias as any);
        // handleSetProductMedias(newMedias);
      },
    });
  };

  const handleReplaceMedia = (
    mediaId: ProductImageModel["id"],
    newMedia: ProductImageModel,
    asNew: boolean,
  ) => {
    if (asNew && productMedias.length >= MAX_MEDIA_IN_PRODUCT) return;
    useProductFetch.refresh();
  };

  useDidMount(() => userStore.checkGalleryInfo());

  if (useProductFetch.loading) {
    return (
      <div className={styles.productPageContainer}>
        <ProductPageHeader
          title={localeStore.t('["product-attributes"].title')}
          onGoBack={handleGoBack}
        />
        <div className={clsx(styles.productPageContent, globalStyles.addScrollStyles)}>
          <div className={styles.pageLoading}>
            {useProductFetch.loading && <Loader type="goo-loader" />}
            <Heading tag="h3" color="textSecondary">
              {localeStore.t('common.warnings["loading-dots"]')}
            </Heading>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.productPageContainer}>
      <ProductPageHeader
        title={localeStore.t('["product-attributes"].title')}
        product={product}
        onGoBack={handleGoBack}
      />

      <div className={clsx(styles.productPageContent, globalStyles.addScrollStyles)}>
        <div className={styles.leftWrapper}>
          <ProductMainMedia
            productImage={selectedMedia}
            onAddMediaClick={handleAddMediaClick}
            onMediaReplace={handleReplaceMedia}
            canAddMedia={!productMedias.length}
          />
          <ProductMediasBar
            productMedias={productMedias}
            onAddMediaClick={handleAddMediaClick}
            onRemoveMediaClick={handleRemoveMedia}
            onMediaClick={handleSelectMedia}
            onMediaDrag={handleSetProductMedias}
            productId={product?._id}
            onBlinkClick={handleBlinkClick}
          />
        </div>

        <div className={clsx(styles.rightWrapper, globalStyles.addScrollStyles)}>
          <ProductTitle title={productAttributesData.title} price={productAttributesData.price} />

          <ProductContacts product={product} hasBlink={hasBlink} />

          {productAttributesData.description && (
            <LimitedEditorText
              containerClassName={styles.productDescription}
              value={productAttributesData.description}
              limit={200}
            />
          )}

          <ProductDetailsList list={productAttributesData.attributesList} />
        </div>
      </div>
    </div>
  );
});

export default ProductPage;
