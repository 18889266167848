export const numberNormalizer = (value) => {
  if (value && value.includes?.(".") && !isNaN(value)) return value;
  if (value && !isNaN(parseFloat(value))) return parseFloat(value);
  return "";
};

export const scrollSelect = (element) => {
  if (!element) return;

  const closestScroll = element.closest(".secondary-scrollbar") || window;
  const closestScrollBox = closestScroll.getBoundingClientRect();
  const posY = element.offsetTop - closestScrollBox.height / 2;

  closestScroll.scrollTo({ left: 0, top: posY, behavior: "smooth" });
};
