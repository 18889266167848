import React from "react";
import { observer } from "mobx-react-lite";
import {
  clsx,
  curry,
  handleSetRef,
  RefModel,
  useVirtualizedLoading,
  globalStyles,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { CatalogGridCard } from "./catalog-grid-card";
import {
  catalogCardMinWidth,
  catalogCardAddedHeight,
  catalogCardHeightMultiplier,
} from "../../catalogs.constants";

import styles from "./grid-view.module.css";

export type GridViewProps = {
  containerRef: RefModel<HTMLDivElement>;
};

export const GridView: React.FC<GridViewProps> = observer(({ containerRef }) => {
  const { catalogsStore } = useStores();

  const { virtualizedItems, virtualizingContainerRef, virtualizingContentRef, onScroll } =
    useVirtualizedLoading({
      itemsAmount: catalogsStore.catalogList.length,
      minItemWidth: catalogCardMinWidth,
      addedHeight: catalogCardAddedHeight,
      heightMultiplier: catalogCardHeightMultiplier,
    });

  return (
    <div
      className={clsx(styles.catalogGridContainer, globalStyles.addScrollStyles)}
      ref={curry(handleSetRef)([virtualizingContainerRef, containerRef])}
      onScroll={onScroll}
    >
      <div
        className={styles.catalogGridWrapper}
        ref={curry(handleSetRef)([virtualizingContentRef])}
      >
        {virtualizedItems.map(({ index, styles }) => {
          const catalogItem = catalogsStore.catalogList[index];
          if (!catalogItem) return;

          return <CatalogGridCard key={catalogItem.id} data={catalogItem} style={styles} />;
        })}
      </div>
    </div>
  );
});
