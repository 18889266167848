import React from "react";
import { clsx, memo } from "@gemlightbox/core-kit";

import styles from "./block-wrapper.module.css";

export type BlockTitleProps = {
  children: React.ReactNode;
  className?: string;
};

export const BlockWrapper: React.FC<BlockTitleProps> = memo(({ children, className }) => {
  return <div className={clsx(styles.blockWrapperContainer, className)}>{children}</div>;
});

export default BlockWrapper;
