import React from "react";
import { observer } from "mobx-react-lite";
import { clsx, Image, Radio, Typography, Nullable } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ProductTypeMediaModel } from "src/models";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./template-selector.module.css";

export type TemplateSelectorProps = {
  template: Nullable<ProductTypeMediaModel>;
  templates: ProductTypeMediaModel[];
  onChange: (template: ProductTypeMediaModel) => void;
};

export const TemplateSelector: React.FC<TemplateSelectorProps> = observer(
  ({ template, templates, onChange }) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.container}>
        <Typography size="small600" color="textSecondary">
          {localeStore.t('["ar-media"]["media-editor"]["template-selector"].title')}
        </Typography>

        <div className={clsx(styles.list, globalStyles.addScrollStyles)}>
          {templates.map((_template, i) => (
            <div key={i} className={styles.item} onClick={() => onChange(_template)}>
              <Radio
                className={styles.selector}
                checked={_template.id === template?.id}
                onChange={() => null}
              />
              <Image src={_template.file?.small ?? ""} />
            </div>
          ))}
        </div>
      </div>
    );
  },
);
