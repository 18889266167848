import React from "react";
import { observer } from "mobx-react-lite";
import { Button, clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./renew-panel.module.css";

export interface RenewPanelProps {
  onRenewClick: VoidFunction;
  className?: string;
}

export const RenewPanel: React.FC<RenewPanelProps> = observer(
  ({ onRenewClick, className = "" }: RenewPanelProps) => {
    const { localeStore, subscriptionsStore } = useStores();

    return (
      <div className={clsx(styles.container, className)}>
        <span>
          {localeStore.t('settings.subscriptions.components["renew-panel"].title.top')}
          <span className={styles.bold}>
            {" "}
            {localeStore.t('settings.subscriptions.components["renew-panel"].title.middle')}{" "}
            {subscriptionsStore.getExpireDate()}.
          </span>
          <br /> {localeStore.t('settings.subscriptions.components["renew-panel"].title.bottom')}
        </span>
        <Button className={styles.reneButton} appearance="error" onClick={onRenewClick}>
          {localeStore.t('settings.subscriptions.components["renew-panel"].buttons.renew')}
        </Button>
      </div>
    );
  },
);

export default RenewPanel;
