import { config } from "src/config/environment";
import { userStorage } from "src/redux/reducers/user";

export const getCatalogLink = (uuid: string, customDomain?: string | undefined) => {
  let result = `${config.galleryURL}/catalog/${uuid}`;

  if (customDomain) result = `https://${customDomain}/catalog/${uuid}`;

  return result;
};

export const getCatalogPdfLink = (
  catalogId: number,
  language: string,
  customDomain?: string | undefined,
) => {
  const { token } = userStorage.get();
  let result = `${config.galleryURL}/print/${catalogId}?token=${token}&lang=${language}`;

  if (customDomain)
    result = `https://${customDomain}/print/${catalogId}?token=${token}&lang=${language}`;

  return result;
};
