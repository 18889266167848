import React from "react";
import { observer } from "mobx-react-lite";

import Modal from "src/components/modals/Modal";
import IconCircle from "src/components/IconCircle";
import { useStores } from "src/hooks";

import questionImg from "../../images/question.svg";
import BtnWithLoader from "../../../../../components/BtnWithLoader";

import "./index.scss";

export const IntegrationDisconnectModal = observer(
  ({ isOpened, name, loading, onClose, onConfirm }) => {
    const { localeStore } = useStores();

    return (
      <Modal
        className="shopify-disconnect-modal"
        isOpened={isOpened}
        withClose
        handleModalClose={onClose}
      >
        <div className="modal-content__header">
          <IconCircle img={questionImg} variant="danger" />
          <div className="headline">
            {localeStore.t(
              'settings.integrations.modals["integration-disconnect-modal"].title.first',
            )}{" "}
            {name}{" "}
            {localeStore.t(
              'settings.integrations.modals["integration-disconnect-modal"].title.last',
            )}
            ?
          </div>
        </div>
        <div className="modal-content__body">
          <div className="label error">
            {localeStore.t('settings.integrations.modals["integration-disconnect-modal"].warning')}
          </div>
          <ol>
            <li className="label">
              {localeStore.t(
                'settings.integrations.modals["integration-disconnect-modal"]["data-will-remain"]',
              )}
            </li>
            <li className="label">
              {localeStore.t(
                'settings.integrations.modals["integration-disconnect-modal"]["data-stop-sync"]',
              )}
            </li>
          </ol>
        </div>
        <div className="modal-content__footer">
          <button className="primary-reverse-btn cancel" onClick={onClose}>
            {localeStore.t("settings.integrations.modals.buttons.cancel")}
          </button>
          <BtnWithLoader
            loading={loading}
            label={localeStore.t("settings.integrations.modals.buttons.proceed")}
            className="primary-btn confirm"
            onClick={onConfirm}
          />
        </div>
      </Modal>
    );
  },
);

export default IntegrationDisconnectModal;
