import React from "react";
import { observer } from "mobx-react-lite";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { getVersion, pushDataLayerEvent } from "src/utils";
import { errorCatchSotre } from "src/utils/error-catch";
import { useStores } from "src/hooks";
import { Button, SvgIcon } from "@gemlightbox/core-kit";

import styles from "./index.module.css";
import { ReactComponent as FailedSVG } from "src/external-ts/assets/images/error-failed.svg";
import { ReactComponent as NotSupportedSVG } from "src/external-ts/assets/images/error-browser-not-supported.svg";
import { ReactComponent as VersionSVG } from "src/external-ts/assets/images/error-browser-version.svg";
import { ReactComponent as NetworkSVG } from "src/external-ts/assets/images/error-network.svg";

export const ErrorBoundaryWrap: React.FC = observer(({ children }) => {
  const { appCallbackStore } = useStores();
  const { errorType } = errorCatchSotre;

  const supportedVersion = {
    Safari: 15,
    Chrome: 109,
    Edge: 116,
  };
  type SupportedBrowser = keyof typeof supportedVersion;
  const browserVersionStr = getVersion();
  const [name, version] = browserVersionStr.split(" ") as [SupportedBrowser, string];
  const isApp = window.$platform.isApp;
  const browserNotSupported = !isApp && supportedVersion[name] === undefined;
  const browserVersionOutdated = !isApp && supportedVersion[name] > Number(version);
  const isOffline = errorType === "network";
  let icon: React.FunctionComponent;
  let desc: string;

  if (isOffline) {
    icon = NetworkSVG;
    desc = "Oops, it seems there is a problem with the network~";
  } else if (browserNotSupported) {
    icon = NotSupportedSVG;
    desc =
      "Sorry, your current browser is not supported GemHub, please switch to another browser for use (Chrome, Safari, Microsoft Edge).";
    pushDataLayerEvent({
      event: "gemhub:browser:unsupported_type",
    });
  } else if (browserVersionOutdated) {
    icon = VersionSVG;
    desc = "The browser version is too low, please update to the latest version.";
    pushDataLayerEvent({
      event: "gemhub:browser:incompatible_version",
      event_params: {
        required_minimum_browser_version: supportedVersion[name],
      },
    });
  } else {
    icon = FailedSVG;
    desc = "Page loading failed, please refresh and try again.";
  }

  const fallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    pushDataLayerEvent({
      event: "gemhub:react:render:fatal_error",
      event_params: { error_message: { message: error.message, stack: error.stack } },
    });
    if (isApp) {
      appCallbackStore.webviewNotification({ type: 1 });
      resetErrorBoundary = () => {
        appCallbackStore.webviewNotification({ type: 2, needReloadMain: false });
      };
    }
    return errorPlaceholder(true, resetErrorBoundary);
  };

  const errorPlaceholder = (refresh: boolean, resetErrorBoundary?: () => void) => {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.errorIcon}>
          <SvgIcon size={176} icon={icon} />
        </div>
        <div className={styles.errorInfo}>
          <div className={styles.errorDesc}>{desc}</div>
          {refresh && (
            <Button appearance="primary" className={styles.refreshBtn} onClick={resetErrorBoundary}>
              Refresh
            </Button>
          )}
        </div>
      </div>
    );
  };

  return browserNotSupported || browserVersionOutdated || isOffline ? (
    errorPlaceholder(false)
  ) : (
    <ErrorBoundary FallbackComponent={fallback}>{children}</ErrorBoundary>
  );
});

export default ErrorBoundaryWrap;
