import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import { ExpandableStep } from "../expandable-step";

import styles from "../steps-list.module.css";

export interface Step1Props {
  completed: boolean;
  initialOpen: boolean;
  isOpen: boolean;
  onProceed: VoidFunction;
}

export const Step1: React.FC<Step1Props> = observer(
  ({ completed, initialOpen, isOpen, onProceed }) => {
    const { localeStore } = useStores();

    return (
      <ExpandableStep
        title={localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-1"].title',
        )}
        checkboxLabel={localeStore.t(
          'settings["dns-settings"]["second-step"]["steps-list"]["step-1"]["checkbox-label"]',
        )}
        checked={completed}
        initialOpen={initialOpen}
        isOpen={isOpen}
        onChange={onProceed}
      >
        <div className={styles.label}>
          {localeStore.t(
            'settings["dns-settings"]["second-step"]["steps-list"]["step-1"].subtitle',
          )}
        </div>
      </ExpandableStep>
    );
  },
);
