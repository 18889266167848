import React from "react";
import { observer } from "mobx-react-lite";

import { Button, UseBooleanReturnType } from "@gemlightbox/core-kit";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import BluetoothTips from "src/external-ts/assets/images/camera/win-bluetooth-tips.png";
import styles from "./bluetooth-permission-modal-win.module.css";
import { useStores } from "src/hooks";

const BootstrapDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "16px",
  },
}));

type BluetoothPermModalProps = {
  bluetoothPermBool: UseBooleanReturnType;
};

export const WinBluetoothPermTipsModal: React.FC<BluetoothPermModalProps> = observer(
  ({ bluetoothPermBool }) => {
    const { localeStore } = useStores();

    return (
      <BootstrapDialog open={bluetoothPermBool.value} maxWidth="xs">
        <DialogTitle className={styles.bluetoothDialogHeader}>
          <img className={styles.tipsBanner} src={BluetoothTips} />
        </DialogTitle>
        <DialogContent>
          <div className={styles.dialogDesc}>
            {localeStore.t('camera["bluetooth-permission-title"]')}
          </div>
          <div className={styles.subDesc}>
            {localeStore.t('camera["bluetooth-permission-desc"]')}
          </div>

          <div className={styles.actionBox}>
            <Button
              appearance="primary"
              className={styles.confirmBtn}
              onClick={bluetoothPermBool.setFalsy}
            >
              {localeStore.t('camera["confirm-text"]')}
            </Button>
          </div>
        </DialogContent>
      </BootstrapDialog>
    );
  },
);
