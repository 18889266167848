import { round, rotateImage, toRadians } from "@gemlightbox/core-kit";

import { ProductType } from "src/models";
import { ProductTypeModel } from "src/models";
import { shouldRotateImgMap } from "./media-editor.constants";
import { PIXELS_IN_CM_ALL, PIXELS_IN_CM_GEMSTONE } from "../ar-media.constants";

export type GetARMainImageDataReturnType = {
  width: number;
  height: number;
  x: number;
  y: number;
  aspectRatio: number;
};

export const getARMainImageData = (
  image: HTMLCanvasElement,
  canvasWidth: number,
  canvasHeight: number,
): GetARMainImageDataReturnType => {
  const width = image.width;
  const height = image.height;
  const aspectRatio = width / height;

  let widthToSet = 358;
  let heightToSet = 358;

  if (aspectRatio > 1) {
    const temp = widthToSet * aspectRatio;
    widthToSet = Math.min(temp, 500);
    const diff = widthToSet / temp;
    heightToSet = heightToSet * diff;
  } else {
    const temp = heightToSet / aspectRatio;
    heightToSet = Math.min(temp, 500);
    const diff = heightToSet / temp;
    widthToSet = widthToSet * diff;
  }

  widthToSet = Math.round(widthToSet);
  heightToSet = Math.round(heightToSet);

  const x = Math.floor(canvasWidth / 2 - widthToSet / 2);
  const y = Math.floor(canvasHeight / 2 - heightToSet / 2);

  return {
    width: widthToSet,
    height: heightToSet,
    x,
    y,
    aspectRatio,
  };
};

export const pixelsToMeasurement = (pixels: number, to: string, type: ProductType) => {
  let result;

  const pixelsCm = type === ProductType.gemstones ? PIXELS_IN_CM_GEMSTONE : PIXELS_IN_CM_ALL;

  switch (to) {
    case "inch": {
      result = pixels / (pixelsCm * 2.54);
      break;
    }
    default: {
      result = pixels / pixelsCm;
      break;
    }
  }

  return round(result, 2);
};

export const measurementToPixel = (value: number, from: any, type: ProductType) => {
  let result;

  const pixelsCm = type === ProductType.gemstones ? PIXELS_IN_CM_GEMSTONE : PIXELS_IN_CM_ALL;

  switch (from) {
    case "inch": {
      result = value * (pixelsCm * 2.54);
      break;
    }
    default: {
      result = value * pixelsCm;
      break;
    }
  }

  return round(result, 2);
};

export const getChainTemplateData = () => {
  // NOTE: this is hardcoded due to business case
  const width = 1026; //51.3% of canvasSize width
  const height = 888; // 44.4% of canvasSize height
  const x = 432; // 55.36% of width
  const y = 662; // 38.06% of height

  return { width, height, x, y };
};

export const rotateImg = (productType: ProductTypeModel, source: HTMLCanvasElement) => {
  if (!shouldRotateImgMap.includes(productType.name)) return source;

  const width = source.width;
  const height = source.height;

  if (width >= height) return source;

  return rotateImage(source, toRadians(90));
};
