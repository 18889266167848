import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import "./index.scss";

const Badge = ({ children, className, ...props }) => (
  <div className={clsx("badge", { [`${className}`]: className })} {...props}>
    {children}
  </div>
);

export default Badge;
