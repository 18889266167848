import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { useStores } from "src/hooks";

import arrowLeftImg from "src/common/images/arrow-left-black.svg";
import IntegrationStatusLogo from "../IntegrationStatusLogo";

const IntegrationStatusSettingsSyncingState = observer(({ logo, title, description, onCancel }) => {
  const { localeStore } = useStores();

  return (
    <>
      <div className="left">
        <Link to={getSettingsTab(SettingTabNames.integrations).item.path} className="back">
          <img src={arrowLeftImg} alt="" />
        </Link>

        <IntegrationStatusLogo src={logo} />
      </div>

      <div className="integration-status-data">
        <div className="integration-status-data__title label"> {title}</div>
        <div className="integration-status-data__description label hint"> {description}</div>
      </div>

      <button className="primary-btn" onClick={onCancel}>
        {localeStore.t(
          'settings.integrations["integration-status-bar"]["syncing-state"]["stop-button"]',
        )}
      </button>
    </>
  );
});

export default IntegrationStatusSettingsSyncingState;
