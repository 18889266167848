import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { useStores } from "src/hooks";
import {
  LOGIN_PAGE,
  CREATE_PAGE,
  SHOPIFY_PRICING_PAGE,
  LIFETIME_ACTIVATE_PAGE,
} from "src/constants";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { clearFromResource } from "src/redux/reducers/user";

export const DefaultRedirect = () => {
  const { userStore } = useStores();
  const { isLogged } = userStore;

  const dispatch = useDispatch();

  const reducer = useSelector(({ userReducer }: any) => userReducer);
  const { fromResource, isSubscribed } = reducer;

  const getRedirectTo = () => {
    let result = LOGIN_PAGE.path;

    if (isLogged) {
      result = CREATE_PAGE.path;
    }

    if (isLogged && fromResource === "Shopify") {
      dispatch(clearFromResource());

      const integrationTab = getSettingsTab(SettingTabNames.integrations);

      if (isSubscribed) {
        result = integrationTab?.item.path as string;
      } else {
        result = SHOPIFY_PRICING_PAGE.path;
      }
    }

    if (isLogged && fromResource === "lifetime_activate") {
      dispatch(clearFromResource());
      result = LIFETIME_ACTIVATE_PAGE.path;
    }

    return result;
  };

  return <Navigate to={getRedirectTo()} />;
};

export default DefaultRedirect;
