import React, { memo } from "react";
import { Nullable, Select, SelectOption, SelectOptionValue } from "@gemlightbox/core-kit";
import { useStores, useUserOptions } from "src/hooks";
import { DEFAULT_USER_FILTER_TYPE } from "./user-select.constants";

import styles from "./user-select.module.css";

export interface UserSelectProps {
  value: string | number;
  onChange: (value: SelectOptionValue) => void;
  className?: string;
  appearance?: "primaryV2" | "primary" | "secondary" | "secondaryV2";
}

export const UserSelect: React.FC<UserSelectProps> = memo(
  ({ value, onChange, className = "", appearance = "primary" }: UserSelectProps) => {
    const { localeStore } = useStores();
    const { options } = useUserOptions();

    const handleChange = (option: Nullable<SelectOption>) => {
      let value = option?.value || "";
      if (value === DEFAULT_USER_FILTER_TYPE) value = "";
      onChange(value);
    };

    return (
      <Select
        className={className}
        inputClassName={styles.inputClassName}
        appearance={appearance}
        label={localeStore.t(
          'media["media-control-panel"].filters["filters-dialog"]["created-by"].title',
        )}
        labelPos="inside"
        options={options.map((item) => {
          if (item.label === "All") {
            item.label = localeStore.t(
              'media["media-control-panel"].filters["filters-dialog"]["media-options"].all',
            );
            return { ...item, label: item.label };
          } else if (!item.value) {
            return { ...item, value: "" };
          }
          return item;
        })}
        selectedOptionsKeys={value || DEFAULT_USER_FILTER_TYPE}
        onChange={handleChange}
        disableError
        disableClearing
        disableSingleOptionUncheck
      />
    );
  },
);

export default UserSelect;
