import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Tooltip, Heading, useBoolean, useDidMount, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { useLimits } from "../subscriptions/subscriptions.utils";
import { PendingInvitationsList } from "./pending-invitations-list";
import { SubaccountsEmpty } from "./subaccounts-empty";
import { SubaccountsTable } from "./subaccounts-table";

import { ReactComponent as PlusIcon } from "src/external-ts/assets/images/plus-circle-grey.svg";
import styles from "./subaccounts.module.css";
import TimeDelayTipComponent from "src/components/TimeDelayTipComponent";
import Alert from "src/components/Feedback/Alert";
import { ReactComponent as ExclamationMarkIcon } from "src/common/images/exclamation-mark.svg";

export const Subaccounts: React.FC = observer(() => {
  const { canSubaccounts, subaccountsLimit } = useLimits();

  const toolTipBoolean = useBoolean();

  const addSubaccountButtonRef = useRef<HTMLButtonElement>(null);

  const { modalsStore, subaccountsStore, userStore, localeStore } = useStores();

  const disabled = subaccountsStore.subaccountList.length >= subaccountsLimit;
  const blocked = !canSubaccounts;

  const isAddSubaccountButtonDisabled = disabled || blocked;

  const handleAddSubaccount = () => modalsStore.open("CreateInvitationLinkModal", {});

  const handleAddSubaccountPointerEnter = () => {
    if (!isAddSubaccountButtonDisabled) return;
    toolTipBoolean.setTruthy();
  };

  const handleAddSubaccountPointerLeave = () => {
    toolTipBoolean.setFalsy();
  };

  useDidMount(async () => {
    await Promise.all([
      subaccountsStore.loadSubaccounts(),
      subaccountsStore.getPendingEvictSubaccountJob(),
    ]);
  });

  return (
    <div className={styles.wrapperContainer}>
      <TimeDelayTipComponent
        isOpened={subaccountsStore.showErrorTip}
        text={subaccountsStore.errorTipMessage}
      />
      {!subaccountsStore.subaccountList.length ? (
        <div className={styles.container}>
          <SubaccountsEmpty blocked={blocked} />
        </div>
      ) : (
        <div className={styles.container}>
          {subaccountsStore.evictSubaccountJob && (
            <Alert
              className={styles.errorTip}
              subtitle={localeStore.t(
                'settings.subaccounts.tooltip["auto-remove-subaccount"].title',
              )}
              icon={<ExclamationMarkIcon />}
              iconClassName={styles.errorTipIcon}
              subTitleClassName={styles.errorTipSubtitle}
            />
          )}
          <div className={styles.headerContainer}>
            <Heading tag="h2" color="textSecondary">
              {localeStore.t("settings.subaccounts.title")}
              <span> ({subaccountsStore.confirmedSubaccounts.length}) </span>
            </Heading>
            {!userStore.isSubaccount && (
              <Button
                className={styles.addSubaccountButton}
                forwardRef={addSubaccountButtonRef}
                onClick={handleAddSubaccount}
                onPointerEnter={handleAddSubaccountPointerEnter}
                onPointerLeave={handleAddSubaccountPointerLeave}
                disabled={isAddSubaccountButtonDisabled}
              >
                <SvgIcon icon={PlusIcon} />
                <div>{localeStore.t("settings.subaccounts.buttons.add")}</div>
              </Button>
            )}
          </div>

          <SubaccountsTable />

          <PendingInvitationsList />

          <Tooltip
            appearance="secondary"
            position="left"
            offsetX={14}
            withAngle
            target={addSubaccountButtonRef}
            isOpen={toolTipBoolean.value}
            onClose={toolTipBoolean.setValue}
          >
            {blocked
              ? localeStore.t('settings.subaccounts.tooltip["pro-subscription-needed"]')
              : `${localeStore.t(
                  'settings.subaccounts.tooltip["subaccount-limit"].first',
                )} ${subaccountsLimit} ${localeStore.t(
                  'settings.subaccounts.tooltip["subaccount-limit"].last',
                )}`}
          </Tooltip>
        </div>
      )}
    </div>
  );

  // if (!subaccountsStore.subaccountList.length) {
  //   return (
  //     <div className={styles.container}>
  //       <SubaccountsEmpty blocked={blocked} />
  //     </div>
  //   );
  // }

  // return (
  //   <div className={styles.container}>
  //       <div onClick={() => setIsTipVisible(true)}>12312</div>
  //       <div className={styles.headerContainer}>
  //         <Heading tag="h2" color="textSecondary">
  //           {localeStore.t("settings.subaccounts.title")}
  //           <span> ({subaccountsStore.confirmedSubaccounts.length}) </span>
  //         </Heading>
  //         {!userStore.isSubaccount && (
  //           <Button
  //             className={styles.addSubaccountButton}
  //             forwardRef={addSubaccountButtonRef}
  //             onClick={handleAddSubaccount}
  //             onPointerEnter={handleAddSubaccountPointerEnter}
  //             onPointerLeave={handleAddSubaccountPointerLeave}
  //             disabled={isAddSubaccountButtonDisabled}
  //           >
  //             <SvgIcon icon={PlusIcon} />
  //             <div>{localeStore.t("settings.subaccounts.buttons.add")}</div>
  //           </Button>
  //         )}
  //       </div>

  //       <SubaccountsTable />

  //       <PendingInvitationsList />

  //       <Tooltip
  //         appearance="secondary"
  //         position="left"
  //         offsetX={14}
  //         withAngle
  //         target={addSubaccountButtonRef}
  //         isOpen={toolTipBoolean.value}
  //         onClose={toolTipBoolean.setValue}
  //       >
  //         {blocked
  //           ? localeStore.t('settings.subaccounts.tooltip["pro-subscription-needed"]')
  //           : `${localeStore.t(
  //             'settings.subaccounts.tooltip["subaccount-limit"].first',
  //           )} ${subaccountsLimit} ${localeStore.t(
  //             'settings.subaccounts.tooltip["subaccount-limit"].last',
  //           )}`}
  //       </Tooltip>
  //     </div>
  //   )
});
