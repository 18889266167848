import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  Input,
  Button,
  SvgIcon,
  Tooltip,
  Typography,
  clsx,
  useBoolean,
  useDebounce,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-circle-grey.svg";
import { ReactComponent as CopySVG } from "src/external-ts/assets/images/copy-grey.svg";
import styles from "./share-element.module.css";

export type ShareElementImperativeRef = {
  toggleCopied: (hideSuccess?: boolean) => void;
};

export type ShareElementProps = {
  className?: string;
  link: string;
  label?: string;
  title?: string;
  message?: string;
  onCopy?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

export const ShareElement: React.FC<ShareElementProps> = observer(
  ({ className, link, title, message, onCopy, disabled }: ShareElementProps) => {
    const { debounce } = useDebounce(500);

    const isCopiedBoolean = useBoolean();
    const isLoadingBoolean = useBoolean(false);

    const { localeStore } = useStores();

    const btnRef = useRef<HTMLButtonElement>(null);

    const isOpenBoolean = useBoolean(false);

    const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!event) return null;
      isLoadingBoolean.setTruthy();
      isCopiedBoolean.setTruthy();
      onCopy?.(event);
      debounce(() => {
        isLoadingBoolean.setFalsy();
      });
    };

    return (
      <div className={clsx(styles.container, className)}>
        {title && (
          <Typography className={styles.title} size="small">
            {title}
          </Typography>
        )}

        <Input
          inputWrapperClassName={styles.inputWrapper}
          inputClassName={styles.input}
          appearance="primaryV2"
          value={link || ""}
          readOnly
          disableError
          data-cy="link-copy-input"
        >
          <Button
            className={styles.button}
            appearance="primaryOutlined"
            forwardRef={btnRef}
            onClick={handleCopy}
            onMouseEnter={isOpenBoolean.setTruthy}
            onMouseLeave={isOpenBoolean.setFalsy}
            disabled={disabled}
            loading={isLoadingBoolean.value}
            checked={isCopiedBoolean.value}
            data-cy="copy-button"
          >
            <SvgIcon icon={CopySVG} />
          </Button>
          <Tooltip
            appearance="secondary"
            position="top"
            target={btnRef}
            onClose={isOpenBoolean.setValue}
            isOpen={isOpenBoolean.value && !isCopiedBoolean.value}
            withAngle
          >
            <Typography className={styles.tooltipContent} size="extraSmall500">
              {isCopiedBoolean.value && (
                <SvgIcon className={styles.tooltipIcon} icon={CheckmarkSVG} />
              )}
              {localeStore.t('common["copy-link"]')}
            </Typography>
          </Tooltip>
        </Input>

        {message && (
          <Typography className={styles.message} size="tiny">
            {message}
          </Typography>
        )}
      </div>
    );
  },
);
