import React from "react";
import { observer } from "mobx-react-lite";

import { FiltersType, SelectedFilters } from "src/external-ts/components";
import { useStores } from "src/hooks";

interface SelectedFiltersPanelProps {
  onOpenFilters?: () => Promise<void>;
  onClearFilters?: VoidFunction;
}

export const SelectedFiltersPanel: React.FC<SelectedFiltersPanelProps> = observer(
  ({ onOpenFilters, onClearFilters }) => {
    const { productsStore } = useStores();

    const handleFiltersChange = (filters: FiltersType) => productsStore.loadProductsList(filters);

    const handleClearFilters = () => {
      productsStore.resetFilters();
      productsStore.loadProductsList();
      onClearFilters?.();
    };

    return (
      <SelectedFilters
        filters={productsStore.filters}
        filtersAmount={productsStore.filtersAmount}
        onChange={handleFiltersChange}
        onClear={handleClearFilters}
        onOpen={onOpenFilters}
      />
    );
  },
);
