import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";

import styles from "./media-pending-upload-card.module.css";
import {
  MediaPreview,
  NUMBER_OF_MEDIA_TO_PREVIEW,
} from "src/containers/products/products-list/media-preview";

type MediaPendingUploadCardProps = {
  style?: React.CSSProperties;
};

export const MediaPendingUploadCard: React.FC<MediaPendingUploadCardProps> = observer(
  ({ style }) => {
    const { mediaStore, modalsStore, localeStore } = useStores();

    const handleCardClick = () => modalsStore.open("PendingUploadMediaSidebar", {});

    return (
      <div className={styles.container} style={style} onClick={handleCardClick}>
        <div className={styles.imageContainer}>
          <div className={styles.imageFiller} />
          <MediaPreview
            className={styles.mediaPreview}
            view="grid"
            totalMediaAmount={mediaStore.totalMediaPendingUploadAmount}
            mediaToShow={mediaStore.mediaPendingUploadList.slice(0, NUMBER_OF_MEDIA_TO_PREVIEW)}
            isPendingUpload={true}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.textWrapper}>
            <span className={styles.title}>
              {localeStore.t('products["products-list"]["pending-upload"].title')}
            </span>
            <span className={styles.mediaAmount}>
              {mediaStore.totalMediaPendingUploadAmount}{" "}
              {localeStore.t('media.modals["pending-upload-media-sidebar"].media')}
            </span>
          </div>
        </div>
      </div>
    );
  },
);

export default MediaPendingUploadCard;
