import React from "react";
import { memo, clsx, globalStyles } from "@gemlightbox/core-kit";

import styles from "./terms-of-service-article.module.css";

export interface TermsOfServiceProps {
  containerClassName?: string;
  articleWrapperClassName?: string;
  withTitle?: boolean;
  withDate?: boolean;
}

export const TermsOfServiceArticle: React.FC<TermsOfServiceProps> = memo(
  ({
    containerClassName = "",
    articleWrapperClassName = "",
    withTitle = true,
    withDate = true,
  }) => {
    return (
      <article
        className={clsx(
          styles.termsArticleContainer,
          globalStyles.addScrollStyles,
          containerClassName,
        )}
      >
        <div className={clsx(styles.termsArticleWrapper, articleWrapperClassName)}>
          {withTitle && <h1 className={styles.title}>Terms Of Service</h1>}
          {withDate && <div className={styles.lastUpdated}>Last updated: 12 April 2021</div>}

          <h3>Note: Picup Media is a fully owned subsidiary of HEIG LIMITED</h3>

          <p className={styles.space}>
            Picup Media offers an image hosting service to serve digital images through web browsers
            and apps. We also offer jewelry photo retouching services, as well as a wide range of
            jewelry photography and video solutions.
            <br />
            <br />
            Prior to using our services, you must carefully review the following Terms of Service
            (the “Terms”) set out below. The Terms along with any additional terms and conditions
            set out on Picup Media’s website (picupmedia.com) including Picup Media’s Privacy
            Policy, (collectively, the “Agreement”) forms a legal agreement between us. Your use of
            Picup Media’s Services (“Services”) constitutes your acceptance of this Agreement. You
            should periodically check this page to make sure you have read the current version of
            these terms. Your use of the services is entirely conditioned on and subject to your
            compliance with these Terms.
            <br />
            <br />
            By using Picup Media, you agree to be bound by Part 1 of these terms – it applies to all
            users including casual visitors. By registering for a Picup Media account, you also
            agree to be bound by Part 2 of these terms, and all registered and unregistered users
            agree to be bound by Part 3. If you do not agree with these terms, do not access or use
            the services.
          </p>

          <h2>1. Your Account</h2>
          <p className={styles.space}>
            Provisions applicable to all Users of the Service (whether registered or not).
            <br />
            <br />
            As a customer of Picup Media, you are responsible for preserving the confidentiality of
            your account and password, for restricting access to your account, and for all
            activities that occur under your account or password. You will immediately notify Picup
            Media of any actual or suspected unauthorized use of your account.
          </p>

          <h3>1.1. Availability</h3>
          <p className={styles.space}>
            Picup Media uses all reasonable endeavors to ensure that the Service is available 24
            hours a day, 7 days a week. However, there may be occasions when the Service will be
            interrupted for upgrades, emergency repairs, or due to failure of telecommunication
            links and equipment that are beyond the control of Picup Media. Every reasonable step
            will be taken by Picup Media to minimize such disruption where it is within our
            reasonable control. You agree that Picup Media shall not be liable to you for any
            modification, suspension, or discontinuance of the Service.
          </p>

          <h3>1.2. Privacy</h3>
          <p className={styles.space}>
            Picup Media is committed to protecting your privacy. Your submission of personal
            information through this site is governed by our Privacy Policy. We will not review,
            share, distribute or reference any personal information, except as provided in this
            Agreement, as provided in our privacy policy, or as may be required by law.
          </p>

          <h3>1.3. Trademarks</h3>
          <p className={styles.space}>
            All brands, service names used in this Service that identify Picup Media or third
            parties and their services are proprietary marks of Picup Media and/or the relevant
            third parties. Nothing in this Service shall be deemed to confer on any person any
            license or right on the part of Picup Media or any third party with respect to any such
            image, logo, or name.
          </p>

          <h3>1.4. Copyright and Intellectual Property</h3>
          <p>
            Picup Media, unless otherwise stated, is the owner of all copyright and rights in the
            Service and its contents. You may not publish, distribute, extract,re-utilize, or
            reproduce any such content in any material form without written permission from Picup
            Media.
            <br />
            <br />
            We do not claim copyright or ownership of any content that you upload to Picup Media
            (see Copyright of Your Content below).
            <br />
            <br />
            If you believe the copyright in your work has been violated through this Service, please
            send the following information in accordance with the DMCA via our{" "}
            <a href="https://picupmedia.com/contact-us/" target="_blank" rel="noreferrer">
              contact form.
            </a>
          </p>

          <br />
          <ul>
            <li> URL of the work or material that you claim has been infringed.</li>
            <li> URL on picupmedia.com of the material that is claimed to be infringing.</li>
            <li>
              Contact information, including your name, address, telephone number, and email
              address.
            </li>
            <li>
              A statement by you that you have a good faith belief that the disputed use is not
              authorized by the copyright owner, its agent, or law.
            </li>
            <li>
              A statement by you that the above information in your Notice is accurate and that,
              under penalty of perjury, you are the copyright owner or authorized to act on the
              copyright owner’s behalf.
            </li>
          </ul>
          <br />

          <p className={styles.space}>
            Picup Media may forward a copy of a Notice including name and email address to the
            account holder in order to notify that person. Picup Media will terminate, under
            appropriate circumstances, the accounts of users who infringe copyright, and reserves
            the right, in its sole discretion, to terminate any user for actual or apparent
            infringement of intellectual property rights.
          </p>

          <h2>Part 2.</h2>
          <p className={styles.space}>Provisions Applicable to Registered Users</p>

          <h3>2.1 Registration</h3>
          <p className={styles.space}>
            To register as a user of the Service you must be 18 years or over. You agree that the
            information that you provide to us on registration and at all other times will be true,
            accurate, current, and complete. You also agree that you will ensure that this
            information is kept accurate and up to date at all times. This is especially important
            with respect to your email address since that is the primary way in which we will
            communicate with you about your account and your payments.
            <br />
            <br />
            You must be a human. Accounts registered by “bots” or other automated methods are not
            permitted. We reserve the right to refuse service to anyone for any reason at any time.
          </p>

          <h3>2.2. Password</h3>
          <p className={styles.space}>
            When you register as a user, you will be asked to provide a password. As you will be
            responsible for all activities that occur under your password, you should keep your
            password confidential. You should change your password immediately if you believe that
            your password is no longer confidential and{" "}
            <a href="https://picupmedia.com/contact-us/" target="_blank" rel="noreferrer">
              notify Picup Media
            </a>{" "}
            of any unauthorized use of your password. We reserve the right to require you to alter
            your password if we believe that your password is no longer secure.
          </p>

          <h3>2.3. Products and Services</h3>
          <h4>2.3.1 Picup Media Jewelry Photo Retouching Service</h4>
          <p className={styles.space}>
            Picup Media will use commercially reasonable efforts to deliver your image within your
            requested time frame. Whilst Picup Media will endeavor to return edited images back to
            you within the committed timeframes, note that Picup Media does not guarantee that an
            order will meet its requested delivery date.
            <br />
            <br />
            Picup Media may use third-party software and public web addresses to accept images from
            or return images to you. To use any such software and public web addresses, you agree to
            the terms and conditions imposed by the third-party software provider. The agreement to
            use such software will be solely between you and its third-party provider. Picup Media
            makes no representations or warranties concerning, and is not liable or responsible for,
            any such software.
            <br />
            <br />
            Should there be a discrepancy on the committed timeframe and actual delivery date of
            more than 48 hours (excluding weekends), please contact the Picup Media team and we may,
            at our sole discretion, refund any difference in time.
            <br />
            <br />
            Note that the returned image will be deemed accepted by you, with no further work
            required from Picup Media unless, within ten (10) days of receiving the image, you
            notify Picup Media through written communication via email or through Picup Media’s
            website by specifying any errors by Picup Media. Note that it is in Picup Media’s sole
            discretion to rework any services. You will review any corrected image(s) and accept or
            reject the same in accordance with this Section (Section 2.3.1 “Jewelry Photo Retouching
            Services”).
            <br />
            <br />
            Picup Media deletes from its system all images approximately thirty (30) days after
            respective acceptance. Picup Media is not responsible for the quality of returned images
            if the image you originally submitted does not meet Picup Media’s current guidelines.
          </p>

          <h4>2.3.2. GemLightbox and the GemLightbox Application</h4>
          <p className={styles.space}>
            Any images, videos, or any other media captured using the GemLightbox and the
            GemLightbox Application is intellectual property solely owned by the user. Picup Media
            does not have access to, unless explicitly provided by the user, the images, videos, or
            intellectual property captured using the GemLightbox.
            <br />
            <br />
            NOTE: DELETING THE GEMLIGHTBOX SMARTPHONE APPLICATION WILL ALSO DELETE ALL ITS DATA
            INCLUDING ALL IMAGES AND VIDEOS CAPTURED USING THE GEMLIGHTBOX SMARTPHONE APPLICATION.
          </p>

          <h4>2.3.3. Delivery Information</h4>
          <p className={styles.space}>
            For all hardware products, we deliver your order within 1-3 days of payment
            confirmation. Please note that the tracking information may be delayed depending on
            which country you are located in.
          </p>

          <h3>Delivery charges</h3>
          <p className={styles.space}>
            Global rate delivery of $100
            <br />
            <br />
            GemLightbox add-ons have a flat global rate of $30
          </p>

          <h3>2.4. Payment, Refunds, Upgrading, and Downgrading</h3>
          <p>
            The price of services shall be as set out in the price list on Picup Media’s website.
            Picup Media reserves the right to update its price list at any time without prior
            notice. All payments are to be made in United States Dollars. By submitting payment to
            Picup Media, you represent and warrant that you have the legal authority to enter into
            this Agreement, and you have the right to use any credit card(s) or other payment means
            used to initiate any transaction.
            <br />
            <br />
            You are responsible for paying any applicable taxes, duties, or tariffs relating to your
            order, except taxes on Picup Media’s income. All payments made by you to Picup Media for
            retouching orders are non-refundable except in cases where Picup Media has incorrectly
            charged you due to miscalculation.
          </p>

          <br />
          <ul>
            <li> Free accounts do not require a registered credit/debit card.</li>
            <li>
              Paid accounts are charged monthly in advance to your registered credit/debit card. If
              you choose an annual plan with a value above $1,000USD, you may also pay by invoice
              (30-day terms), payable by bank transfer.
            </li>
            <li> You can upgrade, downgrade and cancel at any time.</li>
            <li>
              Upgrades and downgrades are initiated immediately. Upgrades pay the extra for the
              remainder of the month. Downgrades receive a credit to be used against future bills.
            </li>
            <li>
              Invoices are generated monthly, within 3 days of the day of the month your
              subscription commenced.
            </li>
            <li>
              You must ensure that Picup Media can take payment from your card. If Picup Media is
              unable to take payment, you must enter new card details. Your account will be frozen
              in 30 days if payment has not been received.
            </li>
            <li> Any unpaid balance due on your account must be paid.</li>
          </ul>
          <br />

          <h3>2.5. Storage Limit</h3>
          <p className={styles.space}>
            Your account has a storage limit measured in gigabytes (GB). If you need more space, you
            can upgrade to a higher plan or contact us to discuss tailoring a plan to suit your
            needs.
          </p>

          <h3>2.6. Storage Period</h3>
          <p className={styles.space}>
            Picup Media provides online storage of content to its users. If your account becomes
            inactive for a period of 6 months, Picup Media may, at its option, terminate access to
            the account altogether (including deletion of any account information held by Picup
            Media). Picup Media may change this policy at any time, upon reasonable notice to you.
          </p>

          <h3>2.7. Service Level Agreement</h3>
          <p className={styles.space}>
            Picup Media commits to providing 99.95% service availability to all paid accounts. We
            also have a 24/7 live chat and email support team to address any other issues you may
            face.
          </p>

          <h3>2.8. Locked Accounts</h3>
          <h3>
            Notification emails will be sent if your account is approaching or has reached the
            permitted storage/usage limit of the plan you have chosen. If your account remains above
            those limits, your account may be locked.
          </h3>

          <p>When an account is locked:</p>

          <br />
          <ul>
            <li> Images will cease to be served to any of your websites.</li>
            <li> Files cannot be uploaded to your account.</li>
          </ul>
          <br />

          <p className={styles.space}>
            To unlock an account, you must either:
            <br />
            <br />
            Register a valid credit/debit card and upgrade to a suitable plan; Or permanently delete
            sufficient files to return within your existing plans’ limit. Deleted files go to your
            Trash until you permanently delete them.
            <br />
            <br />
            Your account will be unlocked within 5 minutes once you have completed one of the
            options above.
          </p>

          <h3>2.9. User Termination</h3>
          <p className={styles.space}>
            You may cancel your account at any time without a refund. Termination of your account
            does not relieve you of any obligation to pay any outstanding fees.
          </p>

          <h3>2.10. Copyright of Your Content</h3>
          <p className={styles.space}>
            Picup Media does not claim ownership rights in any digital content contained in your
            account. For the sole purpose of enabling us to display your content through the Service
            and fulfilling any orders for you or those you have shared your content with, you grant
            Picup Media a non-exclusive, royalty-free license to use, copy, distribute, and display
            that content.
          </p>

          <h3>2.11. User Conduct</h3>
          <p>
            You are responsible for all of the content you upload, share or copy using the Service.
            You must have the legal right to copy and display each image that you upload, share, or
            print. Professional content that is provided to you by professional content creators or
            made available through websites or other resources is protected by copyright and should
            not be uploaded, shared, or copied using the Service.
            <br />
            <br />
            You agree not to use the Service:
          </p>

          <br />
          <ul>
            <li>for any unlawful purposes including advocating illegal activity.</li>
            <li>
              to upload, post, email or otherwise transmit or communicate any material that is
              obscene, pornographic, unlawful, threatening, menacing, abusive, cruel, harmful,
              propaganda, an invasion of privacy or publicity rights, defamatory, libelous or
              illegal;
            </li>
            <li>
              to upload, post, email or otherwise transmit or communicate any material that you do
              not have a right to transmit or communicate under any contractual or fiduciary
              relationship or which infringes any copyright, trademark, patent, or other
              intellectual property right or any moral right of any party including, but not limited
              to Picup Media;
            </li>
            <li>
              to harm minors in any way, including, but not limited to, content that violates child
              pornography laws, child sexual exploitation laws and laws prohibiting the depiction of
              minors engaged in sexual conduct;
            </li>
            <li>
              to forge headers or otherwise manipulate identifiers in order to disguise the origin
              of any content transmitted through the Service;
            </li>
            <li>
              to upload, post, email or otherwise transmit any material which is likely to cause
              harm to Picup Media or anyone else’s computer systems, including but not limited to
              that which contains any virus, worm, corrupt files, Trojan horses, data, or other
              files or programs or forms of corruptive code designed to damage or allow unauthorized
              access to the Service which may cause any defect, error, malfunction or corruption to
              the Service (collectively Corruptive code).
            </li>
            <li>
              as part of any commercial communications campaign that has not been solicited by the
              recipients (i.e. spam).
            </li>
            <li>as part of any non-natural SEO campaign using spam links.</li>
            <li>as a link to materials associated with any of the above.</li>
          </ul>
          <br />

          <p>
            Violation of any of these agreements will result in the termination of your Picup Media
            account. While Picup Media prohibits such conduct and content on its site, you
            understand and agree that Picup Media cannot be responsible for the content posted on
            its website and you nonetheless may be exposed to such materials and that you use the
            Picup Media service at your own risk.
            <br />
            <br />
            You further agree that you shall not:
          </p>

          <br />
          <ul>
            <li>
              interfere with or disrupt (or attempt to interfere with or disrupt) this Service or
              servers or networks connected to this Service, or to disobey any requirements,
              procedures, policies or regulations of networks connected to this Service;
            </li>
            <li>
              provide any information to Picup Media that is false or misleading, that attempts to
              hide your identity or that you do not have the right to disclose;
            </li>
            <li>make duplicate user accounts to bypass account restrictions.</li>
          </ul>
          <br />

          <p className={styles.space}>
            All Content (whether private or public) is the sole responsibility of the person who
            submitted it. You are solely responsible for your Content. By viewing the services, you
            may be exposed to Content that you consider offensive. You take sole responsibility for
            any such exposure. Picup Media, in no way, guarantees the accuracy, quality, or
            appropriateness of Content available through the Services.
          </p>

          <h3>2.12. Monitoring of Content</h3>
          <p className={styles.space}>
            Picup Media makes random and regular checks on the content of users’ accounts, to look
            for any illegal content, including content deemed illegal due to copyright. Should any
            illegal content be found, the content will be erased immediately. You acknowledge that
            you are solely responsible for all content and activity that occurs on your user
            account.
            <br />
            <br />
            By using our services, you also hereby grant Picup Media a non-exclusive, worldwide,
            transferable, royalty-free, sublicensable (through multiple tiers of sublicenses
            including but not limited to Picup Media’s subcontractors) perpetual, irrevocable
            license under all intellectual property rights throughout the world (including but not
            limited to copyrights, trademarks, trade secret, moral, publicity and privacy rights) to
            reproduce, modify, edit, make derivate works from, distribute (through multiple tiers),
            publicly perform and display and otherwise use the image as reasonably necessary for
            Picup Media and its subcontractors to perform the services you requested hereunder you
            agree to indemnify and hold harmless Picup Media, its subcontractors, licensors and
            affiliates and their directors, officers, shareholders, employees and agents from any
            and all claims, liabilities or expenses (including without limitation attorneys’ fees)
            that arise directly or indirectly from your breach of this Agreement.
          </p>

          <h3>2.13. Suspension, Modification, and Termination of Access</h3>
          <p>You agree that Picup Media may, at any time without notice:</p>

          <br />
          <ul className={styles.space}>
            <li>refuse to accept your application to create a Picup Media account;</li>
            <li>remove or suspend any part of the Service;</li>
            <li>
              refuse to fulfill any order, or any part of any order or terminate your account and
              delete any content stored in your account if, in Picup Media’s sole discretion, you
              fail to comply with any of these terms or if a competent regulatory authority requires
              Picup Media to do so;
            </li>
            <li>
              modify any username or other content that could be confusing with any Picup Media
              services or which contains any explicit/objectionable content.
            </li>
          </ul>

          <h2>Part 3.</h2>
          <h3>3.1. Amendment of the Terms</h3>
          <p className={styles.space}>
            Picup Media reserves the right to amend these Terms of Service from time to time without
            notice. You can review the most current version of the Terms of Service at any time at{" "}
            <a href="https://picupmedia.com/terms-of-service/" target="_blank" rel="noreferrer">
              https://picupmedia.com/terms-of-service/
            </a>
          </p>

          <h3>3.2. Applicable Law and Jurisdiction</h3>
          <p className={styles.space}>
            These Terms of Use shall be governed and interpreted in accordance with Hong Kong law.
            If any provision of these Terms of Use is found to be unenforceable, this shall not
            affect the validity of any other provision. We may delay enforcing any of our rights
            under the Terms of Use without losing them. You agree that we may assign these Terms of
            Use or any of our rights or obligations without giving you notice. We shall not be
            liable to any third party in respect of your use of (or inability to use) the website.
          </p>

          <h3>3.3. General</h3>
          <p className={styles.space}>
            These Terms constitute the entire agreement between you and Picup Media with respect to
            your use of the Service. Picup Media’s failure to enforce any right or provision in
            these terms shall not constitute a waiver of such right or provision.
            <br />
            <br />
            If a court shall find that one or more rights or provisions contained in terms are
            invalid, you agree that the remainder of the terms shall be enforceable.
          </p>

          <h3>3.4. Operator</h3>
          <p className={styles.space}>
            This Service is operated by Picup Media, a business owned and operated by HEIG Limited,
            a company incorporated in Hong Kong.
            <br />
            <br />
            Picup Media is also referred to in these terms as “we”, “us” and “the Service”. If you
            have any comments or questions about the Service please contact us by email through our{" "}
            <a href="https://picupmedia.com/contact-us/" target="_blank" rel="noreferrer">
              contact form.
            </a>
          </p>

          <h3>3.5. Disclaimer of Warranty and Limitation of Liability</h3>
          <p>
            Picup Media agrees that all services will be carried out with reasonable skill and care.
            EXCEPT FOR THE PROCEDURE SET FORTH IN SECTION 2.3.1 (“Jewelry Photo Retouching
            Services”), THE SERVICES ARE PROVIDED “AS IS”. NEITHER PICUP MEDIA NOR ITS LICENSORS OR
            SUBCONTRACTORS MAKE ANY WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, REGARDING THE
            SERVICES PROVIDED HEREUNDER. ALL IMPLIED WARRANTIES AS TO SATISFACTORY QUALITY,
            PERFORMANCE, MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSES, OR NON-INFRINGEMENT ARE
            EXPRESSLY DISCLAIMED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
            WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO SUCH LIMITATIONS
            OR EXCLUSIONS MAY NOT APPLY TO YOU.
            <br />
            <br />
            You understand and agree that the submission of any digital content to Picup Media and
            the download or upload of any material through the service is done at your discretion
            and risk and that you will be solely responsible for any loss or damage to your content
            or any damage to your computer system or loss of data that may result in the download or
            upload of any material. You are solely responsible for creating back-ups of your digital
            content.
            <br />
            <br />
            Except as expressly provided in the preceding paragraph, to the fullest extent allowed
            by law, Picup Media shall not be liable for any direct, indirect, incidental,
            consequential, special, exemplary, and punitive damages resulting from the use or
            provision of the service, Picup Media’s site, and all related services, even if Picup
            Media has been advised of the possibility of such damages. The foregoing disclaimers,
            waivers, and limitations shall apply notwithstanding any failure of essential purpose of
            any limited remedy.
          </p>
        </div>
      </article>
    );
  },
);

export default TermsOfServiceArticle;
