import React from "react";
import { observer } from "mobx-react-lite";
import { addMonths, Heading, Typography, Input, useQuery } from "@gemlightbox/core-kit";

import { formatDate } from "src/common/helpers/subscriptions.helpers";
import { useStores } from "src/hooks";
import { BillingInfoItem } from "../billing-info-item";

import styles from "../../billing-modal.module.css";

export interface BillingYearlyProps {
  coupon: string;
  balance: number;
  yearlyPrice: number;
  monthlyToYearly: boolean;
  upgrading: boolean;
  onCouponChange: (value: string) => void;
  promoCodeError?: string;
  promoCodeRequired?: boolean;
}

export const BillingYearly: React.FC<BillingYearlyProps> = observer(
  ({
    coupon,
    balance,
    yearlyPrice,
    monthlyToYearly,
    upgrading,
    onCouponChange,
    promoCodeError = "",
    promoCodeRequired,
  }: BillingYearlyProps) => {
    const { localeStore, subscriptionsStore } = useStores();

    const {
      query: { bluetooth_name },
    } = useQuery();

    const yearMonthDiff = Math.round((yearlyPrice - balance) * 100) / 100;

    return (
      <div className={styles.rightContainer}>
        <div className={styles.rightContent}>
          <Heading className={styles.leftHeader} tag="h3" color="textSecondary">
            US${yearlyPrice}/year
          </Heading>

          <div className={styles.billingInfoContainer}>
            {monthlyToYearly && !upgrading && (
              <BillingInfoItem
                content={
                  <Typography
                    className={styles.offerItemName}
                    size="extraSmall"
                    color="textSecondary"
                  >
                    {localeStore.t(
                      'settings.subscriptions.components["billing-modal"].components["billing-yearly"]["monthly-to-yearly"]',
                    )}
                  </Typography>
                }
              />
            )}
            <BillingInfoItem
              content={
                <Typography
                  className={styles.offerItemName}
                  size="extraSmall"
                  color="textSecondary"
                >
                  {localeStore.t(
                    'settings.subscriptions.components.content["lifetime-offer"]["today-charge"].first',
                  )}{" "}
                  US${subscriptionsStore.formattedBillingInfo?.current_amount_due ?? "--"}{" "}
                  {localeStore.t(
                    'settings.subscriptions.components.content["lifetime-offer"]["today-charge"].today',
                  )}{" "}
                  ({localeStore.t("settings.subscriptions.components.content.balance")}).
                  <br />
                  <span>
                    (${yearlyPrice / 12}/mo x{" "}
                    {localeStore.t(
                      'settings.subscriptions.components["billing-modal"].components["billing-yearly"]["today-charge"].last',
                    )}
                    )
                  </span>
                </Typography>
              }
            />
            <BillingInfoItem
              content={
                <Typography
                  className={styles.offerItemName}
                  size="extraSmall"
                  color="textSecondary"
                >
                  {localeStore.t(
                    'settings.subscriptions.components["billing-modal"].components["billing-yearly"].thereafter.first',
                  )}{" "}
                  US${yearlyPrice}{" "}
                  {localeStore.t(
                    'settings.subscriptions.components["billing-modal"].components["billing-yearly"].thereafter.last',
                  )}
                  .{" "}
                  <span className={styles.nextDate}>
                    {localeStore.t(
                      'settings.subscriptions.components["billing-modal"].components["next-billing"]',
                    )}{" "}
                    {subscriptionsStore.formattedBillingInfo
                      ? formatDate(subscriptionsStore.formattedBillingInfo.next_billing_date)
                      : formatDate(addMonths(12, Date.now()))}
                  </span>
                </Typography>
              }
            />
          </div>
        </div>

        {(promoCodeRequired || !subscriptionsStore.isSubscribed) && !bluetooth_name && (
          <Input
            className={styles.promoCodeContainer}
            label={localeStore.t(
              'settings.subscriptions.components["billing-modal"]["promo-code"].label',
            )}
            placeholder={localeStore.t(
              'settings.subscriptions.components["billing-modal"]["promo-code"].placeholder',
            )}
            appearance="primaryV2"
            value={coupon}
            error={promoCodeError}
            onChange={onCouponChange}
            disableError={!promoCodeRequired}
          />
        )}
      </div>
    );
  },
);

export default BillingYearly;
