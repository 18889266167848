import React, { useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  FileUploadFullscreen,
  filteredMap,
  Modal,
  Heading,
  Typography,
  uniqId,
  useDidUpdate,
  useDidUnmount,
  Button,
  SvgIcon,
  useBoolean,
} from "@gemlightbox/core-kit";

import { PageContainer, PageHeader, ProUserUpgradeModal } from "src/external-ts/components";
import { usePageRedirect, usePageViewEvent, useStores } from "src/hooks";
import { CAMERA_PAGE, CREATE_PAGE, MEDIA_PAGE, SETUP_DOWNLOAD_PAGE } from "src/constants";
import WSConnection from "src/common/socket";
import ProgressLine from "src/components/ProgressLine";
import { RemoveBackground } from "./remove-background";
import { AugmentedReality } from "./augmented-reality";
import { AIProductDescription } from "./ai-product-description";
import { AIProductDescriptionBlock } from "./ai-product-description-block";
import { RemoveBackgroundBlock } from "./remove-background-block";
import { AugmentedRealityBlock } from "./augmented-reality-block";
import { AugmentedRealityProduct } from "./augmented-reality-product";
import { TutorialBlock } from "./tutorial-block";
import {
  createPageUploaderAccept,
  createPageMaxSizeInBytes,
  createPageMaxFiles,
} from "./create.constants";
import { pushUploadMediaDataLayerEvent } from "src/utils";

import { ReactComponent as RecordIconSVG } from "src/external-ts/assets/images/record-icon.svg";
import styles from "./create.page.module.css";
import { CallappOptions } from "callapp-lib/dist/type/types";
import CallApp from "callapp-lib";
import CloudStorageFullAlert from "src/components/CloudStorageFullAlert";

export const CreatePage: React.FC = observer(() => {
  usePageViewEvent();
  const [redirect] = usePageRedirect();
  const navigate = useNavigate();
  const { mediaStore, localeStore, userStore } = useStores();

  const [mediaWS] = useState(() => new WSConnection("/media", userStore.token));
  const [sentFilesIds, setSentFilesIds] = useState<string[]>([]);
  const [processedFiles, setProcessedFiles] = useState(0);
  const [proUserUpgradeContent, setProUserUpgradeContent] = useState("");
  const proUserUpgradeBool = useBoolean(false);

  const uploadProgress = mediaStore.uploadingProgress?.progress || 0;
  const processingProgress = (processedFiles / sentFilesIds.length) * 100;

  const isInUpload = uploadProgress !== 100;
  const progressToShow = isInUpload ? uploadProgress : processingProgress;

  useDidUpdate(() => {
    mediaWS.on("media/UPLOAD_STATUS", ({ payload }: any) => {
      const fileId = payload?.media_data?.uploadID;
      const validFile = !!sentFilesIds.find((id) => id === fileId);
      if (validFile) setProcessedFiles((prevState) => prevState + 1);
    });
  }, [sentFilesIds]);

  useDidUnmount(() => mediaWS.destroy());

  const handleDropAccepted = async (acceptedFiles: File[]) => {
    const data = filteredMap(acceptedFiles, (file) => {
      if ([".jpeg", ".jpg", ".png"].some((format) => file.name.toLowerCase().includes(format))) {
        return { uploadIDs: uniqId(), files: file, types: "image" };
      }

      if ([".mov", ".mp4"].some((format) => file.name.toLowerCase().includes(format))) {
        return { uploadIDs: uniqId(), files: file, types: "video" };
      }

      return undefined;
    });

    const ids = data.map(({ uploadIDs }) => uploadIDs);
    setSentFilesIds(ids);

    const result = await mediaStore.uploadMedia(data as any);

    if (result.success) {
      pushUploadMediaDataLayerEvent({
        media_total: result.success.total_items,
        uploaded_total: data.length,
      });
      navigate(MEDIA_PAGE.path);
    }
  };

  const handleRecordClick = () => {
    if (!window.$platform.isApp && window.$platform.os === "Win") {
      const options = {
        scheme: {
          protocol: "GemLightbox",
        },
      } as CallappOptions;
      const callLib = new CallApp(options);

      callLib.open({
        path: `emailVerification${CAMERA_PAGE.path}`,
        callback: () => {
          redirect(SETUP_DOWNLOAD_PAGE.path);
        },
      });
    } else {
      redirect(CAMERA_PAGE.path);
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageContainer>
            <PageHeader title={localeStore.t("create.title")} showSubscriptionBadge />
            <CloudStorageFullAlert />
            <FileUploadFullscreen
              data-cy="upload-conatiner"
              className={styles.uploadContainer}
              title={
                userStore.hasAttemptedGemCamSetup
                  ? localeStore.t("create.gemcam.title")
                  : localeStore.t('create["file-upload-full-screen"].title')
              }
              subtitle={
                userStore.hasAttemptedGemCamSetup
                  ? localeStore.t("create.gemcam.subtitle")
                  : localeStore.t('create["file-upload-full-screen"].subtitle')
              }
              orText={
                userStore.hasAttemptedGemCamSetup
                  ? ""
                  : localeStore.t('create["file-upload-full-screen"]["or-text"]')
              }
              uploadButtonText={localeStore.t(
                'create["file-upload-full-screen"]["upload-button-text"]',
              )}
              backdropText={localeStore.t('create["file-upload-full-screen"]["backdrop-text"]')}
              accept={createPageUploaderAccept}
              maxSize={createPageMaxSizeInBytes}
              maxFiles={createPageMaxFiles}
              onDropAccepted={handleDropAccepted}
              preventDropOnDocument
              multiple
            >
              {userStore.hasAttemptedGemCamSetup && (
                <Button className={styles.recordBtn} onClick={handleRecordClick}>
                  <SvgIcon icon={RecordIconSVG} />
                  {localeStore.t("create.gemcam.redirect")}
                </Button>
              )}
            </FileUploadFullscreen>
            <div className={styles.blocksContainer}>
              <RemoveBackgroundBlock
                visible={proUserUpgradeBool}
                contentSet={setProUserUpgradeContent}
              />
              <AugmentedRealityBlock />
              <AIProductDescriptionBlock
                visible={proUserUpgradeBool}
                contentSet={setProUserUpgradeContent}
              />
            </div>
            {userStore.hasAttemptedGemCamSetup && (
              <div className={styles.blocksContainer}>
                <TutorialBlock />
              </div>
            )}
            <Modal
              scrollWrapperClassName={styles.modalScrollContent}
              contentClassName={styles.modalContent}
              isOpen={mediaStore.uploadingMedia}
            >
              <Heading className={styles.modalHeading} color="textSecondary" tag="h3">
                {localeStore.t("create.modal.title")}
              </Heading>

              <div className={styles.modalProcessWrapper}>
                <Typography size="small600" color="textSecondary">
                  {isInUpload
                    ? localeStore.t("create.modal.processing.upload")
                    : localeStore.t("create.modal.processing.process")}
                </Typography>
                <Typography size="extraSmall500" color="textPrimary">
                  {isInUpload ? `${uploadProgress}%` : `${processedFiles}/${sentFilesIds.length}`}
                </Typography>
              </div>

              <ProgressLine progress={progressToShow} />
            </Modal>

            <ProUserUpgradeModal
              visible={proUserUpgradeBool}
              onClose={proUserUpgradeBool.setFalsy}
              content={proUserUpgradeContent}
            ></ProUserUpgradeModal>
          </PageContainer>
        }
      />
      <Route path="/remove-background/:type" element={<RemoveBackground />} />
      <Route path="/augmented-reality/:type" element={<AugmentedReality />} />
      <Route path="/augmented-reality-product/:productId" element={<AugmentedRealityProduct />} />
      <Route path="/augmented-reality-product" element={<AugmentedRealityProduct />} />
      <Route path="/ai-product-description" element={<AIProductDescription />} />
      <Route path="*" element={<Navigate to={CREATE_PAGE.path} />} />
    </Routes>
  );
});

export default CreatePage;
