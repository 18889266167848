import LocalStorage, { STORAGE_TYPES } from "src/common/helpers/local-storage.helper";
import qs from "qs";
import { setUserData } from "src/common/helpers/user.helpers";

const LOGIN_IN_PROGRESS = "user/LOGIN_IN_PROGRESS";
export const LOGIN_SUCCESS = "user/LOGIN_SUCCESS";
const LOGIN_FAILED = "user/LOGIN_FAILED";

const SIGNUP_IN_PROGRESS = "user/SIGNUP_IN_PROGRESS";
const SIGNUP_SUCCESS = "user/SIGNUP_SUCCESS";
const SIGNUP_FAILED = "user/SIGNUP_FAILED";

const LOAD_ME_SUCCESS = "user/LOAD_ME_SUCCESS";
const LOAD_INVOICES = "user/LOAD_INVOICES";

export const LOGOUT = "user/LOGOUT";

const CHANGE_USER_AVATAR = "user/CHANGE_USER_AVATAR";

const UPDATE_PROFILE_IN_PROGRESS = "user/UPDATE_PROFILE_IN_PROGRESS";
const UPDATE_PROFILE_SUCCESS = "user/UPDATE_PROFILE_SUCCESS";
const UPDATE_PROFILE_FAILED = "user/UPDATE_PROFILE_FAILED";

const SET_INITIAL_PROFILE = "user/SET_INITIAL_PROFILE";

const LOAD_IP_DATA = "user/LOAD_IP_DATA";
const LOAD_STORAGE_USAGE = "user/LOAD_STORAGE_USAGE";

const CHANGE_GALLERY_COLOR = "user/CHANGE_GALLERY_COLOR";

const CLEAR_FROM_RESOURCE = "user/CLEAR_FROM_RESOURCE";

const TOGGLE_LINK_EXPIRED_MODAL = "user/TOGGLE_LINK_EXPIRED_MODAL";

const LOAD_MY_PROFILE = "user/LOAD_MY_PROFILE";

const SET_TOKEN_TO_STORAGE = "user/SET_TOKEN_TO_STORAGE";

const SET_ACTIVE_SHOPIFY_SHOP = "user/SET_ACTIVE_SHOPIFY_SHOP";

export const userStorage = new LocalStorage(STORAGE_TYPES.USER_DATA);

let data = userStorage.get();

const queryParams = qs.parse(window.location.search.replace("?", ""));

if (queryParams && queryParams.profile) {
  data = {
    user: queryParams.profile,
    token: queryParams.user.replace("token=", ""),
  };

  userStorage.set(data);
}

const initialColors = {
  link: "#000000",
  icons: "#000000",
  button: "#02CAC4",
  text: "#ffffff",
};

const initialState = {
  isLogged: !!data.token,
  modals: {
    linkExpired: {
      isOpened: false,
    },
  },
  storageUsage: 0,
  isLoading: false,
  isValid: true,
  countOfUsers: 0,
  isSubaccount: false,
  isSubscribed: true,
  isShopifyUser: false,
  token: data.token,
  data: data.user,
  initialData: data.user,
  fromResource: queryParams.fromResource,
  invoices: null,
  dns_host: data.dns_host,
  dns_resolved: data.dns_resolved,
  activeShopifyShop: "",
  gallery: {
    logo: null,
    colors: initialColors,
  },
  ipData: {
    country: "US",
  },
  cloudVideos: {
    amount: 0,
  },
  cloudImages: {
    amount: 0,
  },
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_IN_PROGRESS:
    case LOGIN_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_ME_SUCCESS:
      userStorage.set({ ...userStorage.get(), ...action.data });

      return {
        ...state,
        ...setUserData(action),
      };
    case LOGIN_SUCCESS:
      userStorage.set({ ...userStorage.get(), ...action.data });

      return {
        ...state,
        ...setUserData(action),
        token: action.data.token,
      };
    case LOAD_MY_PROFILE:
      return {
        ...state,
        cloudVideos: {
          ...state.cloudVideos,
          amount: action.data.user.videos_details.total_count,
        },
        cloudImages: {
          ...state.cloudImages,
          amount: action.data.user.images_details.total_count,
        },
      };
    case LOAD_STORAGE_USAGE:
      return {
        ...state,
        storageUsage: action.data,
      };
    case SIGNUP_FAILED:
    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case LOGOUT:
      userStorage.destroy();

      if (action.withReload) window.location.reload(true);

      return {
        ...initialState,
        isLogged: false,
        data: {},
        token: undefined,
      };
    case CHANGE_USER_AVATAR:
      return {
        ...state,
        data: {
          ...state.data,
          avatar: action.data,
        },
      };
    case UPDATE_PROFILE_IN_PROGRESS:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_PROFILE_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      userStorage.set({ ...userStorage.get(), user: action.data });

      return {
        ...state,
        dns_host: action.dns_host === undefined ? state.dns_host : action.dns_host,
        dns_resolved: action.dns_resolved === undefined ? state.dns_resolved : action.dns_resolved,
        data: action.data,
        isUpdatingShop: false,
        isUpdating: false,
      };
    case SET_INITIAL_PROFILE:
      return {
        ...state,
        data: state.initialData,
      };
    case LOAD_IP_DATA:
      return {
        ...state,
        ipData: {
          country: action.data.country,
          city: action.data.city,
        },
      };
    case CHANGE_GALLERY_COLOR:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          colors: {
            ...state.gallery.colors,
            [action.element]: action.color,
          },
        },
      };
    case TOGGLE_LINK_EXPIRED_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          linkExpired: {
            ...state.modals.linkExpired,
            isOpened: !state.modals.linkExpired.isOpened,
          },
        },
      };
    case LOAD_INVOICES:
      return {
        ...state,
        invoices: action.data,
      };
    case CLEAR_FROM_RESOURCE:
      return {
        ...state,
        fromResource: undefined,
      };
    case SET_TOKEN_TO_STORAGE:
      userStorage.set({ ...userStorage.get(), token: action.token });
      return {
        ...state,
        token: action.token,
      };
    case SET_ACTIVE_SHOPIFY_SHOP:
      return {
        ...state,
        activeShopifyShop: action.shop,
      };
    default:
      return state;
  }
};

// export const login = ({ email, password }, shopify_token) => ({
//   types: [LOGIN_IN_PROGRESS, LOGIN_SUCCESS, LOGIN_FAILED],
//   request: (client) =>
//     client.post(
//       "/auth/local",
//       { email, password },
//       { headers: { shopify_token: shopify_token || "" } },
//     ),
//   withReload: false,
// });

export const login = (data) => ({
  type: LOGIN_SUCCESS,
  data,
});

export const setTokenToStorage = (token) => ({
  type: SET_TOKEN_TO_STORAGE,
  token,
});

export const setActiveShopifyShop = (shop) => ({ type: SET_ACTIVE_SHOPIFY_SHOP, shop });

export const signUp = (data, shopify_token) => ({
  types: [SIGNUP_IN_PROGRESS, SIGNUP_SUCCESS, SIGNUP_FAILED],
  request: (client) =>
    client.post("/api/users", data, { headers: { shopify_token: shopify_token || "" } }),
});

export const logout = (withReload = true) => ({
  type: LOGOUT,
  withReload,
});

// export const loadMe = () => ({
//   type: LOAD_ME_SUCCESS,
//   request: (client) => client.get("/api/users/me"),
//   isAuthReq: true,
// });

export const loadMe = (data) => ({
  type: LOAD_ME_SUCCESS,
  data,
});

export const loadMyProfile = () => ({
  type: LOAD_MY_PROFILE,
  request: (client) => client.get("/api/users/me/profile"),
  isAuthReq: true,
});

export const clearFromResource = () => ({ type: CLEAR_FROM_RESOURCE });
