import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Image, Typography, Button, UseBooleanReturnType } from "@gemlightbox/core-kit";

import { getStaticUrl } from "src/utils";
import { useStores } from "src/hooks";
import { BlockWrapper } from "../block-wrapper";
import { BlockTitle } from "../block-title";
import { aiDescriptionBlockSubId } from "./ai-product-description-block.constants";

import styles from "./ai-product-description-block.module.css";

type AIProductDescriptionBlockProps = {
  visible: UseBooleanReturnType;
  contentSet: React.Dispatch<React.SetStateAction<string>>;
};

export const AIProductDescriptionBlock: React.FC<AIProductDescriptionBlockProps> = observer(
  ({ visible, contentSet }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { localeStore, subscriptionsStore, userStore } = useStores();
    const { isFreeSubscription } = subscriptionsStore;

    const handleClickTryNow = () => {
      if (
        isFreeSubscription &&
        (userStore.userUsage?.countGPTDescriptionsAttemptMonth ?? 0) >=
          subscriptionsStore.limits.countGPTDescription
      ) {
        contentSet(localeStore.t('common["pro-user"]["description-upgrade-text"]'));
        visible.setTruthy();
        return;
      }
      navigate(location.pathname + "/ai-product-description");
    };

    return (
      <BlockWrapper className={styles.aiDescriptionContainer}>
        <BlockTitle
          title={localeStore.t('create["ai-product-description-block"].title')}
          tooltipText={localeStore.t('create["ai-product-description-block"].subtitle')}
          showTag={isFreeSubscription}
          count={Math.max(
            0,
            subscriptionsStore.limits.countGPTDescription -
              (userStore.userUsage?.countGPTDescriptionsAttemptMonth ?? 0),
          )}
          total={subscriptionsStore.limits.countGPTDescription}
        />
        <div id={aiDescriptionBlockSubId} className={styles.aiContentBlock}>
          <Image
            src={getStaticUrl("/ai-description-thumbnail.png")}
            loading="eager"
            size={[86, 63]}
          />

          <div className={styles.aiTextContentBlock}>
            <Typography size="extraSmall">
              {localeStore.t('create["ai-product-description-block"].description')}
            </Typography>
            <Button
              appearance="primaryGhost"
              onClick={handleClickTryNow}
              data-cy="create-ai-description"
            >
              {localeStore.t('create["ai-product-description-block"]["try-now"]')}
            </Button>
          </div>
        </div>
      </BlockWrapper>
    );
  },
);

export default AIProductDescriptionBlock;
