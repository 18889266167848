import React from "react";
import { observer } from "mobx-react-lite";
import { RefModel } from "@gemlightbox/core-kit";

import { ViewTypeKeys } from "src/external-ts/components";
import { GridView } from "./grid-view";
import { TableView } from "./table-view";

import styles from "./catalogs-list.module.css";

export type CatalogsListProps = {
  view: ViewTypeKeys;
  containerRef: RefModel<HTMLDivElement>;
};

export const CatalogsList: React.FC<CatalogsListProps> = observer(({ view, containerRef }) => {
  return (
    <div className={styles.catalogListContainer}>
      {view === "grid" && <GridView containerRef={containerRef} />}
      {view === "table" && <TableView containerRef={containerRef} />}
    </div>
  );
});
