import React from "react";

import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

export const MuiTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement="bottom" arrow {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--palette-primary)",
    color: "#fff",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
    fontSize: "14px",
    lineHeight: "18px",
    padding: "10px 16px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--palette-primary)",
  },
}));
