import React from "react";
import { Info, memo, Heading } from "@gemlightbox/core-kit";

import { ReactComponent as ToolTipsSVG } from "src/external-ts/assets/images/camera/auto-photography-tips.svg";
import styles from "./block-title.module.css";

export type BlockTitleProps = {
  title: string;
  tooltipText: string;
  showTag?: boolean;
  count?: number;
  total?: number;
};

export const BlockTitle: React.FC<BlockTitleProps> = memo(
  ({ title, tooltipText, showTag, count, total }) => {
    return (
      <div className={styles.blockTitleContainer}>
        <Heading tag="h4">{title}</Heading>
        {showTag && (
          <div className={styles.countTag}>
            <span>{count || 0}</span>/<span>{total || 0}</span>
          </div>
        )}
        <Info
          className={styles.infoTooltip}
          toolTipClassName={styles.blockTooltip}
          position="top"
          withAngle
          icon={ToolTipsSVG}
          size={16}
        >
          {tooltipText}
        </Info>
      </div>
    );
  },
);

export default BlockTitle;
