import { ObjectType } from "@gemlightbox/core-kit";
import { pushDataLayerEvent, GoogleTagSupportedEvents } from "./index";
import { bytesToSize, sizeTobytes } from "src/utils/common.utils";

type mediaLocalSaveEventKeys =
  | "enableSuccess"
  | "enableError"
  | "disableSuccess"
  | "setConfigSuccess"
  | "saveMediaSuccess"
  | "saveMediaError"
  | "configDialogOpen";

type mediaLocalSaveParams = {
  base_path?: string;
  reserved_disk_space?: number;
  reserved_disk_space_unit?: string;
  error_message?: string;
  local_media_filenames?: string;
  product_sku?: string;
  use_custom_reserved_disk_space?: boolean;
};

export const pushMediaLocalSaveLayerEvent = (
  type: mediaLocalSaveEventKeys,
  params?: mediaLocalSaveParams,
) => {
  const mediaLocalSaveEventMap: ObjectType<GoogleTagSupportedEvents, mediaLocalSaveEventKeys> = {
    enableSuccess: "gemhub:camera:media:local_save:enable:success",
    enableError: "gemhub:camera:media:local_save:enable:error",
    disableSuccess: "gemhub:camera:media:local_save:disable:success",
    setConfigSuccess: "gemhub:camera:media:local_save:set_config:success",
    saveMediaSuccess: "gemhub:camera:media:local_save:save_media:success",
    saveMediaError: "gemhub:camera:media:local_save:save_media:error",
    configDialogOpen: "gemhub:camera:media:local_save:config_dialog:open",
  };

  const event = mediaLocalSaveEventMap[type];

  if (!params) {
    pushDataLayerEvent({ event });
  } else if (params?.reserved_disk_space && params?.reserved_disk_space_unit) {
    const reserved_disk_space_in_mb =
      params.reserved_disk_space_unit === "MB"
        ? params.reserved_disk_space
        : bytesToSize(
            sizeTobytes(params.reserved_disk_space, params.reserved_disk_space_unit),
            "MB",
          );

    const { reserved_disk_space, reserved_disk_space_unit, ...event_params } = params;
    pushDataLayerEvent({ event, event_params: { ...event_params, reserved_disk_space_in_mb } });
  } else {
    pushDataLayerEvent({ event, event_params: params });
  }
};
