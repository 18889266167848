import React from "react";
import { clsx, memo } from "@gemlightbox/core-kit";

import styles from "./page-container.module.css";

export type PageContainerProps = {
  className?: string;
};

export const PageContainer: React.FC<PageContainerProps> = memo(({ children, className }) => {
  return <main className={clsx(styles.container, className)}>{children}</main>;
});
