import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, clsx, Button, useBoolean } from "@gemlightbox/core-kit";

import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { ReactComponent as BackgroundSVG } from "src/external-ts/assets/images/edit-media/background-grey.svg";
import { ReactComponent as PointerSVG } from "src/external-ts/assets/images/pointer-grey.svg";
import styles from "./description-content.module.css";
import { ProUserUpgradeModal } from "src/external-ts/components/business";
import { useStores } from "src/hooks";

export type DescriptionContentProps = {
  hasValue: boolean;
  onCreateAIDescription: VoidFunction;
  onEnterManuallyClick: VoidFunction;
  onUpgradeClick?: VoidFunction;
};

export const DescriptionContent: React.FC<DescriptionContentProps> = observer(
  ({ hasValue, onCreateAIDescription, onEnterManuallyClick }: DescriptionContentProps) => {
    const { canGenerateDescription } = useLimits();
    const isLocked = !canGenerateDescription;
    const [proUserUpgradeContent, setProUserUpgradeContent] = useState("");
    const proUserUpgradeBool = useBoolean(false);
    const { localeStore, subscriptionsStore, userStore } = useStores();
    const containerStyles = clsx(styles.container, { [styles.hasValue]: hasValue });

    const handleClick = () => {
      if (
        !userStore.isSubscribed &&
        (userStore.userUsage?.countGPTDescriptionsAttemptMonth ?? 0) >=
          subscriptionsStore.limits.countGPTDescription
      ) {
        setProUserUpgradeContent(localeStore.t('common["pro-user"]["description-upgrade-text"]'));
        proUserUpgradeBool.setTruthy();
        return;
      }
      if (isLocked) return;
      onCreateAIDescription();
    };

    if (hasValue) return null;

    return (
      <div className={containerStyles}>
        <Button appearance="tertiaryOutlined" onClick={handleClick} data-cy="ai-description-button">
          <SvgIcon icon={BackgroundSVG} />
          {localeStore.t(
            'products["products-list"]["products-table"]["products-table-header"].descriptionUsingAI',
          )}
          {!userStore.isSubscribed &&
            `(${Math.max(
              0,
              subscriptionsStore.limits.countGPTDescription -
                (userStore.userUsage?.countGPTDescriptionsAttemptMonth ?? 0),
            )}/${subscriptionsStore.limits.countGPTDescription})`}
          <div className={styles.badge}>NEW</div>
        </Button>

        {!hasValue && (
          <Button
            appearance="secondaryGhost"
            onClick={onEnterManuallyClick}
            data-cy="manual-description-button"
          >
            <SvgIcon icon={PointerSVG} />
            {localeStore.t(
              'products["products-list"]["products-table"]["products-table-header"].descriptionUsingManually',
            )}
          </Button>
        )}

        <ProUserUpgradeModal
          visible={proUserUpgradeBool}
          onClose={proUserUpgradeBool.setFalsy}
          content={proUserUpgradeContent}
        ></ProUserUpgradeModal>
      </div>
    );
  },
);

export default DescriptionContent;
