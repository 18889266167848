import React from "react";
import { observer } from "mobx-react-lite";
import {
  ExpandableSidebar,
  ObjectType,
  Tabs,
  useDidMount,
  useDidUnmount,
  clsx,
  useInfinityScroll,
  useMediaBreakpoints,
} from "@gemlightbox/core-kit";

import { mediaTabs } from "src/containers/media/media-control-panel/media-control-panel.constants";
import { MediaItem } from "src/containers/media/media-list";
import { MediaFilterTypeKeys } from "src/containers/media/media.types";
import { useStores } from "src/hooks";
import { ExtendedMediaModel } from "src/store";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./no-sku-media-sidebar.module.css";

export type NoSKUMediaSidebarProps = {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
  options: ObjectType;
};

export const NoSKUMediaSidebar: React.FC<NoSKUMediaSidebarProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const { mediaStore, localeStore } = useStores();

    const { isMobileMedia } = useMediaBreakpoints();

    const useInfinityScrollHook = useInfinityScroll<HTMLDivElement>();
    useInfinityScrollHook.onScroll(() => {
      const { limit = 1, page = 1 } = mediaStore.filters;
      const offset = page * limit;

      if (offset < mediaStore.resultsMediaAmount) {
        useInfinityScrollHook.resetTrigger();

        mediaStore.updateMediaFilter("page", (mediaStore.filters.page || 0) + 1, "upload");
      }
    });

    useDidMount(async () => {
      await mediaStore.loadMediaList({ withoutSku: true, page: 1, limit: 20 });
    });

    useDidUnmount(() => {
      mediaStore.loadMediaList({ withoutSku: true, limit: 4 });
    });

    const getMediaTabsLocalized = () => {
      return mediaTabs.map(({ value, label }) => {
        switch (value) {
          case "all":
            return { label: localeStore.t('media["media-control-panel"].tabs.all'), value };
          case "image":
            return { label: localeStore.t('media["media-control-panel"].tabs.image'), value };
          case "video":
            return { label: localeStore.t('media["media-control-panel"].tabs.video'), value };
          case "video360":
            return { label: localeStore.t('media["media-control-panel"].tabs.video360'), value };
          case "blink":
            return { label: localeStore.t('media["media-control-panel"].tabs.blink'), value };
          default:
            return { value, label };
        }
      });
    };

    const handleMediaTypeChange = async (type: string) => {
      await mediaStore.updateMediaFilters({ page: 1, type: type as MediaFilterTypeKeys }, "load");

      const { limit = 1, page = 1 } = mediaStore.filters;
      const offset = page * limit;
      if (offset < mediaStore.resultsMediaAmount) useInfinityScrollHook.resetTrigger();
    };

    const handleSelect = (list: ExtendedMediaModel) => mediaStore.toggleMediaList(list, "selected");

    return (
      <ExpandableSidebar
        title={localeStore.t('products.modals["no-sku-media-sidebar"].title')}
        icon="cross"
        iconPos={isMobileMedia ? "right" : "outside"}
        sidebarContentClassName={styles.sidebarContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
      >
        <Tabs
          tabs={getMediaTabsLocalized()}
          currentTab={mediaStore.filters.type || "all"}
          onChange={handleMediaTypeChange}
        />
        <div
          className={clsx(styles.listContainer, globalStyles.addScrollStyles)}
          ref={useInfinityScrollHook.ref}
        >
          <div className={styles.listWrapper}>
            {mediaStore.mediaList.map((data) => (
              <MediaItem
                key={data.id}
                item={data}
                onSelect={handleSelect}
                disableActions
                disableSelect
              />
            ))}
          </div>
        </div>
      </ExpandableSidebar>
    );
  },
);

export default NoSKUMediaSidebarProps;
