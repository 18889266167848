import { omit, JSONParse, JSONStringify } from "@gemlightbox/core-kit";

import { config } from "src/config/environment";

export default class WSConnection {
  connection;
  url = "";
  isClosed = false;
  handlers = {};

  constructor(url, token) {
    this.connection = new WebSocket(config.wsURL + url, this.encodeToken(token));
    this.token = token;
    this.connection.onclose = () => {
      console.error("Could not connect to", `${url}`);
      setTimeout(this.handleReconnect, 1000);
    };

    this.connection.onopen = () => console.log(`${url} --- CONNECTED`);

    this.url = url;
  }

  encodeToken = (token) => {
    return token;
  };

  isConnected = () => this.connection.readyState === 1;

  send = (msg) => {
    if (this.isConnected()) return this.connection.send(JSONStringify(msg));
    else return false;
  };

  destroy = () => {
    if (this.connection) {
      this.isClosed = true;
      this.connection.onclose = null;
      this.connection.close();
    }
  };

  off = (type) => {
    this.handlers = omit(this.handlers, type);
  };

  on = (type, callback) => {
    this.handlers[type] = callback;

    if (this.connection.onmessage) return;

    this.connection.onmessage = (evt) => {
      const payload = JSONParse(evt.data);
      if (payload && payload.type && this.handlers[payload.type]) {
        this.handlers[payload.type](payload);
      }
    };
  };

  cloneConnection = (oldConnection, newConnection) => {
    const propertiesToClone = ["onmessage", "onclose", "onopen"];

    propertiesToClone.map((pr) => (newConnection[pr] = oldConnection[pr]));

    return newConnection;
  };

  handleReconnect = (isWithClose = false) => {
    const connection = new WebSocket(config.wsURL + this.url, this.encodeToken(this.token));
    this.isClosed = false;

    if (isWithClose) this.connection.onclose = () => setTimeout(this.handleReconnect, 1000);

    this.connection = this.cloneConnection(this.connection, connection);
  };
}
