import {
  StateFunction,
  negation,
  clamp,
  isFinitePositive,
  isPositive,
} from "@gemlightbox/core-kit";
import { CAMERA_MAX_SCALE, CAMERA_MIN_SCALE, cameraScalesArr } from "./camera-2d.constants";
import { Camera2dScaleType } from "./camera-2d.types";

export const calcCameraScale = (
  value: StateFunction<Camera2dScaleType, number>,
  currentScale: number,
  currentIndex: number,
  indexTo: number,
): [scale: number, toIndex: number] => {
  value = typeof value === "function" ? value(currentScale) : value;

  if (isFinitePositive(value)) return [clamp(value, CAMERA_MIN_SCALE, CAMERA_MAX_SCALE), -1];

  let toIndex = isPositive(indexTo, true) ? indexTo : currentIndex;
  toIndex += negation(1, value === "asc");
  toIndex = clamp(toIndex, 0, cameraScalesArr.length - 1);

  return [cameraScalesArr[toIndex][1], toIndex];
};
