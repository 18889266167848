import React, { memo, useRef } from "react";
import { Checkbox, useDidMount, SvgIcon, clsx, Button } from "@gemlightbox/core-kit";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import styles from "./ai-description-builder-sidebar.module.css";

export type GeneratedDescriptionProps = {
  title: string;
  index: number;
  selectedDescription: number;
  onCheck: (index: number) => void;
  onEdit: (newText: string, index: number) => void;
  className?: string;
};

export const GeneratedDescription: React.FC<GeneratedDescriptionProps> = memo(
  ({
    title,
    index,
    selectedDescription,
    onCheck,
    onEdit,
    className = "",
    ...rest
  }: GeneratedDescriptionProps) => {
    const isChecked = selectedDescription === index;
    const hasSelectedDescription = selectedDescription >= 0;

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useDidMount(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;
      textarea.style.height = "5px";
      textarea.style.height = textarea.scrollHeight + "px";
    });

    const onInput = (element: React.FormEvent<HTMLTextAreaElement>) => {
      element.currentTarget.style.height = "5px";
      element.currentTarget.style.height = element.currentTarget.scrollHeight + "px";
    };

    const onBlur = (e: React.FormEvent<HTMLTextAreaElement>) => {
      e.currentTarget.setAttribute("disabled", "true");
    };

    const handleCheck = (isChecked: boolean) => onCheck(isChecked ? index : -1);

    const handleEnterEdit = () => {
      textareaRef.current?.removeAttribute("disabled");
      textareaRef.current?.setSelectionRange(title.length, title.length);
      textareaRef.current?.focus();
    };

    const handleEdit = (e: React.ChangeEvent<HTMLTextAreaElement>) => onEdit(e.target.value, index);
    const fadeStyle = clsx({ [styles.fade]: hasSelectedDescription && !isChecked });
    const wrapperStyles = clsx(styles.generatedDescriptionWrapper, fadeStyle, className);
    const textAreaStyles = clsx(
      styles.generatedDescription,
      globalStyles.addScrollStyles,
      fadeStyle,
    );

    return (
      <div className={styles.descriptionContainer} {...rest}>
        <Checkbox
          appearance="secondary"
          value={title}
          checked={isChecked}
          onChange={handleCheck}
          disableError
        />
        <div className={wrapperStyles}>
          <textarea
            className={textAreaStyles}
            value={title}
            ref={textareaRef}
            onChange={handleEdit}
            onInput={onInput}
            onBlur={onBlur}
            disabled
          />

          <Button
            data-cy="ai-description-edit-button"
            className={styles.editIconWrapper}
            appearance="secondaryGhost"
            onClick={handleEnterEdit}
          >
            <SvgIcon icon={EditSVG} />
          </Button>
        </div>
      </div>
    );
  },
);
