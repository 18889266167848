import React from "react";
import { FilterCollapse, useDidMount, Select, SelectOption } from "@gemlightbox/core-kit";

import { AttributePrivacy } from "src/containers/products/modals/create-attribute-sidebar";
import { useStores } from "src/hooks";
import { AttributeModel } from "src/models";
import { getAttributeDisplayName } from "src/utils";
import { AttributeTypeLabelMap } from "src/constants";

import styles from "./default-attribute.module.css";
import { observer } from "mobx-react-lite";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export interface DefaultAttributeProps {
  data: AttributeModel;
  onEdit?: (data: AttributeModel) => void;
}

export const DefaultAttribute: React.FC<DefaultAttributeProps> = observer(({ data, onEdit }) => {
  const { productsStore, localeStore } = useStores();

  const isPrice = data.name === "price";
  const spValue = (data.suffix || data.prefix) ?? "";

  useDidMount(() => {
    if (isPrice && productsStore.currencies.length === 0) {
      productsStore.loadCurrencies();
    }
  });

  const handlePrivacyChange = () => {
    onEdit?.({
      ...data,
      isPublic: !data.isPublic,
    });
  };
  // attribute Price has fixed suffix and no prefix ↓
  const handleCurrencyChange = (option?: SelectOption<string>) => {
    onEdit?.({
      ...data,
      suffix: option ? option.value : "",
    });
  };

  return (
    <FilterCollapse
      className={styles.collapse}
      contentClassName={styles.collapseContent}
      title={
        ["title", "price", "producttype", "description", "quantity"].includes(
          getAttributeDisplayName(data).replace(" ", "").toLocaleLowerCase(),
        )
          ? localeStore.t(
              `products["products-list"]["products-table"]["products-table-header"]["${getAttributeDisplayName(
                data,
              )
                .replace(" ", "")
                .toLocaleLowerCase()}"]` as LocaleCodeTypes,
            )
          : getAttributeDisplayName(data) + ""
      }
      subtitleName={localeStore.t(
        'products.modals["manage-attributes-sidebar"]["attributes-list"].subtitle',
      )}
      subtitleValue={AttributeTypeLabelMap[data.type]}
      data-name={`${getAttributeDisplayName(data)}-attribute`}
    >
      <AttributePrivacy isPrivate={!data.isPublic} onChange={handlePrivacyChange} />
      {isPrice && (
        <Select
          appearance="primaryV2"
          label={localeStore.t(
            'products.modals["manage-attributes-sidebar"]["attributes-list"]["default-attribute"]["currency-label"]',
          )}
          initiallySelectedOptions={{ value: spValue, label: spValue }}
          selectedOptionsKeys={spValue ? spValue : null}
          options={productsStore.currenciesOptions}
          onChange={handleCurrencyChange}
          disableClearing
        />
      )}
    </FilterCollapse>
  );
});
