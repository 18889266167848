import { Button, useFetch } from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import { getCloudStorageInfo } from "src/api/cloud-storage";
import { useStores } from "src/hooks";
import Alert from "../Feedback/Alert";

import { ReactComponent as CloudWarnIcon } from "src/common/images/cloud-warn.svg";

import styles from "./styles.module.css";

const CloudStorageFullAlert: React.FC = observer(() => {
  const { localeStore } = useStores();
  const cloudStorageInfoFetch = useFetch(getCloudStorageInfo);
  const navigate = useNavigate();

  return (
    <>
      {cloudStorageInfoFetch.payload?.cloud_storage_info.usage_level === "critical" && (
        <Alert
          className={styles.alert}
          title={localeStore.t('products.alert["cloud-storage-full"].title')}
          subtitle={localeStore.t('products.alert["cloud-storage-full"].subtitle')}
          icon={<CloudWarnIcon />}
          right={
            <Button
              onClick={() => {
                navigate("/settings/subscriptions");
              }}
              appearance="quaternary"
            >
              {localeStore.t("common.cloud.upgrade")}
            </Button>
          }
        />
      )}
    </>
  );
});

export default CloudStorageFullAlert;
