import React from "react";
import { observer } from "mobx-react-lite";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { UseBooleanReturnType } from "@gemlightbox/core-kit";
import { cameraStore } from "../camera.store";
import illustrationResDetection from "src/common/images/illustration-res-detection.svg";
import styles from "./res-detection-modal.module.css";
import { useStores } from "src/hooks";

export type ResDetectionModalProps = {
  resDetectionBool: UseBooleanReturnType;
};

export const ResDetectionModal: React.FC<ResDetectionModalProps> = observer(
  ({ resDetectionBool }) => {
    const getRecommendedResolution = () => {
      const recommendedResolutions = cameraStore.recommendedResolutions;
      return `${recommendedResolutions[0]}x${recommendedResolutions[1]}`;
    };

    const { localeStore } = useStores();

    const handleRecheckClick = () => {
      resDetectionBool.setFalsy();
      cameraStore.recheckResolution();
    };

    return (
      <Dialog
        open={resDetectionBool.value}
        className={styles.resDetectionModal}
        classes={{
          paper: styles.resDetectionModalPaper,
        }}
      >
        <DialogContent className={styles.resDetectionModalDialogContent}>
          <img src={illustrationResDetection} />
          <div className={styles.describe}>
            <div>{localeStore.t('camera["res-detection-modal"].remind')}</div>
            <div>
              {localeStore.t('camera["res-detection-modal"].suggestion')}
              {getRecommendedResolution()}
            </div>
          </div>
          <div className={styles.btns}>
            <div className={styles.btnRecheck} onClick={handleRecheckClick}>
              {localeStore.t('camera["res-detection-modal"].recheck')}
            </div>
            <div className={styles.btnConfirm} onClick={resDetectionBool.setFalsy}>
              {localeStore.t('camera["res-detection-modal"].ok')}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);
