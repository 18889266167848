import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  TableCell,
  TableRow,
  LastCell,
  Button,
  SvgIcon,
  Typography,
  globalStyles,
  Image,
  arraysIntersection,
} from "@gemlightbox/core-kit";

import { ProductModel } from "src/models";
import { useStores } from "src/hooks";
import { getMediaSrc } from "src/utils";
import { productVariantsStore } from "../../product-variants.store";
import { VariantAttribute } from "./variant-attribute";
import { variantSupportedAttributes } from "./variant-table-row.constants";

import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-thick-grey.svg";
import { ReactComponent as PencilSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./variant-table-row.module.css";

export type VariantTableRowProps = {
  variant: ProductModel;
};

export const VariantTableRow: React.FC<VariantTableRowProps> = observer(
  ({ variant }: VariantTableRowProps) => {
    const { productId } = useParams<{ productId: string }>();

    const { modalsStore, productsStore, attributesStore } = useStores();

    const attributes = useMemo(() => {
      if (!attributesStore.attributes.length) return [];

      return arraysIntersection(
        variantSupportedAttributes,
        attributesStore.attributes,
        (el1, el2) => {
          if (el1.name !== el2.name) return;
          return { ...el2, className: el1.className, placeholder: el1.placeholder };
        },
      );
    }, [attributesStore.attributes]);

    const src = getMediaSrc(variant.images[0], "small");

    const handleAddMediaClick = () => {
      modalsStore.open("ProductAssignMediasSidebar", {
        productImages: variant.images,
        onlyOneMedia: true,
        onSubmit: async (medias) => {
          const mediasIds = medias.map(({ id }) => id);
          const res = await productsStore.updateProduct(variant._id, {
            mediaIDS: mediasIds,
          });

          if (res?.status === "success") {
            productVariantsStore.getProductVariants(Number(productId));
          }
        },
      });
    };

    const handleDeleteClick = () => productVariantsStore.deleteProductVariant(variant._id);

    return (
      <TableRow className={styles.variantTableRowContainer}>
        <TableCell className={styles.imageCellContainer}>
          {!src && (
            <Button appearance="primaryOutlined" onClick={handleAddMediaClick}>
              <SvgIcon icon={PlusSVG} />
            </Button>
          )}
          {src && (
            <div className={styles.variantImagePreview}>
              <Image src={src} />
              <Button appearance="secondaryOutlined" onClick={handleAddMediaClick} size="small">
                <SvgIcon icon={PencilSVG} />
              </Button>
            </div>
          )}
        </TableCell>
        <TableCell className={styles.variantCellContainer}>
          <Typography
            className={globalStyles.applyMultiOverflow}
            size="extraSmall"
            color="textSecondary"
          >
            {variant.optionsTitle}
          </Typography>
        </TableCell>
        {attributes.map((attribute) => (
          <TableCell key={attribute.name} className={attribute.className}>
            <VariantAttribute
              placeholder={attribute.placeholder}
              variant={variant}
              attribute={attribute}
            />
          </TableCell>
        ))}
        <LastCell className={styles.lastCellContainer}>
          <Button
            appearance="tertiaryOutlined"
            size="small"
            onClick={handleDeleteClick}
            disabled={productVariantsStore.deletingVariant}
          >
            <SvgIcon icon={TrashSVG} />
          </Button>
        </LastCell>
      </TableRow>
    );
  },
);

export default VariantTableRow;
