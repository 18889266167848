import { makeAutoObservable } from "mobx";
import {
  CameraExposureTimeRes,
  CameraFocusValueRes,
  DriveInfo,
  EnvironmentInfoRes,
  GetCameraAuthStatusRes,
  NativeAPIParamType,
  NativeAPIResType,
  USBDeviceConnectRes,
} from "./app-callback.store.types";
import { DeviceModel } from "src/models";

type CallbackOptionsType<T> = {
  success?: (ret: T) => void;
  fail?: (msg: string, ret?: T) => void;
};

export class AppCallbackStore {
  constructor() {
    makeAutoObservable(this);

    if (!window.$appCallback) window.$appCallback = {};

    window.$appCallback.getLocalizedLanguage = () => {
      const language = localStorage.getItem("localeCode");
      return language || "en";
    };

    window.$appCallback._stopDropPropagation = (event) => {
      event.stopPropagation();
    };

    window.$appCallback.disableDocumentEvents = () => {
      ["drop", "dragstart", "dragenter", "dragend", "dragover", "dragleave"].forEach(
        (eventType) => {
          // @ts-ignore
          window.addEventListener(eventType, window.$appCallback._stopDropPropagation, true);
        },
      );
      document.body.style.pointerEvents = "none";
    };

    window.$appCallback.enableDocumentEvents = () => {
      ["drop", "dragstart", "dragenter", "dragend", "dragover", "dragleave"].forEach(
        (eventType) => {
          // @ts-ignore
          window.removeEventListener(eventType, window.$appCallback._stopDropPropagation, true);
        },
      );
      document.body.style.pointerEvents = "initial";
    };
  }

  private _initCallback<T>(
    functionName: string,
    param?: NativeAPIParamType,
    options?: CallbackOptionsType<T>,
  ) {
    if (!functionName) throw "functionName must be pass";
    const fname = `${functionName}CB`;
    window.$appCallback[fname] = (res: NativeAPIResType<T>) => {
      // eslint-disable-next-line no-console
      console.info("--action--", functionName, "--payload--", param, "--response--", res);
      if (res.code === 1) {
        typeof options?.success === "function" && options.success(res.data as T);
      } else {
        typeof options?.fail === "function" &&
          options.fail(`${functionName}: ${res.message}`, res?.data as T);
      }
    };

    try {
      const dataStr = JSON.stringify({
        param,
        functionName,
        fn: fname,
      });

      window.$platform.native?.postMessage!(dataStr);
    } catch (error: any) {
      typeof options?.fail === "function" && options.fail(error.message);
      console.warn(error.message);
    }
  }

  /**
   * 相机相关接口
   *
   */

  public getCameraAuthStatus(
    success?: (res: GetCameraAuthStatusRes) => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "getCameraAuthStatus";
    return this._initCallback<GetCameraAuthStatusRes>(functionName, {}, { success, fail });
  }

  public setCameraFocusMode(
    isOpenAutoFocus: boolean,
    cameraName = "",
    success?: () => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "setCameraFocusMode";
    return this._initCallback(functionName, { cameraName, isOpenAutoFocus }, { success, fail });
  }

  public getCameraFocusValue(cameraName = "", success?: (res: CameraFocusValueRes) => void) {
    const functionName = "getCameraFocusValue";
    return this._initCallback(functionName, { cameraName }, { success });
  }

  public setCameraFoucusValue(
    focusValue: number,
    cameraName = "",
    success?: () => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "setCameraFoucusValue";
    return this._initCallback(functionName, { cameraName, focusValue }, { success, fail });
  }

  public setCameraExposureMode(cameraName: string, isOpenAutoExposure: boolean) {
    const functionName = "setCameraExposureMode";
    return this._initCallback(functionName, { cameraName, isOpenAutoExposure });
  }

  public getCameraExposureTime(cameraName = "", success?: (res: CameraExposureTimeRes) => void) {
    const functionName = "getCameraExposureTime";
    return this._initCallback(functionName, { cameraName }, { success });
  }

  public setCameraExposureTime(exposureTime: number, cameraName = "", cameraUniqueID = "") {
    const functionName = "setCameraExposureTime";
    return this._initCallback(functionName, { cameraName, cameraUniqueID, exposureTime });
  }

  public resetCameraExposure(cameraName = "", cameraUniqueID = "") {
    const functionName = "resetCameraExposure";
    return this._initCallback(functionName, { cameraName, cameraUniqueID });
  }

  public resetCameraSetting(cameraName = "", success?: () => void, fail?: () => void) {
    const functionName = "resetCameraSetting";
    return this._initCallback(functionName, { cameraName }, { success, fail });
  }

  public openSystemCameraAuthSetting() {
    const functionName = "openSystemCameraAuthSetting";
    return this._initCallback(functionName);
  }

  public cameraDeviceDidConnected(
    success?: (res: USBDeviceConnectRes) => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "cameraDeviceDidConnected";
    return this._initCallback<USBDeviceConnectRes>(functionName, {}, { success, fail });
  }

  public getCameraDeviceList(
    success?: (res: USBDeviceConnectRes[]) => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "getCameraDeviceList";
    return this._initCallback<USBDeviceConnectRes[]>(functionName, {}, { success, fail });
  }

  /**
   * 蓝牙相关接口
   *
   */

  public getBuletoothDeviceList(
    userID: number,
    autoConnect: boolean,
    success?: (res: DeviceModel[]) => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "getBuletoothDeviceList";
    return this._initCallback<DeviceModel[]>(
      functionName,
      { autoConnect, userID, type: 1 },
      { success, fail },
    );
  }

  public stopScanBuletoothDevice() {
    const functionName = "stopScanBuletoothDevice";
    return this._initCallback(functionName, { type: 1 });
  }

  public getBuletoothAuthStatus(success?: (res: number) => void, fail?: (msg: string) => void) {
    const functionName = "getBuletoothAuthStatus";
    return this._initCallback<number>(functionName, {}, { success, fail });
  }

  public connectBuletoothDevice(
    identifier: string,
    success?: (identifier: string) => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "connectBuletoothDevice";
    return this._initCallback(
      functionName,
      { identifier, needDisconnectLast: true },
      { success, fail },
    );
  }

  public disconnectBluetoothDevice(identifier: string) {
    const functionName = "disconnectBluetoothDevice";
    return this._initCallback(functionName, { identifier });
  }

  public gemLightBoxStartTurning(identifier: string, direction: number) {
    const functionName = "gemLightBoxStartTurning";
    return this._initCallback(functionName, { direction, identifier });
  }

  public gemLightBoxStartRotating(
    identifier: string,
    angle: number[],
    speed: number,
    success?: () => void,
  ) {
    const functionName = "gemLightBoxStartRotating";
    return this._initCallback(functionName, { angle, identifier, speed }, { success });
  }

  public openSystemBluetoothAuthSetting() {
    const functionName = "openSystemBluetoothAuthSetting";
    return this._initCallback(functionName);
  }

  public openSystemBluetoothSetting() {
    const functionName = "openSystemBluetoothSetting";
    return this._initCallback(functionName);
  }

  /**
   * 通知相关
   *
   */
  public webviewNotification(param: {
    type?: number;
    needReloadMain?: boolean;
    info?: string;
    extra?: string;
  }) {
    const functionName = "webviewNotification";
    return this._initCallback(functionName, { ...param });
  }

  public getLifeCycleStatus(
    success?: (res: { status: number }) => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "getLifeCycleStatus";
    return this._initCallback(functionName, {}, { success, fail });
  }

  /**
   * 其他
   *
   */
  public getEnvironmentInfo(
    success?: (res: EnvironmentInfoRes) => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "getEnvironmentInfo";
    return this._initCallback<EnvironmentInfoRes>(functionName, {}, { success, fail });
  }

  public setLocalizedLanguage(language: string) {
    const functionName = "setLocalizedLanguage";
    const param = { language };
    return this._initCallback(functionName, param);
  }

  public videoScreenCapture(
    data: string,
    success?: (res: EnvironmentInfoRes) => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "videoScreenCapture";
    return this._initCallback<EnvironmentInfoRes>(functionName, { data }, { success, fail });
  }

  /**
   * Local resource management
   *
   */
  public selectFolder(
    success?: (res: DriveInfo) => void,
    fail?: (msg: string, res?: DriveInfo) => void,
  ) {
    const functionName = "selectFolder";
    return this._initCallback<DriveInfo>(functionName, {}, { success, fail });
  }

  public getDriveInfo(
    folderPath: string,
    success?: (res: DriveInfo) => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "getDriveInfo";
    return this._initCallback<DriveInfo>(functionName, { folderPath }, { success, fail });
  }

  public saveLocalStorage(
    folderPath: string,
    data: string,
    success?: () => void,
    fail?: (msg: string) => void,
  ) {
    const functionName = "saveLocalStorage";
    return this._initCallback(functionName, { folderPath, data }, { success, fail });
  }
}
