import { makeAutoObservable, runInAction } from "mobx";
import { ApiRequest, ExtractApiResponse, Nullable } from "@gemlightbox/core-kit";

import { pushDataLayerEvent } from "src/utils";
import {
  deleteDeactivateInvitationLink,
  deleteSubaccount,
  getSubaccountsList,
  postCreateInvitationLink,
  getPendingEvictSubaccountJob,
} from "src/api";
import { DataStrategy, SubaccountModel } from "src/models";
import {
  AccessTypes,
  SubaccountRoles,
  SubaccountStatuses,
} from "src/containers/settings/subaccounts";

export class SubaccountsStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _loading = false;
  private _subaccountList: SubaccountModel[] = [];
  private _prevRequest: Nullable<ApiRequest<ExtractApiResponse<typeof getSubaccountsList>>> = null;
  private _showErrorTip = false;
  private _errorTipMessage = "";
  private _evictSubaccountJob: any = null;

  public get loading() {
    return this._loading;
  }

  public get subaccountList() {
    return this._subaccountList.filter((subaccount) => subaccount.role !== SubaccountRoles.master);
  }

  public get subaccountListWithMaster() {
    return this._subaccountList;
  }

  public get subaccountMaster() {
    return this.subaccountListWithMaster.find((item) => item.role === SubaccountRoles.master);
  }

  public get confirmedSubaccounts() {
    return this.subaccountList.filter(
      (subaccount) =>
        subaccount.status === SubaccountStatuses.confirmed &&
        subaccount.role !== SubaccountRoles.master,
    );
  }

  public get pendingSubaccounts() {
    return this.subaccountList.filter(
      (subaccount) => subaccount.status === SubaccountStatuses.pending,
    );
  }

  /* Requests ↓ */
  public async loadSubaccounts() {
    if (this.loading && this._prevRequest) this._prevRequest.abort();

    const request = await getSubaccountsList.getRequest();
    this._prevRequest = request;
    const { success, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      if (success) this._subaccountList = success;
      else this._subaccountList = [];

      this._loading = false;
    });
  }

  public async deleteSubaccount(subaccount: SubaccountModel, dataStrategy: DataStrategy) {
    this._loading = true;
    const { success } = await deleteSubaccount
      .getRequest({
        params: { userId: subaccount.user_id },
        queryParams: { data_strategy: dataStrategy },
      })
      .fetch();

    runInAction(() => {
      if (success) this._subaccountList = success;
      this._loading = false;
    });
  }

  public async createInvitationLink(email: string, access: AccessTypes) {
    const { success, error } = await postCreateInvitationLink
      .getRequest({ params: { email }, queryParams: { access } })
      .fetch();

    runInAction(() => {
      if (success) {
        this._subaccountList = success;
        pushDataLayerEvent({ event: "subaccount_invitation" });
      }
    });

    return error;
  }

  public async deactivateInvitationLink(email: string) {
    const data = await deleteDeactivateInvitationLink.getRequest({ params: { email } }).fetch();
    if (data.error) {
      return {
        errorCode: data.error?.originalError?.code || null,
      };
    }
    runInAction(() => {
      if (data.success) this._subaccountList = data.success;
    });
  }

  public get showErrorTip() {
    return this._showErrorTip;
  }

  public get errorTipMessage() {
    return this._errorTipMessage;
  }

  public set showErrorTip(value: boolean) {
    this._showErrorTip = value;
  }

  public set errorTipMessage(value: string) {
    this._errorTipMessage = value;
  }

  public set evictSubaccountJob(value: any) {
    this._evictSubaccountJob = value;
  }

  public get evictSubaccountJob() {
    return this._evictSubaccountJob;
  }

  public async getPendingEvictSubaccountJob() {
    const { success } = await getPendingEvictSubaccountJob.getRequest().fetch();
    runInAction(() => {
      if (success) {
        this.evictSubaccountJob = success;
      } else {
        this.evictSubaccountJob = null;
      }
    });
  }

  /* Requests ↑ */
}
