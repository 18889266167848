import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import "./index.scss";

const IconCircle = ({ img, icon, variant = "success" }) => (
  <div className={clsx("icon-circle-wrapper", { [`${variant}`]: variant })}>
    <div className="icon-circle">
      {img && <img src={img} alt="" />}
      {icon && icon}
    </div>
  </div>
);

export default IconCircle;
