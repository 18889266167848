import React from "react";
import { observer } from "mobx-react-lite";
import { Info } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./mandatory-tooltip.module.css";

export const MandatoryTooltip: React.FC = observer(() => {
  const { localeStore } = useStores();

  return (
    <Info
      toolTipClassName={styles.content}
      appearance="secondary"
      position="bottom"
      size={16}
      withAngle
    >
      {localeStore.t('["products-import"]["attributes-panel"]["mandatory-tooltip"]')}
    </Info>
  );
});
