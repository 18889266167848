import { ReactComponent as GemLightBoxProSVG } from "src/external-ts/assets/images/camera/gemlightbox-pro-icon.svg";
import { ReactComponent as GemLightBoxSVG } from "src/external-ts/assets/images/camera/gemlightbox-icon.svg";
import { ReactComponent as MacroSVG } from "src/external-ts/assets/images/camera/macro-icon.svg";
import { ReactComponent as GamCamOnlySVG } from "src/external-ts/assets/images/camera/gemcam-icon.svg";
import { ReactComponent as GemStackSVG } from "src/external-ts/assets/images/camera/gemstack-icon.svg";
import { DeviceKind } from "src/models";

export const BLUETOOTH_DEVICE_IDENTIFIER = "bluetoothDeviceIdentifier";
export const BLUETOOTH_CONNECTION_TIMEDOUT = 6;
export const DEVICE_SELECTED_STORAGE = "deviceSelectedStorage";

export const devicekindMap: DeviceKind[] = [
  {
    label: "GemLightbox",
    src: GemLightBoxSVG,
    keywords: ["Turntable", "GemLightbox", "Aerial"],
    value: 0,
  },
  {
    label: "GemLightbox Pro",
    src: GemLightBoxProSVG,
    keywords: ["GLB PRO"],
    value: 1,
  },
  {
    label: "Macro",
    src: MacroSVG,
    keywords: ["Macro"],
    value: 3,
  },
  {
    label: "GemStack",
    src: GemStackSVG,
    keywords: ["GemStack"],
    value: 4,
  },
  {
    label: "Camera Only",
    src: GamCamOnlySVG,
    keywords: [],
    value: 5,
  },
];
