import React from "react";
import {
  clsx,
  CollapseSelfControlled,
  CollapseTriggerProps,
  Button,
  SvgIcon,
  memo,
  Checkbox,
  CircleLoader,
} from "@gemlightbox/core-kit";

import { ReactComponent as AngleDownSVG } from "src/external-ts/assets/images/arrow-down-grey.svg";
import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-rounded-grey.svg";
import styles from "./expandable-step.module.css";

export interface ExpandableStepProps {
  title: string;
  checkboxLabel?: string;
  loadingLabel?: string;
  errorLabel?: string;
  loading?: boolean;
  error?: boolean;
  checked?: boolean;
  initialOpen?: boolean;
  isOpen?: boolean;
  onChange?: VoidFunction;
  triggerContainerClassName?: string;
  contentClassName?: string;
}

export const ExpandableStep: React.FC<ExpandableStepProps> = memo(
  ({
    children,
    title,
    checkboxLabel,
    loading,
    error,
    loadingLabel,
    errorLabel,
    checked,
    initialOpen,
    isOpen,
    onChange,
    triggerContainerClassName,
    contentClassName,
  }) => {
    const renderTrigger = ({ isOpened }: CollapseTriggerProps) => (
      <div
        className={clsx(styles.triggerContainer, triggerContainerClassName, {
          [styles.active]: isOpened,
        })}
      >
        <div className={styles.title}> {title} </div>

        <div className={clsx(styles.content, contentClassName)}>
          {checkboxLabel && onChange && typeof checked === "boolean" && (
            <Checkbox
              containerClassName={styles.checkboxContainer}
              label={checkboxLabel}
              checked={checked}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()}
              disableError
            />
          )}

          {!error && loading && (
            <div className={styles.loader}>
              <CircleLoader size="small" />
              {loadingLabel && <span> {loadingLabel} </span>}
            </div>
          )}

          {error && (
            <div className={styles.error}>
              <div className={styles.errorCircle}>
                <SvgIcon icon={CrossSVG} size={8} />
              </div>
              <div className={styles.label}> {errorLabel} </div>
            </div>
          )}

          <Button appearance="secondaryOutlined">
            <SvgIcon className={styles.icon} icon={AngleDownSVG} />
          </Button>
        </div>
      </div>
    );

    return (
      <CollapseSelfControlled
        className={styles.container}
        triggerElement={renderTrigger}
        openDuration={150}
        hideDuration={150}
        initiallyOpened={initialOpen}
        triggerOpening={isOpen}
      >
        {children}
      </CollapseSelfControlled>
    );
  },
);
