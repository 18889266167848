import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Button,
  Search,
  useFilters,
  useFetch,
  useCall,
  useWindowSize,
  ExpandableSidebar,
  Nullable,
  useBoolean,
  NotificationTextSecondary,
} from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import { ModalExtendedType } from "src/store/modals/modals.store.types";
import {
  getCatalogs,
  getUserTagManagerInfoCallback,
  postAssignProductsToCatalog,
  postCreateCatalog,
  putReassignProducts,
} from "src/api";
import { useStores } from "src/hooks";
import { pushDataLayerEvent } from "src/utils";
import { ErrorCodes } from "src/constants";
import { CatalogModel, ProductModel } from "src/models";
import { CatalogItem } from "./components/catalog-item";
import { CreateCatalogModal } from "./components/create-catalog-modal";
import { initialCatalogFilters } from "./add-to-catalog-sidebar.constants";

import styles from "./add-to-catalog-sidebar.module.css";

export type AddToCatalogSidebarProps = ModalExtendedType<{
  type: "assign" | "reassign";
  title: string;
  products: ProductModel[];
  confirmText?: string;
  onSubmit?: VoidFunction;
}>;

export const AddToCatalogSidebar: React.FC<AddToCatalogSidebarProps> = observer(
  ({ isOpen, options, setClose, onFinalClosed }: AddToCatalogSidebarProps) => {
    const navigate = useNavigate();
    const { windowWidth } = useWindowSize();
    const { type, products, title, confirmText, onSubmit } = options;

    const { catalogStore, localeStore, notificationStore } = useStores();

    const [selectedCatalog, setSelectedCatalog] = useState<Nullable<CatalogModel>>(null);
    const createCatalogModalBoolean = useBoolean(false);

    const { queryString, searchValue, setSearch } = useFilters(initialCatalogFilters);
    const { payload, actions } = useFetch(getCatalogs.setQueryParams(queryString), {
      dependencies: [queryString],
    });

    const { rows = [] } = payload || {};

    const assignCall = useCall(postAssignProductsToCatalog);
    const reassignCall = useCall(putReassignProducts);

    const useCreateCatalogCall = useCall(postCreateCatalog);
    useCreateCatalogCall.onCallSuccess((res) => {
      getUserTagManagerInfoCallback((response) => {
        pushDataLayerEvent({
          event: "create_catalog",
          user_id: response.user_id,
          total: res.total_items,
          account_type: response.account_type,
          is_trial: response.isTrial,
        });
      });

      actions.setData(res);
      createCatalogModalBoolean.setFalsy();
    });
    useCreateCatalogCall.onCallError(({ originalError }) => {
      if (originalError?.code === ErrorCodes.CATALOGS_LIMIT_EXCEEDED) {
        notificationStore.open({
          title: localeStore.t('catalog["add-to-catalog-sidebar"].notification["limit-exceeded"]'),
          message: originalError.message,
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: "",
          onlyConfirm: true,
        });
      }
    });

    const loading = useCreateCatalogCall.submitting;

    const handleCreate = async (data: any) => {
      useCreateCatalogCall.submit({ data });
    };

    assignCall.onCallSuccess(({ assigned, unassigned }) => {
      setClose();
      onSubmit?.();
      handleDisplayNotification(assigned, unassigned);
    });

    reassignCall.onCallSuccess(({ assigned }) => {
      setClose();
      onSubmit?.();
      handleDisplayNotification(assigned);
    });

    const handleConfirm = async () => {
      if (!selectedCatalog) return;

      const ids = products.map(({ _id }) => _id).toString();

      if (type === "assign") {
        assignCall.submit({
          params: { catalogId: selectedCatalog.id },
          queryParams: ids,
        });
      }

      if (type === "reassign") {
        if (!catalogStore.catalog) return;

        reassignCall.submit({
          params: { fromCatalogId: catalogStore.catalog.id, toCatalogId: selectedCatalog.id },
          queryParams: ids,
        });
      }
    };

    const handleDisplayNotification = (assigned: number[], unassigned?: number[]) => {
      const assignedLength = assigned.length;
      const unassignedLength = unassigned?.length ?? 0;

      notificationStore.open({
        title: assignedLength
          ? localeStore.t('catalog["add-to-catalog-sidebar"].notification.success')
          : localeStore.t('catalog["add-to-catalog-sidebar"].notification.warning'),
        message: (
          <>
            {!assignedLength && !unassignedLength && (
              <NotificationTextSecondary>
                {localeStore.t(
                  'catalog["add-to-catalog-sidebar"].notification["all-already-assigned-text"]',
                )}{" "}
                <NotificationTextSecondary tag="span" isStrong>
                  {selectedCatalog?.name}
                </NotificationTextSecondary>
              </NotificationTextSecondary>
            )}
            {!!assignedLength && (
              <NotificationTextSecondary>
                {assignedLength}{" "}
                {localeStore.t('catalog["add-to-catalog-sidebar"].notification["success-text"]')}{" "}
                <NotificationTextSecondary tag="span" isStrong>
                  {selectedCatalog?.name}
                </NotificationTextSecondary>
              </NotificationTextSecondary>
            )}
            {!!unassignedLength && (
              <NotificationTextSecondary>
                {unassignedLength}{" "}
                {localeStore.t(
                  'catalog["add-to-catalog-sidebar"].notification["already-assigned-text"]',
                )}{" "}
                <NotificationTextSecondary tag="span" isStrong>
                  {selectedCatalog?.name}
                </NotificationTextSecondary>
              </NotificationTextSecondary>
            )}
          </>
        ),
        confirmText: localeStore.t('catalog["add-to-catalog-sidebar"].notification["ok-text"]'),
        cancelText: "",
        confirmAppearance: "primary",
        onlyConfirm: true,
        onOk: () => {
          if (selectedCatalog?.id) {
            navigate(`/catalog/${selectedCatalog.id}`);
          }
        },
      });
    };

    const handleCreateCatalog = () => createCatalogModalBoolean.setTruthy();

    const selectedId = selectedCatalog?.id;

    const isMobile = windowWidth < BREAKPOINTS.mobileL;

    return (
      <ExpandableSidebar
        title={title}
        header={
          !isMobile && (
            <Button className={styles.newCatalogButton} onClick={handleCreateCatalog}>
              {localeStore.t('catalog["add-to-catalog-sidebar"].buttons.create')}
            </Button>
          )
        }
        icon="cross"
        iconPos={isMobile ? "right" : "outside"}
        contentClassName={styles.drawer}
        sidebarHeaderClassName={styles.header}
        sidebarContentClassName={styles.content}
        sidebarFooterClassName={styles.footer}
        footer={
          <>
            <Button appearance="tertiaryOutlined" onClick={setClose}>
              {localeStore.t('catalog["add-to-catalog-sidebar"].buttons.clear')}
            </Button>
            <Button onClick={handleConfirm} disabled={!selectedId}>
              {confirmText ?? localeStore.t('catalog["add-to-catalog-sidebar"].buttons.confirm')}
            </Button>
          </>
        }
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        isOpen={isOpen}
      >
        <Search
          className={styles.search}
          placeholder={localeStore.t("components.business.search.placeholder")}
          searchValue={searchValue}
          setSearch={setSearch}
        />
        <div className={styles.itemsContainer}>
          {rows
            .filter(({ is_all }) => !is_all)
            .map((catalog, index) => (
              <CatalogItem
                key={index}
                catalog={catalog}
                checked={catalog.id === selectedId}
                onChange={setSelectedCatalog}
              />
            ))}
        </div>
        <CreateCatalogModal
          isOpen={createCatalogModalBoolean.value}
          setClose={createCatalogModalBoolean.setFalsy}
          handleCreate={handleCreate}
          loading={loading}
        />
      </ExpandableSidebar>
    );
  },
);
