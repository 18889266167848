import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { Button, clsx, SvgIcon, memo, Typography, globalStyles } from "@gemlightbox/core-kit";

import { ReactComponent as DragSVG } from "src/external-ts/assets/images/drag-grey.svg";
import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-grey.svg";

import styles from "./draggable-value-item.module.css";

type DraggableValueItemProps = {
  value: string;
  isDragged: boolean;
  provided: DraggableProvided;
  onDelete: (value: string) => void;
};

export const DraggableValueItem: React.FC<DraggableValueItemProps> = memo(
  ({ value, isDragged, provided, onDelete }) => {
    return (
      <div
        data-name={value}
        key={value}
        className={clsx(styles.valueItem, {
          [styles.dragged]: isDragged,
        })}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <SvgIcon icon={DragSVG} className={clsx(styles.dragIcon)} />
        <Typography
          className={globalStyles.applySingleOverflow}
          size="small600"
          color="textSecondary"
        >
          {value}
        </Typography>

        <Button
          className={styles.valueButton}
          appearance="secondaryOutlined"
          onClick={() => onDelete(value)}
          data-cy="delete-value"
        >
          <SvgIcon icon={CrossSVG} />
        </Button>
      </div>
    );
  },
);
