import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Heading, Typography } from "@gemlightbox/core-kit";

import { LOGIN_PAGE } from "src/constants";
import { useStores } from "src/hooks";

import styles from "./expired.module.css";

export const ExpiredPage: React.FC = observer(() => {
  const navigate = useNavigate();

  const { localeStore } = useStores();

  const handleGoToLogin = () => navigate(LOGIN_PAGE.path);

  return (
    <div className={styles.filler}>
      <div className={styles.expiredPageContainer}>
        <div className={styles.expiredPageWrapper}>
          <Heading className={styles.title} tag="h2" color="textSecondary">
            {localeStore.t("auth.reset.expired.title")}
          </Heading>

          <Typography className={styles.message} size="medium" color="textTertiary">
            {localeStore.t("auth.reset.expired.subtitle")}
          </Typography>

          <Button onClick={handleGoToLogin}>
            {localeStore.t('auth.reset.expired["got-it-button"]')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default ExpiredPage;
