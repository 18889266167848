import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { FormikProps } from "formik";
import {
  ApiRequest,
  Button,
  EmptyObjectType,
  Form,
  FormField,
  Nullable,
  Typography,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { generatePost, GeneratePostResponseData } from "src/api";

import styles from "./manual-post-form.module.css";

type GeneratePostForm = {
  title: string;
  occasion: string;
  promotion: string;
};

type ManualPostFormProps = {
  onRequestStart: (
    request: Nullable<ApiRequest<GeneratePostResponseData, undefined, EmptyObjectType>>,
  ) => void;
  onRequestEnd: (post: string) => void;
  setClose: VoidFunction;
};

export const ManualPostForm: React.FC<ManualPostFormProps> = observer(
  ({ setClose, onRequestStart, onRequestEnd }) => {
    const { localeStore } = useStores();

    const initialValues: GeneratePostForm = { title: "", occasion: "", promotion: "" };
    const [formInner, setFormInner] = useState<FormikProps<GeneratePostForm> | null>(null);

    const handleSubmit = async () => {
      const formData = new FormData();

      formData.append("file", "");
      formData.append("media_id", "");
      formData.append("title", formInner!.values.title);
      formData.append("occasion", formInner!.values.occasion);
      formData.append("promotion", formInner!.values.promotion);
      const request = generatePost.getRequest({ data: formData });
      onRequestStart(request);
      const requestResult = await request.fetch();

      if (requestResult.success) {
        onRequestEnd(requestResult.success.gpt_post_1);
      }
    };

    return (
      <div>
        <Form
          initialValues={initialValues}
          innerRef={setFormInner}
          onSubmit={handleSubmit}
          className={styles.manualPostForm}
        >
          <Typography size="extraSmall" color="textTertiary" className={styles.article}>
            {localeStore.t(
              'components.business["share-modal"]["generate-post-content"]["manual-post-form"].article',
            )}
          </Typography>
          <div className={styles.formFields}>
            <FormField
              required
              type="text"
              name="title"
              appearance="primaryV2"
              label={localeStore.t(
                'components.business["share-modal"]["generate-post-content"]["manual-post-form"]["form-fields"].labels["product-name"]',
              )}
              placeholder={localeStore.t(
                'components.business["share-modal"]["generate-post-content"]["manual-post-form"]["form-fields"].placeholders["product-name"]',
              )}
            />
            <FormField
              type="text"
              name="occasion"
              appearance="primaryV2"
              label={localeStore.t(
                'components.business["share-modal"]["generate-post-content"]["manual-post-form"]["form-fields"].labels.occasion',
              )}
              placeholder={localeStore.t(
                'components.business["share-modal"]["generate-post-content"]["manual-post-form"]["form-fields"].placeholders.occasion',
              )}
            />
            <FormField
              type="text"
              name="promotion"
              appearance="primaryV2"
              label={localeStore.t(
                'components.business["share-modal"]["generate-post-content"]["manual-post-form"]["form-fields"].labels.promotion',
              )}
              placeholder={localeStore.t(
                'components.business["share-modal"]["generate-post-content"]["manual-post-form"]["form-fields"].placeholders.occasion',
              )}
            />
          </div>
          <div className={styles.buttons}>
            <Button appearance="secondaryOutlined" onClick={setClose}>
              {localeStore.t("common.buttons.cancel")}
            </Button>
            <Button appearance="primary" type="submit" disabled={!formInner?.values.title}>
              {localeStore.t(
                'components.business["share-modal"]["generate-post-content"]["manual-post-form"].buttons["generate-post"]',
              )}
            </Button>
          </div>
        </Form>
      </div>
    );
  },
);
