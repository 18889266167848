import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Search,
  SelectOptionValue,
  Tabs,
  ActionsBtn,
  useWindowSize,
  ActionOption,
  Typography,
} from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import { CatalogFilterByType } from "src/store";
import { UserSelect, ViewTypeKeys } from "src/external-ts/components";
import { ViewVariant } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { useCatalogLocalization } from "../catalog.utils";

import styles from "./catalog-control-panel.module.css";

export interface CatalogControlPanelProps {
  view: ViewTypeKeys;
  onViewChange: (value: ViewTypeKeys) => void;
}

export const CatalogControlPanel: React.FC<CatalogControlPanelProps> = observer(
  ({ view, onViewChange }: CatalogControlPanelProps) => {
    const navigate = useNavigate();

    const { windowWidth } = useWindowSize();

    const { catalogsStore, localeStore } = useStores();

    const { catalogTabsLocalized } = useCatalogLocalization();

    const handleCreateCatalog = (catalogType: "manual" | "auto") => {
      return () => navigate(`/catalog/create/${catalogType}`);
    };

    const handleSearchChange = (name: string) => {
      catalogsStore.loadCatalogList({ ...catalogsStore.filters, name, page: 1 });
    };

    const handleUserChange = (user_id: SelectOptionValue) => {
      catalogsStore.loadCatalogList({ ...catalogsStore.filters, user_id });
    };

    const handleTabChange = (type: string) => {
      catalogsStore.loadCatalogList({
        ...catalogsStore.filters,
        page: 1,
        filterBy: type as CatalogFilterByType,
      });
    };

    const createButtonText =
      windowWidth < BREAKPOINTS.tablet
        ? ""
        : localeStore.t('catalog["catalog-control-panel"].buttons.create.text');

    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <Search
            className={styles.search}
            placeholder={localeStore.t("components.business.search.placeholder")}
            searchValue={catalogsStore.filters.name}
            setSearch={handleSearchChange}
            data-cy="catalogs-search"
          />

          <div className={styles.right}>
            <ViewVariant type={view} onChange={onViewChange} />
            <div className={styles.filtersAndActions} data-cy="create-catalog-filters-and-actions">
              <UserSelect
                className={styles.userSelect}
                appearance="primaryV2"
                value={catalogsStore.filters.user_id}
                onChange={handleUserChange}
              />
              <ActionsBtn
                actionBtnClassName={styles.createButton}
                text={createButtonText}
                data-cy="create-catalog-btn"
              >
                <ActionOption
                  className={styles.createAction}
                  onClick={handleCreateCatalog("manual")}
                  data-cy="create-manual-catalog"
                >
                  <Typography size="extraSmall500">
                    {localeStore.t(
                      'catalog["catalog-control-panel"].buttons.create.options.manual.label',
                    )}
                  </Typography>
                  <Typography size="tiny">
                    {localeStore.t(
                      'catalog["catalog-control-panel"].buttons.create.options.manual.description',
                    )}
                  </Typography>
                </ActionOption>
                <ActionOption
                  className={styles.createAction}
                  onClick={handleCreateCatalog("auto")}
                  data-cy="create-auto-catalog"
                >
                  <Typography size="extraSmall500">
                    {localeStore.t(
                      'catalog["catalog-control-panel"].buttons.create.options.auto.label',
                    )}
                  </Typography>
                  <Typography size="tiny">
                    {localeStore.t(
                      'catalog["catalog-control-panel"].buttons.create.options.auto.description',
                    )}
                  </Typography>
                </ActionOption>
              </ActionsBtn>
            </div>
          </div>
        </div>

        <Tabs
          tabs={catalogTabsLocalized}
          currentTab={catalogsStore.filters.filterBy as CatalogFilterByType}
          onChange={handleTabChange}
          data-cy="catalog-type-filter"
        />
      </div>
    );
  },
);

export default CatalogControlPanel;
