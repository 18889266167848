import React from "react";
import { observer } from "mobx-react-lite";
import { Nullable, Select, SelectOption, FilterCollapse } from "@gemlightbox/core-kit";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { useStores } from "src/hooks";
import { productDefaultTypesNames } from "src/models";

export interface ProductTypeFilterProps {
  value: string;
  onChange: (value: string) => void;
}

export const ProductTypeFilter: React.FC<ProductTypeFilterProps> = observer(
  ({ value, onChange }) => {
    const { attributesStore, localeStore } = useStores();

    const values: SelectOption<string>[] =
      attributesStore.findAttribute("productType")?.values?.map((value) => ({
        value,
        label: value,
        name: `product-type-filter-option-${value.toLowerCase()}`,
      })) ?? [];

    values.unshift({
      value: "all",
      label: "All",
      name: "all",
    });

    const beforeChange = (option: Nullable<SelectOption<string>>) => {
      const value = option?.value;
      if (value) onChange(value);
    };

    return (
      <FilterCollapse
        title={localeStore.t(
          'products["products-list"]["products-table"]["products-table-header"].producttype',
        )}
        triggerName="product-type-filter-collapse"
        data-cy="product-type-filter-collapse"
      >
        <Select
          selectedOptionsKeys={value}
          data-cy="product-type-filter-input"
          options={values.map((item) => {
            item.label = localeStore.t(
              `create["augmented-reality-item"]["ar-types"].${
                productDefaultTypesNames[item.label.toLocaleLowerCase()]
              }` as LocaleCodeTypes,
              item.label,
            );
            return item;
          })}
          placeholder={localeStore.t(
            'components.business.filters["filters-sidebar"]["default-filters"]["product-type-filter"].placeholder',
          )}
          appearance="primaryV2"
          onChange={beforeChange}
          disableError
        />
      </FilterCollapse>
    );
  },
);
