import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  clsx,
  Heading,
  SvgIcon,
  TableCell,
  TableNew,
  TableRow,
  TableTitleSection,
  Typography,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getCurrencySymbol } from "src/utils";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { billingTableFields } from "./billing-history-table.constants";

import PDFIcon from "src/external-ts/assets/images/pdf-type-2.svg";

import styles from "./billing-history-table.module.css";

export const BillingHistoryTable: React.FC = observer(() => {
  const { localeStore, userStore } = useStores();

  const billingHistory = userStore.billingHistory;

  return (
    <div className={styles.billingHistoryContainer}>
      <Heading tag="h2" color="textSecondary">
        {localeStore.t("settings.billing.title")}
      </Heading>
      <TableNew className={styles.billingHistoryTable}>
        <TableTitleSection appearance="header" className={styles.billingHistoryTitleRow}>
          {billingTableFields.map((field) => (
            <TableCell key={field.name} className={styles.billingHistoryTableCell}>
              <Typography size="extraSmall" color="textTertiary">
                {localeStore.t(`settings.billing.table.fields[${field.name}]` as LocaleCodeTypes)}
              </Typography>
            </TableCell>
          ))}
        </TableTitleSection>
        <div className={styles.tableBody}>
          {billingHistory
            .filter(({ status }) =>
              ["paid", "void", "open", "refunded", "partially_refunded"].includes(status),
            )
            .map((invoice) => (
              <TableRow key={invoice.id} className={styles.billingHistoryTableRow}>
                <TableCell className={styles.billingHistoryTableCell}>
                  {invoice.status_url ? (
                    <a
                      href={invoice.status_url}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      <Typography size="extraSmall" color="textSecondary">
                        {invoice.number}
                      </Typography>
                    </a>
                  ) : (
                    <Typography size="extraSmall" color="textSecondary">
                      {invoice.number}
                    </Typography>
                  )}
                </TableCell>
                <TableCell className={styles.billingHistoryTableCell}>
                  <Typography size="extraSmall" color="textTertiary">
                    {new Date(+invoice.date * 1000).toLocaleDateString()}
                  </Typography>
                </TableCell>
                <TableCell className={styles.billingHistoryTableCell}>
                  <Typography size="extraSmall" color="textSecondary">
                    {getCurrencySymbol(invoice.currency)} {invoice.currency.toUpperCase()}{" "}
                    {invoice.amount / 100}
                  </Typography>
                </TableCell>
                <TableCell className={styles.billingHistoryTableCell}>
                  <Typography
                    size="extraSmall500"
                    color={
                      (invoice.status === "paid" && "textPrimary") ||
                      (invoice.status === "void" && "error") ||
                      (invoice.status === "open" && "textTertiary") ||
                      "textTertiary"
                    }
                    className={clsx(styles.invoiceStatus, {
                      [styles.inProgressStatus]: invoice.status === "open",
                      [styles.paidStatus]: invoice.status === "paid",
                      [styles.errorStatus]: invoice.status === "void",
                      [styles.refundedStatus]:
                        invoice.status === "refunded" || invoice.status === "partially_refunded",
                    })}
                  >
                    {localeStore.t(
                      `settings.billing.table["invoice-status"][${invoice.status}]` as LocaleCodeTypes,
                    )}
                  </Typography>
                </TableCell>
                <TableCell className={styles.billingHistoryTableCell}>
                  {Boolean(invoice.invoice_pdf) && (
                    <a href={invoice.invoice_pdf}>
                      <Button appearance="primaryOutlined" size="small">
                        <SvgIcon icon={PDFIcon} />
                      </Button>
                    </a>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </div>
      </TableNew>
    </div>
  );
});
