
      var env = import.meta.env.MODE;
      var srcSuffix = `&gtm_auth=${
        env === "production" ? "p7q98CLry8S6rNNGNiBNEw" : "tIbT-CXLCAhJsBwLiAWxDg"
      }&gtm_preview=${env === "production" ? "env-1" : "env-1024"}&gtm_cookies_win=x`;
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl + srcSuffix;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-P348JP");
    