import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { clsx, SvgIcon, Button } from "@gemlightbox/core-kit";

import { DashboardLink } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { getNavbarItemsLocalized } from "../navbar.constants";

import { ReactComponent as LogoSVG } from "src/external-ts/assets/images/gemlightbox-logo.svg";
import styles from "./tablet-layout.module.css";

export const TabletLayout: React.FC = observer(() => {
  const navigate = useNavigate();

  const location = useLocation();

  const { userStore } = useStores();
  const handleLinkClick = (path: string) => () => {
    if (path === location.pathname) return;
    navigate(path);
  };

  const handleOrderRetouch = () => window.open("https://picupmedia.com/dashboard", "_blank");

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <SvgIcon icon={LogoSVG} size={[28, 30]} />
      </div>

      <nav className={styles.navbarTop}>
        <ul>
          {getNavbarItemsLocalized().map(({ className, path, icon }) => (
            <li key={path} id={path}>
              <Button
                className={clsx(
                  styles.navbarItemContent,
                  { [styles.navbarItemContentActive]: path === location.pathname },
                  className,
                )}
                appearance="unset"
                size="unset"
                onClick={handleLinkClick(path)}
              >
                <SvgIcon icon={icon} />
              </Button>
            </li>
          ))}
        </ul>

        {!userStore.isShopifyUser && !userStore.isAppStoreDemoAccount && (
          <div className={styles.retouchItem}>
            <DashboardLink className={styles.retouch} label="" onClick={handleOrderRetouch} />
          </div>
        )}
      </nav>
    </div>
  );
});
