import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Select, SelectOption, FilterCollapse, Nullable } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";
import { mediaAccessOptions } from "src/containers/media/media.constants";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export interface MediaAccessFilterProps {
  value: Nullable<boolean>;
  onChange: (value: string) => void;
}

export const MediaAccessFilter: React.FC<MediaAccessFilterProps> = observer(
  ({ value, onChange }) => {
    const { localeStore } = useStores();

    const handleChange = (option: SelectOption) => {
      const optionValue = option?.value;
      if (optionValue) onChange(optionValue.toString());
    };

    const filterValue = useMemo(() => {
      switch (value) {
        case true:
          return "private";
        case false:
          return "public";
        default:
          return "all";
      }
    }, [value]);
    return (
      <FilterCollapse
        data-cy="media-access-filter-collapse"
        title={localeStore.t(
          'products["products-list"]["products-table"]["products-table-header"].sharelinkaccess',
        )}
      >
        <Select
          data-name={filterValue}
          selectedOptionsKeys={filterValue}
          options={mediaAccessOptions.map((item) => {
            item.label = localeStore.t(
              `products["products-list"]["products-table"]["products-table-header"].sharelinkoptions["${item.value}"]` as LocaleCodeTypes,
            );
            return item;
          })}
          appearance="primaryV2"
          onChange={(option) => handleChange(option || ({} as SelectOption))}
          disableError
          disableClearing
          disableSingleOptionUncheck
        />
      </FilterCollapse>
    );
  },
);
