import React from "react";
import { clsx, Button, Heading, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import { ReactComponent as PointerSVG } from "src/external-ts/assets/images/share-link-modal/pointer-click-grey.svg";
import styles from "./success-copy-bar.module.css";
import { ShareLinkImageModel } from "../share-link-content.types";

export type SuccessCopyBarProps = {
  link: string;
  images: ShareLinkImageModel[];
  className?: string;
  maxPreviews?: number;
  disablePreview?: boolean;
};

export const SuccessCopyBar: React.FC<SuccessCopyBarProps> = ({
  images,
  link,
  maxPreviews = 5,
  disablePreview,
  className = "",
}) => {
  const { localeStore } = useStores();

  const handleOpen = () => window.open(link, "_blank");

  return (
    <div className={clsx(styles.container, className)} data-cy="share-link-success-copy-bar">
      {!disablePreview && (
        <div className={styles.previewContainer}>
          {images.slice(0, maxPreviews).map((image) => {
            const isPdf = image.type === "pdf";
            return (
              <div key={image.src} className={styles.previewItem}>
                {isPdf && <SvgIcon icon={PdfSVG} />}
                {!isPdf && <img src={image.src || ""} alt="" />}
              </div>
            );
          })}

          {images.length > maxPreviews && (
            <div className={clsx(styles.previewItem, styles.previewExtra)}>
              +{images.length - maxPreviews}
            </div>
          )}
        </div>
      )}

      <Heading tag="h4" color="textSecondary">
        {localeStore.t(
          'components.business["share-modal"]["share-link-content"]["success-copy-bar"].title',
        )}
      </Heading>

      <Button className={styles.button} onClick={handleOpen} data-cy="open-link-button">
        <SvgIcon icon={PointerSVG} />
        {localeStore.t(
          'components.business["share-modal"]["share-link-content"]["success-copy-bar"].link',
        )}
      </Button>
    </div>
  );
};
