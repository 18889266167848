import { useStores } from "src/hooks";
import { AttributeRequestModel } from "src/models";
import { ErrorsType } from "./create-attribute-sidebar.types";

export const validate = ({ name }: AttributeRequestModel): ErrorsType => {
  const { localeStore } = useStores();

  const errors: ErrorsType = {};
  const reservedWords = [
    "sku",
    "title",
    "detailedTitle",
    "description",
    "price",
    "producttype",
    "quantity",
  ];

  if (!name)
    errors.name = localeStore.t('products.modals["create-attribute-sidebar"].errors.required');

  if (name && reservedWords.includes(name.trim())) {
    errors.name = localeStore.t(
      'products.modals["create-attribute-sidebar"].errors["reserved-words"]',
    );
  }

  return errors;
};
