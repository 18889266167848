import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Typography, TableRow, TableCell, Nullable } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { CatalogsSortValueType, CatalogsSortDirectionType } from "src/store";
import { TableSortButtons } from "./table-sort-buttons";

import styles from "./t-head.module.css";

export const Thead: React.FC = observer(() => {
  const [activeSortAngleIcon, setActiveSortAngleIcon] =
    useState<Nullable<{ columnName: CatalogsSortValueType; sortOrder: CatalogsSortDirectionType }>>(
      null,
    );

  const { catalogsStore, localeStore } = useStores();

  const handleSort = async (
    columnName: CatalogsSortValueType,
    sortOrder: CatalogsSortDirectionType,
  ) => {
    const sortValues = { sortBy: columnName, sortOrder };
    await catalogsStore.loadCatalogList(undefined, sortValues);
    setActiveSortAngleIcon({ columnName, sortOrder });
  };

  const handleClearSorting = async () => {
    catalogsStore.resetSortValues();
    await catalogsStore.loadCatalogList();
    setActiveSortAngleIcon(null);
  };

  return (
    <TableRow className={styles.catalogTableHeader} appearance="header">
      <TableCell className={styles.name} data-cy="catalog-table-sort-name">
        <Typography size="extraSmall" color="textTertiary">
          {localeStore.t('catalog["catalogs-list"]["table-header"].columns.name')}
        </Typography>
        <TableSortButtons
          catalogColumnName="name"
          activeSortAngleIcon={activeSortAngleIcon}
          handleSort={handleSort}
        />
      </TableCell>
      <TableCell className={styles.totalProducts} data-cy="catalog-table-sort-totalProducts">
        <Typography size="extraSmall" color="textTertiary">
          {localeStore.t('catalog["catalogs-list"]["table-header"].columns["total-products"]')}
        </Typography>
        <TableSortButtons
          catalogColumnName="total_products"
          activeSortAngleIcon={activeSortAngleIcon}
          handleSort={handleSort}
        />
      </TableCell>
      <TableCell className={styles.description} data-cy="catalog-table-sort-description">
        <Typography size="extraSmall" color="textTertiary">
          {localeStore.t('catalog["catalogs-list"]["table-header"].columns.description')}
        </Typography>
      </TableCell>
      <TableCell className={styles.type} data-cy="catalog-table-sort-type">
        <Typography size="extraSmall" color="textTertiary">
          {localeStore.t('catalog["catalogs-list"]["table-header"].columns.type')}
        </Typography>
        <TableSortButtons
          catalogColumnName="filter"
          activeSortAngleIcon={activeSortAngleIcon}
          handleSort={handleSort}
        />
      </TableCell>
      <TableCell className={styles.updatedAt} data-cy="catalog-table-sort-updatedAt">
        <Typography size="extraSmall" color="textTertiary">
          {localeStore.t('catalog["catalogs-list"]["table-header"].columns["updated-at"]')}
        </Typography>
        <TableSortButtons
          catalogColumnName="updatedAt"
          activeSortAngleIcon={activeSortAngleIcon}
          handleSort={handleSort}
        />
      </TableCell>
      <TableCell className={styles.views} data-cy="catalog-table-sort-views">
        <Typography size="extraSmall" color="textTertiary">
          {localeStore.t('catalog["catalogs-list"]["table-header"].columns.views')}
        </Typography>
        <TableSortButtons
          catalogColumnName="views"
          activeSortAngleIcon={activeSortAngleIcon}
          handleSort={handleSort}
        />
      </TableCell>
      <TableCell className={styles.actions} data-cy="catalog-table-sort-actions">
        {activeSortAngleIcon !== null && (
          <Button
            className={styles.clearSortingButton}
            appearance="primaryOutlined"
            size="small"
            onClick={handleClearSorting}
          >
            {localeStore.t('catalog["catalogs-list"]["table-header"].buttons.clear')}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
});

export default Thead;
