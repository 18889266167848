import { ObjectType } from "@gemlightbox/core-kit";

import { ShareModalTypeKeys } from "src/external-ts/components/business/share-modal/share-modal.types";
import { pushDataLayerEvent, GoogleTagSupportedEvents } from "./index";

type ShareKeys = ShareModalTypeKeys | "embedButton" | "embedCode" | "embedCatalog";

export const pushShareDataLayerEvent = (type: ShareKeys) => {
  const shareMap: ObjectType<GoogleTagSupportedEvents, ShareKeys> = {
    media: "gemhub:media:share_single",
    mediaCollection: "gemhub:media:share_multiple",
    product: "gemhub:product:share",
    catalogListItem: "gemhub:catalog:share",
    catalogFullItem: "gemhub:catalog:share",
    embedButton: "gemhub:widget:embedded_button:copied",
    embedCode: "gemhub:widget:embedded_code:copied",
    embedCatalog: "gemhub:widget:embedded_catalog:copied",
  };

  const event = shareMap[type];

  pushDataLayerEvent({ event });
};
