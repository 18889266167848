import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import {
  Heading,
  Typography,
  Button,
  FormField,
  FormButtons,
  Nullable,
  useQuery,
  queryStringify,
} from "@gemlightbox/core-kit";

import { LOGIN_PAGE } from "src/constants";
import { useStores } from "src/hooks";
import { SignupFormType } from "../signup.types";

import styles from "../signup.module.css";

export type FirstStepProps = {
  subaccountMail: Nullable<string>;
};

export const FirstStep: React.FC<FirstStepProps> = observer(
  ({ subaccountMail }: FirstStepProps) => {
    const navigate = useNavigate();
    const { query } = useQuery();

    const { errors, isValidating } = useFormikContext<SignupFormType>();

    const { localeStore } = useStores();

    const handleSignIn = () => navigate(LOGIN_PAGE.path + queryStringify(query));

    return (
      <>
        <Heading className={styles.header} tag="h2" color="textSecondary">
          {localeStore.t('auth["sign-up"]["first-step"].title')}{" "}
        </Heading>
        <Typography
          className={styles.description}
          size="small"
          color="textTertiary"
          data-cy="sign-up-description-text"
        >
          {localeStore.t('auth["sign-up"]["first-step"].subtitle')}
        </Typography>

        <FormField
          className={styles.email}
          type="text"
          name="email"
          label={localeStore.t('auth["sign-up"]["first-step"].fields.email.label')}
          placeholder={localeStore.t('auth["sign-up"]["first-step"].fields.email.placeholder')}
          appearance="primaryV2"
          messageAlign="right"
          bottomMessage={localeStore.t(
            'auth["sign-up"]["first-step"].fields.email["bottom-message"]',
          )}
          disableError={typeof errors.email !== "string"}
          readOnly={!!subaccountMail}
          data-cy="sign-up-email-field"
        />
        <FormField
          className={styles.password}
          type="password"
          name="password"
          label={localeStore.t('auth["sign-up"]["first-step"].fields.password.label')}
          placeholder={localeStore.t('auth["sign-up"]["first-step"].fields.password.placeholder')}
          appearance="primaryV2"
          messageAlign="right"
          bottomMessage={localeStore.t(
            'auth["sign-up"]["first-step"].fields.password["bottom-message"]',
          )}
          disableError
          data-cy="sign-up-password-field"
        />
        <FormField
          type="password"
          name="confirmPassword"
          label={localeStore.t('auth["sign-up"]["first-step"].fields["confirm-password"].label')}
          placeholder={localeStore.t(
            'auth["sign-up"]["first-step"].fields["confirm-password"].placeholder',
          )}
          appearance="primaryV2"
          disableError
          data-cy="sign-up-confirm-password-field"
        />

        <div className={styles.bottomContainer}>
          <FormButtons align="column">
            <Button type="submit" disabled={isValidating} data-cy="sign-up-next-button">
              {localeStore.t('auth["sign-up"]["first-step"].buttons.next')}
            </Button>
          </FormButtons>

          <div className={styles.textContainer}>
            <Typography size="extraSmall" color="textTertiary" data-cy="sing-up-auth-text">
              {localeStore.t('auth["sign-up"]["sign-in-text"]')}
            </Typography>
            <Button appearance="primaryGhost" onClick={handleSignIn} data-cy="sing-up-auth-button">
              {localeStore.t('auth["sign-up"]["sign-in-button"]')}
            </Button>
          </div>
        </div>
      </>
    );
  },
);
