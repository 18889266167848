import React, { memo } from "react";
import { clsx } from "@gemlightbox/core-kit";

import styles from "./integration-block.module.css";

export type IntegrationBlockProps = Omit<React.HTMLProps<HTMLDivElement>, "ref"> & {
  children: React.ReactNode;
  className?: string;
  clickable?: boolean;
};

export const IntegrationBlock: React.FC<IntegrationBlockProps> = memo(
  ({ children, className = "", clickable = false, ...rest }: IntegrationBlockProps) => {
    return (
      <div
        {...rest}
        className={clsx(
          styles.integrationBlockContainer,
          { [styles.clickable]: clickable },
          className,
        )}
      >
        {children}
      </div>
    );
  },
);

export default IntegrationBlock;
