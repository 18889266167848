import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./shopify-subscription-in-progress.module.css";

type ShopifySubscriptionInProgressProps = {
  label: string;
};

const ShopifySubscriptionInProgress: React.FC<ShopifySubscriptionInProgressProps> = observer(
  ({ label }) => {
    const { localeStore, subscriptionsStore } = useStores();

    const handleRefresh = () => subscriptionsStore.loadSubscription();

    return (
      <div className={styles.container}>
        <Typography className={styles.text} size="small" color="textSecondary">
          {label}
        </Typography>

        <Button onClick={handleRefresh} loading={subscriptionsStore.isLoadingSubscription}>
          {localeStore.t(
            'settings.subscriptions["shopify-subscription-in-progress"].buttons.refresh',
          )}
        </Button>
      </div>
    );
  },
);

export default ShopifySubscriptionInProgress;
