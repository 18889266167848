import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Heading,
  Typography,
  useCall,
  useDidMount,
  useDidUpdate,
} from "@gemlightbox/core-kit";

import { getDnsStatus, patchDnsResolve } from "src/api";
import { useStores } from "src/hooks";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { config } from "src/config/environment";
import { StepsBar } from "../steps-bar";
import { StepsList } from "./steps-list";

import logoImg from "src/common/images/logo-primary.svg";
import styles from "./second-step.module.css";

export type SecondStepProps = {
  domain: string;
  onCancel: VoidFunction;
  onProceed: VoidFunction;
};

export const SecondStep: React.FC<SecondStepProps> = observer(({ domain, onCancel, onProceed }) => {
  const { localeStore, userStore } = useStores();
  const { dns_host } = userStore.userMe || {};
  const hasDNS = dns_host && dns_host !== "null";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const getDnsStatusCall = useCall(getDnsStatus);
  getDnsStatusCall.onCallSuccess(() => {
    setError(false);
    setSuccess(true);
    setLoading(false);

    new Notification(
      localeStore.t('settings["dns-settings"]["second-step"]["domain-approved"].title'),
      {
        body: `${localeStore.t(
          'settings["dns-settings"]["second-step"]["domain-approved"].body.first',
        )} ${config.dashboardUrl}${getSettingsTab(SettingTabNames.dns)?.item.path} ${localeStore.t(
          'settings["dns-settings"]["second-step"]["domain-approved"].body.last',
        )}`,
        lang: "en-US",
        icon: logoImg,
      },
    );
  });
  getDnsStatusCall.onCallError((res) => {
    if (res.statusCode == 400) {
      setError(true);
      setSuccess(false);
      setLoading(false);
    } else {
      getDnsStatusCall.submit();
    }
  });

  const resolveDnsCall = useCall(patchDnsResolve);
  resolveDnsCall.onCallSuccess(() => {
    userStore.loadUserMeSilently();
    onProceed();
  });

  const isLoading = getDnsStatusCall.submitting || resolveDnsCall.submitting;

  useDidMount(() => {
    if (Notification.permission === "default") Notification.requestPermission();
  });

  useDidUpdate(
    () => {
      if (hasDNS) {
        setLoading(true);
        getDnsStatusCall.submit();
      }
    },
    [hasDNS],
    true,
  );

  const handleCancel = () => {
    const formData = new FormData();
    formData.append("dns_host", "null");
    userStore.updateUser(formData);
    onCancel();
  };

  const handleSubmit = () => resolveDnsCall.submit();

  return (
    <div className={styles.container}>
      <StepsBar className={styles.stepper} currentStep={2} completed={success} />

      <Heading tag="h4" color="textSecondary">
        {localeStore.t('settings["dns-settings"]["second-step"].title')}
      </Heading>
      <Typography className={styles.description} size="small" color="textTertiary">
        {localeStore.t('settings["dns-settings"]["second-step"].subtitle.text')}
        <a href="https://support.picupmedia.com/how">
          {" "}
          {localeStore.t('settings["dns-settings"]["second-step"].subtitle.link')}
        </a>
      </Typography>

      <StepsList success={success} error={error} loading={loading} domain={domain} />

      <div className={styles.buttons}>
        {!success && (
          <Button appearance="secondaryOutlined" onClick={handleCancel}>
            {localeStore.t('settings["dns-settings"]["second-step"].buttons.cancel')}
          </Button>
        )}

        <Button onClick={handleSubmit} loading={isLoading} disabled={!success}>
          {localeStore.t('settings["dns-settings"]["second-step"].buttons.done')}
        </Button>
      </div>
    </div>
  );
});
