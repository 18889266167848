import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, Heading, Typography, Input, useCall } from "@gemlightbox/core-kit";

import { getRapnetSettings, putRapnetReset, putRapnetStartSync } from "src/api";
import { useStores } from "src/hooks";
import { DEFAULT_PAGE } from "src/constants";

import { ReactComponent as GemlightBoxLogoSVG } from "src/external-ts/assets/images/gemlightbox-logo.svg";
import { ReactComponent as RapnetSVG } from "src/external-ts/assets/images/integrations/rapnet.svg";
import { ReactComponent as ArrowLeftOblongSVG } from "src/external-ts/assets/images/arrow-left-oblong-grey.svg";
import { ReactComponent as ArrowLeftSVG } from "src/external-ts/assets/images/arrow-left-grey.svg";
import styles from "./rapnet.page.module.css";

export const RapnetPage: React.FC = observer(() => {
  const navigate = useNavigate();

  const { userStore, localeStore, notificationStore } = useStores();

  const putRapnetResetCall = useCall(putRapnetReset);
  putRapnetResetCall.onCallFinal(() => getRapnetSettingsCall.submit());

  const getRapnetSettingsCall = useCall(getRapnetSettings);
  getRapnetSettingsCall.onCallSuccess(({ rapnet_catalog_id }) => {
    putRapnetStartSyncCall.submit({
      data: { catalog_id: rapnet_catalog_id },
    });
  });
  getRapnetSettingsCall.onCallError((error) => handleError(error.formattedMessage));

  const putRapnetStartSyncCall = useCall(putRapnetStartSync);
  putRapnetStartSyncCall.onCallSuccess(() => handleGoBack());
  putRapnetStartSyncCall.onCallError((error) => handleError(error.formattedMessage));

  const isInProcess =
    getRapnetSettingsCall.submitting ||
    putRapnetStartSyncCall.submitting ||
    putRapnetResetCall.submitting;

  const handleError = (message: string) => {
    notificationStore.open({
      title: localeStore.t('common.warnings["ooops-something-wrong"]'),
      message: message,
      confirmText: localeStore.t("common.buttons.confirm"),
      cancelText: "",
      icon: "exclamation",
      confirmAppearance: "primary",
      onlyConfirm: true,
      onOk: () => window.location.reload(),
    });
  };

  const handleGoBack = () => navigate(-1);

  const handleStartSync = () => putRapnetResetCall.submit();

  if (!userStore.isRapnetUser) return <Navigate to={DEFAULT_PAGE.path} />;

  return (
    <div className={styles.rapnetPageContainer}>
      <div className={styles.rapnetPageHeader}>
        <Button
          className={styles.backBtn}
          appearance="secondaryGhost"
          onClick={handleGoBack}
          data-cy="catalog-details-back-button"
        >
          <SvgIcon icon={ArrowLeftOblongSVG} />
        </Button>
        <Heading tag="h3" color="textSecondary">
          RapNet {localeStore.t("integrations.general.integration")}
        </Heading>
      </div>

      <ul className={styles.rapnetSyncList}>
        <li>
          <Typography size="medium">
            {localeStore.t('integrations.rapnet["sync-list"]["option-1"]')}
          </Typography>
        </li>
        <li>
          <Typography size="medium">
            {localeStore.t('integrations.rapnet["sync-list"]["option-2"]')}
          </Typography>
        </li>
      </ul>

      <div className={styles.rapnetPageContent}>
        <div className={styles.syncOption}>
          <Input
            className={styles.inputContainer}
            inputWrapperClassName={styles.inputWrapper}
            label="GemLightbox"
            appearance="primaryV2"
            value="Video"
            childrenPos="before-input"
            disableError
            readOnly
          >
            <SvgIcon icon={GemlightBoxLogoSVG} />
          </Input>
          <div className={styles.arrowsWrapper}>
            <SvgIcon icon={ArrowLeftSVG} />
            <SvgIcon icon={ArrowLeftSVG} />
          </div>
          <Input
            className={styles.inputContainer}
            inputWrapperClassName={styles.inputWrapper}
            label="Rapnet"
            appearance="primaryV2"
            value="Video"
            childrenPos="before-input"
            disableError
            disabled
            readOnly
          >
            <SvgIcon icon={RapnetSVG} />
          </Input>
        </div>

        <div className={styles.syncOption}>
          <Input
            className={styles.inputContainer}
            inputWrapperClassName={styles.inputWrapper}
            label="GemLightbox"
            appearance="primaryV2"
            value="SKU"
            childrenPos="before-input"
            disableError
            readOnly
          >
            <SvgIcon icon={GemlightBoxLogoSVG} />
          </Input>
          <div className={styles.arrowsWrapper}>
            <SvgIcon icon={ArrowLeftSVG} />
            <SvgIcon icon={ArrowLeftSVG} />
          </div>
          <Input
            className={styles.inputContainer}
            inputWrapperClassName={styles.inputWrapper}
            label="Rapnet"
            appearance="primaryV2"
            value="Stock #"
            childrenPos="before-input"
            disableError
            disabled
            readOnly
          >
            <SvgIcon icon={RapnetSVG} />
          </Input>
        </div>

        <div className={styles.rapnetBottomContent}>
          <div className={styles.rapnetLogoWrapper}>
            <SvgIcon icon={RapnetSVG} size={48} />
            <Typography size="small600" color="textSecondary">
              Rapnet
            </Typography>
            <Typography size="extraSmall" color="textTertiary">
              {localeStore.t('integrations.rapnet["field-mapping"]')}
            </Typography>
          </div>
          <Button className={styles.startSyncBtn} onClick={handleStartSync} loading={isInProcess}>
            {localeStore.t('integrations.general["start-sync"]')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default RapnetPage;
