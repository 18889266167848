import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Typography, useDidMount, GooLoader, Pagination } from "@gemlightbox/core-kit";

import {
  PageContainer,
  PageHeader,
  PageResultsPlaceholderContainer,
} from "src/external-ts/components";
import { useStores } from "src/hooks";
import { MediaList } from "./media-list";
import { SelectionPanel } from "./selection-panel";
import { EmptyListPlaceholder } from "./empty-list-placeholder";

import styles from "./trash-bin.module.css";

export const TrashBinPage: React.FC = observer(() => {
  const { localeStore, trashBinStore } = useStores();

  const loading = trashBinStore.loading;

  const isEmpty = trashBinStore.itemsAmount === 0;

  const trashBinListContainerRef = useRef<HTMLDivElement>(null);

  useDidMount(() => {
    trashBinStore.loadTrashBinList();
  });

  const handlePageChange = async (page: number) => {
    await trashBinStore.loadTrashBinList({ ...trashBinStore.filters, page });
    trashBinListContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleLimitChange = async (limit: number) => {
    await trashBinStore.loadTrashBinList({ ...trashBinStore.filters, limit, page: 1 });
    trashBinListContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  return (
    <PageContainer className={styles.page}>
      <PageHeader title={localeStore.t(`["trash-bin"].title`)} showSubscriptionBadge />
      <Typography className={styles.warning} size={"extraSmall"} color={"textTertiary"}>
        {localeStore.t(`["trash-bin"].warning`)}
      </Typography>
      <SelectionPanel />
      <PageResultsPlaceholderContainer>
        {loading && <GooLoader />}
        {!loading && isEmpty && <EmptyListPlaceholder />}
      </PageResultsPlaceholderContainer>
      {!loading && !isEmpty && (
        <>
          <MediaList containerRef={trashBinListContainerRef} />
          <Pagination
            totalItemsText={localeStore.t('components.ui.pagination["total-items"]')}
            prevText={localeStore.t("components.ui.pagination.prev")}
            nextText={localeStore.t("components.ui.pagination.next")}
            perPageText={localeStore.t('components.ui.pagination["per-page"]')}
            page={trashBinStore.filters.page || 0}
            totalPages={trashBinStore.totalPages}
            totalItems={trashBinStore.totalTrashAmount}
            limit={trashBinStore.filters.limit || 50}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
          />
        </>
      )}
    </PageContainer>
  );
});
