import { extendObservable, isObservable, makeAutoObservable, runInAction } from "mobx";
import { DeviceKind, ExtendedDeviceKind } from "src/models";
import { DEVICE_SELECTED_STORAGE, devicekindMap } from "./device-list-modal.constants";
import LocalStorage from "src/common/helpers/local-storage.helper";
import { FilterMode } from "../camera.types";

class DeviceListModalStore {
  private _extendedDeviceKindMap: ExtendedDeviceKind[] = devicekindMap.map((deviceKind) =>
    this.extendDeviceKind(deviceKind),
  );
  public get ExtendedDeviceKindMap() {
    return this._extendedDeviceKindMap;
  }

  private _deviceSelected: ExtendedDeviceKind | null;
  public get DeviceSelected() {
    return this._deviceSelected;
  }

  public setDeviceSelected(dev: ExtendedDeviceKind | null) {
    runInAction(() => {
      this._deviceSelected = dev;
    });
  }

  public setDeviceSelectedByvalue(value: number) {
    runInAction(() => {
      this._deviceSelected = this._extendedDeviceKindMap.find((m) => m.value === value) ?? null;
    });
  }

  public extendDeviceKind(deviceKind: DeviceKind) {
    if (isObservable(deviceKind)) return deviceKind as ExtendedDeviceKind;
    return extendObservable<DeviceKind, ExtendedDeviceKind>(deviceKind, {
      ...deviceKind,
      selected: false,
      device: null,
    });
  }

  private _deviceSelectedStorage?: ExtendedDeviceKind | null;
  public get deviceSelectedStorage() {
    const deviceSelectedStorage = localStorage.getItem(DEVICE_SELECTED_STORAGE);
    if (deviceSelectedStorage) {
      const deviceSelectedStorageData = this.ExtendedDeviceKindMap.find(
        (m) => m.value === Number(deviceSelectedStorage),
      );

      if (deviceSelectedStorageData) {
        deviceSelectedStorageData.selected =
          deviceSelectedStorageData.value !== FilterMode.GemCamOnly;
        runInAction(() => {
          this._deviceSelectedStorage = deviceSelectedStorageData;
        });
        return this._deviceSelectedStorage;
      }
    }
    return this._deviceSelectedStorage;
  }

  public setDeviceSelectedStorage(dev: ExtendedDeviceKind | null) {
    localStorage.setItem(DEVICE_SELECTED_STORAGE, dev ? dev.value.toString() : "");
    this.setDeviceSelected(dev);
    runInAction(() => {
      this._deviceSelectedStorage = dev;
    });
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const deviceListModalStore = new DeviceListModalStore();
