import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useDidMount, useQuery } from "@gemlightbox/core-kit";

import { LOGIN_PAGE, DEFAULT_PAGE, CAMERA_PAGE } from "src/constants";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { useIsLinkEntrance, useStores } from "src/hooks";
import { getVerifySubaccountGUID } from "src/api";
import { EmailSentSuccessPage } from "./email-sent-success";
import { LoginPage } from "./login";
import { ResetPage } from "./reset";
import { TermsOfServicePage } from "./terms-of-service";
import { SignupPage } from "./signup";
import { AuthQueryParamsType } from "./auth.types";

export const AuthPage: React.FC = observer(() => {
  const location = useLocation();
  const isResetPassword =
    location.pathname === "/auth/reset/password" || location.pathname === "/auth/reset/expired";

  const {
    query: { shopify_token, isSubaccount, guid, email },
  } = useQuery<AuthQueryParamsType>({
    parseOptions: {
      decode: false,
    },
  });

  const { userStore, subscriptionsStore } = useStores();

  const navigate = useNavigate();

  const shopifyToken = useRef(shopify_token);

  const [isLogged] = useState(userStore.isLogged);
  const [isSubscribed] = useState(subscriptionsStore.isSubscribed);
  const { isLinkEntrance } = useIsLinkEntrance();

  useDidMount(async () => {
    if (!isSubaccount || !guid || !email) return;

    const isValidGUID = await getVerifySubaccountGUID.getRequest({ params: { guid } }).fetch();

    if (!isValidGUID) {
      navigate("/subaccount-invite-failed");
    }
  });

  if (isLogged && isLinkEntrance) {
    // 跳转 gemcamera 页面
    return <Navigate to={CAMERA_PAGE.path + "?source=setup"}></Navigate>;
  }

  if (isLogged && !isSubscribed && !isResetPassword && !userStore.isAppStoreDemoAccount) {
    return <Navigate to={getSettingsTab(SettingTabNames.subscriptions)?.item.path as string} />;
  }

  if (isLogged && isSubscribed && shopifyToken?.current) {
    return (
      <Navigate
        to={`${getSettingsTab(SettingTabNames.integrations)?.item.path}?shopify_token=${
          shopifyToken?.current
        }`}
      />
    );
  }

  if (isLogged && isSubscribed && !isResetPassword) return <Navigate to={DEFAULT_PAGE.path} />;

  return (
    <Routes>
      <Route path="/login" element={<LoginPage shopifyToken={shopifyToken?.current} />} />
      <Route
        path="/signup"
        element={
          <SignupPage
            subaccountGUID={guid}
            subaccountEmail={email}
            shopifyToken={shopifyToken?.current}
            isSubaccount={isSubaccount}
          />
        }
      />
      <Route path="/terms-of-service" element={<TermsOfServicePage />} />
      <Route path="/email-sent-success" element={<EmailSentSuccessPage />} />
      <Route path="/reset/*" element={<ResetPage />} />
      <Route path="*" element={<Navigate to={LOGIN_PAGE.path} />} />
    </Routes>
  );
});

export default AuthPage;
