import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { MEDIA_PAGE } from "src/constants";
import { cameraStore } from "../camera.store";
import { VerifyCameraBlock } from "./verify-camera-block";
import { VerifyGemcamConnection } from "./verify-gemcam-connection";

import styles from "./camera-troubleshooting.module.css";
import { GlobalInstance } from "src/utils/global";
import { useStores } from "src/hooks";

export const CameraTroubleshooting: React.FC = observer(() => {
  const navigate = useNavigate();
  const { mediaStore } = useStores();

  const renderStep = () => {
    if (cameraStore.isBlockCamera) {
      return <VerifyCameraBlock onClose={handleClose} />;
    } else {
      GlobalInstance.isManualStopRecord = true;
      mediaStore.setSavedStatus(0);
      mediaStore.setPreUploadStatus(0);
      mediaStore.setPreUploadFiles([]);
      cameraStore.handleStopRecord().catch((error) => {
        console.error("Error stopping the recording:", error);
      });
      return <VerifyGemcamConnection onClose={handleClose} />;
    }
  };

  const handleClose = () => navigate(-1);

  return <div className={styles.wrapper}>{renderStep()}</div>;
});
