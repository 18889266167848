import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "src/hooks";
import { clsx } from "@gemlightbox/core-kit";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { bytesToSize, sizeTobytes } from "src/utils/common.utils";
import styles from "./available-space-view.module.css";

export const AvailableSpaceView = observer(() => {
  const { localeStore, userStore } = useStores();
  const { storageLimit } = useLimits();

  const getVideos = () => bytesToSize(userStore.userStorageUsage?.group.video_size, "GB");
  const getPhotos = () => bytesToSize(userStore.userStorageUsage?.group.image_size, "GB");
  const getTrashBin = () => bytesToSize(userStore.userStorageUsage?.group.recycle_bin_size, "GB");

  const minValue = (value: number | null | undefined) => {
    let unit = "GB";
    let size = bytesToSize(value, unit);
    if (size > 0 && size < 1) size = bytesToSize(value, (unit = "MB"));
    if (size > 0 && size < 1) size = bytesToSize(value, (unit = "KB"));
    if (size < 0) size = 0;
    return size.toFixed(2) + " " + unit;
  };
  const usedSpace = () => getVideos() + getPhotos() + getTrashBin();

  const getVideosProgress = () => (getVideos() * 100) / storageLimit;
  const getPhotosProgress = () => (getPhotos() * 100) / storageLimit;
  const getTrashBinProgress = () => (getTrashBin() * 100) / storageLimit;

  const minProgress = (progress: number) => Math.max(progress, 1.2);
  const i18nTitle = (usedSpace: string, limit: string) => {
    return `${localeStore.t('settings["used-storage"].first')} ${usedSpace}  ${localeStore.t(
      'settings["used-storage"].middle',
    )} ${limit} GB  ${localeStore.t('settings["used-storage"].last')}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {i18nTitle(minValue(sizeTobytes(usedSpace())), storageLimit.toFixed(2))}
      </div>
      <div className={styles.progressContainer}>
        {getVideos() !== 0 && (
          <div
            className={styles.videos}
            style={{ width: `${minProgress(getVideosProgress())}%` }}
          />
        )}
        {getPhotos() !== 0 && (
          <div
            className={styles.photos}
            style={{ width: `${minProgress(getPhotosProgress())}%` }}
          />
        )}
        {getTrashBin() !== 0 && (
          <div
            className={styles.trashBin}
            style={{ width: `${minProgress(getTrashBinProgress())}%` }}
          />
        )}
      </div>

      <div className={styles.usageDetails}>
        <div>
          <div className={clsx(styles.mark, styles.videos)} />
          <span>
            {localeStore.t('settings["user-storage-media-labels"].videos')}(
            {minValue(userStore.userStorageUsage?.group.video_size)})
          </span>
        </div>
        <div>
          <div className={clsx(styles.mark, styles.photos)} />
          <span>
            {localeStore.t('settings["user-storage-media-labels"].photos')}(
            {minValue(userStore.userStorageUsage?.group.image_size)})
          </span>
        </div>
        <div>
          <div className={clsx(styles.mark, styles.trashBin)} />
          <span>
            {localeStore.t('settings["user-storage-media-labels"]["trash-bin"]')}(
            {minValue(userStore.userStorageUsage?.group.recycle_bin_size)})
          </span>
        </div>
        <div>
          <div className={clsx(styles.mark, styles.available)} />
          <span>
            {localeStore.t('settings["user-storage-media-labels"].available')}(
            {minValue(sizeTobytes(storageLimit - usedSpace(), "GB"))})
          </span>
        </div>
      </div>
    </div>
  );
});
