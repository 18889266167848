import React from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, clsx, Typography } from "@gemlightbox/core-kit";

import { ReactComponent as DiamondSVG } from "src/external-ts/assets/images/diamond-grey.svg";
import styles from "./custom-plan-badge.module.css";

export type CustomPlanBadgeProps = {
  className?: string;
};

export const CustomPlanBadge: React.FC<CustomPlanBadgeProps> = observer(
  ({ className = "" }: CustomPlanBadgeProps) => {
    return (
      <div className={clsx(styles.customPlanBadgeContainer, className)}>
        <SvgIcon icon={DiamondSVG} size="small" />
        <Typography size="medium600">3-Year Custom Plan</Typography>
      </div>
    );
  },
);

export default CustomPlanBadge;
