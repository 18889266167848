import React, { memo } from "react";
import { clsx } from "@gemlightbox/core-kit";

import styles from "./auth-container.module.css";

export type AuthContainerProps = {
  children: React.ReactNode;
  className?: string;
  fullScreen?: boolean;
};

export const AuthContainer: React.FC<AuthContainerProps> = memo(
  ({ children, className = "", fullScreen = false }: AuthContainerProps) => {
    const authContainerStyles = clsx(styles.authContainer, className, {
      [styles.fullScreen]: fullScreen,
    });

    return <div className={authContainerStyles}>{children}</div>;
  },
);

export default AuthContainer;
