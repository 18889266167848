import React from "react";
import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";

import picupmediaLogo from "src/common/images/logo-primary.svg";
import arrowImg from "src/containers/settings/integrations/images/arrow-circle-right.svg";
import { useStores } from "src/hooks";
import IntegrationAttributeSelect from "../IntegrationAttributeSelect";

const AttributeLine = observer(
  ({
    picupmediaValue,
    integrationValue,
    integrationLogo,
    options,
    readOnly,
    onChange,
    onRemove,
  }) => {
    const { localeStore } = useStores();

    return (
      <div className={clsx("attribute-line", { removable: !!onRemove })}>
        <div className="attribute-line__inputs">
          <IntegrationAttributeSelect
            title="Picupmedia"
            image={picupmediaLogo}
            value={picupmediaValue}
            options={options}
            placeholder={localeStore.t(
              'settings.integrations["integration-attributes"]["attribute-line"]["picupmedia-placeholder"]',
            )}
            onChange={onChange}
            readOnly={readOnly}
          />

          <img className="arrow-img" src={arrowImg} alt="" />

          <IntegrationAttributeSelect
            title="Shopify"
            image={integrationLogo}
            value={integrationValue}
            placeholder="-"
            readOnly
          />
        </div>

        {onRemove && (
          <div className="attribute-line__label">
            <div className="label remove-label" onClick={onRemove}>
              {localeStore.t(
                'settings.integrations["integration-attributes"]["attribute-line"]["remove-button"]',
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default AttributeLine;
