export type SupportedSubscriptionInterval = "monthly" | "yearly" | "lifetime";
export enum SubscriptionType {
  monthly = 1,
  yearly,
  lifetime,
}

export type HandleChangeShopifySubscriptionData = {
  shopURL: string;
  planId: string;
  priceId: string;
  additionalPlanId: string;
  additionalPriceId: string;
};

export type HandleChangeShopifySubscription = (data: HandleChangeShopifySubscriptionData) => void;
