import React from "react";
import { observer } from "mobx-react-lite";
import { Heading } from "@gemlightbox/core-kit";

import { LanguageSelect } from "src/external-ts/components";
import { useStores } from "src/hooks";

import styles from "./language-block.module.css";
import { SupportedUppercaseLocalesModel } from "src/models";

export type LanguageBlockProps = {
  onChangeLocale?: (value: SupportedUppercaseLocalesModel) => void;
};

export const LanguageBlock: React.FC<LanguageBlockProps> = observer(
  ({ onChangeLocale }: LanguageBlockProps) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.container}>
        <Heading tag="h2" color="textSecondary">
          {localeStore.t('settings.profile["language-block"].title')}
        </Heading>
        <LanguageSelect
          className={styles.languagesSelectContainer}
          onChangeLocale={onChangeLocale}
        />
      </div>
    );
  },
);
