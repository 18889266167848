import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import { Step1 } from "./step-1";
import { Step2 } from "./step-2";
import { Step3 } from "./step-3";
import { Step4 } from "./step-4";

import styles from "./steps-list.module.css";

export type StepsListProps = {
  domain: string;
  success: boolean;
  error: boolean;
  loading: boolean;
};

export const StepsList: React.FC<StepsListProps> = observer(
  ({ domain, success, error, loading }) => {
    const { userStore } = useStores();
    const { dns_host } = userStore.userMe || {};
    const hasDNS = dns_host && dns_host !== "null";

    const [currentStep, setCurrentStep] = useState(() => (hasDNS ? 3 : 0));

    const handleProceedStep = (step: 0 | 1 | 2 | 3) => () => {
      setCurrentStep((prevState) => Math.max(prevState, step + 1));
    };

    const handleProceedStep2 = async () => {
      const formData = new FormData();
      formData.append("dns_host", domain);
      const { success } = await userStore.updateUser(formData);
      if (success) handleProceedStep(2)();
    };

    return (
      <div className={styles.list}>
        <Step1
          onProceed={handleProceedStep(0)}
          initialOpen={currentStep === 0}
          isOpen={currentStep === 0}
          completed={currentStep > 0}
        />
        <Step2
          onProceed={handleProceedStep(1)}
          initialOpen={currentStep === 1}
          isOpen={currentStep === 1}
          completed={currentStep > 1}
        />
        <Step3
          domain={domain}
          onProceed={handleProceedStep2}
          initialOpen={currentStep === 2}
          isOpen={currentStep === 2}
          completed={currentStep > 2}
        />
        <Step4
          domain={domain}
          initialOpen={currentStep === 3}
          isOpen={currentStep === 3}
          success={success}
          error={error}
          loading={loading}
        />
      </div>
    );
  },
);
