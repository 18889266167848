import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Image, SvgIcon, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { editMediaStore } from "../../../edit-media.store";
import { GroupBlock } from "../../group-block";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";
import styles from "./recent-pictures.module.css";

export const RecentPictures: React.FC = observer(() => {
  const { uploadedBGs, currentUploadedBG } = editMediaStore;

  const { localeStore } = useStores();

  const handleSelectImage = (objectUrl: string) => async () => {
    await editMediaStore.selectBGImage(objectUrl);
  };

  if (!uploadedBGs.length) return null;

  return (
    <GroupBlock className={styles.recentPicturesBlockGroup}>
      <Typography className={styles.recentPicturesTitle} size="small600" color="textSecondary">
        {localeStore.t('["edit-media"].inspector.background["recent-pictures"]')}
        <Typography size="extraSmall" color="textTertiary">
          {localeStore.t('["edit-media"]["max-10"]')}
        </Typography>
      </Typography>

      <div className={styles.picturesWrapper}>
        {uploadedBGs.map((objectUrl) => (
          <Button
            key={objectUrl}
            className={styles.picture}
            appearance="unset"
            size="unset"
            onClick={handleSelectImage(objectUrl)}
            disabled={editMediaStore.isProcessingImage}
          >
            <Image src={objectUrl} />
            {currentUploadedBG === objectUrl && (
              <SvgIcon className={styles.checkmark} icon={CheckmarkSVG} size={25} />
            )}
          </Button>
        ))}
      </div>
    </GroupBlock>
  );
});

export default RecentPictures;
