import React from "react";
import { observer } from "mobx-react-lite";
import { Loader } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { PageResultsEmpty, PageResultsPlaceholderContainer } from "src/external-ts/components";
import { EmptyCatalogs } from "./components";

export const CatalogListPlaceholder: React.FC = observer(() => {
  const { catalogsStore } = useStores();

  const loading = catalogsStore.loading;
  const isEmpty = catalogsStore.totalCatalogsAmount === 0;

  return (
    <PageResultsPlaceholderContainer>
      {/* if catalogs are loading render loader */}
      {loading && <Loader type="goo-loader" />}
      {/* if catalogs are not loading but empty render EmptyCatalogs */}
      {!loading && isEmpty && <EmptyCatalogs />}
      {/* if catalogs are not loading and not empty then render PageResultsEmpty */}
      {!loading && !isEmpty && (
        <PageResultsEmpty
          search={catalogsStore.filters.name}
          isOnlySearch={catalogsStore.isOnlySearch}
        />
      )}
    </PageResultsPlaceholderContainer>
  );
});
