import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Heading, SvgIcon, useWindowSize, WIDTH_BREAKPOINTS } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as ArrowLeftOblongSVG } from "src/external-ts/assets/images/arrow-left-oblong-grey.svg";
import styles from "./product-header.module.css";

export type ProductHeaderProps = {
  title: string;
  formId: string;
  onGoBack: VoidFunction;
  onCancel: VoidFunction;
  onSave?: VoidFunction;
  disabled?: boolean;
  loading?: boolean;
  mainDisabled?: boolean;
  disableSave?: boolean;
};

export const ProductHeader: React.FC<ProductHeaderProps> = observer(
  ({
    title,
    formId,
    onGoBack,
    onCancel,
    onSave,
    disabled = false,
    loading = false,
    mainDisabled = false,
    disableSave = false,
  }: ProductHeaderProps) => {
    const { windowWidth } = useWindowSize();

    const { localeStore } = useStores();

    const isDisabled = disabled || loading;
    const isMainDisabled = mainDisabled || isDisabled;

    const isSaveDisabled = isMainDisabled || disableSave;

    return (
      <div className={styles.productHeaderContainer}>
        <Button appearance="secondaryGhost" onClick={onGoBack} disabled={isDisabled}>
          <SvgIcon icon={ArrowLeftOblongSVG} />
        </Button>
        <Heading
          className={styles.header}
          tag={windowWidth < WIDTH_BREAKPOINTS.mobileL ? "h4" : "h3"}
          color="textSecondary"
        >
          {title}
        </Heading>

        <Button
          data-cy="product-create-cancel-button"
          className={styles.cancelBtn}
          appearance="secondaryOutlined"
          onClick={onCancel}
          disabled={isMainDisabled}
        >
          {localeStore.t("common.buttons.cancel")}
        </Button>
        <Button
          data-cy="product-create-submit-button"
          className={styles.saveBtn}
          type="submit"
          form={formId}
          onClick={onSave}
          disabled={isSaveDisabled}
          loading={loading}
        >
          {localeStore.t("common.buttons.save")}
        </Button>
      </div>
    );
  },
);

export default ProductHeader;
