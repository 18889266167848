import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  clsx,
  useBoolean,
  SelectGroup,
  SelectGroupOption,
  useOutsideClick,
  Loader,
  Button,
  ApiRequestErrorResponse,
} from "@gemlightbox/core-kit";

import { ExtendedMediaModel } from "src/store";
import { useMediaAssign, useStores } from "src/hooks";
import { ErrorCodes } from "src/constants";
import { MAX_MEDIA_IN_PRODUCT } from "src/containers/products";

import styles from "./media-item-sku.module.css";

export type MediaItemSkuProps = {
  data: ExtendedMediaModel;
};

export const MediaItemSku: React.FC<MediaItemSkuProps> = observer(({ data }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { localeStore, notificationStore, userStore } = useStores();

  const editBoolean = useBoolean(false);

  const isSKUTextEditable = !userStore.isCTFSub;

  useOutsideClick(containerRef, editBoolean.setFalsy);

  const {
    searchValue,
    value,
    error,
    canCreateNew,
    inProcess,
    options,
    onSearchProduct,
    onBlur,
    onChangeProduct,
    onCreateProduct,
    onDropdownOpen,
    onDropdownClose,
  } = useMediaAssign({ data, onError: (originalError) => handleMediaLimit(originalError) });

  const handleMediaLimit = (error: ApiRequestErrorResponse) => {
    const arLimit = error.originalError?.code === ErrorCodes.MEDIA_BLINK_ASSIGN_LIMIT_EXCEEDED;

    const defaultMessage = `${localeStore.t(
      'media["media-list"]["media-item"]["media-item-sku"].modal["message-first"]',
    )} ${MAX_MEDIA_IN_PRODUCT} ${localeStore.t(
      'media["media-list"]["media-item"]["media-item-sku"].modal["message-last"]',
    )}`;

    const message = (arLimit && error.originalError?.message) || defaultMessage;

    notificationStore.open({
      title: localeStore.t('media["media-list"]["media-item"]["media-item-sku"].modal.title'),
      message,
      confirmText: localeStore.t("common.buttons.ok"),
      cancelText: "",
      icon: "exclamation",
      onlyConfirm: true,
    });
  };

  const placeholder = isSKUTextEditable
    ? localeStore.t('media["media-list"]["media-item"]["media-item-sku"].placeholder.editable')
    : localeStore.t(
        'media["media-list"]["media-item"]["media-item-sku"].placeholder["not-editable"]',
      );

  return (
    <div
      className={clsx(styles.container, { [styles.editing]: editBoolean.value })}
      ref={containerRef}
      onClick={editBoolean.setTruthy}
      data-cy="media-item-product"
    >
      <SelectGroup
        className={styles.select}
        inputWrapperClassName={clsx(styles.selectInputWrapper, {
          [styles.disabled]: !editBoolean.value,
        })}
        placeholder={placeholder}
        appearance={editBoolean.value ? "secondaryV2" : "primaryV2"}
        searchValue={searchValue}
        value={value}
        error={error}
        onBlur={onBlur}
        onChange={onChangeProduct}
        onSearch={onSearchProduct}
        onDropdownOpen={onDropdownOpen}
        onDropdownClose={onDropdownClose}
        onEnter={onCreateProduct}
        disableSearch={!isSKUTextEditable}
        disableClear
        disableError
        disableArrow
        data-cy="media-item-product-input"
      >
        {inProcess && <Loader className={styles.dropdownLoader} size={24} />}

        {canCreateNew && !options.length && (
          <Button
            className={styles.createNewBtn}
            appearance="secondaryGhost"
            onClick={onCreateProduct}
            data-cy="create-new-sku"
          >
            {localeStore.t('common.buttons["create-new-sku"]')}
          </Button>
        )}

        {!inProcess &&
          options.map(({ label, value }) => (
            <SelectGroupOption
              key={value}
              value={value}
              data-name={`assign-to-${label}`}
              data-cy="assign-to-sku"
            >
              {label}
            </SelectGroupOption>
          ))}
      </SelectGroup>

      <div
        className={clsx(styles.edit, { [styles.editHidden]: editBoolean.value })}
        data-cy="media-item-product-trigger"
      >
        Edit
      </div>
    </div>
  );
});
