import { dashboardApiMiddleware } from "src/api";
import { AttributeModel, AttributeRequestModel } from "src/models";

export const getAttributes = dashboardApiMiddleware<AttributeModel[]>()({
  method: "get",
  endpoint: "/api/attributes",
  auxiliaryKeys: {
    isDisableCache: true,
  },
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
});

export const postAttribute = dashboardApiMiddleware<AttributeModel[], AttributeRequestModel[]>()({
  method: "post",
  endpoint: "/api/attributes",
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
});

export const putAttribute = dashboardApiMiddleware<AttributeModel, AttributeRequestModel>()({
  method: "put",
  endpoint: "/api/attributes/:id",
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
});

export const deleteAttribute = dashboardApiMiddleware<AttributeModel[]>()({
  method: "delete",
  endpoint: "/api/attributes/:id",
  queryFormatter: (query) => Object.assign(query, { attributesVersion: "v2" }),
});
