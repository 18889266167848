import React from "react";
import { memo, clsx, Typography } from "@gemlightbox/core-kit";

import styles from "./with-title.module.css";

export interface WithTitleProps {
  title: string;
  className?: string;
  contentClassName?: string;
}

export const WithTitle: React.FC<WithTitleProps> = memo(
  ({ children, title, className, contentClassName }) => {
    return (
      <div className={clsx(styles.container, className)}>
        <Typography size="extraSmall" color="textTertiary">
          {title}
        </Typography>
        <div className={contentClassName}> {children} </div>
      </div>
    );
  },
);
