import { config } from "src/config/environment";

export type StaticItems =
  | "/ar-type-1.png"
  | "/ar-type-2.png"
  | "/ar-type-3.png"
  | "/ar-type-4.png"
  | "/ar-type-5.png"
  | "/video360-circle-1.png"
  | "/video360-circle-2.png"
  | "/video360-circle-3.png"
  | "/video360-square-1.png"
  | "/video360-square-2.png"
  | "/video360-square-3.png"
  | "/bracelet-model.gif"
  | "/earrings-model.gif"
  | "/necklace-model.gif"
  | "/ring-model.gif"
  | "/virtual-ring-model.gif"
  | "/watch-model.gif"
  | "/ai-description-thumbnail.png"
  | "/confetti-transparent.gif"
  | "/create-background-white.png"
  | "/create-background-transparent.png"
  | "/create-background-black.png"
  | "/earrings-type-thumbnail.png"
  | "/bracelets-type-thumbnail.png"
  | "/gemstones-type-thumbnail.png"
  | "/necklaces-type-thumbnail.png"
  | "/watches-type-thumbnail.png"
  | "/rings-type-thumbnail.png"
  | "/pendants-type-thumbnail.png"
  | "/earrings-type-icon.svg"
  | "/bracelets-type-icon.svg"
  | "/watches-type-icon.svg"
  | "/gemstones-type-icon.svg"
  | "/rings-type-icon.svg"
  | "/necklaces-type-icon.svg"
  | "/pendants-type-icon.svg"
  | "/earring-in-front-face.png"
  | "/earring-behind-face.png"
  | "/erase-tutorial.gif"
  | "/measurement-tutorial.jpg"
  | "/embed-btn-script.js"
  | "/marketplace-shopify.svg"
  | "/marketplace-mercari.svg"
  | "/marketplace-etsy.svg"
  | "/marketplace-amazon.svg"
  | "/marketplace-poshmark.svg"
  | "/marketplace-depop.svg"
  | "/social-instagram.svg"
  | "/activate-brilliants.png"
  | "/activate-gemlightbox.png";

export const getStaticUrl = (item: StaticItems) => {
  return config.staticURL + "/static" + item;
};
