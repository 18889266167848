import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Radio, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./valid-view.module.css";

export interface ValidViewProps {
  overwriteDuplicates: boolean;
  ignoreDuplicates: boolean;
  renameDuplicates: boolean;
  onOverwriteDuplicatesChange: (checked: boolean) => void;
  onSkipDuplicatesChange: VoidFunction;
  onRenameDuplicates: VoidFunction;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
}

export const ValidView: React.FC<ValidViewProps> = observer(
  ({
    overwriteDuplicates,
    ignoreDuplicates,
    renameDuplicates,
    onOverwriteDuplicatesChange,
    onSkipDuplicatesChange,
    onRenameDuplicates,
    onCancel,
    onConfirm,
  }) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.container}>
        <Typography className={styles.header} size="small" color="textTertiary">
          {localeStore.t('["products-import"].modals["finish-import-modal"]["valid-view"].title')}
        </Typography>

        <div className={styles.body}>
          <div className={styles.options}>
            <Radio
              className={styles.radio}
              label={localeStore.t(
                '["products-import"].modals["finish-import-modal"]["valid-view"].options["overwrite-duplicates"]',
              )}
              checked={overwriteDuplicates && !ignoreDuplicates && !renameDuplicates}
              onChange={() => onOverwriteDuplicatesChange(true)}
              disableError
              disableOverflow
            />
            <Radio
              className={styles.radio}
              label={localeStore.t(
                '["products-import"].modals["finish-import-modal"]["valid-view"].options["do-not-overwrite-duplicates"]',
              )}
              checked={!overwriteDuplicates && !ignoreDuplicates && !renameDuplicates}
              onChange={() => onOverwriteDuplicatesChange(false)}
              disableError
              disableOverflow
            />
            <Radio
              className={styles.radio}
              label={localeStore.t(
                '["products-import"].modals["finish-import-modal"]["valid-view"].options["ignore-duplicates"]',
              )}
              checked={ignoreDuplicates}
              onChange={onSkipDuplicatesChange}
              disableError
              disableOverflow
            />
            <Radio
              className={styles.radio}
              label={localeStore.t(
                '["products-import"].modals["finish-import-modal"]["valid-view"].options["rename-duplicates"]',
              )}
              checked={renameDuplicates}
              onChange={onRenameDuplicates}
              disableError
              disableOverflow
            />
          </div>
        </div>

        <div className={styles.footer}>
          <Button appearance="tertiaryOutlined" onClick={onCancel}>
            {localeStore.t(
              '["products-import"].modals["finish-import-modal"]["valid-view"].buttons.cancel',
            )}
          </Button>
          <Button onClick={onConfirm}>
            {localeStore.t(
              '["products-import"].modals["finish-import-modal"]["valid-view"].buttons.confirm',
            )}
          </Button>
        </div>
      </div>
    );
  },
);
