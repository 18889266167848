import React, { memo } from "react";
import { Radio } from "@gemlightbox/core-kit";

import { CatalogModel } from "src/models";

import styles from "./catalog-item.module.css";

export interface CatalogItemProps {
  catalog: CatalogModel;
  checked: boolean;
  onChange: (catalog: CatalogModel) => void;
}

export const CatalogItem: React.FC<CatalogItemProps> = memo(
  ({ catalog, checked, onChange }: CatalogItemProps) => {
    const { name, total_products } = catalog;

    const handleChange = () => onChange(catalog);

    return (
      <div className={styles.catalogItem}>
        <div className={styles.description}>
          <div className={styles.descriptionName}>{name}</div>
          <div className={styles.descriptionCount}>
            <div className={styles.items}>Products</div>
            <div className={styles.count}>{total_products || 0}</div>
          </div>
        </div>
        <Radio checked={checked} onChange={handleChange} disableError />
      </div>
    );
  },
);

export default CatalogItem;
