import {
  Modal,
  Typography,
  Radio,
  Checkbox,
  Button,
  useBoolean,
  Select,
  Loader,
  SelectGroup,
  SelectGroupOption,
} from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { ModalExtendedType } from "src/store/modals/modals.store.types";
import styles from "./print-modal.module.css";
import { MuiSwitch } from "../../mui-styled/switch";
import { useStores } from "src/hooks";
import { useMutation, useQuery } from "urql";
import {
  PRINT_SETTINGS,
  SHARE_SETTINGS_MUTATION,
} from "src/api/graphql-api/share-settings/share-settings.gql";
import {
  MediaFile,
  UserShareSettingsType,
} from "src/api/graphql-api/share-settings/share-settings.interface";
import { useNavigate } from "react-router-dom";
import { LocaleStore } from "src/store/locale";

const LANGUAGE_OPTIONS = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "española (Spanish)",
    value: "es",
  },
  {
    label: "简体中文 (Simplified Chinese)",
    value: "zh",
  },
  {
    label: "繁體中文 (Traditional Chinese)",
    value: "zh-tw",
  },
  {
    label: "हिन्दी (Hindi)",
    value: "hi",
  },
  {
    label: "عربي (Arabic)",
    value: "ar",
  },
  {
    label: "Français (French)",
    value: "fr",
  },
  {
    label: "日本語 (Japanese)",
    value: "ja",
  },
  {
    label: "한국인 (Korean)",
    value: "ko",
  },
  {
    label: "Deutsch (German)",
    value: "de",
  },
  {
    label: "Italiana (Italian)",
    value: "it",
  },
  {
    label: "Türkçe (Turkish)",
    value: "tr",
  },
  {
    label: "แบบไทย (Thai)",
    value: "th",
  },
  {
    label: "עִברִית (Hebrew)",
    value: "he",
  },
];

type PrintStatus = "on" | "off" | null;

type PrintSettingType = UserShareSettingsType & {
  printShowCompany: {
    status: PrintStatus;
    options: ["logo"] | ["name"];
  };
  printShowContact: {
    status: PrintStatus;
    options: ("phone" | "email" | "whatsapp")[];
  };
  printLanguage: string;
};

type Options = {
  exportPdf: (language: string) => void;
};

export type PrintModalProps = ModalExtendedType<Options>;

export const PrintModal: React.FC<PrintModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { exportPdf } = options;
    const { localeStore, modalsStore } = useStores();
    const navigate = useNavigate();
    const [showCompanyStatus, setShowCompanyStatus] = useState<PrintStatus>(null);
    const [showContactStatus, setShowContactStatus] = useState<PrintStatus>(null);
    const [language, setLanguage] = useState<{ label: string; value: string }>(LANGUAGE_OPTIONS[0]);
    const [radioValue, setRadioValue] = useState<"name" | "logo">();
    const [checkboxValues, setCheckboxValues] = useState<("phone" | "email" | "whatsapp")[]>([]);
    const [companyOptions, setCompanyOptions] = useState<
      { label: string; value: string; disabled: boolean }[]
    >([]);
    const [contactOptions, setContactOptions] = useState<
      { label: string; value: string; disabled: boolean }[]
    >([]);
    const loading = useBoolean(true);
    const [{ data, error, fetching }, getPrintSetting] = useQuery<{
      userShareSettings: PrintSettingType;
    }>({
      query: PRINT_SETTINGS,
      pause: true,
      requestPolicy: "network-only",
    });
    const [_, userUpdateShareSettings] = useMutation(SHARE_SETTINGS_MUTATION);
    const handleChangeLanguage = (option: { label: string; value: string } | undefined) => {
      if (option) {
        setLanguage(option);
        userUpdateShareSettings({
          input: {
            printLanguage: language?.value,
          },
        });
      }
    };
    const [companyTips, setCompanyTips] = useState("");
    const [contactTips, setContactTips] = useState("");
    const showCompanyRef = useRef<HTMLDivElement>(null);
    const showContactRef = useRef<HTMLDivElement>(null);
    const handleCompanyOptionChange = (value: "name" | "logo") => {
      setRadioValue(value);
      userUpdateShareSettings({
        input: {
          printShowCompany: {
            status: showCompanyStatus,
            options: [value],
          },
        },
      });
    };

    const handleContactOptionChange = (value: "phone" | "email" | "whatsapp") => {
      setCheckboxValues((pre) => {
        const newValues = [...pre];
        const index = newValues.indexOf(value);
        if (index !== -1) {
          newValues.splice(index, 1);
        } else {
          newValues.push(value);
        }
        userUpdateShareSettings({
          input: {
            printShowContact: {
              status: showContactStatus,
              options: newValues,
            },
          },
        });
        return newValues;
      });
    };

    const handleShowContactChange = (_: any, checked: boolean) => {
      setShowContactStatus(checked ? "on" : "off");
      userUpdateShareSettings({
        input: {
          printShowContact: {
            status: checked ? "on" : "off",
            options: checkboxValues,
          },
        },
      });
    };

    const handleShowCompanyChange = (_: any, checked: boolean) => {
      setShowCompanyStatus(checked ? "on" : "off");
      userUpdateShareSettings({
        input: {
          printShowCompany: {
            status: checked ? "on" : "off",
            options: [radioValue],
          },
        },
      });
    };

    const handleSubmit = () => {
      exportPdf(language?.value);
    };

    const handleLinkToMenu = (
      event: React.MouseEvent<HTMLElement>,
      type: "company" | "contact",
    ) => {
      const target = event.target as HTMLElement;
      if (target.tagName !== "A") return;
      event.preventDefault();
      const href =
        type === "company"
          ? showCompanyRef.current?.querySelector("a")?.href
          : showContactRef.current?.querySelector("a")?.href;
      if (href) {
        const urlObj = new URL(href);
        const path = urlObj.pathname;
        const scrollTo = urlObj.searchParams.get("scrollTo");
        modalsStore.close("PrintModal");
        navigate(path, { state: { scrollTo } });
      } else {
        throw new Error("href is empty");
      }
    };

    useEffect(() => {
      if (error) {
        console.error("get print setting error", error);
      }
    }, [error]);

    useEffect(() => {
      getPrintSetting();
    }, [open]);

    useEffect(() => {
      if (data && data.userShareSettings) {
        const {
          companyName,
          companyLogo,
          contactEmail,
          contactWhatsup,
          contactPhone,
          printShowCompany,
          printShowContact,
          printLanguage,
        } = data.userShareSettings;
        const { options } = printShowCompany;
        setCompanyOptions(_getCompanyOptions(localeStore, companyName, companyLogo));
        setContactOptions(
          _getContactOptions(localeStore, contactPhone, contactEmail, contactWhatsup),
        );
        setRadioValue(options && options[0]);
        setShowCompanyStatus(printShowCompany.status);
        setShowContactStatus(printShowContact.status);
        setCheckboxValues(printShowContact.options);
        setLanguage(
          LANGUAGE_OPTIONS.find((item) => item.value === printLanguage) || LANGUAGE_OPTIONS[0],
        );
        if (!companyName && !companyLogo) {
          setCompanyTips(
            localeStore.t(
              `components.business[\"print-setting-modal\"].company.tips[\"no-company-info\"]`,
            ),
          );
        } else if (!companyName) {
          setCompanyTips(
            localeStore.t(
              `components.business[\"print-setting-modal\"].company.tips[\"no-company-name\"]`,
            ),
          );
        } else if (!companyLogo) {
          setCompanyTips(
            localeStore.t(
              `components.business[\"print-setting-modal\"].company.tips[\"no-company-logo\"]`,
            ),
          );
        }
        if (!contactEmail && !contactPhone && !contactWhatsup) {
          setContactTips(
            localeStore.t(
              'components.business["print-setting-modal"].contact.tips["no-contact-info"]',
            ),
          );
        } else if (!contactEmail || !contactPhone || !contactWhatsup) {
          setContactTips(
            localeStore.t(
              'components.business["print-setting-modal"].contact.tips["any-empty-contact-info"]',
            ),
          );
        }
        loading.setFalsy();
      }
    }, [data]);

    return (
      <Modal
        name="print-modal"
        data-cy="print-modal"
        scrollWrapperClassName={styles.modalScrollWrapper}
        contentClassName={styles.contentClassName}
        isOpen={isOpen}
        withCross={true}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
      >
        {loading.value && <Loader position="absolute" withOverlay />}
        {!loading.value && (
          <>
            <div className={styles.modalHeader}>
              <Typography className={styles.title} color="textSecondary">
                {localeStore.t('components.business["print-setting-modal"].title')}
              </Typography>
            </div>
            <div className={styles.printContent}>
              <div className={styles.contentRight}>
                <div>
                  <div className={styles.itemHeader}>
                    {localeStore.t('components.business["print-setting-modal"].company.title')}
                    <MuiSwitch
                      disabled={data?.userShareSettings.printShowCompany.status === null}
                      checked={showCompanyStatus === "on"}
                      onChange={handleShowCompanyChange}
                    />
                  </div>
                  {showCompanyStatus === "on" && (
                    <div className={styles.companyOption}>
                      {companyOptions.map(({ label, value, disabled }) => {
                        return (
                          <div style={{ position: "relative" }}>
                            <div className={disabled ? styles.disabled : ""}></div>
                            <Radio
                              className={disabled ? styles.opacity : ""}
                              key={value}
                              value={value}
                              label={label}
                              className={disabled ? styles.opacity : ""}
                              checked={radioValue === value}
                              disableError
                              onChange={() => {
                                if (radioValue === value) return;
                                handleCompanyOptionChange(value);
                              }}
                              data-name={label}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {companyTips && (
                    <div
                      ref={showCompanyRef}
                      className={styles.tips}
                      onClick={(event) => {
                        handleLinkToMenu(event, "company");
                      }}
                      dangerouslySetInnerHTML={{ __html: companyTips }}
                    ></div>
                  )}
                </div>
                <div className={styles.separator}></div>
                <div>
                  <div className={styles.itemHeader}>
                    {localeStore.t('components.business["print-setting-modal"].contact.title')}
                    <MuiSwitch
                      disabled={data?.userShareSettings.printShowContact.status === null}
                      checked={showContactStatus === "on"}
                      onChange={handleShowContactChange}
                    />
                  </div>
                  {showContactStatus === "on" && (
                    <div className={styles.companyOption}>
                      {contactOptions.map(({ label, value, disabled }) => {
                        return (
                          <div style={{ position: "relative" }}>
                            <div className={disabled ? styles.disabled : ""}></div>
                            <Checkbox
                              key={value}
                              value={value}
                              label={label}
                              className={disabled ? styles.opacity : ""}
                              checked={checkboxValues.includes(value)}
                              className={disabled ? styles.opacity : ""}
                              disableError
                              onChange={() => {
                                handleContactOptionChange(value);
                              }}
                              data-name={label}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {contactTips && (
                    <div
                      ref={showContactRef}
                      className={styles.tips}
                      onClick={(event) => {
                        handleLinkToMenu(event, "contact");
                      }}
                      dangerouslySetInnerHTML={{ __html: contactTips }}
                    ></div>
                  )}
                </div>
                <div className={styles.separator}></div>
                <div>
                  <div className={styles.itemHeader} style={{ marginBottom: "8px" }}>
                    {localeStore.t('components.business["print-setting-modal"].language')}
                  </div>
                  <Select
                    disableClearing
                    appearance="primaryV2"
                    inputWrapperClassName={styles.languageOption}
                    dropdownClassName={styles.dropdownClassName}
                    initiallySelectedOptions={language}
                    onChange={handleChangeLanguage}
                    options={LANGUAGE_OPTIONS}
                  ></Select>
                </div>
              </div>
            </div>
            <div className={styles.buttons}>
              <Button
                data-cy="cancel-export-all-btn"
                appearance="tertiaryOutlined"
                onClick={setClose}
              >
                {localeStore.t("common.buttons.cancel")}
              </Button>
              <Button data-cy="export-all-btn" onClick={handleSubmit} loading={loading.value}>
                {localeStore.t("common.buttons.confirm")}
              </Button>
            </div>
          </>
        )}
      </Modal>
    );
  },
);

const _getCompanyOptions = (
  localeStore: LocaleStore,
  companyName: string,
  companyLogo: MediaFile | undefined,
) => {
  return [
    {
      label: localeStore.t('components.business["print-setting-modal"].company.logo'),
      value: "logo",
      disabled: !companyLogo,
    },
    {
      label: localeStore.t('components.business["print-setting-modal"].company.name'),
      value: "name",
      disabled: !companyName,
    },
  ];
};

const _getContactOptions = (
  localeStore: LocaleStore,
  contactPhone: string,
  contactEmail: string,
  contactWhatsup: string,
) => {
  return [
    {
      label: localeStore.t('components.business["print-setting-modal"].contact.email'),
      value: "email",
      disabled: !contactEmail,
    },
    {
      label: localeStore.t('components.business["print-setting-modal"].contact.whatsapp'),
      value: "whatsapp",
      disabled: !contactWhatsup,
    },
    {
      label: localeStore.t('components.business["print-setting-modal"].contact.phone'),
      value: "phone",
      disabled: !contactPhone,
    },
  ];
};
