import { pushDataLayerEvent } from "./index";

type UploadMediaEventPayload = {
  uploaded_total: number;
  media_total: number;
};

export const pushUploadMediaDataLayerEvent = (payload: UploadMediaEventPayload) => {
  pushDataLayerEvent({
    event: "upload_media",
    event_params: {
      ...payload,
    },
  });
};
