import { Nullable } from "@gemlightbox/core-kit";

export const getUserStorageUsage = (
  sizeOfImages: Nullable<number>,
  sizeOfVideos: Nullable<number>,
) => {
  // const result = totalSize / (8 * Math.pow(1024, 3));

  const imagesSize = Number(sizeOfImages) ? Number(sizeOfImages) : 0;
  const videosSize = Number(sizeOfVideos) ? Number(sizeOfVideos) : 0;

  return (imagesSize + videosSize).toFixed(2);
};
