import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Form,
  Button,
  Pagination,
  clsx,
  globalStyles,
  useDidUnmount,
  useDidMount,
  NotificationText,
  SvgIcon,
  Typography,
  Switch,
} from "@gemlightbox/core-kit";

import { ErrorCodes } from "src/constants";
import { PageContainer } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ProductsListWrapper } from "./products-list-wrapper";
import { CreateCatalogFormFields } from "../create-catalog-form-fields";
import { CreateCatalogHeader } from "../create-catalog-header";
import { AUTO_CATALOG_FORM_ID } from "../create-catalog.constants";
import { CreateCatalogFormType } from "../create-catalog.types";
import { createCatalogValidation, useInitialCatalogForm } from "../create-catalog.utils";

import { ReactComponent as SuccessSVG } from "src/external-ts/assets/images/subscription/success-prompt.svg";
import styles from "./create-auto-catalog.module.css";

export const CreateAutoCatalogPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { createCatalogForm } = useInitialCatalogForm();

  const { modalsStore, productsStore, catalogsStore, notificationStore, localeStore } = useStores();

  const [sortingStatus, setSorting] = useState(false);
  const [sortingDirection, setDirection] = useState(false);

  useDidMount(async () => {
    //reset and open filters sidebar on mount
    productsStore.resetFilters();
    handleOpenFilters();
    await catalogsStore.loadLastUntitledCatalogNumber();
  });

  useDidUnmount(() => productsStore.resetStore());

  const handleCreate = async (data: CreateCatalogFormType) => {
    let sortOrder: "fromZtoA" | "fromAtoZ" | undefined;

    if (sortingStatus) sortOrder = sortingDirection ? "fromZtoA" : "fromAtoZ";

    const error = await catalogsStore.createCatalog(
      { ...data, sortOrder: sortOrder },
      productsStore.activeFilters,
    );

    if (error) {
      if (error.error.originalError.code === ErrorCodes.CATALOGS_LIMIT_EXCEEDED) {
        notificationStore.open({
          title: localeStore.t('["create-catalog"].modals.error["limit-exceeded"]'),
          message: error.error.originalError.message,
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: "",
          onlyConfirm: true,
        });
      } else if (error.error.originalError.code === ErrorCodes.CATALOGS_NAME_EXISTS) {
        notificationStore.open({
          title: localeStore.t('catalog["add-to-catalog-sidebar"].notification.warning'),
          message: error.error.originalError.message,
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: "",
          onlyConfirm: true,
        });
      } else {
        notificationStore.open({
          title: localeStore.t('["create-catalog"].modals.error["something-went-wrong"]'),
          confirmText: localeStore.t('["create-catalog"].modals.error["ok-text"]'),
          cancelText: "",
          confirmAppearance: "secondary",
          icon: "exclamation",
          onlyConfirm: true,
        });
      }
    } else {
      notificationStore.open({
        title: localeStore.t('["create-catalog"].modals.success.title'),
        message: (
          <NotificationText disableBold>
            {localeStore.t('["create-catalog"].modals.success.subtitle')}
          </NotificationText>
        ),
        confirmText: localeStore.t('["create-catalog"].modals.success["ok-text"]'),
        cancelText: "",
        onlyConfirm: true,
        confirmAppearance: "primary",
        icon: <SvgIcon icon={SuccessSVG} size={195} />,
        onFinal: () => {
          const newCatalog = catalogsStore.catalogList.find(
            (catalog) => catalog.name === data.name,
          );
          navigate(`/catalog/${newCatalog?.id}`);
        },
      });
    }
  };

  const handleOpenFilters = async () => {
    modalsStore.open("FiltersSidebar", {
      title: localeStore.t('["create-catalog"].modals["filters-sidebar"].title'),
      name: "create-auto-catalog",
      description: localeStore.t('["create-catalog"].modals["filters-sidebar"].description'),
      disableSKUFilter: true,
      disableTitleFilter: true,
      disableDescriptionFilter: true,
      renderNumberAsRange: true,
      initialFilters: productsStore.initialFilters,
      appliedFilters: productsStore.filters,
      onApply: (filters) => {
        productsStore.loadProductsList(filters);
      },
      onClear: () => {
        productsStore.resetFilters();
        modalsStore.close("FiltersSidebar");
      },
    });
  };

  const handlePageChange = (page: number) => {
    productsStore.loadProductsList({ ...productsStore.filters, page });
  };

  const handleLimitChange = (limit: number) => {
    productsStore.loadProductsList({ ...productsStore.filters, limit, page: 1 });
  };

  const areFiltersEmpty = productsStore.filtersAmount === 0;
  const isSubmitButtonDisabled =
    areFiltersEmpty || (!areFiltersEmpty && productsStore.productsAmount === 0);

  return (
    <PageContainer className={styles.container}>
      <CreateCatalogHeader
        formId={AUTO_CATALOG_FORM_ID}
        type="automatic"
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        loading={false}
      />
      <Form
        className={clsx(styles.formContainer, globalStyles.addScrollStyles)}
        contentClassName={styles.formContent}
        formId={AUTO_CATALOG_FORM_ID}
        initialValues={createCatalogForm}
        onSubmit={handleCreate}
        validate={createCatalogValidation}
        disabled={catalogsStore.loading}
        enableReinitialize
      >
        <CreateCatalogFormFields />

        <div className={styles.sorting}>
          <Typography size="extraSmall" color="textSecondary">
            {localeStore.t('["create-catalog"].fields.sorting')}
          </Typography>
          <div className={styles.sortingSwitches}>
            <Switch checked={sortingStatus} onChange={setSorting} />
            <Switch
              checked={sortingDirection}
              onChange={setDirection}
              disabled={!sortingStatus}
              color={sortingStatus ? "primary" : "textDisabled"}
              checkedColor={sortingStatus ? "primary" : "textDisabled"}
              label="A-Z"
              checkedLabel="Z-A"
            />
          </div>
        </div>

        <ProductsListWrapper
          areFiltersEmpty={areFiltersEmpty}
          handleOpenFilters={handleOpenFilters}
        />
      </Form>

      {!isSubmitButtonDisabled && (
        <Button
          className={styles.mobileCreateButton}
          type="submit"
          form={AUTO_CATALOG_FORM_ID}
          disabled={isSubmitButtonDisabled}
          loading={catalogsStore.loading}
          data-cy="create-auto-catalog-create-button"
        >
          {localeStore.t('["create-catalog"].buttons.create')}
        </Button>
      )}

      {productsStore.productsAmount !== 0 && !areFiltersEmpty && (
        <Pagination
          totalItemsText={localeStore.t('components.ui.pagination["total-items"]')}
          prevText={localeStore.t("components.ui.pagination.prev")}
          nextText={localeStore.t("components.ui.pagination.next")}
          perPageText={localeStore.t('components.ui.pagination["per-page"]')}
          page={productsStore.filters.page}
          totalPages={productsStore.totalPages}
          totalItems={productsStore.resultsProductsAmount}
          limit={productsStore.filters.limit}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      )}
    </PageContainer>
  );
});
