import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { SvgIcon, Button, Typography, clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { MediaAmountCard } from "./media-amount-card";
import { AvailableSpaceView } from "./available-space-view";

import { ReactComponent as CloudSVG } from "src/external-ts/assets/images/subscription/cloud-icon.svg";

import styles from "./storage-usage.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export const StorageUsage = observer(() => {
  const navigate = useNavigate();

  const { localeStore, subscriptionsStore, userStore } = useStores();
  const { subscription, isSubscribed, isProLifetimeSubscription, planInterval } =
    subscriptionsStore;

  const subscribeLabel = useMemo(() => {
    if (isSubscribed) {
      const lifetimeLabel = localeStore.t(
        `components.business["subscription-badges"].intervals["${planInterval}"]` as LocaleCodeTypes,
      );
      return `${
        subscription?.product?.name?.replace(" v2", "")?.toLowerCase() || "Pro"
      } ${lifetimeLabel}`;
    }
    return "Free";
  }, [
    isSubscribed,
    isProLifetimeSubscription,
    subscription?.product?.name,
    localeStore.currentLocale,
  ]);

  const handleUpgrade = () => {
    navigate(getSettingsTab(SettingTabNames.subscriptions)?.item.path as string);
  };

  const groupVideoCount = userStore.userStorageUsage?.group.video_count || 0;
  const groupImageCount = userStore.userStorageUsage?.group.image_count || 0;
  const selfVideoCount = userStore.userStorageUsage?.self.video_count || 0;
  const selfImageCount = userStore.userStorageUsage?.self.image_count || 0;

  if (userStore.isShopifyUser && !subscriptionsStore.isSubscribed) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <div
              className={clsx(styles.cloudIconContainer, {
                [styles.cloudIconFree]:
                  !userStore.isSubaccount && !isSubscribed && !userStore.isAppStoreDemoAccount,
              })}
            >
              <SvgIcon icon={CloudSVG} />
            </div>
            <div className={styles.titleContainer}>
              <span>
                {localeStore.t(
                  'settings["user-storage-info"]["storage-usage"]["subscription-label"]',
                )}
              </span>
              <span className={styles.subscribeLabel}>{subscribeLabel}</span>
            </div>
          </div>
          {isSubscribed && (
            <div className={styles.paymentDateInfoContainer}>
              <Typography size="extraSmall" color="textTertiary">
                {localeStore.t('settings["user-storage-info"]["storage-usage"]["payment-label"]')}
              </Typography>
              <Typography size="small600" color="textSecondary">
                {isProLifetimeSubscription
                  ? localeStore.t(
                      'settings.subscriptions.components["current-plan-overview"].tooltip.none',
                    )
                  : subscriptionsStore.getExpireDate(true)}
              </Typography>
            </div>
          )}

          {!userStore.isSubaccount && !isSubscribed && !userStore.isAppStoreDemoAccount && (
            <Button
              className={styles.ctaButton}
              size="unset"
              appearance="unset"
              onClick={handleUpgrade}
            >
              {localeStore.t('settings["user-storage-info"]["storage-usage"].buttons.try')}
            </Button>
          )}
        </div>
        <div className={styles.body}>
          <AvailableSpaceView />
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.mediaAmountContainer}>
            <MediaAmountCard
              label={localeStore.t('settings["user-storage-media-labels"].videos')}
              value={groupVideoCount}
            />
            <MediaAmountCard
              label={localeStore.t('settings["user-storage-media-labels"].photos')}
              value={groupImageCount}
            />
            <MediaAmountCard
              label={localeStore.t('settings["user-storage-media-labels"].members')}
              value={userStore.userMe?.countOfUsers || 0}
            />
          </div>
        </div>
      </div>
      {(selfImageCount != groupImageCount || selfVideoCount != groupVideoCount) && (
        <div className={styles.myselfContainer}>
          <span>{localeStore.t('settings["user-storage-media-labels"].self')}</span>
          <div className={styles.mediaAmountContainer}>
            <MediaAmountCard
              label={localeStore.t('settings["user-storage-media-labels"].videos')}
              value={groupVideoCount - selfVideoCount === 0 ? 0 : selfVideoCount}
            />
            <MediaAmountCard
              label={localeStore.t('settings["user-storage-media-labels"].photos')}
              value={groupImageCount - selfImageCount === 0 ? 0 : selfImageCount}
            />
          </div>
        </div>
      )}
    </div>
  );
});
