import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useDidUpdate, Button, useCall, SvgIcon } from "@gemlightbox/core-kit";

import { postResendConfirmEmail } from "src/api";
import { Logo } from "src/external-ts/components";
import { AuthAside } from "../auth-aside";
import { AuthContainer } from "../auth-container";
import { AuthMain } from "../auth-main";
import { PromoCarousel } from "../promo-carousel";
import { TermsOfServiceArticle } from "./terms-of-service-article";

import styles from "./terms-of-service.module.css";
import { ReactComponent as CheckmarkCircleSVG } from "src/external-ts/assets/images/checkmark-circle-grey.svg";

export const TermsOfServicePage: React.FC = memo(() => {
  const navigate = useNavigate();

  const { query } = useQuery<{ email?: string }>();
  const { email } = query;

  const { submit, submitting, onCallSuccess } = useCall(postResendConfirmEmail);
  onCallSuccess(() => {
    if (!email) return;
    navigate(`/auth/email-sent-success?email=${encodeURIComponent(email)}`);
  });

  const handleGoBack = () => navigate(-1);

  const handleAgree = () => {
    if (!email) return;
    submit({ params: { email } });
  };

  useDidUpdate(() => !email && handleGoBack(), [email], true);

  return (
    <AuthContainer>
      <AuthMain className={styles.termsMainContainer}>
        <Logo />
        <TermsOfServiceArticle />
        <div className={styles.buttonsWrapper}>
          <Button className={styles.cancel} appearance="tertiaryOutlined" onClick={handleGoBack}>
            <span>Back</span>
          </Button>
          <Button
            className={styles.confirm}
            onClick={handleAgree}
            disabled={!email}
            loading={submitting}
          >
            <SvgIcon icon={CheckmarkCircleSVG} />
            <span>Agree</span>
          </Button>
        </div>
      </AuthMain>
      <AuthAside>
        <PromoCarousel />
      </AuthAside>
    </AuthContainer>
  );
});

export default TermsOfServicePage;
