import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import { scrollSelect } from "src/common/helpers/common.helpers";

import angleDown from "src/common/images/angle-down-gray.svg";
import InputWrapper from "src/components/inputs/InputWrapper";
import "./index.scss";

const IntegrationAttributeSelect = ({
  input,
  meta,
  value,
  placeholder,
  options,
  beforeValueLabel,
  onChange,
  title,
  className,
  image,
  readOnly,
}) => {
  const optionsRef = React.useRef();
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);

    if (!expanded) {
      scrollSelect(optionsRef.current.closest("attribute-line"));
    }
  };

  const handleOptionClick = (value) => {
    if (onChange) {
      onChange(value);
    }

    toggleExpanded();
  };

  const checkIfIsActive = (option) => value === option.value;

  return (
    <InputWrapper title={title}>
      <div
        className={clsx("custom-select integration-attribute-select", {
          [`${className}`]: className,
          expanded: expanded,
          "input-error": meta && meta.touched && meta.error,
        })}
        onClick={readOnly ? undefined : toggleExpanded}
      >
        <div className="select-cover" onClick={readOnly ? undefined : toggleExpanded} />

        <div className="select">
          <div className="image-container">
            <img src={image} alt="" />
          </div>

          {value ? (
            <div className="selected">
              {beforeValueLabel && <div className="selected-before"> {beforeValueLabel} </div>}
              {value && <div className="selected-value"> {value} </div>}
            </div>
          ) : (
            <div className="placeholder">{placeholder}</div>
          )}

          {!readOnly && (
            <div className="expand-icon">
              <img src={angleDown} alt="" />
            </div>
          )}
        </div>

        <div className="select-options" ref={optionsRef}>
          {options &&
            options.length > 0 &&
            options.map((option) => (
              <div
                className={clsx("select-options__item", { active: checkIfIsActive(option) })}
                onClick={(e) => handleOptionClick(option, e)}
                key={option.value}
              >
                {option.name}
              </div>
            ))}
        </div>
      </div>
    </InputWrapper>
  );
};

export default IntegrationAttributeSelect;
