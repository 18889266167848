import { useStores } from "src/hooks";

export type UseLimitsReturnType = {
  availableRetouch: number;
  removeBackgroundLimit: number;
  storageLimit: number;
  subaccountsLimit: number;
  catalogsLimit: number;
  canRemoveBGAmount: number;
  canRetouchAIAmount: number;
  canDownloadWithConfig: boolean;
  canEmbedCode: boolean;
  canPrivateLink: boolean;
  canRemoveBackground: boolean;
  canRetouchAI: boolean;
  canGenerateDescription: boolean;
  canGeneratePosts: boolean;
  canBlinks: boolean;
  canCustomAttributes: boolean;
  canCatalogs: boolean;
  canSubaccounts: boolean;
  canIntegrations: boolean;
  canDNS: boolean;
  canResizeCanvas: boolean;
  canProductVariants: boolean;
  removeDust: {
    amountLimit: number;
    amountAvailable: number;
    available: boolean;
    isFreeLimit: boolean;
  };
  checkVariantsLimit: VoidFunction;
};

export const useLimits = (): UseLimitsReturnType => {
  const { subscriptionsStore, userStore, shopifyStore } = useStores();

  const { isSubscribed, limits, isProSubscription } = subscriptionsStore;
  const { isShopifyUser } = userStore;

  const {
    ai_retouch,
    ar_count,
    removal_background,
    count_removal_background,
    count_remove_dust,
    subaccounts,
    limit_storage = 3,
    unlimitedRetouch,
    blinks,
    unlimitedBlinks,
    custom_attributes,
    availableRetouch,
    catalogs,
    unlimitedCatalogs,
    embed_link,
    private_link,
    countGPTDescription,
    countGPTPostGeneration,
    unlimitedGPTDescription,
    unlimitedGPTPostGeneration,
    dns_change,
    remove_dust,
  } = limits;

  const checkVariantsLimit = () => shopifyStore.loadShopifySettings();

  const canDownloadWithConfig = isSubscribed;
  const canEmbedCode = embed_link;
  const canPrivateLink = private_link;
  const canRemoveBackground = removal_background;
  const removeBackgroundLimit = count_removal_background;
  const canRetouchAI = ai_retouch || unlimitedRetouch;
  const canGenerateDescription = countGPTDescription > 0 || unlimitedGPTDescription;
  const canGeneratePosts =
    countGPTPostGeneration > 0 || unlimitedGPTPostGeneration || isProSubscription;
  const canBlinks = ar_count > 0 || blinks > 0 || unlimitedBlinks;
  const canCustomAttributes = custom_attributes;
  const canCatalogs = catalogs > 0 || unlimitedCatalogs;
  const canSubaccounts = subaccounts > 0;
  const canIntegrations = isShopifyUser || (isSubscribed && isProSubscription);
  const storageLimit = limit_storage;
  const subaccountsLimit = subaccounts;
  const catalogsLimit = catalogs;
  const canDNS = dns_change;
  const canResizeCanvas = isSubscribed;
  const canProductVariants = !!shopifyStore.shopifySettings.length && isProSubscription;

  const currentBGCount = userStore.userUsage?.countBackgroundRemoval || 0;
  const canRemoveBGAmount = removeBackgroundLimit - currentBGCount;

  const currentRetouchAICount = userStore.userUsage?.countRetouch || 0;
  const canRetouchAIAmount = availableRetouch - currentRetouchAICount;

  const canDustAI = remove_dust;
  const currentDustRemoveCount = userStore.userUsage?.countDustRemoval || 0;
  const canRemoveDustAmount = count_remove_dust - currentDustRemoveCount;
  const isDustFreeLimit = !canDustAI && canRemoveDustAmount <= 0;

  return {
    canRemoveBGAmount,
    canRetouchAIAmount,
    availableRetouch,
    canDownloadWithConfig,
    canEmbedCode,
    canPrivateLink,
    canRemoveBackground,
    removeBackgroundLimit,
    canRetouchAI,
    canGenerateDescription,
    canGeneratePosts,
    canBlinks,
    canCustomAttributes,
    canCatalogs,
    canSubaccounts,
    canIntegrations,
    storageLimit,
    subaccountsLimit,
    catalogsLimit,
    canDNS,
    canResizeCanvas,
    canProductVariants,
    removeDust: {
      amountLimit: count_remove_dust,
      amountAvailable: canRemoveDustAmount,
      available: canDustAI,
      isFreeLimit: isDustFreeLimit,
    },
    checkVariantsLimit,
  };
};
