import { PAGINATION_OPTIONS, SelectOption } from "@gemlightbox/core-kit";

import { MediaFilters, MediaFilterSortTypeKeys, MediaFilterTypeKeys } from "./media.types";

export const MediaFilterType = {
  all: "All",
  image: "Image",
  video: "Video",
  blink: "Model Image",
} as const;

export const MediaExistFilterType = {
  all: "All",
  with: "With media",
  without: "Without media",
} as const;

export const MediaAccessOptionsType = {
  all: "All",
  private: "Private",
  public: "Public",
} as const;

export const MEDIA_TYPE_OPTIONS_NEW: SelectOption<string>[] = Object.entries(MediaFilterType).map(
  ([key, value]) => ({ label: value, value: key }),
);

export const mediaTypeOptions: SelectOption[] = Object.entries(MediaFilterType).map(
  ([key, value]) => ({ label: value, value: key, name: key }),
);

export const mediaExistOptions: SelectOption[] = Object.entries(MediaExistFilterType).map(
  ([key, value]) => {
    return { label: value, value: key, name: value };
  },
);

export const mediaAccessOptions: SelectOption[] = Object.entries(MediaAccessOptionsType).map(
  ([key, value]) => {
    return { label: value, value: key, name: value };
  },
);

export enum MediaSortTypes {
  withoutSkuFirst = "withoutSkuFirst",
  skuFirst = "skuFirst",
}

export const MediaTypeNames: Record<MediaFilterTypeKeys, string> = {
  all: "All",
  image: "Image",
  video: "Video",
  video360: "Interactive Video",
  blink: "Model Image",
  // pdf: "Pdf files",
};
export const MediaSortFilters: Record<MediaFilterSortTypeKeys, string> = {
  all: "All",
  withoutSkuFirst: "Without SKU First",
  skuFirst: "With SKU First",
};

export const mediaSortOptions: SelectOption[] = Object.entries(MediaSortFilters).map(
  ([key, value]) => ({ label: value, value: key }),
);

export const initialFilters: MediaFilters = {
  page: 1,
  limit: PAGINATION_OPTIONS[0].value,
  sort: "all",
  type: "all",
  sku: "",
  user_id: "all",
};

export const maxImagesPerBatch = 50;

//hardcoded values according to the design
export const mediaMinItemWidth = 202;
export const mediaItemAddedHeight = 69;
export const mediaItemHeightMultiplier = 0.67;
