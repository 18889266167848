import { rootStore } from "src/store";
import { supportedLanguages } from "src/constants/locale.constants";
import { SupportedLocalesModel } from "src/models";

export const setupLocale = () => {
  const newGemHubLang = new URLSearchParams(window.location.search).get("lang");
  if (newGemHubLang) {
    const lowerCaseValue = newGemHubLang.split("-")[0].toLowerCase();
    if (supportedLanguages.includes(lowerCaseValue as any)) {
      rootStore.stores.localeStore.fetchLocale(newGemHubLang as SupportedLocalesModel, true);
      return;
    }
  }

  // If there is locale code in localStorage AND it's supported by us
  // -> download that locale
  const localStorageLocaleCode = localStorage.getItem("localeCode");
  if (localStorageLocaleCode && supportedLanguages.includes(localStorageLocaleCode as any)) {
    rootStore.stores.localeStore.fetchLocale(localStorageLocaleCode as SupportedLocalesModel, true);
  } else {
    // If there is no locale in localStorage
    // -> look browser default language
    // If browser language supported by us -> download it
    // If not -> download default english locale
    const browserDefaultLanguage = window.navigator.language.split("-")[0].toLowerCase();

    const hasSupportedLocale = supportedLanguages.includes(browserDefaultLanguage as any);

    if (hasSupportedLocale) {
      rootStore.stores.localeStore.fetchLocale(
        browserDefaultLanguage as SupportedLocalesModel,
        true,
      );
    } else {
      rootStore.stores.localeStore.fetchLocale("en", true);
    }
  }
};
