import React from "react";
import { observer } from "mobx-react-lite";
import { Input, FilterCollapse, Nullable } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

export interface QuantityExactFilterProps {
  value: Nullable<number>;
  onChange: (value: number) => void;
}

export const QuantityExactFilter: React.FC<QuantityExactFilterProps> = observer(
  ({ value, onChange }) => {
    const { localeStore } = useStores();

    const handleChange = (value: string) => {
      const _value = Number(value);
      onChange(_value);
    };

    return (
      <FilterCollapse title="Quantity">
        <Input
          value={value ?? ""}
          appearance="primaryV2"
          placeholder={`${localeStore.t(
            'components.business.filters["filters-sidebar"]["default-filters"]["quantity-exact-filter"].placeholder',
          )} Quantity`}
          type="number"
          onChange={handleChange}
          disableError
        />
      </FilterCollapse>
    );
  },
);
