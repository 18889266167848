import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import IntegrationStatusLogo from "../IntegrationStatusLogo";
import IntegrationStatusOptions from "../IntegrationStatusOptions";
import { SvgIcon, Typography, useWindowSize, clsx } from "@gemlightbox/core-kit";
import styles from "./styles.module.scss";

const IntegrationsStatusDefaultState = observer(
  ({ logo, title, options, onOptionClick, onClick }) => {
    const { localeStore } = useStores();
    const { windowWidth } = useWindowSize();

    return (
      <>
        {windowWidth <= 840 && (
          <div className={styles.title} onClick={onClick}>
            <SvgIcon icon={logo} size={36} />
            <Typography size="medium600">{title}</Typography>
          </div>
        )}
        {windowWidth > 840 && <IntegrationStatusLogo src={logo} />}

        <div className={clsx("integration-status-data", styles.desc)}>
          {windowWidth > 840 && (
            <div className="integration-status-data__title label" onClick={onClick}>
              {title}
            </div>
          )}
          <div
            className={clsx("integration-status-data__description label", styles.text)}
            onClick={onClick}
          >
            {localeStore.t('settings.integrations["integration-status-bar"]["default-state"]')}
          </div>
          {windowWidth <= 840 && (
            <IntegrationStatusOptions options={options} onOptionClick={onOptionClick} />
          )}
        </div>

        {windowWidth > 840 && (
          <IntegrationStatusOptions options={options} onOptionClick={onOptionClick} />
        )}
      </>
    );
  },
);

export default IntegrationsStatusDefaultState;
