import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Input, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { productVariantsStore } from "../../../product-variants.store";

import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./option-value.module.css";

export type OptionValueProps = {
  value: string;
  index: number;
  onOptionValueChange: (value: string, index: number) => void;
  onOptionValueRemoveClick: (index: number) => void;
  showMaxLabel?: boolean;
  canRemove?: boolean;
  onOptionValueBlur?: (index: number) => void;
};

export const OptionValue: React.FC<OptionValueProps> = observer(
  ({
    value,
    index,
    onOptionValueChange,
    showMaxLabel = false,
    canRemove = false,
    onOptionValueBlur,
    onOptionValueRemoveClick,
  }: OptionValueProps) => {
    const { localeStore } = useStores();

    const handleOptionValueChange = (value: string) => onOptionValueChange(value, index);

    const handleOptionValueBlur = () => onOptionValueBlur?.(index);

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (!onOptionValueBlur || e.key !== "Enter") return;
      e.preventDefault();
      e.stopPropagation();
      onOptionValueBlur(index);
    };

    const handleOptionValueRemoveClick = () => onOptionValueRemoveClick(index);

    return (
      <div className={styles.variantValueOptionContainer}>
        <Input
          className={styles.inputContainer}
          appearance="primaryV2"
          label={localeStore.t('["product-variant"]["option-values"]')}
          labelMessage={showMaxLabel ? localeStore.t('["product-variant"]["max-5"]') : ""}
          placeholder={localeStore.t('["product-variant"]["option-value-placeholder"]')}
          value={value}
          onChange={handleOptionValueChange}
          onBlur={handleOptionValueBlur}
          onKeyDown={handleKeyDown}
        />

        <Button
          appearance="tertiaryOutlined"
          onClick={handleOptionValueRemoveClick}
          disabled={!canRemove || !value || productVariantsStore.isVariantOptionInProcess}
        >
          <SvgIcon icon={TrashSVG} />
        </Button>
      </div>
    );
  },
);

export default OptionValue;
