import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  useCall,
  useDidUpdate,
  useQuery,
  SvgIcon,
  Button,
  Heading,
  Typography,
  useBoolean,
  queryStringify,
} from "@gemlightbox/core-kit";

import { postResendConfirmEmail } from "src/api";
import { Logo, WithSuccessTooltip } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { AuthContainer } from "../auth-container";
import { AuthMain } from "../auth-main";

import { ReactComponent as MailSVG } from "src/external-ts/assets/images/mail-grey.svg";
import styles from "./email-sent-success.module.css";
import { LOGIN_PAGE } from "src/constants";

export const EmailSentSuccessPage: React.FC = observer(() => {
  const navigate = useNavigate();

  const { localeStore } = useStores();

  const { query } = useQuery<{ email?: string; fromResource?: string }>();
  const { email } = query;

  const tooltipOpened = useBoolean(false);

  const { submit, submitting, onCallSuccess } = useCall(postResendConfirmEmail);
  onCallSuccess(() => {
    if (!email) return;
    navigate("/auth/email-sent-success" + queryStringify({ ...query, email }));
    tooltipOpened.setTruthy();
    setTimeout(tooltipOpened.setFalsy, 3000);
  });

  const handleGoBack = () => navigate(-1);

  const handleGoLogin = () => navigate(LOGIN_PAGE.path + queryStringify(query));

  useDidUpdate(() => !email && handleGoBack(), [email], true);

  const handleResend = () => {
    if (!email) return;
    submit({ params: { email }, queryParams: { fromResource: query.fromResource } });
  };

  return (
    <AuthContainer fullScreen>
      <AuthMain fullScreen>
        <Logo />
        <div className={styles.filler}>
          <div className={styles.emailSentContainer}>
            <div className={styles.emailSentWrapper}>
              <div className={styles.mailIcon}>
                <SvgIcon icon={MailSVG} size={40} />
              </div>
              <Heading className={styles.checkInbox} tag="h2" color="textSecondary">
                {localeStore.t('auth["email-sent-success"].title')}
              </Heading>
              <Typography className={styles.message} size="medium" color="textSecondary">
                {localeStore.t('auth["email-sent-success"].subtitle.top')} <strong>{email}</strong>.
                <br />
                {localeStore.t('auth["email-sent-success"].subtitle.bottom')}
              </Typography>
              <Typography className={styles.resendText} size="medium" color="textTertiary">
                {localeStore.t('auth["email-sent-success"]["resend-text"]')}
              </Typography>

              <WithSuccessTooltip
                isOpen={tooltipOpened.value}
                label={localeStore.t('auth["email-sent-success"].success')}
                position="bottom"
                onClose={tooltipOpened.setValue}
              >
                {({ targetRef }) => (
                  <div
                    className={styles.resendBtnContainer}
                    ref={targetRef as React.ForwardedRef<HTMLDivElement>}
                  >
                    <Button
                      className={styles.resendBtn}
                      onClick={handleResend}
                      loading={submitting}
                    >
                      {localeStore.t('auth["email-sent-success"].buttons.resend')}
                    </Button>
                  </div>
                )}
              </WithSuccessTooltip>

              <Button
                className={styles.goBackBtn}
                appearance="secondaryGhost"
                onClick={handleGoLogin}
              >
                {localeStore.t("auth.reset.request.buttons.back")}
              </Button>
            </div>
          </div>
        </div>
      </AuthMain>
    </AuthContainer>
  );
});

export default EmailSentSuccessPage;
