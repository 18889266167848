import React from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, clsx, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

import { ReactComponent as CrownSVG } from "src/external-ts/assets/images/crown-pro-plan.svg";
import styles from "./pro-plan-badge.module.css";

export type ProPlanBadgeProps = {
  className?: string;
  forceName?: string;
  split?: boolean;
  hideInterval?: boolean;
  secondaryTextColor?: boolean;
};

export const ProPlanBadge: React.FC<ProPlanBadgeProps> = observer(
  ({
    className = "",
    forceName = "",
    split = false,
    hideInterval = false,
    secondaryTextColor = false,
  }: ProPlanBadgeProps) => {
    const { subscriptionsStore, localeStore } = useStores();

    const { planInterval } = subscriptionsStore;

    return (
      <div
        className={clsx(
          styles.proPlanBadgeContainer,
          { [styles.split]: split },
          { [styles.secondaryTextColor]: secondaryTextColor },
          className,
        )}
      >
        <SvgIcon className={styles.proPlanBadgeIcon} icon={CrownSVG} />
        <Typography size="medium600">
          {!hideInterval && planInterval && (
            <span className={styles.typeText}>
              {forceName?.replace(" v2", "")?.toLowerCase() || "Pro"}
              &nbsp;
              {localeStore.t(
                `components.business["subscription-badges"].intervals["${planInterval}"]` as LocaleCodeTypes,
              )}
            </span>
          )}
        </Typography>
      </div>
    );
  },
);

export default ProPlanBadge;
