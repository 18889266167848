import React from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, clsx, Typography } from "@gemlightbox/core-kit";

import styles from "./free-plan-badge.module.css";
import { ReactComponent as StarSVG } from "src/external-ts/assets/images/star-grey.svg";

export type FreePlanBadgeProps = {
  className?: string;
  split?: boolean;
  secondaryTextColor?: boolean;
};

export const FreePlanBadge: React.FC<FreePlanBadgeProps> = observer(
  ({ className = "", split = false, secondaryTextColor = false }: FreePlanBadgeProps) => {
    return (
      <div
        className={clsx(
          styles.freePlanBadgeContainer,
          { [styles.split]: split },
          { [styles.secondaryTextColor]: secondaryTextColor },
          className,
        )}
      >
        <SvgIcon className={styles.freePlanBadgeIcon} icon={StarSVG} />
        <Typography size="medium600">Free</Typography>
      </div>
    );
  },
);

export default FreePlanBadge;
