import React from "react";
import { Typography } from "@gemlightbox/core-kit";

import { OrderStatusType } from "src/api";

import styles from "./payments-table-status-cell.module.css";

type PaymentsTableStatusCellProps = {
  status: OrderStatusType;
};

export const PaymentsTableStatusCell: React.FC<PaymentsTableStatusCellProps> = ({ status }) => {
  const renderStatus = (status: OrderStatusType) => {
    switch (status) {
      case "payment_failed":
        return (
          <Typography className={styles.fail} size="extraSmall500" color="error">
            Payment failed
          </Typography>
        );
      case "refund_failed":
        return (
          <Typography className={styles.fail} size="extraSmall500" color="error">
            Refund failed
          </Typography>
        );
      case "paid":
        return (
          <Typography className={styles.success} size="extraSmall500" color="primary">
            Paid
          </Typography>
        );
      case "done":
        return (
          <Typography className={styles.success} size="extraSmall500" color="primary">
            Done
          </Typography>
        );
      case "refunded":
        return (
          <Typography className={styles.success} size="extraSmall500" color="primary">
            Refunded
          </Typography>
        );
      case "unpaid":
        return (
          <Typography className={styles.progress} size="extraSmall500" color="textTertiary">
            Unpaid
          </Typography>
        );
      case "refund_requested":
        return (
          <Typography className={styles.progress} size="extraSmall500" color="textTertiary">
            Refund requested
          </Typography>
        );
    }
  };

  return <>{renderStatus(status)}</>;
};
