import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import "./index.scss";

const Aside = ({ children, className }) => {
  return <aside className={clsx("aside", { [`${className}`]: className })}>{children}</aside>;
};

export default Aside;
