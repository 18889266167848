import React from "react";
import { Loader as PackageLoader } from "@gemlightbox/core-kit";

import "./index.scss";

const Loader = () => (
  <div className="loader">
    <PackageLoader type="circle-loader" size={46} />
  </div>
);

export default Loader;
