import React from "react";
import { useBoolean } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";

import { BlockWrapper } from "../block-wrapper";
import { TutorialsModal } from "../../camera/tutorials-modal";

import tutorialImg from "src/external-ts/assets/images/tutorial-video-icon.png";
import styles from "./tutorial-block.module.css";

export const TutorialBlock: React.FC = () => {
  const isOpenTutorial = useBoolean(false);
  const { localeStore } = useStores();

  return (
    <>
      <BlockWrapper>
        <div className={styles.blockTitle}>{localeStore.t("create.gemcam.tutorial")}</div>
        <div className={styles.blockContent}>
          <img
            className={styles.tutorialImg}
            src={tutorialImg}
            alt="tutorial-video"
            onClick={isOpenTutorial.setTruthy}
          />
        </div>
      </BlockWrapper>

      <TutorialsModal
        isOpenTutorial={isOpenTutorial}
        onClose={isOpenTutorial.setFalsy}
      ></TutorialsModal>
    </>
  );
};

export default TutorialBlock;
