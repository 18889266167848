import { ObjectType } from "@gemlightbox/core-kit";

import { validateForRequired, validatePassword } from "src/utils";

export const validate = (values: ObjectType) => {
  const errors: ObjectType = {};

  if (!values.oldPassword) {
    errors.oldPassword = validateForRequired(values.oldPassword);
  }

  if (!values.newPassword) {
    errors.newPassword = validateForRequired(values.newPassword);
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = validateForRequired(values.confirmPassword);
  }

  if (values.newPassword && values.confirmPassword) {
    if (values.newPassword !== values.confirmPassword) {
      errors.newPassword = "passwords are not equal";
      errors.confirmPassword = "passwords are not equal";
    }
  }

  if (values.newPassword && !validatePassword(values.newPassword)) {
    errors.newPassword =
      "Password should be 8 characters long and contain at least: 1 digit, 1 lowercase, 1 uppercase.";
  }

  return errors;
};
