import React, { memo } from "react";
import { Radio, SelectOption, Typography } from "@gemlightbox/core-kit";

import styles from "./filter.module.css";

interface FilterProps {
  value: string | number;
  title: string;
  name: string;
  options: SelectOption[];
  onChange: (value: string) => void;
}

export const Filter: React.FC<FilterProps> = memo(
  ({ value, title, name, options, onChange }: FilterProps) => {
    const beforeChange = (_: boolean, value: string) => onChange(value);

    if (!options.length) return null;

    return (
      <div className={styles.container} onClick={(e) => e.stopPropagation()} data-cy={name}>
        <Typography size="small600" color="textSecondary">
          {title}
        </Typography>

        {options.map(({ value: _value, label }) => (
          <Radio
            key={_value}
            value={_value}
            label={label}
            className={styles.option}
            onChange={beforeChange}
            checked={_value === value}
            disableError
            data-cy={_value}
          />
        ))}
      </div>
    );
  },
);
