import React from "react";
import { observer } from "mobx-react-lite";
import { Modal, Button, Form, FormField } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { validateForRequired } from "src/utils";
import { initialValues } from "./subscription-cancel-survey-1st.constants";
import { SubscriptionCancelSurvey1stForm } from "./subscription-cancel-survey-1st.types";

import styles from "./subscription-cancel-survey-1st.module.css";

export interface SubscriptionCancelSurvey1stProps {
  isOpen: boolean;
  onSubmit: (res: SubscriptionCancelSurvey1stForm) => void;
  setClose?: (value: false) => void;
}

export const SubscriptionCancelSurvey1st: React.FC<SubscriptionCancelSurvey1stProps> = observer(
  ({ isOpen, onSubmit, setClose }: SubscriptionCancelSurvey1stProps) => {
    const { localeStore } = useStores();

    return (
      <Modal isOpen={isOpen} setClose={setClose} withCross>
        <Form
          contentClassName={styles.formContent}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <div className={styles.modalTitle}>
            {localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-1st"].title',
            )}
          </div>
          <div className={styles.modalSubtitle}>
            {localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-1st"].subtitle',
            )}
          </div>
          <FormField
            type="text"
            name="whyCancel"
            label={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-1st"].fields["why-cancel"].label',
            )}
            placeholder={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-1st"].fields["why-cancel"].placeholder',
            )}
            appearance="secondary"
            validate={validateForRequired}
            required
          />
          <FormField
            type="text"
            name="whatLiked"
            label={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-1st"].fields["what-liked"].label',
            )}
            placeholder={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-1st"].fields["what-liked"].placeholder',
            )}
            appearance="secondary"
            validate={validateForRequired}
            required
          />
          <FormField
            type="text"
            name="whatUseFull"
            label={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-1st"].fields["what-useful"].label',
            )}
            placeholder={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-1st"].fields["what-useful"].placeholder',
            )}
            appearance="secondary"
            validate={validateForRequired}
            required
          />
          <Button className={styles.submitButton} type="submit">
            {localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-1st"].buttons.submit',
            )}
          </Button>
        </Form>
      </Modal>
    );
  },
);

export default SubscriptionCancelSurvey1st;
