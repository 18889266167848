import React from "react";
import { observer } from "mobx-react-lite";
import { Heading, Loader } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { PageResultsPlaceholderContainer } from "src/external-ts/components";

export const ProductsListPlaceholder: React.FC = observer(() => {
  const { catalogStore, localeStore } = useStores();

  const loading = catalogStore.loading;
  const isEmpty = !catalogStore.productsAmount;

  if (!loading && !isEmpty) return null;

  return (
    <PageResultsPlaceholderContainer>
      {loading && <Loader type="goo-loader" />}
      {!loading && isEmpty && (
        <Heading tag="h2">
          {localeStore.t('["catalog-details"]["products-list-placeholder"].title')}
        </Heading>
      )}
    </PageResultsPlaceholderContainer>
  );
});
