import React, { useLayoutEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  CommandAction,
  NotificationText,
  NotificationTextSecondary,
  useDidMount,
  useDidUpdate,
  Webgl2New,
  isEqual,
  Typography,
  Switch,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { unsavedChangesNotification } from "src/utils";
import { editMediaStore } from "../../edit-media.store";
import { GroupBlock } from "../group-block";
import { MeasurementItem } from "./measurement-item";
import { FANTOM_MEASUREMENT_CLASS, measurementsLimit } from "./measurement.constants";
import { mapMeasurementsToPlain } from "./measurement.utils";

import styles from "./measurements.module.css";

export const Measurements: React.FC = observer(() => {
  const { localeStore, notificationStore } = useStores();

  const { renderer, measurementsComponent, fantomMeasurement } = editMediaStore;

  const [measurements, setMeasurements] = useState(() => {
    return editMediaStore.measurementsArr.map((currentMeasure) => currentMeasure.clone());
  });
  const [tempMeasurements] = useState(() => mapMeasurementsToPlain(measurements));

  const measurementsLength = measurements.length;
  const fantomMeasurementIndex = measurementsLength;

  const hasReachedLimitRef = useRef(false);
  hasReachedLimitRef.current = measurementsLength >= measurementsLimit;

  const hasDifference = !isEqual(tempMeasurements, mapMeasurementsToPlain(measurements));

  const handleStraightenModeChange = (value: boolean) => {
    editMediaStore.setStraightenMode(value);
    measurements.forEach((measurement) => {
      measurement.setMeasurementsStraightenMode(value);
    });
  };

  const handleRemoveMeasurement = (measurement: Webgl2New.MeasurementComponent) => {
    if (measurement === editMediaStore.currentMeasurement) {
      editMediaStore.currentMeasurement = editMediaStore.fantomMeasurement;
    }

    setMeasurements((prevState) => {
      return prevState.filter((prev) => prev !== measurement);
    });
  };

  const handleAddMeasurement = (measurement: Webgl2New.MeasurementComponent) => {
    if (!measurement.isValidPos) return;
    setMeasurements((prevState) => prevState.concat(measurement));

    const newFantomMeasurement = new Webgl2New.MeasurementComponent({
      color: measurement.color,
      colorIndex: measurement.colorIndex,
      straightenMode: editMediaStore.measurementsStraightenMode,
    });
    editMediaStore.fantomMeasurement = newFantomMeasurement;
    editMediaStore.currentMeasurement = newFantomMeasurement;
  };

  const handleCancel = () => {
    editMediaStore.unregisterTabBlockCallback();
    editMediaStore.measurementsComponent.setChildren(editMediaStore.measurementsArr);
    editMediaStore.closeMeasurementsTab();
  };

  const handleConfirmCancel = () => {
    if (hasDifference) return unsavedChangesNotification(handleCancel);
    handleCancel();
  };

  const handleDone = () => {
    editMediaStore.unregisterTabBlockCallback();

    const actionMeasurements = measurements;

    const undoMeasurements = editMediaStore.measurementsArr;

    editMediaStore.closeMeasurementsTab();

    renderer.commands.execute(
      new CommandAction(
        "Measurements change",
        () => {
          editMediaStore.measurementsArr = actionMeasurements;
          editMediaStore.measurementsComponent.setChildren(actionMeasurements);
          renderer.render();
        },
        () => {
          editMediaStore.measurementsArr = undoMeasurements;
          editMediaStore.measurementsComponent.setChildren(undoMeasurements);
          renderer.render();
        },
      ),
    );
  };

  const handleConfirmDone = () => {
    if (fantomMeasurement.isValidPos) {
      notificationStore.open({
        title: localeStore.t('["edit-media"].inspector.measurements["confirm-done"].title'),
        message: (
          <>
            <NotificationTextSecondary>
              {localeStore.t('["edit-media"].inspector.measurements["confirm-done"].message.main')}
            </NotificationTextSecondary>
            <NotificationText>
              {localeStore.t(
                '["edit-media"].inspector.measurements["confirm-done"].message.strong',
              )}
            </NotificationText>
          </>
        ),
        confirmText: localeStore.t("common.buttons.done"),
        cancelText: localeStore.t("common.buttons.cancel"),
        onOk: handleDone,
      });

      return;
    }

    handleDone();
  };

  useLayoutEffect(() => {
    const prevDisplay = measurementsComponent.style.display;
    measurementsComponent.style.display = "initial";
    return () => {
      measurementsComponent.style.display = prevDisplay;
    };
  }, []);

  useLayoutEffect(() => {
    editMediaStore.measurementsComponent.setChildren(measurements.concat(fantomMeasurement));
    renderer.render();
  }, [measurementsLength]);

  useDidUpdate(
    () => {
      return editMediaStore.registerTabBlockCallback(({ unregister, proceed }) => {
        if (hasDifference) {
          unsavedChangesNotification(() => {
            unregister();
            handleCancel();
            proceed();
          });
          return true;
        }

        handleCancel();
      });
    },
    [hasDifference],
    true,
  );

  // NOTE: fix bug when you close measurements but still can 'select' measurement components
  useDidMount(() => {
    measurementsComponent.style.pointerEvents = "initial";
    return () => {
      measurementsComponent.style.pointerEvents = "none";
    };
  });

  useDidMount(() => {
    const isFantomSelectedCheck = () => {
      return editMediaStore.currentMeasurement === editMediaStore.fantomMeasurement;
    };

    const disposeDown = renderer.tools.measurementTool.events.on("onDown", (e) => {
      if (hasReachedLimitRef.current || !isFantomSelectedCheck()) return;

      const mouseDownPosition = renderer.camera.getGlobalMousePixelPosition(e);
      editMediaStore.fantomMeasurement.changePositions(mouseDownPosition, mouseDownPosition);
      renderer.render();
    });

    const disposeMove = renderer.tools.measurementTool.events.on("onMove", (e) => {
      if (hasReachedLimitRef.current || !isFantomSelectedCheck()) return;

      const movePosition = renderer.camera.getGlobalMousePixelPosition(e);
      editMediaStore.fantomMeasurement.changeEndPosition(movePosition);
      renderer.render();
    });

    const disposeUp = renderer.tools.measurementTool.events.on("onUp", () => {
      if (hasReachedLimitRef.current || !isFantomSelectedCheck()) return;

      const measureItemContainer = document.querySelector("." + FANTOM_MEASUREMENT_CLASS);
      if (!measureItemContainer) return;
      const measureInput = measureItemContainer.querySelector("input");
      if (!measureInput) return;
      measureInput.focus();
      measureInput.scrollIntoView({ behavior: "smooth" });
    });

    const disposeSelect = renderer.tools.measurementTool.events.on("onSelect", (component) => {
      editMediaStore.currentMeasurement = component;
    });

    return () => {
      disposeDown();
      disposeMove();
      disposeUp();
      disposeSelect();
    };
  });

  return (
    <>
      <GroupBlock className={styles.straighteningBlockGroup}>
        <Typography size="small600" color="textSecondary">
          {localeStore.t('["edit-media"].inspector.measurements["auto-straightening-title"]')}
        </Typography>
        <div className={styles.switchContainer}>
          <Switch
            onChange={handleStraightenModeChange}
            checked={editMediaStore.measurementsStraightenMode}
          />
          <Typography size="small" color="textSecondary">
            {localeStore.t('["edit-media"].inspector.measurements["auto-straightening-check"]')}
          </Typography>
        </div>
      </GroupBlock>
      {measurements.map((measurement, i) => (
        <MeasurementItem
          key={i}
          index={i}
          measurement={measurement}
          onRemoveMeasurement={handleRemoveMeasurement}
          isReal
        />
      ))}
      {!hasReachedLimitRef.current && (
        <MeasurementItem
          className={FANTOM_MEASUREMENT_CLASS}
          index={fantomMeasurementIndex}
          measurement={fantomMeasurement}
          onAddMeasurement={handleAddMeasurement}
        />
      )}
      <div className={styles.buttons}>
        <Button appearance="tertiaryOutlined" onClick={handleConfirmCancel}>
          {localeStore.t("common.buttons.cancel")}
        </Button>
        <Button onClick={handleConfirmDone} disabled={!hasDifference}>
          {localeStore.t("common.buttons.done")}
        </Button>
      </div>
    </>
  );
});

export default Measurements;
