import React from "react";
import { observer } from "mobx-react-lite";
import { clsx, globalStyles, useDidMount } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { PaymentInfo } from "./payment-info";
import { BillingHistoryTable } from "./billing-history-table";

import styles from "./billing.module.css";

export const Billing: React.FC = observer(() => {
  const { userStore } = useStores();

  const fullName = `${userStore.userMe?.user.firstName} ${userStore.userMe?.user.lastName}`;

  useDidMount(() => {
    userStore.loadBillingHistory();
  });

  return (
    <div className={clsx(styles.billingTabContainer, globalStyles.hideScrollBar)}>
      {!userStore.userMe?.isSubaccount && <PaymentInfo name={fullName} />}
      <BillingHistoryTable />
    </div>
  );
});
