import { useMemo } from "react";
import { SelectOption } from "@gemlightbox/core-kit";

import { getUserFilterOptions } from "src/external-ts/components/business/user-select/user-select.utils";
import { useStores } from "./use-stores.hook";

type UserIdsToNamesMapType = { [key: number]: string };

export type UseUserOptionsHookReturnType = {
  options: SelectOption<number>[];
  names: UserIdsToNamesMapType;
};

export const useUserOptions = (): UseUserOptionsHookReturnType => {
  const { userStore, subaccountsStore } = useStores();

  return useMemo(() => {
    const options = userStore.userMe
      ? getUserFilterOptions(userStore.userMe.user, subaccountsStore.subaccountListWithMaster)
      : [];

    const names = options.reduce((acc: UserIdsToNamesMapType, { value, label }) => {
      acc[value] = label;
      return acc;
    }, {});

    return { options, names };
  }, [userStore.userMe, subaccountsStore.subaccountListWithMaster]);
};
