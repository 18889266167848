import { ObjectType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { validatePassword } from "src/utils";
import { NewPasswordResetForm } from "./password.types";

export const requestPasswordResetValidate = (values: NewPasswordResetForm) => {
  const { localeStore } = useStores();

  const errors: ObjectType = {};

  if (!values.password) {
    errors.password = true;
  }

  const isValidPass = validatePassword(values.password);

  if (values.password && !isValidPass) {
    errors.password = true;
    // "Password should be 8 characters long and contain at least: 1 digit, 1 lowercase, 1 uppercase.";
  }

  if (values.password && isValidPass && values.password !== values.confirmPassword) {
    errors.confirmPassword = localeStore.t(
      'auth.reset.password.fields["confirm-password"]["error-no-match"]',
    );
  }

  return errors;
};
