import { dashboardApiMiddleware } from "src/api";

import {
  PostPaypalIntegrateResponseData,
  PostPaypalIntegrateRequestData,
  PostPaypalOrderCaptureRequestData,
  PostPaypalOrderRequestData,
} from "./paypal.interface";

export const postPaypalIntegrate = dashboardApiMiddleware<
  PostPaypalIntegrateResponseData,
  PostPaypalIntegrateRequestData
>()({
  method: "POST",
  endpoint: "/api/paypal/partner-referral",
});

export const postPaypalOrder = dashboardApiMiddleware<undefined, PostPaypalOrderRequestData>()({
  method: "POST",
  endpoint: "/api/paypal/order",
});

export const postPaypalOrderCapture = dashboardApiMiddleware<
  undefined,
  PostPaypalOrderCaptureRequestData
>()({
  method: "POST",
  endpoint: "/api/paypal/order/capture",
});
