import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, Typography, SvgIcon, Dropzone } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { editMediaStore } from "../../../edit-media.store";
import { GroupBlock } from "../../group-block";

import { ReactComponent as UploadSVG } from "src/external-ts/assets/images/upload-cloud-grey.svg";
import styles from "./upload-btn.module.css";

export const UploadBtn: React.FC = observer(() => {
  const { localeStore } = useStores();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleDropAccepted = async ([file]: File[]) => {
    await editMediaStore.uploadBGImage(file);
  };

  const handleBrowse = () => inputRef.current?.click();

  return (
    <GroupBlock className={styles.uploadPictureBlockGroup}>
      <Typography size="small600" color="textSecondary">
        {localeStore.t('["edit-media"].inspector.background["upload-picture"]')}
      </Typography>
      <Dropzone
        accept="image/*"
        onDropAccepted={handleDropAccepted}
        disabled={editMediaStore.isProcessingImage}
        preventDropOnDocument
      >
        {({ getRootProps, getInputProps }) => (
          <Button
            {...getRootProps({ onClick: handleBrowse }, true)}
            appearance="tertiaryOutlined"
            loading={editMediaStore.isProcessingImage}
          >
            <input {...getInputProps({ forwardRef: inputRef })} />
            <SvgIcon icon={UploadSVG} />
            {localeStore.t("common.buttons.upload")}
          </Button>
        )}
      </Dropzone>
    </GroupBlock>
  );
});

export default UploadBtn;
