import React from "react";
import { Button, UseBooleanReturnType } from "@gemlightbox/core-kit";
import { styled } from "@mui/material/styles";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router-dom";
import { SettingTabNames, getSettingsTab } from "src/containers/settings";
import { useStores } from "src/hooks";

import upgradeBanner from "src/external-ts/assets/images/pro-user-update-caption.png";

import styles from "./pro-user-upgrade-modal.module.css";

const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: "480px",
    width: "480px",
    borderRadius: "16px",
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
  "& .MuiDialogContent-root": {
    padding: "0 32px",
  },
}));

type ProUserUpgradeModalProps = {
  content: string;
  visible: UseBooleanReturnType;
  onClose: VoidFunction;
};

export const ProUserUpgradeModal: React.FC<ProUserUpgradeModalProps> = ({
  visible,
  onClose,
  content,
}) => {
  const navigate = useNavigate();
  const { localeStore } = useStores();

  const handleUpgrade = () => {
    navigate(getSettingsTab(SettingTabNames.subscriptions)?.item.path as string);
    onClose();
  };

  return (
    <StyledDialog open={visible.value} onClose={onClose} maxWidth="xs">
      <DialogTitle className={styles.dialogHeader}>
        <img src={upgradeBanner} className={styles.banner} alt="banner" />
      </DialogTitle>

      <DialogContent>
        <div className={styles.contentText}>{content}</div>
        <div className={styles.contentSubText}>
          {localeStore.t('common["pro-user"]["sub-upgrade-text"]')}
        </div>
      </DialogContent>

      <DialogActions
        sx={{
          flexDirection: "column",
          marginTop: "44px",
          padding: "0 32px 12px",
        }}
      >
        <Button className={styles.submitBtn} onClick={handleUpgrade}>
          {localeStore.t("common.buttons.upgrade")}
        </Button>
        <Button appearance="secondaryOutlined" className={styles.cancelBtn} onClick={onClose}>
          {localeStore.t("common.buttons.cancel")}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
