import React, { useRef } from "react";
import { memo, clsx, ObjectType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ModalNavType, subscriptionTooltip } from "../share-modal.constants";
import { ShareModalNavKeys } from "../share-modal.types";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import SubscriptionTooltip from "src/components/SubscriptionTooltip";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./share-modal-navigation.module.css";

interface ShareModalNavigationProps {
  tab: ShareModalNavKeys;
  isCatalog?: boolean;
  isMedia?: boolean;
  isPdf?: boolean;
  mediaWithNoSku?: boolean;
  onChange: (tab: ShareModalNavKeys) => void;
  onClose: VoidFunction;
}

export const ShareModalNavigation: React.FC<ShareModalNavigationProps> = memo(
  ({ tab, isCatalog, onChange, onClose, mediaWithNoSku, isMedia, isPdf }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const { localeStore } = useStores();

    const { canEmbedCode, canGeneratePosts } = useLimits();
    const tooltipData = {
      "data-for": subscriptionTooltip,
      "data-tip": "",
      "data-place": "left",
    };

    const beforeChange = (value: ShareModalNavKeys) => {
      if (value !== tab) onChange(value);
    };

    const getModalNavTypeLocalized = () => {
      const res = {} as ObjectType;
      Object.entries(ModalNavType).map(([value, label]) => {
        switch (value) {
          case "generatePost":
            res.generatePost = localeStore.t(
              'components.business["share-modal"]["share-modal-navigation"].tabs["generate-post"]',
            );
            break;
          case "shareLink":
            res.shareLink = localeStore.t(
              'components.business["share-modal"]["share-modal-navigation"].tabs["share-link"]',
            );
            break;
          case "embedButton":
            res.embedButton = localeStore.t(
              'components.business["share-modal"]["share-modal-navigation"].tabs["embed-button"]',
            );
            break;
          case "embedCode":
            res.embedCode = localeStore.t(
              'components.business["share-modal"]["share-modal-navigation"].tabs["embed-code"]',
            );
            break;
          case "embedCatalog":
            res.embedCatalog = localeStore.t(
              'components.business["share-modal"]["share-modal-navigation"].tabs["embed-catalog"]',
            );
            break;
          default:
            res[value] = label;
            break;
        }
      });
      return res;
    };

    return (
      <div
        ref={containerRef}
        className={clsx(styles.container, globalStyles.addScrollStyles)}
        data-cy="share-modal-navigation"
      >
        <SubscriptionTooltip id={subscriptionTooltip} onUpgrade={onClose} />

        {Object.entries(getModalNavTypeLocalized())
          .filter(([_]) => {
            const value = _ as ShareModalNavKeys;
            if (isPdf && value !== "shareLink") return false;
            if (!isCatalog && value === "embedCatalog") return false;
            if (
              (isCatalog || !canGeneratePosts || (isMedia && mediaWithNoSku)) &&
              value === "generatePost"
            )
              return false;
            return !(isCatalog && value === "embedButton");
          })
          .map(([value, label]) => {
            const isEmbedCode = value === "embedCode";
            const isEmbedButton = value === "embedButton";
            const withTooltip = (isEmbedCode || isEmbedButton) && !canEmbedCode;

            return (
              <div
                key={value}
                className={clsx(styles.navItem, {
                  [styles.disabled]: withTooltip,
                  [styles.navItemActive]: tab === value,
                })}
                onClick={withTooltip ? undefined : () => beforeChange(value as ShareModalNavKeys)}
                data-cy={`${value}-tab`}
                {...(withTooltip && tooltipData)}
              >
                <div className={styles.navItemText}>{label}</div>
              </div>
            );
          })}
      </div>
    );
  },
);
