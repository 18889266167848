import { extendObservable, isObservable, makeAutoObservable, runInAction, toJS } from "mobx";
import {
  ApiRequestHeaders,
  isEqual,
  isUndefined,
  MaybeArray,
  Nullable,
  PAGINATION_OPTIONS,
  PaginationList,
  SelectOption,
  toArrayOptions,
} from "@gemlightbox/core-kit";

import {
  deleteExportedProductsList,
  deleteProducts,
  getCurrencies,
  getExportedProductsList,
  getProduct,
  getProducts,
  getUserTagManagerInfoCallback,
  postExportAllProducts,
  postProduct,
  putUpdateProduct,
  putUpdateProductAttribute,
  putUpdateProducts,
} from "src/api";
import {
  AttributeModel,
  MediaModel,
  ProductModel,
  ProductParameterModel,
  ProductParameterRequestModel,
  ProductRequestModel,
  ProductsExportType,
} from "src/models";
import {
  ExtendedProductModel,
  ProductCurrency,
  ProductFiltersQueryType,
  ProductSortingType,
} from "./products.store.types";
import { pushDataLayerEvent } from "src/utils";
import { defaultFiltersNames, FiltersType, ViewTypes } from "src/external-ts/components";
import { ExportedProductsFileType, ExportProductsRequestDataType } from "src/api";

export class ProductsStore {
  private readonly _initialFilters: FiltersType = {
    limit: PAGINATION_OPTIONS[0].value,
    page: 1,
    ids: [],
    attributes: [],
    title: "",
    fromPrice: null,
    toPrice: null,
    detailedTitle: "",
    description: "",
    productType: "all",
    quantity: [],
    user_id: "all",
    mediaType: "all",
    catalog_ids_to_exclude: undefined,
    isThereMedia: undefined,
    isPendingUpload: undefined,
    isPrivate: undefined,
  };

  private readonly _initialSortingValues: ProductSortingType = {
    sort: "newest",
    sortDir: "desc",
  };

  private _loading = false;
  private _totalProductsAmount = 0;
  private _resultsProductsAmount = 0;
  private _productsList: ExtendedProductModel[] = [];
  private _filters: FiltersType = this._initialFilters;
  private _sortingValues: ProductSortingType = this._initialSortingValues;
  private _currencies: ProductCurrency[] = [];
  private _exportedFileId: Nullable<string> = null;
  private _view: ViewTypes = "table" as ViewTypes;
  private _productsWithoutMedia: ExtendedProductModel[] = [];
  private _productsWithoutMediaAmount = 0;
  private _productsWithoutMediaPage = 0;
  private _totalProductsPendingUploadAmount = 0;
  private _productsPendingUploadList: ExtendedProductModel[] = [];

  private _scrollPosition: { left: number; top: number } = { left: 0, top: 0 };
  public get scrollPosition() {
    return { ...this._scrollPosition };
  }
  public setScrollPosition(position: { left: number; top: number }) {
    this._scrollPosition = { ...position };
  }

  private _scrollRestoration = false;
  public get scrollRestoration() {
    return this._scrollRestoration;
  }
  public setScrollRestoration(scrollRestoration: boolean) {
    this._scrollRestoration = scrollRestoration;
  }

  get loading() {
    return this._loading;
  }

  get totalProductsAmount() {
    return this._totalProductsAmount;
  }

  get resultsProductsAmount() {
    return this._resultsProductsAmount;
  }

  get productsList() {
    return this._productsList;
  }

  get productsAmount() {
    return this._productsList.length;
  }

  get view() {
    return this._view;
  }

  set view(view: ViewTypes) {
    this._view = view;
  }

  get totalProductsPendingUploadAmount() {
    return this._totalProductsPendingUploadAmount;
  }

  get productsPendingUploadList() {
    return this._productsPendingUploadList;
  }

  get productsWithoutMedia() {
    return this._productsWithoutMedia;
  }

  get productsWithoutMediaAmount() {
    return this._productsWithoutMediaAmount;
  }

  get productsWithoutMediaPage() {
    return this._productsWithoutMediaPage;
  }

  get selectedProductsList() {
    return this._productsList.filter(({ extended: { selected } }) => selected);
  }

  public get selectedProductsAmount() {
    return this.selectedProductsList.length;
  }

  public get areProductsSelected() {
    return this.selectedProductsAmount !== 0;
  }

  public get isSingleProductSelected() {
    return this.selectedProductsAmount === 1;
  }

  public get areMultipleProductsSelected() {
    return this.selectedProductsAmount > 1;
  }

  public get isEveryProductSelected() {
    return this.selectedProductsAmount === this.productsAmount;
  }

  public get filters() {
    return this._filters;
  }

  public get initialFilters() {
    return this._initialFilters;
  }

  public get sortValues() {
    return this._sortingValues;
  }

  public get totalPages() {
    return Math.ceil(this.resultsProductsAmount / this.filters.limit);
  }

  public get filtersAmount() {
    const {
      attributes,
      fromPrice,
      toPrice,
      quantity,
      mediaType,
      user_id,
      productType,
      isThereMedia,
      isPrivate,
    } = this.filters;

    let result = attributes.length;

    if (fromPrice && toPrice) result += 1;
    if (quantity.length !== 0) result += 1;
    if (mediaType && mediaType !== "all") result += 1;
    if (productType && productType !== "all") result += 1;
    if (user_id && user_id !== "all") result += 1;
    if (isThereMedia !== undefined) result += 1;
    if (isPrivate !== undefined) result += 1;

    defaultFiltersNames.forEach((name) => {
      const value = this.filters[name];

      if (value && value !== "all") {
        result += 1;
      }
    });

    return result;
  }

  public get activeFilters() {
    const {
      attributes,
      fromPrice,
      toPrice,
      quantity,
      mediaType,
      user_id,
      productType,
      isThereMedia,
      isPrivate,
    } = this.filters;
    const result = {} as FiltersType;

    if (attributes.length) result.attributes = attributes;

    if (fromPrice && toPrice) {
      result.fromPrice = fromPrice;
      result.toPrice = toPrice;
    }
    if (quantity.length !== 0) result.quantity = quantity;
    if (mediaType && mediaType !== "all") result.mediaType = mediaType;
    if (productType && productType !== "all") result.productType = productType;
    if (user_id && user_id !== "all") result.user_id = user_id;
    if (!isUndefined(isThereMedia)) result.isThereMedia = isThereMedia;
    if (!isUndefined(isPrivate)) result.isPrivate = isPrivate;

    defaultFiltersNames.forEach((name) => {
      const value = this.filters[name];

      if (value && value !== "all") {
        result[name] = value;
      }
    });

    return result;
  }

  public get isSearch() {
    return !!this.filters.title;
  }

  public get isFilters() {
    return this.filtersAmount !== 0;
  }

  public get isOnlySearch() {
    return this.isSearch && this.filtersAmount <= 1;
  }

  public get currencies() {
    return this._currencies;
  }

  public get currenciesOptions(): SelectOption<string>[] {
    return this._currencies.map(({ icon, name }) => ({
      value: `${icon} ${name}`,
      label: `${icon} ${name}`,
    }));
  }

  public get exportedFileId() {
    return this._exportedFileId;
  }

  constructor() {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async loadProductsList(
    filters?: FiltersType,
    headers?: ApiRequestHeaders,
    sortValues?: ProductSortingType,
  ) {
    if (!filters && sortValues && isEqual(sortValues, this.sortValues)) return;

    runInAction(() => {
      this._loading = true;
      if (filters) this.setFilters(filters);
      if (sortValues) this.setSortValues(sortValues);
    });

    const needGroup = this._view === ViewTypes.grid && this.filters.isThereMedia === undefined;
    const [{ success }, { success: resp2 }] = await Promise.all([
      getProducts
        .getRequest({
          queryParams: {
            ...this._getQueryParams(),
            ...this.sortValues,
            isPendingUpload: false,
            isThereMedia: needGroup ? true : this.filters.isThereMedia,
          },
          headers,
        })
        .fetch(),
      needGroup
        ? getProducts
            .getRequest({
              queryParams: {
                ...this._getQueryParams(),
                ...this.sortValues,
                isThereMedia: false,
                page: 1,
                limit: 20,
              },
              headers,
            })
            .fetch()
        : Promise.resolve({ success: null }),
    ]);

    runInAction(() => {
      if (success) {
        this._setProductsList(success);
      }

      if (resp2) {
        this._productsWithoutMediaAmount = resp2.filtered_items;
        this._productsWithoutMedia = resp2.rows.map((product) => this.extendProduct(product));
        this._productsWithoutMediaPage = 1;
      }

      this._loading = false;
    });
  }

  public async loadMoreProductsWithoutMedia(options: { resetPage?: boolean } = {}) {
    const { success } = await getProducts
      .getRequest({
        queryParams: {
          ...this._getQueryParams(),
          ...this.sortValues,
          isThereMedia: false,
          page: options.resetPage ? 1 : this._productsWithoutMediaPage + 1,
          limit: 20,
        },
      })
      .fetch();

    if (success) {
      const amount = success.filtered_items;
      if (success.rows.length > 0) {
        runInAction(() => {
          if (options.resetPage) {
            this._productsWithoutMediaPage = 1;
          } else {
            this._productsWithoutMediaPage += 1;
          }
          this._productsWithoutMediaAmount = amount;
          this._productsWithoutMedia.push(
            ...success.rows.map((product) => this.extendProduct(product)),
          );
        });
      } else {
        if (options.resetPage) {
          this._productsWithoutMediaPage = 1;
        } else {
          this._productsWithoutMediaPage += 1;
        }
        this._productsWithoutMediaAmount = amount;
      }
    }

    return success;
  }

  public async loadProductsPendingUploadList(page = 1, limit = 20) {
    const isPendingUpload = true;
    const { success } = await getProducts
      .getRequest({
        queryParams: {
          ...this._getQueryParams(),
          ...this.sortValues,
          page,
          limit,
          isPendingUpload,
        },
      })
      .fetch();

    if (success) {
      if (page === 1) {
        this._setProductsPendingUploadList(success);
      } else {
        const prevProductList = this._productsPendingUploadList;
        this._setProductsPendingUploadList(success);
        this._productsPendingUploadList = prevProductList.concat(this._productsPendingUploadList);
      }
    }
  }

  public async updateProductWithoutMedia(
    productId: ExtendedProductModel["_id"],
    data: ProductRequestModel,
  ) {
    const { success } = await putUpdateProduct
      .getRequest({
        params: { productId },
        data: data,
        queryParams: { ids: [productId] },
      })
      .fetch();
    if (success) {
      const product = this._productsWithoutMedia.find((product) => product._id === productId);
      if (product) {
        Object.assign(product, success.rows[0]);
      }
    }
  }

  public async deleteProductsWithoutMedia(
    products: MaybeArray<ExtendedProductModel | ExtendedProductModel["_id"]>,
  ) {
    const ids = toArrayOptions(products).map((product) => {
      return typeof product === "number" ? product : product._id;
    });
    const { success } = await deleteProducts
      .getRequest({
        queryParams: { ...this._getQueryParams(), isThereMedia: false, ids },
      })
      .fetch();

    if (success) {
      runInAction(() => {
        this._productsWithoutMediaAmount = success.filtered_items;
        ids.forEach((id) => {
          const index = this._productsWithoutMedia.findIndex((product) => product._id === id);
          if (index > -1) this._productsWithoutMedia.splice(index, 1);
        });
      });
    }
  }

  public async refreshProduct(product: ExtendedProductModel | ExtendedProductModel["_id"]) {
    const productId = typeof product === "number" ? product : product._id;
    const foundProduct = await this.getProductById(productId);

    runInAction(() => {
      this.toggleProductsList(foundProduct as ExtendedProductModel, "loading");
    });

    const result = await getProduct.getRequest({ params: { productId } }).fetch();

    runInAction(() => {
      this.toggleProductsList(foundProduct as ExtendedProductModel, "loading");
      if (foundProduct) Object.assign(foundProduct, result.success);
    });

    return result;
  }

  public async deleteProducts(
    products: MaybeArray<ExtendedProductModel | ExtendedProductModel["_id"]>,
  ) {
    const productsList = toArrayOptions(products);
    const ids = productsList.map((product) => {
      return typeof product === "number" ? product : product._id;
    });

    const foundedProducts = this.getProductsByIds(ids);

    runInAction(() => {
      this.toggleProductsList(foundedProducts, "loading");
    });

    const { success } = await deleteProducts
      .getRequest({
        queryParams: { ...this._getQueryParams(), ids },
      })
      .fetch();

    runInAction(() => {
      this.toggleProductsList(foundedProducts, "loading");

      if (success) {
        this._setProductsList(success);
      }
    });
  }

  public async deleteProductsPendingUpload(
    products: MaybeArray<ExtendedProductModel | ExtendedProductModel["_id"]>,
  ) {
    const isPendingUpload = true;
    const productsList = toArrayOptions(products);
    const ids = productsList.map((product) => {
      return typeof product === "number" ? product : product._id;
    });

    const { success } = await deleteProducts
      .getRequest({
        queryParams: {
          ...this._getQueryParams(),
          ids,
          isPendingUpload,
        },
      })
      .fetch();

    runInAction(() => {
      if (success) {
        this._totalProductsPendingUploadAmount = success.filtered_items;
        ids.forEach((id) => {
          const index = this._productsPendingUploadList.findIndex((product) => product._id === id);
          if (index > -1) this._productsPendingUploadList.splice(index, 1);
        });
      }
    });
  }

  public async createProduct(data: ProductRequestModel) {
    const result = await postProduct.getRequest({ data }).fetch();

    if (result.success) {
      runInAction(() => {
        this._setProductsList(result.success);
      });

      this.sendCreateProductEvent(result.success.total_items);
    }

    return result;
  }

  public async updateProduct(productId: ExtendedProductModel["_id"], data: ProductRequestModel) {
    const foundProduct = await this.getProductById(productId);
    if (!foundProduct) return;
    const extendedProduct = this.extendProduct(foundProduct);
    runInAction(() => {
      this.toggleProductsList(extendedProduct, "loading");
    });

    const result = await putUpdateProduct
      .getRequest({
        params: { productId },
        data,
        queryParams: { ...this._getQueryParams(), ids: [productId] },
      })
      .fetch();

    if (result.success) {
      const newProduct = result.success.rows[0];

      runInAction(() => {
        this.toggleProductsList(extendedProduct, "loading");
        if (foundProduct) Object.assign(foundProduct, newProduct);
      });
    }

    return result;
  }

  public async updateProductAttribute(
    productId: ProductModel["_id"],
    attributeId: ProductParameterModel["id"],
    value: ProductParameterModel["value"],
  ) {
    const result = await putUpdateProductAttribute
      .getRequest({
        params: { productId, attributeId },
        data: { value },
      })
      .fetch();

    return result;
  }

  public async updateProducts(
    productsToUpdate: ExtendedProductModel[],
    attributes: AttributeModel[],
  ) {
    const data = productsToUpdate.map((product) => ({
      ...this.getProductRequestPayload(product, attributes),
      id: product._id,
    }));

    const { success, error, details } = await putUpdateProducts
      .getRequest({
        data,
      })
      .fetch();

    if (success) {
      this.loadProductsList();
    }

    if (error) {
      return { error, details };
    }
  }

  public async loadCurrencies() {
    const { success, error } = await getCurrencies.getRequest().fetch();

    runInAction(() => {
      if (success) {
        this._currencies = success;
      }
    });

    return { success, error };
  }

  public async startExportProducts(
    data: ExportProductsRequestDataType,
    customAttributes: AttributeModel[],
  ): Promise<boolean> {
    let config: ExportProductsRequestDataType = data;

    if (data.format === ProductsExportType.pdf) {
      config = this._getPdfExportConfig(config, customAttributes);
    }

    const { success } = await postExportAllProducts.getRequest({ data: config }).fetch();

    runInAction(() => {
      if (success) {
        this._exportedFileId = success.export_id;
      }
    });

    return !!success;
  }

  public async loadExportedProductsFile(): Promise<ExportedProductsFileType | undefined> {
    const { success } = await getExportedProductsList.getRequest().fetch();

    if (success) {
      const index = success.rows.findIndex(({ id }) => id === this._exportedFileId);

      if (index !== -1) {
        return success.rows[index];
      }
    }
  }

  public async deleteExportedProductsFile(): Promise<void | boolean> {
    if (!this._exportedFileId) return;

    const { success } = await deleteExportedProductsList
      .getRequest({ params: { id: this._exportedFileId } })
      .fetch();

    runInAction(() => {
      if (success) {
        this._exportedFileId = null;
      }
    });

    return !!success;
  }
  /* Requests ↑ */

  /* UI State ↓ */
  public setFilters(filters: FiltersType) {
    this._filters = filters;
  }

  public resetFilters() {
    this._filters = this._initialFilters;
  }

  public setSortValues(values: ProductSortingType) {
    this._sortingValues = values;
  }

  public resetSortValues() {
    this._sortingValues = this._initialSortingValues;
  }

  public toggleProductsList(
    products: Nullable<MaybeArray<ExtendedProductModel>>,
    field: "selected" | "loading",
  ) {
    if (!products) return;
    toArrayOptions(products).forEach((product) => {
      product.extended[field] = !product.extended[field];
    });
  }

  public selectAllProductsList(field: "selected" | "loading") {
    this._productsList.forEach((product) => {
      product.extended[field] = true;
    });
  }

  public unselectAllProductsList(field: "selected" | "loading") {
    this._productsList.forEach((product) => {
      product.extended[field] = false;
    });
  }

  public unassignMediasFromProduct(
    mediaIds: MaybeArray<MediaModel["id"]>,
    productId: ProductModel["_id"],
  ) {
    const index = this._productsList.findIndex(({ _id }) => _id === productId);

    if (index !== -1) {
      const product = this._productsList[index];

      toArrayOptions(mediaIds).map((mediaId) => {
        const mediaIndex = product.images.findIndex(({ id }) => id === mediaId);

        if (mediaIndex !== -1) {
          product.images.splice(mediaIndex, 1);
        }
      });
    }
  }

  public updateProductsLinkPrivacyLocal(product: ExtendedProductModel, isPrivate: boolean) {
    product.link.isPrivate = isPrivate;
  }

  public resetStore(optionsToKeep?: {
    keepFilters?: boolean;
    keepLoading?: boolean;
    keepProductList?: boolean;
    keepTotalAmount?: boolean;
    keepResultsAmount?: boolean;
  }) {
    if (optionsToKeep) {
      this._filters = optionsToKeep.keepFilters ? this._filters : this._initialFilters;
      this._loading = optionsToKeep.keepLoading ? this._loading : false;
      this._productsList = optionsToKeep.keepProductList ? this._productsList : [];
      this._totalProductsAmount = optionsToKeep.keepTotalAmount ? this._totalProductsAmount : 0;
      this._resultsProductsAmount = optionsToKeep.keepResultsAmount
        ? this._resultsProductsAmount
        : 0;
    } else {
      this._filters = this._initialFilters;
      this._loading = false;
      this._productsList = [];
      this._totalProductsAmount = 0;
      this._resultsProductsAmount = 0;
    }
    this._productsPendingUploadList = [];
    this._totalProductsPendingUploadAmount = 0;
    this._productsWithoutMedia = [];
    this._productsWithoutMediaAmount = 0;
    this._productsWithoutMediaPage = 0;
  }

  /* UI State  ↑  */

  /* Helpers ↓ */
  public extendProduct(product: ProductModel): ExtendedProductModel {
    if (isObservable(product)) return product as ExtendedProductModel;
    return extendObservable<ProductModel, ExtendedProductModel>(product, {
      ...product,
      extended: {
        selected: false,
        loading: false,
      },
    });
  }

  public getProductRequestPayload(
    product: ExtendedProductModel,
    attributes: AttributeModel[],
  ): ProductRequestModel {
    const result: ProductRequestModel = {
      title: product.title,
    };
    const parameters: ProductParameterRequestModel[] = [];

    attributes.forEach((attribute) => {
      const existingParameter = product.parameters.find(({ id }) => id === attribute.id);
      if (!existingParameter) return;

      const parameter: ProductParameterRequestModel = {
        attribute_id: existingParameter.id,
        value: attribute.name === "title" ? product.title : existingParameter.value,
      };

      parameters.push(parameter);
    });

    result.parameters = parameters;

    return result;
  }

  public sendCreateProductEvent(totalItems: number) {
    getUserTagManagerInfoCallback((response) => {
      pushDataLayerEvent({
        event: "create_product",
        user_id: response.user_id,
        totalItems,
        account_type: response.account_type,
        is_trial: response.isTrial,
      });
    });
  }

  public async getProductById(productId: ExtendedProductModel["_id"]) {
    const product = this._productsList.find(({ _id }) => _id === productId);
    if (product) return product;

    const { success } = await getProduct.setParams({ productId: productId }).getRequest().fetch();
    return success;
  }

  public getProductsByIds(productIds: MaybeArray<ExtendedProductModel["_id"]>) {
    if (!this._productsList.length) return [];

    const products: ExtendedProductModel[] = [];

    toArrayOptions(productIds).forEach((productId) => {
      const product = this._productsList.find(({ _id }) => _id === productId);

      if (product) products.push(product);
    });

    return products;
  }

  private _setProductsList(response: PaginationList<ProductModel>) {
    this._totalProductsAmount = response.total_items;
    this._resultsProductsAmount = response.filtered_items;
    this._productsList = response.rows.map((product) => this.extendProduct(product));
  }

  private _setProductsPendingUploadList(response: PaginationList<ProductModel>) {
    this._totalProductsPendingUploadAmount = response.filtered_items;
    this._productsPendingUploadList = response.rows.map((product) => this.extendProduct(product));
  }

  private _getQueryParams(): ProductFiltersQueryType {
    const filtersCopy: ProductFiltersQueryType = toJS(this._filters);

    if (filtersCopy.user_id === "all") filtersCopy.user_id = null;
    if (filtersCopy.mediaType === "all") filtersCopy.mediaType = null;
    if (filtersCopy.productType === "all") filtersCopy.productType = null;

    if (filtersCopy.attributes.length) {
      const newAttributes: ProductParameterRequestModel[] = [];
      filtersCopy.attributes.forEach((attribute) => {
        if (attribute.type === "number") {
          const attributeNumberValues = attribute.value?.toString().split(", ") || [];
          newAttributes.push({
            attribute_id: attribute.attribute_id,
            from: Number(attributeNumberValues[0]),
          });
          newAttributes.push({
            attribute_id: attribute.attribute_id,
            to: Number(attributeNumberValues[1]),
          });
        } else {
          newAttributes.push({ attribute_id: attribute.attribute_id, value: attribute.value });
        }
      });
      filtersCopy.attributes = newAttributes;
    }
    return filtersCopy;
  }

  private _getPdfExportConfig(
    config: ExportProductsRequestDataType,
    customAttributes: AttributeModel[],
  ): ExportProductsRequestDataType {
    const attrs: AttributeModel[] = [];
    const maxCus = 4;

    customAttributes
      .sort((a, b) => a.order - b.order)
      .slice(0, maxCus)
      .forEach((attr) => attrs.push(attr));

    const attribute_ids = attrs.map(({ id }) => id);
    const attrColumns: ExportProductsRequestDataType["column_opts"] = attrs.map(
      ({ displayName, name, kind, id }, index) => ({
        displayName: displayName || name,
        position: index + 3, // 3 because custom columns come after 3rd column
        type: kind === "default" ? "prop" : "attribute",
        uniqueKey: kind === "default" ? name : id.toString(),
      }),
    );

    return {
      ...config,
      attribute_ids,
      column_opts: [
        {
          displayName: "Images",
          position: 0,
          type: "images",
          uniqueKey: "images",
        },
        {
          displayName: "SKU",
          position: 1,
          type: "prop",
          uniqueKey: "title",
        },
        {
          displayName: "Price",
          position: 2,
          type: "prop",
          uniqueKey: "price",
        },
        ...attrColumns,
        {
          displayName: "QR Code",
          position: attrColumns.length + 3,
          type: "custom",
          uniqueKey: "qrcode",
        },
      ],
    };
  }

  public clearExportedFileId() {
    this._exportedFileId = null;
  }
  /* Helpers ↑ */
}
