import React from "react";
import { observer } from "mobx-react-lite";
import { Typography, Info, SvgIcon, Button, Input, clsx, Webgl2New } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MeasurementTutorial } from "../../../measurement-tutorial";
import { editMediaStore } from "../../../edit-media.store";
import { GroupBlock } from "../../group-block";
import { AddColorButton } from "./add-color-button";
import { MeasurementColor } from "./measurement-color";

import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./measurement-item.module.css";

export type MeasurementItemProps = {
  index: number;
  measurement: Webgl2New.MeasurementComponent;
  className?: string;
  isReal?: boolean;
  onRemoveMeasurement?: (measurement: Webgl2New.MeasurementComponent) => void;
  onAddMeasurement?: (measurement: Webgl2New.MeasurementComponent) => void;
};

export const MeasurementItem: React.FC<MeasurementItemProps> = observer(
  ({
    index,
    measurement,
    className = "",
    isReal = false,
    onRemoveMeasurement,
    onAddMeasurement,
  }: MeasurementItemProps) => {
    const { localeStore } = useStores();

    const { renderer, measurementColors } = editMediaStore;

    const isSelected = measurement === editMediaStore.currentMeasurement;

    const handleSelectMeasureItemClick = (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget !== e.target) return;

      editMediaStore.currentMeasurement = isSelected
        ? editMediaStore.fantomMeasurement
        : measurement;
    };

    const handleChangeText = (value: string) => {
      measurement.changeText(value);
      renderer.render();
    };

    const handleRemoveMeasurement = () => onRemoveMeasurement?.(measurement);

    const handleAddMeasurement = () => onAddMeasurement?.(measurement);

    return (
      <GroupBlock
        className={clsx(styles.measurementItemBlockGroup, className)}
        onClick={handleSelectMeasureItemClick}
        selected={isSelected}
      >
        {isReal && (
          <Button
            className={styles.trashBtn}
            appearance="errorGhost"
            onClick={handleRemoveMeasurement}
          >
            <SvgIcon icon={TrashSVG} />
          </Button>
        )}

        <div className={styles.title} onClick={handleSelectMeasureItemClick}>
          <Typography size="small600" onClick={handleSelectMeasureItemClick}>
            {localeStore.t('["edit-media"].inspector.measurements["measurement-item"].title')}&nbsp;
            {index || ""}
          </Typography>
          {!index && (
            <Info position="right" size={16} withAngle>
              <MeasurementTutorial />
            </Info>
          )}
        </div>

        <Input
          appearance="primaryV2"
          placeholder={localeStore.t(
            '["edit-media"].inspector.measurements["measurement-item"].placeholder',
          )}
          maxLength={50}
          value={measurement.text}
          onChange={handleChangeText}
          disableError
        />

        {(isSelected || !isReal) && (
          <div className={styles.colorsContainer}>
            <div className={styles.colorsTitle}>
              <Typography size="extraSmall">
                {localeStore.t(
                  '["edit-media"].inspector.measurements["measurement-item"]["line-color"]',
                )}
              </Typography>
              <Typography size="extraSmall" color="textTertiary">
                {localeStore.t('["edit-media"]["max-10"]')}
              </Typography>
            </div>
            <div className={styles.colorsWrapper}>
              <AddColorButton measurement={measurement} />

              {measurementColors.map((color, index) => (
                <MeasurementColor
                  key={index}
                  color={color}
                  index={index}
                  measurement={measurement}
                />
              ))}
            </div>
          </div>
        )}

        {!isReal && (
          <Button
            appearance="secondary"
            onClick={handleAddMeasurement}
            disabled={!measurement.isValidPos}
          >
            {localeStore.t('["edit-media"].inspector.measurements["add-measurement-btn"]')}
          </Button>
        )}
      </GroupBlock>
    );
  },
);

export default MeasurementItem;
