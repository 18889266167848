import { makeAutoObservable, runInAction } from "mobx";

import { postAddHubspotData, PostHubspotData } from "src/api";

export class HubspotStore {
  constructor() {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async addHubspotData(data: PostHubspotData) {
    const { success } = await postAddHubspotData.getRequest({ data }).fetch();

    runInAction(() => {
      if (success) return success;
    });
  }
  /* Requests ↑ */
}
