import React from "react";
import "./index.scss";
import Aside from "src/components/aside";
import whiteLogo from "src/common/images/logo-white.svg";

const AuthAside = () => {
  return (
    <Aside>
      <div className="auth-aside">
        <div className="logo">
          <img src={whiteLogo} alt="" />

          <div className="logo-headline"> Picupmedia </div>
          <div className="logo-subheadline"> Welcome to The Jewelry Hub </div>
        </div>
      </div>
    </Aside>
  );
};

export default AuthAside;
