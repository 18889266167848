import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Tooltip, clsx, useBoolean, useDidMount } from "@gemlightbox/core-kit";

import { ProPlanBadge } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { IntervalChoicePicker } from "../interval-choice-picker";
import { SubaccountsOfferCounter } from "../subaccounts-offer-counter";
import { proPlanIdV2, subscriptionsData } from "../../../../subscriptions.constants";
import { SubscriptionType, SupportedSubscriptionInterval } from "../../../../subscriptions.types";
import { LifetimeOffer } from "./lifetime-offer";
import { YourPlanBadge } from "../your-plan-badge";
import { useNavigate } from "react-router-dom";
import { handleSubaccountsCheckNumber } from "./pro-offer.utils";

import contentStyles from "../../content.module.css";
import styles from "./pro-offer.module.css";
import { YearlyOffer } from "./yearly-offer";

export interface ProOfferProps {
  subscriptionToSelect: SupportedSubscriptionInterval;
  extraCount: number;
  activeSubaccountAmount: number;
  setSubscriptionToSelect: (
    interval: SupportedSubscriptionInterval,
    plan: "starter" | "pro",
  ) => void;
  setExtraCount: React.Dispatch<React.SetStateAction<number>>;
  onOpenBilling: VoidFunction;
  className?: string;
  canDisplayAddons?: boolean;
}

export const ProOffer: React.FC<ProOfferProps> = observer(
  ({
    subscriptionToSelect,
    extraCount,
    activeSubaccountAmount,
    setSubscriptionToSelect,
    setExtraCount,
    onOpenBilling,
    className = "",
    canDisplayAddons = true,
  }: ProOfferProps) => {
    const { subscriptionsStore, localeStore, subaccountsStore, notificationStore } = useStores();

    const {
      proSubscriptionInterval,
      subGbAddonsAmount,
      proPlan,
      proPlanV2,
      proPlanPrices,
      subaccountAddonPrices,
      isFreeSubscription,
      isStarterSubscription,
      isProMonthlySubscription,
      isProYearlySubscription,
      isCustomSubscription,
      isProLifetimeSubscription,
      currentPlan,
    } = subscriptionsStore;

    const tooltipBoolean = useBoolean();
    const lodingBoolean = useBoolean(false);
    const selectButtonRef = useRef<HTMLButtonElement>(null);

    const matchPlan = currentPlan?.plan_id === proPlanIdV2 ? proPlanV2 : proPlan;

    const planSubaccountsUnit = Number(matchPlan?.metadata?.subaccounts || 0);
    const planStorageUnit = Number(matchPlan?.metadata?.limit_storage || 0);

    const handleSubtractExtraCount = () => setExtraCount((prevState) => Math.max(prevState - 1, 0));

    const handleAddExtraCount = () => setExtraCount((prevState) => prevState + 1);

    const handleSelectPlan = (type: SupportedSubscriptionInterval, disable = false) => {
      return () => {
        if (isCustomSubscription || disable) return;
        setSubscriptionToSelect(type, "pro");
      };
    };

    const navigate = useNavigate();
    const handleOpenBilling = () => {
      if (subGbAddonsAmount > extraCount) {
        handleSubaccountsCheckNumber(
          extraCount,
          () => navigate("/settings/subaccounts"),
          onOpenBilling,
        );
      } else {
        onOpenBilling();
      }
    };

    const isSelectButtonDisabled =
      isCustomSubscription ||
      (!isFreeSubscription &&
        subGbAddonsAmount === extraCount &&
        proSubscriptionInterval === subscriptionToSelect);

    const shouldRenderTooltip =
      isStarterSubscription && activeSubaccountAmount > extraCount + planSubaccountsUnit;

    const isSubscriptionUpgrade =
      subscriptionToSelect !== proSubscriptionInterval &&
      (proSubscriptionInterval === undefined ||
        SubscriptionType[subscriptionToSelect as keyof typeof SubscriptionType] >
          SubscriptionType[proSubscriptionInterval as keyof typeof SubscriptionType]);
    const isSubscriptionDowngrade =
      subscriptionToSelect !== proSubscriptionInterval &&
      proSubscriptionInterval !== undefined &&
      SubscriptionType[subscriptionToSelect as keyof typeof SubscriptionType] <
        SubscriptionType[proSubscriptionInterval as keyof typeof SubscriptionType];
    const isDowngrade =
      (subGbAddonsAmount > extraCount && !isSubscriptionUpgrade) || isSubscriptionDowngrade;
    const isUpgrade =
      (subGbAddonsAmount < extraCount && !isSubscriptionDowngrade) || isSubscriptionUpgrade;

    useDidMount(async () => {
      await subaccountsStore.loadSubaccounts();
    });

    return (
      <div className={clsx(styles.proOfferItem, className)}>
        {(isProLifetimeSubscription || isProYearlySubscription) && <YourPlanBadge />}
        <div className={styles.proOfferHeader}>
          <div className={styles.proOfferHeaderWrapper}>
            <ProPlanBadge
              split
              hideInterval={!isProLifetimeSubscription && !isProYearlySubscription}
              secondaryTextColor
            />

            <div className={styles.proOfferHeaderContent}>
              {!isProLifetimeSubscription && !isProYearlySubscription && (
                <IntervalChoicePicker
                  subscriptionToSelect={subscriptionToSelect}
                  extraCount={extraCount}
                  isMonthlySubscription={isProMonthlySubscription}
                  isYearlySubscription={isProYearlySubscription}
                  isCustomSubscription={isCustomSubscription}
                  planPrices={proPlanPrices}
                  onSelectPlan={handleSelectPlan}
                />
              )}
              {isProLifetimeSubscription || isProYearlySubscription ? (
                <>
                  {isProLifetimeSubscription ? (
                    <LifetimeOffer extraCount={extraCount} />
                  ) : (
                    <YearlyOffer extraCount={extraCount} />
                  )}
                  <Button
                    appearance={isSelectButtonDisabled ? "secondaryOutlined" : "primary"}
                    className={styles.upgradeButton}
                    disabled={isSelectButtonDisabled}
                    onClick={handleOpenBilling}
                  >
                    {isSelectButtonDisabled
                      ? localeStore.t("settings.subscriptions.components.content.buttons.current")
                      : isDowngrade
                      ? localeStore.t("settings.subscriptions.components.content.buttons.downgrade")
                      : localeStore.t("settings.subscriptions.components.content.buttons.upgrade")}
                  </Button>
                </>
              ) : (
                <Button
                  loading={lodingBoolean.value}
                  appearance={isDowngrade || isUpgrade ? "primary" : "secondaryOutlined"}
                  className={clsx(
                    styles.upgradeButton,
                    shouldRenderTooltip ? styles.disabled : undefined,
                  )}
                  forwardRef={selectButtonRef}
                  disabled={isSelectButtonDisabled}
                  onClick={shouldRenderTooltip ? undefined : handleOpenBilling}
                  onMouseEnter={tooltipBoolean.setTruthy}
                  onMouseLeave={tooltipBoolean.setFalsy}
                >
                  {isUpgrade
                    ? localeStore.t("settings.subscriptions.components.content.buttons.upgrade")
                    : isDowngrade
                    ? localeStore.t("settings.subscriptions.components.content.buttons.downgrade")
                    : localeStore.t("settings.subscriptions.components.content.buttons.current")}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={contentStyles.offerContent}>
          {canDisplayAddons && (
            <SubaccountsOfferCounter
              extraCount={extraCount}
              planSubaccountsUnit={planSubaccountsUnit}
              planStorageUnit={planStorageUnit}
              isCustomSubscription={isCustomSubscription}
              subaccountAddonPrices={subaccountAddonPrices}
              onAddExtraCount={handleAddExtraCount}
              onSubtractExtraCount={handleSubtractExtraCount}
              subscriptionToSelect={subscriptionToSelect}
            />
          )}
          <div className={contentStyles.featureOfferListWrapper}>
            {subscriptionsData.features.map(({ proPlanAmount }, index) => (
              <div key={index} className={contentStyles.feature}>
                {proPlanAmount}
              </div>
            ))}
          </div>
        </div>
        <Tooltip
          isOpen={tooltipBoolean.value}
          target={shouldRenderTooltip ? selectButtonRef : undefined}
          onClose={tooltipBoolean.setFalsy}
          position="leftTop"
          withAngle
        >
          <div className={styles.tooltipContent}>
            {localeStore.t('settings.subscriptions.components.content["change-plan-tooltip"]')}
          </div>
        </Tooltip>
      </div>
    );
  },
);

export default ProOffer;
