import { useStores } from "src/hooks";
import { AttributeModel } from "src/models";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export const getAttributePlaceholder = (
  { name, displayName }: AttributeModel,
  isSelect = false,
) => {
  const { localeStore } = useStores();

  const placeholder =
    (isSelect
      ? localeStore.t('["product-attributes"]["attribute-field"]["select-placeholder"]')
      : localeStore.t('["product-attributes"]["attribute-field"]["enter-placeholder"]')) + " ";

  const dName = localeStore.t(
    `products["products-list"]["products-table"]["products-table-header"].${displayName
      ?.replace(" ", "")
      .toLowerCase()}` as LocaleCodeTypes,
  );
  if (displayName) return placeholder + dName;

  return placeholder + name;
};
