import React from "react";
import { observer } from "mobx-react-lite";
import { Nullable, Radio, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { chainTypes } from "../media-editor.constants";

import styles from "./chain-template-selector.module.css";

export type ChainTemplateSelectorProps = {
  templateIndex: Nullable<number>;
  onTemplateIndexChange: (index: number) => void;
};

export const ChainTemplateSelector: React.FC<ChainTemplateSelectorProps> = observer(
  ({ templateIndex, onTemplateIndexChange }) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.container}>
        <Typography tag="div" size="small600">
          {localeStore.t('["ar-media"]["media-editor"]["chain-template-selector"].title')}
        </Typography>

        <div className={styles.list}>
          {chainTypes.map(({ label, gradient }, i) => (
            <div key={i} className={styles.item} onClick={() => onTemplateIndexChange(i)}>
              <Radio checked={i === templateIndex} onChange={() => null} disableError />
              <div className={styles.template} style={{ background: gradient }}>
                {label}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  },
);
