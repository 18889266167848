import React from "react";
import { observer } from "mobx-react-lite";
import { Modal, Form, FormField, Button, Heading } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { FORM_ID, initialValues } from "./create-catalog-modal.constants";
import { createCatalogValidation } from "../../../../create-catalog";

import styles from "./create-catalog-modal.module.css";

export interface CreateCatalogModalProps {
  isOpen: boolean;
  loading: boolean;
  setClose: () => void;
  handleCreate: (data: any) => Promise<void>;
}

export const CreateCatalogModal: React.FC<CreateCatalogModalProps> = observer(
  ({ isOpen, setClose, handleCreate, loading }) => {
    const { localeStore } = useStores();

    return (
      <Modal
        portalClassName={styles.modalContainer}
        contentClassName={styles.modalContent}
        size="medium"
        isOpen={isOpen}
        setClose={setClose}
        withCross
      >
        <Heading className={styles.modalHeader} tag="h2" color="textSecondary">
          {localeStore.t('catalog["add-to-catalog-sidebar"]["create-catalog-modal"].title')}
        </Heading>
        <Form
          formId={FORM_ID}
          initialValues={initialValues}
          onSubmit={handleCreate}
          validate={createCatalogValidation}
          disabled={loading}
          enableReinitialize
        >
          <div className={styles.formFields}>
            <FormField
              type="text"
              name="name"
              label={localeStore.t(
                'catalog["add-to-catalog-sidebar"]["create-catalog-modal"].fields.name.label',
              )}
              placeholder={localeStore.t(
                'catalog["add-to-catalog-sidebar"]["create-catalog-modal"].fields.name.placeholder',
              )}
              appearance="primaryV2"
              required
            />
            <FormField
              type="textarea"
              name="description"
              label={localeStore.t(
                'catalog["add-to-catalog-sidebar"]["create-catalog-modal"].fields.description.label',
              )}
              placeholder={localeStore.t(
                'catalog["add-to-catalog-sidebar"]["create-catalog-modal"].fields.description.placeholder',
              )}
              appearance="primaryV2"
            />
          </div>
        </Form>
        <div className={styles.buttonsContainer}>
          <Button appearance="secondaryOutlined" onClick={setClose}>
            {localeStore.t(
              'catalog["add-to-catalog-sidebar"]["create-catalog-modal"].buttons.cancel',
            )}
          </Button>
          <Button type="submit" form={FORM_ID} loading={loading}>
            {localeStore.t(
              'catalog["add-to-catalog-sidebar"]["create-catalog-modal"].buttons.create',
            )}
          </Button>
        </div>
      </Modal>
    );
  },
);
