import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";

import styles from "./products-pending-upload-card.module.css";
import { NUMBER_OF_PRODUCT_TO_PREVIEW, ProductsPreview } from "../../products-preview";

type ProductsPendingUploadCardProps = {
  style?: React.CSSProperties;
};

export const ProductsPendingUploadCard: React.FC<ProductsPendingUploadCardProps> = observer(
  ({ style }) => {
    const { productsStore, modalsStore, localeStore } = useStores();

    const handleCardClick = () => modalsStore.open("PendingUploadProductsSidebar", {});

    return (
      <div className={styles.container} style={style} onClick={handleCardClick}>
        <div className={styles.imageContainer}>
          <div className={styles.imageFiller} />
          <ProductsPreview
            className={styles.mediaPreview}
            view="grid"
            totalAmount={productsStore.totalProductsPendingUploadAmount}
            productToShow={productsStore.productsPendingUploadList.slice(
              0,
              NUMBER_OF_PRODUCT_TO_PREVIEW,
            )}
          />
        </div>
        <div className={styles.title}>
          {localeStore.t('products["products-list"]["pending-upload"].title')}
        </div>
      </div>
    );
  },
);

export default ProductsPendingUploadCard;
