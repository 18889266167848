import React, { useState } from "react";
import { clsx, Nullable, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { OrderSortDirectionType, OrderTableSortValueType } from "src/store";
import { TableSortButtons } from "./table-sort-buttons";
import { paymentsTableColumns } from "../../payments.page.constants";

import styles from "./payments-table-head.module.css";

export const PaymentsTableHead: React.FC = () => {
  const paymentSystem = window.location.search.substring(1);

  const { ordersStore } = useStores();

  const [activeSortAngleIcon, setActiveSortAngleIcon] =
    useState<Nullable<{ columnName: OrderTableSortValueType; sortOrder: OrderSortDirectionType }>>(
      null,
    );

  const handleSort = async (
    columnName: OrderTableSortValueType,
    sortOrder: OrderSortDirectionType,
  ) => {
    const sortValues = { sortBy: sortOrder, orderBy: columnName };
    await ordersStore.loadOrderList(paymentSystem, sortValues);
    setActiveSortAngleIcon({ columnName, sortOrder });
  };

  return (
    <thead className={styles.paymentsTableHeader}>
      <tr>
        {paymentsTableColumns.map(({ name, label, isSortable }) => {
          return (
            <th key={name} className={styles[name]}>
              <div className={clsx({ [styles.sortable]: isSortable })}>
                <Typography size="extraSmall" color="textTertiary">
                  {label}
                </Typography>
                {isSortable && (
                  <TableSortButtons
                    columnName={name as OrderTableSortValueType}
                    activeSortAngleIcon={activeSortAngleIcon}
                    handleSort={handleSort}
                  />
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
