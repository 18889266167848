import React, { useEffect, useState } from "react";

import styles from "./index.module.css";

type TimeDelayTipComponentProps = {
  isOpened: boolean;
  text: string;
};

const TimeDelayTipComponent: React.FC<TimeDelayTipComponentProps> = ({ text, isOpened }) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(isOpened);
  }, [isOpened]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default TimeDelayTipComponent;
