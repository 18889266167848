import { makeAutoObservable, runInAction } from "mobx";

import { GetCTFStatusResponse, getCTFStatus, postCTFInstall, putCTFSync } from "src/api";
import { ExtendedProductModel } from "../products";

export class CTFStore {
  private _loading = false;
  private _status: GetCTFStatusResponse = {
    error: 0,
    pending: 0,
    syncing: 0,
    completed: 0,
    total: 0,
    syncStatus: null,
    lastSyncStarted: null,
    lastSyncCompleted: null,
  };

  public get loading() {
    return this._loading;
  }

  public get status() {
    return this._status;
  }

  constructor() {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async installCTFDirectory(directory: string) {
    const response = await postCTFInstall.getRequest({ data: { directory } }).fetch();

    return response;
  }

  public async startCTFSync(products: ExtendedProductModel[]) {
    runInAction(() => {
      this._loading = true;
    });

    const productIds = products.map((product) => product._id);

    const response = await putCTFSync
      .getRequest({ data: { selectedProducts: productIds } })
      .fetch();

    this._loading = false;

    return response;
  }

  public async loadCTFStatus() {
    const { success, error } = await getCTFStatus.getRequest().fetch();
    if (success) {
      runInAction(() => {
        Object.assign(this._status, success);
      });
    } else {
      return error;
    }
  }
  /* Requests ↑ */

  /* UI State ↓ */
  /* UI State  ↑  */

  /* Helpers ↓ */
  /* Helpers ↑ */
}
