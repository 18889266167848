import React from "react";
import { observer } from "mobx-react-lite";
import { clsx, FormField } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./create-catalog-form-fields.module.css";

export const CreateCatalogFormFields = observer(() => {
  const { localeStore } = useStores();

  return (
    <div className={styles.formFields}>
      <FormField
        className={styles.catalogFormField}
        type="text"
        name="name"
        label={localeStore.t('["create-catalog"].fields.name.label')}
        labelMessage={localeStore.t('["create-catalog"].fields.name["label-message"]')}
        placeholder={localeStore.t('["create-catalog"].fields.name.placeholder')}
        appearance="primaryV2"
        messageAlign="right"
        data-cy="create-catalog-name-field"
        required
      />
      <FormField
        inputWrapperClassName={clsx(styles.catalogFormField, styles.catalogDescriptionField)}
        type="textarea"
        name="description"
        label={localeStore.t('["create-catalog"].fields.description.label')}
        placeholder={localeStore.t('["create-catalog"].fields.description.placeholder')}
        appearance="primaryV2"
        data-cy="create-catalog-description-field"
        disableError
      />
    </div>
  );
});
