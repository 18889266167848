import React from "react";
import { SvgIcon, Heading, Search } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { SelectedFiltersPanel } from "../../../catalog-add-products/selected-filters-panel";
import { SelectedProductsPanel } from "../../../catalog-add-products/selected-products-panel";
import { ProductsListPlaceholder } from "../../../catalog-add-products/products-list-placeholder";
import { ProductsList } from "../../../catalog-add-products/products-list";
import { ReactComponent as FiltersSVG } from "src/external-ts/assets/images/media/filter-grey.svg";

import styles from "./products-list-wrapper.module.css";

interface ProductsListWrapperProps {
  handleSearch: (title: string) => void;
  handleOpenFilters: () => Promise<void>;
}

export const ProductsListWrapper: React.FC<ProductsListWrapperProps> = ({
  handleSearch,
  handleOpenFilters,
}) => {
  const { productsStore, localeStore } = useStores();

  return (
    <div className={styles.container} data-cy="create-catalog-products-list-wrapper">
      <Heading className={styles.header} tag="h4" color="textSecondary">
        {localeStore.t('["create-catalog"]["create-manual-catalog"]["product-list-wrapper"].title')}
      </Heading>
      <div className={styles.filtersContainer}>
        <Search
          className={styles.search}
          placeholder={localeStore.t("components.business.search.placeholder")}
          searchValue={productsStore.filters.title}
          data-cy="create-catalog-search"
          setSearch={handleSearch}
        />
        <div
          className={styles.filtersButtonContainer}
          data-cy="create-catalog-filters"
          onClick={handleOpenFilters}
        >
          <SvgIcon icon={FiltersSVG} />
        </div>
      </div>
      <SelectedProductsPanel />
      <SelectedFiltersPanel />

      {productsStore.productsAmount === 0 && <ProductsListPlaceholder />}
      {productsStore.productsAmount !== 0 && (
        <ProductsList className={styles.createCatalogProducts} />
      )}
    </div>
  );
};
