import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";

import checkmarkImg from "../../../../../common/images/checkmark-primary.svg";
import warningImg from "../../../../../common/images/warning.svg";
import IntegrationStatusLogo from "../IntegrationStatusLogo";
import IntegrationStatusOptions from "../IntegrationStatusOptions";
import { useSelector } from "react-redux";
import { SvgIcon, Typography, useWindowSize, clsx } from "@gemlightbox/core-kit";
import styles from "./styles.module.scss";

const IntegrationStatusCompletedState = observer(
  ({ logo, title, options, onOptionClick, onClick }) => {
    const { localeStore } = useStores();
    const { completedProducts, errorProducts, totalProducts } = useSelector(
      ({ shopifyReducer }) => shopifyReducer,
    );
    const { windowWidth } = useWindowSize();

    const renderProgress = () => (
      <div className="complete-label">
        {completedProducts}/{totalProducts}{" "}
        {localeStore.t(
          'settings.integrations["integration-status-bar"]["integration-status-syncing-state"].items',
        )}
      </div>
    );

    const renderSuccessText = () => (
      <div className={styles.successText}>
        <div className="success-label label">
          {localeStore.t('settings.integrations["integration-status-bar"]["completed-state"]')}
        </div>
        <img src={checkmarkImg} alt="" />
      </div>
    );

    const renderErrorText = () => (
      <div className="error-label">
        <img src={warningImg} alt="" />
        <div className="error-text">
          {errorProducts}{" "}
          {localeStore.t('settings.integrations["integration-status-bar"]["error-state"]')}
        </div>
        <a className="retry-btn" href="javascript:void(0);" onClick={() => {}}>
          {localeStore.t('camera["save-background"]["retry-btn"]')}
        </a>
      </div>
    );

    return (
      <>
        {windowWidth <= 840 && (
          <div className={styles.title} onClick={onClick}>
            <SvgIcon icon={logo} size={36} />
            <Typography size="medium600">{title}</Typography>
          </div>
        )}
        {windowWidth > 840 && <IntegrationStatusLogo src={logo} />}

        <div className={clsx("integration-status-data", styles.desc)}>
          {windowWidth > 840 && (
            <div className="integration-status-data__title label complete-state" onClick={onClick}>
              {title}
              {!!errorProducts && renderProgress()}
            </div>
          )}

          {windowWidth > 840 && (
            <div className="integration-status-data__description" onClick={onClick}>
              {!errorProducts ? renderSuccessText() : renderErrorText()}
            </div>
          )}

          {windowWidth <= 840 && (
            <>
              <div className={styles.text} onClick={onClick}>
                <div className="complete-state">{!!errorProducts && renderProgress()}</div>
                <div className="integration-status-data__description">
                  {!errorProducts ? renderSuccessText() : renderErrorText()}
                </div>
              </div>
              <IntegrationStatusOptions options={options} onOptionClick={onOptionClick} />
            </>
          )}
        </div>

        {windowWidth > 840 && (
          <IntegrationStatusOptions options={options} onOptionClick={onOptionClick} />
        )}
      </>
    );
  },
);

export default IntegrationStatusCompletedState;
