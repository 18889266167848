import React, { useState, useEffect, useRef, Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Button, Heading, SvgIcon, Typography, useCall, useFetch } from "@gemlightbox/core-kit";

import { EarringsCard } from "./earrings-card";
import { getGalleryInfo, putUpdateGalleryInfo } from "src/api";
import { useStores } from "src/hooks";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";
import styles from "./ar-settings.module.css";

export const ArSettings = observer(() => {
  const [isChanged, setChanges] = useState(false);
  const divRef = useRef<HTMLButtonElement>(null);

  const { tooltipStore, localeStore, notificationStore } = useStores();

  const { payload } = useFetch(getGalleryInfo);
  const ar_earrings_cropping = payload?.ar_earrings_cropping;

  const [areEarringsCropping, setEarringsCropping] = useState(ar_earrings_cropping);

  useEffect(() => {
    setEarringsCropping(ar_earrings_cropping);
  }, [ar_earrings_cropping]);

  const onSizeChange = (value: boolean) => () => {
    setChanges(true);
    setEarringsCropping(value);
  };

  const onCancel = () => {
    setEarringsCropping(ar_earrings_cropping);
    setChanges(false);
  };

  const useUpdateGallery = useCall(putUpdateGalleryInfo);
  useUpdateGallery.onCallSuccess(() => {
    tooltipStore.open(
      <Fragment>
        <SvgIcon className={styles.checkmarkIcon} icon={CheckmarkSVG} size={16} />
        <Typography size="extraSmall500" color="textSecondary" className={styles.message}>
          {localeStore.t('settings["ar-settings"]["success-tooltip"]')}
        </Typography>
      </Fragment>,
      {
        appearance: "primary",
        position: "top",
        className: styles.tooltip,
        target: divRef,
        withAngle: true,
      },
      { timeout: 3000 },
    );
  });
  useUpdateGallery.onCallError((error) => {
    notificationStore.open({
      title: localeStore.t('settings["ar-settings"]["error-tooltip"].title'),
      message: `${localeStore.t('settings["ar-settings"]["error-tooltip"].description')}: ${
        error.formattedMessage
      }`,
      confirmText: localeStore.t("common.buttons.confirm"),
      cancelText: "",
      confirmAppearance: "primary",
      onlyConfirm: true,
    });
  });

  const updateGalleryInfo = () => {
    setChanges(false);
    const formData = new FormData();
    formData.append("ar_earrings_cropping", (!!areEarringsCropping).toString());
    useUpdateGallery.submit({
      data: formData,
    });
  };

  return (
    <div className={styles.container}>
      <Heading tag="h2" color="textSecondary">
        {localeStore.t('settings["ar-settings"].title')}
      </Heading>
      <div className={styles.earringsContainer}>
        <EarringsCard appearance="big" checked={!areEarringsCropping} onCheck={onSizeChange} />
        <EarringsCard appearance="cropped" checked={!!areEarringsCropping} onCheck={onSizeChange} />
      </div>
      <div className={styles.submitButtonsContainer}>
        <Button appearance="secondaryOutlined" onClick={onCancel} disabled={!isChanged}>
          {localeStore.t("common.buttons.cancel")}
        </Button>
        <Button type="submit" onClick={updateGalleryInfo} disabled={!isChanged} forwardRef={divRef}>
          {localeStore.t('common.buttons["save-changes"]')}
        </Button>
      </div>
    </div>
  );
});
