import JsCrypto from "crypto-js";
import {
  imageSourceToCanvas,
  base64ToBlob,
  loadImage,
  cropImage,
  Vec2,
  uniqId,
  CanvasDrawImageSource,
  ObjectType,
} from "@gemlightbox/core-kit";

import { config } from "src/config/environment";
import {
  getUserTagManagerInfoCallback,
  PostRemoveBackgroundResponseData,
  postRemoveBackgroundV4,
} from "src/api";
import { pushDataLayerEvent, combineImageAlpha } from "src/utils";
import { ErrorCodes } from "src/constants";
import { useStores } from "src/hooks";
import { openBackgroundRemovalErrorNotification } from "src/containers/media/media.utils";

type RemoveImageBackgroundOptions = {
  cropped?: boolean;
  directRequest?: boolean;
  rawBoundary?: boolean;
};

export const removeImageBackground = async (
  image: CanvasDrawImageSource,
  options: RemoveImageBackgroundOptions = {},
  media_ids?: number[],
): Promise<{
  image: CanvasDrawImageSource;
  sizes: Omit<PostRemoveBackgroundResponseData, "base64_str" | "statusCode">;
  centerDiff: Vec2;
}> => {
  return new Promise((resolve, reject) => {
    const { directRequest = false, cropped = false } = options;

    const { userStore } = useStores();
    const userId = userStore.userMe?.user._id;

    const canvas = imageSourceToCanvas(image);
    const mainImageBase64 = canvas.toDataURL();
    const mainImageBlob = base64ToBlob(mainImageBase64.split(",")[1], "image/png");

    const mainImageFile = new File([mainImageBlob], "remove-background-image", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("files", mainImageFile);
    formData.append("isBoundReturn", "true");
    if (options.rawBoundary) {
      formData.append("isRawBoundary", options.rawBoundary + "");
    }

    const headers: ObjectType = {};

    if (directRequest && userId) {
      const now = Date.now();
      const token = {
        t: config.crypto_auth_token,
        r: uniqId().slice(0, 10),
        st: now,
        ts: now,
      };

      const encrypted = JsCrypto.AES.encrypt(JSON.stringify(token), config.crypto_auth_key);
      headers["authorization"] = `Token ${encrypted.toString()}`;
      headers["user_id"] = userId;
    }

    let request = null;

    if (media_ids && media_ids?.length > 0) {
      request = postRemoveBackgroundV4.getRequest({
        data: { media_ids: media_ids },
      });
    } else {
      request = postRemoveBackgroundV4.getRequest({ data: formData, headers });
    }

    request.events.on("success", async ({ success }) => {
      const { statusCode, base64_str, ...rest } = success;

      if (statusCode !== 200) {
        return reject((success as any)?.errorMessage || "Something went wrong");
      }

      if (!directRequest) {
        getUserTagManagerInfoCallback((response) => {
          pushDataLayerEvent({
            event: "remove_bg_ai",
            user_id: response.user_id,
            account_type: response.account_type,
            is_trial: response.isTrial,
          });
        });

        // Note: silently updating userMe due to usage limits
        userStore.loadUserMeSilently();
      }

      // NOTE: format check is kinda crutch because v3 version returns base64 without this prepend info
      // whereas native request returns is with this info.
      const format = "data:image/png;base64,";
      const imageBase64 = base64_str.startsWith(format) ? base64_str : format + base64_str;
      const removedBgBase64 = await combineImageAlpha(mainImageBase64, imageBase64);
      let removedBgImage: CanvasDrawImageSource = await loadImage(removedBgBase64 as string);
      if (cropped) {
        removedBgImage = cropImage(removedBgImage, rest.dx, rest.dy, rest.dWidth, rest.dHeight);
      }

      const centerDiff = new Vec2(
        canvas.width / 2 - (rest.dx + rest.dWidth / 2),
        canvas.height / 2 - (rest.dy + rest.dHeight / 2),
      );

      resolve({ image: removedBgImage, sizes: rest, centerDiff });
    });

    request.events.on("error", ({ error }) => {
      if (error.originalError?.code === ErrorCodes.MEDIA_BACKGROUND_REMOVE_LIMIT_EXCEEDED) {
        openBackgroundRemovalErrorNotification(error.originalError?.message);
      }
      reject(error);
    });

    request.fetch();
  });
};
