import React from "react";
import { observer } from "mobx-react-lite";
import { Typography, Checkbox, Image } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getStaticUrl } from "src/utils";

import styles from "./earrings-card.module.css";

export interface EarringsCardProps {
  appearance: "big" | "cropped";
  checked: boolean;
  onCheck: (value: boolean) => () => void;
}

export const EarringsCard: React.FC<EarringsCardProps> = observer(
  ({ appearance, checked, onCheck }) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.container} onClick={onCheck(appearance === "cropped")}>
        <div className={styles.header}>
          <Checkbox
            appearance="secondary"
            checked={checked}
            onChange={onCheck(appearance === "cropped")}
            className={styles.checkbox}
          />
        </div>
        <div className={styles.content}>
          {appearance === "big" ? (
            <>
              <Image
                className={styles.icon}
                src={getStaticUrl("/earring-in-front-face.png")}
                size={[122, 119]}
              />
              <Typography size="medium600" color="textSecondary" className={styles.typography}>
                {localeStore.t('settings["ar-settings"]["earrings-card"].front.top')} <br />
                {localeStore.t('settings["ar-settings"]["earrings-card"].front.bottom')}
              </Typography>
            </>
          ) : (
            <>
              <Image
                className={styles.icon}
                src={getStaticUrl("/earring-behind-face.png")}
                size={[122, 119]}
              />
              <Typography size="medium600" color="textSecondary" className={styles.typography}>
                {localeStore.t('settings["ar-settings"]["earrings-card"].behind')}
              </Typography>
            </>
          )}
        </div>
      </div>
    );
  },
);
