import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Heading, Modal, Typography, useWindowSize } from "@gemlightbox/core-kit";

import { ExtendedProductModel, ModalExtendedType } from "src/store";
import { useStores } from "src/hooks";

import styles from "./delete-product-modal.module.css";
import { PendingUploadStatus } from "src/models";

export type DeleteProductModalProps = ModalExtendedType<{
  products: Array<ExtendedProductModel | ExtendedProductModel["_id"]>;
  onDelete?: VoidFunction;
  deleteFunc?: VoidFunction;
}>;

export const DeleteProductModal: React.FC<DeleteProductModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { products } = options;

    const { productsStore, localeStore } = useStores();

    const { windowWidth } = useWindowSize();

    const productsAmount = options.products.length === 1 ? "single" : "multi";

    const isPendingUpload = (product: ExtendedProductModel | ExtendedProductModel["_id"]) => {
      return typeof product === "number"
        ? false
        : product?.images?.findIndex((f) => f?.status === PendingUploadStatus.pending) >= 0;
    };

    const isUploading = products.findIndex((p) => isPendingUpload(p)) >= 0;

    const handleSubmit = () => {
      if (!options.deleteFunc) {
        productsStore.deleteProducts(products);
      } else {
        options.deleteFunc();
      }
      options.onDelete?.();
      setClose();
    };

    return (
      <Modal
        contentClassName={styles.modal}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        withCross
        disablePadding
        disableBorderRadius
      >
        <Heading
          className={styles.title}
          tag={windowWidth > 375 ? "h2" : "h3"}
          color="textSecondary"
        >
          {localeStore.t(`products.modals.delete.title.${productsAmount}`)}
        </Heading>
        {isUploading ? (
          <Typography className={styles.article} size="medium" color="textTertiary">
            {localeStore.t('products["products-list"]["pending-upload"]["delete-product"]')}
          </Typography>
        ) : (
          <>
            <Typography className={styles.article} size="medium" color="textTertiary">
              {localeStore.t(`products.modals.delete.description.${productsAmount}`)}
            </Typography>
            <Typography className={styles.warning} size="small600" color="textSecondary">
              {localeStore.t(`products.modals.delete.strong.${productsAmount}`)}
            </Typography>
          </>
        )}

        <div className={styles.buttons}>
          <Button
            data-cy="product-delete-cancel-button"
            appearance="tertiaryOutlined"
            onClick={setClose}
          >
            {localeStore.t("products.modals.delete.buttons.cancel")}
          </Button>
          <Button data-cy="product-delete-confirm-button" appearance="error" onClick={handleSubmit}>
            {localeStore.t("products.modals.delete.buttons.delete")}
          </Button>
        </div>
      </Modal>
    );
  },
);
