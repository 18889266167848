import styles from "./variant-table-row.module.css";

export const variantSupportedAttributes = [
  {
    className: styles.size136CellContainer,
    name: "price",
    placeholder: "$ USD 0.00",
  },
  {
    className: styles.quantityCellContainer,
    name: "available",
    placeholder: "0.00",
  },
  { className: styles.quantityCellContainer, name: "on_hand", placeholder: "0.00" },
  { className: styles.size136CellContainer, name: "title", placeholder: "SKU" },
  { className: styles.size136CellContainer, name: "barcode", placeholder: "Barcode" },
];
