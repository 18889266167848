import React from "react";
import { Button, SvgIcon, clsx, isEdgeChromium } from "@gemlightbox/core-kit";

import { ReactComponent as CloseSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import { ReactComponent as BlockCameraSVG } from "src/external-ts/assets/images/camera/verify-block-camera.svg";

import styles from "./camera-troubleshooting.module.css";
import { useStores } from "src/hooks";

type VerifyCameraBlockProps = {
  onClose: VoidFunction;
};

export const VerifyCameraBlock: React.FC<VerifyCameraBlockProps> = ({ onClose }) => {
  const isApp = window.$platform.isApp;
  const { appCallbackStore, localeStore } = useStores();

  const handleOpenCamera = () => {
    appCallbackStore.openSystemCameraAuthSetting();
  };

  return (
    <div
      className={clsx(
        styles.cameraTroubleshootingGemcamContainer,
        styles.pdH72,
        isApp && styles.cameraTroubleshootingBlockContainer,
      )}
    >
      <Button className={styles.closeButton} appearance="secondaryGhost" onClick={onClose}>
        <SvgIcon icon={CloseSVG} />
      </Button>
      <div className={styles.cameraTroubleshootingBlockContent}>
        <SvgIcon size={80} icon={BlockCameraSVG} />
        <div className={clsx(styles.cameraTroubleshootingBlockTitle, styles.mgt112)}>
          {isApp
            ? localeStore.t('camera["camera-permission-tips"]')
            : localeStore.t('camera["camera-turned-off-tips"]')}
        </div>
        {!isApp && (
          <div className={styles.cameraTroubleshootingBlockSubtitle}>
            {isEdgeChromium()
              ? localeStore.t('camera["camera-permission-tips-chrome"]')
              : localeStore.t('camera["camera-permission-tips-no-chrome"]')}
          </div>
        )}
      </div>
      {isApp && (
        <Button className={styles.cameraTroubleshootingBlockConfirmBtn} onClick={handleOpenCamera}>
          ok
        </Button>
      )}
    </div>
  );
};
