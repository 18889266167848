import { pushDataLayerEvent } from "./index";
import { SuperProperties } from "./super-properties.utils";

type PropertiesToRemoveEventPayload = SuperProperties | SuperProperties[];

export const pushRemoveContextPropertiesEvent = (params: PropertiesToRemoveEventPayload) => {
  pushDataLayerEvent({
    event: "remove_context_properties",
    event_params: { properties_to_remove: params },
  });
};

export const pushRemoveGemCamSuperPropertiesEvent = () => {
  pushRemoveContextPropertiesEvent([
    "gemcam:device_name",
    "gemcam:serial_number",
    "gemcam:firmware_version",
    "turntable:type",
    "turntable:device_name",
    "media:local_save:status",
    "media:local_save:base_path",
    "media:local_save:reserved_disk_space_in_mb",
    "media:local_save:use_custom_reserved_disk_space",
  ]);
};
