import React, { Fragment } from "react";
import { TooltipTarget, Typography, TooltipProps, Link } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";

import styles from "./limit-feature-tooltip.module.css";

type LimitFeatureTooltipOptions = {
  onUpgradeClick?: VoidFunction;
} & Partial<Pick<TooltipProps, "offsetX" | "offsetY" | "position">>;

export const limitFeatureTooltip = (
  limit: number,
  target: TooltipTarget,
  { position = "bottom", offsetX, offsetY, onUpgradeClick }: LimitFeatureTooltipOptions = {},
) => {
  const { tooltipStore, localeStore, userStore } = useStores();

  const handleClick = () => {
    tooltipStore.close();
    onUpgradeClick?.();
  };

  tooltipStore.open(
    <Fragment>
      <Typography size="extraSmall">
        {localeStore.t('common["limit-feature"].reached')} {limit}{" "}
        {localeStore.t('common["limit-feature"].tries')}{" "}
      </Typography>
      &nbsp;
      {!userStore.isAppStoreDemoAccount && (
        <>
          <Link
            href={getSettingsTab(SettingTabNames.subscriptions)?.item.path as string}
            size="extraSmall700"
            appearance="quaternary"
            onClick={handleClick}
          >
            {localeStore.t("common.buttons.upgrade")}
          </Link>
          &nbsp;
        </>
      )}
      <Typography size="extraSmall"> {localeStore.t('common["limit-feature"].account')}</Typography>
    </Fragment>,
    {
      className: styles.limitFeatureTooltip,
      target,
      appearance: "promo",
      position,
      offsetX,
      offsetY,
      withAngle: true,
      onPointerLeave: () => tooltipStore.close(),
    },
  );
};
