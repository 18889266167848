import {
  MaybeArray,
  QueryParams,
  download,
  isArrayLike,
  queryStringify,
} from "@gemlightbox/core-kit";

import { config } from "src/config/environment";
import { useStores } from "src/hooks";
import { ExtendedProductModel } from "src/store";
import { pushDataLayerEvent } from "../tag-manager";

export const downloadVideo = (ids: MaybeArray<number>, product: ExtendedProductModel) => {
  const { modalsStore, userStore } = useStores();

  const imageIDS = isArrayLike(ids) ? ids : [ids];

  const queryParams: QueryParams = {
    type: "png",
    imageIDS,
    token: userStore.token,
    isUseSkuAsNames: true,
  };

  const query = queryStringify(queryParams);
  download(config.apiUrl + "/api/content/download/resize" + query);
  pushDataLayerEvent({ event: "gemhub:media:download" });

  modalsStore.open("DownloadModal", {
    data: product,
    type: "product",
  });
};
