import React from "react";
import { memo, clsx } from "@gemlightbox/core-kit";

import styles from "./bottom-panel.module.css";

interface BottomPanelProps extends React.HTMLProps<HTMLDivElement> {
  isOpened: boolean;
  contentClassName?: string;
}

export const BottomPanel: React.FC<BottomPanelProps> = memo(
  ({ isOpened, className, contentClassName, children, ...rest }) => {
    return (
      <div className={clsx(styles.container, isOpened && styles.active, className)} {...rest}>
        <div className={clsx(styles.content, contentClassName)}> {children} </div>
      </div>
    );
  },
);
