import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import closeImg from "src/common/images/x-close.svg";

import "./index.scss";

const Modal = ({ children, isOpened, withClose, handleModalClose, className }) => {
  return (
    <div className={clsx("modal", { "modal-opened": isOpened }, className)}>
      <div className="background-overlay" onClick={handleModalClose} />
      <div className={clsx("modal-content", { "with-close": withClose })}>
        {withClose && (
          <div className="close" onClick={handleModalClose}>
            <img src={closeImg} alt="" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
