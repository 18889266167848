import { queryStringify } from "@gemlightbox/core-kit";

import { config, ENVIRONMENT } from "src/config/environment";
import { getStaticUrl } from "src/utils";

type GetEmbedButtonStringArgs = {
  uuid: string;
  type: string;
  buttonSize: string;
  modalWidth: string;
  modalHeight: string;
  associatedMedia: string;
  showAttributes: boolean;
  isBlink: boolean;
};

// <div
//   data-source="picupmedia_gemlightbox"
//   data-env="development | prestage | staging | production"
//   data-type="blink | video360"
//   data-image-type="ar-type-{0-5} | video360-circle-{1-3} | video360-square-{1-3}"
//   data-button-size="200"
//   data-modal-width="480"
//   data-modal-height="480"
//   data-href="https://dev.gallery.cloud.picupmedia.com/embed-resource/cb46ae75-6c1d-4712-8b45-0bdee6fa93b8?mediaId=89468&withAttributes=true"
// >
export const getEmbedButtonCode = ({
  uuid,
  type,
  buttonSize,
  modalWidth,
  modalHeight,
  associatedMedia,
  showAttributes,
  isBlink,
}: GetEmbedButtonStringArgs) => {
  const imageType = isBlink ? "blink" : "video360";

  const query = queryStringify({
    withAttributes: showAttributes ? undefined : "false",
    associatedMedia,
    tryMeOn: isBlink ? "true" : undefined,
    lang: localStorage.getItem("localeCode") || "en",
  });
  const href = `${config.galleryURL}/c/${uuid}${query}`;

  return `
<div data-source="picupmedia_gemlightbox" data-env="${ENVIRONMENT}" data-type="${imageType}" data-image-type="${type}" data-button-size="${buttonSize}" data-modal-width="${modalWidth}" data-modal-height="${modalHeight}" data-href="${href}"></div>
<script src="${getStaticUrl("/embed-btn-script.js")}" async defer></script>
`;
};
