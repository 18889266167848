import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Input } from "@gemlightbox/core-kit";

import { AttributeModel, ProductModel } from "src/models";
import { useStores } from "src/hooks";
import { numberNormalizer } from "src/common/helpers/common.helpers";
import { productVariantsStore } from "../../../product-variants.store";

export type VariantInputProps = {
  variant: ProductModel;
  attribute: AttributeModel;
  placeholder?: string;
};

export const VariantAttribute: React.FC<VariantInputProps> = observer(
  ({ variant, attribute, placeholder }: VariantInputProps) => {
    const { name, id, type, suffix, prefix } = attribute;

    const { productsStore } = useStores();

    const getPrevValue = () => {
      const prevAttribute = variant.parameters.find((attr) => attr.name === name);
      return prevAttribute?.value?.toString() ?? "";
    };

    const [value, setValue] = useState(() => getPrevValue());

    const [inProcess, setInProcess] = useState(false);

    const isPrice = name === "price";

    const handleUpdateAttribute = async () => {
      setInProcess(true);

      const result = await productsStore.updateProductAttribute(variant._id, attribute.id, value);

      if (result?.status === "success") {
        productVariantsStore.updateVariantValueLocal(variant, id, value);
      } else {
        setValue(getPrevValue());
      }

      setInProcess(false);
    };

    const handleNormalizeNumber = (value: string) => numberNormalizer(value).toString();

    const handleBlur = () => {
      if (getPrevValue() === value) return;
      handleUpdateAttribute();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") return;
      e.currentTarget.blur();
    };

    return (
      <Input
        appearance="primaryV2"
        placeholder={placeholder}
        value={value}
        prefix={isPrice ? suffix : prefix}
        suffix={isPrice ? undefined : suffix}
        normalize={type === "number" ? handleNormalizeNumber : undefined}
        onChange={setValue}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        disabled={inProcess}
        disableError
      />
    );
  },
);

export default VariantAttribute;
