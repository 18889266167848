import { pushDataLayerEvent } from "./push-data-layer-event.utils";

type GoogleTagSupportedWindowsEvents =
  | "gemhub:windows_error:uncaught_exception"
  | "gemhub:windows_error:core_dump";

type eventData = {
  error_message?: string;
  stack_trace?: string;
};

const pushDataLayerEventWindows = (event: GoogleTagSupportedWindowsEvents, data: any) => {
  pushDataLayerEvent({
    event,
    event_params: data,
  });
};

window.$platform.pushDataLayerEventAppUncaughtException = (data: eventData) => {
  pushDataLayerEventWindows("gemhub:windows_error:uncaught_exception", data);
};

window.$platform.pushDataLayerEventAppCoreDump = (data: eventData) => {
  pushDataLayerEventWindows("gemhub:windows_error:core_dump", data);
};
