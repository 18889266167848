import { useQuery } from "@gemlightbox/core-kit";

export const showGemCamLocalKey = "__showGemCam__";
export const isFirstEnterGemCamLocalKey = "__isFirstEnterGemCam__";
export const isFiredZoomLocalKey = "__isFiredZoom__";
export type entranceType = {
  isLinkEntrance: boolean;
  isFirstEnter: boolean;
};

export const useIsLinkEntrance = (): entranceType => {
  const { query } = useQuery();
  const isLinkEntrance = query.source === "setup";
  const isFirstEnter = localStorage.getItem(isFirstEnterGemCamLocalKey) === "true";

  if (isLinkEntrance) {
    localStorage.setItem(showGemCamLocalKey, "true");
    !isFirstEnter && localStorage.setItem(isFirstEnterGemCamLocalKey, "true");
  }

  return {
    isLinkEntrance,
    isFirstEnter,
  };
};
