import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, clsx, memo, SvgIcon, Typography } from "@gemlightbox/core-kit";

import { CREATE_PAGE } from "src/constants";
import { NavSelector } from "./nav-selector";
import { navbarItems } from "../navbar.constants";

import styles from "./mobile-layout.module.css";

export const MobileLayout: React.FC = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const navs = useMemo(() => {
    return navbarItems.slice(1);
  }, []);

  const handleNav = (path: string) => navigate(path);

  return (
    <div className={styles.container}>
      {navs.map(({ label, path, icon }, i) => (
        <Button
          key={i}
          className={clsx(styles.navItem, { [styles.active]: location.pathname === path })}
          appearance="unset"
          size="unset"
          onClick={() => handleNav(path)}
        >
          <SvgIcon icon={icon} />
          <Typography size="extraSmall"> {label} </Typography>
        </Button>
      ))}

      <NavSelector active={location.pathname === CREATE_PAGE.path} />
    </div>
  );
});
