import { isNullable, Nullable } from "@gemlightbox/core-kit";

import { ProductModel } from "src/models";

type GetAttributesNamesValuesListReturnType = Array<{
  title: string;
  value: Nullable<string | number>;
}>;

export const getAttributesNamesValuesList = (
  product: Nullable<ProductModel>,
): GetAttributesNamesValuesListReturnType => {
  if (!product || !product.parameters) return [];

  const attributes = product.parameters;
  const attributesToFilter = ["price", "detailedTitle", "description"];
  const specificIdObjects = attributes.filter(({ user_id }) => user_id === product.user_id);
  const otherObjects = attributes.filter(({ user_id }) => user_id !== product.user_id);

  const filtered = specificIdObjects.slice();
  otherObjects.forEach((item) => {
    const existsInSpecificId = specificIdObjects.some(
      (specificItem) => specificItem.name === item.name,
    );

    if (!existsInSpecificId) {
      filtered.push(item);
    }
  });

  return filtered
    .filter(({ name }) => !attributesToFilter.includes(name))
    .reduce(
      (
        acc: GetAttributesNamesValuesListReturnType,
        { displayName, name, value, prefix, suffix, kind },
      ) => {
        let processedValue: any;
        if (kind === "default") {
          processedValue = product[name as keyof ProductModel];
        } else {
          processedValue = value;
        }
        if (isNullable(value) || !(displayName || name)) return acc;

        if (prefix) processedValue = `${prefix} ${processedValue}`;
        if (suffix) processedValue = `${processedValue} ${suffix}`;

        if (name === "price" && !prefix && !suffix) processedValue = `$ ${processedValue}`;
        const title = displayName || name;

        return [...acc, { title, value: processedValue }];
      },
      [],
    );
};
