import React from "react";
import { observer } from "mobx-react-lite";
import styles from "./camera-res-detection.module.css";
import { useStores } from "src/hooks";
import illustrationResDetection from "src/common/images/illustration-res-detection.svg";

export type CameraResDetectionProps = {
  progress: number;
  isVisible?: boolean | undefined;
};

export const CameraResDetection: React.FC<CameraResDetectionProps> = observer(
  ({ progress, isVisible }) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.wrapper} style={{ display: isVisible ? "" : "none" }}>
        <div>
          <div className={styles.resDetectionBox}>
            <img src={illustrationResDetection} />
            <div className={styles.textDescription}>
              {localeStore.t('camera["camera-res-detection"].description')}
            </div>
            <div className={styles.progressBarContainer}>
              <div
                key={progress}
                className={styles.progressBar}
                style={{ width: `${progress}%` }}
              ></div>
              <div className={styles.percentValue}>{progress}%</div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
