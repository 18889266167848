import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  SvgIcon,
  Button,
  Tooltip,
  Typography,
  EllipseActions,
  ActionOption,
  Loader,
  useCall,
  useBoolean,
  stopPropagationReact,
  useWindowSize,
} from "@gemlightbox/core-kit";

import { postStripeConnectAccount } from "src/api";
import { PAYMENTS_PAGE } from "src/constants";
import { useStores } from "src/hooks";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { IntegrationBlock } from "../integration-block";
import { getSettingsTab } from "../../settings.utils";
import { SettingTabNames } from "../../settings.constants";

import { ReactComponent as CheckSVG } from "src/external-ts/assets/images/checkmark-grey.svg";
import { ReactComponent as InfoSVG } from "src/external-ts/assets/images/info-circle-grey.svg";
import { ReactComponent as StripeSVG } from "src/external-ts/assets/images/integrations/stripe.svg";

import styles from "./stripe.module.css";

export const Stripe: React.FC = observer(() => {
  const navigate = useNavigate();

  const { localeStore, userStore } = useStores();
  const { isSubaccount, isPaypalUser, isStripeUser } = userStore;

  const { canIntegrations } = useLimits();
  const { windowWidth } = useWindowSize();

  const postStripeConnectAccountCall = useCall(postStripeConnectAccount);
  postStripeConnectAccountCall.onCallSuccess(async (data) => {
    const linkElement = document.createElement("a");
    linkElement.target = "_blank";
    linkElement.href = data.link;
    linkElement.click();

    await userStore.loadUserMeSilently();
  });

  const infoRef = useRef<HTMLDivElement>(null);
  const setupButtonRef = useRef<HTMLButtonElement>(null);

  const subscriptionTooltipBoolean = useBoolean();
  const toolTipBoolean = useBoolean();

  const loading = postStripeConnectAccountCall.submitting || userStore.loadingMeSilently;

  const canSetup = canIntegrations && !isSubaccount && !isPaypalUser;

  const handleBlockClick = () => {
    if (!isStripeUser) return;
    navigate(PAYMENTS_PAGE.path + "?stripe");
  };

  const handleRemoveIntegration = async () => {
    const formData = new FormData();
    formData.append("isEnabledStripeIntegration", "false");
    await userStore.updateUser(formData);
  };

  const handleSetupClick = async () => {
    await postStripeConnectAccountCall.submit();
  };

  const handleInfoClick = () => toolTipBoolean.setTruthy();

  const handleSetupButtonMouseEnter = () => subscriptionTooltipBoolean.setTruthy();
  const handleSetupButtonMouseLeave = () => subscriptionTooltipBoolean.setFalsy();

  const getSubscriptionTooltipText = () => {
    if (isPaypalUser) {
      return localeStore.t('integrations.general["multiple-connection-tooltip"]');
    } else if (isSubaccount) {
      return localeStore.t('integrations.general["subaccount-tooltip"]');
    } else if (!canIntegrations) {
      return localeStore.t('common["subscription-tooltip"]');
    }
  };

  return (
    <IntegrationBlock
      className={styles.stripeIntegrationContainer}
      onClick={handleBlockClick}
      clickable={isStripeUser}
    >
      {loading && <Loader position="absolute" withOverlay />}

      {windowWidth > 840 ? (
        <SvgIcon icon={StripeSVG} size={48} />
      ) : (
        <div className={styles.header}>
          <SvgIcon icon={StripeSVG} size={36} />
          <Typography size="medium600">Stripe</Typography>
          <SvgIcon
            className={styles.tooltipIcon}
            forwardRef={infoRef}
            size={16}
            icon={InfoSVG}
            onClick={handleInfoClick}
          />
        </div>
      )}

      <div className={styles.stripeContent}>
        {windowWidth > 840 && (
          <div className={styles.stripeHeading}>
            <Typography size="small600" color="textSecondary">
              Stripe
            </Typography>
            <SvgIcon
              className={styles.tooltipIcon}
              forwardRef={infoRef}
              size={16}
              icon={InfoSVG}
              onClick={handleInfoClick}
            />
          </div>
        )}
        {isStripeUser ? (
          <Typography className={styles.complete} size="extraSmall" color="primary">
            {localeStore.t("common.complete")} <SvgIcon icon={CheckSVG} size={16} />
          </Typography>
        ) : (
          <Typography size="extraSmall" color="textTertiary">
            {localeStore.t('integrations.general["payment-gateway"]')}{" "}
            <a
              href="https://support.picupmedia.com/connecting-stripe-to-gemhub-for-payment"
              target="_blank"
              rel="noreferrer"
              onClick={stopPropagationReact}
            >
              <Typography className={styles.learnButton} size="extraSmall500" color="textTertiary">
                {localeStore.t('common.buttons["learn-more"]')}
              </Typography>
            </a>
          </Typography>
        )}
        {!isStripeUser && windowWidth <= 840 && (
          <Button
            className={styles.setupButton}
            forwardRef={setupButtonRef}
            onClick={handleSetupClick}
            onPointerEnter={handleSetupButtonMouseEnter}
            onPointerLeave={handleSetupButtonMouseLeave}
            disabled={!canSetup}
          >
            {localeStore.t('common.buttons["setup-now"]')}
          </Button>
        )}
      </div>

      {isStripeUser && (
        <EllipseActions
          className={styles.actionsBtn}
          actionsListClassName={styles.stripeActionsList}
        >
          <ActionOption onClick={handleRemoveIntegration}>
            {localeStore.t('integrations.general["remove-integration"]')}
          </ActionOption>
        </EllipseActions>
      )}
      {!isStripeUser && windowWidth > 840 && (
        <Button
          className={styles.setupButton}
          forwardRef={setupButtonRef}
          onClick={handleSetupClick}
          onPointerEnter={handleSetupButtonMouseEnter}
          onPointerLeave={handleSetupButtonMouseLeave}
          disabled={!canSetup}
        >
          {localeStore.t('common.buttons["setup-now"]')}
        </Button>
      )}

      {!canSetup && (
        <Tooltip
          appearance="secondary"
          position="bottom"
          target={setupButtonRef}
          isOpen={subscriptionTooltipBoolean.value}
          onClose={subscriptionTooltipBoolean.setValue}
          withAngle
        >
          {getSubscriptionTooltipText()}
        </Tooltip>
      )}

      <Tooltip
        className={styles.stripeTooltip}
        position="right"
        withAngle
        target={infoRef}
        isOpen={toolTipBoolean.value}
        onClose={toolTipBoolean.setValue}
      >
        <ul className={styles.stripeTooltipList}>
          <Typography size="small" color="textSecondary">
            {localeStore.t('integrations.general.tooltip["to-use"]')} Stripe:
          </Typography>
          <li>
            <Typography size="small" color="textSecondary">
              {localeStore.t('integrations.general.tooltip["add-price"]')}
            </Typography>
          </li>
          <li>
            <Typography size="small" color="textSecondary">
              {localeStore.t('integrations.general.tooltip["manage-buy-now"]')}{" "}
              <Link
                className={styles.tooltipLink}
                to={getSettingsTab(SettingTabNames.gallery)?.item.path || ""}
              >
                {localeStore.t('integrations.general.tooltip["share-setting-link"]')}
              </Link>
            </Typography>
          </li>
        </ul>
      </Tooltip>
    </IntegrationBlock>
  );
});

export default Stripe;
