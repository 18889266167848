import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Modal,
  Heading,
  Button,
  globalStyles,
  clsx,
  useDidMount,
  useDidUnmount,
  ApiRequest,
  ExtractApiResponse,
  Nullable,
  NotificationTextSecondary,
  NotificationText,
  Typography,
  ProgressLine,
  useWindowSize,
} from "@gemlightbox/core-kit";

import { getUserTagManagerInfoCallback, postBatchAIRetouch } from "src/api";
import { useStores } from "src/hooks";
import { MEDIA_PAGE } from "src/constants";
import WSConnection from "src/common/socket";
import { MediaItem } from "../batch-remove-background-modal/media-item";
import { BatchAIRetouchModalProps } from "./batch-ai-retouch-modal.types";
import { pushDataLayerEvent } from "src/utils";

import styles from "./batch-ai-retouch-modal.module.css";

export const BatchAIRetouchModal: React.FC<BatchAIRetouchModalProps> = observer(
  ({ isOpen, options, setClose, onFinalClosed }: BatchAIRetouchModalProps) => {
    const { medias } = options;

    const navigate = useNavigate();

    const { userStore, notificationStore, localeStore, mediaStore } = useStores();

    const requestRef =
      useRef<Nullable<ApiRequest<ExtractApiResponse<typeof postBatchAIRetouch>>>>(null);

    const requestIdRef = useRef("");
    const [progress, setProgress] = useState(0);

    const { windowWidth } = useWindowSize();

    const [finalImages, setFinalImages] = useState<string[]>([]);
    const [retouchResponse, setRetouchResponse] =
      useState<Nullable<ExtractApiResponse<typeof postBatchAIRetouch>>>(null);

    const loadingButtons = mediaStore.loadingAIResult;
    const disableButtons = !retouchResponse;

    const handleError = () => {
      setClose();

      notificationStore.open({
        title: localeStore.t('common.warnings["ooops-something-wrong"]'),
        message: localeStore.t('common.warnings["reload-or-try-later"]'),
        confirmText: localeStore.t("common.buttons.reload"),
        cancelText: "",
        icon: "exclamation",
        confirmAppearance: "primary",
        onlyConfirm: true,
        onOk: () => window.location.reload(),
      });
    };

    const handleConfirmClose = () => {
      notificationStore.open({
        title: localeStore.t('common.warnings["sure-want-close-page"]'),
        message: (
          <>
            <NotificationTextSecondary>
              {localeStore.t('common.warnings["changes-not-been-saved"]')}
            </NotificationTextSecondary>
            <NotificationText>{localeStore.t('common.warnings["cannot-undone"]')}</NotificationText>
          </>
        ),
        confirmText: localeStore.t("common.buttons.close"),
        cancelText: localeStore.t("common.buttons.cancel"),
        icon: "exclamation",
        onOk: setClose,
      });
    };

    const handleSaveAs = (isSaveAsNewMedia: boolean) => async () => {
      if (!retouchResponse) return;

      const result = await mediaStore.putSaveAIResult(retouchResponse.id, isSaveAsNewMedia);

      if (result.status !== "success") return handleError();

      setClose();
      navigate(MEDIA_PAGE.path);

      pushDataLayerEvent({ event: "retouch_ai" });
    };

    useDidMount(() => {
      const userId = userStore.userMe?.user._id;

      if (!userId) return;

      const progressWS = new WSConnection(`/user/${userId}`, userStore.token);

      progressWS.on("progress/UPDATE", ({ payload }: any) => {
        if (requestIdRef.current === payload.requestID) {
          setProgress(payload.progress);
        }
      });

      return () => {
        progressWS.destroy();
      };
    });

    useDidMount(async () => {
      const formData = new FormData();
      medias.forEach((media) => formData.append("media_ids", media.id.toString()));

      const request = postBatchAIRetouch.getRequest({
        data: formData,
      });
      requestRef.current = request;

      request.events.on("requestStart", ({ headers }) => {
        requestIdRef.current = headers["x-request-id"];
      });

      const batchResult = await request.fetch();

      if (batchResult.status === "cancelled") return;
      if (batchResult.error) return handleError();

      // Note: silently updating userMe due to usage limits
      userStore.loadUserMeSilently();

      setFinalImages(batchResult.success.links);
      setRetouchResponse(batchResult.success);
    });

    useDidUnmount(() => requestRef.current?.abort());

    return (
      <Modal
        scrollWrapperClassName={styles.batchAIRetouchScrollWrapper}
        contentClassName={styles.batchAIRetouchModalContent}
        size="big"
        isOpen={isOpen}
        setClose={handleConfirmClose}
        onFinalClosed={onFinalClosed}
        withCross
      >
        <div className={styles.header}>
          <Heading
            className={styles.title}
            tag={windowWidth > 768 ? "h3" : "h4"}
            color="textSecondary"
          >
            {localeStore.t('components.business["batch-ai-retouch-modal"].title')}
          </Heading>
          <div className={styles.loader}>
            <ProgressLine containerClassName={styles.progressBar} progress={progress} />
            <Typography className={styles.progressNumber} size={"extraSmall500"} color="primary">
              {progress.toFixed()}%
            </Typography>
          </div>
        </div>
        <div className={clsx(styles.batchRemoveContent, globalStyles.addScrollStyles)}>
          <div className={styles.batchRemoveContentWrapper}>
            {medias.map((media, i) => (
              <MediaItem
                key={media.id}
                media={media}
                overrideImageSrc={finalImages[i]}
                loaded={!!retouchResponse}
              />
            ))}
          </div>
        </div>
        <div className={styles.batchRemoveActionsWrapper}>
          <Button
            appearance="primaryOutlined"
            onClick={handleSaveAs(true)}
            loading={loadingButtons}
            disabled={disableButtons}
            data-cy="save-as-new"
          >
            {localeStore.t('components.business["batch-ai-retouch-modal"].buttons["save-new"]')}
          </Button>
          <Button
            onClick={handleSaveAs(false)}
            loading={loadingButtons}
            disabled={disableButtons}
            data-cy="save-changes"
          >
            {localeStore.t('common.buttons["save-changes"]')}
          </Button>
        </div>
      </Modal>
    );
  },
);

export default BatchAIRetouchModal;
