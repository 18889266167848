import React, { useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { FormikProps } from "formik";
import {
  parsePhoneNumber,
  useWindowSize,
  Button,
  Heading,
  useBlockRouting,
  useDidUpdate,
  getCountryCallingCode,
  CountryCode,
  clsx,
  ObjectType,
  useDidMount,
  NotificationTextSecondary,
  NotificationText,
  useBoolean,
  SvgIcon,
} from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import { useStores } from "src/hooks";
import { LanguageBlock } from "./language-block";
import { ProfileForm, FORM_ID } from "./profile-form";
import { initialValues } from "./profile.constants";
import { PersonalInfoType } from "./profile.types";
import { DropImageError, Logo } from "../logo";
import MobileTabHeader from "../MobileTabHeader";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./profile.module.css";
import { pushUserAttributeDataLayerEvent } from "src/utils";
import { SupportedLocalesModel, SupportedUppercaseLocalesModel } from "src/models";
import { ReactComponent as CircleWarningSVG } from "src/external-ts/assets/images/circle-warning-icon-red.svg";
import { UploadToolTip } from "../../../external-ts/components/business/tooltips/upload-tooltip/upload-tooltip";
import { DeleteTipModal } from "../modals/delete-tip-modal";
import { BackgroundFillBlock } from "./background-fill-block";

export const Profile: React.FC = observer(() => {
  const {
    notificationStore,
    userStore,
    subaccountsStore,
    localeStore,
    appCallbackStore,
    tooltipStore,
  } = useStores();
  const { isSubaccount } = userStore;

  const { onBlock, blockRouting, unblockRouting } = useBlockRouting();
  onBlock((blocker) => {
    notificationStore.open({
      title: localeStore.t("settings.profile.cancel.title"),
      message: (
        <>
          <NotificationTextSecondary>
            {localeStore.t("settings.profile.cancel.warning.top")}
          </NotificationTextSecondary>
          <NotificationText>
            {localeStore.t("settings.profile.cancel.warning.bottom")}
          </NotificationText>
        </>
      ),
      confirmText: localeStore.t("common.buttons.yes"),
      cancelText: localeStore.t("common.buttons.no"),
      confirmAppearance: "secondary",
      onOk: blocker.retry,
    });
  });

  const { windowWidth } = useWindowSize();

  const [currentAvatar, setCurrentAvatar] = useState<string | null>(null);
  const [formInner, setFormInner] = useState<FormikProps<any> | null>(null);
  const [avatarHasChanged, setAvatarHasChanged] = useState(false);
  const [resetAvatar, setResetAvatar] = useState(false);
  const isSubmitLoading = useBoolean(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { confirmedSubaccounts, loadSubaccounts } = subaccountsStore;

  const avatarToSubmit = useRef<File | null>(null);
  const [locale, setLocale] = useState(
    (localeStore.currentLocaleCode?.toUpperCase() as SupportedUppercaseLocalesModel) ?? "EN",
  );

  const [backgroundFill, setBackgroundFill] = useState(userStore.backgroundFill);

  useDidMount(async () => {
    await userStore.loadUserMe();
    setCurrentAvatar(userStore.userMe?.user.avatar?.small || null);
  });

  useDidUpdate(() => {
    if (formInner?.dirty || avatarHasChanged) blockRouting();
    else unblockRouting();
  }, [formInner?.dirty, avatarHasChanged]);

  useDidUpdate(() => {
    const logo =
      typeof profileInitialValues.avatar === "object"
        ? profileInitialValues.avatar?.small || null
        : profileInitialValues.avatar;
    setCurrentAvatar(logo);
  }, [userStore.userMe?.user.avatar]);

  const handleAvatarChange = (data: any) => {
    avatarToSubmit.current = data.file;
    setCurrentAvatar(data.b64);
    setAvatarHasChanged(true);
    setResetAvatar(false);
  };

  const handleImageError = ({ type, message }: DropImageError) => {
    tooltipStore.open(
      <UploadToolTip>
        <SvgIcon icon={CircleWarningSVG} size={16} />
        {type === "MAX_SIZE_EXCEEDED" && localeStore.t("settings.logo.tip.size")}
        {type === "MAX_WIDTH_EXCEEDED" && localeStore.t("settings.logo.tip.width")}
      </UploadToolTip>,
      {
        target: document.body,
        appearance: "promo",
        position: "bottomRight",
      },
      {
        timeout: 5000,
      },
    );
  };

  const handleCancel = () => {
    formInner?.resetForm();
    setResetAvatar(true);
    setAvatarHasChanged(false);
  };

  const handleSubmit = async (values: PersonalInfoType) => {
    if (hasChanged) {
      handleChangeLocalSettings();
      if (!formInner?.dirty && !avatarHasChanged) {
        notificationStore.open({
          title: localeStore.t("settings.profile.success.title"),
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: "",
          confirmAppearance: "primary",
          onlyConfirm: true,
        });
      }
    }

    if (formInner?.dirty || avatarHasChanged) {
      if (isSubmitLoading.value) return;
      else isSubmitLoading.setTruthy();

      const formData = new FormData();

      const phoneNumber = values.phone.phone
        ? `+${getCountryCallingCode(values.phone.country).callingCode}${values.phone.phone}`
        : "";

      Object.entries(values).forEach(([key, value]: [key: string, value: any]) => {
        if (key === "phone" || key === "avatar" || key === "email") return;
        formData.set(key, value);
      });

      formData.set("phone", phoneNumber);
      if (avatarHasChanged) {
        formData.set("avatar", avatarToSubmit.current as File);
      }

      const { error } = await userStore
        .updateUser(formData)
        .finally(() => isSubmitLoading.setFalsy());

      if (error) {
        notificationStore.open({
          title: localeStore.t("settings.profile.error.title"),
          message: `${localeStore.t("settings.profile.error.message")}: ${error.formattedMessage}`,
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: "",
          confirmAppearance: "primary",
          onlyConfirm: true,
        });
      } else {
        notificationStore.open({
          title: localeStore.t("settings.profile.success.title"),
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: "",
          confirmAppearance: "primary",
          onlyConfirm: true,
        });
      }

      setAvatarHasChanged(false);
    }
  };

  const handleDelete = async () => {
    if (!isSubaccount && confirmedSubaccounts.length) {
      setIsDeleteModalOpen(true);
      return;
    } else {
      notificationStore.open({
        title: localeStore.t("settings.profile.deleteAccount.title"),
        message: (
          <>
            <NotificationText>
              {localeStore.t("settings.profile.deleteAccount.warning")}
            </NotificationText>
          </>
        ),
        confirmText: localeStore.t("common.buttons.yes"),
        cancelText: localeStore.t("common.buttons.no"),
        confirmAppearance: "secondary",
        onOk: () => {
          deleteConfirm();
        },
      });
    }
  };

  const deleteConfirm = async () => {
    const { error } = await userStore.deleteUser();

    if (error) {
      notificationStore.open({
        title: localeStore.t("settings.profile.error.title"),
        message: `${localeStore.t("settings.profile.error.message")}: ${error.formattedMessage}`,
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: "",
        confirmAppearance: "primary",
        onlyConfirm: true,
      });
    }
  };

  const handleChangeLocalSettings = () => {
    if (localeHasChanged) {
      const lowerCaseValue = locale.toLowerCase() as SupportedLocalesModel;
      localeStore.fetchLocale(lowerCaseValue);
      appCallbackStore.setLocalizedLanguage(lowerCaseValue);
      pushUserAttributeDataLayerEvent({
        lang: lowerCaseValue,
      });
    }
    if (backgroundFillHasChanged) {
      userStore.setBackgroundFill(backgroundFill);
    }
  };

  const profileInitialValues: PersonalInfoType = useMemo(() => {
    const parsedPhone: ObjectType = userStore.userMe?.user.phone
      ? parsePhoneNumber(userStore.userMe.user.phone) || {}
      : {};

    return {
      avatar: userStore.userMe?.user.avatar ?? initialValues.avatar,
      firstName: userStore.userMe?.user.firstName ?? initialValues.firstName,
      lastName: userStore.userMe?.user.lastName ?? initialValues.lastName,
      email: userStore.userMe?.user.email ?? initialValues.email,
      company: userStore.userMe?.user.company ?? initialValues.company,
      website: userStore.userMe?.user.website ?? initialValues.website,
      country: userStore.userMe?.user.country ?? initialValues.country,
      phone: {
        country: (parsedPhone?.country as CountryCode) ?? initialValues.phone.country,
        phone: (parsedPhone?.nationalNumber as string) ?? initialValues.phone.phone,
      },
    };
  }, [userStore.userMe]);

  const localeHasChanged = useMemo(() => {
    return locale !== localeStore.currentLocaleCode?.toUpperCase();
  }, [locale, localeStore.currentLocale]);

  const backgroundFillHasChanged = useMemo(() => {
    return backgroundFill !== userStore.backgroundFill;
  }, [backgroundFill, userStore.backgroundFill]);

  const hasChanged = localeHasChanged || backgroundFillHasChanged;

  const isSubmitButtonDisabled = !formInner?.dirty && !avatarHasChanged && !hasChanged;
  const avatarToSet = resetAvatar ? profileInitialValues.avatar : currentAvatar;

  return (
    <div className={styles.profileInfoContainer}>
      <div className={clsx(styles.profile, globalStyles.hideScrollBar)}>
        {windowWidth <= BREAKPOINTS.tablet && (
          <MobileTabHeader title={localeStore.t("settings.profile.title")} />
        )}
        {windowWidth > BREAKPOINTS.tablet && (
          <Heading tag="h2" color="textSecondary">
            {localeStore.t("settings.profile.title")}
          </Heading>
        )}
        <Logo
          containerClassName={styles.logoContainer}
          detailsClassName={styles.logoDetails}
          appearance="primaryV2"
          title={localeStore.t('settings.profile["logo-title"]')}
          initialLogo={avatarToSet}
          onImageChange={handleAvatarChange}
          onImageError={handleImageError}
        />
        <ProfileForm
          initialValues={profileInitialValues}
          onSubmit={handleSubmit}
          formInnerRef={setFormInner}
        />
        <LanguageBlock onChangeLocale={setLocale} />
        <BackgroundFillBlock value={backgroundFill} onChange={setBackgroundFill} />
        <Button
          className={styles.deleteAccountButton}
          appearance="secondaryGhost"
          onClick={handleDelete}
        >
          {localeStore.t("settings.profile.buttons.deleteAccount")}
        </Button>
        <DeleteTipModal
          isOpen={isDeleteModalOpen}
          setClose={() => setIsDeleteModalOpen(false)}
          options={{}}
        />
      </div>
      <div className={styles.submitButtonsContainer}>
        <Button appearance="secondaryOutlined" onClick={handleCancel}>
          {localeStore.t("settings.profile.buttons.cancel")}
        </Button>
        <Button
          type="submit"
          form={FORM_ID}
          loading={isSubmitLoading.value}
          disabled={isSubmitButtonDisabled}
          data-cy="profile-form-save-button"
        >
          {localeStore.t("settings.profile.buttons.save")}
        </Button>
      </div>
    </div>
  );
});
