import { dashboardApiMiddleware } from "src/api";

export const getDnsStatus = dashboardApiMiddleware()({
  method: "get",
  endpoint: "/api/users/dns/status",
});

export const patchDnsResolve = dashboardApiMiddleware()({
  method: "patch",
  endpoint: "/api/users/dns/resolve",
});
