import React, { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { clsx, Input, InputDOMElement, useDidMount, useDidUpdate } from "@gemlightbox/core-kit";

import { CatalogTypeTag } from "src/external-ts/components";
import { useStores } from "src/hooks";

import styles from "./catalog-inputs-panel.module.css";

export const CatalogInputsPanel: React.FC = observer(() => {
  const { catalogStore, localeStore } = useStores();

  const [name, setName] = useState(catalogStore.catalog?.name ?? "");
  const [description, setDescription] = useState(catalogStore.catalog?.description ?? "");

  const [error, setError] = useState("");
  const descriptionInputRef = useRef<InputDOMElement>(null);

  const isAllCatalog = catalogStore.catalog?.is_all;
  const isSpecialCatalog = isAllCatalog || catalogStore.catalog?.isRapnetCatalog;

  useDidUpdate(
    () => {
      setName(catalogStore.catalog?.name ?? "");
      setDescription(catalogStore.catalog?.description ?? "");
    },
    [catalogStore.catalog, catalogStore.catalog?.name, catalogStore.catalog?.description],
    true,
  );

  useDidMount(() => {
    const textarea = descriptionInputRef.current;
    if (!textarea) return;
    textarea.style.height = "5px";
    textarea.style.height = textarea.scrollHeight + "px";
  });

  const onInput = (element: React.FormEvent<HTMLTextAreaElement>) => {
    element.currentTarget.style.height = "5px";
    element.currentTarget.style.height = element.currentTarget.scrollHeight + "px";
  };

  const handleTitleChange = (value: string) => {
    setName(value);
    if (error) setError("");
  };

  const handleTitleBlur = async () => {
    if (!catalogStore.catalog) return;

    if (name === catalogStore.catalog.name) return;

    const error = await catalogStore.updateCatalog({
      name: name,
    });

    if (error?.details?.statusCode === 422) {
      setError(localeStore.t('["catalog-details"]["catalog-inputs-panel"].fields.name.error'));
    }
  };

  const handleDescriptionBlur = () => {
    if (!catalogStore.catalog) return;
    if (description === catalogStore.catalog?.description) return;

    catalogStore.updateCatalog({
      description,
    });
  };

  const catalogType = catalogStore.isAutoCatalog ? "automatic" : "manual";

  return (
    <div className={styles.container}>
      {!isAllCatalog && <CatalogTypeTag type={catalogType} />}
      <div className={clsx(styles.wrapper, { [styles.isSpecialCatalog]: isSpecialCatalog })}>
        <Input
          value={name}
          className={styles.titleWrapper}
          inputWrapperClassName={styles.fieldWrapper}
          inputClassName={styles.title}
          appearance="primaryV2"
          error={error}
          onChange={handleTitleChange}
          onBlur={handleTitleBlur}
          disabled={isSpecialCatalog}
          data-cy="catalog-title"
        />

        <div className={styles.bottom}>
          <Input
            type="textarea"
            value={description}
            className={styles.descriptionWrapper}
            inputWrapperClassName={styles.fieldWrapper}
            inputClassName={styles.description}
            appearance="primaryV2"
            placeholder={localeStore.t(
              '["catalog-details"]["catalog-inputs-panel"].fields.description.placeholder',
            )}
            forwardRef={descriptionInputRef}
            onInput={onInput}
            onChange={setDescription}
            onBlur={handleDescriptionBlur}
            disabled={isSpecialCatalog}
            disableError
            data-cy="catalog-description"
          />
        </div>
      </div>
    </div>
  );
});
