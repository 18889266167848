import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Heading, Modal, Typography } from "@gemlightbox/core-kit";

import { ModalExtendedType } from "src/store";
import { ExtendedTrashBinItemModel } from "src/models";
import { useStores } from "src/hooks";

import styles from "./delete-trash-modal.module.css";

export type DeleteTrashModalProps = ModalExtendedType<{
  media: ExtendedTrashBinItemModel[];
}>;

export const DeleteTrashModal: React.FC<DeleteTrashModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { trashBinStore, localeStore } = useStores();

    const handleSubmit = () => {
      trashBinStore.deleteItems(options.media);
      setClose();
    };

    return (
      <Modal
        contentClassName={styles.modal}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        withCross
        disablePadding
        disableBorderRadius
      >
        <Heading className={styles.title} tag="h2" color="textSecondary">
          {localeStore.t('["trash-bin"].modals.delete.title')}
        </Heading>
        <Typography className={styles.article} size="medium" color="textTertiary">
          {localeStore.t('["trash-bin"].modals.delete.article')}
        </Typography>
        <Typography className={styles.warning} size="small600" color="textSecondary">
          {localeStore.t('["trash-bin"].modals.delete.warning')}
        </Typography>
        <div className={styles.buttons}>
          <Button appearance="tertiaryOutlined" onClick={setClose}>
            {localeStore.t('["trash-bin"].modals.buttons.cancel')}
          </Button>
          <Button appearance="error" onClick={handleSubmit}>
            {localeStore.t('["trash-bin"].modals.buttons.delete')}
          </Button>
        </div>
      </Modal>
    );
  },
);
