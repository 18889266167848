import { makeAutoObservable, runInAction } from "mobx";
import { arrayDifference, cloneDeep, isEqual, isNumber, filteredMap } from "@gemlightbox/core-kit";

import {
  getProductVariantOptions,
  postProductVariantOption,
  putProductVariantOption,
  deleteProducts,
  deleteProductsVariantOption,
  getProducts,
  putProductVariantOptionOrder,
} from "src/api";
import { PostProductVariantOptionDataType, PutProductVariantOptionDataType } from "src/api";
import {
  ProductModel,
  ProductParameterModel,
  ProductVariantOptionModel,
  ProductVariantOptionValueModel,
} from "src/models";
import { rootStore } from "src/store";
import {
  availableOptions,
  maxOptionsLimit,
  maxOptionValues,
  shopifyOptionAttributes,
} from "./product-variant.constants";
import { OptionListType } from "./product-variant.types";

class ProductVariantsStore {
  private _sourceOptionList: OptionListType = [];

  private _loadingOptionsList = true;
  public get loadingOptionsList() {
    return this._loadingOptionsList;
  }

  public optionsList: OptionListType = [{ name: "", option_values: [{ value: "" }] }];
  public get hasOptions() {
    return !!this.optionsList.length;
  }
  public get hasOptionsLimit() {
    return this.optionsList.length >= maxOptionsLimit;
  }

  private _submittingOption = false;
  public get submittingOption() {
    return this._submittingOption;
  }

  private _orderingOption = false;
  public get orderingOption() {
    return this._orderingOption;
  }

  private _deletingVariantOption = false;
  public get deletingVariantOption() {
    return this._deletingVariantOption;
  }

  public get isVariantOptionInProcess() {
    return (
      this.loadingOptionsList ||
      this.submittingOption ||
      this.deletingVariantOption ||
      this.orderingOption
    );
  }

  public availableOptions = availableOptions.slice();

  private _loadingVariantList = true;
  public get loadingVariantList() {
    return this._loadingVariantList;
  }

  public variantList: ProductModel[] = [];
  public get canShowVariantsTable() {
    return !!this.variantList.length;
  }

  private _deletingVariant = false;
  public get deletingVariant() {
    return this._deletingVariant;
  }

  private _isSidebarOpened = false;
  public get isSidebarOpened() {
    return this._isSidebarOpened;
  }

  private _isSidebarFinalOpened = false;
  public get isSidebarFinalOpened() {
    return this._isSidebarFinalOpened;
  }

  constructor() {
    this.resetStore();
    makeAutoObservable(this);
  }

  public openSidebar() {
    if (this.loadingOptionsList) return;
    if (!this.hasOptions) {
      this.addAnotherOption();
    } else {
      this.optionsList.forEach((_, index) => {
        this.addOptionValue("", index);
      });
    }
    this._isSidebarOpened = true;
    this._isSidebarFinalOpened = true;
  }

  public closeSidebar() {
    this.optionsList = cloneDeep(this._sourceOptionList);
    this._checkAvailableOptions();
    this._isSidebarOpened = false;
  }

  public finalCloseSidebar() {
    this._isSidebarFinalOpened = false;
  }

  public addAnotherOption() {
    this.optionsList.push({ name: "", option_values: [{ value: "" }] });
  }

  public setOptionName(name: string, index: number) {
    this.optionsList[index].name = name;
    this._checkAvailableOptions();
  }

  public removeOption(index: number) {
    const option = this.optionsList[index];
    if (option.id && option.product_id) {
      this.deleteProductVariantOption(option.product_id, option.id);
      return;
    }

    this.optionsList.splice(index, 1);
    this._checkAvailableOptions();
  }

  public dropOption(productId: ProductModel["_id"], oldIndex: number, newIndex: number) {
    this.putProductVariantOptionOrder(productId, oldIndex, newIndex);
  }

  public submitOption(productId: ProductModel["_id"], index: number) {
    const option = this.optionsList[index];
    if (!option) return;

    if (option.id && isNumber(option.order)) {
      this.putProductVariantOption(productId, option.id, index);
      return;
    }

    this.postProductVariantOption(productId, index);
  }

  public addOptionValue(value: string, optionIndex: number) {
    const optionValues = this.optionsList[optionIndex].option_values;
    if (optionValues.length >= maxOptionValues) return;

    const emptyOption = optionValues.find(({ value }) => !value);
    if (emptyOption) return;

    const hasSameValue = this.optionsList[optionIndex].option_values.find((option) => {
      return option.value === value;
    });
    if (value && hasSameValue) return;
    this.optionsList[optionIndex].option_values.push({ value });
  }

  public changeOptionValue(value: string, optionIndex: number, optionValueIndex: number) {
    const optionValues = this.optionsList[optionIndex].option_values;
    optionValues[optionValueIndex].value = value;

    if (optionValueIndex === optionValues.length - 1 && optionValues.length < maxOptionValues) {
      this.addOptionValue("", optionIndex);
    }
  }

  public blurOptionValue(optionIndex: number, optionValueIndex: number) {
    const optionValue = this.optionsList[optionIndex].option_values[optionValueIndex].value;
    if (!optionValue) return this.removeOptionValue(optionIndex, optionValueIndex);

    const hasSameValue = this.optionsList[optionIndex].option_values.find((option, index) => {
      return index !== optionValueIndex && option.value === optionValue;
    });
    if (hasSameValue) this.removeOptionValue(optionIndex, optionValueIndex);
  }

  public removeOptionValue(optionIndex: number, optionValueIndex: number) {
    const option = this.optionsList[optionIndex];
    const optionValue = option.option_values[optionValueIndex];

    if (option.id && option.product_id && optionValue.id) {
      this.deleteProductVariantOptionValue(option.product_id, option.id, optionValue.id);
      return;
    }

    const optionValues = this.optionsList[optionIndex].option_values;
    optionValues.splice(optionValueIndex, 1);
    if (!optionValues.length) {
      this.addOptionValue("", optionIndex);
    } else {
      const emptyOption = optionValues.find(({ value }) => !value);
      if (emptyOption) return;
      this.addOptionValue("", optionIndex);
    }
  }

  public checkCanSubmitOption(index: number) {
    if (productVariantsStore.isVariantOptionInProcess) return false;

    const option = this.optionsList[index];
    const optionValues = option.option_values;
    const validOptions = optionValues.filter(({ value }) => !!value);
    if (!validOptions.length) return false;

    const optionToCheck = { ...option, option_values: validOptions };
    return !isEqual(this._sourceOptionList[index], optionToCheck);
  }

  public updateVariantValueLocal(
    variant: ProductModel,
    parameterId: ProductParameterModel["id"],
    value: ProductParameterModel["value"],
  ) {
    const parameterIndex = variant.parameters.findIndex((p) => p.id === parameterId);
    if (parameterIndex === -1) return;
    variant.parameters[parameterIndex].value = value;
  }

  /* Requests ↓ */
  public async getProductVariants(product_id: ProductModel["_id"]) {
    runInAction(() => {
      this._loadingVariantList = true;
    });

    const result = await getProducts
      .getRequest({
        queryParams: { parentProductId: product_id, showVariants: true },
      })
      .fetch();

    runInAction(() => {
      if (result.success) {
        this.variantList = result.success.rows;
      }

      this._loadingVariantList = false;
    });
  }

  public async deleteProductVariant(product_id: ProductModel["_id"]) {
    const foundVariantIndex = this.variantList.findIndex((variant) => variant._id === product_id);
    if (foundVariantIndex === -1) return;

    const variant = this.variantList[foundVariantIndex];

    runInAction(() => {
      this._deletingVariant = true;
      this.variantList.splice(foundVariantIndex, 1);
    });

    const result = await deleteProducts
      .getRequest({
        queryParams: { ids: [product_id] },
      })
      .fetch();

    runInAction(() => {
      this._deletingVariant = false;

      if (result.status !== "success") {
        this.variantList.splice(foundVariantIndex, 0, variant);
      }
    });
  }

  public async getProductVariantOptions(product_id: ProductModel["_id"]) {
    runInAction(() => {
      this._loadingOptionsList = true;
    });

    const result = await getProductVariantOptions
      .getRequest({
        params: { product_id },
      })
      .fetch();

    runInAction(() => {
      if (result.success) {
        this._sourceOptionList = cloneDeep(result.success);
        this.optionsList = result.success;
        this._checkAvailableOptions();
      }

      this._loadingOptionsList = false;
    });
  }

  public async postProductVariantOption(product_id: ProductModel["_id"], optionIndex: number) {
    const option = this.optionsList[optionIndex];
    if (!option) return;

    const ordersArr = filteredMap(this._sourceOptionList, ({ order }) => order);
    const newOrder = ordersArr.length ? Math.max(...ordersArr) + 1 : 0;

    const data: PostProductVariantOptionDataType = {
      name: option.name,
      order: newOrder,
      values: filteredMap(option.option_values, ({ value }) => {
        if (!value) return;
        return value;
      }),
    };

    runInAction(() => {
      this._submittingOption = true;
    });

    const result = await postProductVariantOption
      .getRequest({
        params: { product_id },
        data,
      })
      .fetch();

    runInAction(() => {
      if (result.status === "success") {
        this._sourceOptionList.push(result.success.option);

        this.optionsList[optionIndex] = cloneDeep(result.success.option);
        // NOTE: preserve empty input so user can continue enter
        this.addOptionValue("", optionIndex);

        this.optionsList.sort((a, b) => {
          if (a.id && b.id) return 0;
          if (a.id) return -1;
          return 0;
        });

        this.getProductVariants(product_id);

        const attributes = rootStore.stores.attributesStore.attributes;
        const alreadyHasShopifyAttributes = attributes.some((attribute) => {
          return shopifyOptionAttributes.includes(attribute.name);
        });

        if (!alreadyHasShopifyAttributes) {
          // NOTE: BE requirement is to re-fetch attributes list after variant creating
          // due to that shopify attributes appear only after variant option creating
          rootStore.stores.attributesStore.loadAttributesList();
        }
      }

      this._submittingOption = false;
    });
  }

  public async putProductVariantOption(
    product_id: ProductModel["_id"],
    option_id: number,
    optionIndex: number,
  ) {
    const option = this.optionsList[optionIndex];

    if (!option.id || !isNumber(option.order)) return;

    const data: PutProductVariantOptionDataType = {
      name: option.name,
      order: option.order,
      values: filteredMap(option.option_values, ({ id, value }) => {
        if (!value) return;
        return { id, value };
      }),
    };

    runInAction(() => {
      this._submittingOption = true;
    });

    const result = await putProductVariantOption
      .getRequest({
        params: { product_id, option_id },
        data,
      })
      .fetch();

    runInAction(() => {
      if (result.status === "success") {
        const changedOption = result.success.options.find((option) => option.name === data.name);
        if (changedOption) {
          this._sourceOptionList[optionIndex] = changedOption;

          this.optionsList[optionIndex] = changedOption;
          // NOTE: preserve empty input so user can continue enter
          this.addOptionValue("", optionIndex);
        }
        this.getProductVariants(product_id);
      }

      this._submittingOption = false;
    });
  }

  public async putProductVariantOptionOrder(
    product_id: ProductModel["_id"],
    oldIndex: number,
    newIndex: number,
  ) {
    if (oldIndex === newIndex) return;

    const oldOption = this.optionsList[oldIndex];
    const oldOrder = oldOption.order;
    const nextOption = this.optionsList[newIndex];
    const nextOrder = nextOption.order;

    if (!oldOption.id || !isNumber(oldOrder) || !nextOption.id || !isNumber(nextOrder)) return;

    const source = this._sourceOptionList;
    const options = this.optionsList;

    runInAction(() => {
      this._orderingOption = true;

      source[oldIndex].order = nextOrder;
      source[newIndex].order = oldOrder;
      options[oldIndex].order = nextOrder;
      options[newIndex].order = oldOrder;

      [source[oldIndex], source[newIndex]] = [source[newIndex], source[oldIndex]];
      [options[oldIndex], options[newIndex]] = [options[newIndex], options[oldIndex]];
    });

    const result = await putProductVariantOptionOrder
      .getRequest({
        params: { product_id },
        data: [
          { id: oldOption.id, order: nextOrder },
          { id: nextOption.id, order: oldOrder },
        ],
      })
      .fetch();

    runInAction(() => {
      if (result.status === "success") {
        this.getProductVariants(product_id);
      } else {
        source[oldIndex].order = nextOrder;
        source[newIndex].order = oldOrder;
        options[oldIndex].order = nextOrder;
        options[newIndex].order = oldOrder;

        [source[oldIndex], source[newIndex]] = [source[newIndex], source[oldIndex]];
        [options[oldIndex], options[newIndex]] = [options[newIndex], options[oldIndex]];
      }

      this._orderingOption = false;
    });
  }

  public async deleteProductVariantOption(
    product_id: ProductModel["_id"],
    option_id: ProductVariantOptionModel["id"],
  ) {
    const foundOptionIndex = this.optionsList.findIndex((option) => option.id === option_id);
    if (foundOptionIndex === -1) return;

    const option = this.optionsList[foundOptionIndex];

    runInAction(() => {
      this._deletingVariantOption = true;
      this._sourceOptionList.splice(foundOptionIndex, 1);
      this.optionsList.splice(foundOptionIndex, 1);
      this._checkAvailableOptions();
    });

    const result = await deleteProductsVariantOption
      .getRequest({
        params: { product_id, option_id },
      })
      .fetch();

    runInAction(() => {
      this._deletingVariantOption = false;

      if (result.status === "success") {
        this.getProductVariants(product_id);
      } else {
        this._sourceOptionList.splice(foundOptionIndex, 0, option);
        this.optionsList.splice(foundOptionIndex, 0, option);
        this._checkAvailableOptions();
      }
    });
  }

  public async deleteProductVariantOptionValue(
    product_id: ProductModel["_id"],
    option_id: ProductVariantOptionModel["id"],
    option_value_id: ProductVariantOptionValueModel["id"],
  ) {
    const foundOptionIndex = this.optionsList.findIndex((option) => option.id === option_id);
    if (foundOptionIndex === -1) return;

    const option = this.optionsList[foundOptionIndex];

    if (!isNumber(option.order)) return;

    const foundOptionValueIndex = option.option_values.findIndex(
      (value) => value.id === option_value_id,
    );
    if (foundOptionValueIndex === -1) return;

    const deletedOptionValue = cloneDeep(
      this._sourceOptionList[foundOptionIndex].option_values[foundOptionValueIndex],
    );

    runInAction(() => {
      this._submittingOption = true;
      this._sourceOptionList[foundOptionIndex].option_values.splice(foundOptionValueIndex, 1);
      this.optionsList[foundOptionIndex].option_values.splice(foundOptionValueIndex, 1);
    });

    const data: PutProductVariantOptionDataType = {
      name: option.name,
      order: option.order,
      values: filteredMap(option.option_values, ({ id, value }) => {
        if (!id) return;
        return { id, value };
      }),
    };

    const result = await putProductVariantOption
      .getRequest({
        params: { product_id, option_id },
        data,
      })
      .fetch();

    runInAction(() => {
      if (result.status === "success") {
        // NOTE: preserve empty input so user can continue enter
        this.addOptionValue("", foundOptionIndex);

        this.getProductVariants(product_id);
      } else {
        this._sourceOptionList[foundOptionIndex].option_values.splice(
          foundOptionValueIndex,
          0,
          cloneDeep(deletedOptionValue),
        );
        this.optionsList[foundOptionIndex].option_values.splice(
          foundOptionValueIndex,
          0,
          deletedOptionValue,
        );
      }

      this._submittingOption = false;
    });
  }
  /* Requests ↑ */

  public resetStore() {
    this._sourceOptionList = [];
    this._loadingOptionsList = true;
    this.optionsList = [{ name: "", option_values: [{ value: "" }] }];
    this._submittingOption = false;
    this._deletingVariantOption = false;
    this._loadingVariantList = true;
    this.variantList = [];
    this._deletingVariant = false;
    this._isSidebarOpened = false;
    this._isSidebarFinalOpened = false;
  }

  private _checkAvailableOptions() {
    this.availableOptions = arrayDifference(
      availableOptions,
      this.optionsList.map(({ name }) => name),
    );
  }
}

export const productVariantsStore = new ProductVariantsStore();
