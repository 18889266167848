export const validatePrice = (price: any) => {
  if (price === 0) {
    return "Price cannot be set to 0";
  }

  if (Number(price) < 0) {
    return "This field is invalid.";
  }

  return "";
};
