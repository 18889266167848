import { dashboardApiMiddleware } from "src/api";
import { PostStripeConnectAccountResponseData } from "./stripe.interface";

export const postStripeConnectAccount =
  dashboardApiMiddleware<PostStripeConnectAccountResponseData>()({
    method: "POST",
    endpoint: "/api/stripe/connect-account",
  });

export const postStripeWebhook = dashboardApiMiddleware()({
  method: "POST",
  endpoint: "/api/stripe/webhook",
});
