import { AttributeModel, ProductParameterRequestModel } from "src/models";
import { AttributeFieldType } from "./filters-sidebar.types";

type GetAttributesFieldsReturnType = AttributeFieldType[];
export const getAttributesFields = (
  attributesFilters: ProductParameterRequestModel[],
  attributesList: AttributeModel[],
): GetAttributesFieldsReturnType => {
  return attributesList.reduce(
    (acc: GetAttributesFieldsReturnType, { type, name, displayName, id, values }) => {
      let resultFilter: AttributeFieldType = {
        type,
        id,
        values,
        name: displayName || name,
        value: "",
      };
      const filter = attributesFilters.find(({ attribute_id }) => attribute_id === id);

      if (filter) resultFilter = { ...resultFilter, value: filter.value as number | string };

      return [...acc, resultFilter];
    },
    [],
  );
};
