import React from "react";
import { observer } from "mobx-react-lite";
import { FilterCollapse, Input, Nullable, Range } from "@gemlightbox/core-kit";

import { ProductPriceRangeModel } from "src/models";
import { useStores } from "src/hooks";

import styles from "./price-filter.module.css";

export interface PriceFilterProps {
  value: Array<Nullable<number>>;
  priceRange: ProductPriceRangeModel;
  isFromValid: boolean;
  isToValid: boolean;
  onFromChange: (value: number) => void;
  onToChange: (value: number) => void;
  onRangeChange: (value: number[]) => void;
}

export const PriceFilter: React.FC<PriceFilterProps> = observer(
  ({ value, priceRange, isFromValid, isToValid, onFromChange, onToChange, onRangeChange }) => {
    const { localeStore } = useStores();
    const { min, max } = priceRange;

    const beforeChange = (type: "from" | "to") => (value: string) => {
      const _value = Number(value);

      if (type === "from") onFromChange(_value);
      if (type === "to") onToChange(_value);
    };

    return (
      <FilterCollapse
        data-cy="price-filter-collapse"
        title={localeStore.t(
          'products["products-list"]["products-table"]["products-table-header"].price',
        )}
      >
        <div className={styles.container}>
          <div className={styles.inputs}>
            <Input
              data-cy="price-input-min"
              appearance="primaryV2"
              value={value[0] ?? ""}
              placeholder={min ? min.toString() : ""}
              onChange={beforeChange("from")}
              error={!isFromValid}
            />
            <Input
              data-cy="price-input-max"
              appearance="primaryV2"
              value={value[1] ?? ""}
              placeholder={max ? max.toString() : ""}
              onChange={beforeChange("to")}
              error={!isToValid}
            />
          </div>
          <Range
            data-cy="price-field-range"
            appearance="secondary"
            min={min ?? 0}
            max={max ?? 0}
            value={[value[0] ?? min ?? 100, value[1] ?? max ?? 10000]}
            onChange={(_value) => onRangeChange(_value as number[])}
          />
        </div>
      </FilterCollapse>
    );
  },
);
