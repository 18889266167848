import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Switch, Typography, useWindowSize } from "@gemlightbox/core-kit";

import { ProductCard } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ExtendedProductModel } from "src/store/products";
import { getProductLink, downloadVideo } from "src/utils";

import styles from "./products-list.module.css";
import { ShareType } from "src/external-ts/components/business/share-setting-modal/share-setting-modal.constants";
import { ResourceType } from "src/api/graphql-api/share-settings/share-settings.constants";
import { useShare } from "src/hooks/use-share.hook";

export const ProductsList: React.FC = observer(() => {
  const navigate = useNavigate();

  const { catalogStore, modalsStore, localeStore, notificationStore } = useStores();
  const { handleShare: handleShareProduct } = useShare();

  const { windowWidth } = useWindowSize();

  const handleOrder = () => {
    if (!catalogStore.catalog) return;
    catalogStore.toggleOrderModalOpenStatus();
  };

  const handleSelect = (product: ExtendedProductModel) => {
    catalogStore.toggleProductsList(product, "selected");
  };

  const handleView = (product: ExtendedProductModel) => {
    window.open(getProductLink(product.link.uuid), "_blank");
  };

  const handleDownload = (product: ExtendedProductModel) => {
    const isVideo = product.images[0].type === "video" || product.images[0].type === "video360";
    if (isVideo && product.images.length === 1) {
      downloadVideo(product.images[0].id, product as ExtendedProductModel);
      return;
    }
    modalsStore.open("DownloadMediaModal", { media: [product], type: "products" });
  };

  const handleShare = (product: ExtendedProductModel) => {
    handleShareProduct({
      resourceType: ResourceType.PRODUCT,
      resourceIds: [product._id],
      shareType: ShareType.SHARE_PRODUCT,
    });
  };

  const handleOpenProduct = (product: ExtendedProductModel) => {
    navigate(`/product/${product._id}`);
  };

  const handleEdit = (product: ExtendedProductModel) => {
    navigate(`/product/${product._id}/edit`);
  };

  const handleDelete = (product: ExtendedProductModel) => {
    notificationStore.open({
      title: localeStore.t('["catalog-details"]["products-list"]["delete-notification"].title'),
      confirmText: localeStore.t(
        '["catalog-details"]["products-list"]["delete-notification"]["ok-text"]',
      ),
      cancelText: localeStore.t(
        '["catalog-details"]["products-list"]["delete-notification"]["cancel-text"]',
      ),
      onOk: () => catalogStore.unassignProducts(product),
    });
  };

  const handleChangeSorting = (value: boolean) => {
    if (!value) {
      catalogStore.updateCatalog({ sortOrder: null });
      return;
    }
    const orderToSet = catalogStore.catalog?.sortRule?.title ? "fromZtoA" : "fromAtoZ";
    catalogStore.updateCatalog({ sortOrder: orderToSet });
  };

  const handleChangeDirection = (value: boolean) => {
    const orderToSet = value ? "fromZtoA" : "fromAtoZ";
    catalogStore.updateCatalog({ sortOrder: orderToSet });
  };

  const isAllCatalog = catalogStore.catalog?.is_all;

  const disableDelete = isAllCatalog || catalogStore.isAutoCatalog;

  const shouldRenderManageOrderButton =
    catalogStore.catalog && !isAllCatalog && !catalogStore.isAutoCatalog;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Typography size="medium600" color="textSecondary">
          {localeStore.t('["catalog-details"]["products-list"].title')}
        </Typography>
        <div className={styles.manageOrderPanel}>
          <div className={styles.sortSwitches}>
            <Typography
              size={windowWidth > 768 ? "small600" : "extraSmall500"}
              color="textSecondary"
            >
              {localeStore.t('["catalog-details"]["products-list"].sorting')}
            </Typography>
            <Switch
              checked={!!catalogStore.catalog?.sortRule?.title}
              onChange={handleChangeSorting}
            />
            <Switch
              checked={catalogStore.catalog?.sortRule?.title === "fromZtoA"}
              onChange={handleChangeDirection}
              disabled={!catalogStore.catalog?.sortRule?.title}
              color={catalogStore.catalog?.sortRule?.title ? "primary" : "textDisabled"}
              checkedColor={catalogStore.catalog?.sortRule?.title ? "primary" : "textDisabled"}
              label="A-Z"
              checkedLabel="Z-A"
            />
          </div>
          {shouldRenderManageOrderButton && (
            <Button
              className={styles.manageOrderBtn}
              disabled={!!catalogStore.catalog?.sortRule?.title}
              appearance="primaryOutlined"
              size="small"
              onClick={handleOrder}
            >
              {localeStore.t('["catalog-details"]["products-list"].buttons["manage-order"]')}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.listContainer}>
        {catalogStore.products.map((product) => (
          <ProductCard
            key={product._id}
            className={styles.productContainer}
            titleContainerClassName={styles.productTitleContainer}
            data={product}
            onSelect={handleSelect}
            onView={handleView}
            onDownload={handleDownload}
            onShare={handleShare}
            onOpenProduct={handleOpenProduct}
            onEdit={handleEdit}
            onDelete={disableDelete ? undefined : handleDelete}
          />
        ))}
      </div>
    </div>
  );
});
