import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import {
  ExpandableSidebar,
  loadImage,
  ActionsBtn,
  Nullable,
  Button,
  SvgIcon,
  useDidMount,
  ActionOption,
  useMediaBreakpoints,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { downloadMedias, proFeatureTooltip } from "src/utils";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { AR_MEDIA_PAGE } from "src/constants";
import { MediaItemDetails } from "./media-item-details";
import { MediaItemView } from "./media-item-view";
import { MediaDetailsSidebarProps } from "./media-details-sidebar.types";

import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as DownloadSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as BlinkSVG } from "src/external-ts/assets/images/media/blink-type.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as GeneratePostSVG } from "src/external-ts/assets/images/fountain-pen-grey.svg";
import { ReactComponent as SocialsSVG } from "src/external-ts/assets/images/socials-icons.svg";
import { ReactComponent as ProBadgeSVG } from "src/external-ts/assets/images/badges/pro-badge-yellow.svg";
import styles from "./media-details-sidebar.module.css";
import { PendingUploadStatus } from "src/models";
import { ResourceType } from "src/api/graphql-api/share-settings/share-settings.constants";
import { ShareType } from "src/external-ts/components/business/share-setting-modal/share-setting-modal.constants";

export const MediaDetailsSidebar: React.FC<MediaDetailsSidebarProps> = observer(
  ({ isOpen, options, setClose, onFinalClosed }: MediaDetailsSidebarProps) => {
    const { data, handleShareMedia } = options;
    const { isMobileMedia } = useMediaBreakpoints();
    const navigate = useNavigate();

    const { modalsStore, localeStore, subscriptionsStore, tooltipStore } = useStores();
    const { canBlinks, canGeneratePosts } = useLimits();

    const [image, setImage] = useState<Nullable<HTMLImageElement>>(null);

    const isUploading = data?.status === PendingUploadStatus.pending;
    const isBlink = data.type === "blink";
    const isImage = data.type === "image";
    const disabledBlink = !canBlinks || isUploading || !!data.metaData?.isEclipseMode;

    // NOTE: Using 'data?.arTemplateId' due to not all blinks are editable, but only one's,
    // that has necessary fields
    const canShowEditMediaBlink = isBlink && !!data?.arTemplateId;
    const canShowEditMediaImage = isImage;
    const canShowEdit = canShowEditMediaBlink || canShowEditMediaImage;

    const isEditDisabled = isBlink && !canBlinks;

    const handleShare = () => {
      handleShareMedia({
        resourceType: ResourceType.MEDIA,
        resourceIds: [data.id],
        shareType: ShareType.SHARE_MEDIA,
      });
    };
    // modalsStore.open("ShareModal", { type: "media", data, tab: "shareLink" });

    const handleEditPointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
      if (!isEditDisabled) return;
      proFeatureTooltip(e.currentTarget, {
        position: "left",
      });
    };

    const handleEditPointerLeave = () => {
      if (!isEditDisabled) tooltipStore.close();
    };

    const handleEditClick = () => {
      setClose();
      if (canShowEditMediaImage) navigate(`/media/${data.id}/edit`);
      if (canShowEditMediaBlink) {
        navigate(AR_MEDIA_PAGE.path, {
          state: {
            media: toJS(data),
            type: "media",
            redirect: location.pathname,
          },
        });
      }
    };

    const handleCreateBlink = () => {
      setClose();
      return navigate(AR_MEDIA_PAGE.path, {
        state: {
          media: toJS(data),
          type: "media",
        },
      });
    };

    const handleDownload = () => downloadMedias([data]);

    // const handleGeneratePost = () => {
    //   modalsStore.open("ShareModal", { type: "media", data: data, tab: "generatePost" });
    // };

    const handlePointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
      if (subscriptionsStore.isProSubscription) return;
      proFeatureTooltip(e.currentTarget, {
        position: "left",
      });
    };

    const handleDelete = () =>
      modalsStore.open("DeleteMediaModal", { media: [data], onDelete: setClose });

    useDidMount(async () => {
      if (data.type !== "image") return;

      // preload image
      if (data.url) {
        const img = await loadImage(data.url, true);
        setImage(img);
      }
    });

    return (
      <ExpandableSidebar
        title={localeStore.t('media.modals["media-details-sidebar"].title')}
        icon="cross"
        iconPos={isMobileMedia ? "right" : "outside"}
        contentClassName={styles.mediaSidebar}
        sidebarContentClassName={styles.bodyWrapper}
        sidebarHeaderClassName={styles.headerWrapper}
        header={
          <div className={styles.buttons}>
            <Button className={styles.share} onClick={handleShare} disabled={isUploading}>
              <SvgIcon icon={ShareSVG} />
              {!isMobileMedia &&
                localeStore.t('media.modals["media-details-sidebar"].buttons.share')}
            </Button>
            <ActionsBtn
              actionBtnClassName={styles.actionsBtn}
              optionsWrapperClassName={styles.optionsWrapper}
            >
              {canShowEdit && (
                <ActionOption
                  className={styles.action}
                  onPointerEnter={handleEditPointerEnter}
                  onPointerLeave={handleEditPointerLeave}
                  onClick={handleEditClick}
                  disabled={isEditDisabled || isUploading}
                >
                  <SvgIcon icon={EditSVG} />
                  {localeStore.t("common.buttons.edit")}
                </ActionOption>
              )}
              <ActionOption
                className={styles.action}
                onClick={handleDownload}
                disabled={isUploading}
              >
                <SvgIcon icon={DownloadSVG} />
                {localeStore.t("common.buttons.download")}
              </ActionOption>
              {data.type === "image" && (
                <ActionOption
                  className={styles.action}
                  onClick={handleCreateBlink}
                  disabled={disabledBlink}
                >
                  <SvgIcon icon={BlinkSVG} />
                  {localeStore.t(
                    'media["media-list"]["media-item"]["media-actions"]["create-try-on-link"]',
                  )}
                </ActionOption>
              )}
              {/* {data.sku && (
                <ActionOption
                  onClick={handleGeneratePost}
                  data-cy="media-sidebar-generate-post"
                  className={styles.generatePostAction}
                  disabled={!canGeneratePosts || isUploading}
                  onPointerEnter={handlePointerEnter}
                >
                  <SvgIcon icon={GeneratePostSVG} />
                  {localeStore.t(
                    'media["media-list"]["media-item"]["media-actions"]["generate-ai-post"]',
                  )}
                  <SvgIcon icon={SocialsSVG} className={styles.socialsIcons} />
                  <SvgIcon icon={ProBadgeSVG} className={styles.proBadge} />
                </ActionOption>
              )} */}
              <ActionOption className={styles.action} onClick={handleDelete}>
                <SvgIcon className={styles.deleteIcon} icon={DeleteSVG} />
                {localeStore.t("common.buttons.delete")}
              </ActionOption>
            </ActionsBtn>
          </div>
        }
        setClose={setClose}
        isOpen={isOpen}
        onFinalClosed={onFinalClosed}
      >
        <MediaItemView
          image={image}
          media={data}
          onEdit={handleEditClick}
          closeSidebar={setClose}
        />
        <MediaItemDetails mediaData={data} />
      </ExpandableSidebar>
    );
  },
);
