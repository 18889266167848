import { ObjectType } from "@gemlightbox/core-kit";

import { ProductType } from "src/models";
import { getStaticUrl } from "src/utils";
import { ArMediaFormType } from "./ar-media.types";

export const supportedTypes = [
  ProductType.rings,
  ProductType.earrings,
  ProductType.watches,
  ProductType.pendants,
  ProductType.bracelets,
  ProductType.gemstones,
  ProductType.necklaces,
];

export const heightRequiredTypes = [
  ProductType.earrings,
  ProductType.pendants,
  ProductType.necklaces,
  ProductType.gemstones,
];

export const productTypesMap: ObjectType<{
  type: string;
  label: string;
  icon: string;
}> = {
  bracelets: {
    type: "bracelets",
    label: "Bracelet",
    icon: getStaticUrl("/bracelets-type-icon.svg"),
  },
  necklaces: {
    type: "necklaces",
    label: "Necklace",
    icon: getStaticUrl("/necklaces-type-icon.svg"),
  },
  rings: { type: "rings", label: "Ring", icon: getStaticUrl("/rings-type-icon.svg") },
  watches: { type: "watches", label: "Watch", icon: getStaticUrl("/watches-type-icon.svg") },
  earrings: { type: "earrings", label: "Earrings", icon: getStaticUrl("/earrings-type-icon.svg") },
  gemstones: {
    type: "gemstones",
    label: "Gemstone",
    icon: getStaticUrl("/gemstones-type-icon.svg"),
  },
  pendants: { type: "pendants", label: "Pendant", icon: getStaticUrl("/pendants-type-icon.svg") },
};

export const measureOptions = [
  { label: "inch.", value: "inch" },
  { label: "cm", value: "cm" },
];

export const PIXELS_IN_CM_ALL = 160;
export const PIXELS_IN_CM_GEMSTONE = 82;

export const initialValues: ArMediaFormType = {
  sku: "",
  unit: "inch",
  width: "",
  height: "",
};
