import React from "react";

export const withErrorBoundary = (WrapperComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        error: undefined,
      };
    }

    static getDerivedStateFromError(error) {
      return { error };
    }

    componentDidCatch(error, errorInfo) {
      console.error(error, errorInfo);
    }

    render() {
      const { error } = this.state;

      if (error) {
        return <div className="headline"> Something went wrong </div>;
      }

      return <WrapperComponent {...this.props} />;
    }
  };
};
