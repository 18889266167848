import { ProductType } from "src/models";
import { AugmentedRealityType } from "./augmented-reality-block.types";

export const augmentedRealityBlockSubId = "augmentedRealityBlockSubId";

export const augmentedRealityTypes: AugmentedRealityType[] = [
  {
    type: ProductType.rings,
    tooltipText: "Rings",
    jewelryIcon: "/rings-type-icon.svg",
    jewelryThumbnail: "/rings-type-thumbnail.png",
  },
  {
    type: ProductType.earrings,
    tooltipText: "Earrings",
    jewelryIcon: "/earrings-type-icon.svg",
    jewelryThumbnail: "/earrings-type-thumbnail.png",
  },
  {
    type: ProductType.watches,
    tooltipText: "Watches",
    jewelryIcon: "/watches-type-icon.svg",
    jewelryThumbnail: "/watches-type-thumbnail.png",
  },
  {
    type: ProductType.necklaces,
    tooltipText: "Necklaces",
    jewelryIcon: "/necklaces-type-icon.svg",
    jewelryThumbnail: "/necklaces-type-thumbnail.png",
  },
  {
    type: ProductType.pendants,
    tooltipText: "Pendants",
    jewelryIcon: "/pendants-type-icon.svg",
    jewelryThumbnail: "/pendants-type-thumbnail.png",
  },
  {
    type: ProductType.bracelets,
    tooltipText: "Bracelets",
    jewelryIcon: "/bracelets-type-icon.svg",
    jewelryThumbnail: "/bracelets-type-thumbnail.png",
  },
  {
    type: ProductType.gemstones,
    tooltipText: "Gemstones",
    jewelryIcon: "/gemstones-type-icon.svg",
    jewelryThumbnail: "/gemstones-type-thumbnail.png",
  },
];

export const targetOptions = { eventOpts: { passive: false } };
