import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { ActionsBtn, Search, useDebounce, ActionOption } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ProductPriceRangeModel } from "src/models";
import { getProductPriceRange } from "src/api";
import { FiltersButton, ViewTypes, ViewVariant } from "src/external-ts/components";
import { PRODUCT_CREATE_PAGE } from "src/constants";
import { IntegrationButton } from "./integration-button/integration-button";

import styles from "./products-control-panel.module.css";

export const ProductsControlPanel: React.FC = observer(() => {
  const { debounce } = useDebounce(150);
  const { modalsStore, productsStore, notificationStore, localeStore, userStore } = useStores();

  const navigate = useNavigate();

  const handleCreate = () => {
    if (!userStore.isCTFSub) {
      return navigate(PRODUCT_CREATE_PAGE.path);
    }
    return notificationStore.open({
      title: localeStore.t('products["products-control-panel"]["restricted-modal"].title'),
      message: localeStore.t('products["products-control-panel"]["restricted-modal"].message'),
      confirmText: localeStore.t(
        'products["products-control-panel"]["restricted-modal"]["ok-text"]',
      ),
      cancelText: "",
      icon: "crossRoundedFilled",
      confirmAppearance: "primary",
      onlyConfirm: true,
    });
  };

  const handleImport = () => {
    if (!userStore.isCTFSub) {
      return modalsStore.open("ProductsImportModal", {});
    }
    return notificationStore.open({
      title: localeStore.t('products["products-control-panel"]["restricted-modal"].title'),
      message: localeStore.t('products["products-control-panel"]["restricted-modal"].message'),
      confirmText: localeStore.t(
        'products["products-control-panel"]["restricted-modal"]["ok-text"]',
      ),
      cancelText: "",
      icon: "crossRoundedFilled",
      confirmAppearance: "primary",
      onlyConfirm: true,
    });
  };

  const handleExport = () => {
    return modalsStore.open("ExportAllProductsModal", {});
  };

  const handleManageAttributes = () => {
    return modalsStore.open("ManageAttributesSidebar", {});
  };

  const handleManageColumns = () => {
    return modalsStore.open("ManageColumnsSidebar", {});
  };

  const handleSearch = (title: string) => {
    const filters = { ...productsStore.filters, title, page: 1 };

    productsStore.setFilters(filters);
    debounce(() => productsStore.loadProductsList(filters));
  };

  const handleFilters = async () => {
    const { success } = await getProductPriceRange.getRequest().fetch();
    let priceRange: ProductPriceRangeModel = {
      min: null,
      max: null,
    };

    if (success) {
      priceRange = success;
    }

    modalsStore.open("FiltersSidebar", {
      initialFilters: productsStore.initialFilters,
      appliedFilters: productsStore.filters,
      priceRange,
      renderNumberAsRange: true,
      onApply: (filters) => productsStore.loadProductsList(filters),
      onClear: () => {
        // productsStore.resetFilters();
        // productsStore.loadProductsList();
      },
    });
  };

  return (
    <div className={styles.container} data-cy="products-control-panel">
      <div className={styles.left}>
        <Search
          placeholder={localeStore.t("components.business.search.placeholder")}
          searchValue={productsStore.filters.title}
          data-cy="products-search"
          setSearch={handleSearch}
        />
        <FiltersButton isActive={productsStore.filtersAmount !== 0} onClick={handleFilters} />
        <ViewVariant
          type={productsStore.view}
          onChange={(view) => {
            productsStore.view = view as ViewTypes;
          }}
        />
        <IntegrationButton />
      </div>
      <div className={styles.right}>
        <ActionsBtn
          text={localeStore.t('products["products-control-panel"]["action-button-text"]')}
          data-cy="products-create-button"
          actionBtnClassName={styles.actions}
        >
          <ActionOption className={styles.action} onClick={handleCreate} data-cy="create-product">
            {localeStore.t('products["products-control-panel"]["product-actions"].create')}
          </ActionOption>
          <ActionOption className={styles.action} onClick={handleImport} data-cy="import-products">
            {localeStore.t('products["products-control-panel"]["product-actions"].import')}
          </ActionOption>
          <ActionOption
            className={styles.action}
            onClick={handleManageAttributes}
            data-cy="manage-attributes"
          >
            {localeStore.t(
              'products["products-control-panel"]["product-actions"]["manage-attributes"]',
            )}
          </ActionOption>
          <ActionOption
            className={styles.action}
            onClick={handleManageColumns}
            data-cy="manage-columns"
          >
            {localeStore.t(
              'products["products-control-panel"]["product-actions"]["manage-columns"]',
            )}
          </ActionOption>
          <ActionOption className={styles.action} onClick={handleExport} data-cy="export-all">
            {localeStore.t('products["products-control-panel"]["product-actions"].export')}
          </ActionOption>
        </ActionsBtn>
      </div>
    </div>
  );
});
