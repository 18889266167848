import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, Tooltip, useBoolean, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { AttributeModel } from "src/models";
import { getAttributeDisplayName } from "src/utils";
import { AttributeTypeLabelMap } from "src/constants";

import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";

import styles from "./custom-attribute.module.css";

export interface CustomAttributeProps {
  data: AttributeModel;
  onEdit?: (data: AttributeModel) => void;
  onDelete?: (data: AttributeModel) => void;
}

export const CustomAttribute: React.FC<CustomAttributeProps> = observer(
  ({ data, onEdit, onDelete }) => {
    const { localeStore, userStore } = useStores();

    const buttonRef = useRef<HTMLButtonElement>(null);

    const isTooltipOpen = useBoolean();

    const canDelete = !userStore.isSubaccount;

    const handleEdit = () => onEdit?.(data);
    const handleDelete = () => onDelete?.(data);

    return (
      <div data-name={`${getAttributeDisplayName(data)}-attribute`} className={styles.container}>
        <div className={styles.textContainer}>
          <Typography size="small600" color="textSecondary">
            {getAttributeDisplayName(data)}
          </Typography>
          <div className={styles.subtitle}>
            <Typography size="small" color="textTertiary">
              {localeStore.t(
                'products.modals["manage-attributes-sidebar"]["attributes-list"].subtitle',
              )}
            </Typography>
            <Typography size="small" color="textSecondary">
              {AttributeTypeLabelMap[data.type]}
            </Typography>
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          <Button data-cy="edit-attribute" appearance="tertiaryOutlined" onClick={handleEdit}>
            <SvgIcon icon={EditSVG} />
          </Button>

          <Button
            data-cy="delete-attribute"
            appearance="tertiaryOutlined"
            onClick={handleDelete}
            forwardRef={buttonRef}
            disabled={!canDelete}
            onMouseOver={isTooltipOpen.setTruthy}
            onMouseOut={isTooltipOpen.setFalsy}
          >
            <SvgIcon icon={DeleteSVG} />
            <Tooltip
              className={styles.tooltip}
              appearance="secondary"
              position="left"
              target={buttonRef}
              withAngle
              isOpen={isTooltipOpen.value && !canDelete}
              onClose={isTooltipOpen.setValue}
            >
              {localeStore.t(
                'products.modals["manage-attributes-sidebar"]["subbacount-delete-warning"]',
              )}
            </Tooltip>
          </Button>
        </div>
      </div>
    );
  },
);
