import React from "react";
import { observer } from "mobx-react-lite";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { SvgIcon, clsx, Typography } from "@gemlightbox/core-kit";

import { AttributesIdMap } from "src/store";
import { ColumnAttributeType } from "src/store";
import { MandatoryTooltip } from "./mandatory-tooltip";

import { ReactComponent as DraggableSVG } from "src/external-ts/assets/images/products-import/draggable-grey.svg";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./attributes-list.module.css";

export interface AttributesListProps {
  title: string;
  id: string;
  attributes: ColumnAttributeType[];
}

export const AttributesList: React.FC<AttributesListProps> = observer(
  ({ title, id, attributes }) => {
    const isMandatory = id === AttributesIdMap.mandatory;

    if (attributes.length === 0) return null;

    return (
      <Droppable droppableId={id}>
        {(provided) => (
          <div ref={provided.innerRef} className={styles.container}>
            <Typography className={styles.title} size="extraSmall500" color="textTertiary">
              {title}
              {isMandatory && <MandatoryTooltip />}
            </Typography>
            <div className={styles.list}>
              {attributes.map(({ id, displayName }, index) => (
                <Draggable draggableId={id} key={id} index={index}>
                  {(provided) => (
                    <div
                      className={styles.draggable}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <SvgIcon icon={DraggableSVG} />
                      <div className={clsx(globalStyles.applySingleOverflow, styles.text)}>
                        {displayName}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  },
);
