import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaBreakpoints } from "@gemlightbox/core-kit";

import { DesktopLayout } from "./desktop-layout";
import { TabletLayout } from "./tablet-layout";
import { MobileLayout } from "./mobile-layout";
import { useStores } from "src/hooks";

export const Navbar: React.FC = observer(() => {
  const { isMobileMedia, isTabletMedia, isDesktopMedia } = useMediaBreakpoints(undefined, {
    mobileBreakpoint: 767,
  });
  const { userStore } = useStores();
  const isTablet = isTabletMedia && !isMobileMedia;

  return (
    <>
      {isDesktopMedia && !window.$platform.isCompact && <DesktopLayout />}
      {isTablet && !window.$platform.isCompact && <TabletLayout />}
      {isMobileMedia && !window.$platform.isCompact && <MobileLayout />}
    </>
  );
});
