import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal, Heading, useCall, useDidMount, useWindowSize } from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import {
  ExtendedCatalogFullModel,
  ExtendedCatalogModel,
  ExtendedMediaModel,
  ModalExtendedType,
} from "src/store";
import { useStores } from "src/hooks";
import { CatalogModel, MediaCollectionModel } from "src/models";
import { ExtendedProductModel } from "src/store/products";
import { postLinkPassword } from "src/api";
import { pushShareDataLayerEvent } from "src/utils";
import { ShareModalNavKeys, ShareModalTypeKeys } from "./share-modal.types";
import { ShareModalNavigation } from "./share-modal-navigation";
import { GeneratePostContent } from "./generate-post-content";
import { ShareLinkContent } from "./shared-link-content";
import { EmbedWidgetContent } from "./embed-widget-content";
import { EmbedButtonContent } from "./embed-button-content";
import { EmbedCodeContent } from "./embed-code-content";

import styles from "./share-modal.module.css";

export type ShareModalProps = ModalExtendedType<{
  type: ShareModalTypeKeys;
  data:
    | ExtendedMediaModel
    | MediaCollectionModel
    | ExtendedProductModel
    | ExtendedCatalogModel
    | ExtendedCatalogFullModel
    | CatalogModel;
  tab: ShareModalNavKeys;
  onClose?: VoidFunction;
}>;

export const ShareModal: React.FC<ShareModalProps> = observer(
  ({ options, isOpen, setClose, onFinalClosed }) => {
    const { windowWidth } = useWindowSize();

    const { type, data, onClose } = options;

    const {
      mediaStore,
      productsStore,
      catalogStore,
      catalogsStore,
      localeStore,
      notificationStore,
    } = useStores();

    const [tab, setTab] = useState<ShareModalNavKeys>(options.tab);

    const { submit, onCallSuccess } = useCall(postLinkPassword.setParams({ id: data.link.id }));
    onCallSuccess(() => {
      handlePrivacyChange(false);
    });

    const isMedia = type === "media";
    const isProduct = type === "product";
    const isMediaCollection = type === "mediaCollection";
    const isCatalogListItem = type === "catalogListItem";
    const isCatalogFullItem = type === "catalogFullItem";
    const isCatalog = isCatalogListItem || isCatalogFullItem;

    useDidMount(() => pushShareDataLayerEvent(type));

    const handleChangeTab = (value: ShareModalNavKeys) => {
      const isPrivate = data.link.isPrivate;
      const isEmbedButton = value === "embedButton";
      const isEmbedCode = value === "embedCode";

      if (isPrivate && (isEmbedCode || isEmbedButton)) {
        notificationStore.open({
          title: localeStore.t('components.business["share-modal"]["pop-up"].title'),
          message: localeStore.t('components.business["share-modal"]["pop-up"].message'),
          confirmText: localeStore.t("common.buttons.yes"),
          cancelText: localeStore.t('components.business["share-modal"]["pop-up"]["cancel-text"]'),
          onOk: async () => {
            await submit({ data: { password: null } });
            setTab(value);
          },
        });
      } else {
        setTab(value);
      }
    };

    const handlePrivacyChange = (isPrivate: boolean) => {
      if (isMedia) {
        const d = data as ExtendedMediaModel;
        mediaStore.updateMediaLinkPrivacyLocal(d, isPrivate);
      }
      if (isProduct) {
        const d = data as ExtendedProductModel;
        productsStore.updateProductsLinkPrivacyLocal(d, isPrivate);
      }
      if (isCatalogListItem) {
        const d = data as ExtendedCatalogModel;
        catalogsStore.updateCatalogLinkPrivacyLocal(d, isPrivate);
      }
      if (isCatalogFullItem) {
        catalogStore.updateCatalogLinkPrivacyLocal(isPrivate);
      }
    };

    const handleCatalogProductsPrivacyChange = (isPrivate: boolean) => {
      if (isCatalogFullItem) {
        catalogStore.updateCatalogProductsLinkPrivacyLocal(isPrivate);
      }
    };

    const handleClose = () => {
      setClose();
      onClose?.();
    };

    const isMobile = windowWidth < BREAKPOINTS.mobileL;
    const isPdf = data?.type === "pdf";

    return (
      <Modal
        scrollWrapperClassName={styles.modalScrollWrapper}
        contentClassName={styles.modalContent}
        size="medium"
        name="share-modal"
        setClose={handleClose}
        onFinalClosed={onFinalClosed}
        isOpen={isOpen}
        withCross
        data-cy="share-modal"
      >
        <Heading className={styles.title} tag={isMobile ? "h3" : "h2"} color="textSecondary">
          {localeStore.t('components.business["share-modal"].title')}
        </Heading>
        {!isMediaCollection && (
          <ShareModalNavigation
            isCatalog={isCatalog}
            isMedia={isMedia}
            isPdf={isPdf}
            mediaWithNoSku={!data.sku}
            tab={tab}
            onChange={handleChangeTab}
            onClose={setClose}
          />
        )}

        {(type === "media" || type === "product") && tab === "generatePost" && (
          <GeneratePostContent
            type={type}
            data={data as ExtendedProductModel | ExtendedMediaModel}
            setClose={setClose}
          />
        )}
        {tab === "shareLink" && (
          <ShareLinkContent type={type} data={data} onPrivacyChange={handlePrivacyChange} />
        )}
        {tab === "embedButton" && <EmbedButtonContent type={type} data={data} />}
        {tab === "embedCode" && <EmbedCodeContent type={type} data={data} />}
        {tab === "embedCatalog" && (
          <EmbedWidgetContent
            data={data}
            onPrivacyChange={handlePrivacyChange}
            onProductsPrivacyChange={handleCatalogProductsPrivacyChange}
          />
        )}
      </Modal>
    );
  },
);
