import { useStores } from "src/hooks";
import { mediaSortOptions } from "./media.constants";

export const openBackgroundRemovalErrorNotification = (message: string) => {
  const { notificationStore, localeStore } = useStores();

  notificationStore.open({
    title: localeStore.t(
      'media["media-control-panel"].filters["filters-dialog"]["modal-titles"].background',
    ),
    message: message,
    confirmText: localeStore.t("common.buttons.confirm"),
    cancelText: "",
    onlyConfirm: true,
  });
};

export const openRetouchErrorNotification = (message: string) => {
  const { notificationStore, localeStore } = useStores();

  notificationStore.open({
    title: localeStore.t(
      'media["media-control-panel"].filters["filters-dialog"]["modal-titles"].retouch',
    ),
    message: message,
    confirmText: localeStore.t("common.buttons.confirm"),
    cancelText: "",
    onlyConfirm: true,
  });
};

export const openDustRemovalErrorNotification = (message: string) => {
  const { notificationStore, localeStore } = useStores();

  notificationStore.open({
    title: localeStore.t(
      'media["media-control-panel"].filters["filters-dialog"]["modal-titles"].dust',
    ),
    message: message,
    confirmText: localeStore.t("common.buttons.confirm"),
    cancelText: "",
    onlyConfirm: true,
  });
};

export const getMediaSortOptionsLocalized = () => {
  const { localeStore } = useStores();

  return mediaSortOptions.map(({ value, label }) => {
    switch (value) {
      case "all":
        return {
          label: localeStore.t(
            'media["media-control-panel"].filters["filters-dialog"]["media-options"].all',
          ),
          value,
        };
      case "withoutSkuFirst":
        return {
          label: localeStore.t(
            'media["media-control-panel"].filters["filters-dialog"]["media-options"]["without-sku-first"]',
          ),
          value,
        };
      case "skuFirst":
        return {
          label: localeStore.t(
            'media["media-control-panel"].filters["filters-dialog"]["media-options"]["sku-first"]',
          ),
          value,
        };
      default:
        return { label, value };
    }
  });
};
