import React, { memo } from "react";
import { clsx } from "@gemlightbox/core-kit";

import styles from "./auth-aside.module.css";

export type AuthAsideProps = {
  children: React.ReactNode;
  className?: string;
};

export const AuthAside: React.FC<AuthAsideProps> = memo(
  ({ children, className = "" }: AuthAsideProps) => {
    return <div className={clsx(styles.authAsideContainer, className)}>{children}</div>;
  },
);

export default AuthAside;
