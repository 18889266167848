import { clsx } from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "src/hooks";

import { ReactComponent as ProductThumbnail } from "../../../images/no-media-product-thumbnail.svg";

import styles from "./products-card-without-media.module.css";

type ProductsCardWithoutMediaProps = {
  style?: React.CSSProperties;
};

export const ProductsCardWithoutMedia: React.FC<ProductsCardWithoutMediaProps> = observer(
  ({ style }) => {
    const { modalsStore, localeStore, productsStore } = useStores();

    const handleCardClick = () => modalsStore.open("NoMediaProductsSidebar", {});

    const thumbnails = new Array(Math.min(productsStore.productsWithoutMediaAmount, 3)).fill(
      <ProductThumbnail />,
    );
    const remain = productsStore.productsWithoutMediaAmount - thumbnails.length;

    return (
      <div className={styles.container} style={style} onClick={handleCardClick}>
        <div className={styles.imageContainer}>
          <div className={styles.imageFiller} />
          <div className={styles.thumbnails}>
            {thumbnails.map((thumbnail, i) => (
              <div key={i} className={clsx(styles.itemContainer)}>
                {thumbnail}
              </div>
            ))}
            {remain > 0 && (
              <div className={clsx(styles.itemContainer, styles.totalAmount)}>+{remain}</div>
            )}
          </div>
        </div>
        <div className={styles.title}>
          {localeStore.t(
            'products["products-list"]["grid-view"]["products-card-without-media"].title',
          )}
        </div>
      </div>
    );
  },
);

export default ProductsCardWithoutMedia;
