import React from "react";
import { NotificationTextSecondary, NotificationText } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

export const unsavedChangesNotification = (onOk: VoidFunction) => {
  const { notificationStore, localeStore } = useStores();

  notificationStore.open({
    title: localeStore.t('common.warnings["unsaved-changes-title"]'),
    message: (
      <>
        <NotificationTextSecondary>
          {localeStore.t('common.warnings["proceed-last-changes"]')}
        </NotificationTextSecondary>
        <NotificationText>{localeStore.t('common.warnings["cannot-undone"]')}</NotificationText>
      </>
    ),
    confirmText: localeStore.t("common.buttons.done"),
    cancelText: localeStore.t("common.buttons.cancel"),
    onOk,
  });
};
