import { SvgIcon, clsx } from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import React from "react";

import { ReactComponent as HintSVG } from "src/external-ts/assets/images/hint-icon.svg";
import styles from "./yearly-offer.module.css";
import { useStores } from "src/hooks";

export interface YearlyOfferProps {
  className?: string;
  extraCount: number;
}

export const YearlyOffer: React.FC<YearlyOfferProps> = observer(
  ({ className = "", extraCount }: YearlyOfferProps) => {
    const { localeStore, subscriptionsStore } = useStores();
    const currentExtraCount = extraCount - subscriptionsStore.subGbAddonsAmount;

    const extraPriceYr = extraCount * subscriptionsStore.subaccountAddonPrices.yearlyPrice;
    const yearlyText = `US$${subscriptionsStore.proPlanPrices.yearlyPrice + extraPriceYr}/yr`;

    return (
      <>
        <div className={clsx(styles.lifetimeOffer, className)}>
          {currentExtraCount === 0 ? (
            <div className={styles.typography}>
              <SvgIcon icon={HintSVG} size={16} />
              <span>
                {localeStore.t('settings.subscriptions.components.content["lifetime-offer"].title')}
              </span>
            </div>
          ) : (
            <>
              <div className={styles.yearlyWrapper}>
                <span className={styles.yearlyText}>Yearly</span>
                <span className={styles.yearlyPrice}>{yearlyText}</span>
              </div>
              {currentExtraCount >= 0 && (
                <span className={styles.expandAgain}>
                  {localeStore.t(
                    'settings.subscriptions.components.content["lifetime-offer"]["today-charge"].first',
                  )}{" "}
                  ${subscriptionsStore.formattedBillingInfo?.current_amount_due ?? "--"}{" "}
                  {localeStore.t(
                    'settings.subscriptions.components.content["lifetime-offer"]["today-charge"].today',
                  )}{" "}
                  ({localeStore.t("settings.subscriptions.components.content.balance")}).
                </span>
              )}
            </>
          )}
        </div>
      </>
    );
  },
);
