import React from "react";
import { observer } from "mobx-react-lite";
import { clsx } from "@gemlightbox/core-kit";

import styles from "./step-label.module.css";

export type StepLabelProps = {
  label: string;
  order: number;
  active: boolean;
  completed: boolean;
};

export const StepLabel: React.FC<StepLabelProps> = observer(
  ({ label, order, active, completed }) => {
    return (
      <div
        className={clsx(styles.container, {
          [styles.active]: active,
          [styles.completed]: completed,
        })}
      >
        <div className={styles.order}>{order}</div>
        <div className={styles.label}>{label}</div>
      </div>
    );
  },
);
