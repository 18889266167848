import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Heading, IconCircleButton, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import logoImg from "src/common/images/logo-text.svg";
import { ReactComponent as accountsImg } from "src/common/images/accounts.svg";
import styles from "./subaccount-invite-failed.module.css";

export const SubaccountInviteFailed: React.FC = observer(() => {
  const navigate = useNavigate();

  const { localeStore } = useStores();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.logo} src={logoImg} alt="" />
      </div>

      <div className={styles.body}>
        <IconCircleButton icon={accountsImg} className={styles.iconCircle} />

        <Heading tag="h3">{localeStore.t('common.warnings["we-sorry"]')}</Heading>
        <Typography size="small" className={styles.label}>
          {localeStore.t('common.warnings["deactivated-invitation"]')}
        </Typography>

        <Button
          className={styles.button}
          onClick={() => navigate("/auth/account/create/email-setup")}
        >
          {localeStore.t('common.buttons["back-to-registration"]')}
        </Button>
      </div>
    </div>
  );
});

export default SubaccountInviteFailed;
