export const picupmediaAttributeNames = {
  description: "Description",
  quantity: "Quantity",
  price: "Price",
  images: "Images",
  productType: "Product Type",
  stoneType: "Stone Type",
  title: "SKU",
  detailedTitle: "Title",
  _id: "ID",
};

export const integrationAttributeNames = picupmediaAttributeNames;
