import React, { memo } from "react";
import {
  Typography,
  Heading,
  useWindowSize,
  WIDTH_BREAKPOINTS,
  Nullable,
} from "@gemlightbox/core-kit";

import styles from "./product-title.module.css";

export type ProductTitleProps = {
  title?: Nullable<string>;
  price?: Nullable<string>;
};

export const ProductTitle: React.FC<ProductTitleProps> = memo(
  ({ title, price }: ProductTitleProps) => {
    const { windowWidth } = useWindowSize();

    if (!title && !price) return null;

    return (
      <div className={styles.productTitleContainer}>
        {title && (
          <Typography size={windowWidth < WIDTH_BREAKPOINTS.mobileL ? "medium" : "big"}>
            {title}
          </Typography>
        )}

        {price && (
          <Heading tag={windowWidth < WIDTH_BREAKPOINTS.mobileL ? "h3" : "h2"}>{price}</Heading>
        )}
      </div>
    );
  },
);

export default ProductTitle;
