import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { observer } from "mobx-react-lite";
import { SvgIcon, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { AttributeModel } from "src/models";
import { getAttributeDisplayName } from "src/utils";

import { ReactComponent as DragIcon } from "src/external-ts/assets/images/products-import/draggable-grey.svg";

import styles from "./selected-columns.module.css";

interface SelectedColumnsProps {
  columns: AttributeModel[];
}

export const SelectedColumns: React.FC<SelectedColumnsProps> = observer(({ columns }) => {
  const { localeStore } = useStores();

  return (
    <Droppable
      droppableId="attributes-columns"
      renderClone={(provided, _snapshot, rubric) => (
        <div
          className={styles.draggableColumn}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className={styles.columnNameContainer}>
            {getAttributeDisplayName(columns[rubric.source.index])}
          </div>
        </div>
      )}
    >
      {(provided) => (
        <div className={styles.selectedColumnsContainer} ref={provided.innerRef}>
          <div className={styles.header}>
            <Typography size="small600" color="textSecondary">
              {localeStore.t('products.modals["manage-column-sidebar"]["selected-columns"].title')}
            </Typography>
            <Typography size="extraSmall" color="textTertiary">
              {localeStore.t(
                'products.modals["manage-column-sidebar"]["selected-columns"].subtitle',
              )}
            </Typography>
          </div>

          <div className={styles.draggableColumnsContainer}>
            {columns.map(({ name, displayName, isHidden, draggableId }, index) => (
              <Draggable key={draggableId} draggableId={draggableId} index={index}>
                {(provided) => (
                  <div
                    className={styles.draggableColumn}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    style={{
                      ...provided.draggableProps.style,
                      display: isHidden ? "none" : "block",
                    }}
                  >
                    <div className={styles.columnNameContainer}>
                      <SvgIcon className={styles.dragIcon} icon={DragIcon} />
                      {getAttributeDisplayName({ name, displayName } as AttributeModel)}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
});

export default SelectedColumns;
