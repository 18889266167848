import React from "react";
import { clsx, Nullable } from "@gemlightbox/core-kit";

import { CatalogsSortDirectionType, CatalogsSortValueType } from "src/store";

import { ReactComponent as AngleUpSVG } from "src/common/images/angle-up-secondary.svg";
import styles from "./table-sort-buttons.module.css";

interface TableSortButtonsProps {
  catalogColumnName: CatalogsSortValueType;
  activeSortAngleIcon: Nullable<{
    columnName: CatalogsSortValueType;
    sortOrder: CatalogsSortDirectionType;
  }>;
  handleSort: (
    catalogColumnName: CatalogsSortValueType,
    sortOrder: CatalogsSortDirectionType,
  ) => Promise<void>;
}

export const TableSortButtons: React.FC<TableSortButtonsProps> = ({
  catalogColumnName,
  activeSortAngleIcon,
  handleSort,
}) => {
  return (
    <div>
      <div
        className={styles.angleUpIconContainer}
        onClick={() => handleSort(catalogColumnName, "desc")}
      >
        <AngleUpSVG
          className={clsx(styles.angleIcon, {
            [styles.active]:
              activeSortAngleIcon?.columnName === catalogColumnName &&
              activeSortAngleIcon?.sortOrder === "desc",
          })}
        />
      </div>
      <div
        className={clsx(styles.angleDownIconContainer, {
          [styles.active]:
            activeSortAngleIcon?.columnName === catalogColumnName &&
            activeSortAngleIcon?.sortOrder === "asc",
        })}
        onClick={() => handleSort(catalogColumnName, "asc")}
      >
        <AngleUpSVG className={styles.angleIcon} />
      </div>
    </div>
  );
};
