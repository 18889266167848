import React from "react";
import { observer } from "mobx-react-lite";
import styles from "./camera-keyboard-tips.module.css";
import { useStores } from "src/hooks";
import { clsx, SvgIcon, UseBooleanReturnType } from "@gemlightbox/core-kit";
import { MuiTooltip } from "src/external-ts/components";
import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import { ReactComponent as PinchZoomSVG } from "src/external-ts/assets/images/camera/pinch-zoom-icon.svg";
import { ReactComponent as DirectionalKeysSVG } from "src/external-ts/assets/images/camera/directional-keys-icon.svg";
import { ReactComponent as DirectionalKeysPlusSVG } from "src/external-ts/assets/images/camera/directional-keys-plus-icon.svg";
import { TooltipProps } from "@mui/material";

export type CameraKeyboardTipsProps = {
  className?: string;
  isOpen: UseBooleanReturnType;
  placement?: TooltipProps["placement"];
  children: React.ReactElement<any, any>;
  type: "adjustments" | "zoom";
  onClose?: () => void;
};
export const CameraKeyboardTips: React.FC<CameraKeyboardTipsProps> = observer(
  ({ className, isOpen, placement = "left-start", type, children, onClose }) => {
    const { localeStore } = useStores();

    return (
      <MuiTooltip
        sx={{
          [`& .MuiTooltip-tooltip`]: {
            borderRadius: " 8px",
            background: " var(--Textcolor-Navy, #363E5C)",
            width: "256px",
            padding: "16px 20px",
          },
        }}
        title={
          <div className={clsx(styles.cameraKeyboardTipsContainer, className)}>
            {type === "zoom" ? (
              <div className={styles.zoomTips}>
                <div className={styles.example}>
                  <SvgIcon icon={DirectionalKeysSVG} size={[100, 66]} />
                  <div className={styles.pinchZoom}>
                    <span>{localeStore.t('camera["camera-keyboard-tips"]["or-text"]')}</span>
                    <SvgIcon icon={PinchZoomSVG} size={[70, 76]} />
                  </div>
                </div>
                <span>{localeStore.t('camera["camera-keyboard-tips"]["zoom-tips"]')}</span>
              </div>
            ) : (
              <div className={styles.adjustmentsTips}>
                <div className={styles.example}>
                  <SvgIcon icon={DirectionalKeysPlusSVG} size={[140, 99]}></SvgIcon>
                </div>
                <span>{localeStore.t('camera["camera-keyboard-tips"]["adjustments-tips"]')}</span>
              </div>
            )}
            <SvgIcon
              className={styles.closeIcon}
              onClick={() => {
                isOpen.setFalsy();
                onClose?.();
              }}
              icon={CrossSVG}
              size={16}
            />
          </div>
        }
        open={isOpen.value}
        placement={placement}
        arrow={false}
      >
        {children}
      </MuiTooltip>
    );
  },
);
