import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import { withErrorBoundary } from "src/components/ErrorBoundary";
import IconBtn from "../IconBtn";

import "./index.scss";

const itemClass = "select-options__item";

const ActionsIcon = ({ src, options, onOptionClick }) => {
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  const handleItemClick = (name) => onOptionClick(name);

  return (
    <IconBtn onClick={toggleExpanded} className={clsx("actions-icon", { expanded: expanded })}>
      <img src={src} alt="" />

      <div className="select-cover" onClick={toggleExpanded} />

      {expanded && (
        <div className="select-options">
          {options.length !== 0 &&
            options.map(({ name, label, render, disabled }, index) =>
              render ? (
                <React.Fragment key={index}>
                  {render({
                    className: itemClass,
                    onClick: () => handleItemClick(name),
                    label,
                    disabled,
                  })}
                </React.Fragment>
              ) : (
                <div key={index} className={itemClass} onClick={() => handleItemClick(name)}>
                  {label}
                </div>
              ),
            )}
        </div>
      )}
    </IconBtn>
  );
};
export default withErrorBoundary(ActionsIcon);
