import React from "react";
import { observer } from "mobx-react-lite";
import { LastCell, TableCell, TableRow, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import styles from "./variant-table-head.module.css";

export const VariantTableHead: React.FC = observer(() => {
  const { localeStore } = useStores();

  return (
    <TableRow className={styles.variantTableHeadContainer} appearance="header">
      <TableCell className={styles.imageCellContainer}>
        <Typography size="extraSmall">
          {localeStore.t('["product-variant"].table.image')}
        </Typography>
      </TableCell>
      <TableCell className={styles.variantCellContainer}>
        <Typography size="extraSmall">
          {localeStore.t('["product-variant"].table.variant')}
        </Typography>
      </TableCell>
      <TableCell className={styles.size136CellContainer}>
        <Typography size="extraSmall">
          {localeStore.t('["product-variant"].table.price')}
        </Typography>
      </TableCell>
      <TableCell className={styles.quantityCellContainer}>
        <Typography size="extraSmall">
          {localeStore.t('["product-variant"].table.available')}
        </Typography>
      </TableCell>
      <TableCell className={styles.quantityCellContainer}>
        <Typography size="extraSmall">
          {localeStore.t('["product-variant"].table["on-hand"]')}
        </Typography>
      </TableCell>
      <TableCell className={styles.size136CellContainer}>
        <Typography size="extraSmall">{localeStore.t('["product-variant"].table.sku')}</Typography>
      </TableCell>
      <TableCell className={styles.size136CellContainer}>
        <Typography size="extraSmall">
          {localeStore.t('["product-variant"].table.barcode')}
        </Typography>
      </TableCell>
      <LastCell className={styles.lastCellContainer} appearance="header" />
    </TableRow>
  );
});

export default VariantTableHead;
