import React from "react";
import { observer } from "mobx-react-lite";

import { editMediaStore } from "../../edit-media.store";
import { AdjustmentsFilters } from "./adjustments-filters";
import { Crop } from "./crop";
import { Eraser } from "./eraser";

export const Adjustments: React.FC = observer(() => {
  const { isInCrop } = editMediaStore;

  if (isInCrop) {
    return (
      <>
        <Crop />
      </>
    );
  }

  return (
    <>
      <AdjustmentsFilters />
      <Eraser />
      <Crop />
    </>
  );
});

export default Adjustments;
