import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Modal, ObjectType } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";
import { ReactComponent as DeleteAccountLogo } from "src/external-ts/assets/images/delete-account-logo.svg";

import { SvgIcon } from "@gemlightbox/core-kit";
import styles from "./delete-tip-modal.module.css";
import { useNavigate } from "react-router-dom";

export interface DeleteTipModalProps {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed?: VoidFunction;
  options: ObjectType;
}

export const DeleteTipModal: React.FC<DeleteTipModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const { localeStore } = useStores();
    const navigate = useNavigate();

    return (
      <Modal
        scrollWrapperClassName={styles.modalContainer}
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
        withCross
      >
        <div>
          <SvgIcon
            className={styles.deleteAccountLogo}
            icon={DeleteAccountLogo}
            size={{ width: 84, height: 68 }}
          />
        </div>
        <div className={styles.modelContentText}>
          {localeStore.t('settings.profile.deleteAccount["sub-warning-modal-text"]')}
        </div>
        <Button
          className={styles.deleteAccountButton}
          appearance="primary"
          onClick={() => {
            setClose();
            navigate("/settings/subaccounts");
          }}
        >
          {localeStore.t('settings.profile.deleteAccount["sub-warning-modal-button"]')}
        </Button>
      </Modal>
    );
  },
);

export default DeleteTipModal;
