import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Heading, Modal, SvgIcon, useWindowSize } from "@gemlightbox/core-kit";

import { ModalExtendedType } from "src/store";

import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import styles from "./pdf-preview-modal.module.css";

export type PdfPreviewModalProps = ModalExtendedType<{
  name: string;
  url: string;
}>;

export const PdfPreviewModal: React.FC<PdfPreviewModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { url, name } = options;
    const pdfUrl = useMemo(() => `${url}#toolbar=1`, [url]);
    const { windowWidth } = useWindowSize();

    return (
      <Modal
        contentClassName={styles.modal}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        withCross
        disablePadding
        disableBorderRadius
        data-cy="pdf-preview"
      >
        <Heading
          className={styles.title}
          tag={windowWidth > 375 ? "h2" : "h3"}
          color="textSecondary"
        >
          <SvgIcon icon={PdfSVG} /> {name}
        </Heading>
        <div style={{ height: "1000px", width: "100%", minHeight: 300 }}>
          <iframe height="100%" width="100%" src={pdfUrl} />
        </div>
      </Modal>
    );
  },
);
