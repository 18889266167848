import { queryStringify } from "@gemlightbox/core-kit";

import { config } from "src/config/environment";
import { ShareModalTypeKeys } from "../share-modal.types";

type GetEmbedCodeLinkOptions = {
  associatedMedia: string;
  showAttributes: boolean;
};

export const getEmbedCodeLink = (
  uuid: string,
  type: ShareModalTypeKeys,
  { associatedMedia, showAttributes }: GetEmbedCodeLinkOptions,
): string => {
  const isCatalog = type === "catalogFullItem" || type === "catalogListItem";
  const resource = isCatalog ? "/catalog" : "/c";

  const query = queryStringify({
    withAttributes: showAttributes ? undefined : "false",
    associatedMedia,
    lang: localStorage.getItem("localeCode") || "en",
  });
  return `${config.galleryURL}${resource}/${uuid}${query}`;
};

export const getEmbedCodeToCopy = (link: string, frameWidth: string, frameHeight: string) => {
  frameWidth = frameWidth ? `${frameWidth}px` : "initial";
  frameHeight = frameHeight ? `${frameHeight}px` : "initial";
  return `<iframe src="${link}" allow="camera *; microphone *; fullscreen;" sandbox="allow-downloads allow-scripts allow-same-origin" style="width: ${frameWidth}; height: ${frameHeight}; max-width: ${frameWidth}; max-height: ${frameHeight}; border: none; overflow: hidden;" allowfullscreen></iframe>`;
};
