import React from "react";
import "./index.scss";

const InputWrapper = ({
  title,
  children,
  required,
  className,
  error,
  label,
  tooltipComponent,
  errorPosition,
}) => {
  return (
    <div
      className={`input-wrapper ${className ? className : ""} ${
        error ? "input-wrapper-error" : ""
      }`}
    >
      {title && (
        <div className="input-wrapper__headline">
          <div className="title">
            {title}
            {required && <span> * </span>}
            {tooltipComponent}
          </div>
          {error && errorPosition === "top" && <div className="error"> {error} </div>}
        </div>
      )}

      {children}
      {!title && required && <div className="asterisk"> * </div>}
      {error && errorPosition !== "top" && <div className="error"> {error} </div>}
      {label && <div className="bottom-label label"> {label} </div>}
    </div>
  );
};

export default InputWrapper;
