import React from "react";
import { observer } from "mobx-react-lite";
import {
  Heading,
  Typography,
  Button,
  copyToClipboard,
  Input,
  SvgIcon,
  useBoolean,
} from "@gemlightbox/core-kit";

import { ShareOptions } from "src/external-ts/components";
import { useStores } from "src/hooks";

import { ReactComponent as PointerSVG } from "src/external-ts/assets/images/share-link-modal/pointer-click-grey.svg";
import { ReactComponent as CopySVG } from "src/external-ts/assets/images/copy-grey.svg";
import styles from "./link-content.module.css";

export type LinkContentProps = {
  link: string;
};

export const LinkContent: React.FC<LinkContentProps> = observer(({ link }) => {
  const { localeStore } = useStores();

  const copiedBoolean = useBoolean();

  const handleCopy = async () => {
    await copyToClipboard(link);
    copiedBoolean.setTruthy();
  };

  const handleOpen = () => window.open(link, "_blank");

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <Heading tag="h2" color="textSecondary">
          {localeStore.t('components.business["download-modal"]["link-content"].title')}
        </Heading>
        <Typography className={styles.subtitle} size="medium" color="textTertiary">
          {localeStore.t('components.business["download-modal"]["link-content"].subtitle.top')}
          <br />
          {localeStore.t('components.business["download-modal"]["link-content"].subtitle.bottom')}
        </Typography>
      </div>

      <ShareOptions link={link} />

      <div className={styles.linkContainer}>
        <Input
          inputWrapperClassName={styles.inputWrapper}
          appearance="primaryV2"
          value={link || ""}
          readOnly
          disableError
        />
        <div className={styles.buttonContainer}>
          <Button
            className={styles.copyLinkButton}
            appearance="primaryOutlined"
            onClick={handleCopy}
          >
            <SvgIcon icon={CopySVG} />
            {localeStore.t('components.business["download-modal"]["link-content"].buttons.copy')}
          </Button>
          <Button className={styles.openLinkButton} onClick={handleOpen}>
            <SvgIcon icon={PointerSVG} />
            {localeStore.t('components.business["download-modal"]["link-content"].buttons.open')}
          </Button>
        </div>
      </div>
    </div>
  );
});
