import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { copyToClipboard, Input, Typography, Switch, Info } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { pushShareDataLayerEvent } from "src/utils";
import { CatalogModel, MediaCollectionModel, MediaModel, ProductModel } from "src/models";
import {
  ExtendedCatalogFullModel,
  ExtendedCatalogModel,
  ExtendedMediaModel,
  ExtendedProductModel,
} from "src/store";
import { ShareElement } from "../share-element";
import { ShareModalTypeKeys } from "../share-modal.types";
import { EmbedButtonPreview } from "./embed-button-preview";
import { EmbedButtonType } from "./embed-button-type";
import { getEmbedButtonCode } from "./embed-button-content.utils";

import styles from "./embed-button-content.module.css";

export type EmbedCodeContentProps = {
  type: ShareModalTypeKeys;
  data:
    | ExtendedMediaModel
    | MediaCollectionModel
    | ExtendedProductModel
    | ExtendedCatalogModel
    | ExtendedCatalogFullModel
    | CatalogModel;
};

export const EmbedButtonContent: React.FC<EmbedCodeContentProps> = observer(
  ({ type, data }: EmbedCodeContentProps) => {
    const isMedia = type === "media";
    const isMediaAssigned = isMedia && !!(data as MediaModel).sku_id;
    const isMediaBlink = isMedia && (data as ExtendedMediaModel)?.type === "blink";

    const isProduct = type === "product";
    const isProductBlink =
      isProduct && !!(data as ProductModel).images.find(({ type }) => type === "blink");

    const isBlink = isMediaBlink || isProductBlink;

    const { localeStore } = useStores();

    const [frameWidth, setFrameWidth] = useState("");
    const [frameHeight, setFrameHeight] = useState("");
    const [buttonSize, setButtonSize] = useState(() => (isBlink ? "200" : "74"));
    const [showAttributes, setShowAttributes] = useState(true);
    const [associatedMedia, setAssociatedMedia] = useState("");

    const [embedButtonType, setEmbedButtonType] = useState(() => {
      return isBlink ? "ar-type-0" : "video360-circle-1";
    });

    const codeToCopy = useMemo(() => {
      return getEmbedButtonCode({
        uuid: data.link.uuid,
        type: embedButtonType,
        buttonSize,
        modalWidth: frameWidth,
        modalHeight: frameHeight,
        associatedMedia,
        showAttributes,
        isBlink,
      });
    }, [
      embedButtonType,
      buttonSize,
      frameWidth,
      frameHeight,
      associatedMedia,
      showAttributes,
      isBlink,
    ]);

    const canShowAttributes = isProduct || isMediaAssigned;
    const canShowAssociated = isMedia && isMediaAssigned;

    const handleAssociatedMediaChange = (checked: boolean) => {
      if (!isMedia) return;
      const valueToSet = checked ? "" : (data as MediaModel).id.toString();
      setAssociatedMedia(valueToSet);
    };

    const handleCopy = async () => {
      try {
        await copyToClipboard(codeToCopy);
        pushShareDataLayerEvent("embedButton");
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <div className={styles.embedButtonContainer}>
        <EmbedButtonPreview
          embedButtonType={embedButtonType}
          buttonSize={buttonSize}
          isBlink={isBlink}
        />

        <div className={styles.embedButtonContent}>
          <EmbedButtonType
            embedButtonType={embedButtonType}
            onChangeEmbedType={setEmbedButtonType}
            isBlink={isBlink}
          />
          <Input
            affixClassName={styles.affix}
            appearance="primaryV2"
            label="Iframe width (px)"
            suffix="px"
            type="number"
            value={frameWidth}
            placeholder={localeStore.t(
              'components.business["share-modal"]["embed-button-content"]["frame-width-placeholder"]',
            )}
            onChange={setFrameWidth}
            disableError
          />
          <Input
            affixClassName={styles.affix}
            appearance="primaryV2"
            label="Iframe height (px)"
            suffix="px"
            type="number"
            value={frameHeight}
            placeholder={localeStore.t(
              'components.business["share-modal"]["embed-button-content"]["frame-height-placeholder"]',
            )}
            onChange={setFrameHeight}
            disableError
          />
          <Input
            affixClassName={styles.affix}
            appearance="primaryV2"
            label="Button Size"
            suffix="px"
            type="number"
            value={buttonSize}
            onChange={setButtonSize}
            disableError
          />

          {canShowAttributes && (
            <div className={styles.switchWrapper}>
              <Typography className={styles.attributesTypography} size="extraSmall">
                {localeStore.t(
                  'components.business["share-modal"]["embed-button-content"]["attributes-switch"].title',
                )}
                <Info
                  className={styles.switchInfo}
                  appearance="secondary"
                  position="top"
                  size={16}
                  withAngle
                >
                  {localeStore.t(
                    'components.business["share-modal"]["embed-button-content"]["attributes-switch"].info',
                  )}
                </Info>
              </Typography>
              <Switch checked={showAttributes} onChange={setShowAttributes} />
            </div>
          )}

          {canShowAssociated && (
            <div className={styles.switchWrapper}>
              <Typography size="extraSmall">
                {localeStore.t(
                  'components.business["share-modal"]["embed-button-content"]["associated-media-title"]',
                )}
              </Typography>
              <Switch checked={!associatedMedia} onChange={handleAssociatedMediaChange} />
            </div>
          )}

          <ShareElement
            className={styles.shareElementContainer}
            title={localeStore.t(
              'components.business["share-modal"]["embed-button-content"]["share-element-title"]',
            )}
            link={codeToCopy}
            onCopy={handleCopy}
          />
        </div>
      </div>
    );
  },
);

export default EmbedButtonContent;
