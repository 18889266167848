import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Typography,
  SvgIcon,
  globalStyles,
  clsx,
  useDidMount,
  useDidUnmount,
} from "@gemlightbox/core-kit";

import { mediaTypeOptions } from "src/containers/media/media.constants";
import { FiltersType } from "src/external-ts/components";
import { useStores, useUserOptions } from "src/hooks";
import { catalogDefaultFilters } from "./catalog-applied-filters.constants";

import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import styles from "./catalog-applied-filters.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { productDefaultTypesNames } from "src/models";

export const CatalogAppliedFilters: React.FC = observer(() => {
  const { options: userOptions } = useUserOptions();
  const { modalsStore, productsStore, catalogStore, catalogsStore, localeStore } = useStores();

  useDidMount(() => {
    const catalogFilters = catalogStore.catalog?.filter ?? {};

    const filters: FiltersType = {
      ...productsStore.filters,
      productType: catalogFilters.productType || "",
      fromPrice: catalogFilters.price?.from || null,
      toPrice: catalogFilters.price?.to || null,
      quantity: catalogFilters.quantity
        ? [catalogFilters.quantity.from, catalogFilters.quantity.to]
        : [],
      user_id: catalogFilters.creator || "all",
    };

    productsStore.setFilters(filters);
  });

  useDidUnmount(() => productsStore.resetFilters());

  const handleEditFilters = async () => {
    modalsStore.open("FiltersSidebar", {
      title: localeStore.t(
        '["catalog-details"]["catalog-applied-filters"]["filters-sidebar"].title',
      ),
      description: localeStore.t(
        '["catalog-details"]["catalog-applied-filters"]["filters-sidebar"].description',
      ),
      disableSKUFilter: true,
      disableTitleFilter: true,
      disableDescriptionFilter: true,
      initialFilters: productsStore.initialFilters,
      appliedFilters: productsStore.filters,
      onApply: (filters) => {
        productsStore.setFilters(filters);

        const parsedFilters = catalogsStore.parseFilters(productsStore.activeFilters);
        catalogStore.updateCatalog({ filter: parsedFilters });
      },
      onClear: () => {
        productsStore.resetFilters();
        modalsStore.close("FiltersSidebar");
      },
    });
  };

  const renderFilters = () =>
    Object.keys(catalogStore.catalog?.filter ?? {}).map((propName) => {
      const filters = catalogStore.catalog?.filter ?? {};
      let value = filters[propName];
      //check if filter has a label, if not - set its propName as label
      let label =
        catalogDefaultFilters.find((defaultFilter) => defaultFilter.propName === propName)?.label ??
        propName;
      switch (propName) {
        case "creator":
          label = localeStore.t('media["media-selected-filters-panel"].chips["created-by"]');
          value = userOptions.find(({ value }) => value === filters.creator)?.label;
          break;
        case "media_type":
          label = localeStore.t(
            'media.modals["assign-to-product-sidebar"]["media-item"]["media-type"].title',
          );
          value = mediaTypeOptions.find((option) => option.value === value)?.label;
          break;
        case "isThereMedia":
          label = localeStore.t(
            `products["products-list"]["products-table"]["products-table-header"]["media-column"]`,
          );
          value = value
            ? localeStore.t(
                `products["products-list"]["products-table"]["products-table-header"].mediaoptions.with`,
              )
            : localeStore.t(
                `products["products-list"]["products-table"]["products-table-header"].mediaoptions.without`,
              );
          break;
        case "isPrivate":
          label = localeStore.t(
            `products["products-list"]["products-table"]["products-table-header"].privacy`,
          );
          value = value
            ? localeStore.t(
                `products["products-list"]["products-table"]["products-table-header"].sharelinkoptions.private`,
              )
            : localeStore.t(
                `products["products-list"]["products-table"]["products-table-header"].sharelinkoptions.public`,
              );
          break;
        case "productType":
          label = localeStore.t(
            `products["products-list"]["products-table"]["products-table-header"].producttype`,
          );
          value = localeStore.t(
            `create["augmented-reality-item"]["ar-types"].${
              productDefaultTypesNames[value.toLocaleLowerCase()]
            }` as LocaleCodeTypes,
            value,
          );
          break;
        case "quantity":
          label = localeStore.t(
            `products["products-list"]["products-table"]["products-table-header"].quantity`,
          );
          value = `${localeStore.t(
            'components.business.filters["filters-sidebar"]["default-filters"]["range-filter"]["from-placeholder"]',
          )} ${filters[propName].from} ${localeStore.t(
            'components.business.filters["filters-sidebar"]["default-filters"]["range-filter"]["to-placeholder"]',
          )} ${filters[propName].to}`;
          break;
        case "price":
          label = localeStore.t(
            `products["products-list"]["products-table"]["products-table-header"].price`,
          );
          value = `${localeStore.t(
            'components.business.filters["filters-sidebar"]["default-filters"]["range-filter"]["from-placeholder"]',
          )} ${filters[propName].from} ${localeStore.t(
            'components.business.filters["filters-sidebar"]["default-filters"]["range-filter"]["to-placeholder"]',
          )} ${filters[propName].to}`;
          break;
        default:
          if (value?.from && value?.to) {
            value = `${localeStore.t(
              'components.business.filters["filters-sidebar"]["default-filters"]["range-filter"]["from-placeholder"]',
            )} ${filters[propName].from} ${localeStore.t(
              'components.business.filters["filters-sidebar"]["default-filters"]["range-filter"]["to-placeholder"]',
            )} ${filters[propName].to}`;
          }
          break;
      }

      if (value) {
        return (
          <div className={styles.filter} key={propName}>
            <Typography size="extraSmall" color="textTertiary">
              {label}
            </Typography>
            <Typography className={styles.filterValue} size="small600" color="textSecondary">
              {Array.isArray(value) ? value.join(", ") : value}
            </Typography>
          </div>
        );
      }
    });

  if (!catalogStore.isAutoCatalog) return null;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography size="medium600" color="textSecondary">
          {localeStore.t('["catalog-details"]["catalog-applied-filters"].title')}
        </Typography>
        <Button
          className={styles.editButton}
          appearance="tertiaryOutlined"
          size="small"
          onClick={handleEditFilters}
          data-cy="edit-filters"
        >
          <SvgIcon icon={EditSVG} />
          {localeStore.t('["catalog-details"]["catalog-applied-filters"].buttons.edit')}
        </Button>
      </div>
      <div className={clsx(styles.filtersContainer, globalStyles.addScrollStyles)}>
        {renderFilters()}
      </div>
    </div>
  );
});
