import React from "react";
import { observer } from "mobx-react-lite";
import { Typography, Chip, Button, isSameDate } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { useUserOptions } from "src/hooks";
import { getMediaSortOptionsLocalized } from "src/containers/media/media.utils";

import styles from "./media-selected-filters-panel.module.css";

export const MediaSelectedFiltersPanel: React.FC = observer(() => {
  const { mediaStore, localeStore } = useStores();

  const { names: userNames } = useUserOptions();

  const handleClearFilters = () => {
    mediaStore.loadMediaList({
      ...mediaStore.filters,
      sort: "all",
      user_id: "all",
      createdFrom: "",
      createdTo: "",
    });
  };

  const handleClearSortType = () => {
    mediaStore.loadMediaList({ ...mediaStore.filters, sort: "all" });
  };

  const handleClearUser = () => mediaStore.loadMediaList({ ...mediaStore.filters, user_id: "all" });

  const handleClearDateSort = () =>
    mediaStore.loadMediaList({ ...mediaStore.filters, createdFrom: "", createdTo: "" });

  if (!mediaStore.filtersAmount) return null;

  const getSortByText = () => {
    const optionsLocalized = getMediaSortOptionsLocalized();
    return optionsLocalized.find((option) => option.value === mediaStore.filters.sort)?.label || "";
  };

  const formatSortDataText = (isoDate: string, shouldDisplayYear?: boolean) => {
    const data = new Date(isoDate);
    const formattedDay = data.toLocaleDateString("EN-en", {
      day: "numeric",
    });
    const formattedMonth = data.toLocaleDateString("EN-en", {
      month: "long",
    });
    const formattedYear = shouldDisplayYear
      ? ` ${data.toLocaleDateString("EN-en", {
          year: "numeric",
        })}`
      : "";

    return `${formattedDay} ${formattedMonth} ${formattedYear}`;
  };

  const getSortByDateFullText = () => {
    const startDate = new Date(mediaStore.filters.createdFrom || "").toDateString();
    const endDate = new Date(mediaStore.filters.createdTo || "").toDateString();
    const currentData =
      endDate && !isSameDate(endDate, startDate)
        ? `${formatSortDataText(mediaStore.filters.createdFrom || "")} - ${formatSortDataText(
            mediaStore.filters.createdTo || "",
            true,
          )}`
        : `${formatSortDataText(mediaStore.filters.createdFrom || "", true)}`;
    return currentData;
  };

  return (
    <div className={styles.container} data-cy="media-selected-filters-panel">
      {mediaStore.isSearch && (
        <Typography size="small600" color="textSecondary">
          {`${localeStore.t('media["media-selected-filters-panel"]["search-results"]')} "${
            mediaStore.filters.sku
          }"`}
        </Typography>
      )}

      {mediaStore.isFilters && (
        <>
          <Typography size="small600" color="textSecondary">
            {localeStore.t('media["media-selected-filters-panel"]["filter-results"]')}
          </Typography>
          <div className={styles.filters}>
            <Button
              className={styles.clearFiltersBtn}
              appearance="secondary"
              size="small"
              onClick={handleClearFilters}
              data-cy="media-clear-filters"
            >
              {localeStore.t('media["media-selected-filters-panel"].buttons.clear')}
              {mediaStore.filtersAmount !== 0 && ` (${mediaStore.filtersAmount})`}
            </Button>

            {mediaStore.isSort && (
              <Chip
                appearance="primaryV2"
                preText={localeStore.t('media["media-selected-filters-panel"].chips["sort-by"]')}
                text={getSortByText()}
                onDelete={handleClearSortType}
                data-name={`sort-by-${getSortByText()}`}
                data-cy="sort-by-filter"
              />
            )}
            {mediaStore.isUser && (
              <Chip
                appearance="primaryV2"
                preText={localeStore.t('media["media-selected-filters-panel"].chips["created-by"]')}
                text={userNames[mediaStore.filters.user_id as number]}
                onDelete={handleClearUser}
                data-name={`created-by-${userNames[mediaStore.filters.user_id as number]}`}
                data-cy="created-by-filter"
              />
            )}
            {mediaStore.isSortedByDate && (
              <Chip
                appearance="primaryV2"
                preText={localeStore.t(
                  'media["media-selected-filters-panel"].chips["sorted-by-date"]',
                )}
                text={getSortByDateFullText()}
                onDelete={handleClearDateSort}
                data-name="sorted-by-date"
                data-cy="sorted-by-date-filter"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});
