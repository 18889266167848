import React from "react";
import { observer } from "mobx-react-lite";
import { useDidMount } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

export const LogoutPage: React.FC = observer(() => {
  const { userStore } = useStores();

  useDidMount(() => userStore.logout());

  return null;
});

export default LogoutPage;
