import { TabType } from "@gemlightbox/core-kit";

export const CATALOG_TABLE_COLUMNS = [
  {
    name: "name",
    value: "Catalog Name",
    propName: "name",
    sortValue: "name",
  },
  {
    name: "totalProducts",
    value: "Products",
    propName: "total_products",
    sortValue: "total_products",
  },
  {
    name: "description",
    value: "Description",
    propName: "description",
  },
  {
    name: "type",
    value: "Type",
    propName: "type",
    sortValue: "filter",
  },
  {
    name: "updatedAt",
    value: "Created / Last Updated",
    propName: "updatedAt",
    sortValue: "createdAt",
  },
  {
    name: "views",
    value: "Views",
    propName: "views",
    sortValue: "views",
  },
];

const CatalogTypeNames = {
  all: "All",
  auto: "Automatic",
  manual: "Manual",
};

export const catalogTabs: TabType[] = Object.entries(CatalogTypeNames).map(([value, label]) => {
  const result: TabType = {
    value,
    label,
  };

  return result;
});

export const catalogLocalStorageEntity = "catalog-view";

//hardcoded values according to the designs
export const catalogCardMinWidth = 197;
export const catalogCardAddedHeight = 77;
export const catalogCardHeightMultiplier = 1;
export const catalogRowMinHeight = 80;
