import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, useWindowSize, SvgIcon } from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import { useStores } from "src/hooks";

import { ReactComponent as ArrowLeftBack } from "src/external-ts/assets/images/arrow-left-oblong-grey.svg";
import { ReactComponent as CheckmarkIcon } from "src/external-ts/assets/images/checkmark-circle-grey.svg";
import { ReactComponent as CloseIcon } from "src/external-ts/assets/images/cross-grey.svg";

import styles from "./control-panel.module.css";

export const ControlPanel: React.FC = observer(() => {
  const navigate = useNavigate();
  const { windowWidth } = useWindowSize();
  const { catalogStore, productsStore, localeStore } = useStores();

  const handleRedirect = () => navigate(`/catalog/${catalogStore.catalog?.id}`);

  const handleConfirm = async () => {
    if (!catalogStore.catalog) return;

    const newIds = productsStore.selectedProductsList.map(({ _id }) => _id);

    await catalogStore.assignProducts(newIds);

    handleRedirect();
  };

  const isMobile = windowWidth < BREAKPOINTS.mobileL;
  const backButtonText = isMobile
    ? localeStore.t('["catalog-add-products"]["control-panel"]["back-mobile"]')
    : localeStore.t('["catalog-add-products"]["control-panel"].back');

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Button className={styles.back} appearance="secondaryGhost" onClick={handleRedirect}>
          <SvgIcon icon={ArrowLeftBack} />
          {backButtonText}
        </Button>
      </div>

      <div className={styles.right}>
        <Button
          className={styles.button}
          appearance="secondaryOutlined"
          onClick={handleRedirect}
          data-cy="catalog-add-products-cancel-button"
        >
          {isMobile && <SvgIcon icon={CloseIcon} />}
          {!isMobile && localeStore.t('["catalog-add-products"]["control-panel"].cancel')}
        </Button>
        <Button
          className={styles.button}
          loading={catalogStore.loading}
          disabled={!productsStore.selectedProductsAmount}
          onClick={handleConfirm}
          data-cy="catalog-add-products-add-button"
        >
          <SvgIcon icon={CheckmarkIcon} />
          {!isMobile && localeStore.t('["catalog-add-products"]["control-panel"].add')}
        </Button>
      </div>
    </div>
  );
});
