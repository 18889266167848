import React, { Fragment } from "react";
import { TooltipTarget, Typography, TooltipProps, Link } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";

import styles from "./pro-feature-tooltip.module.css";

type ProFeatureTooltipOptions = {
  onUpgradeClick?: VoidFunction;
} & Partial<Pick<TooltipProps, "offsetX" | "offsetY" | "position">>;

export const proFeatureTooltip = (
  target: TooltipTarget,
  { position = "bottom", offsetX, offsetY, onUpgradeClick }: ProFeatureTooltipOptions = {},
) => {
  const { tooltipStore, localeStore, userStore } = useStores();

  const handleClick = () => {
    tooltipStore.close();
    onUpgradeClick?.();
  };

  tooltipStore.open(
    <Fragment>
      <Typography size="extraSmall" data-cy="pro-feature-tooltip">
        {localeStore.t('common["pro-feature"].text')}{" "}
      </Typography>
      {!userStore.isAppStoreDemoAccount && (
        <>
          &nbsp;
          <Link
            href={getSettingsTab(SettingTabNames.subscriptions)?.item.path as string}
            size="extraSmall700"
            appearance="quaternary"
            onClick={handleClick}
            data-cy="upgrade-button"
          >
            {localeStore.t("common.buttons.upgrade")}
          </Link>
        </>
      )}
    </Fragment>,
    {
      className: styles.proFeatureTooltip,
      target,
      appearance: "promo",
      position,
      offsetX,
      offsetY,
      withAngle: true,
      onPointerLeave: () => tooltipStore.close(),
    },
  );
};
