export * from "./attributes.constants";
export * from "./locale.constants";
export * from "./error-codes.constants";
export * from "./routes.constants";

export const COLORS = {
  white: "#ffffff",
  label: "#24272A",
  lightGray: "#ececec",
  primary: "#02CAC4",
  gray: "#A29EA1",
  danger: "#ff3b30",
  subscription: "#FE881A",
};

export const BREAKPOINTS = {
  laptop: 1441,
  tablet: 1025,
  mobileL: 769,
  mobileM: 486,
  mobileS: 366,
};

export const UNAUTHORIZED_STATUS = 401;
export const FORBIDDEN_STATUS = 403;
export const MAX_LABEL_LENGTH = 100;

export const INTEGRATIONS = {
  SHOPIFY: "shopify",
};

export const INTEGRATION_ACTIONS = {
  ADD_STORE: "Add Store",
  SELECT_STORE: "Select Store",
  SETTINGS: "Settings",
  STOP_SYNC: "Stop Syncing",
  REMOVE_INTEGRATION: "Remove Integration",
  VIEW_ERRORS: "View Errors",
};

export const INTEGRATION_OPTIONS = [
  {
    name: INTEGRATION_ACTIONS.ADD_STORE,
    label: INTEGRATION_ACTIONS.ADD_STORE,
  },
  {
    name: INTEGRATION_ACTIONS.SELECT_STORE,
    label: INTEGRATION_ACTIONS.SELECT_STORE,
  },
  {
    name: INTEGRATION_ACTIONS.SETTINGS,
    label: INTEGRATION_ACTIONS.SETTINGS,
  },
  {
    name: INTEGRATION_ACTIONS.STOP_SYNC,
    label: INTEGRATION_ACTIONS.STOP_SYNC,
  },
  {
    name: INTEGRATION_ACTIONS.REMOVE_INTEGRATION,
    label: INTEGRATION_ACTIONS.REMOVE_INTEGRATION,
  },
  {
    name: INTEGRATION_ACTIONS.VIEW_ERRORS,
    label: INTEGRATION_ACTIONS.VIEW_ERRORS,
  },
];

export const INTEGRATION_STATUSES = {
  SETUP: "setup",
  SYNCING: "syncing",
  COMPLETED: "completed",
  ERROR: "error",
  SETTINGS: "settings",
  DEFAULT: "default",
};
