import { TabType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { catalogTabs, CATALOG_TABLE_COLUMNS } from "./catalogs.constants";

export const useCatalogLocalization = () => {
  const { localeStore } = useStores();

  const getTabLabel = (value: string) => {
    switch (value) {
      case "all":
        return localeStore.t('catalog["catalog-control-panel"].tabs.all');
      case "auto":
        return localeStore.t('catalog["catalog-control-panel"].tabs.auto');
      case "manual":
        return localeStore.t('catalog["catalog-control-panel"].tabs.manual');
      default:
        return "";
    }
  };

  const getColumnName = (value: string) => {
    switch (value) {
      case "name":
        return localeStore.t('catalog["catalogs-list"]["table-header"].columns.name');
      case "totalProducts":
        return localeStore.t('catalog["catalogs-list"]["table-header"].columns["total-products"]');
      case "description":
        return localeStore.t('catalog["catalogs-list"]["table-header"].columns.description');
      case "type":
        return localeStore.t('catalog["catalogs-list"]["table-header"].columns.type');
      case "updatedAt":
        return localeStore.t('catalog["catalogs-list"]["table-header"].columns["updated-at"]');
      case "views":
        return localeStore.t('catalog["catalogs-list"]["table-header"].columns.views');
      default:
        return "";
    }
  };

  const catalogTabsLocalized: TabType[] = catalogTabs.map(({ value }) => {
    return { value, label: getTabLabel(value) };
  });

  const catalogTableColumnsLocalized = CATALOG_TABLE_COLUMNS.map((column) => {
    return { ...column, value: getColumnName(column.name) };
  });

  return {
    catalogTabsLocalized,
    catalogTableColumnsLocalized,
  };
};
