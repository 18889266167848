import React from "react";
import { observer } from "mobx-react-lite";
import { Input, FilterCollapse } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

export interface TitleFilterProps {
  value: string;
  onChange: (value: string) => void;
}

export const TitleFilter: React.FC<TitleFilterProps> = observer(({ value, onChange }) => {
  const { localeStore } = useStores();

  return (
    <FilterCollapse
      data-cy="title-filter-collapse"
      title={localeStore.t(
        'products["products-list"]["products-table"]["products-table-header"].title',
      )}
    >
      <Input
        data-cy="title-filter-input"
        value={value}
        appearance="primaryV2"
        placeholder={`${localeStore.t(
          'components.business.filters["filters-sidebar"]["default-filters"]["title-filter"].placeholder',
        )} ${localeStore.t(
          'products["products-list"]["products-table"]["products-table-header"].title',
        )}`}
        onChange={onChange}
        disableError
      />
    </FilterCollapse>
  );
});
