import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, clsx } from "@gemlightbox/core-kit";

import { useUserOptions, useStores } from "src/hooks";
import { MediaFilterSortTypeKeys } from "src/containers/media/media.types";
import { getMediaSortOptionsLocalized } from "../../../media.utils";
import { Filter } from "./filter";

import styles from "./filters-dialog.module.css";

interface FiltersDialogProps {
  onClose: VoidFunction;
}

export const FiltersDialog: React.FC<FiltersDialogProps> = observer(
  ({ onClose }: FiltersDialogProps) => {
    const { mediaStore, localeStore } = useStores();

    const { options: userOptions } = useUserOptions();

    const [sortType, setSortType] = useState<MediaFilterSortTypeKeys>(
      mediaStore.filters.sort as MediaFilterSortTypeKeys,
    );
    const [userId, setUserId] = useState<"all" | number>(
      mediaStore.filters.user_id as "all" | number,
    );

    const handleSortChange = (value: string) => setSortType(value as MediaFilterSortTypeKeys);
    const handleUserChange = (value: string) => setUserId(value === "all" ? value : Number(value));

    const handleApply = () => {
      mediaStore.loadMediaList({ ...mediaStore.filters, sort: sortType, user_id: userId });
      onClose();
    };

    return (
      <div className={clsx(styles.container)} data-cy="media-filters-dialog">
        <div className={styles.filters}>
          <Filter
            value={sortType}
            title={localeStore.t(
              'media["media-control-panel"].filters["filters-dialog"]["sort-by"].title',
            )}
            name="sort-by-filter"
            options={getMediaSortOptionsLocalized()}
            onChange={handleSortChange}
          />

          <Filter
            value={userId}
            title={localeStore.t(
              'media["media-control-panel"].filters["filters-dialog"]["created-by"].title',
            )}
            name="created-by-filter"
            options={userOptions}
            onChange={handleUserChange}
          />
        </div>

        <div className={styles.buttonContainer} onClick={handleApply}>
          <Button className={styles.button} data-cy="media-filters-confirm-button">
            {localeStore.t('media["media-control-panel"].filters["filters-dialog"].buttons.apply')}
          </Button>
        </div>
      </div>
    );
  },
);
