import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  curry,
  handleSetRef,
  RefModel,
  TableNew,
  useVirtualizedLoading,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { productRowMinHeight } from "../../../products.constants";
import { ProductsTableHeaderNew } from "./products-table-header";
import { ProductsTableBody } from "./products-table-body";

export interface ProductsTableProps {
  productsContainerRef: RefModel<HTMLDivElement>;
}

export const ProductsTable: React.FC<ProductsTableProps> = observer(({ productsContainerRef }) => {
  const { productsStore, mediaStore } = useStores();

  const headerRowRef = useRef<HTMLDivElement>(null);
  const shouldRenderNoSKUCard = mediaStore.resultsMediaAmount > 0;
  const shouldRenderPendingUploadCard = productsStore.totalProductsPendingUploadAmount > 0;
  let offset = 0;
  offset += +shouldRenderNoSKUCard;
  offset += +shouldRenderPendingUploadCard;
  const { virtualizedItems, virtualizingContainerRef, virtualizingContentRef, onScroll } =
    useVirtualizedLoading({
      itemsAmount: productsStore.productsList.length + offset,
      minItemHeight: productRowMinHeight,
    });

  useEffect(() => {
    if (productsStore.scrollRestoration) {
      virtualizingContainerRef.current?.scrollTo(productsStore.scrollPosition);
      productsStore.setScrollRestoration(false);
      productsStore.setScrollPosition({ left: 0, top: 0 });
    } else {
      productsStore.setScrollPosition({ left: 0, top: 0 });
    }
  }, []);

  return (
    <TableNew
      forwardRef={curry(handleSetRef)([virtualizingContainerRef, productsContainerRef])}
      /* @ts-ignore */
      onScroll={(e) => {
        productsStore.setScrollPosition({
          left: (e.target as HTMLDivElement).scrollLeft,
          top: (e.target as HTMLDivElement).scrollTop,
        });
        onScroll();
      }}
    >
      <ProductsTableHeaderNew forwardRef={headerRowRef} />
      <ProductsTableBody
        noSkuWidth={headerRowRef.current?.offsetWidth || 0}
        virtualizedItems={virtualizedItems}
        forwardRef={curry(handleSetRef)(virtualizingContentRef)}
      />
    </TableNew>
  );
});
