import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, clsx } from "@gemlightbox/core-kit";

import { ColumnType } from "src/store";
import { useStores } from "src/hooks";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-grey.svg";
import styles from "./column.module.css";

export interface ColumnProps {
  data: ColumnType;
}

export const Column: React.FC<ColumnProps> = observer(({ data }) => {
  const { productsImportStore, localeStore } = useStores();

  const { id, values, displayName, nameID, namesGroupID } = data;

  const handleUnassign = () => {
    const assignedAttributeIndex = productsImportStore.assignedAttributes.findIndex(
      (attribute) => attribute.id === nameID,
    );
    const columnIndex = productsImportStore.columns.findIndex((column) => column.id === id);

    if (assignedAttributeIndex !== -1 && columnIndex !== -1 && namesGroupID) {
      productsImportStore.unassignAttribute(namesGroupID, assignedAttributeIndex, columnIndex);
    } else {
      console.error(`Impossible to unassign empty column: ${displayName}`);
    }
  };

  return (
    <div className={styles.container}>
      <Droppable droppableId={id}>
        {(provided) => (
          <>
            <div
              {...provided.droppableProps}
              className={clsx(styles.attributeName, { [styles.assigned]: displayName })}
              ref={provided.innerRef}
            >
              {displayName && (
                <Button appearance="whiteGhost" onClick={handleUnassign}>
                  <SvgIcon icon={CrossSVG} />
                </Button>
              )}

              <div className={clsx(globalStyles.applySingleOverflow, styles.name)}>
                {displayName || localeStore.t('["products-import"]["columns-list"].column.title')}
              </div>
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>

      <div className={clsx(globalStyles.addScrollStyles, styles.list)}>
        {values.slice(0, 12).map((value, index) => (
          <div
            key={index}
            className={clsx(globalStyles.applySingleOverflow, styles.attributeValue)}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
});
