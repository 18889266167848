import { IdentifyEventPayload, pushDataLayerEvent } from "./index";

type UserAttrEventPayload = Omit<IdentifyEventPayload, "user_id">;

export const pushUserAttributeDataLayerEvent = (payload: UserAttrEventPayload) => {
  pushDataLayerEvent({
    event: "update_user_attributes",
    ...payload,
    event_params: {
      updated_attributes: Object.keys(payload) || [],
    },
  });
};
