import {
  Nullable,
  EventEmitter,
  CoreMath,
  Mat3,
  Vec2,
  isPositive,
  normalize,
} from "@gemlightbox/core-kit";
import { cameraAnimationTime, cameraInitViewport, cameraScalesArr } from "./camera-2d.constants";
import { Camera2dMouseInfo, Camera2dEmitter } from "./camera-2d.types";
import { calcCameraScale } from "./camera-2d.utils";

export class Camera2d {
  protected _projection: Vec2 = cameraInitViewport.clone();
  public get projection() {
    return this._projection.clone();
  }
  public get projectionArr() {
    return this._projection.getArray();
  }

  protected _translationClamp: Nullable<Vec2> = null;
  public get translationClamp() {
    return this._translationClamp?.clone();
  }

  protected _translation = new Vec2(0, 0);
  public get translation() {
    return this._translation.clone();
  }

  protected _rotation = new CoreMath(0);
  public get rotation() {
    return this._rotation.clone();
  }

  protected _scale = new CoreMath(1);
  public get scale() {
    return this._scale.valueOf();
  }

  protected _scaleBackup = new CoreMath(1);
  public get scaleBackup() {
    return this._scaleBackup.valueOf();
  }

  protected _scaleIndexCurr = cameraScalesArr.findIndex(([, scale]) => scale === this.scale);
  protected _scaleIndexTo = -1;

  protected _projectionMat: Mat3;
  protected _viewMat: Mat3;
  protected _viewProjection: Mat3;
  public get viewProjection() {
    return this._viewProjection.clone();
  }
  public get viewProjectionMatrix() {
    return this._viewProjection.mat;
  }

  private readonly _emitter = new EventEmitter<Camera2dEmitter>();
  public get events() {
    return this._emitter.events;
  }

  private _savedCam: Camera2d | null = null;

  constructor(viewport?: Vec2) {
    if (viewport) this._projection.copy(viewport);

    this._projectionMat = Mat3.projection(this._projection);
    this._updateView();
  }

  public clone() {
    const cloned = new Camera2d();
    cloned.copy(this);
    return cloned;
  }

  public save() {
    this._savedCam = this.clone();
  }

  public restore() {
    if (!this._savedCam) return;
    this.copy(this._savedCam);
    this._savedCam = null;
  }

  public copy(from: Camera2d) {
    this._projection.copy(from.projection);
    this._projectionMat.setIndex(0, 2 / this._projection.width);
    this._projectionMat.setIndex(4, -2 / this._projection.height);

    this._translation.copy(from.translation);
    this._rotation.copy(from.rotation);

    this._scaleIndexTo = -1;
    this._scaleIndexCurr = cameraScalesArr.findIndex(([, scale]) => scale === from.scale);
    this._scale.set(from.scale);

    this._updateView();

    this._emitter.emit("change", this);
    this._emitter.emit("scale", this.scale);
  }

  public reset(width: number, height: number): void;
  public reset(vec: Vec2): void;
  public reset(a: any, b?: any) {
    if (a instanceof Vec2) {
      this._projection.copy(a);
    } else {
      this._projection.set(a, b);
    }

    this._projectionMat.setIndex(0, 2 / this._projection.width);
    this._projectionMat.setIndex(4, -2 / this._projection.height);
    this._translation.setZero();
    this._rotation.zero();

    const scaleToSet = 1;
    this._scaleIndexTo = -1;
    this._scaleIndexCurr = cameraScalesArr.findIndex(([, scale]) => scale === scaleToSet);
    this._scale.set(scaleToSet);

    this._updateView();

    this._emitter.emit("change", this);
    this._emitter.emit("scale", this.scale);
  }

  public updateProjection(width: number, height: number): void;
  public updateProjection(vec: Vec2): void;
  public updateProjection(a: any, b?: any) {
    if (a instanceof Vec2) {
      this._projection.copy(a);
    } else {
      this._projection.set(a, b);
    }

    this._projectionMat.setIndex(0, 2 / this._projection.width);
    this._projectionMat.setIndex(4, -2 / this._projection.height);
    this._updateViewProjection();

    this._emitter.emit("change", this);
  }

  // Centers camera viewport on canvas global coords
  public centerViewportOnGlobalPosition(center: Vec2) {
    const vec = center.sub(this.projection.divide(2 * this.scale));
    this.setTranslation(vec.x, vec.y);
  }

  public setTranslationClamp(x: number, y: number): void;
  public setTranslationClamp(vec2: Vec2): void;
  public setTranslationClamp(none: null): void;
  public setTranslationClamp(a: any, b?: any) {
    if (a == null) {
      this._translationClamp = null;
      return;
    }

    const isVec = a instanceof Vec2;
    const x = isVec ? a.x : a;
    const y = isVec ? a.y : b;

    if (this._translationClamp?.equals(x, y)) return;

    this._translationClamp = new Vec2(x, y);

    const offset = this.projection.divide(this.scale);

    this._translation.clamp(Vec2.zero(), this._translationClamp.clone().sub(offset));

    this._updateView();
    this._emitter.emit("change", this);
  }

  public setTranslation(x: number, y: number): void;
  public setTranslation(vec2: Vec2): void;
  public setTranslation(a: any, b?: any) {
    const isVec = a instanceof Vec2;
    const x = isVec ? a.x : a;
    const y = isVec ? a.y : b;

    if (this._translation.equals(x, y)) return;

    // NOTE: rounding, so translation won't be so ugly and affect viewProjection that way
    this._translation.set(x, y).roundToClosest(0.005);

    const translationClamp = this.translationClamp;
    if (translationClamp) {
      const offset = this.projection.divide(this.scale);
      this._translation.clamp(Vec2.zero(), translationClamp.sub(offset));
    }

    this._updateView();
    this._emitter.emit("change", this);
  }

  public translate(x: number, y: number, affectScale: boolean): void;
  public translate(vec2: Vec2, affectScale: boolean): void;
  public translate(a: any, b?: any, c?: any) {
    const isVec = a instanceof Vec2;
    let x = isVec ? a.x : a;
    let y = isVec ? a.y : b;
    const affectScale = isVec ? b : c;

    if (affectScale) {
      const scale = Math.max(this.scale, 0.5);
      x /= scale;
      y /= scale;
    }

    this.setTranslation(this.translation.add(x, y));
  }

  public rotate(degrees: number) {
    this._rotation.set(degrees);
    this._updateView();
    this._emitter.emit("change", this);
  }

  // public rescale(e: WheelEvent) {
  //   const [scaleToSet, toIndex] = calcCameraScale(
  //     isPositive(Math.sign(e.deltaY)) ? "des" : "asc",
  //     this.scale,
  //     this._scaleIndexCurr,
  //     this._scaleIndexTo,
  //   );

  //   if (scaleToSet === this.scale) {
  //     this._scaleIndexTo = -1;
  //     return this;
  //   }

  //   const target = e.target as HTMLElement;

  //   const clipSpace = Camera2d.getClipSpacePosition(
  //     target.clientWidth,
  //     target.clientHeight,
  //     this.getCSSMousePosition(e),
  //   );

  //   this._scaleIndexTo = toIndex;
  //   this._scale
  //     .onAnimation(() => {
  //       this._emitter.emit("scale", this.scale);

  //       const prevCameraPos = clipSpace.transformMat3Det(this.viewProjection.invert().mat);

  //       this._updateView();

  //       const nextCameraPos = clipSpace.transformMat3Det(this.viewProjection.invert().mat);

  //       const diff = prevCameraPos.sub(nextCameraPos);

  //       const newTranslation = this.translation.add(diff);
  //       this.setTranslation(newTranslation.x, newTranslation.y);
  //     })
  //     .onAnimationEnd(() => {
  //       this._scaleIndexCurr = toIndex;
  //       this._scaleIndexTo = -1;
  //     })
  //     .lerp(scaleToSet, cameraAnimationTime);

  //   return this;
  // }

  public rescale(e: WheelEvent) {
    const [scaleToSet, toIndex] = calcCameraScale(
      isPositive(Math.sign(e.deltaY)) ? "des" : "asc",
      this.scaleBackup,
      this._scaleIndexCurr,
      this._scaleIndexTo,
    );

    if (scaleToSet === this.scaleBackup) {
      this._scaleIndexTo = -1;
      return this;
    }

    this._scaleIndexTo = toIndex;

    this._scaleBackup
      .onAnimation(() => {
        this._emitter.emit("scale", this.scaleBackup);
      })
      .onAnimationEnd(() => {
        this._scaleIndexCurr = toIndex;
        this._scaleIndexTo = -1;
      })
      .lerp(scaleToSet, cameraAnimationTime);

    return this;
  }

  public setScaleBackup(value: number) {
    if (value === this.scaleBackup) return this;

    this._scaleIndexTo = -1;
    this._scaleIndexCurr = cameraScalesArr.findIndex(([, scale]) => scale === value);

    this._scaleBackup.set(value);
    return this;
  }

  public setScale(value: number) {
    if (value === this.scale) return this;

    this._scaleIndexTo = -1;
    this._scaleIndexCurr = cameraScalesArr.findIndex(([, scale]) => scale === value);

    this._scale.set(value);
    this._updateView();
    this._emitter.emit("change", this);
    this._emitter.emit("scale", this.scale);
    return this;
  }

  // Get vector from global canvas position to TOP LEFT corner canvas css position
  public convertGlobalToTopLeft(global: Vec2) {
    return global.clone().sub(this._translation).multiply(this.scale);
  }

  // converts to -1 -> +1 for both x and y (aka. clipSpace coords in webgl2)
  public static getClipSpacePosition(
    projectionWidth: number,
    projectionHeight: number,
    position: Vec2,
  ) {
    const xNormalized = normalize(position.x, 0, projectionWidth);
    const yNormalized = normalize(position.y, 0, projectionHeight);

    // convert to clip space
    const clipX = xNormalized * 2 - 1;
    const clipY = yNormalized * -2 + 1;

    return new Vec2(clipX, clipY);
  }

  // converts to css x and y coords from canvas TOP LEFT corner
  public getCSSMousePosition(e: Camera2dMouseInfo) {
    const target = e.target as HTMLElement;
    const rect = target.getBoundingClientRect();

    const touchMouse: MouseEvent | Touch = e.type.includes("touch")
      ? (e as TouchEvent).changedTouches[0]
      : (e as MouseEvent);

    const cssX = touchMouse.pageX - rect.left;
    const cssY = touchMouse.pageY - rect.top;
    return new Vec2(cssX, cssY);
  }

  // global vector from camera (0,0) translation.
  public getGlobalMousePosition(e: Camera2dMouseInfo) {
    return this.getCSSMousePosition(e).divide(this.scale).add(this._translation);
  }

  // global vector from camera (0,0) translation floored to pixel values.
  public getGlobalMousePixelPosition(e: Camera2dMouseInfo) {
    return this.getGlobalMousePosition(e).getFloor();
  }

  // converts to css x and y coords but from BOTTOM LEFT corner. Special for gl.readPixels();
  public getReadPixelPosition(e: Camera2dMouseInfo) {
    return this.getCSSMousePosition(e).negateY().addY(this._projection.y);
  }

  private _updateView() {
    const scale = 1 / this._scale.value;
    this._viewMat = Mat3.identity()
      .translate(this._translation)
      .rotate(this._rotation.getRadians())
      .scale(scale, scale)
      .invert();

    this._updateViewProjection();
  }

  private _updateViewProjection() {
    this._viewProjection = this._projectionMat.multiplyNew(this._viewMat.mat);
  }
}

export default Camera2d;
