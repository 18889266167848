import {
  base64ToBlob,
  Canvas2d,
  loadImage,
  imageSourceToCanvas,
  toRadians,
  CanvasDrawImageSource,
  Vec2,
} from "@gemlightbox/core-kit";

import { postBlinkMedia, PostRemoveBackgroundResponseData } from "src/api";
import { ProductTypeModel } from "src/models";
import { canvasSize } from "../../ar-media/media-editor/media-editor.constants";
import { pixelsToMeasurement, rotateImg } from "../../ar-media/media-editor/media-editor.utils";
import { CameraMediaType } from "../camera.types";

export const prepareBlink = async (
  productCategory: ProductTypeModel,
  noBGImage: {
    image: CanvasDrawImageSource;
    sizes: Omit<PostRemoveBackgroundResponseData, "base64_str" | "statusCode">;
    centerDiff: Vec2;
  },
): Promise<CameraMediaType> => {
  const templateToUse = productCategory.templates[0].medias[0];
  const templateImage = await loadImage(templateToUse.file.medium, true);

  const canvas = new Canvas2d({ size: canvasSize });
  canvas.ctx.drawImage(templateImage, 0, 0, canvasSize.width, canvasSize.height);

  const rotatedNoBGCanvas = rotateImg(productCategory, imageSourceToCanvas(noBGImage.image));

  // NOTE: NUMBERS ARE HARDCODED AND CALCULATED FOR EACH `productCategory.templates[0].medias[0]` TEMPLATE IMAGE
  let dWidth = 0;
  let dHeight = 0;
  // let dX = 0;
  // let dY = 0;
  let cX = 0;
  let cY = 0;
  let angle = 0;

  switch (productCategory.name) {
    case "Bracelets":
    case "Watches":
      //Note: dHeight is not needed here since it would be set automatically according to dWidth
      // dHeight = 550;
      dWidth = 540;
      cX = 440;
      cY = 830;
      angle = 63;
      break;
    case "Gemstones":
    case "Rings":
      dWidth = 212;
      // dHeight = 158;
      cX = 682;
      cY = 955;
      angle = 15;
      break;
    case "Earrings":
      dWidth = 220;
      // dHeight = 99;
      cX = 1030;
      cY = 1150;
      break;
    case "Necklaces":
      dWidth = 1013;
      // dHeight = 891;
      cX = 947.5;
      cY = 1206.5;
      break;
    case "Pendants":
      dWidth = 136;
      // dHeight = 156;
      cX = 946;
      cY = 1486;
      break;
  }

  const aspectRatio = rotatedNoBGCanvas.width / rotatedNoBGCanvas.height;
  dHeight = dWidth / aspectRatio;

  canvas.ctx.save();
  canvas.ctx.translate(cX, cY);
  canvas.ctx.rotate(-toRadians(angle));
  canvas.ctx.drawImage(rotatedNoBGCanvas, -dWidth / 2, -dHeight / 2, dWidth, dHeight);
  canvas.ctx.restore();

  const cropImg64 = canvas.toDataURL();
  const cropDataUrl = cropImg64.split(",")[1];
  const cropBlob = base64ToBlob(cropDataUrl, "image/png");

  const blinkImg64 = imageSourceToCanvas(rotatedNoBGCanvas).toDataURL();
  const blinkDataUrl = blinkImg64.split(",")[1];
  const blinkBlob = base64ToBlob(blinkDataUrl, "image/png");

  const blinkFormData = new FormData();

  const blinkWidth = pixelsToMeasurement(dWidth, "inch", productCategory.name);
  const blinkHeight = pixelsToMeasurement(dHeight, "inch", productCategory.name);

  blinkFormData.append("arWidth", String(blinkWidth));
  blinkFormData.append("arWidthUnit", String("inch"));
  blinkFormData.append("arHeight", String(blinkHeight));
  blinkFormData.append("arHeightUnit", String("inch"));
  blinkFormData.append("blinkFile", blinkBlob, "blinkFile.png");
  blinkFormData.append("cropFile", cropBlob, "cropFile.png");
  blinkFormData.append("productTypeId", productCategory.id.toString());
  blinkFormData.append("productType", productCategory.name);

  const blinkResult = await postBlinkMedia.getRequest({ data: blinkFormData }).fetch();
  const blinkFile = blinkResult.success!.arData!.cropFile!.medium;
  const blinkElement = await loadImage(blinkFile, true);

  return {
    element: blinkElement,
    previewSrc: blinkFile,
    blinkId: blinkResult.success!.id,
  };
};

export const getCategory = (category: string) => {
  switch (category) {
    case "bracelets":
      return "Bracelets";
    case "brooches":
      return "Brooches";
    case "earrings":
      return "Earrings";
    case "pendant":
      return "Pendants";
    case "rings":
      return "Rings";
    case "watches":
      return "Watches";
    default:
      return "Rings";
  }
};
