import React from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Form,
  FormButtons,
  FormField,
  Button,
  Heading,
  Typography,
  useCall,
  useQuery,
  useFetch,
  isAndroid,
  isIPhone,
  SvgIcon,
  queryStringify,
  useDidMount,
  useDidUnmount,
} from "@gemlightbox/core-kit";

import { getCheckResetToken, putResetPassword } from "src/api";
import { ErrorCodes, LOGIN_PAGE } from "src/constants";
import { useStores } from "src/hooks";
import { initialNewPasswordResetForm } from "./password.constants";
import { NewPasswordResetForm } from "./password.types";
import { requestPasswordResetValidate } from "./password.utils";
import CallApp from "callapp-lib";
import { CallappOptions } from "callapp-lib/dist/type/types";
import { Logo } from "src/external-ts/components";

import styles from "./password.module.css";
import { ReactComponent as ArrowRightSVG } from "src/external-ts/assets/images/arrow-right-grey.svg";
import { ReactComponent as LogoSVG } from "src/external-ts/assets/images/welcome/logo.svg";
import { ReactComponent as CheckSVG } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";

export const PasswordPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { localeStore } = useStores();
  const [isCallSuccess, setIsCallSuccess] = React.useState(false);

  const {
    query: { guid = "" },
  } = useQuery<{ guid?: string }>();

  const isGuidEmpty = !guid?.trim();

  const { loading, onFetchSuccess } = useFetch(getCheckResetToken.setParams({ guid }), {
    disabled: isGuidEmpty,
  });
  onFetchSuccess((result) => {
    if (!result) handleExpired();
  });

  const { submit, onCallSuccess, onCallError } = useCall(putResetPassword);
  onCallSuccess(() => {
    if (isIPhone() || isAndroid()) {
      // window.location.href = isIPhone()
      //   ? "https://apps.apple.com/app/id1348230536"
      //   : "https://play.google.com/store/apps/details?id=com.gemlightbox";
      handleOpenGemHubApp("/");
      setIsCallSuccess(true);
    } else {
      navigate(LOGIN_PAGE.path);
    }
  });
  onCallError(({ originalError }) => {
    if (originalError?.code === ErrorCodes.USER_RESET_PASSWORD_TOKEN_EXPIRED) {
      handleExpired();
    }
  });

  const handleExpired = () => navigate("/auth/reset/expired");

  const handleSubmit = async ({ password }: NewPasswordResetForm) => {
    await submit({
      params: { guid },
      data: { password },
    });
  };

  const { query } = useQuery();
  const queryString = queryStringify(query);
  const handleOpenGemHubApp = (path?: string) => {
    const isIPhoneBool = isIPhone();
    const isAndroidBool = isAndroid();
    const isMobile = isIPhoneBool || isAndroidBool;
    if (isMobile) {
      const options = {
        scheme: {
          protocol: isIPhoneBool ? "gemlightbox" : "com.gemlightbox",
        },
        appstore: "https://apps.apple.com/app/id1348230536",
        fallback: "https://play.google.com/store/apps/details?id=com.gemlightbox",
      } as CallappOptions;
      const callLib = new CallApp(options);
      callLib.open({
        path: path || `${isIPhoneBool ? "ResetPassword" : "resetpw"}?${queryString}`,
      });
    }
  };

  const handleMetaTagAttribute = (isMount = true) => {
    const metaTag = document.querySelector('meta[name="viewport"]');
    if (metaTag) {
      if (isMount) {
        const originalContent = metaTag.getAttribute("content");
        metaTag.setAttribute(
          "content",
          "width=device-width, maximum-scale=1, minimum-scale=1, initial-scale=1,user-scalable=no, shrink-to-fit=no",
        );

        if (!metaTag.getAttribute("originalContent"))
          metaTag.setAttribute("originalContent", originalContent || "");
      } else {
        const originalContent = metaTag.getAttribute("originalContent");
        if (originalContent) metaTag.setAttribute("content", originalContent);
      }
    }
  };

  useDidMount(() => {
    handleMetaTagAttribute();
  });

  useDidUnmount(() => {
    handleMetaTagAttribute(false);
  });

  if (isGuidEmpty) return <Navigate to={LOGIN_PAGE.path} />;

  return (
    <>
      <div className={styles.filler}>
        <div className={styles.passwordPageContainer}>
          <Form
            className={styles.passwordPageFormContainer}
            contentClassName={styles.passwordPageWrapper}
            initialValues={initialNewPasswordResetForm}
            validate={requestPasswordResetValidate}
            onSubmit={handleSubmit}
            loading={loading}
          >
            <Heading tag="h2" color="textSecondary" className={styles.passwordPageTitle}>
              {localeStore.t("auth.reset.password.title")}
            </Heading>

            <Typography className={styles.message} size="small" color="textTertiary">
              {localeStore.t("auth.reset.password.subtitle")}
            </Typography>

            <FormField
              className={styles.passwordContainer}
              type="password"
              name="password"
              label={localeStore.t("auth.reset.password.fields.password.label")}
              placeholder={localeStore.t("auth.reset.password.fields.password.placeholder")}
              appearance="primaryV2"
              bottomMessageAlign="opposite-of-error"
              bottomMessage={localeStore.t('auth.reset.password.fields.password["bottom-message"]')}
            />

            <FormField
              className={styles.confirmPasswordContainer}
              type="password"
              name="confirmPassword"
              label={localeStore.t('auth.reset.password.fields["confirm-password"].label')}
              placeholder={localeStore.t(
                'auth.reset.password.fields["confirm-password"].placeholder',
              )}
              appearance="primaryV2"
            />

            <FormButtons className={styles.buttonsWrapper} align="column">
              <Button type="submit">{localeStore.t("auth.reset.password.buttons.save")}</Button>
            </FormButtons>
          </Form>

          {(isIPhone() || isAndroid()) && (
            <div className={styles.appContainer} onClick={() => handleOpenGemHubApp()}>
              <span>{localeStore.t("auth.reset.password.open")} GemHub App</span>
              <SvgIcon icon={ArrowRightSVG} size={32} className={styles.arrowRight}></SvgIcon>
            </div>
          )}
        </div>
      </div>
      {isCallSuccess && (
        <div className={styles.successContainer}>
          <SvgIcon icon={LogoSVG} className={styles.logoIcon} />
          <Logo className={styles.logo} />
          <div className={styles.successMessage}>
            <SvgIcon icon={CheckSVG} className={styles.check} />
            <span>{localeStore.t("auth.reset.password.complete")} </span>
          </div>
          <Button onClick={() => handleOpenGemHubApp("/")} className={styles.openAppButton}>
            {localeStore.t("auth.reset.password.open")} GemHub
          </Button>
        </div>
      )}
    </>
  );
});

export default PasswordPage;
