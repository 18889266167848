import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Checkbox,
  TableCell,
  clsx,
  Button,
  Nullable,
  TableRow,
  TableTitleSection,
  Typography,
  LastCell,
} from "@gemlightbox/core-kit";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { useStores } from "src/hooks";
import { AttributeModel } from "src/models";
import { checkIfAttributeIsCustom } from "src/utils";
import { ProductSortDirectionType, ProductSortingType } from "src/store";
import { TableSortButtons } from "./table-sort-buttons";
import { ProductsTableHeaderNewProps } from "./types";

import styles from "./products-table-header.module.css";

export const ProductsTableHeaderNew: React.FC<ProductsTableHeaderNewProps> = observer(
  ({ forwardRef }) => {
    const [activeSortAngleIcon, setActiveSortAngleIcon] =
      useState<Nullable<{ attributeName: string; sortDir: ProductSortDirectionType }>>(null);

    const { attributesStore, productsStore, localeStore } = useStores();

    const handleSelect = () => productsStore.selectAllProductsList("selected");
    const handleUnselect = () => productsStore.unselectAllProductsList("selected");

    const handleSort = async (attribute: AttributeModel, sortDir: ProductSortDirectionType) => {
      const sortValues: ProductSortingType = {
        sort: "attributeId",
        sortDir,
        sortVal: attribute.id,
      };

      await productsStore.loadProductsList(undefined, undefined, sortValues);
      setActiveSortAngleIcon({ attributeName: attribute.name, sortDir });
    };

    const handleClearSorting = async () => {
      productsStore.resetSortValues();
      await productsStore.loadProductsList();
      setActiveSortAngleIcon(null);
    };

    return (
      <TableRow className={styles.container} appearance="header" forwardRef={forwardRef}>
        <TableTitleSection appearance="header" className={styles.headerTitleSection}>
          <TableCell className={styles.checkboxCell}>
            <Checkbox
              className={styles.checkbox}
              checked={productsStore.isEveryProductSelected}
              onChange={productsStore.isEveryProductSelected ? handleUnselect : handleSelect}
              data-cy="products-table-header-checkbox"
              disableError
            />
          </TableCell>
          <TableCell className={styles.media}>
            <Typography size="extraSmall" color="textSecondary">
              {localeStore.t(
                'products["products-list"]["products-table"]["products-table-header"]["media-column"]',
              )}
            </Typography>
          </TableCell>
          {attributesStore.findAttribute("title") && (
            <TableCell className={clsx(styles.title, styles.headerCell)}>
              <Typography size="extraSmall" color="textSecondary">
                SKU
              </Typography>
              {attributesStore.columnsAttributes[0] && (
                <TableSortButtons
                  attribute={attributesStore.findAttribute("title") as AttributeModel}
                  activeSortAngleIcon={activeSortAngleIcon}
                  handleSort={handleSort}
                />
              )}
            </TableCell>
          )}
        </TableTitleSection>
        {attributesStore.columnsAttributes.map((attribute) => {
          if (attribute.name === "title") return null;
          const isSortable = attribute.kind === "default" && attribute.name !== "description";
          return (
            <TableCell
              key={attribute.name}
              className={clsx(styles.headerCell, styles[attribute.name], {
                [styles.headerCell]: checkIfAttributeIsCustom(attribute),
              })}
            >
              <Typography size="extraSmall" color="textSecondary">
                {["title", "price", "producttype", "description", "quantity"].includes(
                  (attributesStore?.attributesNamesMap[attribute.id] + "")
                    .replace(" ", "")
                    .toLocaleLowerCase(),
                )
                  ? localeStore.t(
                      `products["products-list"]["products-table"]["products-table-header"].${attributesStore.attributesNamesMap[
                        attribute.id
                      ]
                        ?.replace(" ", "")
                        .toLowerCase()}` as LocaleCodeTypes,
                    )
                  : attributesStore.attributesNamesMap[attribute.id]}
              </Typography>
              {isSortable && (
                <TableSortButtons
                  attribute={attribute}
                  activeSortAngleIcon={activeSortAngleIcon}
                  handleSort={handleSort}
                />
              )}
            </TableCell>
          );
        })}
        <TableCell className={styles.headerCell}>
          <Typography size="extraSmall" color="textSecondary">
            {localeStore.t(
              'products["products-list"]["products-table"]["products-table-header"]["views-column"]',
            )}
          </Typography>
        </TableCell>
        <LastCell className={styles.actions} appearance="header">
          {productsStore.sortValues.sort !== "newest" && (
            <Button
              appearance="primaryOutlined"
              size="small"
              onClick={handleClearSorting}
              className={styles.clearButton}
            >
              {localeStore.t(
                'products["products-list"]["products-table"]["products-table-header"].buttons["clear-sorting"]',
              )}
            </Button>
          )}
        </LastCell>
      </TableRow>
    );
  },
);
