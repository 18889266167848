import { AttributesStore } from "./attributes";
import { CatalogStore } from "./catalog";
import { CatalogsStore } from "./catalogs";
import { CategoriesStore } from "./categories";
import { CTFStore } from "./chow-tai-fook";
import { HubspotStore } from "./hubspot";
import { LocaleStore } from "./locale";
import { MediaStore } from "./media";
import { ModalsStore } from "./modals";
import { OrdersStore } from "./orders";
import { ProductsStore } from "./products";
import { ProductsImportStore } from "./products-import";
import { ShopifyStore } from "./shopify";
import { SubaccountsStore } from "./subaccounts";
import { SubscriptionsStore } from "./subscriptions";
import { TrashBinStore } from "./trash-bin";
import { UserStore } from "./user";
import { LinkedInStore } from "./linkedin";
import { AppCallbackStore } from "./app-callback";
import { StoresType } from "./root.store.types";

export class RootStore {
  private _stores: StoresType = {
    attributesStore: new AttributesStore(),
    catalogStore: new CatalogStore(),
    catalogsStore: new CatalogsStore(this),
    categoriesStore: new CategoriesStore(),
    chowTaiFookStore: new CTFStore(),
    hubspotStore: new HubspotStore(),
    localeStore: new LocaleStore(),
    mediaStore: new MediaStore(),
    modalsStore: new ModalsStore(),
    ordersStore: new OrdersStore(),
    productsStore: new ProductsStore(),
    productsImportStore: new ProductsImportStore(),
    shopifyStore: new ShopifyStore(),
    subaccountsStore: new SubaccountsStore(),
    subscriptionsStore: new SubscriptionsStore(this),
    trashBinStore: new TrashBinStore(),
    userStore: new UserStore(),
    linkedInStore: new LinkedInStore(),
    appCallbackStore: new AppCallbackStore(),
  };

  get stores() {
    return this._stores;
  }
}

export const rootStore = new RootStore();
