import React, { useRef } from "react";
import { memo, SvgIcon, Tooltip, useBoolean } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as InfoSVG } from "src/external-ts/assets/images/info-circle-grey.svg";
import styles from "./eraser-info.module.css";

export type EraserInfoProps = {
  onInfoClick: VoidFunction;
};

export const EraserInfo: React.FC<EraserInfoProps> = memo(({ onInfoClick }) => {
  const { localeStore } = useStores();

  const ref = useRef<HTMLDivElement>(null);
  const tooltipActive = useBoolean(false);

  const beforeInfoClick = () => {
    tooltipActive.setFalsy();
    onInfoClick();
  };

  return (
    <>
      <div ref={ref} className={styles.container} onMouseEnter={tooltipActive.setTruthy}>
        <SvgIcon icon={InfoSVG} size={[16, 16]} />
      </div>

      <Tooltip
        appearance="primary"
        position="rightTop"
        className={styles.tooltip}
        target={ref}
        isOpen={tooltipActive.value}
        onClose={tooltipActive.setFalsy}
        withAngle
      >
        {localeStore.t('["ar-media"]["media-editor"]["brush-hint"]')}
        <span className={styles.link} onClick={beforeInfoClick}>
          &nbsp; {localeStore.t('["ar-media"]["media-editor"].link')}
        </span>
      </Tooltip>
    </>
  );
});
