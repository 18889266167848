import { Vec2 } from "@gemlightbox/core-kit";

// Same as default canvas size
export const cameraInitViewport = new Vec2(0, 0);

// cameraAnimationTime in ms
export const cameraAnimationTime = 125;

export const CAMERA_MAX_SCALE = 8;

export const CAMERA_MIN_SCALE = 1;

export const cameraScalesArr: Array<[name: string | number, scale: number]> = [
  [8, 8],
  [7.5, 7.5],
  [7, 7],
  [6.5, 6.5],
  [6, 6],
  [5.5, 5.5],
  [5, 5],
  [4.5, 4.5],
  [4, 4],
  [3.5, 3.5],
  [3, 3],
  [2.5, 2.5],
  [2, 2],
  [1.5, 1.5],
  [1, 1],
];
