import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Button,
  Heading,
  SvgIcon,
  useWindowSize,
  WIDTH_BREAKPOINTS,
  EllipseActions,
  ActionOption,
  Nullable,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { PendingUploadStatus, ProductModel } from "src/models";
import { CREATE_PAGE } from "src/constants";
import { ExtendedProductModel } from "src/store";
import { proFeatureTooltip, downloadVideo } from "src/utils";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { MAX_MEDIA_IN_PRODUCT } from "src/containers/products";

import { ReactComponent as ArrowLeftOblongSVG } from "src/external-ts/assets/images/arrow-left-oblong-grey.svg";
import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as ArrowSVG } from "src/external-ts/assets/images/arrow-down-grey.svg";
import { ReactComponent as BlinkSVG } from "src/external-ts/assets/images/media/blink-type.svg";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as DownloadCloudSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as GeneratePostSVG } from "src/external-ts/assets/images/fountain-pen-grey.svg";
import { ReactComponent as SocialsSVG } from "src/external-ts/assets/images/socials-icons.svg";
import { ReactComponent as ProBadgeSVG } from "src/external-ts/assets/images/badges/pro-badge-yellow.svg";
import styles from "./product-page-header.module.css";
import { useShare } from "src/hooks/use-share.hook";
import { ResourceType } from "src/api/graphql-api/share-settings/share-settings.constants";
import { ShareType } from "src/external-ts/components/business/share-setting-modal/share-setting-modal.constants";

export type ProductHeaderProps = {
  title: string;
  onGoBack: VoidFunction;
  product?: Nullable<ProductModel>;
};

export const ProductPageHeader: React.FC<ProductHeaderProps> = observer(
  ({ title, onGoBack, product }: ProductHeaderProps) => {
    const navigate = useNavigate();

    const { windowWidth } = useWindowSize();

    const { canBlinks, canGeneratePosts } = useLimits();

    const { localeStore, modalsStore, subscriptionsStore } = useStores();

    const { handleShare: handleShareProduct } = useShare();

    const handleShareClick = () => {
      if (!product) return;
      handleShareProduct({
        resourceType: ResourceType.PRODUCT,
        resourceIds: [product._id],
        shareType: ShareType.SHARE_PRODUCT,
      });
    };

    const handleModelOptionClick = () => {
      if (!product) return null;
      navigate(CREATE_PAGE.path + "/augmented-reality-product/" + product._id);
    };

    const handleEditOptionClick = () => {
      if (!product) return;
      navigate(`/product/${product._id}/edit`);
    };

    // const handleGeneratePost = () => {
    //   if (!product) return;
    //   modalsStore.open("ShareModal", {
    //     type: "product",
    //     // TODO: fix ShareModal data should not accept ExtendedModels
    //     data: product as any,
    //     tab: "generatePost",
    //   });
    // };

    const handlePointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
      if (subscriptionsStore.isProSubscription) return;
      proFeatureTooltip(e.currentTarget, {
        position: "left",
      });
    };

    const handleDownloadOptionClick = () => {
      if (!product) return;
      // NOTE: downloadMediaModal has conflict condition for download video from product logic
      // created condition below to fix it.
      // Also product as ExtendedProductModel cause of MOBx bug when extending product.
      const isVideo = product.images[0].type === "video" || product.images[0].type === "video360";
      if (isVideo && product.images.length === 1) {
        downloadVideo(product.images[0].id, product as ExtendedProductModel);
        return;
      }
      modalsStore.open("DownloadMediaModal", { media: [product as any], type: "products" });
    };

    const handleDeleteOptionClick = () => {
      if (!product) return;
      modalsStore.open("DeleteProductModal", {
        products: [product._id],
        onDelete: onGoBack,
      });
    };

    const canShowBlink = useMemo(() => {
      const productMedias = product?.images || [];
      const alreadyHasBlink = productMedias.find((media) => media.type === "blink");
      const hasImages = productMedias.some(
        (media) =>
          media.type === "image" &&
          media?.status === PendingUploadStatus.complete &&
          !media.metaData?.isEclipseMode,
      );
      return (
        canBlinks && !alreadyHasBlink && hasImages && productMedias.length < MAX_MEDIA_IN_PRODUCT
      );
    }, [product, canBlinks]);

    return (
      <div className={styles.productHeaderContainer}>
        <Button appearance="secondaryGhost" onClick={onGoBack} data-cy="product-back-button">
          <SvgIcon icon={ArrowLeftOblongSVG} />
        </Button>
        <Heading
          className={styles.header}
          tag={windowWidth < WIDTH_BREAKPOINTS.mobileL ? "h4" : "h3"}
          color="textSecondary"
          data-cy="products-title"
        >
          {title}
        </Heading>

        <Button className={styles.shareBtn} onClick={handleShareClick} data-cy="share">
          <SvgIcon icon={ShareSVG} />
          {localeStore.t("common.buttons.share")}
        </Button>

        <EllipseActions
          data-cy="product-page-actions-btn"
          actionsListClassName={styles.productActionList}
          appearance="primaryOutlined"
          icon={ArrowSVG}
        >
          {canShowBlink && (
            <ActionOption data-cy="product-page-create-blink" onClick={handleModelOptionClick}>
              <SvgIcon icon={BlinkSVG} disableAutoColor />
              {localeStore.t('common.media["create-model-image"]')}
            </ActionOption>
          )}
          <ActionOption data-cy="product-page-edit" onClick={handleEditOptionClick}>
            <SvgIcon icon={EditSVG} />
            {localeStore.t('common.product["edit-product"]')}
          </ActionOption>
          {/* <ActionOption
            onClick={handleGeneratePost}
            data-cy="product-page-generate-post"
            className={styles.generatePostAction}
            disabled={!canGeneratePosts}
            onPointerEnter={handlePointerEnter}
          >
            <SvgIcon icon={GeneratePostSVG} />
            {localeStore.t(
              'media["media-list"]["media-item"]["media-actions"]["generate-ai-post"]',
            )}
            <SvgIcon className={styles.socialsIcons} icon={SocialsSVG} />
            <SvgIcon className={styles.proBadge} icon={ProBadgeSVG} />
          </ActionOption> */}
          <ActionOption data-cy="product-page-download" onClick={handleDownloadOptionClick}>
            <SvgIcon icon={DownloadCloudSVG} />
            {localeStore.t("common.buttons.download")}
          </ActionOption>
          <ActionOption data-cy="product-page-delete" onClick={handleDeleteOptionClick}>
            <SvgIcon className={styles.deleteSVG} icon={DeleteSVG} />
            {localeStore.t("common.buttons.delete")}
          </ActionOption>
        </EllipseActions>
      </div>
    );
  },
);

export default ProductPageHeader;
