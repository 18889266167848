import React from "react";
import { observer } from "mobx-react-lite";
import { Nullable, Input, FilterCollapse, Range } from "@gemlightbox/core-kit";

import { ProductRangeModel } from "src/models";
import { useStores } from "src/hooks";

import styles from "./quantity-filter.module.css";

export interface QuantityFilterProps {
  value: Array<Nullable<number>>;
  quantityRange: ProductRangeModel;
  isFromValid: boolean;
  isToValid: boolean;
  onFromChange: (value: number) => void;
  onToChange: (value: number) => void;
  onRangeChange: (value: number[]) => void;
}

export const QuantityFilter: React.FC<QuantityFilterProps> = observer(
  ({ value, quantityRange, isFromValid, isToValid, onFromChange, onToChange, onRangeChange }) => {
    const { localeStore } = useStores();
    const { max, min } = quantityRange;

    const beforeChange = (type: "from" | "to") => (value: string) => {
      const _value = Number(value);

      if (type === "from") onFromChange(_value);
      if (type === "to") onToChange(_value);
    };

    return (
      <FilterCollapse
        data-cy="quantity-filter-collapse"
        title={localeStore.t(
          'products["products-list"]["products-table"]["products-table-header"].quantity',
        )}
      >
        <div className={styles.container}>
          <div className={styles.inputs}>
            <Input
              data-cy="min-input-quantity"
              appearance="primaryV2"
              placeholder={min ? min.toString() : ""}
              value={value[0] ?? ""}
              onChange={beforeChange("from")}
              error={!isFromValid}
            />
            <Input
              data-cy="max-input-quantity"
              appearance="primaryV2"
              placeholder={max ? max.toString() : ""}
              value={value[1] ?? ""}
              onChange={beforeChange("to")}
              error={!isToValid}
            />
          </div>
          <Range
            data-cy="quantity-range"
            appearance="secondary"
            min={quantityRange.min}
            max={quantityRange.max}
            value={[value[0] ?? quantityRange.min, value[1] ?? quantityRange.max]}
            onChange={(_value) => onRangeChange(_value as number[])}
          />
        </div>
      </FilterCollapse>
    );
  },
);
