import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";

import { LOGIN_PAGE, SETUP_DOWNLOAD_PAGE } from "src/constants/routes.constants";
import { Button, SvgIcon, queryStringify, useBoolean, useQuery } from "@gemlightbox/core-kit";
import { usePageViewEvent, useStores } from "src/hooks";
import { TutorialsModal } from "../../camera/tutorials-modal";

import { ReactComponent as RightLinkSVG } from "src/external-ts/assets/images/right-welcome.svg";
import { ReactComponent as HubLogoSVG } from "src/external-ts/assets/images/welcome/hub-logo-light.svg";
import { ReactComponent as PlayVideoSVG } from "src/external-ts/assets/images/welcome/play-video.svg";
import tutorialVideo from "src/external-ts/assets/images/welcome/tutorial-video.webm";
import styles from "./home.module.css";

export const WelcomeHomePage: React.FC = observer(() => {
  usePageViewEvent();
  const { query } = useQuery();
  const { userStore, localeStore } = useStores();
  const isOpenTutorial = useBoolean(false);
  const path = userStore.isLogged
    ? SETUP_DOWNLOAD_PAGE.path + queryStringify({ ...query, source: "setup" })
    : LOGIN_PAGE.path + queryStringify({ ...query, source: "setup" });

  return (
    <div className={styles.welcomePageContainer}>
      <div className={styles.pageTitle}>{localeStore.t("setup.title")}</div>

      <div className={styles.pageContent}>
        <video autoPlay loop muted className={styles.videoPlaceholder}>
          <source src={tutorialVideo} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className={styles.pageFooter}>
        <Link to={path}>
          <Button className={styles.pageLink}>
            {localeStore.t("setup.start")} <SvgIcon size={24} icon={RightLinkSVG}></SvgIcon>
          </Button>
        </Link>
        <div className={styles.tutorialBox} onClick={isOpenTutorial.setTruthy}>
          <SvgIcon icon={PlayVideoSVG} size={20}></SvgIcon>
          <span className={styles.text}>{localeStore.t("setup.how")}</span>
        </div>
      </div>

      <SvgIcon icon={HubLogoSVG} className={styles.hubLogoLight}></SvgIcon>

      <TutorialsModal
        isOpenTutorial={isOpenTutorial}
        onClose={isOpenTutorial.setFalsy}
      ></TutorialsModal>
    </div>
  );
});
