export enum AccessTypes {
  // r - read, w - write, u - update, d - delete
  member = "wrud",
  viewer = "r",
}

export enum SubaccountStatuses {
  pending = "pending",
  confirmed = "confirmed",
}

export enum SubaccountRoles {
  member = "sub",
  master = "master",
}
