import React, { memo, useRef } from "react";
import {
  Button,
  clsx,
  SvgIcon,
  Checkbox,
  Nullable,
  SetupMouseDownCallback,
  SetupTouchStartCallback,
  globalStyles,
  Typography,
} from "@gemlightbox/core-kit";

import { ExtendedProductModel } from "src/store";
import { getMediaPreviewFromProduct } from "src/utils";
import { useStores } from "src/hooks";

import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import { ReactComponent as NoMediaSvg } from "src/external-ts/assets/images/media/no-media-grey.svg";
import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-grey.svg";
import styles from "./styles.module.css";

interface CardProps {
  card: ExtendedProductModel;
  isAnyItemSelected: boolean;
  isInDragNDrop: boolean;
  isSelected: boolean;
  onMouseDown: SetupMouseDownCallback;
  onTouchStart: SetupTouchStartCallback;
  onClick: (lastClickedElement: Nullable<ExtendedProductModel>) => void;
  handleSelect: (item: ExtendedProductModel) => void;
}

export const Card: React.FC<CardProps> = memo(
  ({
    card,
    isSelected,
    isAnyItemSelected,
    isInDragNDrop,
    onMouseDown,
    onTouchStart,
    onClick,
    handleSelect,
  }: CardProps) => {
    const cardRef = useRef<HTMLDivElement>(null);

    const { localeStore } = useStores();

    const imageToShow = card.images?.[0];
    const isPdf = card?.images?.[0]?.type === "pdf";

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      if (!isSelected) {
        return false;
      }
      onMouseDown(event);
      onClick(card);
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
      if (!isSelected) {
        return false;
      }
      onTouchStart(event);
      onClick(card);
    };

    return (
      <div
        className={clsx(styles.card, {
          [styles.selectedAndDragged]: isInDragNDrop && isSelected,
        })}
        ref={cardRef}
        onClick={() => handleSelect(card)}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        {isAnyItemSelected && (
          <div
            className={clsx(styles.buttonWrapper, {
              [styles.selected]: isSelected,
              [styles.selectedAndDragged]: isInDragNDrop && isSelected,
            })}
          >
            <Button className={styles.selectBtn} appearance="secondary" size="small">
              {isSelected && <SvgIcon className={styles.checkmarkWrapper} icon={CheckmarkSVG} />}
              {isSelected
                ? localeStore.t('["catalog-details"]["product-order-modal"].main.unselect')
                : localeStore.t('["catalog-details"]["product-order-modal"].main.select')}
            </Button>
          </div>
        )}
        {!isAnyItemSelected && (
          <div className={styles.cardActions}>
            <Checkbox
              className={styles.checkbox}
              checked={isSelected}
              onChange={() => null}
              disableError
            />
          </div>
        )}
        <div className={styles.cardImageContainer}>
          <div className={clsx(styles.imageFiller, "productCardImageFiller")} />
          <div className={styles.imageWrapper}>
            {!imageToShow && <SvgIcon icon={NoMediaSvg} size={[83, 53]} />}
            {imageToShow && !isPdf ? (
              <img
                className={styles.cardImage}
                src={getMediaPreviewFromProduct(imageToShow)}
                alt=""
              />
            ) : (
              <SvgIcon icon={PdfSVG} size={80} />
            )}
          </div>
        </div>
        <Typography
          className={clsx(styles.productTitleContainer, globalStyles.applySingleOverflow)}
          size="tiny"
        >
          {card?.title ||
            localeStore.t('["catalog-details"]["product-order-modal"].main["not-assigned"]')}
        </Typography>
      </div>
    );
  },
);
