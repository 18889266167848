import React, { Fragment, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, Tooltip, useBoolean, Webgl2New } from "@gemlightbox/core-kit";

import { ColorPickerContent } from "../../../../color-picker-content";
import { editMediaStore } from "../../../../edit-media.store";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import styles from "./measurement-color.module.css";

export type MeasurementColorProps = {
  color: string;
  index: number;
  measurement: Webgl2New.MeasurementComponent;
};

export const MeasurementColor: React.FC<MeasurementColorProps> = observer(
  ({ color, index, measurement }: MeasurementColorProps) => {
    const { renderer, measurementColors, measurementsComponent } = editMediaStore;

    const buttonRef = useRef<HTMLButtonElement>(null);

    const isInOverBoolean = useBoolean();
    const isEditColorOpenBoolean = useBoolean();

    const isSelected = measurement.color === color;

    const handleCloseTooltip = () => {
      measurement.changeColor(color, measurement.colorIndex);
      measurementsComponent.forEachChildren((component) => {
        if (component instanceof Webgl2New.MeasurementComponent) {
          if (component.colorIndex === index) {
            component.changeColor(color, component.colorIndex);
          }
        }
      });

      renderer.render();

      isEditColorOpenBoolean.setFalsy();
    };

    const handleEditColorClick = (color: string) => {
      isEditColorOpenBoolean.setFalsy();

      const sameColorIndex = measurementColors.findIndex((c) => c === color);
      if (sameColorIndex !== -1) {
        measurementColors.splice(sameColorIndex, 1);
      }

      measurementColors[index] = color;
      measurement.changeColor(color, index);
      measurementsComponent.forEachChildren((component) => {
        if (component instanceof Webgl2New.MeasurementComponent) {
          if (component.colorIndex === index) {
            component.changeColor(color, index);
          }
        }
      });

      renderer.render();
    };

    const handleColorChange = (color: string) => {
      measurement.changeTempColor(color);
      measurementsComponent.forEachChildren((component) => {
        if (component instanceof Webgl2New.MeasurementComponent) {
          if (component.colorIndex === index) {
            component.changeTempColor(color);
          }
        }
      });

      renderer.render();
    };

    const handleColorClick = () => {
      if (isSelected) {
        return isEditColorOpenBoolean.trigger();
      }

      measurement.changeColor(color, index);
      renderer.render();
    };

    return (
      <Fragment>
        <Button
          appearance="unset"
          size="unset"
          forwardRef={buttonRef}
          onClick={handleColorClick}
          onMouseEnter={isInOverBoolean.setTruthy}
          onMouseLeave={isInOverBoolean.setFalsy}
        >
          <div className={styles.measurementColor} style={{ backgroundColor: color }}>
            {!isEditColorOpenBoolean.value && isSelected && (
              <Fragment>
                {isInOverBoolean.value && (
                  <SvgIcon className={styles.icon} icon={EditSVG} size="small" />
                )}
                {!isInOverBoolean.value && (
                  <SvgIcon className={styles.icon} icon={CheckmarkSVG} size="small" />
                )}
              </Fragment>
            )}
          </div>
        </Button>
        <Tooltip
          className={styles.colorPickerTooltip}
          appearance="unset"
          outsideClickOption="rect"
          position="right"
          offsetX={4}
          target={buttonRef}
          onClose={handleCloseTooltip}
          isOpen={isEditColorOpenBoolean.value}
          disableAutoPosChange
        >
          <ColorPickerContent
            color={measurement.tempColor}
            onColorChange={handleColorChange}
            onEditColorClick={handleEditColorClick}
            onClose={handleCloseTooltip}
            isEdit
          />
        </Tooltip>
      </Fragment>
    );
  },
);

export default MeasurementColor;
