import React from "react";
import { observer } from "mobx-react-lite";
import { addMonths, Heading, Typography, Input, useQuery, clsx } from "@gemlightbox/core-kit";

import { formatDate } from "src/common/helpers/subscriptions.helpers";
import { useStores } from "src/hooks";
import { BillingInfoItem } from "../billing-info-item";

import styles from "../../billing-modal.module.css";

export interface BillingLifetimeProps {
  coupon: string;
  yearlyPrice: number;
  currentExtraPrice: number;
  onCouponChange: (value: string) => void;
  promoCodeError?: string;
  promoCodeRequired?: boolean;
}

export const BillingLifetime: React.FC<BillingLifetimeProps> = observer(
  ({
    coupon,
    yearlyPrice,
    currentExtraPrice,
    onCouponChange,
    promoCodeError = "",
    promoCodeRequired,
  }: BillingLifetimeProps) => {
    const { localeStore, subscriptionsStore } = useStores();

    const {
      query: { bluetooth_name },
    } = useQuery();

    return (
      <div className={styles.rightContainer}>
        <div className={styles.rightContent}>
          <Heading className={styles.leftHeader} tag="h3" color="textSecondary">
            US${yearlyPrice}/yearly
          </Heading>

          <div className={styles.billingInfoContainer}>
            <BillingInfoItem
              content={
                <Typography
                  className={styles.offerItemName}
                  size="extraSmall"
                  color="textSecondary"
                >
                  {localeStore.t(
                    'settings.subscriptions.components.content["lifetime-offer"]["expand-cloud"]',
                  )}
                </Typography>
              }
            />

            <BillingInfoItem
              content={
                <Typography
                  className={clsx(styles.offerItemName, styles.oneLine)}
                  size="extraSmall"
                  color="textSecondary"
                >
                  {localeStore.t(
                    'settings.subscriptions.components["billing-modal"].components["billing-yearly"]["today-charge"].first',
                  )}{" "}
                  ${subscriptionsStore.formattedBillingInfo?.current_amount_due ?? "--"}{" "}
                  {localeStore.t(
                    'settings.subscriptions.components["billing-modal"].components["billing-yearly"]["today-charge"].middle',
                  )}{" "}
                  ({localeStore.t("settings.subscriptions.components.content.balance")}).
                </Typography>
              }
            />
            <BillingInfoItem
              content={
                <Typography
                  className={styles.offerItemName}
                  size="extraSmall"
                  color="textSecondary"
                >
                  {localeStore.t(
                    'settings.subscriptions.components["billing-modal"].components["billing-yearly"].thereafter.first',
                  )}{" "}
                  US${yearlyPrice}{" "}
                  {localeStore.t(
                    'settings.subscriptions.components["billing-modal"].components["billing-yearly"].thereafter.last',
                  )}
                  .{" "}
                  <span className={styles.nextDate}>
                    {localeStore.t(
                      'settings.subscriptions.components["billing-modal"].components["next-billing"]',
                    )}{" "}
                    {subscriptionsStore.formattedBillingInfo
                      ? formatDate(subscriptionsStore.formattedBillingInfo.next_billing_date)
                      : formatDate(addMonths(12, Date.now()))}
                  </span>
                </Typography>
              }
            />
          </div>
        </div>

        {(promoCodeRequired || !subscriptionsStore.isSubscribed) && !bluetooth_name && (
          <Input
            className={styles.promoCodeContainer}
            label={localeStore.t(
              'settings.subscriptions.components["billing-modal"]["promo-code"].label',
            )}
            placeholder={localeStore.t(
              'settings.subscriptions.components["billing-modal"]["promo-code"].placeholder',
            )}
            appearance="primaryV2"
            value={coupon}
            error={promoCodeError}
            onChange={onCouponChange}
            disableError={!promoCodeRequired}
          />
        )}
      </div>
    );
  },
);

export default BillingLifetime;
