import React, { memo } from "react";
import { clsx } from "@gemlightbox/core-kit";

import styles from "./stripe-input.module.css";

export interface StripeInputProps {
  label: string;
  children: React.ReactNode;
  className?: string;
}

export const StripeInput: React.FC<StripeInputProps> = memo(
  ({ label, children, className = "" }: StripeInputProps) => {
    return (
      <div className={clsx(styles.container, className)}>
        <div className={styles.label}>{label}</div>
        <div className={clsx(styles.cardNumberWrapper, styles.stripeInputWrapper)}>{children}</div>
      </div>
    );
  },
);

export default StripeInput;
