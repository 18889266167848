import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { SvgIcon, Heading, useWindowSize, Button } from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import { useStores } from "src/hooks";

import { ReactComponent as ArrowLeftBack } from "src/external-ts/assets/images/arrow-left-oblong-grey.svg";
import { ReactComponent as CheckmarkIcon } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";

import styles from "./create-catalog-header.module.css";

interface CreateCatalogHeaderProps {
  formId: string;
  type: "automatic" | "manual";
  isSubmitButtonDisabled: boolean;
  loading: boolean;
}

export const CreateCatalogHeader: React.FC<CreateCatalogHeaderProps> = observer(
  ({ formId, type, isSubmitButtonDisabled, loading }) => {
    const { windowWidth } = useWindowSize();
    const navigate = useNavigate();

    const { localeStore } = useStores();

    const isVerticalTablet = windowWidth < BREAKPOINTS.tablet;
    const isMobile = windowWidth < BREAKPOINTS.mobileL - 1;

    return (
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <Link className={styles.back} to="/catalog">
            <SvgIcon icon={ArrowLeftBack} />
          </Link>
          <Heading tag={isVerticalTablet ? "h4" : "h3"} color="textSecondary">
            {type === "automatic"
              ? localeStore.t('["create-catalog"]["create-catalog-header"].title.auto')
              : localeStore.t('["create-catalog"]["create-catalog-header"].title.manual')}
          </Heading>
        </div>

        {!isMobile && (
          <div className={styles.headerRight}>
            <Button
              className={styles.cancelButton}
              appearance="secondaryOutlined"
              data-cy="create-catalog-cancel-button"
              onClick={() => navigate("/catalog")}
            >
              {localeStore.t('["create-catalog"]["create-catalog-header"].buttons.cancel')}
            </Button>
            <Button
              type="submit"
              form={formId}
              data-cy="create-catalog-create-button"
              disabled={isSubmitButtonDisabled}
              loading={loading}
            >
              <SvgIcon icon={CheckmarkIcon} />
              {localeStore.t('["create-catalog"]["create-catalog-header"].buttons.create')}
            </Button>
          </div>
        )}
      </div>
    );
  },
);
