import React, { useMemo, useState } from "react";
import { FormikHelpers, FormikProps } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  clsx,
  globalStyles,
  Nullable,
  Form,
  ObjectType,
  JSONParse,
  TextEditorUtils,
} from "@gemlightbox/core-kit";

import {
  ProductAttributes,
  ProductHeader,
  ProductMainMedia,
  ProductMediasBar,
} from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ProductImageModel, ProductModel, ProductRequestModel } from "src/models";
import { ErrorCodes, PRODUCTS_PAGE } from "src/constants";
import { getAttributesForRequest } from "src/utils";
import { formId } from "./product-create.page.constants";
import { ProductCreatePageState } from "./product-create.page.types";

import styles from "./product-create.page.module.css";

export const ProductCreatePage: React.FC = observer(() => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const pageState: Nullable<ProductCreatePageState> = useMemo(() => {
    return state ? JSONParse(state as any) : null;
  }, [state]);

  const [productMedias, setProductMedias] = useState<ProductImageModel[]>(
    pageState?.initialImages ?? [],
  );

  const [selectedMedia, setSelectedMedia] = useState<Nullable<ProductImageModel>>(productMedias[0]);
  const [formInner, setFormInner] = useState<FormikProps<any> | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { localeStore, modalsStore, notificationStore, attributesStore, productsStore } =
    useStores();

  const initialValues: ObjectType = useMemo(() => {
    return {
      detailedTitle: pageState?.initialFormValues?.detailedTitle || "",
      productType: pageState?.initialFormValues?.productType || "",
      description: pageState?.initialFormValues?.description || "",
    };
  }, [pageState]);

  const handleSelectMedia = (media: ProductImageModel) => setSelectedMedia(media);

  const handleGoBack = () => navigate(-1);

  const handleConfirmGoBack = () => {
    if (formInner?.dirty) {
      notificationStore.open({
        title: localeStore.t('common.warnings["confirm-action"]'),
        message: localeStore.t('common.warnings["exit-without-saving"]'),
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: localeStore.t("common.buttons.cancel"),
        icon: "exclamation",
        onOk: handleGoBack,
      });
    } else {
      handleGoBack();
    }
  };

  const handleRemoveMedia = (media: ProductImageModel) => {
    const newMedias = productMedias.filter((curr) => curr.id !== media.id);
    setProductMedias(newMedias);
    if (media.id === selectedMedia?.id) handleSelectMedia(newMedias[0]);
  };

  const handleAddMediaClick = () => {
    modalsStore.open("ProductAssignMediasSidebar", {
      productImages: productMedias,
      onSubmit: (medias) => {
        const newMedias = productMedias.concat(medias as any);
        setProductMedias(newMedias);
        if (!selectedMedia?.id) handleSelectMedia(newMedias[0]);
      },
    });
  };

  const handleReplaceMedia = (mediaId: ProductImageModel["id"], newMedia: ProductImageModel) => {
    const newMedias = productMedias.map((curr) => {
      if (curr.id !== mediaId) return curr;
      return newMedia;
    });
    setProductMedias(newMedias);
    handleSelectMedia(newMedia);
  };

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    const data: ProductRequestModel = {
      // title for backwards compatability with attributesVersion v1
      title: values.title,
      parameters: getAttributesForRequest(values, attributesStore.attributes),
    };

    if (productMedias.length > 0) {
      data.mediaIDS = productMedias.map(({ id }) => id);
    }

    setIsSubmitting(true);

    const result = await productsStore.createProduct(data);

    setIsSubmitting(false);

    if (result.error) {
      if (
        result.details.statusCode === 400 &&
        result.error.originalError?.code === ErrorCodes.PRODUCT_TITLE_EXISTS
      ) {
        // TODO: localize it?
        helpers.setFieldError("title", "Product with provided SKU already exists.");
      }

      return;
    }

    if (pageState?.type === "ai-description") {
      // NOTE: as ProductModel is made intentionally, due to when new product created,
      // it must be present in list, because we have "index-response" request header.
      const createdProduct = result.success.rows.find(
        (product) => product.title === values.title,
      ) as ProductModel;

      const normalized = TextEditorUtils.normalizeEditorValue(createdProduct.description ?? "");
      const stringified = TextEditorUtils.stringifyEditorValue(normalized);

      modalsStore.open("WellDoneModal", {
        type: "product",
        message: stringified,
        clickHerePreText: localeStore.t(
          'create["ai-product-description"]["well-done-modal-pre-text"]',
        ),
        data: productsStore.extendProduct(createdProduct),
      });
    }

    navigate(PRODUCTS_PAGE.path);
  };

  return (
    <div className={styles.productCreatePageContainer}>
      <ProductHeader
        title={localeStore.t('["product-create"].title')}
        formId={formId}
        onGoBack={handleConfirmGoBack}
        onCancel={handleConfirmGoBack}
        loading={isSubmitting}
      />

      <div className={clsx(styles.productCreatePageContent, globalStyles.addScrollStyles)}>
        <div className={styles.leftWrapper}>
          <ProductMainMedia
            productImage={selectedMedia}
            onAddMediaClick={handleAddMediaClick}
            onMediaReplace={handleReplaceMedia}
            canAddMedia={!productMedias.length}
          />
          <ProductMediasBar
            productMedias={productMedias}
            onAddMediaClick={handleAddMediaClick}
            onRemoveMediaClick={handleRemoveMedia}
            onMediaClick={handleSelectMedia}
            onMediaDrag={setProductMedias}
          />
        </div>

        <Form
          className={clsx(styles.productFormContainer, globalStyles.addScrollStyles)}
          contentClassName={styles.productFormContent}
          formId={formId}
          initialValues={initialValues}
          innerRef={setFormInner}
          onSubmit={handleSubmit}
          enableReinitialize
          data-cy="product-info"
        >
          <ProductAttributes productMedias={productMedias} />
        </Form>
      </div>
    </div>
  );
});

export default ProductCreatePage;
