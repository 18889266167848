import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { SelectGroup, SelectGroupOption, clsx } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";

import styles from "./background-fill-select.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export type BackgroundFillSelectProps = {
  className?: string;
  inputWrapperClassName?: string;
  onChange?: (value: ColorValue) => void;
  value: ColorValue;
};

export const colorsArr = [
  { label: "White", value: "white" },
  { label: "Transparent grid", value: "transparent" },
] as const;

export type ColorValue = (typeof colorsArr)[number]["value"];

export const BackgroundFillSelect: React.FC<BackgroundFillSelectProps> = observer(
  ({ className, inputWrapperClassName, onChange, value }: BackgroundFillSelectProps) => {
    const { localeStore } = useStores();

    const handleChangeLocale = (value: ColorValue) => {
      onChange?.(value);
    };

    const getLabelLocalized = (value: ColorValue) => {
      return localeStore.t(
        `settings.profile["background-fill-block"]["color-labels"]["${value.toLowerCase()}"]` as LocaleCodeTypes,
      );
    };

    const renderIcon = (color: ColorValue) => {
      return (
        <div
          className={clsx(styles.colorIcon, {
            [styles.transparentColor]: color === "transparent",
          })}
          style={{ backgroundColor: color }}
        ></div>
      );
    };

    const localeLabel = useMemo(() => {
      const item = colorsArr.find((option) => option.value === value) || colorsArr[0];
      return getLabelLocalized(item.value);
    }, [value, localeStore.currentLocaleCode]);

    return (
      <SelectGroup
        className={className}
        inputWrapperClassName={clsx(styles.selectWrapper, inputWrapperClassName)}
        searchValue={localeLabel}
        value={value}
        onChange={handleChangeLocale}
        renderPrefix={() => renderIcon(value)}
        disableClear
        disableError
        disableUncheck
        disableSearch
      >
        {colorsArr.map((option) => {
          return (
            <SelectGroupOption
              key={option.value}
              contentContainerClassName={styles.option}
              value={option.value}
            >
              {renderIcon(option.value)}
              {`${getLabelLocalized(option.value)}`}
            </SelectGroupOption>
          );
        })}
      </SelectGroup>
    );
  },
);

export default BackgroundFillSelect;
