import React, { memo } from "react";
import { Modal, Button, Form, FormField } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { initialValues } from "./subscription-cancel-survey-2nd.constants";
import { SubscriptionCancelSurvey2ndForm } from "./subscription-cancel-survey-2nd.types";

import styles from "./subscription-cancel-survey-2nd.module.css";

export interface SubscriptionCancelSurvey2ndProps {
  isOpen: boolean;
  onSubmit: (res: SubscriptionCancelSurvey2ndForm) => void;
  setClose?: (value: false) => void;
}

export const SubscriptionCancelSurvey2nd: React.FC<SubscriptionCancelSurvey2ndProps> = memo(
  ({ isOpen, onSubmit, setClose }: SubscriptionCancelSurvey2ndProps) => {
    const { localeStore } = useStores();

    return (
      <Modal isOpen={isOpen} setClose={setClose} withCross>
        <Form
          contentClassName={styles.formContent}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <div className={styles.modalTitle}>
            {localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-2nd"].title',
            )}
          </div>
          <div className={styles.modalSubtitle}>
            {localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-2nd"].subtitle',
            )}
          </div>
          <FormField
            type="text"
            name="whatGood"
            label={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-2nd"].fields["what-good"].label',
            )}
            placeholder={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-2nd"].fields["what-good"].placeholder',
            )}
            appearance="secondary"
          />
          <FormField
            type="text"
            name="howToPrevent"
            label={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-2nd"].fields["how-to-prevent"].label',
            )}
            placeholder={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-2nd"].fields["how-to-prevent"].placeholder',
            )}
            appearance="secondary"
          />
          <FormField
            type="text"
            name="wantInterview"
            label={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-2nd"].fields["want-interview"].label',
            )}
            placeholder={localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-2nd"].fields["want-interview"].placeholder',
            )}
            appearance="secondary"
          />
          <Button className={styles.submitButton} type="submit">
            {localeStore.t(
              'settings.subscriptions.components["subscription-cancel-survey-2nd"].buttons.submit',
            )}
          </Button>
        </Form>
      </Modal>
    );
  },
);

export default SubscriptionCancelSurvey2nd;
