import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  clsx,
  parsePhoneNumber,
  Tr,
  Td,
  round,
  Button,
  useDidUpdate,
  useBoolean,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { DataStrategy, SubaccountModel } from "src/models";
import { TableActionIcon } from "src/external-ts/components";
import { SubaccountRoles } from "src/containers/settings/subaccounts/subaccounts.constants";

import { ReactComponent as UserAvatarPlaceholderIcon } from "src/external-ts/assets/images/profile-grey.svg";
import { ReactComponent as TrashIcon } from "src/external-ts/assets/images/trash-grey.svg";
import styles from "./subaccount-table-row.module.css";

interface SubaccountTableRowProps {
  role: string;
  countOfImages: string | number;
  countOfVideos: string | number;
  storageUsage: string | number;
  data: SubaccountModel;
}

export const SubaccountTableRow: React.FC<SubaccountTableRowProps> = observer(
  ({ data, role, countOfImages, countOfVideos, storageUsage }) => {
    const { localeStore, notificationStore, userStore, subaccountsStore } = useStores();

    const { avatar, firstName, lastName, email, phone } = data;

    const [selectedPolicy, setSelectedPolicy] = useState("");
    const [loading, setLoading] = useState(false);
    const visiable = useBoolean();

    // const handleAction = () => {
    //   notificationStore.open({
    //     title: `${localeStore.t('settings.subaccounts["delete-modal"].title.first')} ${
    //       data.email
    //     } ${localeStore.t('settings.subaccounts["delete-modal"].title.last')}?`,
    //     confirmText: localeStore.t("common.buttons.yes"),
    //     cancelText: localeStore.t("common.buttons.no"),
    //     confirmAppearance: "secondary",
    //     onOk: () => subaccountsStore.deleteSubaccount(data),
    //   });
    // };

    const handleOptionalClick = async (dataStrategy: DataStrategy) => {
      setLoading(true);
      setSelectedPolicy(dataStrategy);
      await subaccountsStore.deleteSubaccount(data, dataStrategy);
      await subaccountsStore.getPendingEvictSubaccountJob();
      notificationStore.close();
    };

    // delete subaccount
    const handleAction = () => {
      visiable.setTruthy();
    };

    const phoneNumberParsed = parsePhoneNumber(phone || "");
    const isAdminRow = role === SubaccountRoles.master;

    useDidUpdate(() => {
      if (!visiable.value) {
        notificationStore.close();
        return;
      }
      notificationStore.open({
        title: `${localeStore.t('settings.subaccounts["delete-modal"].title.first')} (${
          data.email
        }), ${localeStore.t('settings.subaccounts["delete-modal"].title.last')}?`,
        message: (
          <div className={styles.btnOptionalGroup}>
            <Button
              appearance="primary"
              className={styles.buttonOptional}
              onClick={() => handleOptionalClick(DataStrategy.RETAIN_ALL)}
              loading={loading && selectedPolicy === DataStrategy.RETAIN_ALL}
              disabled={selectedPolicy !== "" && selectedPolicy !== DataStrategy.RETAIN_ALL}
            >
              {localeStore.t('settings.subaccounts["data-policy"]["retain-all"]')}
            </Button>
            <Button
              appearance="secondaryOutlined"
              data-cy="delete-subaccount"
              className={styles.buttonOptional}
              onClick={() => handleOptionalClick(DataStrategy.RETAIN_NONE)}
              loading={loading && selectedPolicy === DataStrategy.RETAIN_NONE}
              disabled={selectedPolicy !== "" && selectedPolicy !== DataStrategy.RETAIN_NONE}
            >
              {localeStore.t('settings.subaccounts["data-policy"]["retain-none"]')}
            </Button>
            <Button
              appearance="secondaryOutlined"
              className={styles.buttonOptional}
              onClick={() => handleOptionalClick(DataStrategy.RETAIN_PARTIAL)}
              loading={loading && selectedPolicy === DataStrategy.RETAIN_PARTIAL}
              disabled={selectedPolicy !== "" && selectedPolicy !== DataStrategy.RETAIN_PARTIAL}
            >
              {localeStore.t('settings.subaccounts["data-policy"]["retain-partical"]')}
            </Button>
          </div>
        ),
        confirmText: "",
        cancelText: "",
        icon: "exclamation",
        confirmAppearance: "unset",
        cancelAppearance: "unset",
        onFinal: () => {
          visiable.setFalsy();
        },
      });
    }, [visiable.value, loading, selectedPolicy]);

    return (
      <Tr>
        <Td className={styles.avatarCell}>
          <div className={styles.avatarContainer}>
            {avatar ? (
              <img
                className={styles.avatar}
                src={typeof avatar === "string" ? avatar : avatar.small}
                alt=""
              />
            ) : (
              <UserAvatarPlaceholderIcon />
            )}
          </div>
        </Td>
        <Td className={styles.cell}>
          <div className={styles.nameContainer}>
            <div className={styles.name}>
              {firstName} {lastName}
            </div>
            <div className={clsx(styles.role, isAdminRow ? styles.admin : styles.member)}>
              {isAdminRow
                ? localeStore.t("settings.subaccounts.roles.admin")
                : localeStore.t("settings.subaccounts.roles.member")}
            </div>
          </div>
        </Td>
        <Td className={styles.cell}>
          <div className={styles.email}>{email}</div>
        </Td>
        <Td className={styles.cell}>
          <div>{`+${phoneNumberParsed?.countryCallingCode} ${phoneNumberParsed?.nationalNumber}`}</div>
        </Td>
        <Td className={styles.cellSmall}>
          <div className={styles.amount}>{countOfImages}</div>
        </Td>
        <Td className={styles.cellSmall}>
          <div className={styles.amount}>{countOfVideos}</div>
        </Td>
        <Td className={styles.cellSmall}>
          <div className={styles.amount}>{storageUsage} GB</div>
        </Td>
        {!userStore.isSubaccount && (
          <Td className={clsx(styles.cellSmall, styles.actions)}>
            {!isAdminRow && (
              <TableActionIcon
                label={localeStore.t("common.buttons.delete")}
                appearance="errorGhost"
                icon={TrashIcon}
                onClick={handleAction}
              />
            )}
          </Td>
        )}
      </Tr>
    );
  },
);
