import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Typography, Chip } from "@gemlightbox/core-kit";

import {
  DefaultFilterNameType,
  initialFilters,
  defaultFiltersNames,
  defaultFiltersNamesLabels,
  FiltersType,
} from "src/external-ts/components";
import { ProductParameterRequestModel, productDefaultTypesNames } from "src/models";
import { MediaTypeNames } from "src/containers/media/media.constants";
import { useStores, useUserOptions } from "src/hooks";

import styles from "./selected-filters.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

interface SelectedFiltersProps {
  filters: FiltersType;
  filtersAmount: number;
  onChange: (filters: FiltersType) => void;
  onClear: VoidFunction;
  onOpen?: () => Promise<void>;
}

export const SelectedFilters: React.FC<SelectedFiltersProps> = observer(
  ({ filters, filtersAmount, onChange, onClear, onOpen }) => {
    const { attributesStore, localeStore } = useStores();
    const { names: userNames } = useUserOptions();

    const {
      fromPrice,
      toPrice,
      quantity,
      attributes,
      mediaType,
      user_id,
      isThereMedia,
      isPrivate,
    } = filters;

    const handleClearDefault = (name: DefaultFilterNameType) => () => {
      onChange({ ...filters, [name]: initialFilters[name] });
    };

    const handleClearPrice = () => {
      onChange({
        ...filters,
        fromPrice: initialFilters.fromPrice,
        toPrice: initialFilters.toPrice,
      });
    };

    const handleClearQuantity = () => {
      onChange({ ...filters, quantity: initialFilters.quantity });
    };

    const handleClearMediaType = () => {
      onChange({ ...filters, mediaType: "all" });
    };

    const handleClearUserId = () => {
      onChange({ ...filters, user_id: "all" });
    };

    const handleClearMediaFilter = () => {
      onChange({ ...filters, isThereMedia: undefined });
    };

    const handleClearLinkAccessFilter = () => {
      onChange({ ...filters, isPrivate: undefined });
    };

    const handleClearCustom =
      (attribute_id: ProductParameterRequestModel["attribute_id"]) => () => {
        const newAttributes = attributes.filter(
          (attribute) => attribute.attribute_id !== attribute_id,
        );
        onChange({ ...filters, attributes: newAttributes });
      };

    if (!filtersAmount) return null;

    return (
      <div data-cy="selected-filters" className={styles.container}>
        <Typography size="small600" color="textSecondary">
          {localeStore.t('components.business.filters["selected-filters"].title')}
        </Typography>

        <div className={styles.filtersList}>
          {!!onOpen && (
            <Button
              className={styles.editBtn}
              size="small"
              data-cy="edit-selected-filters-button"
              onClick={onOpen}
            >
              {localeStore.t('components.business.filters["selected-filters"].buttons.edit')}
            </Button>
          )}
          <Button
            className={styles.clearBtn}
            appearance="secondary"
            size="small"
            data-cy="clear-selected-filters-button"
            onClick={onClear}
          >
            {`${localeStore.t(
              'components.business.filters["selected-filters"].buttons.clear',
            )} (${filtersAmount})`}
          </Button>
          {defaultFiltersNames.map((name) => (
            <React.Fragment key={name}>
              {filters[name] && filters[name] !== "all" && (
                <Chip
                  className={styles.filter}
                  appearance="primaryV2"
                  preText={
                    ["title", "price", "producttype", "description", "quantity"].includes(
                      (defaultFiltersNamesLabels[name] + "").replace(" ", "").toLocaleLowerCase(),
                    )
                      ? localeStore.t(
                          `products["products-list"]["products-table"]["products-table-header"]["${defaultFiltersNamesLabels[
                            name
                          ]
                            .replace(" ", "")
                            .toLocaleLowerCase()}"]` as LocaleCodeTypes,
                        ) + ":"
                      : defaultFiltersNamesLabels[name] + ":"
                  }
                  text={
                    name === "productType"
                      ? localeStore.t(
                          `create["augmented-reality-item"]["ar-types"].${
                            productDefaultTypesNames[filters[name].toLocaleLowerCase()]
                          }` as LocaleCodeTypes,
                          filters[name],
                        )
                      : filters[name]
                  }
                  onDelete={handleClearDefault(name)}
                  data-cy="sku-results"
                  data-name={filters[name]}
                />
              )}
            </React.Fragment>
          ))}
          {fromPrice && toPrice && (
            <Chip
              className={styles.filter}
              appearance="primaryV2"
              preText={
                localeStore.t(
                  `products["products-list"]["products-table"]["products-table-header"].price`,
                ) + ":"
              }
              text={`${fromPrice} - ${toPrice}`}
              onDelete={handleClearPrice}
              data-cy="price-results"
              data-name={`${fromPrice} - ${toPrice}`}
            />
          )}
          {quantity.length !== 0 && (
            <Chip
              className={styles.filter}
              appearance="primaryV2"
              preText={
                localeStore.t(
                  `products["products-list"]["products-table"]["products-table-header"].quantity`,
                ) + ":"
              }
              text={`${quantity[0]} - ${quantity[1]}`}
              onDelete={handleClearQuantity}
              data-cy="quantity-results"
              data-name={`${quantity[0]} - ${quantity[1]}`}
            />
          )}
          {mediaType && mediaType !== "all" && (
            <Chip
              className={styles.filter}
              appearance="primaryV2"
              preText={
                localeStore.t(
                  'media.modals["assign-to-product-sidebar"]["media-item"]["media-type"].title',
                ) + ":"
              }
              text={localeStore.t(
                `media["media-control-panel"].tabs["${mediaType}"]` as LocaleCodeTypes,
              )}
              onDelete={handleClearMediaType}
              data-cy="media-type-results"
              data-name={MediaTypeNames[mediaType]}
            />
          )}
          {user_id !== "all" && (
            <Chip
              className={styles.filter}
              appearance="primaryV2"
              preText={localeStore.t('media["media-selected-filters-panel"].chips["created-by"]')}
              text={String(userNames[user_id])}
              onDelete={handleClearUserId}
              data-cy="author-results"
              data-name={String(userNames[user_id])}
            />
          )}
          {isThereMedia !== undefined && (
            <Chip
              className={styles.filter}
              appearance="primaryV2"
              preText={
                localeStore.t(
                  `products["products-list"]["products-table"]["products-table-header"]["media-column"]`,
                ) + ":"
              }
              text={
                isThereMedia
                  ? localeStore.t(
                      `products["products-list"]["products-table"]["products-table-header"].mediaoptions.with`,
                    )
                  : localeStore.t(
                      `products["products-list"]["products-table"]["products-table-header"].mediaoptions.without`,
                    )
              }
              onDelete={handleClearMediaFilter}
              data-cy="media-results"
              data-name={isThereMedia ? "with" : "without"}
            />
          )}
          {isPrivate !== undefined && (
            <Chip
              className={styles.filter}
              appearance="primaryV2"
              preText={
                localeStore.t(
                  `products["products-list"]["products-table"]["products-table-header"].privacy`,
                ) + ":"
              }
              text={
                isPrivate
                  ? localeStore.t(
                      `products["products-list"]["products-table"]["products-table-header"].sharelinkoptions.private`,
                    )
                  : localeStore.t(
                      `products["products-list"]["products-table"]["products-table-header"].sharelinkoptions.public`,
                    )
              }
              onDelete={handleClearLinkAccessFilter}
              data-cy="privacy-results"
              data-name={isPrivate ? "private" : "public"}
            />
          )}
          {attributes.map(({ value, attribute_id, type }) => {
            const label =
              type === "number" ? value?.toString().replace(", ", " - ") : String(value);
            return (
              <React.Fragment key={attribute_id}>
                {value && (
                  <Chip
                    className={styles.filter}
                    appearance="primaryV2"
                    preText={`${attributesStore.attributesNamesMap[attribute_id]}:`}
                    text={label || ""}
                    onDelete={handleClearCustom(attribute_id)}
                    data-name={label || ""}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  },
);
