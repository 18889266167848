import React from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { PageContainer, PageHeader } from "src/external-ts/components";
import { CREATE_PAGE } from "src/constants";
import { useStores } from "src/hooks";
import { ExtendedMediaModel } from "src/store";
import { MediaList } from "../media-list";
import { removeBackgroundMap } from "../remove-background-block/remove-background-block.constants";
import { RemoveBackgroundType } from "../remove-background-block/remove-background-block.types";
import { removeBackgroundImagesLimit } from "./remove-background.constants";

export const RemoveBackground: React.FC = observer(() => {
  const { type } = useParams<{ type: string }>();
  const navigate = useNavigate();
  const { modalsStore, localeStore } = useStores();

  const handleGoBack = () => navigate(-1);

  const handleBatchRemoveBackground = (medias: ExtendedMediaModel[]) => {
    modalsStore.open("BatchRemoveBackgroundModal", {
      type: type as RemoveBackgroundType,
      medias,
    });
  };

  if (!type || !removeBackgroundMap[type as RemoveBackgroundType]) {
    return <Navigate to={CREATE_PAGE.path} />;
  }

  return (
    <PageContainer>
      <PageHeader
        title={localeStore.t('create["remove-background"].title')}
        onBack={handleGoBack}
      />
      <MediaList
        subTitle={
          localeStore.t('create["remove-background"].subtitle.first') +
          " " +
          removeBackgroundImagesLimit +
          localeStore.t('create["remove-background"].subtitle.second')
        }
        actionBtnText={localeStore.t('create["remove-background"]["action-btn-text"]')}
        type="image"
        limit={removeBackgroundImagesLimit}
        onActionClick={handleBatchRemoveBackground}
        isRemoveBG
      />
    </PageContainer>
  );
});

export default RemoveBackground;
