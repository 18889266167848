import React from "react";
import { observer } from "mobx-react-lite";
import { Heading, Typography, Button } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import emptyImg from "src/external-ts/assets/images/media/media-list-placeholder/media-total-empty.png";
import styles from "./media-total-empty.module.css";

export const MediaTotalEmpty: React.FC = observer(() => {
  const { modalsStore, localeStore } = useStores();

  const handleUpload = () => modalsStore.open("UploadMediaModal", {});

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={emptyImg} alt="" />
      </div>

      <div className={styles.textContainer}>
        <Heading tag="h2" color="textSecondary">
          {localeStore.t('media["media-list-placeholder"]["media-total-empty"].title')}
        </Heading>
        <Typography className={styles.subtitle} size="small" color="textTertiary">
          {localeStore.t('media["media-list-placeholder"]["media-total-empty"].subtitle')}
        </Typography>
      </div>

      <Button className={styles.upload} onClick={handleUpload}>
        {localeStore.t('media["media-list-placeholder"]["media-total-empty"].buttons.upload')}
      </Button>
    </div>
  );
});
