import { dashboardApiMiddleware } from "src/api";
import {
  GeneratePostRequestData,
  GeneratePostResponseData,
  PostAIGenerateDescriptionsRequestData,
  PostAIGenerateDescriptionsResponseData,
  PostAIGenerateImageDescriptionsRequestData,
  PostAIGenerateImageDescriptionsResponseData,
  PutSaveAIResultResponseData,
  PostBatchAIRetouchRequestData,
  PostBatchAIRetouchResponseData,
  PostBatchRemoveBackgroundRequestData,
  PostBatchRemoveBackgroundResponseData,
  PostRemoveBackgroundRequestData,
  PostRemoveBackgroundResponseData,
  PostRetouchAISingleRequestData,
  PostRetouchAISingleResponseData,
  PostSingleDustAIRequestData,
  PostSingleDustAIResponseData,
  PostBatchDustAIRequestData,
  PostBatchDustAIResponseData,
  PostClassifyImageResponseData,
  PostClassifyImageRequestData,
  PostRemoveBackgroundRequestDataV4,
} from "./ai.interface";

export const postAIGenerateDescriptions = dashboardApiMiddleware<
  PostAIGenerateDescriptionsResponseData,
  PostAIGenerateDescriptionsRequestData
>()({
  method: "post",
  endpoint: "/api/product/generate_description",
});

export const postAIGenerateImageDescriptions = dashboardApiMiddleware<
  PostAIGenerateImageDescriptionsResponseData,
  PostAIGenerateImageDescriptionsRequestData
>()({
  method: "post",
  endpoint: "/api/product/image/generate_description",
  // isFormData: true,
});

export const generatePost = dashboardApiMiddleware<
  GeneratePostResponseData,
  GeneratePostRequestData
>()({
  method: "post",
  endpoint: "/api/product/gpt/post",
});

export const putSaveAIResult = dashboardApiMiddleware<PutSaveAIResultResponseData>()({
  method: "put",
  endpoint: "/api/content/ai/result/:id/save",
});

export const postRemoveBackground = dashboardApiMiddleware<
  PostRemoveBackgroundResponseData,
  PostRemoveBackgroundRequestData
>()({
  method: "post",
  // NOTE: isBoundReturn is always true to have dimensions
  endpoint: "/api/content/v3/single/background-removal?isBoundReturn=true",
  // isFormData: true,
});

export const postRemoveBackgroundV4 = dashboardApiMiddleware<
  PostRemoveBackgroundResponseData,
  PostRemoveBackgroundRequestDataV4
>()({
  method: "post",
  // NOTE: isBoundReturn is always true to have dimensions
  endpoint: "/api/content/v4/single/background-removal",
  // isFormData: true,
});

export const postBatchRemoveBackground = dashboardApiMiddleware<
  PostBatchRemoveBackgroundResponseData,
  PostBatchRemoveBackgroundRequestData
>()({
  method: "post",
  endpoint: "/api/content/v3/background-removal",
  isFormData: true,
});

export const postRetouchAISingle = dashboardApiMiddleware<
  PostRetouchAISingleResponseData,
  PostRetouchAISingleRequestData
>()({
  method: "post",
  endpoint: "/api/content/v3/retouch/ai/single",
  responseType: "text",
  // isFormData: true,
});

export const postBatchAIRetouch = dashboardApiMiddleware<
  PostBatchAIRetouchResponseData,
  PostBatchAIRetouchRequestData
>()({
  method: "post",
  endpoint: "/api/content/v3/retouch/ai",
});

export const postSingleRemoveDust = dashboardApiMiddleware<
  PostSingleDustAIResponseData,
  PostSingleDustAIRequestData
>()({
  method: "post",
  endpoint: "/api/content/v2/single/remove-dust",
});

export const postBatchRemoveDust = dashboardApiMiddleware<
  PostBatchDustAIResponseData,
  PostBatchDustAIRequestData
>()({
  method: "post",
  endpoint: "/api/content/v2/remove-dust",
});

export const postClassifyImage = dashboardApiMiddleware<
  PostClassifyImageResponseData,
  PostClassifyImageRequestData
>()({
  method: "post",
  endpoint: "/api/content/image/classifier",
  isFormData: true,
});
