import React from "react";
import { useDispatch, useSelector } from "react-redux";

import IntegrationCancelSyncModal from "../IntegrationCancelSyncModal";

import {
  toggleShopifyCancelSyncModal,
  stopSync,
  loadShopifyStatus,
} from "src/redux/reducers/shopify";
import { getShopifyStoreURL } from "src/common/helpers/integrations.helpers";

export const ShopifyCancelSyncModal = ({ isOpened }) => {
  const dispatch = useDispatch();
  const activeShopifyShop = useSelector(({ userReducer }) => userReducer.activeShopifyShop);
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = async () => {
    const storeURL = getShopifyStoreURL(activeShopifyShop);

    try {
      setLoading(true);
      await dispatch(stopSync(storeURL));
      await dispatch(loadShopifyStatus(storeURL));
      setLoading(false);
      dispatch(toggleShopifyCancelSyncModal());
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <IntegrationCancelSyncModal
      isOpened={isOpened}
      loading={loading}
      name="Shopify"
      onClose={() => dispatch(toggleShopifyCancelSyncModal())}
      onConfirm={handleConfirm}
    />
  );
};

export default ShopifyCancelSyncModal;
