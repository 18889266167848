import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { copyToClipboard, Input, Typography, Switch, Info } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { pushShareDataLayerEvent } from "src/utils";
import { CatalogModel, MediaCollectionModel, MediaModel } from "src/models";
import {
  ExtendedCatalogFullModel,
  ExtendedCatalogModel,
  ExtendedMediaModel,
  ExtendedProductModel,
} from "src/store";
import { ShareElement } from "../share-element";
import { ShareModalTypeKeys } from "../share-modal.types";
import { embedCodeWidth, embedCodeHeight } from "./embed-code-content.constants";
import { getEmbedCodeLink, getEmbedCodeToCopy } from "./embed-code-content.utils";

import styles from "./embed-code-content.module.css";

export type EmbedCodeContentProps = {
  type: ShareModalTypeKeys;
  data:
    | ExtendedMediaModel
    | MediaCollectionModel
    | ExtendedProductModel
    | ExtendedCatalogModel
    | ExtendedCatalogFullModel
    | CatalogModel;
};

export const EmbedCodeContent: React.FC<EmbedCodeContentProps> = observer(
  ({ type, data }: EmbedCodeContentProps) => {
    const isMedia = type === "media";
    const isMediaAssigned = isMedia && !!(data as MediaModel).sku_id;

    const isProduct = type === "product";

    const { localeStore } = useStores();

    const [frameWidth, setFrameWidth] = useState(embedCodeWidth);
    const [frameHeight, setFrameHeight] = useState(embedCodeHeight);
    const [showAttributes, setShowAttributes] = useState(true);
    const [associatedMedia, setAssociatedMedia] = useState("");

    const [linkToIframe, setLinkToIframe] = useState(() => {
      return getEmbedCodeLink(data.link.uuid, type, { associatedMedia, showAttributes });
    });

    const [codeToCopy, setCodeToCopy] = useState(() => {
      return getEmbedCodeToCopy(linkToIframe, frameWidth, frameHeight);
    });

    const canShowAttributes = isProduct || isMediaAssigned;
    const canShowAssociated = isMedia && isMediaAssigned;

    const onFrameWidthChange = (value: string) => {
      setFrameWidth(value);
      setCodeToCopy(getEmbedCodeToCopy(linkToIframe, value, frameHeight));
    };

    const onFrameHeightChange = (value: string) => {
      setFrameHeight(value);
      setCodeToCopy(getEmbedCodeToCopy(linkToIframe, frameWidth, value));
    };

    const handleShowAttributesChange = (checked: boolean) => {
      const newLink = getEmbedCodeLink(data.link.uuid, type, {
        associatedMedia,
        showAttributes: checked,
      });
      setLinkToIframe(newLink);
      setCodeToCopy(getEmbedCodeToCopy(newLink, frameWidth, frameHeight));
      setShowAttributes(checked);
    };

    const handleAssociatedMediaChange = (checked: boolean) => {
      if (!isMedia) return;
      const valueToSet = checked ? "" : (data as MediaModel).id.toString();

      const newLink = getEmbedCodeLink(data.link.uuid, type, {
        associatedMedia: valueToSet,
        showAttributes,
      });

      setLinkToIframe(newLink);
      setCodeToCopy(getEmbedCodeToCopy(newLink, frameWidth, frameHeight));
      setAssociatedMedia(valueToSet);
    };

    const handleCopy = async () => {
      try {
        await copyToClipboard(codeToCopy);
        pushShareDataLayerEvent("embedCode");
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <div className={styles.embedCodeContainer}>
        <div className={styles.iframeContainer}>
          <iframe
            src={linkToIframe}
            allow="camera *; microphone *; fullscreen;"
            sandbox="allow-scripts allow-same-origin"
          />
        </div>

        <div className={styles.embedCodeContent}>
          <Input
            affixClassName={styles.affix}
            appearance="primaryV2"
            label="Iframe width (px)"
            suffix="px"
            type="number"
            value={frameWidth}
            placeholder={localeStore.t(
              'components.business["share-modal"]["embed-code-content"]["frame-width-placeholder"]',
            )}
            onChange={onFrameWidthChange}
            disableError
          />
          <Input
            affixClassName={styles.affix}
            appearance="primaryV2"
            label="Iframe height (px)"
            suffix="px"
            type="number"
            value={frameHeight}
            placeholder={localeStore.t(
              'components.business["share-modal"]["embed-code-content"]["frame-height-placeholder"]',
            )}
            onChange={onFrameHeightChange}
            disableError
          />

          {canShowAttributes && (
            <div className={styles.switchWrapper}>
              <Typography className={styles.attributesTypography} size="extraSmall">
                {localeStore.t(
                  'components.business["share-modal"]["embed-code-content"]["attributes-switch"].title',
                )}
                <Info
                  className={styles.switchInfo}
                  appearance="secondary"
                  position="top"
                  size={16}
                  withAngle
                >
                  {localeStore.t(
                    'components.business["share-modal"]["embed-code-content"]["attributes-switch"].info',
                  )}
                </Info>
              </Typography>
              <Switch checked={showAttributes} onChange={handleShowAttributesChange} />
            </div>
          )}

          {canShowAssociated && (
            <div className={styles.switchWrapper}>
              <Typography size="extraSmall">
                {localeStore.t(
                  'components.business["share-modal"]["embed-code-content"]["associated-media-title"]',
                )}
              </Typography>
              <Switch checked={!associatedMedia} onChange={handleAssociatedMediaChange} />
            </div>
          )}

          <ShareElement
            className={styles.shareElementContainer}
            title={localeStore.t(
              'components.business["share-modal"]["embed-code-content"]["share-element-title"]',
            )}
            link={codeToCopy}
            onCopy={handleCopy}
          />
        </div>
      </div>
    );
  },
);

export default EmbedCodeContent;
