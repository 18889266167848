import React from "react";
import { clsx, Typography, FileQueueItem, RadioOption, RadioGroup } from "@gemlightbox/core-kit";

import { MediaType, MediaTypeKeys } from "src/models";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./media-list.module.css";

export type MediaListProps = {
  list: FileQueueItem[];
  onMediaTypeChange: (id: string, type: MediaTypeKeys) => void;
};

export const MediaList: React.FC<MediaListProps> = ({ list, onMediaTypeChange }) => {
  const handleChange = (id: string, option?: MediaTypeKeys) => {
    if (!option) return;
    onMediaTypeChange(id, option);
  };

  return (
    <div className={clsx(styles.container, globalStyles.addScrollStyles)}>
      {list.map(({ id, originalFile, data }) => {
        const { name } = originalFile;
        const { type } = data;

        return (
          <div key={name} className={styles.fileItem}>
            <Typography size="medium" tag="span" color="textSecondary">
              {originalFile.name}
            </Typography>
            {type !== MediaType.image && (
              <>
                <div className={styles.videoTypeLabel}>
                  <Typography size="extraSmall" color="textTertiary">
                    Video type
                  </Typography>
                </div>
                <RadioGroup
                  value={data.type as MediaTypeKeys}
                  onChange={(value) => handleChange(id, value as MediaTypeKeys)}
                  disableError
                >
                  <RadioOption value="video" data-cy="save-as-video">
                    <Typography size="medium" color="textSecondary">
                      Video
                    </Typography>
                  </RadioOption>
                  <RadioOption value="video360" data-cy="save-as-video360">
                    <Typography size="medium" color="textSecondary">
                      Interactive Video
                    </Typography>
                  </RadioOption>
                </RadioGroup>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
