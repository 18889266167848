import React, { useState } from "react";
import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import {
  copyToClipboard,
  Form,
  FormField,
  Button,
  SvgIcon,
  Loader,
  useCall,
  useDidMount,
  useWindowSize,
} from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import DangerBadge from "src/components/badges/DangerBadge";
import { ShareOptions } from "src/external-ts/components";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { postLinkPassword } from "src/api";
import { useStores } from "src/hooks";
import { CatalogModel, MediaCollectionModel } from "src/models";
import {
  ExtendedMediaModel,
  ExtendedProductModel,
  ExtendedCatalogModel,
  ExtendedCatalogFullModel,
} from "src/store";
import { ShareModalTypeKeys } from "../share-modal.types";
import { ShareElement } from "../share-element";
import { SuccessCopyBar } from "./success-copy-bar";
import { privateLinkValidation } from "./share-link-modal.utils";
import { FormValuesType } from "./share-link-content.types";
import { getSharedResourcePreviews, getSharedResourceLink } from "./share-link-modal.utils";

import { ReactComponent as LockSVG } from "src/external-ts/assets/images/locked-grey.svg";
import styles from "./share-link-content.module.css";

type ShareLinkContentProps = {
  type: ShareModalTypeKeys;
  data:
    | ExtendedMediaModel
    | MediaCollectionModel
    | ExtendedProductModel
    | ExtendedCatalogModel
    | ExtendedCatalogFullModel
    | CatalogModel;
  onPrivacyChange: (isPrivate: boolean) => void;
};

export const ShareLinkContent: React.FC<ShareLinkContentProps> = observer(
  ({ type, data, onPrivacyChange }) => {
    const { canPrivateLink } = useLimits();
    const { localeStore } = useStores();

    const { windowWidth } = useWindowSize();

    const [linkValue, setLinkValue] = useState("");
    const [copied, setCopied] = useState(false);
    const [copiedError, setCopiedError] = useState(false);
    const [formInner, setFormInner] = useState<FormikProps<any> | null>(null);

    const linkId = data.link.id;

    const formInitialValues: FormValuesType = { password: "" };

    const isMobile = windowWidth < BREAKPOINTS.mobileL;

    const { submit, submitting, onCallSuccess, onCallError } = useCall(
      postLinkPassword.setParams({ id: linkId }),
    );
    onCallError((error) => {
      setCopiedError(true);
      console.error(error);
    });
    onCallSuccess(() => {
      const isPrivate = !!formInner?.dirty;

      onPrivacyChange(isPrivate);
      handleCopyChange();
    });

    useDidMount(() => {
      const linkValue = getSharedResourceLink(type, data);
      setLinkValue(linkValue);
      handleCopyChange(linkValue);
    });

    const hidePasswordField =
      type === "mediaCollection" || (type === "catalogFullItem" && !!(data as any)?.isGenerated);

    const handleCopyChange = (currentLinkValue?: string) => {
      copyToClipboard(currentLinkValue || linkValue)
        .then(() => {
          setCopied(true);
        })
        .catch(() => setCopiedError(true));
    };

    const handleCopyPrivate = (values: FormValuesType) => {
      submit({
        data: { password: values.password },
      });
    };

    const handleCopy = () => {
      if (formInner?.dirty) {
        formInner.submitForm();
      } else {
        handleCopyChange();
      }
    };

    const handleLockClick = () => {
      formInner?.setFieldValue("password", "");
      submit({ data: { password: null } });
    };

    return (
      <div className={styles.container}>
        {submitting && <Loader position="absolute" withOverlay />}

        <ShareElement
          className={styles.shareElement}
          link={linkValue}
          onCopy={handleCopy}
          disabled={submitting}
        />

        {copied && (
          <SuccessCopyBar
            images={getSharedResourcePreviews(type, data)}
            link={linkValue}
            disablePreview={isMobile}
          />
        )}
        {copiedError && (
          <DangerBadge>
            {localeStore.t(
              'components.business["share-modal"]["share-link-content"]["copy-error"]',
            )}
          </DangerBadge>
        )}

        {!hidePasswordField && canPrivateLink && (
          <Form
            contentClassName={styles.formContent}
            initialValues={formInitialValues}
            innerRef={setFormInner}
            onSubmit={handleCopyPrivate}
            validate={privateLinkValidation}
          >
            <div className={styles.passwordWrapper}>
              <FormField
                appearance="primaryV2"
                type="password"
                name="password"
                label={localeStore.t(
                  'components.business["share-modal"]["share-link-content"]["password-field"].label',
                )}
                placeholder={localeStore.t(
                  'components.business["share-modal"]["share-link-content"]["password-field"].placeholder',
                )}
                bottomMessage={localeStore.t(
                  'components.business["share-modal"]["share-link-content"]["password-field"]["bottom-message"]',
                )}
                messageAlign="right"
                disableError
                data-cy="password-input"
              />
              {data.link.isPrivate && (
                <Button
                  className={styles.passwordBtn}
                  appearance="unset"
                  size="unset"
                  onClick={handleLockClick}
                  disabled={submitting}
                  data-cy="lock-button"
                >
                  <SvgIcon icon={LockSVG} />
                </Button>
              )}
            </div>
            <ShareOptions link={linkValue} />
          </Form>
        )}
      </div>
    );
  },
);
