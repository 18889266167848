import React, { memo } from "react";
import { clsx } from "@gemlightbox/core-kit";

import styles from "./auth-main.module.css";

export type AuthMainProps = {
  children: React.ReactNode;
  className?: string;
  fullScreen?: boolean;
};

export const AuthMain: React.FC<AuthMainProps> = memo(
  ({ children, className = "", fullScreen = false }: AuthMainProps) => {
    const authMainStyles = clsx(styles.authMainContainer, className, {
      [styles.fullScreen]: fullScreen,
    });

    return <div className={authMainStyles}>{children}</div>;
  },
);

export default AuthMain;
