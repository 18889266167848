import { MediaCollectionItemModel, MediaType, ProductImageModel } from "src/models";

export const getMediaPreviewFromProduct = (data: ProductImageModel | MediaCollectionItemModel) => {
  let result: string | undefined =
    data?.file && typeof data.file === "object" ? data.file.small : "";

  if (data.type === MediaType.blink && data?.arData) {
    result = data.arData?.cropFile ? data.arData.cropFile.small : "";
  }
  return result;
};
