import React from "react";
import { clamp, clsx } from "@gemlightbox/core-kit";

import "./index.scss";

const ProgressLine = ({ className = "", progress }) => (
  <div className={clsx("progress-line-placeholder", className)}>
    <div className="progress" style={{ width: `${clamp(progress, 0, 100)}%` }} />
  </div>
);

export default ProgressLine;
