import { makeAutoObservable, runInAction } from "mobx";
import { ApiRequest, ExtractApiResponse, isEqual, Nullable } from "@gemlightbox/core-kit";

import { getOrders, OrderModel } from "src/api";
import { OrdersSortType } from "./orders.store.types";

export class OrdersStore {
  private readonly _initialSortValues: OrdersSortType = {
    orderBy: '"createdAt"',
    sortBy: "DESC",
  };

  private _prevRequest: Nullable<ApiRequest<ExtractApiResponse<typeof getOrders>>> = null;

  private _loading = false;
  public get loading() {
    return this._loading;
  }

  private _totalOrdersAmount = 0;
  public get totalOrdersAmount() {
    return this._totalOrdersAmount;
  }

  private _orderList: OrderModel[] = [];
  public get orderList() {
    return this._orderList;
  }

  private _sortValues: OrdersSortType = this._initialSortValues;
  public get sortValues() {
    return this._sortValues;
  }

  constructor() {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async loadOrderList(paymentSystem: string, sortValues?: OrdersSortType) {
    if (sortValues && isEqual(sortValues, this._sortValues)) return;
    if (this.loading && this._prevRequest) this._prevRequest.abort();

    runInAction(() => {
      this._loading = true;
      if (sortValues) this.setSortValues(sortValues);
    });

    const request = getOrders.getRequest({
      queryParams: { ...this._sortValues, paymentSystem },
    });
    this._prevRequest = request;
    const { success, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      if (success) {
        this._orderList = success.orders;
        this._totalOrdersAmount = success.total_items;
      } else {
        this._totalOrdersAmount = 0;
        this._orderList = [];
      }

      this._prevRequest = null;
      this._loading = false;
    });
  }
  /* Requests ↑ */

  /* UI State ↓ */
  public setSortValues(sortValues: OrdersSortType) {
    this._sortValues = sortValues;
  }
  /* UI State  ↑  */
}
