import React from "react";

const AnimationModal = ({ component, isOpened, ...props }) => {
  const [isShown, setShown] = React.useState(false);
  const [isFakedOpened, setFakeOpened] = React.useState(false);

  React.useEffect(() => {
    if (isOpened) {
      setShown(true);
      setTimeout(() => setFakeOpened(true), 10);
    } else if (!isOpened) {
      setFakeOpened(false);
      setTimeout(() => setShown(false), 300);
    }
  }, [isOpened]);

  if (!isShown) return null;

  return (
    <>
      {typeof component === "function"
        ? component({ ...props, isOpened: isFakedOpened })
        : React.cloneElement(component, { ...props, isOpened: isFakedOpened })}
    </>
  );
};

export default AnimationModal;
