import { Vec2 } from "@gemlightbox/core-kit";

import goldChainTemplateImg from "src/external-ts/assets/images/ar-media/gold-chain.png";
import silverChainTemplateImg from "src/external-ts/assets/images/ar-media/silver-chain.png";
import roseGoldChainTemplateImg from "src/external-ts/assets/images/ar-media/rose-gold-chain.png";
import { ProductType } from "src/models";

export const canvasSize = new Vec2(2000, 2000);

export const chainTypes = [
  {
    label: "Yellow",
    gradient: "linear-gradient(96.46deg, #FADC90 4.9%, #C09831 93.01%)",
    src: goldChainTemplateImg,
  },
  {
    label: "White",
    gradient: "linear-gradient(100.83deg, #DBDBDB 3.19%, #9DA2A1 76.02%)",
    src: silverChainTemplateImg,
  },
  {
    label: "Rose",
    gradient: "linear-gradient(99.13deg, #F6E7F2 -5.94%, #E6AFAF 97.23%)",
    src: roseGoldChainTemplateImg,
  },
];

export const shouldRotateImgMap: string[] = [
  ProductType.rings,
  ProductType.watches,
  ProductType.bracelets,
];
