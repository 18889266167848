import React from "react";
import { observer } from "mobx-react-lite";
import { clsx, Heading } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { PendingInvitation } from "./pending-invitation";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./pending-invitations-list.module.css";

export const PendingInvitationsList: React.FC = observer(() => {
  const { subaccountsStore, localeStore } = useStores();

  if (!subaccountsStore.pendingSubaccounts.length) return null;

  return (
    <div className={styles.container}>
      <Heading tag="h4" color="textSecondary">
        {localeStore.t('settings.subaccounts["pending-invitations-list"].title')}
      </Heading>
      <div className={clsx(styles.list, globalStyles.addScrollStyles)}>
        {subaccountsStore.pendingSubaccounts.map((invitation) => (
          <PendingInvitation key={invitation.email} data={invitation} />
        ))}
      </div>
    </div>
  );
});
