import React from "react";
import { observer } from "mobx-react-lite";
import { objectKeys, Radio } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { AttributeType, AttributeTypeKeys } from "src/models";
import { WithTitle } from "src/containers/products/modals/create-attribute-sidebar/components";

import styles from "./attribute-types.module.css";

export interface AttributeTypesProps {
  value: AttributeTypeKeys;
  onChange: (value: AttributeTypeKeys) => void;
}

export const AttributeTypes: React.FC<AttributeTypesProps> = observer(({ value, onChange }) => {
  const { localeStore } = useStores();

  const getAttributeTypeLabelLocalized = (attributeType: AttributeTypeKeys) => {
    switch (attributeType) {
      case "text":
        return localeStore.t(
          'products.modals["create-attribute-sidebar"]["default-settings"]["attribute-types"].types.text',
        );
      case "number":
        return localeStore.t(
          'products.modals["create-attribute-sidebar"]["default-settings"]["attribute-types"].types.number',
        );
      case "multiselect":
      case "select":
        return localeStore.t(
          'products.modals["create-attribute-sidebar"]["default-settings"]["attribute-types"].types.select',
        );
      default:
        return "";
    }
  };

  const checkIfChecked = (attributeType: AttributeTypeKeys) => {
    if (attributeType === "select") {
      return value === "select" || value === "multiselect";
    }

    return attributeType === value;
  };

  return (
    <WithTitle
      title={localeStore.t(
        'products.modals["create-attribute-sidebar"]["default-settings"]["attribute-types"].title',
      )}
      contentClassName={styles.container}
    >
      {objectKeys(AttributeType)
        .slice(0, 3)
        .map((attributeType) => (
          <Radio
            key={attributeType}
            value={attributeType}
            label={getAttributeTypeLabelLocalized(attributeType)}
            checked={checkIfChecked(attributeType)}
            disableError
            onChange={() => onChange(attributeType)}
            data-name={attributeType}
          />
        ))}
    </WithTitle>
  );
});
