import React, { Fragment, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Tooltip, Button, SvgIcon, ActionsList, clsx, useBoolean } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as AngleDownSVG } from "src/external-ts/assets/images/arrow-down-grey.svg";
import styles from "./more-button-actions.module.css";

export type MoreButtonActionsProps = {
  children: React.ReactNode;
  className?: string;
  tooltipClassName?: string;
  actionsContainerClassName?: string;
};

export const MoreButtonActions: React.FC<MoreButtonActionsProps> = observer(
  ({ children, className = "", tooltipClassName = "", actionsContainerClassName }) => {
    const { localeStore } = useStores();

    const buttonRef = useRef<HTMLButtonElement>(null);

    const isOpenBoolean = useBoolean(false);

    return (
      <Fragment>
        <Button
          className={className}
          appearance="secondaryGhost"
          forwardRef={buttonRef}
          onClick={isOpenBoolean.trigger}
          data-cy="media-selection-panel-more-button"
        >
          {localeStore.t('media["media-selection-panel"].buttons.more')}
          <SvgIcon icon={AngleDownSVG} />
        </Button>
        <Tooltip
          className={clsx(styles.moreButtonTooltipContainer, tooltipClassName)}
          position="bottomRight"
          appearance="unset"
          target={buttonRef}
          onClose={isOpenBoolean.setValue}
          isOpen={isOpenBoolean.value}
          disableAutoPosChange
        >
          <ActionsList className={actionsContainerClassName} onOptionClick={isOpenBoolean.setFalsy}>
            {children}
          </ActionsList>
        </Tooltip>
      </Fragment>
    );
  },
);
