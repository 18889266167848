import React from "react";
import { memo } from "@gemlightbox/core-kit";

import { Logo } from "../logo";

import styles from "./mobile-header.module.css";

export const MobileHeader: React.FC = memo(() => {
  return (
    <div className={styles.container}>
      <Logo />
    </div>
  );
});
