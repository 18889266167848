import React from "react";
import { observer } from "mobx-react-lite";
import { Typography, clsx, globalStyles } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { AREmbedButton } from "./ar-embed-button";
import { DefaultEmbedButton } from "./default-embed-button";
import { Video360Variants, ARBlinkVariants } from "./embed-button-type.constants";

import styles from "./embed-button-type.module.css";

export type EmbedButtonTypeProps = {
  embedButtonType: string;
  isBlink: boolean;
  onChangeEmbedType: (type: string) => void;
};

export const EmbedButtonType: React.FC<EmbedButtonTypeProps> = observer(
  ({ embedButtonType, isBlink, onChangeEmbedType }: EmbedButtonTypeProps) => {
    const { localeStore } = useStores();

    return (
      <div className={styles.embedButtonTypeContainer}>
        <Typography size="small" color="textSecondary">
          {localeStore.t(
            'components.business["share-modal"]["embed-button-content"]["button-type"]',
          )}
        </Typography>
        <div
          className={clsx(styles.embedButtonButtons, globalStyles.hideScrollBar, {
            [styles.isBlink]: isBlink,
          })}
        >
          {!isBlink &&
            Object.entries(Video360Variants).map(([type, staticUrl]) => (
              <DefaultEmbedButton
                key={type}
                value={embedButtonType}
                type={type}
                staticUrl={staticUrl}
                onChange={onChangeEmbedType}
              />
            ))}
          {isBlink &&
            Object.entries(ARBlinkVariants).map(([type, staticUrl]) => (
              <AREmbedButton
                key={type}
                value={embedButtonType}
                type={type}
                staticUrl={staticUrl}
                onChange={onChangeEmbedType}
              />
            ))}
        </div>
      </div>
    );
  },
);

export default EmbedButtonType;
