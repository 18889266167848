import React from "react";
import { observer } from "mobx-react-lite";
import { Typography, Button, SvgIcon, Collapse } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as RecoverSVG } from "src/external-ts/assets/images/redo-arrow-grey.svg";
import styles from "./selection-panel.module.css";

export const SelectionPanel: React.FC = observer(() => {
  const { trashBinStore, modalsStore, localeStore } = useStores();

  const selectedAmount = trashBinStore.selectedItemsAmount;

  const handleDelete = () => {
    return modalsStore.open("DeleteTrashModal", { media: trashBinStore.selectedItemsList });
  };

  const handleRecover = () => {
    return modalsStore.open("RecoverMediaModal", { media: trashBinStore.selectedItemsList });
  };

  const handleSelectAll = () => trashBinStore.selectAllTrashBinList("selected");

  const handleUnSelectAll = () => trashBinStore.unselectAllTrashBinList("selected");

  return (
    <Collapse isOpen={trashBinStore.selectedItemsAmount > 0} opacityTransition>
      <div className={styles.selectionPanelWrapper}>
        <div className={styles.selectionPanelLeft}>
          <div className={styles.selectedAmount}>
            <Typography size="small600">
              {selectedAmount}&nbsp;
              {localeStore.t('["trash-bin"]["selection-panel"].media')}
            </Typography>
            &nbsp;
            <Typography size="small">
              {localeStore.t('["trash-bin"]["selection-panel"].selected')}
            </Typography>
          </div>
          <Button appearance="secondary" size="small" onClick={handleUnSelectAll}>
            {localeStore.t('["trash-bin"]["selection-panel"].buttons.unselect')}
          </Button>
          <Button appearance="primaryOutlined" size="small" onClick={handleSelectAll}>
            {localeStore.t('["trash-bin"]["selection-panel"].buttons["select-all"]')}
          </Button>
        </div>

        <div className={styles.selectionPanelRight}>
          <Button appearance="primaryGhost" onClick={handleRecover}>
            <SvgIcon icon={RecoverSVG} />
            {localeStore.t('["trash-bin"]["selection-panel"].buttons.recover')}
          </Button>
          <Button appearance="errorGhost" onClick={handleDelete}>
            <SvgIcon icon={DeleteSVG} />
            {localeStore.t('["trash-bin"]["selection-panel"].buttons.delete')}
          </Button>
        </div>
      </div>
    </Collapse>
  );
});
