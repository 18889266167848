import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useQuery, queryStringify } from "@gemlightbox/core-kit";

import {
  SUBACCOUNT_INVITE_FAILED_PAGE,
  SUBACCOUNT_LOGIN_FAILED_PAGE,
  AUTH_PAGE,
  LOGIN_PAGE,
  CREATE_PAGE,
  PRODUCTS_PAGE,
  PRODUCTS_IMPORT_PAGE,
  CAMERA_PAGE,
  MEDIA_PAGE,
  EDIT_MEDIA_PAGE,
  CATALOG_PAGE,
  CATALOG_DETAILS_PAGE,
  CREATE_MANUAL_CATALOG_PAGE,
  CREATE_AUTO_CATALOG_PAGE,
  ADD_PRODUCT_TO_CATALOG_PAGE,
  DEFAULT_PAGE,
  SETTINGS_PAGE,
  SHOPIFY_CONNECT_PAGE,
  SHOPIFY_PRICING_PAGE,
  LOGOUT_PAGE,
  QR_CODE_PAGE,
  NOT_FOUND_PAGE,
  AR_MEDIA_PAGE,
  AR_MEDIA_ID_PAGE,
  AR_MEDIA_ID_TYPE_PAGE,
  TRASH_BIN_PAGE,
  ACTIVATE_PAGE,
  LIFETIME_ACTIVATE_PAGE,
  PAYMENTS_PAGE,
  RAPNET_PAGE,
  ORDER_PAGE,
  PRODUCT_CREATE_PAGE,
  PRODUCT_EDIT_PAGE,
  PRODUCT_PAGE,
  SETUP_PAGE,
  REDIRECT_PAGE,
  CAMERA_PAGE_TEST,
  SETUP_DOWNLOAD_PAGE,
  CONFIRM_EMAIL_PAGE,
  MAINTENANCE_PAGE,
  WECHAT_PAGE,
} from "src/constants";
import { useStores } from "src/hooks";
import {
  ActivatePage,
  LifetimeActivatePage,
  ArMediaPage,
  AuthPage,
  CameraPage,
  CreatePage,
  CatalogList,
  CatalogDetailsList,
  CatalogAddProductsPage,
  CreateAutoCatalogPage,
  CreateManualCatalogPage,
  EditMediaPage,
  LogoutPage,
  QRCodePage,
  TrashBinPage,
  PaymentsPage,
  RapnetPage,
  Order,
  ProductCreatePage,
  ProductEditPage,
  ProductPage,
  WelcomePage,
  ConfirmEmailPage,
  WechatPageMemo,
} from "src/external-ts/pages";
import { MobileHeader, Navbar } from "src/external-ts/components";
import { DefaultRedirect } from "src/components/default-redirect";
import { ShopifyConnect } from "src/containers/shopify-connect";
import { SubaccountInviteFailed } from "src/external-ts/pages/subaccount-invite-failed/subaccount-invite-failed";
import { SubaccountLoginFailed } from "src/external-ts/pages/subaccount-login-failed/subaccount-login-failed";
import { Products } from "src/containers/products/products";
import { ProductsImport } from "src/containers/products-import";
import { Media } from "src/containers/media";
import { ShopifyPricing } from "src/containers/shopify-pricing";
import { Settings } from "src/containers/settings";
import { RedirectPage } from "src/external-ts/pages/welcome/redirect";
import { Maintenance } from "src/containers/maintenance";
export interface RouteConfig {
  path: string;
  auth: boolean;
  component: React.ComponentType<unknown>;
  showNavigation?: boolean;
  authRedirect?: boolean;
}

const NotFound = () => <Navigate to={DEFAULT_PAGE.path} />;

const AuthRoute: React.FC<RouteConfig> = (route: RouteConfig) => {
  const { query } = useQuery();
  const location = useLocation();
  const { userStore } = useStores();
  if (route.path === CAMERA_PAGE.path && !window.$platform.isApp) {
    return <Navigate to={SETUP_DOWNLOAD_PAGE.path + "?from=gemcam"} />;
  }

  if (window.$platform.isCompact && !userStore.isLogged) {
    userStore.parentPostMessageLogout();
    return <></>;
  }

  if (!userStore.isLogged) {
    return (
      <Navigate
        to={
          LOGIN_PAGE.path +
          queryStringify(route.authRedirect ? { ...query, r: location.pathname } : query)
        }
      />
    );
  }

  return <DefaultRoute {...route} />;
};

const DefaultRoute: React.FC<RouteConfig> = ({ component, ...rest }: RouteConfig) => {
  const Component = component;
  return (
    <>
      {rest.showNavigation && <MobileHeader />}
      <div className="content">
        {rest.showNavigation && <Navbar />}
        <Component />
      </div>
    </>
  );
};

export const RouteGuard: React.FC<RouteConfig> = (route: RouteConfig) => {
  const RouteComponent = route.auth ? AuthRoute : DefaultRoute;
  return <RouteComponent {...route} />;
};

export const routes: RouteConfig[] = [
  { ...SETUP_PAGE, component: WelcomePage, path: SETUP_PAGE.path + "/*" },
  { ...REDIRECT_PAGE, component: RedirectPage },
  { ...SHOPIFY_PRICING_PAGE, component: ShopifyPricing },
  { ...SHOPIFY_CONNECT_PAGE, component: ShopifyConnect },
  { ...SUBACCOUNT_INVITE_FAILED_PAGE, component: SubaccountInviteFailed },
  { ...SUBACCOUNT_LOGIN_FAILED_PAGE, component: SubaccountLoginFailed },
  { ...CREATE_PAGE, component: CreatePage, path: CREATE_PAGE.path + "/*" },
  { ...PRODUCTS_PAGE, component: Products },
  { ...PRODUCTS_IMPORT_PAGE, component: ProductsImport },
  { ...PRODUCT_CREATE_PAGE, component: ProductCreatePage },
  { ...PRODUCT_PAGE, component: ProductPage },
  { ...PRODUCT_EDIT_PAGE, component: ProductEditPage },
  { ...MEDIA_PAGE, component: Media },
  { ...EDIT_MEDIA_PAGE, component: EditMediaPage },
  { ...PAYMENTS_PAGE, component: PaymentsPage },
  { ...RAPNET_PAGE, component: RapnetPage },
  { ...ORDER_PAGE, component: Order },
  { ...AR_MEDIA_PAGE, component: ArMediaPage },
  { ...AR_MEDIA_ID_PAGE, component: ArMediaPage },
  { ...AR_MEDIA_ID_TYPE_PAGE, component: ArMediaPage },
  { ...CATALOG_PAGE, component: CatalogList },
  { ...CREATE_MANUAL_CATALOG_PAGE, component: CreateManualCatalogPage },
  { ...CREATE_AUTO_CATALOG_PAGE, component: CreateAutoCatalogPage },
  { ...CATALOG_DETAILS_PAGE, component: CatalogDetailsList },
  { ...TRASH_BIN_PAGE, component: TrashBinPage },
  { ...CAMERA_PAGE, component: CameraPage },
  { ...CAMERA_PAGE_TEST, component: CameraPage },
  { ...TRASH_BIN_PAGE, component: TrashBinPage },
  { ...ADD_PRODUCT_TO_CATALOG_PAGE, component: CatalogAddProductsPage },
  { ...SETTINGS_PAGE, component: Settings, path: SETTINGS_PAGE.path + "/*" },
  { ...QR_CODE_PAGE, component: QRCodePage },
  { ...WECHAT_PAGE, component: WechatPageMemo },
  { ...AUTH_PAGE, component: AuthPage, path: AUTH_PAGE.path + "/*" },
  { ...LOGOUT_PAGE, component: LogoutPage },
  { ...ACTIVATE_PAGE, component: ActivatePage },
  { ...CONFIRM_EMAIL_PAGE, component: ConfirmEmailPage },
  { ...LIFETIME_ACTIVATE_PAGE, component: LifetimeActivatePage },
  { ...MAINTENANCE_PAGE, component: Maintenance, path: MAINTENANCE_PAGE.path + "/*" },
  { ...DEFAULT_PAGE, component: DefaultRedirect },
  { ...NOT_FOUND_PAGE, component: NotFound },
];
