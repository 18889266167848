import { ObjectType } from "@gemlightbox/core-kit";

export type GoogleTagSupportedEvents =
  | "identify"
  | "gemhub:registration:started"
  | "gemhub:login"
  | "registration_completed"
  | "pageview"
  | "upgrade_completed"
  | "upgrade_failed"
  | "retouch_ai"
  | "retouch_lite"
  | "remove_bg_ai"
  | "upload_media"
  | "downgrade_completed"
  | "create_product"
  | "create_catalog"
  | "gemhub:product:ai_description:received"
  | "import_products"
  | "gemhub:product:share"
  | "gemhub:catalog:share"
  | "gemhub:media:share_single"
  | "gemhub:media:share_multiple"
  | "gemhub:widget:embedded_button:copied"
  | "gemhub:widget:embedded_code:copied"
  | "gemhub:widget:embedded_catalog:copied"
  | "gemhub:media:download"
  | "delete_media"
  | "export_products"
  | "gemhub:shopify:setup"
  | "subaccount_invitation"
  | "gemhub:gemcam:connected"
  | "gemhub:turntable:connected"
  | "update_user_attributes"
  | "update_context_properties"
  | "remove_context_properties"
  | "gemhub:app:launch"
  | "gemhub:logout"
  | "run_out_of_storage"
  | "click:download_gemhub_mac_app"
  | "click:download_gemhub_windows_app"
  | "click:gemcam-setup:open_in_browser"
  | "click:camera:capture:photo"
  | "click:camera:capture:video"
  | "gemhub:camera:capture:video:started"
  | "gemhub:camera:capture:video:stopped"
  | "click:camera:preview:save"
  | "gemhub:camera:media:preupload:started"
  | "gemhub:camera:media:preupload:success"
  | "gemhub:camera:media:preupload:error"
  | "gemhub:camera:media:cloud_save:started"
  | "gemhub:camera:media:cloud_save:success"
  | "gemhub:camera:media:cloud_save:error"
  | "gemhub:camera:media:cloud_save:canceled"
  | "click:camera:media:cloud_save:retry"
  | "gemhub:locale:text:download:error"
  | "gemhub:app:upgrade_required"
  | "gemhub:browser:unsupported_type"
  | "gemhub:browser:incompatible_version"
  | "gemhub:javascript_error:uncaught_exception"
  | "gemhub:javascript_error:unhandled_rejection"
  | "gemhub:react:render:fatal_error"
  | "gemhub:windows_error:uncaught_exception"
  | "gemhub:windows_error:core_dump"
  | "gemhub:camera:media:local_save:enable:success"
  | "gemhub:camera:media:local_save:enable:error"
  | "gemhub:camera:media:local_save:disable:success"
  | "gemhub:camera:media:local_save:set_config:success"
  | "gemhub:camera:media:local_save:save_media:success"
  | "gemhub:camera:media:local_save:save_media:error"
  | "gemhub:camera:media:local_save:config_dialog:open";

type PushDataLayerData = {
  event: GoogleTagSupportedEvents;
} & ObjectType;

export const pushDataLayerEvent = (data: PushDataLayerData) => {
  if (!data.event) return;

  const event_params = data.event_params || {};
  data.event_params_str = JSON.stringify(event_params);
  delete data.event_params;

  let layer = window.dataLayer;

  if (!Array.isArray(layer)) {
    window.dataLayer = [];
    layer = window.dataLayer;
  }

  layer.push(data);
};
