import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import { ProductParameterRequestModel } from "src/models";
import { FilterField } from "./filter-field/filter-field";
import { AttributeFieldType } from "../filters-sidebar.types";
import { getAttributesFields } from "../filters-sidebar.utils";

export interface CustomFiltersProps {
  attributesFilters: ProductParameterRequestModel[];
  onChange: (attribute: ProductParameterRequestModel) => void;
  renderNumberAsRange?: boolean;
}

export const CustomFilters: React.FC<CustomFiltersProps> = observer(
  ({ attributesFilters, onChange, renderNumberAsRange }) => {
    const { attributesStore } = useStores();
    const attributesFields = getAttributesFields(
      attributesFilters,
      attributesStore.customAttributes,
    );

    const handleChange = (field: AttributeFieldType) => (value: string | number) => {
      onChange({ attribute_id: field.id, name: field.name, type: field.type, value });
    };

    return (
      <>
        {attributesFields.map((field) => (
          <FilterField
            key={field.id}
            renderNumberAsRange={renderNumberAsRange}
            {...field}
            onChange={handleChange(field)}
          />
        ))}
      </>
    );
  },
);
