import React, { Fragment, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Form,
  Nullable,
  getCountryCallingCode,
  useFetch,
  queryStringify,
  useQuery,
} from "@gemlightbox/core-kit";

import { postCreateSubaccount, getIPInfo } from "src/api";
import { Logo } from "src/external-ts/components";
import { UserPayloadType } from "src/models";
import { pushDataLayerEvent } from "src/utils";
import { signUp } from "src/redux/reducers/user";
import { PromoCarousel } from "../promo-carousel";
import { AuthContainer } from "../auth-container";
import { AuthMain } from "../auth-main";
import { AuthAside } from "../auth-aside";
import { FirstStep } from "./first-step";
import { SecondStep } from "./second-step";
import { signupInitialForm } from "./signup.constants";
import { SignupFormType } from "./signup.types";
import { signupValidationStep1, signupValidationStep2 } from "./signup.utils";

import styles from "./signup.module.css";

export type SignupProps = {
  subaccountGUID: Nullable<string>;
  shopifyToken: Nullable<string>;
  subaccountEmail: Nullable<string>;
  isSubaccount: Nullable<boolean>;
};

export const SignupPage: React.FC<SignupProps> = observer(
  ({ subaccountGUID, shopifyToken, subaccountEmail, isSubaccount }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { query } = useQuery();

    const useIPInfoFetch = useFetch(getIPInfo);
    const ipInfo = useIPInfoFetch.payload;

    const subaccountMail = isSubaccount && subaccountGUID && subaccountEmail ? subaccountEmail : "";

    const initialValues: SignupFormType = useMemo(
      () => ({
        ...signupInitialForm,
        email: subaccountMail || "",
        phone: {
          country: ipInfo?.country ?? "US",
          phone: "",
        },
      }),
      [ipInfo?.country, subaccountMail],
    );

    const handleSubmit = async (values: SignupFormType) => {
      const countryCodee = getCountryCallingCode(values.phone.country as any).callingCode;
      const phone = `+${countryCodee}${values.phone.phone}`;

      const payload: UserPayloadType = {
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        email: values.email,
        fromResource: query.fromResource,
        phone,
      };

      if (values.company) payload.company = values.company;
      if (values.website) payload.website = values.website;

      if (isSubaccount && subaccountGUID) {
        payload.guid = subaccountGUID;
        const subaccountResult = await postCreateSubaccount
          .getRequest({
            data: payload,
          })
          .fetch();
        if (subaccountResult.status === "success") {
          navigate("/auth/login" + queryStringify({ ...query, email: values.email }));
          pushDataLayerEvent({
            event: "registration_completed",
            email: values.email,
          });
        }
      } else {
        await dispatch(signUp(payload, shopifyToken));
        navigate("/auth/email-sent-success" + queryStringify({ ...query, email: values.email }));
        pushDataLayerEvent({
          event: "registration_completed",
          email: values.email,
        });
      }
    };

    return (
      <AuthContainer>
        <AuthMain>
          <Logo />
          <section className={styles.section}>
            <Form
              contentClassName={styles.form}
              pages={2}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validate={[signupValidationStep1, signupValidationStep2]}
              validateOnChange={false}
              validateOnMount={false}
              validateOnBlur={false}
              enableReinitialize
            >
              {({ page }) => (
                <Fragment>
                  {page === 1 && <FirstStep subaccountMail={subaccountMail} />}
                  {page === 2 && <SecondStep />}
                </Fragment>
              )}
            </Form>
          </section>
        </AuthMain>
        <AuthAside>
          <PromoCarousel />
        </AuthAside>
      </AuthContainer>
    );
  },
);
