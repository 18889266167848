import { ExtendedProductModel } from "src/store/products";
import { AttributeModel } from "src/models";

export const getAttributeValue = (
  product: ExtendedProductModel,
  attributeName: AttributeModel["name"],
) => {
  const defaultAttr = product[attributeName as keyof ExtendedProductModel];
  if (defaultAttr) {
    return defaultAttr.toString();
  }
  const parameter = product.parameters.find(({ name }) => name === attributeName);
  if (parameter) {
    const { value } = parameter;
    if (value) return value;
  }

  return "";
};

export type GetAttributesValuesReturnType = {
  title: string;
  [name: string]: string;
};
export const getAttributesValues = (
  attributes: AttributeModel[],
  product: ExtendedProductModel,
): GetAttributesValuesReturnType => {
  return attributes.reduce(
    (accumulator, attribute) => ({
      ...accumulator,
      [attribute.name]: getAttributeValue(product, attribute.name),
    }),
    { title: getAttributeValue(product, "title") },
  );
};
