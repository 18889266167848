import React from "react";
import { observer } from "mobx-react-lite";
import { Image, Info, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import infoTooltipPNG from "src/external-ts/assets/images/auth/info-tooltip.png";
import styles from "./mobile-sign-in-info.module.css";

export const MobileSignInInfo: React.FC = observer(() => {
  const { localeStore } = useStores();

  return (
    <Info className={styles.infoContainer} position="right" size={16} withAngle>
      <div className={styles.infoContentWrapper}>
        <Typography className={styles.infoUpperText} size="small" color="textSecondary">
          {localeStore.t("auth.login['mobile-sign-in-info']['info-upper-text'].upper")}
          <Typography size="small600">
            {" "}
            {localeStore.t("auth.login['mobile-sign-in-info']['info-upper-text'].middle.bold")}{" "}
          </Typography>
          {localeStore.t("auth.login['mobile-sign-in-info']['info-upper-text'].middle.regular")}
          <br />
          {localeStore.t("auth.login['mobile-sign-in-info']['info-upper-text'].bottom")}
        </Typography>
        <Typography className={styles.infoBottomText} size="small600" color="textSecondary">
          {localeStore.t("auth.login['mobile-sign-in-info']['info-bottom-text']")}
        </Typography>
        <Image className={styles.infoImg} src={infoTooltipPNG} size={[272, 129]} />
      </div>
    </Info>
  );
});

export default MobileSignInInfo;
