import { clsx } from "@gemlightbox/core-kit";
import React from "react";

import "./styles.css";

export interface AlertProps {
  title?: string;
  subtitle?: string;
  icon?: React.ReactNode;
  className?: string;
  right?: React.ReactNode;
  iconClassName?: string;
  subTitleClassName?: string;
}

const Alert: React.FC<AlertProps> = ({
  title,
  subtitle,
  icon,
  className,
  right,
  iconClassName,
  subTitleClassName,
}) => {
  console.log("className..", className, clsx("gem-alert", className));
  return (
    <div className={clsx("gem-alert", className)}>
      <div className={clsx("gem-alert__icon", iconClassName)}>{icon}</div>
      <div className="gem-alert__content">
        {title && <div className="gem-alert__title">{title}</div>}
        {subtitle && (
          <div className={clsx("gem-alert__subtitle", subTitleClassName)}>{subtitle}</div>
        )}
      </div>
      <div className="gem-alert__right">{right}</div>
    </div>
  );
};

export default Alert;
