import React from "react";
import { CoreKitDate } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { SupportedSubscriptionInterval } from "../../subscriptions.types";

export const checkIfIsTrialPeriod = (subscription: any): boolean =>
  subscription?.trial_start && subscription?.trial_end;

export const getRawExpiredDate = (subscription: any): number =>
  subscription?.trial_end || subscription?.current_period_end;

export const getRawStartDate = (subscription: any): number =>
  subscription?.trial_start || subscription?.current_period_start;

export const getTrialEndDate = () => +new CoreKitDate().addDays(14);

export type UseBillingModalInput = {
  subscriptionType: SupportedSubscriptionInterval;
  extraCount: number;
};

export type UseBillingModalOutput = {
  title: React.ReactNode;
  subtitle: string;
  currentSubscription: "pro" | "starter" | "free";
  freeToMonthly: boolean;
  freeToYearly: boolean;
  monthlyToYearly: boolean;
  yearlyToMonthly: boolean;
  upgrading: boolean;
  downgrade: boolean;
};

export const useBilling = (options: UseBillingModalInput): UseBillingModalOutput => {
  const { subscriptionsStore, localeStore } = useStores();

  const {
    proSubscriptionInterval,
    starterSubscriptionInterval,
    isProSubscription,
    isStarterSubscription,
    isProLifetimeSubscription,
    subGbAddonsAmount,
  } = subscriptionsStore;

  const { subscriptionType, extraCount } = options;

  let title: React.ReactNode = localeStore.t(
    'settings.subscriptions.components["billing-modal"].title.default',
  );
  let subtitle = localeStore.t(
    'settings.subscriptions.components["billing-modal"].subtitle.default',
  );

  const currentSubscription = isProSubscription
    ? "pro"
    : isStarterSubscription
    ? "starter"
    : "free";

  const currentSubscriptionInterval =
    currentSubscription === "pro"
      ? proSubscriptionInterval
      : currentSubscription === "starter"
      ? starterSubscriptionInterval
      : undefined;

  const freeToMonthly = !currentSubscriptionInterval && subscriptionType === "monthly";
  const freeToYearly = !currentSubscriptionInterval && subscriptionType === "yearly";

  const monthlyToYearly =
    currentSubscriptionInterval === "monthly" && subscriptionType === "yearly";
  const yearlyToMonthly =
    currentSubscriptionInterval === "yearly" && subscriptionType === "monthly";

  const upgrading = currentSubscriptionInterval === subscriptionType;
  const downgrade = extraCount - subGbAddonsAmount < 0;

  if (freeToMonthly) {
    title = localeStore.t(
      'settings.subscriptions.components["billing-modal"].title["free-to-monthly"]',
    );
    subtitle = localeStore.t(
      'settings.subscriptions.components["billing-modal"].subtitle["free-to-monthly"]',
    );
  }

  if (freeToYearly || monthlyToYearly) {
    title = localeStore.t('settings.subscriptions.components["billing-modal"].title["to-yearly"]');
    subtitle = localeStore.t(
      'settings.subscriptions.components["billing-modal"].subtitle["to-yearly"]',
    );
  }

  if (yearlyToMonthly) {
    title = localeStore.t(
      'settings.subscriptions.components["billing-modal"].title["yearly-to-monthly"]',
    );
    subtitle = localeStore.t(
      'settings.subscriptions.components["billing-modal"].subtitle["yearly-to-monthly"]',
    );
  }

  if (upgrading) {
    if (isProLifetimeSubscription) {
      title = localeStore.t(
        'settings.subscriptions.components["billing-modal"].title["expand-cloud"]',
      );
      subtitle = "";
    } else {
      title = localeStore.t('settings.subscriptions.components["billing-modal"].title.upgrading');
      subtitle = localeStore.t(
        'settings.subscriptions.components["billing-modal"].subtitle.upgrading',
      );
    }
  }

  if (downgrade) {
    title = localeStore.t(
      'settings.subscriptions.components["billing-modal"].components["billing-downgrade"].heading',
    );
    subtitle = "";
  }

  return {
    title,
    subtitle,
    currentSubscription,
    freeToMonthly,
    freeToYearly,
    monthlyToYearly,
    yearlyToMonthly,
    upgrading,
    downgrade,
  };
};
