import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { clsx, useDidUpdate } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { FirstStep } from "./first-step";
import { SecondStep } from "./second-step";
import { ContentPlaceholder } from "./content-placeholder";
import { ResultContent } from "./result-content";
import { useLimits } from "../subscriptions/subscriptions.utils";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./dns-settings.module.css";

export const DNSSettings: React.FC = observer(() => {
  const navigate = useNavigate();

  const { canDNS } = useLimits();

  const { userStore } = useStores();
  const { dns_host, dns_resolved } = userStore.userMe || {};
  const hasDNS = dns_host && dns_host !== "null";
  const hasDNSResolved = hasDNS && dns_resolved;

  const [step, setStep] = useState(() => {
    if (hasDNS && !hasDNSResolved) return 2;
    return 0;
  });

  const [domain, setDomain] = useState(() => {
    if (dns_host === "null") return null;
    return dns_host;
  });

  useDidUpdate(
    () => {
      if (canDNS !== null && !canDNS) return;
    },
    [canDNS],
    true,
  );

  const handleProceedStep0 = () => setStep(1);

  const handleCancelStep1 = () => setStep(0);

  const handleProceedStep1 = (domain: string) => {
    setDomain(domain);
    setStep(2);
  };

  const handleCancelStep2 = () => setStep(1);

  const handleProceedStep2 = () => {
    setStep(0);
    setDomain(null);
  };

  return (
    <div className={clsx(styles.container, globalStyles.hideScrollBar)}>
      {hasDNSResolved && step === 0 && <ResultContent />}

      {!hasDNSResolved && step === 0 && <ContentPlaceholder onConfirm={handleProceedStep0} />}

      {!hasDNSResolved && step === 1 && (
        <FirstStep onCancel={handleCancelStep1} onProceed={handleProceedStep1} />
      )}

      {!hasDNSResolved && step === 2 && domain && (
        <SecondStep domain={domain} onCancel={handleCancelStep2} onProceed={handleProceedStep2} />
      )}
    </div>
  );
});
