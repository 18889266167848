import React from "react";
import { useDispatch } from "react-redux";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { toggleShopifySelectStoreModal, toggleShopifySetupModal } from "src/redux/reducers/shopify";

import { ReactComponent as AddStoreSVG } from "src/external-ts/assets/images/integrations/shopify/add-store.svg";

import styles from "./add-store-button.module.css";

export const AddStoreButton: React.FC = observer(() => {
  const dispatch = useDispatch();
  const { localeStore } = useStores();

  const handleAddStore = () => {
    dispatch(toggleShopifySelectStoreModal());
    dispatch(toggleShopifySetupModal());
  };

  return (
    <div className={styles.container} onClick={handleAddStore}>
      <Button appearance="primaryGhost">
        <SvgIcon icon={AddStoreSVG} size="small" />
        {localeStore.t('settings.integrations.modals["shopify-select-store"].buttons["add-store"]')}
      </Button>
    </div>
  );
});
