import React from "react";
import {
  Input,
  SelectGroup,
  SelectGroupOption,
  FilterCollapse,
  Range,
} from "@gemlightbox/core-kit";

import { AttributeType } from "src/models";
import { AttributeFieldType } from "src/external-ts/components";
import { defaultRange } from "../../filters-sidebar.constants";

import styles from "./filter-field.module.css";
import { useStores } from "src/hooks";

export type FilterFieldProps = AttributeFieldType & {
  onChange: (value: string | number) => void;
  renderNumberAsRange?: boolean;
};

export const FilterField: React.FC<FilterFieldProps> = ({
  type,
  value,
  name,
  values,
  onChange,
  renderNumberAsRange,
}) => {
  const { localeStore } = useStores();

  const { min, max } = defaultRange;

  const beforeInputRangeChange = (type: "from" | "to") => (inputValue: string) => {
    const _value = Number(inputValue);
    if (isNaN(_value)) return;

    let valuesString = "";
    if (type === "from") {
      const toValue = String(value).split(", ")[1];
      const maxValue = toValue ? toValue : max;
      valuesString = [_value, maxValue].join(", ");
    } else {
      const fromValue = String(value).split(", ")[0];
      const minValue = fromValue ? fromValue : min;
      valuesString = [minValue, _value].join(", ");
    }

    onChange(valuesString);
  };

  const beforeRangeChange = (rangeValue: number[]) => onChange(rangeValue.join(", "));

  const beforeInputChange = (value: string) => {
    const _value: string = value;

    if (type === "number") {
      if (isNaN(+_value)) return;
    }

    onChange(_value);
  };

  const beforeSelectChange = (value: string | string[]) => {
    if (!value) onChange("");
    else if (Array.isArray(value)) onChange(value.join(", "));
    else onChange(value);
  };

  const getSelectValue = (): string | string[] => {
    const _value = String(value);
    let result: string | string[] = _value;

    if (type === AttributeType.multiselect && _value) {
      result = _value.split(", ");
    }

    return result;
  };

  const getSelectDisplayValue = (option: string) => option;

  const checkIfRangeValueIsValid = (type: "from" | "to"): boolean => {
    const [from, to] = String(value).split(", ");
    const fromValue = Number(from);
    const toValue = Number(to);

    if (isNaN(fromValue) || isNaN(toValue)) return true;
    if (type === "from") return fromValue <= toValue;
    else return toValue >= fromValue;
  };

  const fromValue = String(value).split(", ")[0];
  const toValue = String(value).split(", ")[1];

  return (
    <FilterCollapse data-cy={`${name}-filter-collapse`} title={name}>
      {(type === AttributeType.text || (type === AttributeType.number && !renderNumberAsRange)) && (
        <Input
          data-cy={`${name}-filter-input`}
          value={value ?? ""}
          appearance="primaryV2"
          placeholder={
            localeStore.t('["product-attributes"]["attribute-field"]["enter-placeholder"]') + name
          }
          onChange={beforeInputChange}
        />
      )}
      {(type === AttributeType.select || type === AttributeType.multiselect) && (
        <SelectGroup
          data-cy={`select-${name}`}
          appearance="primaryV2"
          placeholder={
            localeStore.t('["product-attributes"]["attribute-field"]["select-placeholder"]') + name
          }
          searchValue={type === AttributeType.multiselect ? "" : getSelectValue().toString()}
          value={getSelectValue()}
          onChange={beforeSelectChange}
          getDisplayValue={getSelectDisplayValue}
          isMulti={type === AttributeType.multiselect}
          disableSearch
        >
          {(values ?? []).map((value) => (
            <SelectGroupOption data-cy={value} key={value} value={value}>
              {value}
            </SelectGroupOption>
          ))}
        </SelectGroup>
      )}
      {type === AttributeType.number && renderNumberAsRange && (
        <div className={styles.container}>
          <div className={styles.inputs}>
            <Input
              data-cy={`${name}-min-value`}
              appearance="primaryV2"
              value={fromValue}
              placeholder={min.toString()}
              onChange={beforeInputRangeChange("from")}
              error={!checkIfRangeValueIsValid("from")}
            />
            <Input
              data-cy={`${name}-max-value`}
              appearance="primaryV2"
              value={toValue}
              placeholder={max.toString()}
              onChange={beforeInputRangeChange("to")}
              error={!checkIfRangeValueIsValid("to")}
            />
          </div>
          <Range
            data-cy={`${name}-range`}
            appearance="secondary"
            min={min}
            max={max}
            value={[fromValue ? Number(fromValue) : min, toValue ? Number(toValue) : max]}
            onChange={beforeRangeChange}
          />
        </div>
      )}
    </FilterCollapse>
  );
};
