import React, { useRef } from "react";
import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import {
  Modal,
  Button,
  Form,
  SvgIcon,
  Heading,
  Typography,
  FormField,
  useCall,
} from "@gemlightbox/core-kit";

import { postRapnetInstall } from "src/api";
import { useStores } from "src/hooks";
import { validateForRequired } from "src/utils";
import { loginToRapnetModalForm } from "./login-to-rapnet-modal.constants";
import { LoginToRapnetModalProps, LoginToRapnetModalForm } from "./login-to-rapnet-modal.types";

import { ReactComponent as RapnetSVG } from "src/external-ts/assets/images/integrations/rapnet.svg";
import styles from "./login-to-rapnet-modal.module.css";

export const LoginToRapnetModal: React.FC<LoginToRapnetModalProps> = observer(
  ({ isOpen, options, setClose, onFinalClosed }: LoginToRapnetModalProps) => {
    const { onAddIntegration } = options;

    const { localeStore, userStore } = useStores();

    const formRef = useRef<FormikProps<LoginToRapnetModalForm> | null>(null);

    const postRapnetInstallCall = useCall(postRapnetInstall);
    postRapnetInstallCall.onCallSuccess(async () => {
      await userStore.loadUserMeSilently();
      setClose();
      onAddIntegration?.();
    });
    postRapnetInstallCall.onCallError((err) => {
      formRef.current?.setFieldError("accountId", err.formattedMessage);
    });

    const isSubmitting = postRapnetInstallCall.submitting || userStore.loadingMeSilently;

    const handleSubmit = (data: LoginToRapnetModalForm) => {
      postRapnetInstallCall.submit({
        data: { rapnet_id: data.accountId },
      });
    };

    return (
      <Modal
        contentClassName={styles.loginToRapnetModalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBackdropClose={isSubmitting}
        withCross={!isSubmitting}
        disableBorderRadius
        disablePadding
      >
        <Form
          contentClassName={styles.loginToRapnetFormContent}
          initialValues={loginToRapnetModalForm}
          innerRef={formRef}
          onSubmit={handleSubmit}
          disabled={isSubmitting}
        >
          <SvgIcon icon={RapnetSVG} size={110} />

          <Heading className={styles.title} tag="h2" color="textSecondary">
            {localeStore.t('integrations.rapnet["login-to-account"]')}
          </Heading>

          <Typography className={styles.description} size="small" color="textTertiary">
            {localeStore.t('integrations.rapnet["login-to-description"]')}
          </Typography>

          <FormField
            className={styles.accountIdContainer}
            type="text"
            name="accountId"
            appearance="primaryV2"
            placeholder={localeStore.t('integrations.rapnet["rapnet-acc-id-placeholder"]')}
            label={localeStore.t('integrations.rapnet["rapnet-acc-id"]')}
            validate={validateForRequired}
          />

          <div className={styles.buttonsWrapper}>
            <Button appearance="tertiaryOutlined" onClick={setClose} disabled={isSubmitting}>
              {localeStore.t("common.buttons.cancel")}
            </Button>
            <Button type="submit" loading={isSubmitting}>
              {localeStore.t('common.buttons["log-in"]')}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  },
);

export default LoginToRapnetModal;
