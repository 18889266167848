import React, { useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import {
  useDidMount,
  ExpandableSidebar,
  Button,
  ObjectType,
  MaybeArray,
  toArrayOptions,
  isEqualBy,
  useMediaBreakpoints,
  uniqId,
  arraysIntersection,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { AttributeModel } from "src/models";
import { sortAttributesByOrder } from "src/utils";
import { AvailableAttributes } from "./available-attributes";
import { SelectedColumns } from "./selected-columns";

import styles from "./manage-columns-sidebar.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export interface ManageColumnsSidebarProps {
  isOpen: boolean;
  options: ObjectType;
  setClose: VoidFunction;
  onFinalClosed?: VoidFunction;
}

export const ManageColumnsSidebar: React.FC<ManageColumnsSidebarProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const { attributesStore, localeStore, notificationStore } = useStores();

    const [initialColumns, setInitialColumns] = useState<AttributeModel[]>([]);
    const [columns, setColumns] = useState<AttributeModel[]>([]);
    const [editedIds, setEditedIds] = useState<number[]>([]);

    const { isMobileMedia } = useMediaBreakpoints();

    useDidMount(() => {
      const defaultAttrsCopy = toJS(attributesStore.defaultAttributes);
      const customAttrsCopy = toJS(attributesStore.customAttributes);

      const allowedDefaultAttributes = [
        "price",
        "description",
        "productType",
        "detailedTitle",
        "quantity",
      ];
      const filteredDefaultAttributes = arraysIntersection(
        allowedDefaultAttributes,
        defaultAttrsCopy,
        (allowed, defaultAttribute) => {
          if (defaultAttribute.name !== allowed) return;
          return defaultAttribute;
        },
      );
      const attributes = [...filteredDefaultAttributes, ...customAttrsCopy];
      const draggableAttributes = sortAttributesByOrder(attributes).map((attribute) => ({
        ...attribute,
        draggableId: uniqId(),
      }));

      // draggableId requires string
      setInitialColumns(draggableAttributes);
      setColumns(draggableAttributes);
    });

    const checkForChanges = (attributes: MaybeArray<AttributeModel>) => {
      let resultIds = [...editedIds];

      toArrayOptions(attributes).forEach((attribute) => {
        const initialAttribute = initialColumns.find(({ id }) => id === attribute.id);

        if (initialAttribute) {
          const equal = isEqualBy<AttributeModel, AttributeModel>(initialAttribute, attribute, [
            "order",
            "isHidden",
          ]);
          const editedIndex = resultIds.findIndex((id) => id === attribute.id);

          if (equal && editedIndex !== -1) {
            resultIds.splice(editedIndex, 1);
          }

          if (!equal) {
            const uniqueIds = new Set([...resultIds, attribute.id]);
            resultIds = [...uniqueIds];
          }
        }
      });

      setEditedIds(resultIds);
    };

    const toggleAttributeVisibility = (id: number) => {
      const result = columns.map((attribute: AttributeModel) => {
        if (attribute.id === id) {
          return {
            ...attribute,
            isHidden: !attribute.isHidden,
          };
        }

        return attribute;
      });

      checkForChanges(result);
      setColumns(result);
    };

    const handleReorder = ({ source, destination }: DropResult) => {
      if (!destination) return;

      let result = [...columns];
      const [sourceColumn] = result.splice(source.index, 1);

      result.splice(destination.index, 0, sourceColumn);

      result = result.map((attribute, index) => ({
        ...attribute,
        order: index,
      }));

      checkForChanges(result);
      setColumns(result);
    };

    const handleConfirm = async () => {
      const promises = [];
      for (const column of columns) {
        const data = attributesStore.getAttributeRequestPayload(column);
        promises.push(attributesStore.updateAttribute(column.id, data));
      }

      const errors = await Promise.all(promises);

      const error = errors.find((error) => error !== undefined);
      if (error) {
        notificationStore.open({
          title: localeStore.t('common.warnings["something-wrong"]'),
          message: error.formattedMessage,
          confirmText: localeStore.t("common.buttons.close"),
          cancelText: "",
          onlyConfirm: true,
        });
      }

      setClose();
    };

    return (
      <ExpandableSidebar
        sidebarHeaderClassName={styles.sidebarHeaderContainer}
        sidebarContentClassName={styles.sidebarContentContainer}
        sidebarFooterClassName={styles.sidebarFooterContainer}
        isOpen={isOpen}
        title={localeStore.t('products.modals["manage-column-sidebar"].title')}
        icon="cross"
        iconPos={isMobileMedia ? "right" : "outside"}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        footer={
          <div className={styles.footer}>
            <Button appearance="tertiaryOutlined" onClick={setClose}>
              {localeStore.t('products.modals["manage-column-sidebar"].buttons.cancel')}
            </Button>
            <Button
              onClick={handleConfirm}
              disabled={editedIds.length === 0}
              loading={attributesStore.loading}
            >
              {localeStore.t('products.modals["manage-column-sidebar"].buttons.apply')}
            </Button>
          </div>
        }
      >
        <div className={styles.sidebarContent}>
          <DragDropContext onDragEnd={handleReorder}>
            <SelectedColumns
              columns={columns.map((item) => {
                const displayName = [
                  "title",
                  "price",
                  "producttype",
                  "description",
                  "quantity",
                ].includes((item.displayName + "").replace(" ", "").toLocaleLowerCase())
                  ? localeStore.t(
                      `products["products-list"]["products-table"]["products-table-header"]["${item.displayName
                        ?.replace(" ", "")
                        .toLocaleLowerCase()}"]` as LocaleCodeTypes,
                    )
                  : item.displayName;
                return { ...item, displayName };
              })}
            />
          </DragDropContext>
          <AvailableAttributes
            attributes={columns.map((item) => {
              const displayName = [
                "title",
                "price",
                "producttype",
                "description",
                "quantity",
              ].includes((item.displayName + "").replace(" ", "").toLocaleLowerCase())
                ? localeStore.t(
                    `products["products-list"]["products-table"]["products-table-header"]["${item.displayName
                      ?.replace(" ", "")
                      .toLocaleLowerCase()}"]` as LocaleCodeTypes,
                  )
                : item.displayName;
              return { ...item, displayName };
            })}
            onVisibilityChange={toggleAttributeVisibility}
          />
        </div>
      </ExpandableSidebar>
    );
  },
);
