import React from "react";
import ActionsIcon from "src/components/ActionsIcon";
import optionsImg from "src/external-ts/assets/images/options-dots.svg";

const IntegrationStatusOptions = ({ options, onOptionClick }) => (
  <div className="integration-status-options">
    <ActionsIcon src={optionsImg} options={options} onOptionClick={onOptionClick} />
  </div>
);

export default IntegrationStatusOptions;
