import React from "react";
import { observer } from "mobx-react-lite";
import { Button, clsx, SvgIcon, Typography, useWindowSize } from "@gemlightbox/core-kit";

import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { useStores } from "src/hooks";
import { proFeatureTooltip } from "src/utils";
import IntegrationStatusLogo from "../IntegrationStatusLogo";

import styles from "./integration-status-setup-state.module.css";

const IntegrationStatusSetupState = observer(({ logo, title, description, link, onSetup }) => {
  const { canIntegrations } = useLimits();

  const { localeStore, tooltipStore } = useStores();

  const { windowWidth } = useWindowSize();

  const handlePointerEnter = (e) => {
    if (canIntegrations) return;
    proFeatureTooltip(e.currentTarget, {
      position: "top",
    });
  };

  const handlePointerLeave = () => {
    if (!canIntegrations) return;
    tooltipStore.close();
  };

  return (
    <>
      {windowWidth <= 840 && (
        <div className={styles.header}>
          <SvgIcon icon={logo} size={36} />
          <Typography size="medium600">{title}</Typography>
        </div>
      )}

      {windowWidth > 840 && <IntegrationStatusLogo src={logo} />}

      <div className={clsx("integration-status-data", styles.footer)}>
        {windowWidth > 840 && <div className="integration-status-data__title label"> {title} </div>}
        <Typography size="extraSmall" color="textTertiary" className={styles.description}>
          {description}{" "}
          <a href={link} target="_blank" rel="noreferrer">
            <Typography className={styles.learnButton} size="extraSmall500" color="textTertiary">
              {localeStore.t('common.buttons["learn-more"]')}
            </Typography>
          </a>
        </Typography>
        {windowWidth <= 840 && (
          <Button
            className={styles.setupButton}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
            onClick={onSetup}
            disabled={!canIntegrations}
          >
            {localeStore.t(
              'settings.integrations["integration-status-bar"]["setup-state"].buttons.setup',
            )}
          </Button>
        )}
      </div>

      {windowWidth > 840 && (
        <Button
          className={styles.setupButton}
          onPointerEnter={handlePointerEnter}
          onPointerLeave={handlePointerLeave}
          onClick={onSetup}
          disabled={!canIntegrations}
        >
          {localeStore.t(
            'settings.integrations["integration-status-bar"]["setup-state"].buttons.setup',
          )}
        </Button>
      )}
    </>
  );
});

export default IntegrationStatusSetupState;
