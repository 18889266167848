import React from "react";
import { Link } from "react-router-dom";

import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import arrowLeftImg from "src/common/images/arrow-left-black.svg";
import BtnWithLoader from "src/components/BtnWithLoader";
import { useStores } from "src/hooks";
import IntegrationStatusLogo from "../IntegrationStatusLogo";

const IntegrationStatusSettingsState = ({ logo, title, description, onStart }) => {
  const { localeStore } = useStores();

  return (
    <>
      <div className="text-wrapper">
        <div className="left">
          <Link to={getSettingsTab(SettingTabNames.integrations).item.path} className="back">
            <img src={arrowLeftImg} alt="" />
          </Link>

          <IntegrationStatusLogo src={logo} />
        </div>
        <div className="integration-status-data">
          <div className="integration-status-data__title label"> {title}</div>
          <div className="integration-status-data__description label hint"> {description}</div>
        </div>
      </div>
      <BtnWithLoader
        onClick={onStart}
        label={localeStore.t(
          'settings.integrations["integration-status-bar"]["integration-status-settings-state"]["start-button"]',
        )}
        className="primary-btn"
      />
    </>
  );
};

export default IntegrationStatusSettingsState;
