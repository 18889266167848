import { makeAutoObservable, runInAction } from "mobx";
import { ApiMiddlewareSettledQueryType } from "@gemlightbox/core-kit";

import {
  deleteShopifySubscription,
  getShopifySettings,
  getUserTagManagerInfoCallback,
  postShopifySubscription,
  putShopifySubscriptionAdditional,
} from "src/api";
import {
  DeleteShopifySubscriptionRequestData,
  GetShopifySettingsResponseData,
  PostShopifySubscriptionRequestData,
} from "src/api";
import { pushDataLayerEvent } from "src/utils";

export class ShopifyStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _isPaymentDisabled = false;
  public get isPaymentDisabled() {
    return this._isPaymentDisabled;
  }

  private _shopifySettings: GetShopifySettingsResponseData[] = [];
  public get shopifySettings() {
    return this._shopifySettings;
  }

  /* Requests ↓ */
  public async loadShopifySettings() {
    const { success } = await getShopifySettings.getRequest().fetch();

    runInAction(() => {
      if (success) this._shopifySettings = success;
      else this._shopifySettings = [];
    });
  }

  public async createShopifySubscription(
    data: PostShopifySubscriptionRequestData,
    prevAccountType?: string,
  ) {
    this._isPaymentDisabled = true;

    const { success, error } = await postShopifySubscription.getRequest({ data }).fetch();

    runInAction(() => {
      if (success) {
        this._handleShopifyPaymentSuccess(prevAccountType);
        window.location.replace(success.confirmationUrl);
      } else {
        this._handleShopifyPaymentError(error.formattedMessage, prevAccountType);
        this._isPaymentDisabled = false;
      }
    });
  }

  public async addAdditionShopifySubscription(
    queryParams: ApiMiddlewareSettledQueryType,
    prevAccountType?: string,
  ) {
    this._isPaymentDisabled = true;

    const { success, error } = await putShopifySubscriptionAdditional
      .getRequest({ queryParams })
      .fetch();

    runInAction(() => {
      if (success) {
        this._handleShopifyPaymentSuccess(prevAccountType);
        window.location.replace(success.confirmationUrl);
      } else {
        this._handleShopifyPaymentError(error.formattedMessage, prevAccountType);
        this._isPaymentDisabled = false;
      }
    });
  }

  public async removeShopifySubscription(data: DeleteShopifySubscriptionRequestData) {
    const { success } = await deleteShopifySubscription.getRequest({ data }).fetch();

    runInAction(() => {
      if (success) return success;
    });
  }

  /* Requests ↑ */

  /* Helpers ↓ */
  private _handleShopifyPaymentSuccess(prevAccountType?: string) {
    getUserTagManagerInfoCallback((response) => {
      pushDataLayerEvent({
        event: "upgrade_completed",
        event_params: {
          user_id: response.user_id,
          prev_account_type: prevAccountType ?? "couldn't get data",
          new_account_type: response?.account_type ?? "couldn't get data",
          new_paid_plan: response?.current_paid_plan ?? "couldn't get data",
          is_trial: response?.isTrial ?? "couldn't get data",
        },
      });
    });
  }

  private _handleShopifyPaymentError = (formattedMessage: string, prevAccountType?: string) => {
    getUserTagManagerInfoCallback((response) => {
      pushDataLayerEvent({
        event: "upgrade_failed",
        event_params: {
          user_id: response.user_id,
          prev_account_type: prevAccountType ?? "couldn't get data",
          new_account_type: response?.account_type ?? "couldn't get data",
          new_paid_plan: response?.current_paid_plan ?? "couldn't get data",
          is_trial: response?.isTrial ?? "couldn't get data",
        },
      });
    });
  };
  /* Helpers ↑*/
}
