import React, { memo } from "react";
import { Image, Loader, Nullable, SvgIcon } from "@gemlightbox/core-kit";

import { ExtendedMediaModel } from "src/store";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-thin-grey.svg";
import styles from "./batch-remove-background-modal.module.css";

export type MediaItemProps = {
  overrideImageSrc: Nullable<string>;
  loaded: boolean;
  media: ExtendedMediaModel;
};

export const MediaItem: React.FC<MediaItemProps> = memo(
  ({ overrideImageSrc, loaded = false, media }: MediaItemProps) => {
    const src = overrideImageSrc || media.thumbnail;
    return (
      <div className={styles.batchMediaItem}>
        {loaded && (
          <SvgIcon className={styles.batchMediaItemCheckmark} icon={CheckmarkSVG} size={40} />
        )}
        {!loaded && (
          <Loader className={styles.batchMediaItemLoader} type="circle-loader" size={24} />
        )}
        <Image
          key={src} // NOTE: pass src as key, so no flicks will be visible due to cache
          className={styles.imageContainer}
          src={src}
          objectFit="scaleDown"
          loading="eager"
        />
      </div>
    );
  },
);

export default MediaItem;
