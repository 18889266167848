import React from "react";
import { Radio, SvgIcon, clsx } from "@gemlightbox/core-kit";

import { AddStoreButton } from "./add-store-button";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./stores-list.module.css";
import checkmarkImg from "src/common/images/checkmark-primary.svg";
import { useSelector } from "react-redux";

const StoresList = ({ store, onChange, list }) => {
  const activeShopifyShop = useSelector(({ userReducer }) => userReducer.activeShopifyShop);
  return (
    <ul className={clsx(styles.container, globalStyles.addScrollStyles)}>
      {list.map(({ shop }) => (
        <li key={shop} className={styles.listItem}>
          <Radio
            value={shop}
            checked={store === shop}
            onChange={onChange}
            label={
              activeShopifyShop === shop ? (
                <>
                  <span style={{ marginRight: 10 }}>{shop}</span>
                  <SvgIcon size={16} icon={checkmarkImg} />
                </>
              ) : (
                shop
              )
            }
            disableError
          />
        </li>
      ))}

      <AddStoreButton />
    </ul>
  );
};

export default StoresList;
