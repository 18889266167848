import { dashboardApiMiddleware, GetRapnetProductsResponseData } from "src/api";
import {
  GetRapnetSettingsResponseData,
  GetRapnetStatusResponseData,
  PostRapnetInstallResponseData,
  PostRapnetInstallRequestData,
  PutRapnetStartSyncResponseData,
  PutRapnetStartSyncRequestData,
  PutRapnetRemoveResponseData,
} from "./rapnet.interface";

export const getRapnetSettings = dashboardApiMiddleware<GetRapnetSettingsResponseData>()({
  method: "get",
  endpoint: "/api/rapnet/2.0/settings",
});

export const getRapnetStatus = dashboardApiMiddleware<GetRapnetStatusResponseData>()({
  method: "get",
  endpoint: "/api/rapnet/2.0/status",
});

export const getRapnetProducts = dashboardApiMiddleware<GetRapnetProductsResponseData>()({
  method: "get",
  endpoint: "/api/rapnet/2.0/products",
});

export const postRapnetInstall = dashboardApiMiddleware<
  PostRapnetInstallResponseData,
  PostRapnetInstallRequestData
>()({
  method: "post",
  endpoint: "/api/rapnet/2.0/install",
});

export const putRapnetStartSync = dashboardApiMiddleware<
  PutRapnetStartSyncResponseData,
  PutRapnetStartSyncRequestData
>()({
  method: "put",
  endpoint: "/api/rapnet/2.0/sync",
});

export const putRapnetRemove = dashboardApiMiddleware<PutRapnetRemoveResponseData>()({
  method: "put",
  endpoint: "/api/rapnet/2.0/remove",
});

export const putRapnetReset = dashboardApiMiddleware()({
  method: "put",
  endpoint: "/api/rapnet/2.0/reset",
});
