import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "src/hooks";
import { clsx, RefModel } from "@gemlightbox/core-kit";

import { ExtendedTrashBinItemModel } from "src/models";
import { MediaItem } from "./media-item";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./media-list.module.css";

export type MediaListProps = {
  containerRef: RefModel<HTMLDivElement>;
};

export const MediaList: React.FC<MediaListProps> = observer(() => {
  const { trashBinStore } = useStores();

  const handleSelect = (list: ExtendedTrashBinItemModel) =>
    trashBinStore.toggleTrashBinList(list, "selected");

  const canSelectOnClick = trashBinStore.selectedItemsAmount > 0;

  return (
    <div className={clsx(styles.container, globalStyles.addScrollStyles)}>
      <div className={styles.wrapper}>
        {trashBinStore.trashBinList.map((data) => (
          <MediaItem
            key={data._id}
            item={data}
            selectOnClick={canSelectOnClick}
            onSelect={handleSelect}
          />
        ))}
      </div>
    </div>
  );
});
