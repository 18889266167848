import React, { memo } from "react";
import { clsx } from "@gemlightbox/core-kit";

import {
  Video360Variants,
  ARBlinkVariants,
} from "../embed-button-type/embed-button-type.constants";

import styles from "./embed-button-previw.module.css";

export type EmbedButtonPreviewProps = {
  embedButtonType: string;
  buttonSize: string;
  isBlink: boolean;
};

export const EmbedButtonPreview: React.FC<EmbedButtonPreviewProps> = memo(
  ({ embedButtonType, buttonSize, isBlink }: EmbedButtonPreviewProps) => {
    const previewStaticUrl =
      (Video360Variants as any)[embedButtonType] || (ARBlinkVariants as any)[embedButtonType] || "";

    if (!previewStaticUrl && embedButtonType !== "ar-type-0") return null;

    return (
      <div className={styles.embedButtonPreview}>
        <button
          className={clsx(styles.previewButton, { [styles.isBlink]: isBlink })}
          type="button"
          style={{
            width: buttonSize ? buttonSize + "px" : undefined,
            backgroundImage: previewStaticUrl ? `url(${previewStaticUrl})` : undefined,
          }}
        >
          {isBlink && "Try me on!"}
        </button>
      </div>
    );
  },
);

export default EmbedButtonPreview;
