import React from "react";
import { observer } from "mobx-react-lite";
import { FilterCollapse } from "@gemlightbox/core-kit";

import { UserSelect } from "src/external-ts/components/index";
import { useStores } from "src/hooks";

export interface CreatedByUserFilterProps {
  userId: string | number;
  onUserFilter: (value: string | number) => void;
}

export const CreatedByUserFilter: React.FC<CreatedByUserFilterProps> = observer(
  ({ onUserFilter, userId }) => {
    const { localeStore } = useStores();

    return (
      <FilterCollapse
        data-cy="created-by-filter-collapse"
        title={localeStore.t(
          'media["media-control-panel"].filters["filters-dialog"]["created-by"].title',
        )}
      >
        <UserSelect appearance="primaryV2" value={userId} onChange={onUserFilter} />
      </FilterCollapse>
    );
  },
);
