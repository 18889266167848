import React, { useState } from "react";
import {
  memo,
  ValuesList,
  ValuesListProps,
  SvgIcon,
  clsx,
  Collapse,
  Button,
} from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";
import { ReactComponent as ArrowSVG } from "src/external-ts/assets/images/arrow-down-grey.svg";
import styles from "./product-details.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { productDefaultTypesNames } from "src/models";

export type ProductDetailsListProps = ValuesListProps;

export const ProductDetailsList: React.FC<ProductDetailsListProps> = memo(({ list }) => {
  const { localeStore } = useStores();

  const [isOpened, setIsOpened] = useState(true);

  const handleOpen = () => setIsOpened(!isOpened);

  return (
    <div className={styles.productDetailsContainer}>
      <Button className={styles.openBtn} appearance="unset" size="unset" onClick={handleOpen}>
        {localeStore.t('["product-root"].details')}
        <SvgIcon className={clsx(styles.icon, { [styles.active]: isOpened })} icon={ArrowSVG} />
      </Button>
      <Collapse openDuration={150} hideDuration={150} isOpen={isOpened}>
        <ValuesList
          containerClassName={styles.productDetailsListContainer}
          detailsContainerClassName={styles.productDetailsItem}
          list={list.map((item) => {
            let value = item.value;
            if (item.title === "Product Type") {
              value = localeStore.t(
                `create["augmented-reality-item"]["ar-types"].${
                  productDefaultTypesNames[(value + "").toLocaleLowerCase()]
                }` as LocaleCodeTypes,
                value + "",
              );
            }
            const title = ["title", "price", "producttype", "description", "quantity"].includes(
              (item.title + "").replace(" ", "").toLocaleLowerCase(),
            )
              ? localeStore.t(
                  `products["products-list"]["products-table"]["products-table-header"]["${(
                    item.title + ""
                  )
                    .replace(" ", "")
                    .toLocaleLowerCase()}"]` as LocaleCodeTypes,
                )
              : item.title;
            return { ...item, value, title };
          })}
        />
      </Collapse>
    </div>
  );
});

export default ProductDetailsList;
