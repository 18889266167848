import React from "react";
import { observer } from "mobx-react-lite";
import {
  Heading,
  Typography,
  Modal,
  Form,
  FormField,
  Button,
  ObjectType,
  useCall,
  SvgIcon,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { putUpdateUser } from "src/api";
import { initialValues, FORM_ID } from "./change-password-modal.constants";
import { ChangePasswordFormType } from "./change-password-modal.types";
import { validate } from "./change-password-modal.utils";

import { ReactComponent as CheckmarkIcon } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";
import styles from "./change-password-modal.module.css";

export interface ChangePasswordModalProps {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed?: VoidFunction;
  options: ObjectType;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const { notificationStore, userStore, localeStore } = useStores();

    const { submit, onCallSuccess, onCallError } = useCall(putUpdateUser);

    const formInitialValues: ChangePasswordFormType = {
      ...initialValues,
      accountAccess: userStore.userMe?.isAllowedAdminAccess ?? true,
    };

    onCallSuccess(() => {
      setClose();
      notificationStore.open({
        title: localeStore.t('settings["change-password-modal"].success.title'),
        confirmText: localeStore.t('settings["change-password-modal"].success["ok-text"]'),
        cancelText: "",
        onlyConfirm: true,
        confirmAppearance: "primary",
        onFinal: () => {
          userStore.logout();
        },
        icon: (
          <div className={styles.checkmarkContainer}>
            <SvgIcon icon={CheckmarkIcon} color="secondary" size={44} />
          </div>
        ),
      });
    });

    onCallError(() => {
      notificationStore.open({
        title: localeStore.t('settings["change-password-modal"]["error-title"]'),
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: "",
        confirmAppearance: "primary",
        onlyConfirm: true,
      });
    });

    const handleSubmit = (values: ChangePasswordFormType) => {
      const formData = new FormData();
      formData.append("oldPassword", values.oldPassword);
      formData.append("newPassword", values.newPassword);
      formData.append("isAllowedAdminAccess", String(values.accountAccess));
      submit({ data: formData });
    };

    return (
      <Modal
        scrollWrapperClassName={styles.modalContainer}
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
        withCross
      >
        <Heading className={styles.title} tag="h2" color="textSecondary">
          {localeStore.t('settings["change-password-modal"].title')}
        </Heading>
        <Typography className={styles.subtitle} size="extraSmall" color="textTertiary">
          {localeStore.t('settings["change-password-modal"].subtitle')}
        </Typography>
        <Form<ChangePasswordFormType>
          contentClassName={styles.fields}
          formId={FORM_ID}
          initialValues={formInitialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          <FormField
            inputWrapperClassName={styles.field}
            appearance="primaryV2"
            type="text"
            name="oldPassword"
            label={localeStore.t('settings["change-password-modal"].fields["old-password"].label')}
            placeholder={localeStore.t(
              'settings["change-password-modal"].fields["old-password"].placeholder',
            )}
            data-cy="old-password-field"
          />
          <FormField
            inputWrapperClassName={styles.field}
            appearance="primaryV2"
            type="text"
            name="newPassword"
            label={localeStore.t('settings["change-password-modal"].fields["new-password"].label')}
            placeholder={localeStore.t(
              'settings["change-password-modal"].fields["new-password"].placeholder',
            )}
            data-cy="new-password-field"
          />
          <FormField
            inputWrapperClassName={styles.field}
            appearance="primaryV2"
            type="text"
            name="confirmPassword"
            label={localeStore.t(
              'settings["change-password-modal"].fields["confirm-password"].label',
            )}
            placeholder={localeStore.t(
              'settings["change-password-modal"].fields["confirm-password"].placeholder',
            )}
            data-cy="confirm-new-password-field"
          />

          <div className={styles.checkboxContainer}>
            <FormField
              type="checkbox"
              className={styles.checkbox}
              name="accountAccess"
              label={localeStore.t('settings["change-password-modal"].fields.access.label')}
              disableError
            />
            <Typography
              className={styles.checkboxDescription}
              size="extraSmall"
              color="textSecondary"
            >
              {localeStore.t('settings["change-password-modal"].agreement.top')}
              <br />
              {localeStore.t('settings["change-password-modal"].agreement.bottom')}
            </Typography>
          </div>
        </Form>
        <div className={styles.buttons}>
          <Button
            appearance="tertiaryOutlined"
            onClick={setClose}
            data-cy="change-cancel-submit-button"
          >
            {localeStore.t('settings["change-password-modal"].buttons.cancel')}
          </Button>
          <Button type="submit" form={FORM_ID} data-cy="change-password-submit-button">
            {localeStore.t('settings["change-password-modal"].buttons.change')}
          </Button>
        </div>
      </Modal>
    );
  },
);

export default ChangePasswordModal;
