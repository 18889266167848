import React from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, Range, Button, Typography, useRerender } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { editMediaStore } from "../../../edit-media.store";
import { GroupBlock } from "../../group-block";

import { ReactComponent as EraserIcon } from "src/external-ts/assets/images/edit-media/eraser.svg";
import styles from "./eraser.module.css";

export const Eraser: React.FC = observer(() => {
  const rerender = useRerender();
  const { localeStore } = useStores();
  const { renderer, mainImageComponent } = editMediaStore;

  const handleEraseBrushClick = () => {
    const { eraseTool, handSelectionTool } = renderer.tools;
    const isEraser = renderer.eventsManager.checkForTool(eraseTool);

    !renderer.hasSelectedComponent && renderer.selectComponent(mainImageComponent);
    renderer.eventsManager.assignTool(isEraser ? handSelectionTool : eraseTool);
    rerender();
  };

  const handleEraseBrushSize = (value: number) => {
    renderer.tools.eraseTool.brushSize = value;
    rerender();
  };

  return (
    <GroupBlock className={styles.eraseBlockGroup}>
      <Typography size="small600" color="textSecondary">
        {localeStore.t('["edit-media"].inspector.adjustments.eraser.title')}
      </Typography>
      <div className={styles.buttons}>
        <Button
          appearance="tertiaryOutlined"
          onClick={handleEraseBrushClick}
          data-cy="eraser-button"
        >
          <SvgIcon icon={EraserIcon} />
          {localeStore.t('["edit-media"].inspector.adjustments.eraser["erase-btn"]')}
        </Button>
      </div>
      <Range
        label={localeStore.t('["edit-media"].inspector.adjustments.eraser.range')}
        min={1}
        max={1000}
        step={1}
        value={renderer.tools?.eraseTool?.brushSize ?? 1}
        onChange={handleEraseBrushSize}
        disableError
        showRangeValue
      />
    </GroupBlock>
  );
});

export default Eraser;
