import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, Image, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as IntegrationSVG } from "src/external-ts/assets/images/integrations/integration-grey.svg";
import ShopifyLogoImg from "src/containers/settings/integrations/images/shopify-logo.svg";
import styles from "./integration-button.module.css";

export const IntegrationButton: React.FC = observer(() => {
  const navigate = useNavigate();

  const { localeStore } = useStores();

  const handleClick = () => navigate("/settings/integrations");

  return (
    <Button
      className={styles.integrateButton}
      appearance="secondaryOutlined"
      onClick={handleClick}
      data-cy="integration-button"
    >
      <SvgIcon icon={IntegrationSVG} />
      <Typography size="medium">
        {localeStore.t('products["products-control-panel"]["integration-button"].label')}
      </Typography>
      <Image src={ShopifyLogoImg} size={32} />
    </Button>
  );
});
