import React, { useRef, Fragment } from "react";
import { observer } from "mobx-react-lite";
import {
  Input,
  SvgIcon,
  Tooltip,
  Range,
  round,
  useTargetListener,
  useOutsideClick,
  useDidMount,
  useBoolean,
  clsx,
  Webgl2New,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";

import { ReactComponent as ArrowSVG } from "src/external-ts/assets/images/arrow-right-grey.svg";
import styles from "./scale-select.module.css";

export type ScaleSelectProps = {
  renderer: Webgl2New.Webgl2dRenderer;
};

export const ScaleSelect: React.FC<ScaleSelectProps> = observer(({ renderer }) => {
  const { localeStore } = useStores();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const rangeWrapperRef = useRef<HTMLDivElement | null>(null);

  const isOpenBoolean = useBoolean(false);

  const { addTargetListener, removeTargetListener } = useTargetListener();

  const normalizedScale = renderer.camera.scale;
  const percentageScale = round(normalizedScale * 100, 2) + "%";

  const handleTrigger = (e: React.MouseEvent | MouseEvent) => {
    e.stopPropagation();
    if (rangeWrapperRef.current?.contains(e.target as any)) return;
    isOpenBoolean.trigger();
  };

  const handleRangeChange = (value: number) => {
    renderer.camera.setScale(value);
    renderer.camera.centerViewportOnGlobalPosition(renderer.canvasSize.getHalf());
  };

  useDidMount(() => {
    addTargetListener("click", wrapperRef.current, handleTrigger);
    return removeTargetListener;
  });

  useOutsideClick([wrapperRef, tooltipRef], isOpenBoolean.setFalsy, {
    disable: !isOpenBoolean.value,
  });

  const arrowBtnStyles = clsx(styles.arrowBtn, { [styles.isOpen]: isOpenBoolean.value });

  return (
    <Fragment>
      <Input
        className={styles.scaleContainer}
        inputWrapperClassName={styles.scaleInputWrapper}
        appearance="primaryV2"
        childrenPos="before-input"
        value={percentageScale}
        forwardWrapperRef={wrapperRef}
        aria-haspopup={isOpenBoolean.value}
        disableError
        readOnly
        data-cy="scale-select"
      >
        <SvgIcon className={arrowBtnStyles} icon={ArrowSVG} onClick={handleTrigger} />
      </Input>
      <Tooltip
        className={styles.scaleRangeWrapper}
        position="bottomLeft"
        offsetY={4}
        target={wrapperRef}
        forwardRef={tooltipRef}
        onClose={isOpenBoolean.setValue}
        isOpen={isOpenBoolean.value}
      >
        <Range
          label={localeStore.t('["ar-media"]["media-editor"]["control-buttons"]["scale-select"]')}
          min={Webgl2New.CAMERA_MIN_SCALE}
          max={Webgl2New.CAMERA_MAX_SCALE}
          step={Webgl2New.CAMERA_SCALE_STEP}
          roundValue={2}
          value={normalizedScale}
          onChange={handleRangeChange}
          messageFormatter={percentageScale}
          disableError
          showRangeValue
        />
      </Tooltip>
    </Fragment>
  );
});

export default ScaleSelect;
