import { MediaModel, MediaType } from "src/models";

export const getMediaPreview = (data: MediaModel) => {
  let result: string | undefined = data.thumbnail;

  if (data.type === MediaType.blink && data?.cropFile) {
    result = data.cropFile?.small;
  }

  return result;
};
