import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import { CustomPlanBadge } from "../custom-plan-badge";
import { FreePlanBadge } from "../free-plan-badge";
import { ProPlanBadge } from "../pro-plan-badge";
import { StarterPlanBadge } from "../starter-plan-badge";

export type YourSubscriptionPlanBadgeProps = {
  className?: string;
  split?: boolean;
  hideInterval?: boolean;
  secondaryTextColor?: boolean;
};

export const YourSubscriptionPlanBadge: React.FC<YourSubscriptionPlanBadgeProps> = observer(
  ({
    className = "",
    split = false,
    hideInterval = false,
    secondaryTextColor,
  }: YourSubscriptionPlanBadgeProps) => {
    const { subscriptionsStore } = useStores();

    const { subscription, isCustomSubscription, isProSubscription, isStarterSubscription } =
      subscriptionsStore;

    if (isCustomSubscription) return <CustomPlanBadge className={className} />;

    if (isProSubscription) {
      return (
        <ProPlanBadge
          className={className}
          forceName={subscription?.product?.name}
          split={split}
          hideInterval={hideInterval}
          secondaryTextColor={secondaryTextColor}
        />
      );
    }

    if (isStarterSubscription) {
      return (
        <StarterPlanBadge
          className={className}
          split={split}
          hideInterval={hideInterval}
          secondaryTextColor={secondaryTextColor}
        />
      );
    }

    return (
      <FreePlanBadge className={className} split={split} secondaryTextColor={secondaryTextColor} />
    );
  },
);

export default YourSubscriptionPlanBadge;
