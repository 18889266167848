import { createBrowserHistory } from "history";
import { requestMiddleware } from "redux-request-async";
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import createFilter from "redux-persist-transform-filter";

import { config } from "src/config/environment";
import unauthorizedMiddleware from "src/redux/middlewares/unauhorized.middleware";
import forbiddenMiddleware from "src/redux/middlewares/forbidden.middleware";
import { reducers } from "./reducers";
import requestHooks from "./request.hooks";

export const history = createBrowserHistory();

const requestHandler = requestMiddleware({
  apiUrl: config.apiUrl,
  pathToToken: "userReducer.token",
  hooks: requestHooks,
});
const middleware = [
  requestHandler,
  routerMiddleware(history),
  unauthorizedMiddleware,
  forbiddenMiddleware,
];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));

export const store = createStore(
  persistReducer(
    {
      key: "root",
      storage,
      whitelist: ["shopifyReducer"],
      stateReconciler: autoMergeLevel2,
      transforms: [
        createFilter("shopifyReducer", [
          "totalProducts",
          "completedProducts",
          "errorProducts",
          "remainProducts",
          "syncStatus",
          "lastSyncStarted",
          "lastSyncCompleted",
        ]),
      ],
    },
    reducers,
  ),
  enhancer,
);

export const persistor = persistStore(store);
