import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Button,
  Form,
  useDidMount,
  Pagination,
  useDebounce,
  useDidUnmount,
  clsx,
  globalStyles,
  NotificationText,
  SvgIcon,
  Typography,
  Switch,
} from "@gemlightbox/core-kit";

import { getProductPriceRange } from "src/api";
import { PageContainer } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ErrorCodes } from "src/constants";
import { ProductPriceRangeModel } from "src/models";
import { ProductsListWrapper } from "./products-list-wrapper";
import { CreateCatalogFormFields } from "../create-catalog-form-fields";
import { CreateCatalogHeader } from "../create-catalog-header";
import { MANUAL_CATALOG_FORM_ID } from "../create-catalog.constants";
import { CreateCatalogFormType } from "../create-catalog.types";
import { createCatalogValidation, useInitialCatalogForm } from "../create-catalog.utils";

import { ReactComponent as SuccessSVG } from "src/external-ts/assets/images/subscription/success-prompt.svg";
import styles from "./create-manual-catalog.module.css";

export const CreateManualCatalogPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { debounce } = useDebounce(150);
  const { createCatalogForm } = useInitialCatalogForm();

  const { productsStore, modalsStore, catalogsStore, notificationStore, localeStore } = useStores();

  const [sortingStatus, setSorting] = useState(false);
  const [sortingDirection, setDirection] = useState(false);

  useDidMount(() => productsStore.loadProductsList());
  useDidUnmount(() => productsStore.resetStore());

  const handleCreate = async (form: CreateCatalogFormType) => {
    let sortOrder: "fromZtoA" | "fromAtoZ" | undefined;

    if (sortingStatus) sortOrder = sortingDirection ? "fromZtoA" : "fromAtoZ";

    const data = {
      ...form,
      product_ids: productsStore.selectedProductsList.map(({ _id }) => _id),
      sortOrder: sortOrder,
    };

    const error = await catalogsStore.createCatalog(data);
    if (error) {
      if (error.error.originalError.code === ErrorCodes.CATALOGS_LIMIT_EXCEEDED) {
        notificationStore.open({
          title: localeStore.t('["create-catalog"].modals.error["limit-exceeded"]'),
          message: error.error.originalError.message,
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: "",
          onlyConfirm: true,
        });
      } else if (error.error.originalError.code === ErrorCodes.CATALOGS_NAME_EXISTS) {
        notificationStore.open({
          title: localeStore.t('catalog["add-to-catalog-sidebar"].notification.warning'),
          message: error.error.originalError.message,
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: "",
          onlyConfirm: true,
        });
      } else {
        notificationStore.open({
          title: localeStore.t('["create-catalog"].modals.error["something-went-wrong"]'),
          confirmText: localeStore.t('["create-catalog"].modals.error["ok-text"]'),
          cancelText: "",
          confirmAppearance: "secondary",
          icon: "exclamation",
          onlyConfirm: true,
        });
      }
    } else {
      notificationStore.open({
        title: localeStore.t('["create-catalog"].modals.success.title'),
        message: (
          <NotificationText disableBold>
            {localeStore.t('["create-catalog"].modals.success.subtitle')}
          </NotificationText>
        ),
        confirmText: localeStore.t('["create-catalog"].modals.success["ok-text"]'),
        cancelText: "",
        onlyConfirm: true,
        confirmAppearance: "primary",
        icon: <SvgIcon icon={SuccessSVG} size={195} />,
        onFinal: () => {
          const newCatalog = catalogsStore.catalogList.find(
            (catalog) => catalog.name === data.name,
          );
          navigate(`/catalog/${newCatalog?.id}`);
        },
      });
    }
  };

  const handlePageChange = (page: number) => {
    productsStore.loadProductsList({ ...productsStore.filters, page });
  };

  const handleLimitChange = (limit: number) => {
    productsStore.loadProductsList({ ...productsStore.filters, limit, page: 1 });
  };

  const handleSearch = (title: string) => {
    const filters = { ...productsStore.filters, title, page: 1 };

    productsStore.setFilters(filters);
    debounce(() => productsStore.loadProductsList(filters));
  };

  const handleOpenFilters = async () => {
    //getting min and max price values for filters sidebar
    const { success } = await getProductPriceRange.getRequest().fetch();
    let priceRange: ProductPriceRangeModel = {
      min: null,
      max: null,
    };

    if (success) {
      priceRange = success;
    }

    modalsStore.open("FiltersSidebar", {
      initialFilters: productsStore.initialFilters,
      appliedFilters: productsStore.filters,
      priceRange,
      onApply: (filters) => productsStore.loadProductsList(filters),
      onClear: () => {
        // productsStore.resetFilters();
        // productsStore.loadProductsList();
      },
    });
  };

  return (
    <PageContainer className={styles.container}>
      <CreateCatalogHeader
        formId={MANUAL_CATALOG_FORM_ID}
        type="manual"
        loading={catalogsStore.loading}
        isSubmitButtonDisabled={false}
      />
      <Form
        className={clsx(styles.formContainer, globalStyles.addScrollStyles)}
        contentClassName={styles.formContent}
        formId={MANUAL_CATALOG_FORM_ID}
        initialValues={createCatalogForm}
        onSubmit={handleCreate}
        validate={createCatalogValidation}
        disabled={catalogsStore.loading}
        enableReinitialize
      >
        <CreateCatalogFormFields />

        <div className={styles.sorting}>
          <Typography size="extraSmall" color="textSecondary">
            {localeStore.t('["create-catalog"].fields.sorting')}
          </Typography>
          <div className={styles.sortingSwitches}>
            <Switch checked={sortingStatus} onChange={setSorting} />
            <Switch
              checked={sortingDirection}
              onChange={setDirection}
              disabled={!sortingStatus}
              color={sortingStatus ? "primary" : "textDisabled"}
              checkedColor={sortingStatus ? "primary" : "textDisabled"}
              label="A-Z"
              checkedLabel="Z-A"
            />
          </div>
        </div>

        <ProductsListWrapper handleSearch={handleSearch} handleOpenFilters={handleOpenFilters} />
      </Form>

      <Button
        className={styles.mobileCreateButton}
        type="submit"
        form={MANUAL_CATALOG_FORM_ID}
        data-cy="create-catalog-create-button"
        loading={catalogsStore.loading}
      >
        {localeStore.t('["create-catalog"].buttons.create')}
      </Button>

      {productsStore.productsAmount !== 0 && (
        <Pagination
          totalItemsText={localeStore.t('components.ui.pagination["total-items"]')}
          prevText={localeStore.t("components.ui.pagination.prev")}
          nextText={localeStore.t("components.ui.pagination.next")}
          perPageText={localeStore.t('components.ui.pagination["per-page"]')}
          page={productsStore.filters.page}
          totalPages={productsStore.totalPages}
          totalItems={productsStore.resultsProductsAmount}
          limit={productsStore.filters.limit}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      )}
    </PageContainer>
  );
});
