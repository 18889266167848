import React from "react";
import { Button, memo, SvgIcon } from "@gemlightbox/core-kit";

import { ReactComponent as FiltersSVG } from "src/external-ts/assets/images/media/filter-grey.svg";
import styles from "./filters-button.module.css";

export interface FiltersButtonProps {
  isActive?: boolean;
  disabled?: boolean;
  onClick?: VoidFunction;
}

export const FiltersButton: React.FC<FiltersButtonProps> = memo(
  ({ isActive, disabled, onClick }) => {
    return (
      <Button
        className={styles.container}
        appearance="primaryOutlined"
        onClick={onClick}
        disabled={disabled}
        data-cy="filters-button"
      >
        {isActive && (
          <div className={styles.circle} data-cy="filters-button-circle">
            &nbsp;
          </div>
        )}
        <SvgIcon icon={FiltersSVG} className={styles.filtersIcon} />
      </Button>
    );
  },
);
