import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { LOGIN_PAGE } from "src/constants";
import { SupportedSubscriptionInterval } from "src/containers/settings/subscriptions/subscriptions.types";
import { Content } from "../settings/subscriptions/components";

import logoImg from "src/common/images/logo-text.svg";
import styles from "./shopify-pricing.module.css";

// NOTE: THIS PAGE IS PRESENT DUE TO 'SHOPIFY' POLICY THAT APPLICATION MUST HAVE 'PRICING PAGE'
// WHERE 'UNREGISTERED USER' CAN ACCESS TO SEE PRICES, EVEN THOUGH IT'S COMPLETELY BROKEN :)
export const ShopifyPricing = observer(() => {
  const navigate = useNavigate();

  const [subscriptionToSelect, setSubscriptionToSelect] =
    useState<SupportedSubscriptionInterval>("monthly");

  const handleUpgrade = () => navigate(LOGIN_PAGE.path);

  return (
    <div className={styles.shopifyPricingContainer}>
      <div className={styles.shopifyPricingHeader}>
        <img className={styles.logo} src={logoImg} alt="" />
      </div>

      <div className={styles.shopifyPricingBody}>
        <Content
          isPaymentDisabled={false}
          canDisplayAddons={false}
          proSubscriptionToSelect={subscriptionToSelect}
          extraCount={0}
          activeSubaccountAmount={0}
          setSubscriptionToSelect={setSubscriptionToSelect}
          onDowngrade={() => null}
          onOpenBillingFromProOffer={handleUpgrade}
          onProceed={() => undefined}
          disableStarterUpgrade
        />
      </div>
    </div>
  );
});

export default ShopifyPricing;
