const before = ({ action, agent }) => {
  if (action.isDisableCache) {
    agent.defaults.headers = {
      ...agent.defaults.headers,
      ...{ "Cache-Control": "private, no-cache, no-store" },
    };
  }

  if (action.isRestoreCache) {
    agent.defaults.headers = {
      ...agent.defaults.headers,
      ...{ "Cache-Control": "private, no-cache, no-store, set-store" },
    };
  }

  agent.defaults.headers = {
    ...agent.defaults.headers,
    "index-response": true,
  };
};

export default { before };
