import React, { useCallback, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
  Button,
  clamp,
  clsx,
  globalStyles,
  Heading,
  isEqual,
  Modal,
  Nullable,
  Portal,
  roundToClosest,
  useDidUpdate,
  useDragNDrop,
  useInfinityScroll,
} from "@gemlightbox/core-kit";

import { ExtendedProductModel } from "src/store";
import { useStores } from "src/hooks";
import { Card } from "./card";
import { EditCatalogOrderItem } from "./edit-order-item";
import { SelectPanel } from "./select-panel";
import { ShadowCard } from "./card-shadow";

import styles from "./styles.module.css";

export const DragNDrop: React.FC = observer(() => {
  const { catalogStore, localeStore, notificationStore } = useStores();
  const [orderedItems, setOrderedItems] = useState<ExtendedProductModel[]>(() => {
    return catalogStore.dragNDropList ?? [];
  });

  const [lastClickedItem, setLastClickedItem] = useState<Nullable<ExtendedProductModel>>(null);
  const cardContainerRef = useInfinityScroll<HTMLDivElement>();
  const modalContainerRef = useRef<HTMLDivElement>(null);

  const dragNDrop = useDragNDrop();

  const { catalogId } = useParams<{ catalogId: string }>();

  cardContainerRef.onScroll(() => {
    if (!catalogId) return;
    const filters = {
      ...catalogStore.filters,
      page: catalogStore.filters.page + 1,
    };
    catalogStore.updateCatalogOnScroll(Number(catalogId), orderedItems, filters);
  });

  useDidUpdate(() => {
    if (catalogStore.dragNDropList) {
      setOrderedItems(catalogStore.dragNDropList);
    }
  }, [catalogStore.dragNDropList]);

  useDidUpdate(() => {
    if (!catalogStore.catalog) return;

    const { page, limit } = catalogStore.filters;
    const offset = page * limit;
    if (offset < catalogStore.catalog.products.filtered_items) cardContainerRef.resetTrigger();
  }, [catalogStore.filters]);

  const isAnyItemSelected = useMemo(
    () => !!catalogStore.selectedDragNDropAmount,
    [catalogStore.selectedDragNDropList],
  );

  const {
    containerBoundingClientRect,
    containerScrollLeft,
    containerScrollTop,
    cardBoundingClientRect,
    cardsInRow,
    containerGap,
    dropIndex,
    dropPositionStyles,
  } = useMemo(() => {
    const defaultDOMRect: Omit<DOMRect, "toJSON"> = {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0,
    };
    const dropPositionStyles: React.CSSProperties = {};
    const container = cardContainerRef.ref.current;
    const defaultValues = {
      containerBoundingClientRect: defaultDOMRect,
      containerScrollLeft: 0,
      containerScrollTop: 0,
      cardBoundingClientRect: defaultDOMRect,
      cardsInRow: 0,
      cardsInColumn: 0,
      containerGap: 0,
      dropIndex: 0,
      dropPositionStyles,
    };
    if (!container) return defaultValues;

    const card = container.children[0];
    if (!card) return defaultValues;

    const containerBoundingClientRect = container.getBoundingClientRect();
    const cardBoundingClientRect = card.getBoundingClientRect();
    const clientY = dragNDrop.top - containerBoundingClientRect.top;
    const scrollY = container.scrollTop + clientY;
    const scrollX = dragNDrop.left - containerBoundingClientRect.left;

    // NOTE: getComputedStyle returns 'gap' as '24px' for example, so take this into account
    const containerGap = parseFloat(window.getComputedStyle(container).gap);
    const containerHeight = parseFloat(window.getComputedStyle(container).height);
    const cardsInRow = Math.floor(
      (containerBoundingClientRect.width + containerGap) /
        (cardBoundingClientRect.width + containerGap),
    );
    const cardsInColumn = Math.ceil(catalogStore.productsAmount / cardsInRow);

    const xModifier = containerBoundingClientRect.width / cardsInRow;
    const xIndex = roundToClosest(scrollX / xModifier, 1);
    const xIndexClamped = clamp(xIndex, 0, cardsInRow);

    const containerHeightWithoutScroll =
      (cardBoundingClientRect.height + containerGap) * cardsInColumn - containerGap;
    const containerScrollHeight =
      containerHeightWithoutScroll < containerHeight
        ? containerHeightWithoutScroll
        : container.scrollHeight;

    const yModifier = containerScrollHeight / cardsInColumn;
    const yIndex = Math.floor(scrollY / yModifier);
    const yIndexClamped = clamp(yIndex, 0, cardsInColumn - 1);

    const isNotInContainer =
      dragNDrop.top < containerBoundingClientRect.top ||
      dragNDrop.top > containerBoundingClientRect.height + containerBoundingClientRect.top;

    const dropIndex = yIndexClamped * cardsInRow + xIndexClamped;
    dropPositionStyles.top =
      cardBoundingClientRect.height * yIndexClamped +
      containerBoundingClientRect.top +
      yIndexClamped * containerGap -
      container.scrollTop;

    const maxXIndex = orderedItems.length % cardsInRow;
    const calculatedXIndex =
      xIndexClamped > maxXIndex &&
      orderedItems.length % cardsInRow &&
      yIndexClamped + 1 >= cardsInColumn
        ? maxXIndex
        : xIndexClamped;
    dropPositionStyles.left =
      cardBoundingClientRect.width * calculatedXIndex +
      containerBoundingClientRect.left +
      calculatedXIndex * containerGap -
      containerGap / 2 +
      4;
    dropPositionStyles.height = cardBoundingClientRect.height;
    dropPositionStyles.display = !isNotInContainer ? "block" : "none";

    return {
      containerBoundingClientRect,
      containerScrollLeft: container.scrollLeft,
      containerScrollTop: container.scrollTop,
      cardBoundingClientRect,
      cardsInRow,
      cardsInColumn,
      containerGap,
      dropIndex,
      dropPositionStyles,
    };
  }, [dragNDrop.top, dragNDrop.left]);

  const isNoUserChanges = useMemo(() => {
    if (!catalogStore.products) {
      return isEqual(catalogStore.dragNDropList, orderedItems);
    }

    return isEqual(catalogStore.dragNDropList, orderedItems);
  }, [orderedItems]);

  const productsFilters = useMemo(() => {
    return catalogStore.filters;
  }, []);

  const handleSelect = useCallback((item: ExtendedProductModel) => {
    catalogStore.toggleProductsList(item, "selected");
  }, []);

  const handleUnselectAllItems = () => catalogStore.unselectAllDragNDropList();

  const reorderArray = (
    newIndex: number,
    selectedProducts: ExtendedProductModel[],
    itemsArray: ExtendedProductModel[],
  ) => {
    const selectedItemsIDsArray = selectedProducts.map((el) => el._id);
    const sortedElementsByOrder = itemsArray.filter((el) => selectedItemsIDsArray.includes(el._id));

    const remainingItems = itemsArray.map((item) =>
      !selectedItemsIDsArray.includes(item._id) ? item : null,
    );

    const sortedItemsArray = [
      ...remainingItems.slice(0, newIndex),
      ...sortedElementsByOrder,
      ...remainingItems.slice(newIndex),
    ].filter((el) => el !== null);
    return sortedItemsArray as ExtendedProductModel[];
  };

  const onClose = () => {
    catalogStore.toggleOrderModalOpenStatus();
    catalogStore.resetDragNDrop();
  };

  const handleOnDrop = () => {
    const newOrderItem = reorderArray(dropIndex, catalogStore.selectedDragNDropList, orderedItems);
    setOrderedItems(newOrderItem);
  };

  const handleCancel = () => {
    return isNoUserChanges
      ? onClose()
      : notificationStore.open({
          title: localeStore.t(
            '["catalog-details"]["catalog-details-actions-panel"].modals.unsaved.title',
          ),
          message: localeStore.t(
            '["catalog-details"]["catalog-details-actions-panel"].modals.unsaved.message',
          ),
          confirmText: localeStore.t("common.buttons.close"),
          cancelText: localeStore.t("common.buttons.cancel"),
          onOk: () => onClose(),
        });
  };
  const handleSave = async () => {
    const result: Array<[number, number]> = [];

    orderedItems.forEach(({ _id }, index) => {
      result.push([_id, index]);
    });

    await catalogStore.updateProductOrder(result);

    const filters = productsFilters !== catalogStore.filters ? productsFilters : undefined;
    catalogStore.loadCatalog(Number(catalogId), filters);
    onClose();
  };

  dragNDrop.onMouseUp(handleOnDrop);
  dragNDrop.onTouchEnd(handleOnDrop);

  return (
    <Modal
      contentClassName={styles.modalContainer}
      forwardRef={modalContainerRef}
      setClose={handleCancel}
      isOpen={catalogStore.orderModalOpenStatus}
      withCross
      disableBorderRadius
    >
      <Heading className={styles.heading} tag="h2" color="textSecondary">
        {localeStore.t('["catalog-details"]["product-order-modal"].main.title')}
      </Heading>
      <SelectPanel
        selectedAmount={catalogStore.selectedDragNDropAmount}
        handleUnSelectAll={handleUnselectAllItems}
        isOpen={isAnyItemSelected}
      />
      <div
        className={clsx(styles.cardsContainer, globalStyles.addScrollStyles)}
        ref={cardContainerRef.ref}
      >
        {orderedItems.map((item, i) => {
          const row = Math.floor(i / cardsInRow);
          const col = i % cardsInRow;
          const cardTop =
            containerBoundingClientRect.top +
            (row * cardBoundingClientRect.height + row * containerGap);
          const cardLeft =
            containerBoundingClientRect.left +
            (col * cardBoundingClientRect.width + col * containerGap);

          return (
            <div key={item._id} className={styles.cardWrapper}>
              <Card
                card={item}
                onMouseDown={dragNDrop.setupMouseDown}
                onTouchStart={dragNDrop.setupTouchStart}
                onClick={setLastClickedItem}
                handleSelect={handleSelect}
                isSelected={item.extended.selected}
                isInDragNDrop={dragNDrop.isInDragNDrop}
                isAnyItemSelected={isAnyItemSelected}
              />
              {item.extended.selected && dragNDrop.isInDragNDrop && (
                <ShadowCard
                  top={dragNDrop.top + containerScrollTop}
                  left={dragNDrop.left + containerScrollLeft}
                  cardTop={cardTop}
                  cardLeft={cardLeft}
                  shadowCard={item}
                />
              )}
            </div>
          );
        })}
        {dragNDrop.isInDragNDrop && modalContainerRef.current && (
          <Portal portalNode={modalContainerRef.current}>
            <div className={styles.dropPosition} style={dropPositionStyles} />
          </Portal>
        )}
        {dragNDrop.isInDragNDrop && modalContainerRef.current && (
          <Portal portalNode={modalContainerRef.current}>
            <EditCatalogOrderItem
              top={dragNDrop.top}
              left={dragNDrop.left}
              itemCount={catalogStore.selectedDragNDropAmount}
              draggedCard={lastClickedItem}
            />
          </Portal>
        )}
      </div>
      <div
        className={clsx(styles.buttonSection, {
          [styles.buttonSectionNoPadding]: isAnyItemSelected,
        })}
      >
        <Button appearance="secondaryOutlined" onClick={handleCancel}>
          {localeStore.t('["catalog-details"]["product-order-modal"].buttons.cancel')}
        </Button>
        <Button appearance="primary" onClick={handleSave} disabled={isNoUserChanges}>
          {localeStore.t('["catalog-details"]["product-order-modal"].buttons.save')}
        </Button>
      </div>
    </Modal>
  );
});
export default DragNDrop;
