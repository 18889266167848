import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Search, useBoolean, useOutsideClick } from "@gemlightbox/core-kit";

import { FiltersButton } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { FiltersDialog } from "./filters-dialog";

import styles from "./filters.module.css";

export const Filters: React.FC = observer(() => {
  const { mediaStore, localeStore } = useStores();

  const ref = useRef<HTMLDivElement>(null);
  const dialogBoolean = useBoolean(false);

  useOutsideClick(ref, dialogBoolean.setFalsy);

  const handleSearch = (sku: string) => {
    if (mediaStore.isEmpty) return;
    mediaStore.loadMediaList({ ...mediaStore.filters, sku, page: 1 });
  };

  return (
    <div className={styles.container} ref={ref}>
      <Search
        placeholder={localeStore.t("components.business.search.placeholder")}
        searchValue={mediaStore.filters.sku || ""}
        setSearch={handleSearch}
        data-cy="media-search"
      />

      <FiltersButton
        isActive={mediaStore.isSort || mediaStore.isUser}
        disabled={mediaStore.isEmpty}
        onClick={dialogBoolean.trigger}
      />
      {dialogBoolean.value && <FiltersDialog onClose={dialogBoolean.setFalsy} />}
    </div>
  );
});
