import React from "react";
import { observer } from "mobx-react-lite";
import { Select, SelectOption, FilterCollapse } from "@gemlightbox/core-kit";

import { mediaTypeOptions } from "src/containers/media/media.constants";
import { MediaFilterTypeKeys } from "src/containers/media/media.types";
import { useStores } from "src/hooks";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export interface MediaTypeFilterProps {
  value: MediaFilterTypeKeys;
  onChange: (value: string) => void;
}

export const MediaTypeFilter: React.FC<MediaTypeFilterProps> = observer(({ value, onChange }) => {
  const { localeStore } = useStores();

  const beforeChange = (option: SelectOption) => {
    const value = option?.value;
    if (value) onChange(value.toString());
  };

  return (
    <FilterCollapse
      data-cy="media-type-filter-collapse"
      title={localeStore.t(
        'media.modals["assign-to-product-sidebar"]["media-item"]["media-type"].title',
      )}
      triggerName="media-type-filter-collapse"
    >
      <Select
        selectedOptionsKeys={value}
        options={mediaTypeOptions.map((item) => {
          item.label = localeStore.t(
            `media["media-control-panel"].tabs["${item.value}"]` as LocaleCodeTypes,
          );
          return item;
        })}
        placeholder={`${localeStore.t(
          'components.business.filters["filters-sidebar"]["default-filters"]["media-type-filter"].placeholder',
        )} ${localeStore.t(
          'media.modals["assign-to-product-sidebar"]["media-item"]["media-type"].title',
        )}`}
        appearance="primaryV2"
        onChange={(option) => beforeChange(option || ({} as SelectOption))}
        disableError
        data-cy="media-type-filter-input"
      />
    </FilterCollapse>
  );
});
