import { getCountryCallingCode, isPossiblePhoneNumber, ObjectType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { validateEmail } from "src/utils";
import { PersonalInfoType } from "./profile.types";

export const profileValidation = (values: PersonalInfoType) => {
  const { localeStore } = useStores();

  const errors: ObjectType = {};

  const phoneCallingCode = getCountryCallingCode(values.phone.country).callingCode;
  const phoneInternationalPhone = `+${phoneCallingCode}${values.phone.phone}`;

  if (!isPossiblePhoneNumber(phoneInternationalPhone) && values.phone.phone !== "") {
    errors.phone = localeStore.t('settings.profile["profile-form"].fields.errors["phone-invalid"]');
  }

  if (!values.firstName) {
    errors.firstName = localeStore.t('settings.profile["profile-form"].fields.errors.required');
  }

  if (!values.lastName) {
    errors.lastName = localeStore.t('settings.profile["profile-form"].fields.errors.required');
  }

  if (!values.phone.phone) {
    errors.phone = localeStore.t('settings.profile["profile-form"].fields.errors.required');
  }

  if (!values.email) {
    errors.email = localeStore.t('settings.profile["profile-form"].fields.errors.required');
  }

  if (values.email && !validateEmail(values.email)) {
    errors.email = localeStore.t('settings.profile["profile-form"].fields.errors["email-invalid"]');
  }

  return errors;
};
