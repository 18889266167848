import { ObjectType } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";

import { validateEmail } from "src/utils";
import { LoginForm } from "./login.types";

export const signInValidation = (values: LoginForm) => {
  const { localeStore } = useStores();
  const errors: ObjectType = {};

  if (!values.email) {
    errors.email = localeStore.t("common.errors.required");
  }

  if (values.email && !validateEmail(values.email)) {
    errors.email = localeStore.t('common.errors["email-invalid"]');
  }

  if (!values.password) {
    errors.password = localeStore.t("common.errors.required");
  }

  return errors;
};
