import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, clsx, SvgIcon, Typography } from "@gemlightbox/core-kit";

import { DashboardLink } from "src/external-ts/components";
import { usePageRedirect, useStores } from "src/hooks";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { getNavbarItemsLocalized } from "../navbar.constants";
import { CAMERA_PAGE, SETUP_DOWNLOAD_PAGE } from "src/constants";

import { ReactComponent as LogoSVG } from "src/external-ts/assets/images/gemlightbox-logo-text.svg";
import styles from "./desktop-layout.module.css";
import CallApp from "callapp-lib";
import { CallappOptions } from "callapp-lib/dist/type/types";

export const DesktopLayout: React.FC = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirect] = usePageRedirect();

  const { tooltipStore, localeStore, userStore } = useStores();
  const isValid = userStore.isValid;

  const handleLinkClick = (path: string) => () => {
    if (!window.$platform.isApp && window.$platform.os === "Win" && path === CAMERA_PAGE.path) {
      const options = {
        scheme: {
          protocol: "GemLightbox",
        },
      } as CallappOptions;
      const callLib = new CallApp(options);

      callLib.open({
        path: `emailVerification${path}`,
        callback: () => {
          //pushDataLayerEvent({ event: "click:download_gemhub_windows_app" });
          redirect(SETUP_DOWNLOAD_PAGE.path);
        },
      });
    } else {
      redirect(path);
    }
  };

  const handleOrderRetouch = () => window.open("https://picupmedia.com/dashboard", "_blank");

  const handleMouserEnter = (id: string) => {
    const handleClick = () => {
      navigate(getSettingsTab(SettingTabNames.account)?.item.path as string);
      tooltipStore.close();
    };

    tooltipStore.open(
      <>
        <Typography color="white" tag="span" size="extraSmall500">
          {localeStore.t('app.navbar["desktop-layout"].tooltip.title')}
        </Typography>
        <Button className={styles.userSetupTooltipButton} onClick={handleClick}>
          {localeStore.t('app.navbar["desktop-layout"].tooltip.button')}
        </Button>
      </>,
      {
        className: styles.userSetupTooltip,
        appearance: "secondary",
        position: "right",
        withAngle: true,
        target: id,
        onMouseLeave: () => tooltipStore.close(),
      },
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <SvgIcon icon={LogoSVG} size={[192, 30]} />
      </div>

      <nav className={styles.navbarTop}>
        <ul>
          {getNavbarItemsLocalized().map(({ className, path, label, icon, name }) => (
            <li key={path} id={path} data-cy={`desktop-navbar-${name}`}>
              <div
                className={clsx(
                  styles.navbarItemContent,
                  { [styles.navbarItemContentActive]: path === location.pathname && isValid },
                  { [styles.navbarItemContentDisabled]: !isValid },
                  className,
                )}
                onClick={isValid ? handleLinkClick(path) : undefined}
                onMouseEnter={!isValid ? () => handleMouserEnter(path) : undefined}
              >
                <SvgIcon icon={icon} />
                <Typography size="medium">{label}</Typography>
              </div>
            </li>
          ))}
        </ul>

        {!userStore.isShopifyUser && !userStore.isAppStoreDemoAccount && (
          <div className={styles.retouchItem}>
            <DashboardLink
              className={styles.retouch}
              label={localeStore.t('app.navbar["desktop-layout"]["dashboard-link"]')}
              onClick={handleOrderRetouch}
            />
          </div>
        )}
      </nav>
    </div>
  );
});
